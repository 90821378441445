import { trackTimeSpentCommonEvents } from './MatomoTrackingDataHelper';

const TIME_SPENT_CATEGORY = 'Time Spent';
const OP_SUBMITTING_A_NEW_REQUEST = 'OP_Submitting-a-new-request';
const BUILD_CREATING_A_PREZENTATION = 'Build_Creating-a-Prezentation';
const REPLACING_AN_IMAGE = 'Replacing-an-Image';
const REPLACING_A_SLIDE = 'Replacing-a-Slide';
const DOWNLOAD_BUTTON_ENABLED = 'Download-button-enabled';

export const trackTimeSpentEvents = {
  OPSubmittingANewRequest(currentUser, otherData) {
    try {
      trackTimeSpentCommonEvents({
        currentUser,
        otherData,
        eventCategory: TIME_SPENT_CATEGORY,
        eventAction: OP_SUBMITTING_A_NEW_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  BuildCreatingAPrezentation(currentUser, otherData) {
    try {
      trackTimeSpentCommonEvents({
        currentUser,
        otherData,
        eventCategory: TIME_SPENT_CATEGORY,
        eventAction: BUILD_CREATING_A_PREZENTATION,
      });
    } catch (e) {
      console.log(e);
    }
  },
  ReplacingAnImage(currentUser, otherData) {
    try {
      trackTimeSpentCommonEvents({
        currentUser,
        otherData,
        eventCategory: TIME_SPENT_CATEGORY,
        eventAction: REPLACING_AN_IMAGE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  ReplacingASlide(currentUser, otherData) {
    try {
      trackTimeSpentCommonEvents({
        currentUser,
        otherData,
        eventCategory: TIME_SPENT_CATEGORY,
        eventAction: REPLACING_A_SLIDE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  DownloadButtonEnabled(currentUser, otherData) {
    try {
      trackTimeSpentCommonEvents({
        currentUser,
        otherData,
        eventCategory: TIME_SPENT_CATEGORY,
        eventAction: DOWNLOAD_BUTTON_ENABLED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
