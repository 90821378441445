var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),_c('div',{staticClass:"full-width"},[_c('div',{class:"content-wrapper"},[_c('h2',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.isTrialUser ? 'Your trial has expired' : 'Your subscription has expired')+" ")]),_c('div',{staticClass:"image-wrapper"},[_c('v-carousel',{staticClass:"tip-img",attrs:{"cycle":"","height":"auto","hide-delimiters":"","show-arrows":false,"interval":3000,"light":""},model:{value:(_vm.currentBanner),callback:function ($$v) {_vm.currentBanner=$$v},expression:"currentBanner"}},_vm._l((_vm.banners),function(banner,index){return _c('v-carousel-item',{key:index,attrs:{"eager":""}},[_c('v-img',{attrs:{"src":banner,"min-height":"265px","max-height":"265px","eager":""}})],1)}),1)],1),_c('div',{staticClass:"banner-text"},[_c('h3',[_vm._v("Get more out of Prezent")]),_c('p',[_vm._v(_vm._s(_vm.getCurrentBannerText())+_vm._s(_vm.getSubHeaderText()))])]),_c('div',{staticClass:"carousel-nav-section"},_vm._l((_vm.banners),function(banner,bIndex){return _c('img',{key:bIndex,attrs:{"src":_vm.currentBanner === bIndex
              ? "/assets/img/trial/dot-indicator-active.svg"
              : "/assets/img/trial/dot-indicator-inactive.svg","alt":""},on:{"click":function($event){return _vm.setCurrentBanner(bIndex)}}})}),0),void 0,_c('div',{staticClass:"action-wrapper"},[(
            this.currentUser &&
            this.currentUser.user &&
            this.currentUser.user.role &&
            this.currentUser.user.role !== 'User'
          )?_c('v-btn',{staticClass:"primary-button",attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"93"},on:{"click":_vm.handleSubscribeClick}},[_vm._v(" "+_vm._s(_vm.isTrialUser ? _vm.$t('trialAndSubscribeBanner.upgrade') : _vm.$t('subscriptionExpired.renewNow'))+" ")]):_c('p',{staticClass:"user-cta-text"},[_vm._v(" "+_vm._s(_vm.$t('profile.reachOutAdminToRenew'))+" ")])],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }