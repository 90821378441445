<template>
  <v-tooltip top max-width="200" content-class="tooltip-content" fixed>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="go-to-btn"
        rounded
        height="36"
        min-width="64"
        color="#21a7e0"
        @click="handleClick"
        primary
        v-bind="attrs"
        v-on="on"
        >{{ $t(getButtonText) }}
      </v-btn>
    </template>
    <span>{{ $t(getPageToolTip) }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'GoToFeatureLanding',
  data() {
    return {
      pageDetails: [
        {
          ctaText: 'searchLandingCTAtext.people',
          tooltip: 'rightSideBar.fingerprintTooltip',
          pageType: 'people',
        },
        {
          ctaText: 'searchLandingCTAtext.slides',
          tooltip: 'rightSideBar.slidesTooltip',
          pageType: 'slides',
        },
        {
          ctaText: 'searchLandingCTAtext.bpeDecks',
          tooltip: 'rightSideBar.bestPracticesTooltip',
          pageType: 'bpe-decks',
        },
        {
          ctaText: 'searchLandingCTAtext.bpeSlides',
          tooltip: 'rightSideBar.bestPracticesTooltip',
          pageType: 'bpe-slides',
        },
        {
          ctaText: 'searchLandingCTAtext.courses',
          tooltip: 'rightSideBar.learnTooltip',
          pageType: 'courses',
        },
        {
          ctaText: 'searchLandingCTAtext.prezentation',
          tooltip: 'rightSideBar.prezentationsTooltip',
          pageType: 'prezentation',
        },
        {
          ctaText: 'searchLandingCTAtext.OP',
          tooltip: 'rightSideBar.overnightPresentationTooltip',
          pageType: 'OP',
        },
        {
          ctaText: 'searchLandingCTAtext.storybuilder',
          tooltip: 'rightSideBar.buildTooltip',
          pageType: 'storybuilder',
        },
        {
          ctaText: 'searchLandingCTAtext.storylines',
          tooltip: 'searchLanding.storyLineTooltip',
          pageType: 'storylines',
        },
        {
          ctaText: 'searchLandingCTAtext.templates',
          tooltip: 'searchLanding.templatesTooltip',
          pageType: 'templates',
        },
        {
          ctaText: 'searchLandingCTAtext.help',
          tooltip: 'searchLanding.helpTooltip',
          pageType: 'help',
        },
        {
          ctaText: 'searchLandingCTAtext.slidesCollection',
          tooltip: 'searchLanding.collectionTooltip',
          pageType: 'slides-collection',
        },
      ],
    };
  },
  props: {
    pageType: {
      type: String,
      required: true,
      default: '',
    },
    handleLandingPageClick: {
      type: Function,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getPageToolTip() {
      const currentPage = this.pageDetails.find(
        (item) => this.pageType === item?.pageType,
      );
      if (currentPage) return currentPage?.tooltip;
      return 'No tooltip';
    },
    getButtonText() {
      const currentPage = this.pageDetails.find(
        (item) => this.pageType === item?.pageType,
      );
      if (currentPage) return currentPage?.ctaText;
      return 'No CTA';
    },
  },
  methods: {
    handleClick() {
      this.handleLandingPageClick(this.pageType);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.go-to-btn {
  color: white;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
}
</style>
