var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide-detail wrapper",class:{ isModal: _vm.isModal }},[(_vm.isModal)?_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1):_vm._e(),_c('div',{staticClass:"content-wrapper"},[_c('v-row',{class:[
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide' ].includes(_vm.slideType)
          ? 'mb-2'
          : '',staticStyle:{"flex-wrap":"nowrap","width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.result)?_c('div',{staticClass:"main-wrapper pa-0"},[_c('div',{staticClass:"header text-h6 text-left font-weight-bold modal-header"},[_c('div',{staticClass:"nameOrIdeaName"},[(
                  _vm.slideType === 'prezentSourcedSlide' ||
                  _vm.slideType === 'companyBrandedSlide'
                )?_c('div',[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]):_c('div',[_vm._v(_vm._s(_vm.result.name))])]),(_vm.slideType === 'prezentSourcedSlide')?_c('div',{staticClass:"prezentLogo"},[_c('img',{attrs:{"src":"/assets/img/prezent_avatar.svg","alt":"","width":"22px"}})]):_vm._e()]),_c('div',{staticClass:"header text-subtitle-1 text-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"source"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.sourceLabel', { sourceName: _vm.capitalizeFirstLetter(_vm.getSourceForSlide()), }))+" ")])]}}],null,false,3249674680)},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationsList.sourceLabel', { sourceName: _vm.capitalizeFirstLetter(_vm.getSourceForSlide()), })))])]),_vm._v("   |   "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(
                    [
                      'companyBrandedSlide',
                      'userUploadedSlide',
                      'userGeneratedSlide',
                      'userRedesignSlide',
                      'synthesisSlide',
                      'userComplySlide' ].includes(_vm.slideType)
                  )?_c('div',_vm._g(_vm._b({staticClass:"author"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.authorLabel', { authorName: _vm.getAuthor(), }))+" ")]):_vm._e()]}}],null,false,4218535036)},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationsList.authorLabel', { authorName: _vm.getAuthor(), })))])]),(
                [
                  'companyBrandedSlide',
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  // 'userRedesignSlide',
                  'synthesisSlide',
                  'userComplySlide' ].includes(_vm.slideType)
              )?_c('div',[_vm._v("   |   ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(
                    _vm.slideType === 'prezentSourcedSlide' ||
                    _vm.slideType === 'companyBrandedSlide'
                  )?_c('div',_vm._g(_vm._b({staticClass:"description",style:({
                    'max-width': ((_vm.getDescriptionWidthBasedOnSlide()) + "%"),
                  })},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('slides.descriptionLabel', { descriptionText: _vm.getDescription(_vm.getTitle) || _vm.$t('slides.addDescriptionLabel'), }))+" ")]):(_vm.slideType !== 'userRedesignSlide')?_c('div',_vm._g(_vm._b({staticClass:"description",style:({
                    'max-width': ((_vm.headingsWidth - _vm.getDescriptionWidthBasedOnSlide()) + "%"),
                  })},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.description'))+": "),_c('span',{class:_vm.result.summary && _vm.result.summary !== ''
                        ? 'availableDesc'
                        : 'unavailableDesc'},[_vm._v(_vm._s(_vm.result.summary && _vm.result.summary !== '' ? _vm.result.summary : _vm.$t('slides.addDescription')))])]):_vm._e()]}}],null,false,799633110)},[(
                  _vm.slideType === 'prezentSourcedSlide' ||
                  _vm.slideType === 'companyBrandedSlide'
                )?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.getDescription(_vm.result.title) || _vm.getDescription(_vm.result.asset.title) || _vm.$t('slides.addDescription'))))+" ")]):(_vm.slideType !== 'userRedesignSlide')?_c('span',[_vm._v(" "+_vm._s(_vm.result.summary && _vm.result.summary !== '' ? _vm.result.summary : _vm.$t('slides.addDescription')))]):_vm._e()]),(
                [
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  'synthesisSlide' ].includes(_vm.slideType)
              )?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: ("" + (_vm.getTooltipContentForBestPractisesToggle())),
                placement: 'bottom',
              }),expression:"{\n                content: `${getTooltipContentForBestPractisesToggle()}`,\n                placement: 'bottom',\n              }"}],staticClass:"bestPracticesSwitch"},[_c('v-switch',{attrs:{"label":_vm.$t('slides.bestPractices'),"color":"#21a7e0","hide-details":"","disabled":_vm.getBestPractisesOnSlide.filter(function (item) { return item.isAdded; })
                    .length <= 0},on:{"click":_vm.handleToggleButton},model:{value:(_vm.toggleBestPractises),callback:function ($$v) {_vm.toggleBestPractises=$$v},expression:"toggleBestPractises"}})],1):_vm._e()],1)]):_vm._e()]),_c('v-col',{attrs:{"cols":"4 ml-2"}},[(
            _vm.slideType === 'prezentSourcedSlide' ||
            _vm.slideType === 'companyBrandedSlide'
          )?_c('div',{staticClass:"header text-h6 text-left font-weight-bold modal-header"},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.similarSlidesText'))+" ")]):_vm._e(),(
            !_vm.isModal &&
            (_vm.slideType === 'prezentSourcedSlide' ||
              _vm.slideType === 'companyBrandedSlide')
          )?_c('v-radio-group',{staticClass:"similar-slides-radiobtns",model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-row',{class:{ 'disable-events': _vm.isImageReplaceWalkThrough }},[_c('v-col',{attrs:{"cols":"4","data-pendo-id":"slides-radio-idea"}},[_c('v-radio',{attrs:{"label":_vm.$t('slides.idea'),"value":"idea"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":_vm.$t('slides.diagram'),"value":"diagram"}})],1)],1)],1):_vm._e(),(
            [
              'userUploadedSlide',
              'userGeneratedSlide',
              'userRedesignSlide',
              'synthesisSlide',
              'userComplySlide' ].includes(_vm.slideType)
          )?_c('div',{staticClass:"header text-h6 text-left font-weight-bold modal-header"},[_vm._v(" "+_vm._s(_vm.getSidePanelHeader())+" ")]):_vm._e(),(
            [
              'userUploadedSlide',
              'userGeneratedSlide',
              'userRedesignSlide',
              'synthesisSlide',
              'userComplySlide' ].includes(_vm.slideType)
          )?_c('div',{style:({ 'font-size': '16px', 'line-height': '28px' })},[_vm._v(" "+_vm._s(_vm.getSidePanelSubHeader())+" ")]):_vm._e()],1)],1),_c('v-row',{staticStyle:{"flex-wrap":"nowrap","width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.result)?_c('div',{staticClass:"main-wrapper"},[_c('div',{ref:"slideDetailImage",staticClass:"image-wrapper",attrs:{"id":_vm.isImageReplaceWalkThrough
                ? 'walkthough-slide-id'
                : 'main-slide-id'}},[_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":_vm.landscapeAsset,"lazy-src":"/assets/img/slides/placeholder-slide.svg","alt":"image","contain":""}},[(
                  _vm.toggleBestPractises &&
                  _vm.getBestPractisesOnSlide &&
                  _vm.getBestPractisesOnSlide.length
                )?_c('div',{key:_vm.pointsKey,staticClass:"best-practices-container"},[(_vm.sidePanelAction === 'bestPractises')?_c('div',_vm._l(([].concat( _vm.getBestPractisesOnSlide )),function(point,index){return _c('span',{key:index,staticClass:"coordinate-point"},[(point && (_vm.result.isOwner || point.isNew))?_c('BestPractisesDraggable',{attrs:{"slideThumbnailRef":_vm.slideThumbnailRef,"draggableLeft":_vm.draggableLeft,"bestPractisePoint":point}}):_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:({
                            width: '28px',
                            height: '28px',
                            color: '#ffffff',
                            'background-color': '#ec9f0b',
                            border: '2px solid #f5cf95',
                            'border-radius': '50%',
                            'text-align': 'center',
                            margin: '0px 4px 0px 0px',
                            cursor: 'pointer',
                            left: ((point.x) + "%"),
                            top: ((point.y) + "%"),
                            position: 'absolute',
                          })},'div',attrs,false),on),[_vm._v(" "+_vm._s(point.name.replace(/#/g, ''))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(point.tooltip))])])],1)}),0):_c('div',_vm._l((_vm.getBestPractisesOnSlide),function(point,index){return _c('span',{key:index,staticClass:"coordinate-point",style:(("left: " + (point.x) + "%; top: " + (point.y) + "%; position: absolute;"))},[_c('v-tooltip',{attrs:{"left":_vm.movingInRightHalf(point),"right":!_vm.movingInRightHalf(point),"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":'/assets/icons/bulb.svg',"width":"37.5px"}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(point.tooltip))])])],1)}),0)]):_vm._e()])],1)]):_vm._e()]),_c('v-col',{attrs:{"cols":"4"}},[(
            _vm.slideType === 'prezentSourcedSlide' ||
            _vm.slideType === 'companyBrandedSlide'
          )?_c('div',{staticClass:"related-products",style:({
            height: ("" + (_vm.mainSlideHeight ? (_vm.mainSlideHeight + "px") : '100%')),
          })},[(_vm.loadingRelatedSlides)?_c('div',{staticClass:"relatedSlidesLoaderContainer"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"color":"primary"}})],1):_c('div',{key:_vm.componentKey,staticClass:"related-products-thumbnails"},[(
                _vm.filteredSlideByNode.length && _vm.filteredSlideByNode.length > 0
              )?_c('div',{style:({ padding: '8px 0px 0px 0px' })},_vm._l((_vm.filteredSlideByNode),function(product,index){return _c('RelatedSlide',{key:index,class:{ 'disable-events': _vm.isImageReplaceWalkThrough },attrs:{"in-viewport-once":true,"in-viewport-root-margin":'0% 0%',"handle-related-product-click":_vm.handleRelatedProductClick,"product":product,"data-pendo-id":index === 0 ? 'slides-detail-thumbnail' : null}})}),1):_c('div',{staticClass:"noRelatedSlidesIllustration"},[_c('div',{staticClass:"imgContainer"},[_c('img',{attrs:{"src":"/assets/img/no-files-available.svg"}})]),_c('div',{staticClass:"relatedSlidesError"},[_vm._v(" "+_vm._s(("" + (_vm.isRelatedSlidesFetchError ? _vm.$t('slides.somethingWentWrong') : _vm.$t('slides.noSimilarSlidesFound'))))+" ")])])])]):(
            [
              'userUploadedSlide',
              'userGeneratedSlide',
              'userRedesignSlide',
              'synthesisSlide',
              'userComplySlide' ].includes(_vm.slideType)
          )?_c('div',[(_vm.sidePanelAction === 'editDetails')?_c('EditUserSlideType',{key:_vm.compKeyEditSlide,ref:"editUserSlideType",attrs:{"editMode":_vm.editingSlideDetails,"slideDataForEdit":_vm.result,"mainSlideHeight":_vm.mainSlideHeight,"slideType":_vm.slideType,"permission":_vm.result.permission,"isOwner":_vm.result.isOwner,"getAcceptButtonState":_vm.getAcceptButtonState,"getDataForSlideUpdate":_vm.getDataForSlideUpdate}}):_vm._e(),(_vm.sidePanelAction === 'shareDetails')?_c('ShareSlidePermissions',{key:_vm.compKeyShareSlide,staticClass:"share-slide-permission",attrs:{"slideOpened":_vm.result,"template":_vm.currentTheme,"getDataForSharePermissionsUpdate":_vm.getDataForSharePermissionsUpdate,"type":'component',"mainSlideHeight":_vm.mainSlideHeight,"getAcceptButtonState":_vm.getAcceptButtonState}}):_vm._e(),(_vm.sidePanelAction === 'bestPractises')?_c('BestPractises',{key:_vm.compKeyEditBP,attrs:{"mainSlideHeight":_vm.mainSlideHeight,"isBestPractiseToggleActive":_vm.toggleBestPractises,"getAcceptButtonState":_vm.getAcceptButtonState,"isOwner":_vm.result.isOwner}}):_vm._e()],1):_vm._e()])],1)],1),_c('div',{staticClass:"dialog-actions"},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap","width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"action-wrapper pl-0 pt-0 action-items__container"},[(!_vm.isModal)?_c('div',{staticClass:"add-fav-btn__wrapper"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"action-button",class:{ 'disable-events': _vm.isImageReplaceWalkThrough },style:({ 'background-color': hover ? '#fafafa' : '' }),attrs:{"color":"primary","text":"","data-pendo-id":"slides-detail-favorite","loading":_vm.processingFavSlide},on:{"click":function($event){return _vm.handleFavorite({ asset: _vm.result })}}},[(!_vm.isFav)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-heart-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-heart ")]),_c('span',{staticClass:"action-button__text"},[_vm._v(_vm._s(("" + (!_vm.isFav ? _vm.$t('bestPracticesGuide.knowSlideCard.addFavorites') : _vm.$t('bestPracticesGuide.knowSlideCard.removeFavorites')))))])],1)]}}],null,false,1447810190)})],1):_vm._e(),(!_vm.isModal)?_c('div',{staticClass:"add-fav-btn__wrapper"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-btn',{staticClass:"action-coll-button",class:{ 'disable-events': _vm.isImageReplaceWalkThrough },attrs:{"color":"primary","ripple":false,"text":"","plain":_vm.isCollectionLimitReached && !_vm.isAddedToCollection,"data-pendo-id":"slides-detail-collection","loading":_vm.processingCollection},on:{"mouseenter":_vm.trackCollectionMaxLimitEvent,"click":function($event){return _vm.handleCollection()}}},[(!_vm.isAddedToCollection && _vm.isCollectionLimitReached)?_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-content","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#bdbdbd"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-plus-outline ")]),_c('span',_vm._g(_vm._b({staticClass:"action-button-disable__text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('replaceImage.addToCollection'))+" ")])]}}],null,false,2151002600)},[_c('span',[_vm._v(_vm._s(_vm.$t('replaceImage.addToCollectionTooltip')))])]):_vm._e(),_c('NewFeatureBadge',{attrs:{"module-name":"collections","feature-id":'collections_thumbnail_id',"offsetX":'14',"offsetY":(!_vm.isCollectionLimitReached && !_vm.isAddedToCollection) ||
                    _vm.isAddedToCollection
                      ? '0'
                      : '-8',"size":'medium',"inline":true}},[(!_vm.isAddedToCollection && !_vm.isCollectionLimitReached)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-file-plus-outline ")]):_vm._e(),(!_vm.isAddedToCollection && !_vm.isCollectionLimitReached)?_c('span',{staticClass:"action-button__text"},[_vm._v(_vm._s(_vm.$t('replaceImage.addToCollection')))]):_vm._e(),(_vm.isAddedToCollection)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-file-minus ")]):_vm._e(),(_vm.isAddedToCollection)?_c('span',{staticClass:"action-button__text"},[_vm._v(_vm._s(_vm.$t('slides.removeFromCollection')))]):_vm._e()],1)],1)]},proxy:true}],null,false,29586725)})],1):_vm._e(),(!_vm.isModal && !_vm.hideEditSlideDetails)?_c('div',{staticClass:"add-fav-btn__wrapper"},[_c('v-btn',{class:!_vm.editingSlideDetails &&
                _vm.startLoadingSlidesFlag &&
                _vm.themes.length > 0
                  ? 'editSlideDetailsBtn'
                  : 'editSlideDetailsBtnDisabled',attrs:{"disabled":_vm.editingSlideDetails ||
                !_vm.startLoadingSlidesFlag ||
                !_vm.themes ||
                !(_vm.themes.length > 0)},on:{"click":_vm.toggleEditSlideDetails}},[_c('v-icon',[_vm._v(_vm._s('mdi-tag-multiple-outline'))]),_c('NewFeatureBadge',{attrs:{"module-name":"slides","feature-id":'slides_revamp_id',"offsetX":'20',"offsetY":'-5',"size":'medium'}},[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.editStorylineDetailsText'))+" ")])],1)],1):_vm._e(),(_vm.showReplaceVisual)?_c('div',{staticClass:"add-fav-btn__wrapper"},[_c('v-menu',{attrs:{"top":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:(!_vm.canReplaceImage || _vm.hideReplaceImage) &&
                    (!_vm.canReplaceIcons || _vm.hideReplaceIcon)
                      ? 'replaceAssetsBtnDisabled'
                      : 'replaceAssets',attrs:{"data-pendo-id":"replace-visuals"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s('mdi-swap-horizontal'))]),_vm._v(" "+_vm._s(_vm.$t('slideDetails.replaceOption'))+" ")],1)]}}],null,false,4079038542)},[_c('v-list',_vm._l((_vm.replaceMenuItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"replaceAssetsList"},[_c('v-btn',{class:item.disabled
                        ? 'replaceAssetsTitleDisabled'
                        : 'replaceAssetsTitle',attrs:{"data-pendo-id":item.pendoId,"id":item.eleId,"disabled":item.disabled},on:{"click":function($event){return item.clickHandler()}}},[(item.title === 'slideDetails.iconTitle')?_c('img',{attrs:{"src":item.icon,"alt":""}}):_c('v-icon',[_vm._v(_vm._s(("" + (item.icon))))]),(item.title === 'slideDetails.iconTitle')?_c('div',{staticClass:"assetLabel"},[_c('NewFeatureBadge',{attrs:{"module-name":'replace_icon',"feature-id":'replace_icon_id',"offsetX":'-3',"offsetY":'14',"size":'small'}},[_vm._v(_vm._s(_vm.$t(item.title)))])],1):_c('div',{staticClass:"assetLabel"},[_vm._v(_vm._s(_vm.$t(item.title)))])],1)],1)}),1)],1)],1):_vm._e(),(!_vm.isModal)?_c('div',{staticClass:"add-fav-btn__wrapper"},[(
                _vm.slideType === 'prezentSourcedSlide' ||
                _vm.slideType === 'companyBrandedSlide'
              )?_c('div',{staticClass:"actionContainer"},[(_vm.showCopied === true)?_c('div',{staticClass:"copiedStatus"},[_c('div',[_vm._v(_vm._s(_vm.$t('navbarActions.shareFreeTrial.copied')))])]):_c('v-btn',{staticClass:"copyLinkBtn",attrs:{"data-pendo-id":"copy-link-btn"},on:{"click":function($event){return _vm.copyToClipBoard('action')}}},[_c('v-icon',[_vm._v(_vm._s('mdi-link-variant'))]),_c('NewFeatureBadge',{attrs:{"module-name":"slides","feature-id":'slides_revamp_id',"offsetX":'0',"offsetY":'0',"size":'medium'}},[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.copyLink'))+" ")])],1)],1):(
                [
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  'userRedesignSlide',
                  'userComplySlide',
                  'synthesisSlide' ].includes(_vm.slideType) &&
                (!_vm.hideCopyLink ||
                  !_vm.hideShareEllipsisAction ||
                  !_vm.hideEditBestPractisesEllipsisAction)
              )?_c('div',{staticClass:"actionContainer"},[_c('v-menu',{key:_vm.menuKey,attrs:{"top":"","offset-y":"","close-on-content-click":_vm.closeEllipsisMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"otherActionsBtn"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s('mdi-dots-vertical'))]),_c('NewFeatureBadge',{attrs:{"module-name":"slides","feature-id":'slides_revamp_id',"offsetX":'0',"offsetY":'0',"size":'medium'}},[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.otherActions'))+" ")])],1)]}}],null,false,560113378)},[_c('v-list',[(!_vm.hideCopyLink)?_c('v-list-item',{on:{"click":function($event){return _vm.copyToClipBoard('menuItem')}}},[(_vm.showCopied === true)?_c('div',{staticClass:"copiedStatus"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('navbarActions.shareFreeTrial.copied'))+" ")])]):_c('div',{staticClass:"copyOption"},[_c('v-icon',[_vm._v(_vm._s('mdi-link-variant'))]),_c('div',{staticClass:"actionName"},[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.copyLink'))+" ")])],1)]):_vm._e(),(!_vm.hideShareEllipsisAction)?_c('v-list-item',{on:{"click":function($event){return _vm.handleShareSlide(_vm.result)}}},[_c('v-icon',[_vm._v(_vm._s('mdi-account-check-outline'))]),_c('div',{staticClass:"actionName"},[_vm._v(_vm._s(_vm.$t('common.share')))])],1):_vm._e(),(!_vm.hideEditBestPractisesEllipsisAction)?_c('v-list-item',{on:{"click":_vm.handleBestPractisesLoad}},[_c('v-icon',[_vm._v(_vm._s('mdi-lightbulb-on-outline'))]),_c('div',{staticClass:"actionName"},[_vm._v(" "+_vm._s(_vm.$t('slides.editBestPractices'))+" ")])],1):_vm._e()],1)],1)],1):_vm._e()]):_vm._e()])])],1),_c('v-row',{staticStyle:{"flex-wrap":"nowrap","width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-0 pb-0 ma-0"},[_c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"node pt-0"},[(
                _vm.slideType === 'prezentSourcedSlide' ||
                _vm.slideType === 'companyBrandedSlide'
              )?_c('div',{staticClass:"node-text header text-left font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowDetails.selectNodeCount'))+" "),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title",domProps:{"textContent":_vm._s("mdi-information-outline")}},'v-icon',attrs,false),on))]}}],null,false,1227455136)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'bestPracticesGuide.knowDetails.selectNumberOfMessages' ))+" ")])])],1):_vm._e(),(
                _vm.slideType === 'prezentSourcedSlide' ||
                _vm.slideType === 'companyBrandedSlide'
              )?_c('div',{staticClass:"node-list",attrs:{"data-pendo-id":"slides-node-list"}},_vm._l((_vm.availableNodes),function(item){return _c('span',{key:item,class:{ 'disable-events': _vm.isImageReplaceWalkThrough }},[_c('span',{class:("node-list-item " + (_vm.selectedNode === item.toString() ? 'outlined' : '') + " " + (_vm.availableNodes.includes(item) ? '' : 'disabled')),on:{"click":function($event){return _vm.handleNodeClick(item)}}},[_vm._v(_vm._s(item))])])}),0):_vm._e()]),_c('div',{staticClass:"slide-download"},[(
                _vm.slideType === 'prezentSourcedSlide' ||
                _vm.slideType === 'companyBrandedSlide'
              )?_c('v-btn',{class:_vm.disableRevertToOriginal ? 'disabledRevertBtn' : 'revertBtn',attrs:{"disabled":_vm.disableRevertToOriginal,"data-pendo-id":"revert-to-original-btn"},on:{"click":_vm.handleRevertSlide}},[_vm._v(" "+_vm._s(_vm.$t('slides.revertToOriginal'))+" ")]):(
                [
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  'userRedesignSlide',
                  'userComplySlide',
                  'synthesisSlide' ].includes(_vm.slideType) &&
                (_vm.editingSlideDetails ||
                  _vm.editingShareOptions ||
                  _vm.editingBestPractises)
              )?_c('v-btn',{staticClass:"cancelEdit",on:{"click":_vm.handleCancelDuringEdit}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]):_vm._e(),(
                _vm.slideType === 'prezentSourcedSlide' ||
                _vm.slideType === 'companyBrandedSlide' ||
                ((_vm.slideType === 'userUploadedSlide' ||
                  _vm.slideType === 'userComplySlide' ||
                  _vm.slideType === 'userGeneratedSlide' ||
                  _vm.slideType === 'userRedesignSlide' ||
                  _vm.slideType === 'synthesisSlide') &&
                  // || slideType === 'userGeneratedSlide') &&
                  !_vm.editingSlideDetails &&
                  !_vm.editingShareOptions &&
                  !_vm.editingBestPractises)
              )?_c('Download',{attrs:{"file-name":_vm.getFilename(_vm.result),"item":_vm.result,"downloading":_vm.downloadingSlide,"log-download":_vm.logDownload,"count-first-time":1,"on-download":function () { return _vm.handleDownload(
                    false,
                    _vm.result,
                    true /** this boolean indicates download from expanded view*/
                  ); },"speedDialPosRight":'10',"origin":"slide","limitsKey":"slide"}}):_vm._e(),(
                [
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  'userRedesignSlide',
                  'userComplySlide',
                  'synthesisSlide' ].includes(_vm.slideType) &&
                (_vm.editingSlideDetails ||
                  _vm.editingShareOptions ||
                  _vm.editingBestPractises)
              )?_c('v-btn',{staticClass:"acceptEdit",attrs:{"disabled":_vm.disableAccept,"loading":_vm.editing},on:{"click":_vm.handleAccpetBtn}},[_vm._v(" "+_vm._s(_vm.$t(_vm.updateText))+" ")]):_vm._e()],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }