<template>
  <div
    class="analyzing-area pt-4"
    :style="{
      height: `${customContainerHeight ? `${customContainerHeight}` : '400px'}`,
    }"
  >
    <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
    <div class="analyzing-wrapper" v-if="image">
      <div>
        <div class="animation-text text-center text-h6 pa-4">
          {{ $t(image.text) }}
        </div>
        <div class="image-wrapper">
          <v-img width="280" contain :src="image.url" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'LoadingAnimation',
  components: {
    ClipLoader,
  },
  data() {
    return {
      image: null,
    };
  },
  props: {
    animationImages: {
      type: Object,
      required: true,
    },
    customContainerHeight: {
      type: String,
      default: '400px',
    },
  },
  watch: {
    animationImages(val) {
      this.image = { ...val };
    },
  },
  mounted() {
    this.image = { ...this.animationImages };
  },
};
</script>

<style lang="scss" scoped>
.analyzing-area {
  // height: 400px;
  width: 100%;
}
.analyzing-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .animation-text {
    font-family: 'Lato' !important;
  }
}
</style>
