<template>
  <div class="search-wrapper">
    <v-text-field
      solo
      v-model="search"
      single-line
      hide-details
      :placeholder="placeholder"
      class="item-search-input"
      autocomplete="off"
      ><template v-slot:append>
        <v-icon
          color="primary"
          @click="resetSearch"
          v-text="`${search ? 'mdi-close' : iconText}`"
        /> </template
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    searchKey: {
      type: String,
      default: '',
    },
    onSearch: {
      type: Function,
      default: () => {},
    },
    iconText: {
      type: String,
      default: 'mdi-magnify',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      search: this.searchKey,
    };
  },
  methods: {
    resetSearch() {
      this.search = '';
      this.onSearch(this.search);
    },
  },
  watch: {
    search(val) {
      this.onSearch(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  ::v-deep {
    .item-search-input {
      align-items: center;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      justify-content: flex-start;
      margin-left: 2px;
      padding: 0 !important;
      position: relative;
      // max-width: 425px;
      z-index: 3;

      > .v-input__control {
        min-height: 40px;
        > .v-input__slot {
          box-shadow: none;
        }
      }

      .v-input__control > .v-input__slot:before {
        border-style: none;
      }

      input {
        background-clip: padding-box;
        border: none;
        color: $gray-search-input;
        display: block;
        font-size: 14px;
        line-height: 1.25;
        margin: 0 auto;
        outline: none;
        padding: 0.5rem 0.7rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        width: 100%;
      }

      input::placeholder {
        color: $gray-dark-login-text !important;
        font-size: 14px;
        opacity: 1;
      }

      .v-input__append-inner {
        padding-right: 5px;
      }
    }
  }
}
</style>
