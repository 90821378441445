import { trackCommonEvent, TD_PLACEMENT } from './MatomoTrackingDataHelper';
import store from '../../../store/index.js';

const UPLOAD_EVENT_CATEGORY = 'Upload';
const UPLOAD_ICONCLICK = 'Upload_iconclick';
const UPLOAD_IMPORT_NEXT = 'Upload_Import-Next';
const UPLOAD_IMPORT_NEXT_SELECT_NEXT = 'Upload_Import-Next_Select-Next';
const UPLOAD_IMPORT_NEXT_SELECT_NEXT_SANITIZE_NEXT =
  'Upload_Import-Next_Select-Next_Sanitize-Next';
const UPLOAD_IMPORT_NEXT_SELECT_NEXT_SANITIZE_NEXT_TAG_NEXT =
  'Upload_Import-Next_Select-Next_Sanitize-Next_Tag-Next';
const UPLOAD_IMPORT_NEXT_SELECT_NEXT_SANITIZE_NEXT_TAG_NEXT_SHARE_UPDATE =
  'Upload_Import-Next_Select-Next_Sanitize-Next_Tag-Next_Share-update';
const UPLOAD_BACK = 'Upload_back';
const UPLOAD_SANITIZE_SLIDE_THUMBNAIL_CLICK =
  'upload_sanitize_slide-thumbnail_click';
const UPLOAD_TAG_SLIDE_NAME_CHANGED = 'upload_Tag_slide-name_changed';
const UPLOAD_TAG_BUSINESS_IDEA_CHANGED = 'upload_Tag_business-idea_changed';
const UPLOAD_TAG_NODE_COUNT_CHANGED = 'upload_Tag_Node-count_changed';
const UPLOAD_TAG_KEYWORDS_CHANGED = 'upload_Tag_keywords_changed';
const UPLOAD_TAG_SLIDE_TEMPLATE_CHANGED = 'upload_Tag_slide_Template_changed';
const UPLOAD_TAG_SPEAKER_NOTES_CHANGED = 'upload_Tag_speaker-notes_changed';
const UPLOAD_SUCCESS_ADD_TO_SLIDES = 'upload_success_Add-to-slides';
const UPLOAD_SUCCESS_UPLOAD = 'upload_success_upload';
const UPLOAD_FAILED = 'Upload-failed';

export const trackUploadEvents = {
  uploadIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadImportNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_IMPORT_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadImportNextSelectNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_IMPORT_NEXT_SELECT_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadImportNextSelectNextSanitizeNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_IMPORT_NEXT_SELECT_NEXT_SANITIZE_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadImportNextSelectNextSanitizeNextTagNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_IMPORT_NEXT_SELECT_NEXT_SANITIZE_NEXT_TAG_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadImportNextSelectNextSanitizeNextTagNextShareUpdate(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction:
          UPLOAD_IMPORT_NEXT_SELECT_NEXT_SANITIZE_NEXT_TAG_NEXT_SHARE_UPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadBack(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_BACK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadSanitizeSlideThumbnailClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_SANITIZE_SLIDE_THUMBNAIL_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadTagSlideNameChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_TAG_SLIDE_NAME_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadTagBusinessIdeaChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_TAG_BUSINESS_IDEA_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadTagNodeCountChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_TAG_NODE_COUNT_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadTagKeywordsChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_TAG_KEYWORDS_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadTagSlideTemplateChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_TAG_SLIDE_TEMPLATE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadTagSpeakerNotesChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_TAG_SPEAKER_NOTES_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadSuccessAddToSlides(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_SUCCESS_ADD_TO_SLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadSuccessUpload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          [TD_PLACEMENT]: store.getters['upload/getPlacement'],
        },
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_SUCCESS_UPLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  uploadFailed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: UPLOAD_EVENT_CATEGORY,
        eventAction: UPLOAD_FAILED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
