<template>
  <div class="filter-sort-wrapper">
    <v-tabs color="#21a7e0" height="40" grow v-model="activeTab">
      <v-tab
        class="vtab"
        :name--auto="`slides-advanced-filter-filter-tab${
          activeTab === 0 ? '--active' : ''
        }`"
      >
        <span>{{ $t('prezentationsList.advancedFilters') }}</span>
      </v-tab>
      <v-tab
        class="vtab"
        :name--auto="`slides-advanced-filter-sort-tab${
          activeTab === 1 ? '--active' : ''
        }`"
      >
        <span>{{ $t('bestPracticesGuide.knowContainer.sortBy') }}</span>
      </v-tab>

      <v-tab-item>
        <div class="advanced-filter-wrapper">
          <div
            :class="
              !isAdvancedFilterChanged && !searchProgress
                ? 'resetFilters'
                : 'disabledResetFilters'
            "
          >
            <div
              class="resetText"
              @click="
                !isAdvancedFilterChanged && !searchProgress
                  ? resetAdvancedFilters(true)
                  : null
              "
            >
              {{ $t('prezentationsList.resetAllFilters') }}
            </div>
          </div>
          <div class="advanced-filter-options">
            <v-radio-group
              v-model="filterOptionCategory"
              @change="handleFilterCategoryChangeFn()"
            >
              <v-radio
                color="#20a7e0"
                :label="$t('prezentationsList.brandSlides')"
                value="brand_slides"
                :disabled="searchProgress"
              >
              </v-radio>
              <v-list v-if="filterOptionCategory === 'brand_slides'">
                <v-list-group
                  v-for="item in advancedItems"
                  :key="item.title"
                  v-model="item.active"
                  no-action
                >
                  <template v-slot:activator>
                    <img
                      alt
                      :src="`/assets/img/${item.icon}.svg`"
                      width="24"
                      height="24"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-title"
                        v-text="$t(item.display_title)"
                      />
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox
                        :disabled="searchProgress || item.disableCheckAll"
                        v-model="item.checkAll"
                        @click="onCheckboxAllClicked($event, item.title)"
                        color="primary"
                        :on-icon="'mdi-checkbox-marked-outline'"
                        :off-icon="'mdi-checkbox-blank-outline'"
                      />
                    </v-list-item-action>
                    <img
                      alt
                      :src="`/assets/img/filter-all.svg`"
                      width="20"
                      height="20"
                      class="advanced-filter-icon"
                      v-if="item.showIcon"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-content-text"
                        v-text="$t('overnightPresentations.all')"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    dense
                    v-for="child in item.items"
                    :key="child.title"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :disabled="searchProgress || child.disabled"
                          :on-icon="'mdi-checkbox-marked-outline'"
                          :off-icon="'mdi-checkbox-blank-outline'"
                          :input-value="active"
                          v-model="child.active"
                          color="primary"
                          @click="onAdvancedFilerChangeNew(child, item)"
                        />
                      </v-list-item-action>
                      <img
                        alt
                        :src="`/assets/img/filter-${child.icon}.svg`"
                        width="20"
                        height="20"
                        v-if="child.icon"
                        class="advanced-filter-icon"
                      />
                      <v-list-item-content v-if="child.title === 'Restricted'">
                        <NewFeatureBadge
                          :module-name="'restricted'"
                          :feature-id="'restricted_id'"
                          :offsetX="'100'"
                          :offsetY="'30'"
                          :size="'medium'"
                          ><v-list-item-title
                            class="advanced-filter-content-text"
                            v-text="$t(child.display_title)"
                        /></NewFeatureBadge>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-group>
              </v-list>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.favourites')"
                value="favorites"
                :disabled="searchProgress"
              >
              </v-radio>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.downloads')"
                value="downloads"
                :disabled="searchProgress"
              >
              </v-radio>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.generatedSlides')"
                value="generated"
                v-if="getGenerateAccessLevel"
                :disabled="searchProgress"
              >
              </v-radio>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.redesignedSlides')"
                value="redesign"
                v-if="isRedesignFullAccess || isRedesignLimitedAccess"
                :disabled="searchProgress"
              >
              </v-radio>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.complySlides')"
                value="comply"
                v-if="isComplyFullAccess || isComplyLimitedAccess"
                :disabled="searchProgress"
              >
              </v-radio>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.uploads')"
                value="uploaded"
                :disabled="searchProgress"
                v-if="getUploadAccessLevel"
              >
              </v-radio>

              <v-list v-if="filterOptionCategory === 'uploaded'">
                <v-list-group
                  v-for="item in advancedItemsUpload"
                  :key="item.title"
                  v-model="item.active"
                  no-action
                >
                  <template v-slot:activator>
                    <img
                      alt
                      :src="`/assets/img/${item.icon}.svg`"
                      width="24"
                      height="24"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-title"
                        v-text="$t(item.display_title)"
                      />
                    </v-list-item-content>
                  </template>
                  <v-list-item v-if="!item.isCustomGroup">
                    <v-list-item-action>
                      <v-checkbox
                        :disabled="searchProgress || item.disableCheckAll"
                        v-model="item.checkAll"
                        @click="onCheckboxAllClicked($event, item.title)"
                        color="primary"
                        :on-icon="'mdi-checkbox-marked-outline'"
                        :off-icon="'mdi-checkbox-blank-outline'"
                      />
                    </v-list-item-action>
                    <img
                      alt
                      :src="`/assets/img/filter-all.svg`"
                      width="20"
                      height="20"
                      class="advanced-filter-icon"
                      v-if="item.showIcon"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-content-text"
                        v-text="$t('overnightPresentations.all')"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    dense
                    v-for="child in item.items"
                    :key="child.title"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :disabled="searchProgress || child.disabled"
                          :on-icon="'mdi-checkbox-marked-outline'"
                          :off-icon="'mdi-checkbox-blank-outline'"
                          :input-value="active"
                          v-model="child.active"
                          color="primary"
                          @click="onAdvancedFilerChangeNew(child, item)"
                        />
                      </v-list-item-action>
                      <img
                        alt
                        :src="`/assets/img/filter-${child.icon}.svg`"
                        width="20"
                        height="20"
                        v-if="child.icon"
                        class="advanced-filter-icon"
                      />
                      <v-list-item-content v-if="child.title === 'Restricted'">
                        <NewFeatureBadge
                          :module-name="'restricted'"
                          :feature-id="'restricted_id'"
                          :offsetX="'63'"
                          :offsetY="'30'"
                          :size="'medium'"
                          ><v-list-item-title
                            class="advanced-filter-content-text"
                            v-text="$t(child.display_title)"
                        /></NewFeatureBadge>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <div v-if="item.isCustomGroup" class="customGroupWrapper">
                    <div class="fromDateWrapper">
                      <div class="label">
                        {{ $t('overnightPresentations.from') }}
                      </div>
                      <div class="datePicker">
                        <v-menu
                          v-model="fromDateMenu"
                          :close-on-content-click="false"
                          :close-on-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fromDateStr"
                              :placeholder="
                                $t('overnightPresentations.dateFormat')
                              "
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @mouseup="getCurrentDateFormatted('from')"
                              @click="(e) => handleDateTextFieldClick(e)"
                              @focus="getCurrentDateFormatted('from')"
                            >
                              <template #append>
                                <v-icon
                                  v-if="fromDateStr !== ''"
                                  @click="handleClearDate('from')"
                                  class="clearIcon"
                                >
                                  {{ 'mdi-close' }}
                                </v-icon>
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="calendarIcon"
                                >
                                  {{ 'mdi-calendar' }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fromDate"
                            :allowedDates="allowedFromDates"
                            no-title
                            scrollable
                            @input="updateFromDateMenu(false)"
                            @change="
                              (val) => handleDatePickerTextChange('from', val)
                            "
                            color="#20a7e0"
                            :width="`${datePickerWidth}px`"
                          />
                        </v-menu>
                      </div>
                    </div>
                    <div class="toDateWrapper">
                      <div class="label">
                        {{ $t('overnightPresentations.to') }}
                      </div>
                      <div class="datePicker">
                        <v-menu
                          v-model="toDateMenu"
                          :close-on-content-click="false"
                          :close-on-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="toDateStr"
                              :placeholder="
                                $t('overnightPresentations.dateFormat')
                              "
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @mouseup="getCurrentDateFormatted('to')"
                              @focus="getCurrentDateFormatted('to')"
                              @click="(e) => handleDateTextFieldClick(e)"
                            >
                              <template #append>
                                <v-icon
                                  v-if="toDateStr !== ''"
                                  @click="handleClearDate('to')"
                                  class="clearIcon"
                                >
                                  {{ 'mdi-close' }}
                                </v-icon>
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="calendarIcon"
                                >
                                  {{ 'mdi-calendar' }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="toDate"
                            :allowedDates="allowedToDates"
                            no-title
                            scrollable
                            @input="updateToDateMenu(false)"
                            @change="
                              (val) => handleDatePickerTextChange('to', val)
                            "
                            color="#20a7e0"
                            :width="`${datePickerWidth}px`"
                          />
                        </v-menu>
                      </div>
                    </div>
                    <div class="applyDatesCTA">
                      <v-btn @click="onAdvancedFilerChangeNew(item)">
                        {{ $t('prezentationsList.applyCustomDates') }}
                      </v-btn>
                    </div>
                  </div>
                </v-list-group>
              </v-list>
              <v-radio
                color="#20a7e0"
                :label="$t('slides.synthesis')"
                value="synthesis"
                :disabled="searchProgress"
                v-if="getSynthesisAccessLevel"
              >
              </v-radio>
            </v-radio-group>
          </div>
        </div>
      </v-tab-item>

      <v-tab-item>
        <SlidesSort />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import __ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import EventBus from '../../event-bus';
import SlidesSort from './SlidesSort.vue';

export default {
  name: 'SlidesAdvancedFilters',
  components: {
    SlidesSort,
  },
  data() {
    return {
      datePickerWidth: '',
      menuPosition: {
        x: 0,
        y: 0,
      },
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters('users', [
      'getGenerateAccessLevel',
      'getUploadAccessLevel',
      'getSynthesisAccessLevel',
      'isRedesignFullAccess',
      'isRedesignLimitedAccess',
      'isComplyFullAccess',
      'isComplyLimitedAccess',
    ]),
    ...mapGetters('advancedFilterStore', ['getSlidesAdvanceItemsOnload']),
    ...mapState('advancedFilterStore', ['advancedFilterOpened']),
    advancedItemsUpload: {
      get() {
        return this.$store.state.advancedFilterStore.slides.advancedItemsUpload;
      },
      set() {},
    },
    filterOptionCategory: {
      get() {
        return this.$store.state.advancedFilterStore.slides
          .filterOptionCategory;
      },
      set(category) {
        this.$store.dispatch(
          'advancedFilterStore/setSlidesFilterOptionCategory',
          category,
        );
      },
    },
    searchProgress: {
      get() {
        return this.$store.state.advancedFilterStore.slides.searchProgress;
      },
      set() {},
    },
    fromDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.slides.fromDateMenu;
      },
      set(value) {
        this.$store.dispatch(
          'advancedFilterStore/setSlidesFromDateMenu',
          value,
        );
      },
    },
    toDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.slides.toDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setSlidesToDateMenu', value);
      },
    },
    fromDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.slides.fromDateStr;
      },
      set() {},
    },
    toDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.slides.toDateStr;
      },
      set() {},
    },
    fromDate: {
      get() {
        return this.$store.state.advancedFilterStore.slides.fromDate;
      },
      set() {},
    },
    toDate: {
      get() {
        return this.$store.state.advancedFilterStore.slides.toDate;
      },
      set() {},
    },
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.slides.advancedItems;
      },
      set() {},
    },
    pickerDate: {
      get() {
        return this.$store.state.advancedFilterStore.slides.pickerDate;
      },
      set() {},
    },
    isAdvancedFilterChanged() {
      if (this.filterOptionCategory !== 'brand_slides') return false;
      const advItems = [...this.advancedItems].map((item) => ({
        ...item,
        active: true,
      }));
      const advItemsOnLoad = [...this.getSlidesAdvanceItemsOnload].map(
        (item) => ({
          ...item,
          active: true,
        }),
      );
      return __.isEqual(advItems, advItemsOnLoad);
    },
  },
  methods: {
    ...mapActions('advancedFilterStore', [
      'setFromDateMenu',
      'setToDateMenu',
      'setSlidesFromDate',
      'setSlidesToDate',
      'setSlidesFromDateStr',
      'setSlidesToDateStr',
      'setSlidesPickerDate',
      'setSlidesFromDateMenu',
      'setSlidesToDateMenu',
      'setSlidesDatePickerTextChange',
    ]),
    updateFromDateMenu(type) {
      this.setSlidesFromDateMenu(type);
    },
    updateToDateMenu(type) {
      this.setSlidesToDateMenu(type);
    },
    getDatePickerWidth() {
      if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        this.datePickerWidth = 250;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.datePickerWidth = 218;
      } else {
        this.datePickerWidth = 280;
      }
    },
    resetAdvancedFilters(value) {
      EventBus.$emit('RESET_SLIDES_ADVANCED_FILTERS', value);
    },
    onAdvancedFilerChange(item) {
      EventBus.$emit('ON_FILTER_CHANGE', item);
    },
    handleDatePickerTextChange(type, val) {
      if (type === 'from') {
        this.setSlidesFromDate(val);
        this.setSlidesFromDateStr(moment(val).format('MM/DD/YYYY'));
        if (this.toDate && moment(this.toDate).isBefore(moment(val))) {
          this.setSlidesToDate('');
          this.setSlidesToDateStr('');
        }
      } else if (type === 'to') {
        this.setSlidesToDate(val);
        this.setSlidesToDateStr(moment(val).format('MM/DD/YYYY'));
      }
    },
    allowedFromDates(val) {
      return moment(val).isSameOrBefore(new Date());
    },
    allowedToDates(val) {
      if (this.fromDate === '') {
        return moment(val).isSameOrBefore(new Date());
      }
      return (
        moment(val).isSameOrAfter(this.fromDate) &&
        moment(val).isSameOrBefore(new Date())
      );
    },
    getCurrentDateFormatted(origin) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      this.setSlidesPickerDate(`${year}-${month}-${day}`);
      if (origin === 'from' && this.fromDate)
        this.setSlidesPickerDate(this.fromDate);
      if (origin === 'to' && this.toDate) this.setSlidesPickerDate(this.toDate);
    },
    handleDateTextFieldClick(e) {
      this.menuPosition.x = e.clientX - 150;
      this.menuPosition.y = e.clientY;
    },
    handleClearDate(type) {
      if (type === 'from') {
        this.setSlidesFromDate('');
        this.setSlidesFromDateStr('');
      } else if (type === 'to') {
        this.setSlidesToDate('');
        this.setSlidesToDateStr('');
      }
    },
    handleFilterCategoryChangeFn() {
      EventBus.$emit('SLIDES_FILTER_CATEGORY_CHANGE');
    },
    onCheckboxAllClicked(e, title) {
      EventBus.$emit('SLIDES_CHECK_ALL_FILTER', e, title);
    },
    onAdvancedFilerChangeNew(filterItem, filterCategory) {
      EventBus.$emit(
        'SLIDES_ADVANCE_FILTER_CHANGE',
        filterItem,
        filterCategory,
      );
    },
  },
  mounted() {
    this.getDatePickerWidth();
    window.addEventListener('resize', this.getDatePickerWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.getDatePickerWidth);
  },
};
</script>

<style lang="scss" scoped>
.advanced-filter-wrapper {
  .exp-filter-header {
    display: flex;
    align-items: center;
    gap: 5px;

    .filter-icon {
      width: 24px;
      height: 24px;
    }
  }

  .clear-text {
    color: #21a7e0 !important;
    cursor: pointer;
  }

  .badge-container {
    display: flex;
    position: absolute;
    right: 0;
    top: -4px;
    z-index: 4;
  }

  .advanced-filter-title {
    color: black;
  }
}

.vtab {
  span {
    font-size: 16px;
    font-weight: 600;
    color: #4d5358;
    text-transform: none;
    letter-spacing: normal;
  }
}

.advanced-filter-options {
  height: calc(100vh - 230px) !important;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::v-deep .v-label {
    font-size: 17px;
    color: black;
    padding: 8px;
  }
}

.advanced-filter-options::-webkit-scrollbar {
  display: none;
}

.customGroupWrapper {
  margin-left: 30px;
  padding: 15px 15px 15px 0;

  .fromDateWrapper,
  .toDateWrapper {
    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
    .datePicker {
      .v-input {
        .v-input__control {
          .v-input__slot {
            width: 80%;

            .v-input__append-inner {
              .clearIcon,
              .calendarIcon {
                &:hover {
                  color: #21a7e0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panels {
  height: calc(100vh - 127px);
  overflow: auto;
  display: block;
  .v-expansion-panel {
    flex: none;
    width: 100%;
    &::before {
      box-shadow: none;
    }

    &::after {
      opacity: 0 !important;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: 47px !important;
  }
}

.v-list {
  margin-left: 12px !important;
}

.v-list-item {
  min-height: 30px !important;
  padding: 0;
  padding-left: 47px !important;
  .v-list-item__action {
    margin: 0px 10px 0px 0px;
  }
}

.v-list-item__content {
  padding: 8px 0px;
  margin-left: 10px !important;
  .v-list-item__title {
    font-size: 16px;
    line-height: 18px !important;
  }
}

.applyDatesCTA {
  width: fit-content;

  .v-btn {
    padding: 0 !important;
    background: transparent !important;
    border: none;
    box-shadow: none;
    letter-spacing: normal;
    text-transform: none;
    font-size: 16px;
    color: #21a7e0;
    width: 100%;
    background-color: transparent !important;
  }

  .disabled-applyDatesCTA {
    color: #878d96;
  }
}

.resetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .resetText {
    color: #21a7e0 !important;
    cursor: pointer;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.disabledResetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .v-icon,
  .resetText {
    color: #7b7b7b;
    margin: 0px 0px 0px 5px;
    cursor: default;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.filter-header {
  padding: 5px 10px 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
  }

  h3 {
    font-size: 17px;
    text-align: center;
  }
}
</style>
