<template>
  <v-text-field
    v-model="searchText"
    single-line
    hide-details
    :placeholder="placeholder"
    autocomplete="off"
    rounded
    solo
    dense
    clearable
    @focus="handleFocus"
    @blur="handleBlur"
    @change="onChangeFunc"
    @input="onInput"
    @keyup="onChangeFunc"
  >
    <template v-slot:append="">
      <v-icon :color="isFocused ? `#21a7e0` : 'inherit'">mdi-magnify</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    onInput: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      searchText: '',
      isFocused: false,
    };
  },
  methods: {
    onChangeFunc() {
      this.onChange(this.searchText);
    },
    handleTextClear() {
      this.searchText = '';
      this.onChange();
      this.onInput();
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
