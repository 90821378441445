import { trackCommonEvent } from './MatomoTrackingDataHelper';

const GENERAL_CATEGORY = 'General';
const DOWNLOAD_SETTINGS_CLICK = 'Download_settings_click';
const DOWNLOAD_SETTINGS_SAVE = 'Download_settings_save';

export const trackDownloadSettingsEvents = {
  downloadSettingsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_CATEGORY,
        eventAction: DOWNLOAD_SETTINGS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  downloadSettingsSave(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_CATEGORY,
        eventAction: DOWNLOAD_SETTINGS_SAVE,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
