<template>
  <div class="audienceToolContainerWrapper">
    <div
      v-if="isSlides"
      class="audience-selection-tool"
      data-pendo-id="audience-selection-dropdown"
    >
      <AudienceSelectorTypesense
        class="audience-selector"
        v-model="filterTailor"
        :loading="!startLoadingSlidesFlag"
        :label="$t('build.step3.tailorSlidesFor')"
        :placeholder="$t('build.step3.tailorSlidesForPlaceholder')"
        :maxWidth="300"
        :value="filterTailor"
        :addToRealtimeAudienceOnSelect="true"
        @change:user="handleAudienceChange($event)"
        @change="handleAudienceChangeInitial($event)"
        @focus="handleAudienceFocus($event)"
        @add="handleAddAudience($event)"
      />
    </div>
    <div class="audience-tool-cntr">
      <template v-if="isSlides"
        ><h3 class="pt-4">
          {{ $t('generate.yourAudienceAtAGlance') }}
        </h3></template
      >
      <template v-else
        ><h3 class="info-text">
          {{
            'This will help our designers create slides that your audience will love.'
          }}'
        </h3>
        <p>
          {{
            "Don't worry! You will be able to override these preferences in the final step."
          }}
        </p>
      </template>
      <div class="pref-container" v-if="!isPublicLimited">
        <div class="pref-logo">
          <img :src="fingerprintData.logo" height="100px" width="100px" />
          <div class="description-text">{{ getFPDescription }}</div>
        </div>
        <div
          class="pref-item"
          v-for="(item, index) in fingerprintData.preferences"
          :key="index"
        >
          <img :src="item.logo" width="90px" height="90px" />
          <div class="pref-cont">
            <div class="pref-heading">{{ $t(item.title) }}</div>
            <div class="pref-type">
              {{ item.preference }}
            </div>
            <div class="pref-sub-heading">
              {{ item.oneLine }}
            </div>
          </div>
        </div>
      </div>
      <div class="fingerprint-data" v-else>
        <img
          src="/assets/img/lock-icon@100px.svg"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
        <h3 class="locked-fingerprint-heading">
          {{
            $t('generate.fingerprintLabel2', {
              audienceLabel: isMeAsAudience
                ? ''
                : $t('overnightPresentations.audience'),
            })
          }}
          <img alt class="locked-inline" src="/assets/img/lock-icon@24px.svg" />
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AudienceSelectorTypesense from '../AudienceSelectorTypesense.vue';
import { fpTypes } from '@/mock/fingerprint';
import { capitalizeFirstLetter } from '../../../utils/common';

export default {
  name: 'AudienceToolCntr',
  components: {
    AudienceSelectorTypesense,
  },
  data() {
    return {
      startLoadingSlidesFlag: false,
      filterTailor: null,
    };
  },
  props: {
    handleAudienceChange: {
      type: Function,
      required: true,
    },
    handleAudienceChangeInitial: {
      type: Function,
      required: true,
    },
    handleAudienceFocus: {
      type: Function,
      required: true,
    },
    handleAddAudience: {
      type: Function,
      required: true,
    },
    audience: {
      type: Object,
      default: () => ({}),
    },
    audienceOnLoad: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (this.slidesAudienceSelected) {
      this.filterTailor = this.slidesAudienceSelected;
    } else if (this.audienceOnLoad && this.audienceOnLoad.defaultAudience) {
      this.filterTailor = this.audienceOnLoad;
    } else {
      this.filterTailor = {
        defaultAudience: 'SELF',
      };
    }
  },
  computed: {
    ...mapState('users', ['currentUser', 'slidesAudienceSelected']),
    isSlides() {
      return this.$route?.path === '/home/slides';
    },
    isPublicLimited() {
      if (!this.isSlides && this.audience) {
        return this.audience.isPublicLimited;
      }
      return this.filterTailor?.isPublicLimited;
    },
    fingerprintData() {
      if (!this.isSlides && this.audience) {
        const fpData = fpTypes.find(
          (fprint) =>
            fprint.title === this.audience?.fingerPrint?.toLowerCase(),
        );
        if (!fpData) {
          return fpTypes?.[0];
        }
        return fpData;
      }
      if (this.filterTailor) {
        if (this.filterTailor?.defaultAudience === 'SELF') {
          const fpData = fpTypes.find(
            (fprint) =>
              fprint.title ===
              this.currentUser?.user?.fingerPrint?.toLowerCase(),
          );
          if (!fpData) {
            return fpTypes?.[0];
          }
          return fpData;
        }
        const fpData = fpTypes.find(
          (fprint) =>
            fprint.title === this.filterTailor?.fingerPrint?.toLowerCase(),
        );
        if (!fpData) {
          return fpTypes?.[0];
        }
        return fpData;
      }
      // if (!this.isSlides && this.audience) {
      //   const fpData = fpTypes.find(
      //     (fprint) => fprint.title === this.audience?.fingerPrint.toLowerCase(),
      //   );
      //   if (!fpData) {
      //     return fpTypes?.[0];
      //   }
      //   return fpData;
      // }
      // if (this.isSlides && !this.filterTailor) {
      //   const fpData = fpTypes.find(
      //     (fprint) =>
      //       fprint.title === this.currentUser?.user?.fingerPrint.toLowerCase(),
      //   );
      //   if (!fpData) {
      //     return fpTypes?.[0];
      //   }
      //   return fpData;
      // }
      return {};
    },
    isMeAsAudience() {
      if (!this.isSlides && this.audience) {
        return this.currentUser?.user?.id === this.audience?.id;
      }
      return this.currentUser?.user?.id === this.filterTailor?.id;
    },
    selectedAudienceName() {
      let audience = null;
      if (this.isSlides && this.filterTailor) {
        if (this.filterTailor?.defaultAudience === 'SELF') {
          audience = this.currentUser?.user;
        }
        audience = this.filterTailor;
      } else if (!this.isSlides && this.audience) {
        audience = this.audience;
      }
      return audience?.firstName || '';
    },
    getFPDescription() {
      return `${
        this.selectedAudienceName
      }'s fingerprint is the ${capitalizeFirstLetter(
        this.fingerprintData?.title,
      )}`;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.audienceToolContainerWrapper {
  .audience-selection-tool {
    .audience-selector {
      display: flex !important;
      gap: 15px;
      flex-direction: column;
      align-items: normal;
      .audience-select-title {
        font-size: 16px !important;
        font-weight: bold !important;
      }
    }
  }
  .audience-tool-cntr {
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    .info-text {
      padding-top: 16px;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 500;
    }
    .pref-container {
      .pref-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
        .description-text {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          padding-top: 10px;
        }
      }
      .pref-item {
        display: flex;
        gap: 10%;
        padding: 10px;
        .pref-cont {
          .pref-heading {
            font-size: 14px;
            font-weight: 400;
            color: rgba(123, 123, 123, 1);
          }
          .pref-type {
            font-size: 16px;
            font-weight: 600;
            color: rgba(33, 33, 33, 1);
          }
          .pref-sub-heading {
            font-size: 14px;
            font-weight: 400;
            color: rgba(33, 33, 33, 1);
          }
        }
      }
    }

    .fingerprint-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      .locked-fingerprint-heading {
        height: 19px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #000000;
        display: flex;
        gap: 5px;
      }
      .logo-fingerprint {
        height: 72px;
        width: 72px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin: 20px;
      }
    }
  }
}
</style>
