<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.475 16.825L20.85 8.45L19.55 7.15L11.175 15.525L12.475 16.825ZM5.95 19C4.28333 18.9167 3.04167 18.5667 2.225 17.95C1.40833 17.3333 1 16.4417 1 15.275C1 14.1917 1.44583 13.3125 2.3375 12.6375C3.22917 11.9625 4.46667 11.5583 6.05 11.425C6.7 11.375 7.1875 11.2708 7.5125 11.1125C7.8375 10.9542 8 10.7333 8 10.45C8 10.0167 7.75417 9.69167 7.2625 9.475C6.77083 9.25833 5.95833 9.1 4.825 9L5 7C6.71667 7.13333 7.97917 7.47917 8.7875 8.0375C9.59583 8.59583 10 9.4 10 10.45C10 11.3333 9.67917 12.025 9.0375 12.525C8.39583 13.025 7.45 13.325 6.2 13.425C5.13333 13.5083 4.33333 13.7042 3.8 14.0125C3.26667 14.3208 3 14.7417 3 15.275C3 15.8583 3.23333 16.2792 3.7 16.5375C4.16667 16.7958 4.95 16.95 6.05 17L5.95 19ZM12.95 19.175L8.825 15.05L18.375 5.5C18.7083 5.16667 19.1042 5 19.5625 5C20.0208 5 20.4167 5.16667 20.75 5.5L22.5 7.25C22.8333 7.58333 23 7.97917 23 8.4375C23 8.89583 22.8333 9.29167 22.5 9.625L12.95 19.175ZM8.975 20C8.69167 20.0667 8.44167 19.9917 8.225 19.775C8.00833 19.5583 7.93333 19.3083 8 19.025L8.825 15.05L12.95 19.175L8.975 20Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'RedesignIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
