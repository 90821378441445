<template>
  <div
    :class="`left-side-item ${getimageClass} ${className} section-${sectionIndex}-slide-${slideIndex}-dragarea--auto`"
    v-if="slide.assetId"
  >
    <span v-if="serialNumber" class="serial-number">{{ serialNumber }}</span>
    <div
      v-if="
        animationsData.type === 'acceptSuggestionLoader' ||
        animationsData.type === 'reviewCheckLoader' ||
        animationsData.type === 'synthesizeVideoLoading'
      "
    >
      <v-img
        alt
        lazy-src="/assets/img/slides/placeholder-slide.svg"
        :aspect-ratio="16 / 9"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
          </v-row>
        </template>
      </v-img>
    </div>
    <template v-else>
      <v-tooltip right max-width="250px" v-if="slide.outline">
        <template v-slot:activator="{ on }">
          <v-img
            v-on="on"
            :src="slide.thumbnail"
            alt
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            :aspect-ratio="16 / 9"
            @click="onClick"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
              </v-row>
            </template>
          </v-img>
        </template>
        <span class="tooltip-outline-title">{{ slide.outline }}</span>
      </v-tooltip>
      <v-img
        v-else
        :src="slide.thumbnail"
        alt
        lazy-src="/assets/img/slides/placeholder-slide.svg"
        :aspect-ratio="16 / 9"
        @click="onClick"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
          </v-row>
        </template>
      </v-img>
    </template>
    <div
      class="slide-controls"
      v-if="!isViewAccess && !isTemplateRemoved && !isOpPrezentation"
    >
      <div :class="`drag-icon`">
        <v-icon dense> mdi-dots-vertical </v-icon>
        <v-icon dense> mdi-dots-vertical </v-icon>
      </div>
      <div class="actions">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="onDuplicate">
              mdi-content-copy
            </v-icon>
          </template>
          <span> {{ $t('common.duplicate') }} </span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" size="18" v-on="on" @click="onDelete">
              mdi-delete-outline
            </v-icon>
          </template>
          <span> {{ $t('common.delete') }} </span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';

export default {
  components: {
    ClipLoader,
  },
  props: {
    isViewAccess: {
      type: Boolean,
      default: false,
    },
    isTemplateRemoved: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isMatchedSlide: {
      type: Boolean,
      default: false,
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
    onClick: {
      type: Function,
      default: () => ({}),
    },
    onDuplicate: {
      type: Function,
      default: () => ({}),
    },
    onDelete: {
      type: Function,
      default: () => ({}),
    },
    serialNumber: {
      type: Number,
      default: 0,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
    isOpPrezentation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('prezentationDetails', ['animationsData']),
    getimageClass() {
      if (this.isSelected) {
        return 'selected';
      }
      if (this.isMatchedSlide) {
        return 'matched-slide';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.left-side-item {
  border-radius: 8px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%), -1px -1px 4px rgb(0 0 0 / 10%);
  cursor: pointer;
  // margin-bottom: 15px;
  overflow: hidden;
  width: 100%;
  &.selected {
    border: 2px solid $zen-blue-default;
  }
  &.matched-slide {
    border: 2px solid #fea93d;
  }

  ::v-deep .title-text::after {
    content: '' !important;
    display: none !important;
  }

  .slide-controls {
    display: none;
    justify-content: space-between;

    .drag-icon {
      cursor: move;
      display: flex !important;
      padding: 4px 8px;

      .v-icon {
        width: 5px;
        display: flex;
      }
    }
    .actions {
      display: flex !important;
      padding: 4px 8px;

      .v-icon:hover {
        color: #21a7e0 !important;
      }
    }
  }

  &:hover {
    .slide-controls {
      display: flex;
    }
  }

  .serial-number {
    margin-left: -20px;
    z-index: 10;
    position: absolute;
  }
}
.tooltip-outline-title {
  font-weight: bold;
}
</style>
