import { trackCommonEvent } from './MatomoTrackingDataHelper';

const SYNTHESIS_EVENT_CATEGORY = 'Synthesis';
const SYNTHESIS_ICON_CLICK = 'synthesis_icon-click';
const SYNTHESIS_UPLOAD = 'synthesis_upload';
const SYNTHESIS_UPLOAD_SYNTHESIZE = 'synthesis_upload_synthesize';
const SYNTHESIS_UPLOAD_SYNTHESIZE_NEXT = 'synthesis_upload_synthesize_Next';
const SYNTHESIS_UPLOAD_SYNTHESIZE_NEXT_SYNTHESIZE =
  'synthesis_upload_synthesize_Next_synthesize';
const SYNTHESIS_AUDIENCE_CHANGED = 'synthesis_audience-changed';
const SYNTHESIS_RESYNTHESIZE = 'synthesis_resynthesize';
const SYNTHESIS_TUTORIAL_CLICK = 'synthesis_tutorial-icon-click';
const SYNTHESIS_FEEDBACK_CLICK = 'synthesis_feedback-click';
const SYNTHESIS_FEEDBACK_SUBMITTED = 'synthesis_feedback-submitted';
const SYNTHESIS_UPLOAD_SYNTHESIZE_CANCEL = 'synthesis_upload_synthesize_cancel';
const SYNTHESIS_UPLOAD_SYNTHESIZE_NEXT_CANCEL =
  'synthesis_upload_synthesize_Next_cancel';
const SYNTHESIS_SMART_TOOLS_CLICK = 'synthesis_smart-tools-click';
const SYNTHESIS_DOWNLOAD = 'synthesis_download';
const SYNTHESIS_VISUALS_REPLACED = 'synthesis_visuals-replaced';
const SYNTHESIS_LAYOUT_CHANGED = 'synthesis_layout-changed';
const SYNTHESIS_TEMPLATE_SWITCHED = 'synthesis_template-switched';
const SYNTHESIS_NODE_EDITED = 'synthesis_node-edited';
const SYNTHESIS_EMOTION_CHANGED = 'synthesis_emotion-changed';
const SYNTHESIS_TONE_CHANGED = 'synthesis_tone-changed';
const SYNTHESIS_FORMAT_CHANGED = 'synthesis_format-changed';
const SYNTHESIS_TUTORIAL_LOAD = 'synthesis_tutorial-icon_tutorial-click';
const SYNTHESIS_SHOW_CURRENT_SLIDE = 'Synthesis_show-current-slide';
const SYNTHESIS_SHOW_EXECUTIVE_SUMMARY = 'Synthesis_show-executive-summary';
const SYNTHESIS_MAXIMIZE_MINIMIZE_CLICK = 'Synthesis_maximize-minimize-click';
const SYNTHESIS_FAVORITE_CLICK = 'Synthesis_favorite-click';
const SYNTHESIS_ADD_TO_SLIDELIBRARY = 'Synthesis_add-to-slidelibrary';
const SYNTHESIS_UPLOAD_SYNTHESIZE_FAILED = 'synthesis_upload_synthesize_failed';
const SYNTHESIS_MODIFY_DATASTYLE_CLICK = 'Synthesis_modify-datastyle_click';
const SYNTHESIS_MODIFY_DATASTYLE_SELECTED =
  'Synthesis_modify-datastyle_selected';
const SYNTHESIS_USER_SWITCHED = 'Synthesis_user-switched';

export const trackSynthesisEvents = {
  synthesisIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUpload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUploadSynthesize(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD_SYNTHESIZE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUploadSynthesizeNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD_SYNTHESIZE_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUploadSynthesizeNextSynthesize(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD_SYNTHESIZE_NEXT_SYNTHESIZE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisAudienceChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_AUDIENCE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisResynthesise(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_RESYNTHESIZE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisTutorialClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_TUTORIAL_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisTutorialLoad(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_TUTORIAL_LOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisFeedbackClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_FEEDBACK_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisFeedbackSubmitted(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_FEEDBACK_SUBMITTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUploadSynthesizeCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD_SYNTHESIZE_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUploadSynthesizeNextCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD_SYNTHESIZE_NEXT_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisSmartToolsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_SMART_TOOLS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisVisualsReplaced(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_VISUALS_REPLACED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisLayoutChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_LAYOUT_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisTemplateSwitched(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_TEMPLATE_SWITCHED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisNodeEdited(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_NODE_EDITED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisEmotionChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_EMOTION_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisToneChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_TONE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisFormatChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_FORMAT_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisShowCurrentSlide(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_SHOW_CURRENT_SLIDE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisShowExecutiveSummary(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_SHOW_EXECUTIVE_SUMMARY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisMinimizeMaximizeClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_MAXIMIZE_MINIMIZE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisAddToFavourite(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_FAVORITE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisAddToSlideLibrary(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_ADD_TO_SLIDELIBRARY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUploadSynthesizeFailed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_UPLOAD_SYNTHESIZE_FAILED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisModifyDatastyleClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_MODIFY_DATASTYLE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisModifyDatastyleSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_MODIFY_DATASTYLE_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  synthesisUserTabSwitch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SYNTHESIS_EVENT_CATEGORY,
        eventAction: SYNTHESIS_USER_SWITCHED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
