<template>
  <div class="template-complete-popup-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="template-complete-popup-wrapper-content">
      <div class="template-complete-popup-image-wrapper">
        <img
          src="/assets/img/templateDesign/template-ready.svg"
          alt
          width="342px"
          height="276px"
        />
      </div>
      <div class="template-complete-popup-desc">
        <p v-if="!isTemplateSelected">
          {{
            $t('templateDesign.templateReady', {
              templateName: getTemplateName,
            })
          }}
        </p>

        {{
          isTemplateSelected
            ? $t('templateDesign.templateUpdated')
            : $t('templateDesign.checkItOut')
        }}
      </div>
      <div class="button-container">
        <v-btn @click="handleSelectTemp" elevation="3" class="next">
          {{
            isTemplateSelected
              ? $t('templateDesign.gotIt')
              : $t('templateDesign.selectTemplate')
          }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import usersApi from '../../API/users-api';
import { TemplateDesignerService } from '../../pages/components/TemplateDesigner/TemplateDesignerService.js';

export default {
  name: 'TemplateCompletePopup', // Shown in Main [home page] when only the first designer template is completed,
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    ...mapGetters('templateDesigner', ['getTDData', 'getFirstTDData']),
    getTemplateName() {
      return this.getFirstTDData.name ? this.getFirstTDData.name : '';
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  data() {
    return {
      isTemplateSelected: false,
      firstTDData: {},
    };
  },
  methods: {
    ...mapActions('users', ['setCurrentTheme', 'setCurrentUser']),
    ...mapActions('templateDesigner', ['setTDData']),
    handleClose() {
      this.handleUpdateState();
      this.$modal.hide('TemplateCompletePopup');
    },
    handleUpdateState() {
      // Updating the popup flag in state
      const updatedTDData = this.getTDData.map((item) => {
        if (item.uuid === this.firstTDData.uuid) {
          item = {
            ...item,
            banners: {
              ...item.banners,
              templateCompletionPopup: 1,
            },
          };
        }
        return item;
      });
      this.setTDData(updatedTDData);
    },
    async handleSelectTemp() {
      if (this.isTemplateSelected === true) {
        this.handleUpdateState();

        this.$router.push(`/home/slides`, () => {});
        this.handleClose();
      } else {
        const theme = this.getFirstTDData;
        try {
          await usersApi.methods
            .updateUserProfile({ defaultTheme: theme.code })
            .then(async (updatedUserDetail) => {
              const userInfo = this.currentUser;
              userInfo.user = { ...userInfo.user, ...updatedUserDetail };
              userInfo.theme = theme;
              await this.setCurrentUser(userInfo);
              this.isTemplateSelected = true;
            });
          this.hideTemplateCompletionBanner();
          this.setCurrentTheme(theme);
        } catch (err) {
          console.error(err);
        }
      }
    },
    hideTemplateCompletionBanner() {
      // To hide the banner that has an option to select first template
      const updatedTDData = this.getTDData.map((item) => {
        if (item.uuid === this.firstTDData.uuid) {
          item = {
            ...item,
            banners: {
              ...item.banners,
              templateCompletionBanner: 1,
            },
          };
        }
        return item;
      });
      this.templateDesignerService.updateBanner({
        uuid: this.firstTDData.uuid,
        bannerType: 'templateCompletionBanner',
      });
      this.setTDData(updatedTDData);
    },
  },
  mounted() {
    this.firstTDData = { ...this.getFirstTDData };
    // Updating flag in designer theme instead of old approach of user object
    this.templateDesignerService.updateBanner({
      uuid: this.getFirstTDData?.uuid,
      bannerType: 'templateCompletionPopup',
    });
  },
};
</script>

<style lang="scss" scoped>
.template-complete-popup-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .template-complete-popup-wrapper-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .template-complete-popup-image-wrapper {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    width: fit-content;
  }

  .template-complete-popup-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 11px;
  }

  .desc {
    margin-bottom: 15px;
  }

  .template-complete-popup-desc {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    p {
      margin-bottom: 10px;
    }
  }
}

.button-container {
  padding: 20px;
  margin: 5px;
  .next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 22px;
    gap: 8px;

    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}
</style>
