import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import { getMatomoEventName, getSlideData } from './MatomoTrackingDataHelper';

const ICON_REPLACEMENT_CATEGOTY = 'Icon replacement';
const ICON_REPLACEMENT_CLICK_ON_REPLACE_ICON =
  'Icon-replacement_click-on-replace-icon';
const ICON_REPLACEMENT_SUCCESS = 'Icon-replacement_success';
const ICON_REPLACEMENT_RESETTODEFAULT = 'Icon-replacement_Resettodefault';
const ICON_REPLACEMENT_ICON_SEARCH = 'Icon-replacement_icon-search';

export const trackIconReplacementEvents = {
  iconReplacementClickOnReplaceIcon(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        ICON_REPLACEMENT_CATEGOTY,
        ICON_REPLACEMENT_CLICK_ON_REPLACE_ICON,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  iconReplacementSuccess(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        ICON_REPLACEMENT_CATEGOTY,
        ICON_REPLACEMENT_SUCCESS,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  iconReplacementResettodefault(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        ICON_REPLACEMENT_CATEGOTY,
        ICON_REPLACEMENT_RESETTODEFAULT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  iconReplacementIconSearch(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        ICON_REPLACEMENT_CATEGOTY,
        ICON_REPLACEMENT_ICON_SEARCH,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
};
