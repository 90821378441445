import { render, staticRenderFns } from "./CoursesIcon.vue?vue&type=template&id=1071a47a&scoped=true&"
import script from "./CoursesIcon.vue?vue&type=script&lang=js&"
export * from "./CoursesIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1071a47a",
  null
  
)

export default component.exports