var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.componentKey,staticClass:"slider-wrapper mx-4 my-2"},[(_vm.title)?_c('div',{staticClass:"slider-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"content-wrapper row"},_vm._l((_vm.filteredPrezentations),function(presentation,index){return _c('div',{key:index,staticClass:"col-sm-3 carousel-cell"},[_c('div',{staticClass:"image-wrapper"},[_c('v-img',{attrs:{"src":presentation.thumbnail ||
            '/assets/img/slides/placeholder-slide.svg',"alt":"image","min-width":"200","aspect-ratio":16 / 9},on:{"click":function($event){return _vm.handlePrezentationClick(presentation)}}}),(_vm.editable)?_c('div',{staticClass:"additional-options"},[_c('div',[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","id":"edit-presentation--auto"},on:{"click":function($event){return _vm.handleEdit(index)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Rename")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","id":"duplicate-presentation--auto"},on:{"click":function($event){return _vm.handleDuplicate(index)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","id":"delete-presentation--auto"},on:{"click":function($event){return _vm.handleDelete(index)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]):_vm._e()],1),(_vm.editingIndex === index)?_c('v-text-field',{staticClass:"edit",attrs:{"value":presentation.name,"autofocus":""},on:{"change":_vm.handleTextChange}}):_c('div',{staticClass:"prezentation-title"},[_vm._v(" "+_vm._s(presentation.name)+" ")]),_c('div',{staticClass:"type"},[_c('strong',[_vm._v("Created On:")]),_vm._v(" "+_vm._s(new Date(presentation.createdAt).toLocaleDateString())+" ")]),_c('div',{staticClass:"type"},[_c('strong',[_vm._v("Type:")]),_vm._v(" "+_vm._s(presentation.type)+" ")]),(!_vm.editable && presentation.ownerID)?_c('div',{staticClass:"creator"},[_c('strong',[_vm._v("Creator:")]),_vm._v(" "+_vm._s(presentation.owner.firstName)+" "+_vm._s(presentation.owner.lastName)+" ")]):_vm._e(),(presentation.countNewComments)?_c('div',{staticClass:"comment-wrapper"},[_c('div',{staticClass:"comment-icon"}),_c('div',{staticClass:"comment-content"},[_vm._v(" "+_vm._s(presentation.countNewComments > 1 ? ((presentation.countNewComments) + " New Comments") : ((presentation.countNewComments) + " New Comment"))+" ")])]):_vm._e()],1)}),0),(_vm.filteredPrezentations.length < _vm.prezentations.length)?_c('div',{staticClass:"slides-action"},[_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"#21a7e0","height":"32"},on:{"click":_vm.handleLoadMorePrezentations}},[_vm._v(" See more results ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }