<template>
  <div class="text-center">
    <v-dialog
      width="auto"
      :no-click-animation="true"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="isOpen"
      v-if="requiredDataLoaded"
      :persistent="true"
      style="overflow-y: unset !important"
    >
      <v-card
        class="video-content-section"
        height="600px !important; overflow-y: unset !important"
      >
        <div class="close-icon-section d-flex justify-end pb-1">
          <v-icon
            color="#000"
            style="cursor: pointer"
            @click="handleOutSideClickFn('closed from button')"
          >
            mdi-close
          </v-icon>
        </div>
        <v-card-text style="padding-bottom: 10px">
          <video
            class="video-js vjs-default-skin video-content-section__video-player"
            data-setup="{'fluid': true}"
            ref="video"
            height="100%"
            controls
            @volumechange="updateVolume"
          />
          <v-btn
            class="ma-2 skip-intro-btn"
            outlined
            @click="skipIntro"
            v-if="!skipIntroClicked"
          >
            {{ $t('learn.novice.skipIntro') }}
          </v-btn>
        </v-card-text>

        <v-card-actions class="video-footer-section">
          <!-- <div class="video-footer-section__like-dislike">
            <v-btn @click="updateUserAction('like')" icon>
              <v-icon>mdi-thumb-up</v-icon>
              {{ videoDetail.asset.likes }}
            </v-btn>
            <v-btn @click="updateUserAction('dislike')" icon>
              <v-icon>mdi-thumb-down</v-icon>
              {{ videoDetail.asset.dislikes }}
            </v-btn>
          </div> -->
          <div class="video-footer-section__description">
            {{ videoDetail.display_name }}
          </div>
          <div class="video-footer-section__views">
            {{ getViews }}
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-else>
      <v-row
        align="center"
        justify="center"
        v-if="loading"
        class="mt-9"
        style="height: 800px"
      >
        <LoadSpinner
          :id="'spinner'"
          :text="'Loading...'"
          :size="'40'"
          :bgcolor="'lightgray'"
          :speed="'0'"
          :color="'#21a7e0'"
          class="loadspinner"
        />
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Hls from 'hls.js';
import LoadSpinner from './LoadSpinner.vue';
import learnApi from '../../API/learn-api';
import { MatomoAnalyticsHandler } from './Analytics/MatomoAnalyticsHandler';
import { trackLearnEvents } from './Analytics/LearnEvents';
import usersApi from '../../API/users-api.js';

export default {
  name: 'VideoPlayer',
  components: {
    LoadSpinner,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    videoDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      percentageOfCompletion: 0,
      videoDescription:
        'Brief description of this video. Gives additional detail so people can decide if they want to watch. ',
      skipIntroClicked: false,
      hls: new Hls(),
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['courseInfoForMatotmo']),
    getViews() {
      return `${this.videoDetail.asset.views} ${
        this.videoDetail.asset.views === 1 ? 'View' : 'Views'
      }`;
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    updateVolume() {
      usersApi.methods
        .updateUserProfile({
          volume: this.$refs.video.volume,
          volumeMuted: this.$refs.video.muted,
        })
        .then(() => {
          this.setCurrentUser({
            ...this.currentUser,
            user: {
              ...this.currentUser.user,
              volume: this.$refs.video.volume,
              volumeMuted: this.$refs.video.muted,
            },
          });
        });
    },
    handleOutSideClickFn(origin) {
      this.stopStreamingVideo();
      // update percentage only if the user haven't already completed the asset.
      const updateStatus = {
        asset_id: this.videoDetail.id,
        status: this.percentageOfCompletion,
      };
      this.$emit('modalclosed', {
        uStatus: this.videoDetail.status !== 100 ? updateStatus : {},
        videoClosedOrCompleted:
          this.percentageOfCompletion === 100 ? 'completed' : 'closed',
      });
      if (origin === 'closed from button') {
        MatomoAnalyticsHandler.learnVideoClose(
          this.courseInfoForMatotmo,
          this.currentUser,
        );
        trackLearnEvents.learnVideoClose(this.currentUser, {
          ...this.courseInfoForMatotmo,
          learnVideoLength: this.videoDetail?.duration_seconds,
        });
      }
    },
    stopStreamingVideo() {
      this.hls.destroy();
    },
    skipIntro() {
      this.skipIntroClicked = true;
      const { video } = this.$refs;
      video.currentTime = this.videoDetail.asset.intro_end_seconds;
    },
    skipOutro() {
      const { video } = this.$refs;
      video.currentTime = 50;
    },
    introCompleted() {
      const { video } = this.$refs;
      if (video) {
        console.log(Math.round(video.currentTime));
        return (
          Math.round(video.currentTime) >
          this.videoDetail.asset.intro_end_seconds
        );
      }
      return false;
    },
    durationChange(event) {
      console.log(
        'Not sure why, but the duration of the video has changed. -- ',
        event,
      );
    },

    requiredDataLoaded() {
      return this.videoDetail !== undefined;
    },

    updateUserAction(type) {
      let updateInfo = { asset_id: `${this.videoDetail.id}` };
      switch (type) {
        case 'like':
          updateInfo = { ...updateInfo, ...{ affinity: 1 } };
          break;
        case 'dislike':
          updateInfo = { ...updateInfo, ...{ affinity: -1 } };
          break;
        case 'view':
          updateInfo = { ...updateInfo, ...{ views_incrementer: 1 } };
          break;
        default:
          break;
      }
      learnApi.methods.updateAssetUserStatus(updateInfo).then(
        (resp) => {
          if (resp) {
            switch (type) {
              case 'like':
                this.$emit('likeupdated', this.videoDetail.id);
                break;
              case 'dislike':
                this.$emit('dislikeupdated', this.videoDetail.id);
                break;
              case 'view':
                this.$emit('viewupdated', this.videoDetail.id);
                break;
              default:
                break;
            }
          }
        },
        (error) => {
          console.log('error while updating the status --', error);
        },
      );
    },

    trackProgressBarSeek(
      video,
      videoDetail,
      courseInfoForMatotmo,
      currentUser,
    ) {
      return _.debounce(() => {
        const currentTime = Math.floor(video.currentTime);
        const courseDetailData = {
          ...courseInfoForMatotmo,
          learnVideoSeekBarTime: currentTime,
          learnVideoLength: this.videoDetail?.duration_seconds,
        };

        if (currentTime === videoDetail.asset.intro_end_seconds) {
          MatomoAnalyticsHandler.learnVideoSkipIntro(
            courseInfoForMatotmo,
            currentUser,
          );
          trackLearnEvents.learnVideoSkipIntro(currentUser, courseDetailData);
        } else if (currentTime === 0 && !video.played?.length) {
          MatomoAnalyticsHandler.learnVideoSeekBarEndClick(
            courseInfoForMatotmo,
            currentUser,
          );
          trackLearnEvents.learnVideoSeekBarEndClick(
            currentUser,
            courseDetailData,
          );
        } else {
          MatomoAnalyticsHandler.learnVideoSeekBarClick(
            courseInfoForMatotmo,
            currentUser,
          );
          trackLearnEvents.learnVideoSeekBarClick(
            currentUser,
            courseDetailData,
          );
        }
      }, 600);
    },
  },
  mounted() {
    const stream = `${this.videoDetail.asset.video}`;
    const { video } = this.$refs;
    this.hls.loadSource(stream);
    this.hls.attachMedia(video);
    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      video.play();
    });
    this.hls.startLoad();
    this.$refs.video.volume = this.currentUser.user.volume || 1;
    this.$refs.video.muted = this.currentUser.user.volumeMuted;

    video.addEventListener('timeupdate', () => {
      if (
        (this.skipIntroClicked &&
          Math.round(video.currentTime) ===
            this.videoDetail.asset.content_end_seconds) ||
        Math.round(video.currentTime) >
          this.videoDetail.asset.content_end_seconds
      ) {
        this.percentageOfCompletion = 100;
      }
      if (
        Math.round(video.currentTime) > this.videoDetail.asset.intro_end_seconds
      ) {
        this.skipIntroClicked = true;
      }
      if (video.ended) {
        this.percentageOfCompletion = 100;
        this.handleOutSideClickFn('');
      }
    });

    video.addEventListener('ended', () => {
      this.handleOutSideClickFn('');
    });

    video.addEventListener(
      'seeking',
      this.trackProgressBarSeek(
        video,
        this.videoDetail,
        this.courseInfoForMatotmo,
        this.currentUser,
      ),
    );

    this.updateUserAction('view');
  },
  beforeDestroy() {
    this.hls.destroy();
  },
};
</script>
<style lang="scss" scoped>
.video-content-section {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 #68788c;
  padding-top: 20px;
  &__video-player {
    border-radius: 8px;
    height: 500px;
  }
}
.close-icon-section {
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
}

.video-footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-left: 24px !important;
  padding-right: 24px !important;
  min-height: 50px;

  &__like-dislike {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100px;

    p {
      margin-bottom: 0;
    }
  }

  &__description {
    margin-left: 0px;
    font-family: 'Roboto', 'sans-serif';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #68788c;
  }

  &__views {
    font-family: 'Roboto', 'sans-serif';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #68788c;
  }
}

.skip-intro-btn {
  background: white;
  right: 4%;
  position: absolute;
  top: 70%;
  z-index: 1;
  min-width: 157px;
  height: 50px;
  margin: 11px 0 19px 96px;
  padding: 11px 26px 10px 27px;
  border-radius: 25px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  border: solid 2px #fff;
  // color: #fff !important;
  text-transform: unset !important;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.controls .progress {
  cursor: pointer;
  width: 75.390625%;
}
</style>
