import {
  TD_FP_MY,
  TD_FP_NEW_AUDIENCE,
  TD_FP_OTHER,
  TD_FP_PROFILE,
  TD_FP_RETAKE,
  TD_PLACEMENT,
  TD_REMINDER,
  TD_RETAKE_FINGERPRINT_TEST,
  TD_SHARE_FINGERPRINT_ZENTRENDS,
  trackCommonEvent,
  trackNewUserEvents,
} from './MatomoTrackingDataHelper';

const FINGERPRINTS_EVENT_CATEGORY = 'Fingerprints';

const FINGERPRINTS_ICONCLICK = 'Fingerprints_iconclick';
const FINGERPRINTS_COMPANY_RADIOBUTTONCLICK =
  'Fingerprints_company_radiobuttonclick';

const FINGERPRINTS_GLOBAL_RADIOBUTTONCLICK =
  'Fingerprints_Global_radiobuttonclick';
const FINGERPRINTS_TEAM_RADIOBUTTONCLICK = 'Fingerprints_Team_radiobuttonclick';
const FINGERPRINTS_LEARN_MORE_ABOUT_FINGERPRINTS_CLICK =
  'Fingerprints_Learn-more-about-fingerprints_click';
const FINGERPRINTS_INDIVIDUAL_FINGERPINT_CLICK =
  'Fingerprints_individual-Fingerpint_click';
const FINGERPRINTS_INDIVIDUAL_FINGERPINT_PLAY =
  'Fingerprints_individual-Fingerpint_play';
const FINGERPRINTS_ADDAUDIENCE_SELECTED = 'Fingerprints_addaudience_selected';
const FINGERPRINTS_COMPAREFG_COMPARE_BUTTONCLICK =
  'Fingerprints_comparefg_compare_buttonclick';
const FINGERPRINTS_COMPAREFG_SHOW_MORE_CLICK =
  'Fingerprints_comparefg_show-more_click';
const FINGERPRINTS_COMPAREFG_GENERATE_GROUP_INISGHTS_CLICK =
  'Fingerprints_comparefg_Generate-group-inisghts_click';
const FINGERPRINTS_GROUPINSIGHTS_GENERATE_INSIGHTS =
  'Fingerprints_groupinsights_generate-insights';
const FINGERPRINTS_GROUPINSIGHTS_GENERATE_INSIGHTS_GENERATE_FINGERPRINT =
  'Fingerprints_groupinsights_generate-insights_Generate-Fingerprint';
const FINGERPRINTS_COMPAREFG_GROUP_INISGHTS_RESET =
  'Fingerprints_comparefg_group-inisghts_Reset';
const FINGERPRINTS_GROUPINSIGHTS_EDIT_MEMBERS =
  'Fingerprints_groupinsights_Edit-Members';
const FINGERPRINTS_SCREEN_ELIPSIS_BUILD = 'Fingerprint_screen_elipsis_Build';
const FINGERPRINTS_SCREEN_ELIPSIS_SLIDES = 'Fingerprint_screen_elipsis_Slides';
const FINGERPRINTS_SCREEN_ELIPSIS_COMPARE =
  'Fingerprint_screen_elipsis_compare';
const FINGERPRINTS_SCREEN_ELIPSIS_LEARN_MORE =
  'Fingerprint_screen_elipsis_Learn-more';
const FINGERPRINTS_SCREEN_VIEW_INSIGHTS = 'Fingerprint_screen_view-insights';
const FINGERPRINT_SCREEN_BACK_TO_FEATURE = 'Fingerprint_Screen_Back_to_feature';
const FINGERPRINTS_MY_FINGERPRINT_OWN_FINGERPRINT_CLICK =
  'Fingerprints_MyFingerprint_own-Fingerprint_click';
const FINGERPRINTS_MY_FINGERPRINT_SIMILAR_FINGERPRINTS_SELECTED =
  'Fingerprints_MyFingerprint_similar-fingerprints_selected';
const FINGERPRINTS_MY_FINGERPRINT_SIMILAR_FINGERPRINTS_DROPDWON_SELECTED =
  'Fingerprints_MyFingerprint_similar-fingerprints-dropdown_selected';
const FINGERPRINTS_MY_FINGERPRINT_EDIT_PERSONAL_PREFERENCES =
  'Fingerprints_Edit-Personal-Preferences';
const FINGERPRINTS_MY_FINGERPRINT_SHARE_MY_FINGERPRINT_UPDATE =
  'Fingerprints_MyFingerprint_share-my-fingerprint_update';
const FINGERPRINTS_MY_FINGERPRINT_RETAKE_FINGERPRINT_TEST =
  'Fingerprints_MyFingerprint_Retake-Fingerprint-test';
const FINGERPRINTS_MY_FINGERPRINT_GO_TO_PROFILE =
  'Fingerprints_My Fingerprint_Go-to-Profile';
const FINGERPRINTS_SHARE_ON_LINKEDIN = 'Fingerprint_Share-on-LinkedIn';

const fpPlacementMap = {
  [TD_FP_NEW_AUDIENCE]: 'Addnewaudience',
  [TD_FP_MY]: 'My Fingerprint',
  [TD_FP_PROFILE]: 'Go to profile',
  [TD_SHARE_FINGERPRINT_ZENTRENDS]: 'Clicking on other profile',
  [TD_FP_OTHER]: 'Clicking on other profile',
  [TD_REMINDER]: 'Reminder popup',
  [TD_RETAKE_FINGERPRINT_TEST]: TD_FP_RETAKE,
};

export const trackFingerprintEvents = {
  fingerprintsIconclick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  fingerprintsRadiobuttonclick(currentUser, radioButtonType, otherData) {
    try {
      let eventAction = '';
      switch (radioButtonType) {
        case 'global':
          eventAction = FINGERPRINTS_GLOBAL_RADIOBUTTONCLICK;
          break;
        case 'company':
          eventAction = FINGERPRINTS_COMPANY_RADIOBUTTONCLICK;
          break;
        case 'team':
          eventAction = FINGERPRINTS_TEAM_RADIOBUTTONCLICK;
          break;
        default:
          break;
      }
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsLearnMoreAboutFingerprintsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_LEARN_MORE_ABOUT_FINGERPRINTS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsIndividualFingerpintClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_INDIVIDUAL_FINGERPINT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsIndividualFingerpintPlay(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_INDIVIDUAL_FINGERPINT_PLAY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsAddaudienceSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_ADDAUDIENCE_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },

  fingerprintsComparefgCompareButtonclick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_COMPAREFG_COMPARE_BUTTONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsComparefgShowMoreClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_COMPAREFG_SHOW_MORE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsComparefgGenerateGroupInisghtsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_COMPAREFG_GENERATE_GROUP_INISGHTS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsGroupinsightsGenerateInsights(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_GROUPINSIGHTS_GENERATE_INSIGHTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsGroupinsightsGenerateInsightsGenerateFingerprint(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction:
          FINGERPRINTS_GROUPINSIGHTS_GENERATE_INSIGHTS_GENERATE_FINGERPRINT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsComparefgGroupInisghtsReset(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_COMPAREFG_GROUP_INISGHTS_RESET,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsGroupinsightsEditMembers(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_GROUPINSIGHTS_EDIT_MEMBERS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsScreenElipsisBuild(currentUser, origin, otherData) {
    try {
      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: fpPlacementMap[origin],
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_SCREEN_ELIPSIS_BUILD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsScreenElipsisSlides(currentUser, origin, otherData) {
    try {
      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: fpPlacementMap[origin],
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_SCREEN_ELIPSIS_SLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsScreenElipsisCompare(currentUser, origin, otherData) {
    try {
      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: fpPlacementMap[origin],
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_SCREEN_ELIPSIS_COMPARE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsScreenElipsisLearnMore(currentUser, origin, otherData) {
    try {
      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: fpPlacementMap[origin],
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_SCREEN_ELIPSIS_LEARN_MORE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsScreenViewInsights(currentUser, origin, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_SCREEN_VIEW_INSIGHTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintScreenBackToFeature(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINT_SCREEN_BACK_TO_FEATURE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintOwnFingerprintClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_MY_FINGERPRINT_OWN_FINGERPRINT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintSimilarFingerprintsSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_MY_FINGERPRINT_SIMILAR_FINGERPRINTS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintSimilarFingerprintsDropdwonSelected(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction:
          FINGERPRINTS_MY_FINGERPRINT_SIMILAR_FINGERPRINTS_DROPDWON_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintEditPersonalPreferences(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_MY_FINGERPRINT_EDIT_PERSONAL_PREFERENCES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsShareOnLinkedIn(currentUser, otherData) {
    try {
      if (currentUser) {
        trackCommonEvent({
          currentUser,
          otherData,
          eventCategory: FINGERPRINTS_EVENT_CATEGORY,
          eventAction: FINGERPRINTS_SHARE_ON_LINKEDIN,
        });
      } else {
        trackNewUserEvents({
          otherData,
          eventCategory: FINGERPRINTS_EVENT_CATEGORY,
          eventAction: FINGERPRINTS_SHARE_ON_LINKEDIN,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintShareMyFingerprintUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_MY_FINGERPRINT_SHARE_MY_FINGERPRINT_UPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintRetakeFingerprintTest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_MY_FINGERPRINT_RETAKE_FINGERPRINT_TEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  fingerprintsMyFingerprintGoToProfile(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: FINGERPRINTS_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_MY_FINGERPRINT_GO_TO_PROFILE,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
