var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-layout-container",attrs:{"data-pendo-id":'redesign-change-layout'}},[_c('div',{staticClass:"heading",style:({
      marginBottom: '8px',
    })},[_vm._v(" "+_vm._s(_vm.$t('generate.smartLayout'))+" "),_c('div',{staticClass:"sub"},[_vm._v(_vm._s(_vm.$t('build.step3.poweredByZenSence')))])]),_c('div',{staticClass:"sub-heading"},[_vm._v(_vm._s(_vm.$t('generate.slideWithDifferentLayout')))]),_c('v-tabs',{attrs:{"color":"#21a7e0","height":"57","fixed-tabs":"","hide-slider":true},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{staticClass:"vtab",style:(_vm.currentTab === 0 ? { 'border-bottom': '2px solid #21a7e0' } : {}),attrs:{"hide-slider":true}},[_vm._v(" "+_vm._s('Redesigned Layouts')+" ")]),_c('v-tab',{staticClass:"vtab",style:(_vm.currentTab === 1 ? { 'border-bottom': '2px solid #21a7e0' } : {}),attrs:{"hide-slider":true}},[_vm._v(" "+_vm._s('Reformatted Layouts')+" ")]),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{attrs:{"value":0}},[_c('RedesignChangeLayoutViews',{attrs:{"slides":_vm.selectedSlide && _vm.selectedSlide.redesignedSlides
              ? _vm.selectedSlide.redesignedSlides.slides
              : [],"loading":_vm.selectedSlide && _vm.selectedSlide.redesignedSlides
              ? _vm.selectedSlide.redesignedSlides.loading
              : true,"type":'redesigned',"selectedSlideID":_vm.selectedSlideID},on:{"change":_vm.handleChangeLayout}})],1),_c('v-tab-item',{attrs:{"value":1}},[_c('RedesignChangeLayoutViews',{attrs:{"slides":_vm.selectedSlide && _vm.selectedSlide.reformattedSlides
              ? _vm.selectedSlide.reformattedSlides.slides
              : [],"loading":_vm.selectedSlide && _vm.selectedSlide.reformattedSlides
              ? _vm.selectedSlide.reformattedSlides.loading
              : true,"type":'reformatted',"selectedSlideID":_vm.selectedSlideID},on:{"change":_vm.handleChangeLayout}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }