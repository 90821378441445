<template>
  <div class="filter-sort-wrapper">
    <v-tabs color="#21a7e0" height="40" grow>
      <v-tab class="vtab"
        ><span>{{ $t('prezentationsList.advancedFilters') }}</span></v-tab
      >
      <v-tab class="vtab"
        ><span>{{ $t('bestPracticesGuide.knowContainer.sortBy') }}</span></v-tab
      >

      <v-tab-item>
        <div class="advanced-filter-wrapper">
          <div
            :class="
              !isAdvancedFilterChanged ? 'resetFilters' : 'disabledResetFilters'
            "
          >
            <div
              class="resetText"
              @click="
                !isAdvancedFilterChanged ? resetAdvancedFilters(true) : null
              "
            >
              {{ $t('prezentationsList.resetAllFilters') }}
            </div>
          </div>
          <v-expansion-panels accordion multiple v-model="activePanels">
            <v-expansion-panel
              v-for="item in advancedItems"
              :key="item.title"
              expand
            >
              <v-expansion-panel-header>
                <div class="exp-filter-header">
                  <img
                    class="filter-icon"
                    alt
                    :src="`/assets/img/${item.icon}.svg`"
                    width="24"
                    height="24"
                  />
                  <div>{{ $t(item.display_title) }}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item
                  dense
                  v-for="child in item.items"
                  :key="child.title"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :on-icon="'mdi-checkbox-marked-outline'"
                        :off-icon="'mdi-checkbox-blank-outline'"
                        :input-value="active"
                        :disabled="isSearching"
                        v-model="child.active"
                        color="primary"
                        @click="onAdvancedFilerChange(item, child)"
                      />
                    </v-list-item-action>
                    <img
                      alt
                      :src="`/assets/img/filter-${child.icon}.svg`"
                      width="20"
                      height="20"
                      v-if="child.icon"
                      class="advanced-filter-icon"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-content-text"
                        v-text="$t(child.display_title)"
                      />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-tab-item>

      <v-tab-item>
        <v-radio-group
          v-model="sortByValue"
          @change="handleSortByChange()"
          column
        >
          <v-radio
            v-for="(option, index) in sortByList"
            :key="index"
            :label="option"
            :value="option"
            color="primary"
          />
        </v-radio-group>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import __ from 'lodash';
import { mapState } from 'vuex';
import EventBus from '../../event-bus';
import { trackKnowEvents } from '../../Analytics/KnowEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_PLACEMENT,
  TD_SECTION_CHANGED,
  TD_FROM_ADVANCED_FILTER_DECKS,
} from '../../Analytics/MatomoTrackingDataHelper';

export default {
  name: 'KnowDeckAdvancedFilters',
  data() {
    return {
      filterKey: 1,
      activePanels: [0, 1, 2],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isSearching: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.isSearching;
      },
      set() {},
    },
    advancedItemsOnLoad: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck
          .advancedItemsOnLoad;
      },
      set() {},
    },
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      },
      set() {},
    },
    sortByValue: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.sortByValue;
      },
      set(value) {
        this.$store.dispatch(
          'advancedFilterStore/setKnowDeckSortByValue',
          value,
        );
      },
    },
    sortByList: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.sortByList;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setToDateMenu', value);
      },
    },
    resetFilter: {
      get() {
        return this.$store.state.advancedFilterStore.knowDeck.resetFilter;
      },
      set() {},
    },
    isAdvancedFilterChanged() {
      const advItems = [...this.advancedItems].map((item) => ({
        ...item,
        active: true,
      }));
      const advItemsOnLoad = [...this.advancedItemsOnLoad].map((item) => ({
        ...item,
        active: true,
      }));

      return __.isEqual(advItems, advItemsOnLoad);
    },
  },
  methods: {
    handleSortByChange() {
      EventBus.$emit('KNOW_RESET_ADVANCED_FILTERS', false, 'tab-change');
      EventBus.$emit('HANDLE_KNOW_SORTING', this.sortByValue, true);
    },
    resetAdvancedFilters(value) {
      EventBus.$emit('KNOW_RESET_ADVANCED_FILTERS', value);
    },
    onAdvancedFilerChange(item, child) {
      this.sortByValue = 'Recommended';
      EventBus.$emit('HANDLE_KNOW_SORTING', 'Recommended', 'tab-change');
      this.filterKey++;
      trackKnowEvents.knowAdvanceFiltersSelected(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
        [TD_COMMON_COLUMN_VALUE]: item?.title,
        [TD_PLACEMENT]: TD_FROM_ADVANCED_FILTER_DECKS,
      });
      if (item.isFunction) {
        this.updateFuntionFilterCheckbox(child);
      }
      EventBus.$emit('KNOW_ADVANCE_FILTER_CHANGE');
    },
    updateFuntionFilterCheckbox(item) {
      if (item.title === 'All' && !item.active) {
        const foundItem = this.advancedItems.find((i) => i.isFunction);
        if (foundItem) {
          foundItem.items.forEach((itm) => {
            itm.active = false;
          });
        }
      }
      if (item.title === 'All' && item.active) {
        const foundItem = this.advancedItems.find((i) => i.isFunction);
        if (foundItem) {
          foundItem.items.forEach((itm) => {
            itm.active = true;
          });
        }
      }
      if (item.title !== 'All' && !item.active) {
        const foundItem = this.advancedItems.find((i) => i.isFunction);
        if (foundItem) {
          foundItem.items.forEach((itm) => {
            if (itm.title === 'All') itm.active = false;
          });
        }
      }
      if (item.title !== 'All' && item.active) {
        const foundItem = this.advancedItems.find((i) => i.isFunction);
        const removeAll = foundItem?.items.filter((itm) => itm.title !== 'All');
        const allActive = removeAll.every((itm) => itm.active);
        if (allActive) {
          foundItem.items.forEach((itm) => {
            if (itm.title === 'All') itm.active = true;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-filter-wrapper {
  .exp-filter-header {
    display: flex;
    align-items: center;
    gap: 5px;

    .filter-icon {
      width: 24px;
      height: 24px;
    }
  }

  .clear-text {
    color: #21a7e0 !important;
    cursor: pointer;
  }
}

.vtab {
  span {
    font-size: 16px;
    font-weight: 600;
    color: #4d5358;
    text-transform: none;
    letter-spacing: normal;
  }
}

.customGroupWrapper {
  margin-left: 30px;
  padding: 15px 15px 15px 0;

  .fromDateWrapper,
  .toDateWrapper {
    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
    .datePicker {
      .v-input {
        .v-input__control {
          .v-input__slot {
            width: 80%;

            .v-input__append-inner {
              .clearIcon,
              .calendarIcon {
                &:hover {
                  color: #21a7e0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panels {
  height: calc(100vh - 280px);
  overflow: auto;
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .v-expansion-panel {
    flex: none;
    width: 100%;
    &::before {
      box-shadow: none;
    }

    &::after {
      opacity: 0 !important;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: 47px !important;
  }

  .v-list-item {
    min-height: 30px !important;
    padding: 0;
    padding-left: 2px;
    .v-list-item__action {
      margin: 0px 7px 0px 0px;
    }
    .v-list-item__content {
      padding: 8px 0px;
      .v-list-item__title {
        font-size: 16px;
        line-height: 18px !important;
      }
    }
  }
}

.v-expansion-panels::-webkit-scrollbar {
  display: none;
}

.applyDatesCTA {
  width: fit-content;

  .v-btn {
    padding: 0 !important;
    background: transparent !important;
    border: none;
    box-shadow: none;
    letter-spacing: normal;
    text-transform: none;
    font-size: 16px;
    color: #21a7e0;
    width: 100%;
    background-color: transparent !important;
  }

  .disabled-applyDatesCTA {
    color: #878d96;
  }
}

.resetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .resetText {
    color: #21a7e0 !important;
    cursor: pointer;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.disabledResetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .v-icon,
  .resetText {
    color: #7b7b7b;
    margin: 0;
    cursor: default;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.filter-header {
  padding: 5px 10px 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
  }

  h3 {
    font-size: 17px;
    text-align: center;
  }
}
</style>
