import { cloneDeep, debounce } from 'lodash';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState, mapGetters } from 'vuex';
import usersApi from '../../../API/users-api';
import { isEmailValid, isOutsideCompanyEmail } from '../../utils';
import AudienceItem from '../AudienceItem.vue';

const filters = {
  outsideCompanyUsers: (user) => `accessible_to:!=c__${user.company.num_id}`,
  insideCompanyUsers: (user) =>
    `companyNumID:=${user.company.num_id} && email:!=${user.user.id}`,
};

/** @type {import('vue').Component} */
export default {
  name: 'UserSelector',
  components: { AudienceItem, ClipLoader },
  model: { prop: 'value', event: 'change' },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'navbarActions.shareFingerprintvue.addMoreColleagues',
    },
    filter: {
      type: String,
      default: 'outsideCompanyUsers',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    canAddEmail: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selection: null,
      users: [],
      query: null,
      loading: false,
    };
  },
  watch: {
    query(val) {
      if (!this.hasQuery) {
        return;
      }
      this.users = [];
      this.loading = true;
      this.search(val, this);
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTestAudience']),
    ...mapGetters('users', ['isEmailOutsideCompanyEnabled']),

    hasQuery() {
      return this.query?.length && this.query !== this.selection?.fullName;
    },
  },
  methods: {
    isEmailValid,
    isOutsideCompanyEmail(email) {
      const isValid = isOutsideCompanyEmail(
        this.isEmailOutsideCompanyEnabled,
        this.currentUser.user.id,
        email,
        'error',
      );
      return isValid;
    },
    search: debounce((val, _this) => {
      const filterBy = filters[_this.filter](_this.currentUser);
      const fields = [
        'isPublicLimited',
        'companyName',
        'companyID',
        'accessible_to',
        ..._this.fields,
      ];
      usersApi.methods
        .search(val, { fields, filterBy })
        .then((docs) => {
          _this.users = docs.map((doc) => {
            doc.document.isDifferentCompany =
              _this.currentUser.company.id !== doc.document.companyID;
            doc.document.id = doc.document.email;
            return doc.document;
          });
        })
        .finally(() => {
          _this.loading = false;
        });
    }, 500),

    onUserSelect(user) {
      if (!user) {
        return;
      }
      let addUser;
      if (typeof user === 'object') {
        addUser = user;
      } else if (this.canAddEmail && isEmailValid(user)) {
        user = user.toLowerCase();
        addUser = {
          id: user,
          profileImage: '/assets/img/non-member-dropdown-icon.svg',
        };
      } else {
        return;
      }
      this.addUser(addUser);

      this.query = null;
      this.users = [];

      this.$nextTick(() => {
        this.selection = null;
      });
    },

    addUser(user) {
      if (this.value.find((u) => u.id === user.id)) {
        return;
      }
      const users = cloneDeep(this.value);
      users.push(user);
      this.$emit('change', users);
    },

    removeUser(index) {
      const users = cloneDeep(this.value);
      users.splice(index, 1);
      this.$emit('change', users);
    },
  },
};
