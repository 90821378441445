<template>
  <div class="inlineSlidesList">
    <v-item-group
      active-class="primary"
      class="text-center"
      v-if="slides.length > 0"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="(item, index) in slides"
            :key="index"
            class="slide-wrapper col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
            :style="[
              $vuetify.breakpoint.xs ||
              $vuetify.breakpoint.sm ||
              $vuetify.breakpoint.md
                ? { 'min-width': '300px' }
                : {},
            ]"
          >
            <Slide
              :favorite-slides="favoriteSlides"
              :in-viewport-once="true"
              :in-viewport-root-margin="'0% 0%'"
              :item="item"
              :log-download="
                logDownload.bind(this, { fromExpandedView: false })
              "
              :handle-favorite="handleFavorite"
              :slideOrigin="slideOrigin"
              :handle-click-detail="handleSlideClick"
              :disable-download="disableDownload"
              :handle-download-from-list="handleDownloadFromList"
              :listDownloads="listDownloads"
              :listImageReplaced="listImageReplaced"
              :listIconReplaced="listIconReplaced"
              :is-trending="trendingSlides.includes(item.unique_id)"
              :id="index"
              :slides-meta-data="slidesMetaData"
              :audience="currentAudience"
              :removeSlideOnDelete="removeSlideOnDelete"
              :downloadingSlides="downloadingSlides"
              :addingToFavSlides="addingToFavSlides"
              v-on="$listeners"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
    <div class="loadmore-container" data-pendo-id="slides-container">
      <v-btn
        id="loadmore-btn"
        class="loadmore-btn"
        style="text-transform: none"
        :disabled="canLoadMore"
        @click="loadMoreSlides"
        color="#21a7e0"
        rounded
        outlined
        height="32"
      >
        {{
          !isNoMore
            ? $t('prezentationsList.seeMoreResult')
            : $t('prezentationsList.noMoreResult')
        }}
      </v-btn>
      <p
        class="mt-2"
        v-if="
          isNoMore &&
          origin === 'hybrid_search' &&
          slideOrigin === 'brand_slides'
        "
      >
        {{ $t('prezentationsList.limitedResultsSlide') }}
        <a class="text-link" @click="goToSlideLibrary">
          {{ $t('sideBarFeatures.slide_library') }}
        </a>
      </p>
      <p
        class="mt-2"
        v-else-if="
          isNoMore &&
          origin === 'slidesListPage' &&
          this.searchQuery.length === 0 &&
          slideOrigin === 'brand_slides'
        "
      >
        {{ $t('prezentationsList.limitedResults') }}
        <a class="text-link" @click="scrollToSearch">
          {{ $t('prezentationsList.searchBar') }}
        </a>
        {{ $t('prezentationsList.or') }}
        <a class="text-link" @click="scrollToFilters">
          {{ $t('prezentationsList.filters') }}
        </a>
        {{ $t('prezentationsList.onTop') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Slide from '../Main/Slides/Slide.vue';
import { trackSearchEvents } from './Analytics/SearchEvents';
import { trackSlideEvents } from './Analytics/SlideEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_QUERY,
  TD_REST_OF_THE_SLIDES_IN_UI,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  TD_HYBRID_RESULTS_SECTION_SLIDES,
  TD_HYBRIDCHIP,
  TD_AUD,
  TD_HYBRID_SEARCH,
} from './Analytics/MatomoTrackingDataHelper';

export default {
  name: 'InlineSlides',
  components: {
    Slide,
    // SlideDetailWrapper,
    // ConfirmationDialog,
  },
  computed: {
    ...mapGetters('replaceImageStore', [
      'getIsDirty',
      'getShowDialog',
      'getShouldReturnToSlide',
    ]),
    ...mapState('users', ['currentUser', 'hybridSearchQueryPayload']),
  },
  data: () => ({
    // internalResetFn: null,
    // activeWrapper: '',
    disableDownload: false,
    // activeSlide: null,
    // tempActiveSlide: null,
  }),
  mounted() {
    this.reset();
    this.resetReturn();
    this.activeWrapper = '';
    this.setCurrentThemeCode(this.currentThemeCode);
    this.setCurrentAudience(this.currentAudience);
    this.setSavedQueryPayload(this.savedQueryPayload);
    this.setSlideViewOrigin(this.origin);
  },
  props: {
    canLoadMore: {
      type: Boolean,
    },
    slideOrigin: {
      type: String,
      default: '',
    },
    handleDetailClick: {
      type: Function,
      default: () => {},
    },
    isNoMore: { type: Boolean },
    savedQueryPayload: {
      type: Object,
    },
    currentAudience: {
      type: Object,
    },
    loadMoreSlides: {
      type: Function,
      required: true,
    },
    slidesMetaData: {
      type: Object,
    },
    logDownload: {
      type: Function,
      required: true,
    },
    handleFavorite: {
      type: Function,
    },
    handleDownloadFromList: {
      type: Function,
    },
    listDownloads: {
      type: Array,
      required: true,
    },
    favoriteSlides: {
      type: Array,
      required: true,
    },
    slides: {
      type: Array,
      required: true,
    },
    trendingSlides: {
      type: Array,
    },
    itemsPerRow: {
      type: Number,
      required: true,
    },
    listImageReplaced: {
      type: Array,
      required: true,
    },
    listIconReplaced: {
      type: Array,
      default: () => [],
      required: true,
    },
    hideAdvancedFilter: {
      type: Function,
    },
    origin: {
      type: String,
      required: false,
    },
    currentThemeCode: {
      type: String,
      default: 'default',
    },
    snapshot: {
      type: Object,
      default: () => ({}),
    },
    removeSlideOnDelete: {
      type: Function,
      default: () => {},
    },
    downloadingSlides: {
      type: Array,
      default: () => [],
    },
    addingToFavSlides: {
      type: Array,
      default: () => [],
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('replaceImageStore', [
      'reset',
      'closeDialog',
      'openDialog',
      'resetReturn',
    ]),
    ...mapActions('search', ['updateRecentQueries']),
    ...mapActions('slidesStore', [
      'setCurrentAudience',
      'setSavedQueryPayload',
      'setCurrentThemeCode',
      'setSlideViewOrigin',
      'saveSlidesStateSnapshot',
    ]),

    handleSlideClick(selected, index, origin) {
      this.handleDetailClick();
      this.handleDoSlideClick(selected, index, origin);
    },

    async handleDoSlideClick(selected, index, origin) {
      console.log(selected, index, origin);
      if (this.origin === 'slidesListPage') {
        this.saveSlidesStateSnapshot(this.snapshot);
      }
      if (this.origin === TD_HYBRID_SEARCH) {
        const payload = {
          type: 'Slides',
          value: selected.title,
          subvalue: selected.title,
          attributes: `/home/slides/${
            selected.prefs.source === 'uploaded' ||
            selected.prefs.source === 'generated' ||
            selected.prefs.source === 'synthesis' ||
            selected.prefs.source === 'redesign' ||
            selected.prefs.source === 'comply'
              ? selected.id
              : selected.unique_id
          }`,
          object: selected,
        };
        this.updateRecentQueries(payload);
      }
      this.$router
        .push(
          `/home/slides/${
            selected.prefs.source === 'uploaded' ||
            selected.prefs.source === 'generated' ||
            selected.prefs.source === 'synthesis' ||
            selected.prefs.source === 'redesign' ||
            selected.prefs.source === 'comply'
              ? selected.id
              : selected.unique_id
          }`,
        )
        .catch(() => {});
      const assetIds = [...this.slides].map((sld) => sld.unique_id);
      let beforeInteractionSlides = [];
      let afterInteractionSlides = [];
      if (assetIds.length) {
        const itemToRemove = selected.asset
          ? selected.asset.unique_id
          : selected.unique_id;
        const itemIndexToRemove = assetIds.indexOf(itemToRemove);
        if (itemIndexToRemove !== -1) {
          beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
          afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
        }
      }
      const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
      const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
      const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
      if (this.$route.name === 'search') {
        trackSearchEvents.hybridSearchSlideClick(selected, this.currentUser, {
          [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
          [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
          [TD_QUERY]: this.$route.query.s,
          [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_SLIDES,
          [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
        });
      }
      if (this.searchQuery.length) {
        trackSearchEvents.searchDropdownSlideThumbnailClick(
          selected,
          this.currentUser,
          {
            [TD_AUD]: this.currentAudience?.id
              ? this.currentAudience?.id
              : this.currentAudience?.user?.id,
            [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
            [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
            [TD_QUERY]: this.searchQuery,
          },
        );
      }
      trackSlideEvents.slideThumbNailClick(selected, this.currentUser, {
        [TD_AUD]: this.currentAudience?.id
          ? this.currentAudience?.id
          : this.currentAudience?.user?.id,
        [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
        [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
        [TD_QUERY]: this.searchQuery,
      });
    },
    scrollToFilters() {
      this.$emit('scrollToOption', 'filter');
    },
    scrollToSearch() {
      this.$emit('scrollToOption', 'search');
    },
    goToSlideLibrary() {
      this.$router.push(`/home/slides`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.inlineSlidesList {
  width: 100%;
  .slide-wrapper {
    position: relative;
    float: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    min-height: 200px;
    text-align: center;
    .slider-content-wrapper {
      border-radius: 8px;
      box-shadow: $item-box-shadow;
      margin-bottom: 5px;
      position: relative;

      .already-download {
        position: absolute;
        right: 12px;
        top: 12px;
      }
    }
    .slider-content-wrapper:hover {
      box-shadow: $item-box-shadow-hover;
    }
    .image-wrapper img {
      border-radius: 8px;
      cursor: pointer;
      max-height: 90%;
      max-width: 90%;
      padding-top: 5%;
    }
    .image-wrapper {
      border-bottom: 1px solid rgba(211, 211, 211, 0.2);
      cursor: pointer;
    }
    .bottom-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      .bottom-wrapper-title {
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
        max-width: 239px;
        min-width: 65px;
      }
      .action-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        .favorite {
          cursor: pointer;
        }
        .show-detail {
          cursor: pointer;
          margin-left: 22px;
          margin-right: -1px;
        }

        ::v-deep .fab-btn {
          background: white;
          box-shadow: none;
          padding: 0;

          &::before {
            display: none;
          }
        }

        ::v-deep .new-slide-speed .v-speed-dial__list {
          left: -60px;
        }

        ::v-deep .download-list {
          border-radius: 2px;
          box-shadow: 0 2px 4px 0 #68788c;
          width: 208px;

          .download-list-item {
            background: white;
            color: black;
            cursor: pointer;
            font-size: 20px;
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            line-height: normal;
            padding-bottom: 5px;
            padding-left: 5px;
            text-align: left;

            &:hover {
              background: #f2f2f2;
            }
          }
        }
      }
      .trending {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        img {
          margin-right: 3px;
        }
        .trending-title {
          color: #68788c;
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;
          line-height: normal;
        }
      }
    }
  }
  .loadmore-container {
    justify-content: center;
    text-align: center;
    padding-bottom: 1.75rem;
  }
}
.loadmore-btn {
  margin-top: 18px;
}
</style>
