<template>
  <div class="tmpDesignerProgressBannerCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{
          $t('templateDesign.banners.progressText', {
            feature: getFeatureName,
            templateName: getTemplateName,
            progress: getTemplatePercentage,
          })
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'TemplateDesignerProgressBanner', // Banner is displayed when a designer job is in progress along with the percentage
  data() {
    return {
      jobUUID: '',
    };
  },
  computed: {
    ...mapGetters('templateDesigner', ['getTDData']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapState('users', ['currentUser', 'startLoadingSlidesFlag', 'themes']),
    ...mapGetters('bestPracticesExamples', ['getSelectedViewType']),

    getTemplateName() {
      return this.getCurrentTDData.name ? this.getCurrentTDData.name : '';
    },
    getCurrentTDData() {
      const data = this.getTDData.filter((item) => item.uuid === this.jobUUID);
      return data.length ? data[0] : {};
    },

    isInWalkthroughPage() {
      return this.$route.path.includes('/help/walkthroughs');
    },

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },
    getTemplatePercentage() {
      let percentage = 0;
      if (this.isBPLSlideView) {
        if (this.getSelectedViewType === 'Decks') {
          percentage = this.getCurrentTDData.percentageBpgPresentations;
        }
        if (this.getSelectedViewType === 'Slides') {
          percentage = this.getCurrentTDData.percentageBpgSlides;
        }
      } else {
        percentage = this.getCurrentTDData.percentage;
      }
      return parseInt(percentage, 10);
    },
    getFeatureName() {
      let feature = '';
      if (this.isBPLSlideView) {
        if (this.getSelectedViewType === 'Decks') {
          feature = 'best practice decks';
        }
        if (this.getSelectedViewType === 'Slides') {
          feature = 'best practice slides';
        }
      } else {
        feature = '35K+ slides';
      }
      return feature;
    },
  },
  props: {
    componentKey: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const regex = /uuid_(.*)/;
    const [, uuid] = this.componentKey.match(regex);
    this.jobUUID = uuid;
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    goToWalkThroughs() {
      this.handleNavigate();
    },
    handleNavigate() {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: `/help/walkthroughs`,
          type: 'to_help_walkthroughs',
          action: 'push',
          pushAction: false,
        });
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        this.$router.push('help/walkthroughs', () => {}).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerProgressBannerCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 14px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
