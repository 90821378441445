import { trackCommonEvent } from './MatomoTrackingDataHelper';

const ACCELERATORS_EVENT_CATEGORY = 'Accelerators';
const ACCELERATORS_ICON_CLICK = 'Accelerators_icon-click';
const ACCELERATORS_GET_IN_TOUCH = 'Accelerators_Get-in-touch';
const ACCELERATORS_GET_IN_TOUCH_SUBMIT = 'Accelerators_Get-in-touch_Submit';
const ACCELERATORS_FAQ_CLICK = 'Accelerators_FAQ_click';

export const trackAcceleratorsEvents = {
  acceleratorsIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: ACCELERATORS_EVENT_CATEGORY,
        eventAction: ACCELERATORS_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  acceleratorsGetInTouchClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: ACCELERATORS_EVENT_CATEGORY,
        eventAction: ACCELERATORS_GET_IN_TOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  acceleratorsSubmitClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: ACCELERATORS_EVENT_CATEGORY,
        eventAction: ACCELERATORS_GET_IN_TOUCH_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  acceleratoeFAQClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: ACCELERATORS_EVENT_CATEGORY,
        eventAction: ACCELERATORS_FAQ_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
