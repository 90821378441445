<template>
  <div class="communication">
    <div class="top-header-wrapper">
      <v-app-bar :elevation="0" app clipped-left flat>
        <v-toolbar-title>
          <div class="logo">
            <router-link to="/home/main" @click.native="$router.go()">
              <img
                src="/assets/img/prezent_logo.svg"
                alt
                width="90%"
                height="85%"
                max-height="60px"
              />
            </router-link>
          </div>
        </v-toolbar-title>
      </v-app-bar>
    </div>
    <div class="communicationStandAloneContainer">
      <div class="questionsContentContainer">
        <div class="expired" v-if="isExpired === true">
          <div class="ratingHeading">
            {{ 'Redirecting you to ' }}
            <span class="emailHeading">{{ 'teams.prezent.ai' }}</span>
          </div>
        </div>
        <div class="question_1">
          <div class="questionOneImageWrapper">
            <img src="/assets/img/keep-in-touch@2x.svg" alt />
          </div>
          <div class="communicationHeading">
            {{ "Let's keep in touch" }}
          </div>
          <div class="itemContainer" v-if="thanksContainer === true">
            <div class="ratingHeading">
              {{
                'Your communication preferences have been updated successfully!'
              }}
            </div>
          </div>
          <div class="itemContainer" v-else>
            <div class="ratingHeading">
              {{ 'Update communication preferences for ' }}
              <span class="emailHeading">{{ email }}</span>
            </div>
            <div class="ratingSubHeading">
              {{
                'You are currently subscribed to receive the following product emails. Uncheck the types of emails you do not wish to receive'
              }}
            </div>
            <div class="ratingWrapper">
              <v-checkbox
                v-model="audience"
                color="#20a7e0"
                @click="handleCheckbox('audience_invite')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="header">Audience Invites</div>
                    <div class="sub-header">
                      Prezent member invites you to take the fingerprint test
                    </div>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="freeTrial"
                color="#20a7e0"
                @click="handleCheckbox('free_trial')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="header">Free Trials</div>
                    <div class="sub-header">
                      Prezent member shares a free trial with you
                    </div>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="presentation"
                color="#20a7e0"
                @click="handleCheckbox('presentation_preference')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="header">Presentation Preferences</div>
                    <div class="sub-header">
                      Prezent member shares their presentation preferences with
                      you
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </div>
            <div class="ratingSubHeading">
              {{
                'Alternatively, select this option to unsubscribe from all emails'
              }}
            </div>
            <div class="ratingWrapper2">
              <v-checkbox
                v-model="allEmails"
                color="#20a7e0"
                @click="handleCheckbox('all')"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="header">Unsubscribe from all</div>
                  </div>
                </template>
              </v-checkbox>
            </div>
            <div class="firstQuestionNext">
              <v-btn class="next" @click="handleUpdateClick">
                {{ 'Update preferences' }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCommunicationStandAlone,
  updateEmailNotification,
} from '../../utils/api-helper';

export default {
  name: 'CommunicationStandAlone',
  data() {
    return {
      preferences: [],
      email: this.$route.query.email,
      audience: true,
      freeTrial: true,
      presentation: true,
      allEmails: false,
      thanksContainer: false,
      isExpired: false,
    };
  },
  methods: {
    handleCheckbox(type) {
      if (this.preferences.includes(type)) {
        this.preferences = this.preferences.filter((item) => item !== type);
      } else if (type === 'all' && this.allEmails === true) {
        this.preferences.push(
          'audience_invite',
          'free_trial',
          'presentation_preference',
        );
        this.preferences = this.preferences.filter(
          (item, index) => this.preferences.indexOf(item) === index,
        );
        this.audience = false;
        this.freeTrial = false;
        this.presentation = false;
      } else if (type === 'all' && this.allEmails === false) {
        this.preferences = [];
        this.audience = true;
        this.freeTrial = true;
        this.presentation = true;
      } else {
        this.preferences.push(type);
      }
      console.log(this.preferences);
    },
    async handleUpdateClick() {
      updateEmailNotification({
        email: this.email,
        type: 'email_notification',
        preferences: this.preferences,
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
      this.thanksContainer = true;
    },
  },
  async mounted() {
    await getCommunicationStandAlone(this.email, 'email_notification')
      .then((resp) => {
        console.log(resp);
        if (resp.data.isExpired === true) {
          this.isExpired = true;
          setTimeout(() => {
            window.location.href = 'https://teams.prezent.ai/';
          }, 2000);
        }
        this.preferences = resp.data.preferences
          ? [...resp.data.preferences]
          : [];
        this.audience = !this.preferences.includes('audience_invite');
        this.freeTrial = !this.preferences.includes('free_trial');
        this.presentation = !this.preferences.includes(
          'presentation_preference',
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {},
  components: {},
};
</script>

<style lang="scss" scoped>
.top-header-wrapper {
  height: 68px;
}

::v-deep .v-toolbar__content {
  padding-left: 28px;
  background-color: #fff;
  min-height: 68px;
  padding-right: 21px;
  .logo {
    margin-left: 15px;
    margin-top: 5px;
    max-height: 60px !important;
    max-width: 200px;
    min-width: 200px;
  }

  .logo img {
    width: 90%;
  }
}
.communicationStandAloneContainer {
  padding: 14px;
  height: 80vh;
  width: 900px;
  margin: 0 auto;
  .questionsContentContainer {
    .expired {
      .ratingHeading {
        width: 100%;
        text-align: center;
        margin: 50px auto 7px auto;
        font-size: 17px;
        font-weight: bolder;
        .emailHeading {
          color: gray;
        }
      }
    }
    .question_1 {
      .questionOneImageWrapper {
        width: 60%;
        margin: 0 auto;
        padding: 16px 0px 16px 0px;
        height: 200px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .communicationHeading {
        width: 100%;
        text-align: center;
        font-size: 50px;
      }
      .itemContainer {
        .ratingHeading {
          width: 100%;
          text-align: center;
          margin: 50px auto 7px auto;
          font-size: 17px;
          font-weight: bolder;
          .emailHeading {
            color: gray;
          }
        }
        .ratingSubHeading {
          width: 100%;
          text-align: center;
          margin: 5px auto 10px auto;
          font-size: 15px;
        }
        .ratingWrapper2 {
          width: 100%;
          margin-left: 35%;
          padding: 0px 36px;
          .header {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin: 0px 0px 2.5px 2.5px;
            color: #000000;
          }
        }
        .ratingWrapper {
          width: 100%;
          margin-left: 25%;
          padding: 0px 36px;
          .header {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin: 0px 0px 2.5px 2.5px;
            color: #000000;
          }
          .sub-header {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.02em;
            margin: 0px 0px 0px 2.5px;
            color: #7b7b7b;
          }
        }
        .firstQuestionNext {
          padding: 20px 0px 10px 0px;
          .next {
            width: 25%;
            margin: 4px auto 4px auto !important;
            display: block;
            text-transform: none;
            border-radius: 16px;
            letter-spacing: 0.1px;
            background-color: #21a7e0;
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
