<template>
  <v-badge
    :value="shouldDisplayBadge"
    :color="color"
    :content="$t(content)"
    overlap
    tile
    :inline="inline"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :class="{
      'small-badge': isSmall,
      'medium-badge': isMedium,
      'large-badge': isLarge,
    }"
  >
    <slot></slot>
  </v-badge>
</template>
<script>
import { mapState } from 'vuex';
import { isFeatureReleaseBadgeEnabled } from '../utils';

export default {
  name: 'NewFeatureBadge',
  components: {},
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    featureId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#075689',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: 'filters.new',
    },
    offsetX: {
      type: String,
      default: '37',
    },
    offsetY: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    userFeatureAccessDate: {
      type: String,
      required: false,
    },
    additionalFeatureId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState('featureReleaseInfo', ['featureReleaseInfo']),
    ...mapState('users', ['currentUser', 'currentActiveSubscription']),
    isSmall() {
      return this.size === 'small';
    },
    isMedium() {
      return this.size === 'medium';
    },
    isLarge() {
      return this.size === 'large';
    },
    shouldDisplayBadge() {
      return isFeatureReleaseBadgeEnabled(
        this.featureReleaseInfo,
        this.currentUser?.user?.createdAt,
        this.moduleName,
        this.featureId,
        this.currentActiveSubscription
          ? this.currentActiveSubscription.planID
          : null,
        this.userFeatureAccessDate,
        this.additionalFeatureId,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
::v-deep .v-badge__badge {
  background: $new-tag-bg;
  border-radius: 6px !important;
  line-height: initial !important;
  font-size: 14px;
  font-weight: 600;
  height: fit-content;
  padding: 6px 16px !important;
  width: fit-content;
}
.small-badge {
  ::v-deep .v-badge__badge {
    border-radius: 4px !important;
    font-size: 8px;
    height: fit-content;
    padding: 2px 4px !important;
    width: fit-content;
  }
}
.medium-badge {
  ::v-deep .v-badge__badge {
    border-radius: 5px !important;
    font-size: 10px;
    height: fit-content;
    padding: 3px 8px !important;
    width: fit-content;
  }
}
.large-badge {
  ::v-deep .v-badge__badge {
    border-radius: 6px !important;
    font-size: 12px;
    height: fit-content;
    padding: 5px 8px !important;
    width: fit-content;
  }
}
</style>
