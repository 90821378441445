<template>
  <div class="survey">
    <div class="top-header-wrapper">
      <v-app-bar :elevation="0" app clipped-left flat>
        <v-toolbar-title>
          <div class="logo">
            <router-link to="/home/main" @click.native="$router.go()">
              <img
                src="/assets/img/prezent_logo.svg"
                alt
                width="90%"
                height="85%"
                max-height="60px"
              />
            </router-link>
          </div>
        </v-toolbar-title>
      </v-app-bar>
    </div>
    <div class="surveyStandAloneContainer">
      <div class="headingContainer">
        <h3
          class="heading"
          v-if="questionNumberLoaded < 6 && questionNumberLoaded > 0"
        >
          {{ 'Add zen to your presentations!' }}
        </h3>
      </div>
      <div
        class="surveyProgressContainer"
        v-if="questionNumberLoaded < 6 && questionNumberLoaded > 0"
      >
        <div
          :class="
            completedProgress === 100 ? 'completed' : 'completionInProgress'
          "
          :style="{ width: `${completedProgress}%` }"
        />
        <div
          :class="
            pendingProgress === 100 ? 'pending' : 'questionsStillUnanswered'
          "
          :style="{ width: `${pendingProgress}%` }"
        />
      </div>
      <div class="questionsContentContainer">
        <div class="question_m2" v-if="standAloneLoading">
          <img src="/assets/img/survey/computer-screen.svg" alt />
          <v-progress-circular
            indeterminate
            color="primary"
            class="spinner"
          ></v-progress-circular>
        </div>
        <div class="question_m1" v-if="!status">
          <div class="thanksContainerImageWrapper">
            <img src="/assets/img/survey/expired-link.svg" alt />
          </div>
          <div class="thanksContainerHeading">
            <h3 class="response">
              {{ 'Oops! This link has expired.' }}
            </h3>
            <h3 class="response">
              {{ 'Need help? You can reach us at ' }}
              <span class="successMaill">{{ 'success@prezent.ai' }}</span>
            </h3>
          </div>
        </div>
        <div
          class="question_0"
          v-if="questionNumberLoaded === 0 && status && !standAloneLoading"
        >
          <div class="thanksContainerImageWrapper">
            <img src="/assets/img/survey/welcome-survey.svg" alt />
          </div>
          <div class="thanksContainerHeading">
            <h1 class="userDetails">
              {{ 'Hi ' + userName }}
            </h1>
            <h3 class="response">
              {{
                'Share your thoughts on Prezent. This will help us become better!'
              }}
            </h3>
            <p class="reachUsAt">
              {{ 'By clicking below, you agree to our' }}
              <a href="https://prezent.ai/privacy-policy/"
                ><span class="link">{{ 'Privacy Policy.' }}</span></a
              >
            </p>
            <v-btn class="login" @click="takeSurveyHandler">
              {{ 'Take Survey' }}
            </v-btn>
          </div>
        </div>
        <div class="question_1" v-if="questionNumberLoaded === 1">
          <div class="questionOneImageWrapper">
            <img
              src="/assets/img/how-likely-are-you-to-recommend-prezent.ai-to-a-colleague.svg"
              alt
            />
          </div>
          <div class="ratingHeading">
            {{ 'How likely are you to recommend Prezent to a colleague?' }}
          </div>
          <div class="ratingWrapper">
            <div class="ratingNodes">
              <div
                v-for="num in firstQuerstionRatingRange"
                :key="num"
                @click="() => handleFirstQuestionRatingSave(num)"
                :class="num === firstQuestionRating ? 'selected' : 'unselected'"
              >
                {{ num }}
              </div>
            </div>
          </div>
          <div class="sliderTexts">
            <div class="leftText">{{ 'Extremely unlikely' }}</div>
            <div class="rightText">{{ 'Extremely likely' }}</div>
          </div>
          <div class="firstQuestionNext">
            <v-btn
              @click="handleFirstQuestionNextClick"
              class="next"
              :disabled="firstQuestionRating <= 0"
            >
              {{ 'Next' }}
            </v-btn>
          </div>
        </div>
        <div
          class="goodRatingScenario"
          v-if="goodRatingFlow === true && questionNumberLoaded < 6"
        >
          <div class="question_2" v-if="questionNumberLoaded === 2">
            <div class="questionTwoImageWrapper">
              <img
                src="/assets/img/how-much-time-are-you-saving-in-creating-presentations.svg"
                alt
              />
            </div>
            <div class="ratingHeading">
              {{ 'How much time are you saving in creating presentations?' }}
            </div>
            <div class="sliderRatingWrapper">
              <VueSlider
                v-model="secondQuestionSliderRating"
                :width="568"
                :min="0"
                :max="100"
                :interval="25"
                :drag-on-click="true"
                :adsorb="true"
                tooltip="always"
              >
                <template #tooltip="{ value }">
                  <div>
                    <h3>{{ `${value}%` }}</h3>
                  </div>
                </template>
              </VueSlider>
            </div>
            <div class="sliderTexts">
              <div class="leftText">{{ '0%' }}</div>
              <div class="rightText">{{ '100%' }}</div>
            </div>
            <div class="secondQuestionNext">
              <v-btn @click="handleSecondQuestionNextClick" class="next">
                {{ 'Next' }}
              </v-btn>
            </div>
          </div>
          <div class="question_3" v-if="questionNumberLoaded === 3">
            <div class="questionThreeImageWrapper">
              <img
                src="/assets/img/how-much-have-your-presentations-improved.svg"
                alt
              />
            </div>
            <div class="ratingHeading">
              {{ 'How much have your presentations improved?' }}
            </div>
            <div class="sliderRatingWrapper">
              <VueSlider
                v-model="thirdQuestionSliderRating"
                :width="568"
                :min="0"
                :max="100"
                :interval="25"
                :drag-on-click="true"
                :adsorb="true"
                tooltip="always"
              >
                <template #tooltip="{ value }">
                  <img :src="satisfactionEmojis[value / 25]" alt />
                </template>
              </VueSlider>
            </div>
            <div class="sliderTexts">
              <div class="leftText">{{ 'A little bit' }}</div>
              <div class="rightText">{{ 'A lot' }}</div>
            </div>
            <div class="thirdQuestionNext">
              <v-btn @click="handleThirdQuestionNextClick" class="next">
                {{ 'Next' }}
              </v-btn>
            </div>
          </div>
          <div class="question_4" v-if="questionNumberLoaded === 4">
            <div class="ratingHeading">
              {{ 'What do you like the most about Prezent?' }}
            </div>
            <div class="questionFourSuggestionsListContainer">
              <div
                v-for="(suggestion, index) in questionFourSuggestions"
                :key="index"
                :class="
                  suggestion.selected === true
                    ? 'suggestionItemWhenSelected'
                    : 'suggestionItem'
                "
                @click="() => handleFourthQuestionSuggestionsClick(suggestion)"
              >
                <div class="suggestionItemImageContainer">
                  <img
                    :src="
                      suggestion.selected === true
                        ? suggestion.iconWhenSelected
                        : suggestion.icon
                    "
                    alt
                  />
                </div>
                <div class="suggestionText">
                  <h3>{{ suggestion.textContent }}</h3>
                </div>
              </div>
            </div>
            <div
              :class="
                fourthQuestionOtherSuggestionActive === true
                  ? 'addSuggestionsToFourthQuestionContainerActive'
                  : 'addSuggestionsToFourthQuestionContainer'
              "
            >
              <div class="textAreaHeader">
                <img
                  :src="`/assets/img/other-${
                    fourthQuestionOtherSuggestionActive === true
                      ? 'selected'
                      : 'default'
                  }.svg`"
                  alt
                />
                <h3>{{ 'Other' }}</h3>
              </div>
              <div class="textAreaContainer">
                <v-textarea
                  v-model="fourthQuestionTextAreaSuggestion"
                  solo
                  :rules="[
                    (v) => (v || '').length <= 120 || 'Maximum 120 characters',
                  ]"
                  name="input-7-4"
                  @focus="handleFourthQuestionTextAreaFocus"
                  @blur="handleFourthQuestionTextAreaBlur"
                  placeholder="Type your own"
                />
              </div>
            </div>
            <div class="fourthQuestionSubmit">
              <v-btn
                @click="handleFourthQuestionSubmitClick"
                class="submit"
                :disabled="
                  atleastOneSuggestionSelectedForFourthQues === false ||
                  fourthQuestionTextAreaSuggestion.length > 120
                "
              >
                {{ 'Submit' }}
              </v-btn>
            </div>
          </div>
        </div>
        <div
          class="badRatingScenario"
          v-if="badRatingFlow === true && questionNumberLoaded < 6"
        >
          <div class="question_5" v-if="questionNumberLoaded === 5">
            <div class="ratingHeading">
              {{ 'How can we improve your Prezent experience?' }}
            </div>
            <div class="questionFiveSuggestionsListContainer">
              <div
                v-for="(suggestion, index) in questionFiveSuggestions"
                :key="index"
                :class="
                  suggestion.selected === true
                    ? 'suggestionItemWhenSelected'
                    : 'suggestionItem'
                "
                @click="() => handleFifthQuestionSuggestionsClick(suggestion)"
              >
                <div class="suggestionItemImageContainer">
                  <img
                    :src="
                      suggestion.selected === true
                        ? suggestion.iconWhenSelected
                        : suggestion.icon
                    "
                    alt
                  />
                </div>
                <div class="suggestionText">
                  <h3>{{ suggestion.textContent }}</h3>
                </div>
              </div>
            </div>
            <div
              :class="
                fifthQuestionOtherSuggestionActive === true
                  ? 'addSuggestionsToFifthQuestionContainerActive'
                  : 'addSuggestionsToFifthQuestionContainer'
              "
            >
              <div class="textAreaHeader">
                <img
                  :src="`/assets/img/other-${
                    fifthQuestionOtherSuggestionActive === true
                      ? 'selected'
                      : 'default'
                  }.svg`"
                  alt
                />
                <h3>{{ 'Other' }}</h3>
              </div>
              <div class="textAreaContainer">
                <v-textarea
                  v-model="fifthQuestionTextAreaSuggestion"
                  solo
                  :rules="[
                    (v) => (v || '').length <= 120 || 'Maximum 120 characters',
                  ]"
                  name="input-7-4"
                  @focus="handleFifthQuestionTextAreaFocus"
                  @blur="handleFifthQuestionTextAreaBlur"
                  placeholder="Type your own"
                />
              </div>
            </div>
            <div class="fifthQuestionSubmit">
              <v-btn
                @click="handleFifthQuestionSubmitClick"
                class="submit"
                :disabled="
                  atleastOneSuggestionSelectedForFifthQues === false ||
                  fifthQuestionTextAreaSuggestion.length > 120
                "
              >
                {{ 'Submit' }}
              </v-btn>
            </div>
          </div>
        </div>
        <div
          class="thanksContainer"
          v-if="questionNumberLoaded === 6 || questionNumberLoaded === 7"
        >
          <div class="thanksContainerImageWrapper">
            <img src="/assets/img/thanks-for-feedback.svg" alt />
          </div>
          <div class="thanksContainerHeading">
            <h3 class="response" v-if="questionNumberLoaded === 6">
              {{ 'We have received your responses!' }}
            </h3>
            <h3 class="response" v-if="questionNumberLoaded === 7">
              {{ 'We have already received your responses!' }}
            </h3>
            <p class="thanks">{{ 'Thanks for your feedback ' }}</p>
            <router-link
              to="/home/main"
              class="loginPrezent"
              @click.native="$router.go()"
            >
              <v-btn class="login">
                {{ 'Login to Prezent' }}
              </v-btn>
            </router-link>
            <p class="reachUsAt">
              {{ 'You can also reach us at' }}
              <span class="link">{{ 'success@prezent.ai' }}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="actionButtonsContainer"
        v-if="questionNumberLoaded > 0 && questionNumberLoaded < 6"
      >
        <v-btn
          @click="handleSurveyModalClose(questionNumberLoaded)"
          class="notNow"
        >
          {{ 'Not Now' }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
// import StarRating from 'vue-star-rating';
import {
  getVerifyDetails,
  getUpdateLink,
  addStandSurvey,
  updateStandSurvey,
  surveyStandSkip,
} from '../../utils/api-helper';

export default {
  name: 'SurveyStandAlone',
  data() {
    return {
      completedProgress: 25,
      pendingProgress: 75,
      firstQuerstionRatingRange: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      firstQuestionRating: 0,
      questionNumberLoaded: 0,
      userName: '',
      standAloneLoading: true,
      email: '',
      status: true,
      goodRatingFlow: false,
      badRatingFlow: false,
      secondQuestionSliderRating: 100,
      thirdQuestionSliderRating: 100,
      satisfactionEmojis: [
        '/assets/img/level1.svg',
        '/assets/img/level2.svg',
        '/assets/img/level3.svg',
        '/assets/img/level4.svg',
        '/assets/img/level5.svg',
      ],
      questionFourSuggestions: [
        {
          icon: '/assets/img/time-saver-default.svg',
          textContent: 'Time Saver',
          selected: false,
          iconWhenSelected: '/assets/img/time-saver-selected.svg',
        },
        {
          icon: '/assets/img/brand-template-default.svg',
          textContent: '35K+ brand template slides',
          selected: false,
          iconWhenSelected: '/assets/img/brand-template-selected.svg',
        },
        {
          icon: '/assets/img/new-ideas-default.svg',
          textContent: 'Catalyst for new ideas',
          selected: false,
          iconWhenSelected: '/assets/img/new-ideas-selected.svg',
        },
        {
          icon: '/assets/img/share-colleagues-default.svg',
          textContent: 'Sharing trials with colleagues',
          selected: false,
          iconWhenSelected: '/assets/img/share-colleagues-selected.svg',
        },
        {
          icon: '/assets/img/my-audience-default.svg',
          textContent: 'Tailoring to my audience',
          selected: false,
          iconWhenSelected: '/assets/img/my-audience-selected.svg',
        },
        {
          icon: '/assets/img/enhancing-communication-default.svg',
          textContent: 'Enhancing communication',
          selected: false,
          iconWhenSelected: '/assets/img/enhancing-communication-selected.svg',
        },
        {
          icon: '/assets/img/replace-images-default.svg',
          textContent: 'Ability to replace images',
          selected: false,
          iconWhenSelected: '/assets/img/replace-images-selected.svg',
        },
        {
          icon: '/assets/img/add-audiences-share-trials-default.svg',
          textContent: 'Add audiences',
          selected: false,
          iconWhenSelected:
            '/assets/img/add-audiences-share-trials-selected.svg',
        },
      ],
      fourthQuestionTextAreaSuggestion: '',
      fourthQuestionTextAreaActive: false,
      questionFiveSuggestions: [
        {
          icon: '/assets/img/searching-ideas-default.svg',
          textContent: 'Searching for ideas in the search bar',
          selected: false,
          iconWhenSelected: '/assets/img/searching-ideas-selected.svg',
        },
        {
          icon: '/assets/img/downloading-slides-default.svg',
          textContent: 'Downloading slides or prezentations',
          selected: false,
          iconWhenSelected: '/assets/img/downloading-slides-selected.svg',
        },
        {
          icon: '/assets/img/support-default.svg',
          textContent: 'Reaching out to support to resolve issues',
          selected: false,
          iconWhenSelected: '/assets/img/support-selected.svg',
        },
        {
          icon: '/assets/img/share-fingerprint-request-default.svg',
          textContent: 'Sharing a trial or fingerprint request',
          selected: false,
          iconWhenSelected:
            '/assets/img/share-fingerprint-request-selected.svg',
        },
        {
          icon: '/assets/img/add-audiences-share-trials-default.svg',
          textContent: 'Share trials and add audience',
          selected: false,
          iconWhenSelected:
            '/assets/img/add-audiences-share-trials-selected.svg',
        },
        {
          icon: '/assets/img/sso-default.svg',
          textContent: 'SSO for easier login',
          selected: false,
          iconWhenSelected: '/assets/img/sso-selected.svg',
        },
      ],
      fifthQuestionTextAreaActive: false,
      fifthQuestionTextAreaSuggestion: '',
      runningSurveyID: 0,
      linkID: null,
    };
  },
  methods: {
    async takeSurveyHandler() {
      this.questionNumberLoaded = 1;
      await getUpdateLink({ linkId: this.$route.params.id, active: false })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSurveyModalClose(question) {
      if (question === 1) {
        surveyStandSkip({ email: this.email, surveyOrigin: 'survey_link' })
          .then((resp) => console.log(resp))
          .catch((err) => console.log(err));
      }
      this.questionNumberLoaded = 6;
    },
    handleFirstQuestionRatingSave(rating) {
      this.firstQuestionRating = rating;
    },
    handleFirstQuestionNextClick() {
      addStandSurvey({
        recommendationRating: this.firstQuestionRating,
        email: this.email,
        surveyOrigin: 'survey_link',
        linkID: this.linkID,
      })
        .then((resp) => {
          console.log(resp);
          this.runningSurveyID = resp.data?.id ? resp.data.id : 0;
          if (this.runningSurveyID === 0) {
            this.questionNumberLoaded = 7;
            this.completedProgress = 100;
            this.pendingProgress = 0;
            this.goodRatingFlow = false;
            this.badRatingFlow = true;
          } else if (this.firstQuestionRating > 8) {
            this.questionNumberLoaded = 2;
            this.completedProgress = 50;
            this.pendingProgress = 50;
            this.goodRatingFlow = true;
            this.badRatingFlow = false;
          } else {
            this.questionNumberLoaded = 5;
            this.completedProgress = 100;
            this.pendingProgress = 0;
            this.goodRatingFlow = false;
            this.badRatingFlow = true;
          }
        })
        .catch((err) => console.log(err));
    },
    handleSecondQuestionNextClick() {
      this.completedProgress = 75;
      this.pendingProgress = 25;
      this.questionNumberLoaded = 3;
      updateStandSurvey({
        id: this.runningSurveyID,
        effciencyRating: this.secondQuestionSliderRating / 25 + 1,
        email: this.email,
      })
        .then((resp) => console.log(resp))
        .catch((err) => console.log(err));
    },
    handleThirdQuestionNextClick() {
      this.completedProgress = 100;
      this.pendingProgress = 0;
      this.questionNumberLoaded = 4;
      updateStandSurvey({
        id: this.runningSurveyID,
        effectivenessRating: this.thirdQuestionSliderRating / 25 + 1,
        email: this.email,
      })
        .then((resp) => console.log(resp))
        .catch((err) => console.log(err));
    },
    handleFourthQuestionSubmitClick() {
      this.completedProgress = 100;
      this.pendingProgress = 0;
      this.questionNumberLoaded = 6;
      const selectedFourthQuesSuggestions = [];
      this.questionFourSuggestions.forEach((item) => {
        if (item.selected === true) {
          selectedFourthQuesSuggestions.push(item.textContent);
        }
      });
      if (this.fourthQuestionTextAreaSuggestion !== '') {
        selectedFourthQuesSuggestions.push(
          this.fourthQuestionTextAreaSuggestion,
        );
      }
      updateStandSurvey({
        id: this.runningSurveyID,
        likes: selectedFourthQuesSuggestions,
        email: this.email,
      })
        .then((resp) => console.log(resp))
        .catch((err) => console.log(err));
    },
    handleFourthQuestionSuggestionsClick(suggestion) {
      const { selected, textContent } = suggestion;
      if (selected === true) {
        this.questionFourSuggestions = this.questionFourSuggestions.map(
          (sugg) => {
            if (sugg.textContent === textContent) {
              return {
                ...sugg,
                selected: false,
              };
            }
            return {
              ...sugg,
            };
          },
        );
      } else {
        this.questionFourSuggestions = this.questionFourSuggestions.map(
          (sugg) => {
            if (sugg.textContent === textContent) {
              return {
                ...sugg,
                selected: true,
              };
            }
            return {
              ...sugg,
            };
          },
        );
      }
    },
    handleFourthQuestionTextAreaFocus() {
      this.fourthQuestionTextAreaActive = true;
    },
    handleFourthQuestionTextAreaBlur() {
      this.fourthQuestionTextAreaActive = false;
    },
    handleFifthQuestionSuggestionsClick(suggestion) {
      const { selected, textContent } = suggestion;
      if (selected === true) {
        this.questionFiveSuggestions = this.questionFiveSuggestions.map(
          (sugg) => {
            if (sugg.textContent === textContent) {
              return {
                ...sugg,
                selected: false,
              };
            }
            return {
              ...sugg,
            };
          },
        );
      } else {
        this.questionFiveSuggestions = this.questionFiveSuggestions.map(
          (sugg) => {
            if (sugg.textContent === textContent) {
              return {
                ...sugg,
                selected: true,
              };
            }
            return {
              ...sugg,
            };
          },
        );
      }
    },
    handleFifthQuestionTextAreaFocus() {
      this.fifthQuestionTextAreaActive = true;
    },
    handleFifthQuestionTextAreaBlur() {
      this.fifthQuestionTextAreaActive = false;
    },
    handleFifthQuestionSubmitClick() {
      const selectedFifthQuesSuggestions = [];
      this.questionFiveSuggestions.forEach((item) => {
        if (item.selected === true) {
          selectedFifthQuesSuggestions.push(item.textContent);
        }
      });
      if (this.fifthQuestionTextAreaSuggestion !== '') {
        selectedFifthQuesSuggestions.push(this.fifthQuestionTextAreaSuggestion);
      }
      updateStandSurvey({
        id: this.runningSurveyID,
        improvements: selectedFifthQuesSuggestions,
        email: this.email,
      })
        .then((resp) => console.log(resp))
        .catch((err) => console.log(err));
      this.questionNumberLoaded = 6;
    },
    verifyLink() {
      getVerifyDetails(this.$route.params.id)
        .then((resp) => {
          console.log(resp);
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    },
  },
  async mounted() {
    await getVerifyDetails(this.$route.params.id)
      .then((resp) => {
        this.linkID = this.$route.params.id;
        this.userName = resp.data.user.firstName;
        this.standAloneLoading = false;
        this.email = resp.data.user.id;
        console.log(resp);
      })
      .catch((err) => {
        this.status = false;
        this.standAloneLoading = false;
        console.log(err);
      });
  },
  computed: {
    fourthQuestionOtherSuggestionActive() {
      return (
        this.fourthQuestionTextAreaActive ||
        this.fourthQuestionTextAreaSuggestion.length > 0
      );
    },
    atleastOneSuggestionSelectedForFourthQues() {
      return (
        this.questionFourSuggestions.filter((sugg) => sugg.selected === true)
          .length > 0 || this.fourthQuestionTextAreaSuggestion.length > 0
      );
    },
    fifthQuestionOtherSuggestionActive() {
      return (
        this.fifththQuestionTextAreaActive ||
        this.fifthQuestionTextAreaSuggestion.length > 0
      );
    },
    atleastOneSuggestionSelectedForFifthQues() {
      return (
        this.questionFiveSuggestions.filter((sugg) => sugg.selected === true)
          .length > 0 || this.fifthQuestionTextAreaSuggestion.length > 0
      );
    },
  },
  components: {
    VueSlider,
  },
};
</script>

<style lang="scss" scoped>
.top-header-wrapper {
  height: 68px;
}

::v-deep .v-toolbar__content {
  padding-left: 28px;
  background-color: #fff;
  min-height: 68px;
  padding-right: 21px;
  .logo {
    margin-left: 15px;
    margin-top: 5px;
    max-height: 60px !important;
    max-width: 200px;
    min-width: 200px;
  }

  .logo img {
    width: 90%;
  }

  .right-nav {
    align-items: center;
    align-content: flex-end;
    display: flex;
    gap: 20px;
  }
}
.surveyStandAloneContainer {
  padding: 14px;
  height: 80vh;
  width: 600px;
  margin: 0 auto;
  .closeIconContainer {
    text-align: right;
    height: 1.5rem;
  }
  .headingContainer {
    .heading {
      width: 60%;
      margin: 16px auto;
      font-size: 22px;
      text-align: center;
    }
    .headingSeventhQues {
      width: 66%;
      text-align: center;
      margin: 16px auto;
      font-size: 22px;
    }
  }
  .surveyProgressContainer {
    width: 30%;
    margin: 24px auto;
    height: 10px;
    border-radius: 8px;
    display: flex;
    .completed {
      height: 100%;
      background-color: #1fa7e0;
      border-radius: 8px 8px 8px 8px;
    }
    .completionInProgress {
      height: 100%;
      background-color: #1fa7e0;
      border-radius: 8px 0px 0px 8px;
    }
    .pending {
      height: 100%;
      background-color: #bde5f6;
      border-radius: 8px 8px 8px 8px;
    }
    .questionsStillUnanswered {
      height: 100%;
      background-color: #bde5f6;
      border-radius: 0px 8px 8px 0px;
    }
  }
  .questionsContentContainer {
    //height: 80%;
    .question_1 {
      //height: 100%;
      .questionOneImageWrapper {
        width: 60%;
        margin: 0 auto;
        padding: 16px 0px 16px 0px;
        height: 200px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .rating {
        display: flex;
        justify-content: center;
        margin-left: 45px;
      }
      .ratingHeading {
        width: 88%;
        text-align: center;
        margin: 12px auto 7px auto;
        font-size: 17px;
      }
      .starToRateHeading {
        width: 88%;
        text-align: center;
        margin: 5px auto 20px auto;
        font-size: 17px;
      }
      .ratingWrapper {
        width: 100%;
        margin: 25px 0px 0px 0px;
        padding: 0px 36px;
        .ratingNodes {
          display: flex;
          justify-content: space-between;
          .selected {
            width: 8%;
            text-align: center;
            border-radius: 50%;
            height: 37px;
            background: $zen-blue-default;
            color: #fff;
            border: none;
            font-size: 16px;
            padding: 8px;
            cursor: pointer;
          }
          .unselected {
            width: 8%;
            text-align: center;
            border-radius: 50%;
            height: 37px;
            background: #e2e0e0;
            color: black;
            border: none;
            font-size: 16px;
            padding: 8px;
            cursor: pointer;
          }
        }
      }
      .sliderTexts {
        display: flex;
        font-size: 16px;
        padding: 12px 0px 0px 0px;
        .leftText,
        .rightText {
          width: 50%;
        }
        .leftText {
          text-align: left;
        }
        .rightText {
          text-align: right;
        }
      }
      .firstQuestionNext {
        padding: 50px 0px 10px 0px;
        .next {
          width: 15%;
          margin: 4px auto 4px auto !important;
          display: block;
          text-transform: none;
          border-radius: 16px;
          letter-spacing: 0.1px;
          background-color: #21a7e0;
          color: #fff;
          font-size: 18px;
        }
      }
    }
    .goodRatingScenario {
      // height: 100%;
      .question_2 {
        // height: 100%;
        .questionTwoImageWrapper {
          width: 60%;
          margin: 0 auto;
          padding: 16px 0px 16px 0px;
          height: 280px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .ratingHeading {
          width: 85%;
          text-align: center;
          margin: 12px auto 24px auto;
          font-size: 18px;
        }
        .sliderRatingWrapper {
          width: 98%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 60px 0px 0px 0px;
          padding: 0px 24px 0px 24px;
          height: 0px;
          ::v-deep .vue-slider-dot-tooltip {
            border: 1px solid #21a7e0;
            background-color: #21a7e0;
            height: 36px;
            width: 36px;
            transform: translateY(-20%) translateY(-30px) translateX(-50%)
              rotate(45deg);
            border-radius: 50% 50% 0% 50%;
            div {
              padding: 6px;
              transform: rotate(-45deg);
              width: 32px;
              height: 32px;
              font-size: 11px;
              color: #fff;
              text-align: center;
            }
          }
        }
        .sliderTexts {
          display: flex;
          padding: 12px 10px 0px 20px;
          font-size: 16px;
          .leftText,
          .rightText {
            width: 50%;
          }
          .leftText {
            text-align: left;
          }
          .rightText {
            text-align: right;
          }
        }
        .secondQuestionNext {
          padding: 72px 0px 10px 0px;
          .next {
            width: 15%;
            margin: 0 auto !important;
            display: block;
            text-transform: none;
            border-radius: 16px;
            letter-spacing: 0.1px;
            background-color: #21a7e0;
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .question_3 {
        //height: 100%;
        .questionThreeImageWrapper {
          width: 60%;
          margin: 0 auto;
          padding: 16px 0px 16px 0px;
          height: 280px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .ratingHeading {
          width: 70%;
          text-align: center;
          margin: 12px auto 24px auto;
          font-size: 18px;
        }
        .sliderRatingWrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 60px 0px 0px 0px;
          padding: 0px 24px 0px 24px;
          height: 0px;
          ::v-deep .vue-slider-dot-tooltip {
            border: 1px solid #21a7e0;
            background-color: #21a7e0;
            height: 36px;
            width: 36px;
            transform: translateY(-20%) translateY(-30px) translateX(-50%)
              rotate(45deg);
            border-radius: 50% 50% 0% 50%;
            img {
              padding: 16%;
              transform: rotate(-45deg);
              text-align: center;
            }
          }
        }
        .sliderTexts {
          display: flex;
          padding: 12px 10px 0px 10px;
          font-size: 16px;
          .leftText,
          .rightText {
            width: 50%;
          }
          .leftText {
            text-align: left;
          }
          .rightText {
            text-align: right;
          }
        }
        .thirdQuestionNext {
          padding: 70px 0px 6px 0px;
          .next {
            width: 15%;
            margin: 4px auto 4px auto !important;
            display: block;
            text-transform: none;
            border-radius: 16px;
            letter-spacing: 0.1px;
            background-color: #21a7e0;
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .question_4 {
        height: 100%;
        .ratingHeading {
          width: 70%;
          text-align: center;
          margin: 0px auto 16px auto;
          font-size: 18px;
        }
        .questionFourSuggestionsListContainer {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 14px;
          grid-row-gap: 14px;
          padding: 0px 30px;
          .suggestionItem {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            border-radius: 8px;
            padding: 4px;
            cursor: pointer;
            .suggestionItemImageContainer {
              width: 14%;
              margin: 0 auto;
              height: 28px;
              .img {
                width: 100%;
              }
            }
            .suggestionText {
              text-align: center;
            }
          }
          .suggestionItemWhenSelected {
            background-color: #f5fcff;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            border-radius: 8px;
            padding: 4px;
            cursor: pointer;
            .suggestionItemImageContainer {
              width: 14%;
              margin: 0 auto;
              height: 28px;
              .img {
                width: 100%;
              }
            }
            .suggestionText {
              text-align: center;
            }
          }
        }
        .addSuggestionsToFourthQuestionContainer,
        .addSuggestionsToFourthQuestionContainerActive {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          border-radius: 8px;
          margin: 16px 30px;
          padding: 6px 10px 10px 10px;
          .textAreaHeader {
            display: flex;
            margin: 6px 0px;
            h3 {
              margin-top: 4px;
            }
          }
          .textAreaContainer {
            height: 90px;
            ::v-deep .v-input {
              height: 100%;
              .v-input__control {
                height: 100%;
                display: block;
                .v-input__slot {
                  padding: 0;
                  height: 100%;
                  .v-text-field__slot {
                    padding: 4px;
                    height: 100%;
                  }
                }
                .v-text-field__details {
                  padding: 0px;
                  margin: 20px 0px 8px 0px;
                  .v-messages {
                    .v-messages__wrapper {
                      .v-messages__message {
                        color: red;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .addSuggestionsToFourthQuestionContainerActive {
          background-color: #f5fcff;
        }
        .fourthQuestionSubmit {
          padding: 54px 0px 4px 0px;
          .submit {
            width: 15%;
            margin: 4px auto 4px auto !important;
            display: block;
            text-transform: none;
            border-radius: 16px;
            letter-spacing: 0.1px;
            background-color: #21a7e0;
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
    .badRatingScenario {
      height: 100%;
      .question_5 {
        height: 100%;
        .ratingHeading {
          width: 70%;
          text-align: center;
          margin: 0px auto 16px auto;
          font-size: 18px;
        }
        .questionFiveSuggestionsListContainer {
          padding: 0px 30px;
          .suggestionItem {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            border-radius: 8px;
            padding: 6px;
            margin-bottom: 8px;
            display: flex;
            cursor: pointer;
            .suggestionItemImageContainer {
            }
            .suggestionText {
              padding: 4px 4px 4px 8px;
            }
          }
          .suggestionItemWhenSelected {
            background-color: #f5fcff;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            border-radius: 8px;
            padding: 6px;
            margin-bottom: 8px;
            display: flex;
            cursor: pointer;
            .suggestionItemImageContainer {
            }
            .suggestionText {
              padding: 4px 4px 4px 8px;
            }
          }
        }
        .addSuggestionsToFifthQuestionContainer,
        .addSuggestionsToFifthQuestionContainerActive {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          border-radius: 8px;
          margin: 16px 30px;
          padding: 6px 10px 10px 10px;
          .textAreaHeader {
            display: flex;
            margin: 6px 0px;
            h3 {
              margin-top: 4px;
            }
          }
          .textAreaContainer {
            height: 90px;
            ::v-deep .v-input {
              height: 100%;
              .v-input__control {
                height: 100%;
                display: block;
                .v-input__slot {
                  padding: 0;
                  height: 100%;
                  .v-text-field__slot {
                    padding: 4px;
                    height: 100%;
                  }
                }
                .v-text-field__details {
                  padding: 0px;
                  margin: 20px 0px 8px 0px;
                  .v-messages {
                    .v-messages__wrapper {
                      .v-messages__message {
                        color: red;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .addSuggestionsToFifthQuestionContainerActive {
          background-color: #f5fcff;
        }
        .fifthQuestionSubmit {
          padding: 32px 0px 4px 0px;
          .submit {
            width: 15%;
            margin: 4px auto 4px auto !important;
            display: block;
            text-transform: none;
            border-radius: 16px;
            letter-spacing: none;
            background-color: #21a7e0;
            color: #fff;
            font-size: 17px;
            letter-spacing: normal;
          }
        }
      }
    }
    .thanksContainer {
      // height: 100%;
      width: 90vw;
      position: absolute;
      left: 10%;
      display: flex;
      .thanksContainerImageWrapper {
        width: 50%;
        margin: 0 auto;
        padding: 16px 0px 16px 0px;
        height: 550px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .thanksContainerHeading {
        justify-content: center;
        width: 50%;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        display: flex;
        .response {
          font-weight: bolder;
          margin: 20px;
        }
        .link {
          color: #21a7e0;
          margin: 0px 0px 0px 6px;
        }
        .loginPrezent {
          text-decoration: none;
        }
        .login {
          width: 25%;
          margin: 10px auto 10px auto !important;
          display: block;
          text-decoration: none;
          text-transform: none;
          border-radius: 20px;
          letter-spacing: 0.1px;
          background-color: #21a7e0;
          color: #fff;
          font-size: 18px;
        }
        .reachUsAt {
          margin: 10px;
        }
      }
    }
    .question_m2 {
      width: 100vw;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      .spinner {
        position: absolute;
        top: 40%;
        left: auto;
        right: auto;
      }
    }
    .question_m1 {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      .response {
        text-align: center;
      }
      .successMaill {
        color: #21a7e0;
      }
    }
    .question_0 {
      width: 75vw;
      position: absolute;
      left: 10%;
      display: flex;
      .thanksContainerImageWrapper {
        width: 55%;
        margin: 0 auto;
        padding: 16px 0px 16px 0px;
        height: 550px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .thanksContainerHeading {
        justify-content: center;
        width: 37%;
        display: flex;
        margin-left: 100px;
        flex-direction: column;
        font-size: 15px;
        display: flex;
        .response {
          font-weight: 400;
          font-size: 24px;
          margin: 40px 0 20px 0;
          text-align: center;
          font-family: 'Lato', sans-serif;
          line-height: normal;
        }
        .link {
          color: #21a7e0;
          margin: 0px 0px 0px 5px;
        }
        .login {
          margin: 0px auto 10px auto !important;
          display: block;
          text-transform: none;
          text-decoration: none;
          border-radius: 20px;
          letter-spacing: 0.1px;
          background-color: #21a7e0;
          color: #fff;
          font-size: 18px;
        }
        .reachUsAt {
          margin: 10px 0 10px 0;
          text-align: center;
          font-size: 13px;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  .actionButtonsContainer {
    .notNow {
      width: 15%;
      margin: 0 auto !important;
      display: block;
      text-transform: none;
      border-radius: 16px;
      letter-spacing: 0.1px;
      background-color: #fff;
      color: #21a7e0;
      border: none;
      box-shadow: none;
      font-size: 15px;
    }
  }
  .userDetails {
    color: #21a7e0;
    text-transform: capitalize;
    text-align: center;
  }
}
</style>
