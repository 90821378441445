<template>
  <div class="layout-views-container">
    <div class="icon-container">
      <div @click="handleView(false)">
        <v-icon
          :color="isColumnView ? '#000' : '#21A7E0'"
          v-tooltip="{
            content: $t('prezentationGallery.gridView'),
            placement: 'top-center',
          }"
          >{{
            isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid'
          }}</v-icon
        >
      </div>

      <div @click="handleView(true)">
        <v-icon
          :color="isColumnView ? '#21A7E0' : '#000'"
          v-tooltip="{
            content: $t('prezentationGallery.columnView'),
            placement: 'top-center',
          }"
          >{{
            isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline'
          }}</v-icon
        >
      </div>
    </div>
    <div class="layout-content">
      <template v-if="!loading && slides.length === 0">
        <div class="error-layout-container">
          <img :src="'/assets/img/redesign/no-layout-available.svg'" />
          <p class="no-layout-text">
            No {{ type }} layouts available for the selected slide
          </p>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(slide, index) in slidesAdjusted"
          :class="[{ 'column-view': isColumnView }, 'slide']"
          :key="index"
        >
          <v-img
            contain
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            :aspect-ratio="16 / 9"
            :class="{
              current:
                selectedSlideID &&
                (slide.unique_id === selectedSlideID ||
                  slide.id === selectedSlideID),
              disabled: slide.disabled,
            }"
            :src="getImgPath(slide)"
            loading="lazy"
            class="layout-image"
            @click="handleChangeLayout(slide)"
            v-if="!slide.loading"
          />
          <div v-else class="layout-skeleton">
            <Skeleton
              :type="slide.type"
              :view="isColumnView ? 'column' : 'list'"
            ></Skeleton>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
// import {
//   TD_COLUMN_VIEW,
//   TD_GRID_VIEW,
//   TD_VIEW_SELECTED,
//   TD_COMMON_COLUMN_VALUE,
//   TD_COMMON_COLUMN_NAME,
//   trackTimeSpentCommonEvents,
// } from './Analytics/MatomoTrackingDataHelper';
import Skeleton from './Skeleton.vue';

export default {
  name: 'RedesignChangeLayoutViews',
  components: { Skeleton },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    selectedSlideID: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  methods: {
    handleChangeLayout(slide) {
      this.$emit('change', slide);
    },
    handleView(value) {
      this.isColumnView = value;
      // const otherData = {
      //   [TD_COMMON_COLUMN_NAME]: TD_VIEW_SELECTED,
      //   [TD_COMMON_COLUMN_VALUE]: this.isColumnView
      //     ? TD_COLUMN_VIEW
      //     : TD_GRID_VIEW,
      // };
      // trackTimeSpentCommonEvents.trackSmartLayoutViewChange(
      //   this.currentUser,
      //   otherData,
      //   this.origin,
      // );
    },
    getImgPath(slide) {
      let path = '';
      if (slide && slide.landscapePath && this.isColumnView) {
        path = slide.landscapePath;
      } else if (slide && slide.thumbnailPath && !this.isColumnView) {
        path = slide.thumbnailPath;
      } else {
        path = '/assets/img/slides/placeholder-slide.svg';
      }
      return path;
    },
  },
  data() {
    return {
      isColumnView: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState('users', ['currentUser']),
    slidesAdjusted() {
      if (this.loading) {
        return [
          ...this.slides,
          ...Array(Math.max(8 - this.slides.length, 0))
            .fill(null)
            .map((_, ind) => ({
              loading: true,
              type: `type-${(ind % 6) + 1}`,
            })),
        ];
      }
      return [...this.slides];
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-views-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  .icon-container {
    display: flex;
    margin-right: 30px;
    column-gap: 10px;
    justify-content: flex-end;
    margin-bottom: 20px;

    div {
      cursor: pointer;
    }
  }

  .layout-content {
    overflow: auto;
    max-height: calc(100% - 70px);
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    .slide {
      width: calc(50% - 12px);
      .layout-image {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;

        &.current {
          border: 1.5px solid #21a7e0;
          cursor: default;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }
      .layout-skeleton {
        box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
        aspect-ratio: 16 / 9;

        ::v-deep .v-skeleton-loader .v-skeleton-loader__avatar,
        ::v-deep .v-skeleton-loader .v-skeleton-loader__button,
        ::v-deep .v-skeleton-loader .v-skeleton-loader__chip,
        ::v-deep .v-skeleton-loader .v-skeleton-loader__divider,
        ::v-deep .v-skeleton-loader .v-skeleton-loader__heading,
        ::v-deep .v-skeleton-loader .v-skeleton-loader__image,
        ::v-deep .v-skeleton-loader .v-skeleton-loader__text {
          background: linear-gradient(90deg, #e7f8fe, #9adffd);
        }
      }
    }
    .column-view {
      width: calc(100% - 20px);
      height: auto;
    }
  }
}
.error-layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .no-layout-text {
    text-align: center;
    margin-top: 16px;
    width: 70%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}
</style>
