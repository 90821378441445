<template>
  <div class="download-info-content">
    <div
      class="download-info-image-wrapper"
      :style="{ height: `${isLocalDownload ? '250px' : '170px'}` }"
    >
      <img :src="imageUrl" alt />
    </div>
    <div class="message">
      {{ $t(message) }} <b v-if="!isLocalDownload">{{ $t('common.save') }}</b>
    </div>
    <div v-if="loading" class="current-audience-preferences">
      <clip-loader color="#21a7e0" :width="20" :height="20" />
    </div>
    <div
      v-else-if="audience && showPreferences && !isTheCurrentUserTargetAudience"
      class="current-audience-preferences"
    >
      <div class="audience-info">
        <v-list-item-avatar size="26" class="profile-avatar">
          <v-img v-if="audience.profileImage" :src="audience.profileImage" />
          <div v-else>
            <v-avatar color="rgb(88, 168, 222)" size="26">
              <span class="profile-avatar--initial">
                {{ userInitials }}
              </span>
            </v-avatar>
          </div>
        </v-list-item-avatar>
        <span class="preferences-title">{{ preferencesTitle }}</span>
      </div>
      <div class="preferences">
        <span
          class="preference"
          v-for="(item, index) in personalPrefs"
          :key="index"
        >
          <span>
            <v-img :src="getPrefImage(item)" height="30px" width="30px" />
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span class="preference-heading" v-on="on">
                {{ item }}
              </span>
            </template>
            <span>{{ item }}</span>
          </v-tooltip>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';
import { getInitials, getFullName } from '@/utils/common';
import { fpSelections } from '@/mock/fingerprint';
import audienceApi from '../../API/audience-api';
import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'DownloadInfoContent',
  components: { ClipLoader },
  props: {
    audienceId: String,
    audienceOwnerId: String,
    imageUrl: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    isLocalDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audience: null,
      loading: false,
    };
  },

  mounted() {
    this.setPrezentationAudience();
  },
  computed: {
    ...mapState('users', ['currentUser']),
    preferencesTitle() {
      if (!this.audience) {
        return '';
      }
      const name = this.audience.firstName
        ? this.audience.firstName
        : this.audience.fullName;
      return this.$t('prezentation.dontForgetPreferences', {
        name: getFullName(name),
      });
    },
    personalPrefs() {
      return this.audience.personalPreference;
    },
    userInitials() {
      const { firstName, lastName } = this.audience;
      return getInitials(firstName, lastName);
    },
    isTheCurrentUserTargetAudience() {
      if (!this.audienceId || !this.currentUser) return false;
      return this.audienceId === this.currentUser.user.id;
    },
    showPreferences() {
      if (
        this.audience?.isPrivate &&
        this.audience?.ownerID === this.currentUser?.user?.id
      ) {
        return true;
      }
      if (this.audience?.companyID === this.currentUser?.company?.id) {
        return true;
      }
      if (this.audience?.isPublicLimited) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getPrefImage(item) {
      const selectedPref = fpSelections.find((prefs) => prefs.title === item);
      if (selectedPref?.image) return `/${selectedPref.image}`;
      return '/assets/img/preferences-added_by_user.svg';
    },
    async setPrezentationAudience() {
      if (this.audienceId && this.audienceId !== this.currentUser.user.id) {
        this.loading = true;
        const audience =
          await audienceApi.methods.getPrezentationAudienceTypesense(
            this.audienceId,
            this.audienceOwnerId,
            {
              fields: [
                'id',
                'email',
                'firstName',
                'lastName',
                'fullName',
                'profileImage',
                'personalPreference',
                'accessible_to',
                'companyID',
                'isPrivate',
                'ownerID',
              ],
            },
          );

        if (audience) {
          if (
            audience.profileImage &&
            !audience.profileImage.startsWith('/assets/img/')
          ) {
            audience.profileImage = await getFileURL(
              null,
              `public/${audience.profileImage}`,
              null,
              'storage',
            );
          }

          this.audience = audience;
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-info-content {
  .current-audience-preferences {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 50px;

    .audience-info {
      display: flex;
      align-items: center;

      .preferences-title {
        font-size: 16px;
      }
    }

    .preferences {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 8px;
      max-height: 250px;
      overflow: scroll;
      width: 100%;
      .preference {
        align-items: center;
        background: #ffffff;
        display: flex;
        justify-content: flex-start;
        border-radius: 4px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        color: #212121;
        font-family: 'Lato';
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin: 5px 0px 10px 0;
        padding: 6px 16px;
        white-space: nowrap;
        height: 50px;
        width: 75%;
        .preference-heading {
          padding-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .v-image {
          margin: 0 10px;
        }
      }
    }

    .profile-avatar {
      margin-right: 8px !important;

      &--initial {
        color: white;
        font-size: 14px;
        font-weight: 250;
      }
    }
  }
  .download-info-image-wrapper {
    width: 75%;
    margin: 32px auto 32px auto;
    // height: 170px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .message {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 0px 0px 4px 0px;
    text-align: center;
  }
}
</style>
