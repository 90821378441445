import { render, staticRenderFns } from "./GdriveTransitionV2.vue?vue&type=template&id=6bc6e4a2&scoped=true&"
import script from "./GdriveTransitionV2.vue?vue&type=script&lang=js&"
export * from "./GdriveTransitionV2.vue?vue&type=script&lang=js&"
import style0 from "./GdriveTransitionV2.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./GdriveTransitionV2.vue?vue&type=style&index=1&id=6bc6e4a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc6e4a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VIcon,VProgressCircular})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
