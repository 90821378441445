<template>
  <div>
    <v-radio-group
      v-model="selectedFilter"
      @change="handleChangeFilter()"
      column
    >
      <v-radio
        v-for="(option, index) in filterItems"
        :key="index"
        :label="option.title"
        :value="option"
        color="primary"
      />
    </v-radio-group>
  </div>
</template>

<script>
import EventBus from '../../event-bus';

export default {
  computed: {
    filterItems: {
      get() {
        return this.$store.state.advancedFilterStore.op.filterItems;
      },
      set() {},
    },
    selectedFilter: {
      get() {
        return this.$store.state.advancedFilterStore.op.selectedFilter;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setSelectedFilter', value);
      },
    },
  },
  methods: {
    handleChangeFilter() {
      EventBus.$emit('CHANGE_SORT_FILTER');
    },
  },
};
</script>
