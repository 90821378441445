import { trackCommonEvent } from './MatomoTrackingDataHelper';

const TRIALS_EVENT_CATEGORY = 'Trials';
const UPGRADE_BANNER_ON_TOP_CLICK = 'upgrade-banner-on-top_click';
const UPGRADE_POPUP_CLICK = 'upgrade-popup_click';
const SUBSCRIPTION_EXPIRY_POPUP_UPGRADE_CLICK =
  'subscription-expiry-popup_upgrade_click';
const SUBSCRIPTION_EXPIRY_POPUP_RENEW_CLICK =
  'subscription-expiry-popup_renew_click';

export const trackTrialsEvents = {
  upgradeBannerOnTopClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: TRIALS_EVENT_CATEGORY,
        eventAction: UPGRADE_BANNER_ON_TOP_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  upgradePopupClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: TRIALS_EVENT_CATEGORY,
        eventAction: UPGRADE_POPUP_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  subscriptionExpiryPopupUpgradeClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: TRIALS_EVENT_CATEGORY,
        eventAction: SUBSCRIPTION_EXPIRY_POPUP_UPGRADE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  subscriptionExpiryPopupRenewClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: TRIALS_EVENT_CATEGORY,
        eventAction: SUBSCRIPTION_EXPIRY_POPUP_RENEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
