<template>
  <div class="slide-collection-download-row">
    <v-tooltip top max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <span class="statusText" v-bind="attrs" v-on="on">
          {{ status }}
        </span>
      </template>
      <span>{{ status }}</span>
    </v-tooltip>
    <span
      class="completed-icon-container"
      v-if="itemToBeDownloaded.status === 'complete'"
    >
      <v-tooltip top max-width="250" v-if="showNavIcon">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="getNavItem.navFn"
            class="completedIconStatus"
          >
            <component
              :is="iconMap[itemToBeDownloaded.originType]"
              :iconWidth="24"
              :iconHeight="24"
              :iconColor="'#21a7e0'"
              :iconStrokeWidth="0.25"
            />
          </v-btn>
        </template>
        <span>{{ $t(getNavItem.tooltipContent) }}</span>
      </v-tooltip>
      <v-icon color="primary" class="completedCheckStatus" v-else>
        {{ 'mdi-check-circle' }}
      </v-icon>
    </span>
    <v-icon
      color="primary"
      v-else-if="itemToBeDownloaded.status === 'cancelled'"
    >
      {{ 'mdi-close-circle' }}
    </v-icon>
    <v-icon v-else-if="itemToBeDownloaded.status === 'failed'" color="#f70000">
      {{ 'mdi-alert' }}
    </v-icon>
    <v-progress-circular
      v-else-if="itemToBeDownloaded.status === 'downloading'"
      :rotate="-90"
      :value="downloadPercentage"
      color="primary"
      :width="3"
      :size="24"
    ></v-progress-circular>
    <v-hover v-slot="{ hover }" v-else>
      <span>
        <v-progress-circular
          v-if="!hover"
          :rotate="-90"
          :value="0"
          color="primary"
          :width="3"
          :size="24"
        ></v-progress-circular>
        <v-tooltip right max-width="250" v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="cancelDownload()"
            >
              {{ 'mdi-close-circle-outline' }}
            </v-icon>
          </template>
          {{ $t('common.cancel') }}&nbsp;{{ $t('common.downloadText') }}
        </v-tooltip>
      </span>
    </v-hover>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
// import { v4 as uuidv4 } from 'uuid';
import {
  // capitalizeFirstLetter,
  downloadFileWithCustomName,
  downloadFileWithAPI,
} from '../../utils/common';
import { miscConfig } from '../../runtimeConfig';
import { callMergeSlidesApi } from '../../utils/merge-slide-helper';
import {
  downloadComplyDeck,
  downloadFinalOPPPT,
  getFeatureUsage,
  postFeatureUsage,
} from '../../utils/api-helper';
import { getFileURL } from '@/utils/calyrex';
import { getFileURLDownload } from '@/utils/calyrex-test';
// import CommonDownloadItemCancelPopup from './CommonDownloadItemCancelPopup.vue';
import DirectDownloadInfo from './DirectDownloadInfo.vue';
import audienceApi from '../../API/audience-api';
import { getLoggedInUserToken } from '../../utils/api';

import SlideLibIcon from './Icons/SlideLibIcon.vue';
import PrezentationsExIcon from './Icons/PrezentationsExIcon.vue';
import BpeIcon from './Icons/BpeIcon.vue';
import OpIcon from './Icons/OpIcon.vue';

export default {
  name: 'CommonDownloadSnackbarItem',
  components: {},
  props: {
    itemToBeDownloaded: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      downloadPercentage: 0,
      iconMap: {
        slideLibDetail: SlideLibIcon,
        slideLibCard: SlideLibIcon,
        slideLibCardHybridSearch: SlideLibIcon,
        slideLibDetailReplaceVisuals: SlideLibIcon,
        slideLibCardHybridSearchMergeSlide: SlideLibIcon,
        bpeSlideDetail: BpeIcon,
        bpeSlideCard: BpeIcon,
        bpLibraryDecks: BpeIcon,
        prezLibrary: PrezentationsExIcon,
        prezLibraryDetail: PrezentationsExIcon,
        opDecks: OpIcon,
        opDecksDetail: OpIcon,
      },
      postDownloadInteractableItems: [
        {
          type: 'slideLibDetail',
          tooltipContent: 'snackBarItems.showInSlideLibrary',
          navFn: () => this.handleNavigate('slideLanding'),
        },
        {
          type: 'slideLibCard',
          tooltipContent: 'snackBarItems.showInSlideLibrary',
          navFn: () => this.handleNavigate('slideLanding'),
        },
        {
          type: 'slideLibCardHybridSearch',
          tooltipContent: 'snackBarItems.showInSlideLibrary',
          navFn: () => this.handleNavigate('slideLanding'),
        },
        {
          type: 'bpeSlideDetail',
          tooltipContent: 'snackBarItems.showInBpLibrary',
          navFn: () => this.handleNavigate('bpLandingSlides'),
        },
        {
          type: 'bpeSlideCard',
          tooltipContent: 'snackBarItems.showInBpLibrary',
          navFn: () => this.handleNavigate('bpLandingSlides'),
        },
        // {
        //   type: 'slideLibDetailReplaceVisuals',
        //   tooltipContent: 'snackBarItems.showInSlideLibrary',
        //   navFn: () => this.goToSlidesLibrary(),
        // },
        {
          type: 'slideLibCardHybridSearchMergeSlide',
          tooltipContent: 'snackBarItems.showInSlideLibrary',
          navFn: () => this.handleNavigate('slideLanding'),
        },
        {
          type: 'prezLibrary',
          tooltipContent: 'snackBarItems.showInPrezLibrary',
          navFn: () => this.handleNavigate('prezLanding'),
        },
        {
          type: 'prezLibraryDetail',
          tooltipContent: 'snackBarItems.showInPrezLibrary',
          navFn: () => this.handleNavigate('prezLanding'),
        },
        {
          type: 'bpLibraryDecks',
          tooltipContent: 'snackBarItems.showInBpLibrary',
          navFn: () => this.handleNavigate('bpLandingDecks'),
        },
        {
          type: 'opDecks',
          tooltipContent: 'snackBarItems.showInOp',
          navFn: () => this.handleNavigate('opLanding'),
        },
        {
          type: 'opDecksDetail',
          tooltipContent: 'snackBarItems.showInOp',
          navFn: () => this.handleNavigate('opLanding'),
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapState('users', [
      'currentUser',
      'limitsInfoObj',
      'startLoadingSlidesFlag',
      'themes',
    ]),
    ...mapState('prezentationDetails', ['actionCompletedInfo']),
    ...mapGetters('commonDownloads', ['getDownloadItems']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },

    getNavItem() {
      const item = this.postDownloadInteractableItems.find(
        (itm) => itm.type === this.itemToBeDownloaded.originType,
      );
      if (item) {
        return item;
      }
      return null;
    },

    showNavIcon() {
      return [
        'slideLibDetail',
        'slideLibCard',
        'slideLibCardHybridSearch',
        'bpeSlideDetail',
        'bpeSlideCard',
        // 'slideLibDetailReplaceVisuals',
        'slideLibCardHybridSearchMergeSlide',
        'prezLibrary',
        'prezLibraryDetail',
        'bpLibraryDecks',
        'opDecks',
        'opDecksDetail',
      ].includes(this.itemToBeDownloaded.originType);
    },

    getAssemblingEndText() {
      if (
        [
          'slideLibDetail',
          'slideLibCard',
          'slideLibCardHybridSearch',
          'bpeSlideDetail',
          'bpeSlideCard',
          'slideLibDetailReplaceVisuals',
          'slideLibCardHybridSearchMergeSlide',
          'synthesisDownload',
        ].includes(this.itemToBeDownloaded.originType)
      ) {
        return 'snackBarItems.slideText';
      }
      if (
        [
          'prezLibrary',
          'prezLibraryDetail',
          'bpLibraryDecks',
          'opDecks',
          'opDecksDetail',
          'slideCollections',
        ].includes(this.itemToBeDownloaded.originType)
      ) {
        return 'snackBarItems.slidesText';
      }
      if (
        ['generateDownload', 'redesignDownload', 'tcDownload'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        if (this.itemToBeDownloaded?.metaData?.noofslides > 1) {
          return 'snackBarItems.slidesText';
        }
        return 'snackBarItems.slideText';
      }
      return 'snackBarItems.slideText';
    },

    getFileTypeForHeader() {
      if (
        [
          'slideLibDetail',
          'slideLibCard',
          'slideLibCardHybridSearch',
          'bpeSlideDetail',
          'bpeSlideCard',
          'slideLibDetailReplaceVisuals',
          'slideLibCardHybridSearchMergeSlide',
          'synthesisDownload',
        ].includes(this.itemToBeDownloaded.originType)
      ) {
        return 'snackBarItems.slideText';
      }
      if (
        [
          'prezLibrary',
          'prezLibraryDetail',
          'bpLibraryDecks',
          'opDecks',
          'opDecksDetail',
          'slideCollections',
        ].includes(this.itemToBeDownloaded.originType)
      ) {
        return 'snackBarItems.fileText';
      }
      if (
        ['generateDownload', 'redesignDownload', 'tcDownload'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        if (this.itemToBeDownloaded?.metaData?.noofslides > 1) {
          return 'snackBarItems.fileText';
        }
        return 'snackBarItems.slideText';
      }
      return 'snackBarItems.fileText';
    },

    status() {
      let header;
      switch (this.itemToBeDownloaded.status) {
        case 'assembling':
          header = `${this.$t('snackBarItems.assemblingSlides', {
            Name: this.itemToBeDownloaded.metaData.fileName,
          })} ${this.$t(this.getAssemblingEndText)}`;
          break;
        case 'downloading':
          header = `${this.$t('snackBarItems.downloadingFile', {
            Name: this.itemToBeDownloaded.metaData.fileName,
          })} ${this.$t(this.getFileTypeForHeader)}`;
          break;
        case 'complete':
          header = `${this.itemToBeDownloaded.metaData.fileName} ${this.$t(
            this.getFileTypeForHeader,
          )} ${this.$t('snackBarItems.fileDownloaded')}`;
          break;
        case 'cancelled':
          header = `${this.itemToBeDownloaded.metaData.fileName} ${this.$t(
            this.getFileTypeForHeader,
          )} ${this.$t('snackBarItems.fileDownloadCancelled')}`;
          break;
        case 'failed':
          header = `${this.itemToBeDownloaded.metaData.fileName} ${this.$t(
            this.getFileTypeForHeader,
          )} ${this.$t('snackBarItems.fileDownloadFailed')}`;
          break;
        default:
          header = this.$t('snackBarItems.assemblingSlides', {
            Name: this.itemToBeDownloaded.metaData.fileName,
          });
          break;
      }
      return header;
    },

    isPrezentation() {
      return this.itemToBeDownloaded?.metaData?.origin === 'prezentation';
    },
  },
  methods: {
    ...mapActions('commonDownloads', [
      'updateDownloadItemStatus',
      'setNavFromDownloadSnackbarOP',
      'setNavFromDownloadSnackbarSlides',
      'setNavFromDownloadSnackbarBPSld',
      'setNavFromDownloadSnackbarBPDck',
      'setNavFromDownloadSnackbarPrezs',
    ]),
    ...mapActions('users', [
      'setCurrentUser',
      'setLimitData',
      'setUpgradePopup',
    ]),

    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),

    assignRoutesWhenDirty(itemType) {
      if (itemType === 'slideLanding') {
        this.setNavFromDownloadSnackbarSlides({
          navigated: true,
          type: 'slides',
        });
        this.setRouteToNavigate({
          content: '/home/slides',
          type: 'snck_to_slides_page',
          action: 'push',
          pushAction: true,
        });
      } else if (itemType === 'prezLanding') {
        this.setNavFromDownloadSnackbarPrezs({
          navigated: true,
          type: 'prezentations',
        });
        this.setRouteToNavigate({
          content: '/home/prezentations',
          type: 'snck_to_prezentations_page',
          action: 'push',
          pushAction: true,
        });
      } else if (itemType === 'opLanding') {
        this.setNavFromDownloadSnackbarOP({
          navigated: true,
          type: 'opMyRequests',
        });
        this.setRouteToNavigate({
          content: '/home/overnight-prezentations?tab=myRequest',
          type: 'snck_to_op_page',
          action: 'push',
          pushAction: true,
        });
      } else if (itemType === 'bpLandingSlides') {
        this.setNavFromDownloadSnackbarBPSld({
          navigated: true,
          type: 'bpSlides',
        });
        this.setRouteToNavigate({
          content: '/home/best-practice-library',
          type: 'snck_to_bp_slides_page',
          action: 'push',
          pushAction: true,
        });
      } else if (itemType === 'bpLandingDecks') {
        this.setNavFromDownloadSnackbarBPDck({
          navigated: true,
          type: 'bpDecks',
        });
        this.setRouteToNavigate({
          content: '/home/best-practice-library',
          type: 'snck_to_bp_decks_page',
          action: 'push',
          pushAction: true,
        });
      }
    },

    handleNavigate(itemType) {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        // showing confirmation popup on download item click when in slide detail, some flow is dirty
        this.setSlideViewConfirmationModalState(true);
        this.assignRoutesWhenDirty(itemType);
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });

        // navigating to respective landing pages when no flow under slide detail is dirty
        if (itemType === 'slideLanding') {
          this.goToSlidesLibrary();
        } else if (itemType === 'prezLanding') {
          this.goToPrezentationLibrary();
        } else if (itemType === 'opLanding') {
          this.goToOpMyRequests();
        } else if (itemType === 'bpLandingSlides') {
          this.goToBpLibrarySlides();
        } else if (itemType === 'bpLandingDecks') {
          this.goToBpLibraryDecks();
        }
      }
    },

    goToPrezentationLibrary() {
      this.setNavFromDownloadSnackbarPrezs({
        navigated: true,
        type: 'prezentations',
      });
      this.$router.push('/home/prezentations').catch(() => {});
    },

    goToOpMyRequests() {
      this.setNavFromDownloadSnackbarOP({
        navigated: true,
        type: 'opMyRequests',
      });
      this.$router
        .push('/home/overnight-prezentations?tab=myRequest')
        .catch(() => {});
    },

    goToSlidesLibrary() {
      this.setNavFromDownloadSnackbarSlides({
        navigated: true,
        type: 'slides',
      });
      this.$router.push('/home/slides').catch(() => {});
    },

    goToBpLibraryDecks() {
      this.setNavFromDownloadSnackbarBPDck({
        navigated: true,
        type: 'bpDecks',
      });
      this.$router.push('/home/best-practice-library').catch(() => {});
    },

    goToBpLibrarySlides() {
      this.setNavFromDownloadSnackbarBPSld({
        navigated: true,
        type: 'bpSlides',
      });
      this.$router.push('/home/best-practice-library').catch(() => {});
    },

    trackDownloadProgressInPercentage(value) {
      this.downloadPercentage = value;
    },

    downloadInProgress(flag = true) {
      if (flag) {
        this.updateDownloadItemStatus({
          itemIndex: this.itemToBeDownloaded.itemIndex,
          newStatus: 'downloading',
        });
      } else if (this.itemToBeDownloaded.downloadStatusCallback) {
        this.itemToBeDownloaded.downloadStatusCallback();
        this.updateDownloadItemStatus({
          itemIndex: this.itemToBeDownloaded.itemIndex,
          newStatus: 'complete',
        });
        if (this.itemToBeDownloaded.logDownloadCallback) {
          this.itemToBeDownloaded.logDownloadCallback();
        }
      }
    },

    cancelDownload() {
      // this.$modal.show(
      //   CommonDownloadItemCancelPopup,
      //   {
      //     downloadItem: this.itemToBeDownloaded,
      //   },
      //   {
      //     name: 'CommonDownloadItemCancelPopup',
      //     width: '500px',
      //     height: 'auto',
      //   },
      // );

      const dlItem = this.getDownloadItems.find(
        (dl) => dl.itemIndex === this.itemToBeDownloaded.itemIndex,
      );
      if (dlItem && dlItem.status && dlItem.status === 'assembling') {
        this.updateDownloadItemStatus({
          itemIndex: this.itemToBeDownloaded.itemIndex,
          newStatus: 'cancelled',
        });
        if (this.itemToBeDownloaded.cancelDownloadCallBack) {
          this.itemToBeDownloaded.cancelDownloadCallBack();
        }
        if (this.itemToBeDownloaded.downloadStatusCallback) {
          this.itemToBeDownloaded.downloadStatusCallback();
        }
      }
    },

    async getMergeSlidesUrl(payload, onlyGetMergedSlidePath) {
      let mergedUrl = '';
      await callMergeSlidesApi(payload)
        .then((res) => {
          const mergedslide = res.data;
          if (
            mergedslide.includes('.pptx') &&
            [
              'prezLibrary',
              'prezLibraryDetail',
              'bpLibraryDecks',
              'generateDownload',
            ].includes(this.itemToBeDownloaded.originType) &&
            this.itemToBeDownloaded.mergeSlidesCallback
          ) {
            this.itemToBeDownloaded.mergeSlidesCallback(mergedslide);
          }
          mergedUrl = onlyGetMergedSlidePath
            ? mergedslide
            : `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
        })
        .catch((err) => {
          console.log(err);
          this.updateFailStatusOnDownloadFailure();
        });
      return mergedUrl;
    },

    updateFailStatusOnDownloadFailure() {
      this.updateDownloadItemStatus({
        itemIndex: this.itemToBeDownloaded.itemIndex,
        newStatus: 'failed',
      });
      if (this.itemToBeDownloaded.downloadStatusCallback) {
        this.itemToBeDownloaded.downloadStatusCallback();
      }
    },

    getSlideDownloadCount(list) {
      let noofslides = 0;
      let knownoofslides = 0;
      list?.forEach((sec) => {
        sec.slides?.forEach((slide) => {
          if (
            this.isPrezentation &&
            ((slide.prefs && slide.prefs && slide.prefs.is_know === 'yes') ||
              slide.isAddedFromBestpracticeDecks)
          ) {
            knownoofslides++;
          } else if (slide.assetId) {
            noofslides += 1;
          }
        });
      });
      return { noofslides, knownoofslides };
    },

    getUsageDataObj(downloadCount, userData) {
      return downloadCount.knownoofslides
        ? {
            ...userData,
            noofslides: downloadCount.noofslides,
            knownoofslides: downloadCount.knownoofslides,
          }
        : {
            ...userData,
            noofslides: downloadCount.noofslides,
          };
    },

    async getDeckAudience() {
      const audience =
        await audienceApi.methods.getPrezentationAudienceTypesense(
          this.itemToBeDownloaded?.metaData?.audienceID,
          this.itemToBeDownloaded?.metaData?.audienceOwnerID,
          {
            fields: [
              'id',
              'email',
              'firstName',
              'lastName',
              'fullName',
              'accessible_to',
              'companyID',
              'isPrivate',
              'ownerID',
            ],
          },
        );
      if (audience) {
        return audience;
      }
      return null;
    },

    showPopupForAud(aud) {
      if (!aud) {
        return false;
      }
      if (aud?.isPrivate && aud?.ownerID === this.currentUser?.user?.id) {
        return true;
      }
      if (aud?.companyID === this.currentUser?.company?.id) {
        return true;
      }
      if (aud?.isPublicLimited) {
        return false;
      }
      return true;
    },

    async initFeatureUsageChecksAndAudValidity() {
      // checking feature usage and updating usage before downloading any asset

      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      let key = 'slide';
      let userData = { feature: this.itemToBeDownloaded?.metaData?.limitsKey };

      // if (this.limitsKey === 'bpe-slide') {
      //   key = 'bpe-slide';
      // }

      if (this.itemToBeDownloaded?.metaData?.limitsKey === 'bpe-slide') {
        key = 'bpe-slide';
      }

      if (this.itemToBeDownloaded?.metaData?.limitsKey === 'bpe-deck') {
        key = 'bpe-slide';
      }

      if (
        this.itemToBeDownloaded?.metaData?.limitsKey === 'bpe-deck' ||
        this.itemToBeDownloaded?.metaData?.limitsKey === 'prezentation'
      ) {
        // this.item not having 'noofslides' from build step3
        if (this.itemToBeDownloaded?.metaData?.noofslides) {
          userData = {
            ...userData,
            noofslides: this.itemToBeDownloaded?.metaData?.noofslides,
          };
        } else {
          const downloadCount = this.getSlideDownloadCount(
            this.itemToBeDownloaded?.metaData?.sections?.list,
          );
          userData = this.getUsageDataObj(downloadCount, userData);
        }
        if (
          this.isPrezentation &&
          !this.itemToBeDownloaded?.metaData?.noofslides
        ) {
          const downloadCount = this.getSlideDownloadCount(
            this.itemToBeDownloaded?.metaData?.sections?.list,
          );
          userData = this.getUsageDataObj(downloadCount, userData);
        }
      }

      if (
        !this.limitsInfoObj?.[key]?.isLimitCrossed ||
        this.currentUser?.user?.role !== 'TrialUser'
      ) {
        postFeatureUsage(userData)
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));

        let aud = {};

        /* Adding a check for audienceID as some DT BPE Decks do not have it.
        Prezentation Decks may have Slides in Designer Template but will have audienceID
        */
        if (
          this.isPrezentation &&
          this.itemToBeDownloaded?.metaData?.audienceID
        ) {
          aud = await this.getDeckAudience();
        }

        if (
          this.isPrezentation &&
          this.itemToBeDownloaded?.metaData?.audienceID &&
          this.itemToBeDownloaded?.metaData?.audienceID?.toLowerCase() !==
            'custom' &&
          this.itemToBeDownloaded?.metaData?.audienceID !==
            this.currentUser?.user?.id &&
          this.showPopupForAud(aud)
        ) {
          this.$modal.show(
            DirectDownloadInfo,
            {
              dialogContent: null,
              audienceId: this.itemToBeDownloaded?.metaData?.audienceID,
              audienceOwnerId:
                this.itemToBeDownloaded?.metaData?.audienceOwnerID,
              isLocalDownload: true,
            },
            {
              name: 'DirectDownloadInfo',
              width: '500px',
              height: 'auto',
            },
          );
        }

        // starting download after usage checks and audience validity for decks
        await this.initDownloadItem();
      } else {
        this.setUpgradePopup({
          upgradePopup: true,
          popupType: this.itemToBeDownloaded.metaData?.limitsKey,
        });
      }
    },

    async initDownloadItem() {
      if (
        ['prezLibrary', 'prezLibraryDetail', 'bpLibraryDecks'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        let s = {
          sections: this.itemToBeDownloaded.metaData.sections,
        };
        if (
          this.itemToBeDownloaded.metaData.isRestricted ||
          this.itemToBeDownloaded.metaData.permission_level === 0
        ) {
          s = {
            ...s,
            outputFormat: 'pdf',
          };
        }

        const s3url = await this.getMergeSlidesUrl(s, true);
        const { fileName } = this.itemToBeDownloaded.metaData;

        if (this.itemToBeDownloaded.status !== 'cancelled') {
          this.updateDownloadItemStatus({
            itemIndex: this.itemToBeDownloaded.itemIndex,
            newStatus: 'downloading',
          });
          await downloadFileWithAPI(
            'prezentation',
            s3url,
            `${fileName}${
              this.itemToBeDownloaded.metaData.isRestricted ||
              this.itemToBeDownloaded.metaData.permission_level === 0
                ? '.pdf'
                : '.pptx'
            }`,
            '',
            this.trackDownloadProgressInPercentage,
          )
            .then(() => {
              if (this.itemToBeDownloaded.downloadStatusCallback) {
                this.itemToBeDownloaded.downloadStatusCallback();
                this.updateDownloadItemStatus({
                  itemIndex: this.itemToBeDownloaded.itemIndex,
                  newStatus: 'complete',
                });
                if (this.itemToBeDownloaded.logDownloadCallback) {
                  this.itemToBeDownloaded.logDownloadCallback();
                }
              }
            })
            .catch(() => {
              this.updateFailStatusOnDownloadFailure();
            });
        }
      } else if (
        ['opDecks', 'opDecksDetail'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        await downloadFinalOPPPT(this.itemToBeDownloaded.metaData.ticketId)
          .then((resp) => {
            if (this.itemToBeDownloaded.status !== 'cancelled') {
              downloadFileWithCustomName(
                resp.attachmentUrl,
                this.itemToBeDownloaded.metaData.fileName,
                this.downloadInProgress,
                true,
                this.updateFailStatusOnDownloadFailure,
                this.trackDownloadProgressInPercentage,
              );
            }
          })
          .catch((err) => {
            this.updateFailStatusOnDownloadFailure();
            if (this.itemToBeDownloaded.errorCallback) {
              this.itemToBeDownloaded.errorCallback(err);
            }
          });
      } else if (
        ['slideCollections'].includes(this.itemToBeDownloaded.originType)
      ) {
        const s = {
          slides: [],
        };
        this.itemToBeDownloaded.metaData.slides.forEach((slide) => {
          s.slides.push({
            slideId:
              slide.slideFileName.charAt(0) === '/'
                ? slide.slideFileName.substring(1)
                : slide.slideFileName,
            isReplaced:
              slide.slideFileName.startsWith(
                '/newImageReplaceOutput/output/',
              ) || slide.slideFileName.startsWith('/output/pptx/'),
            isUploaded: slide.isUploaded ? slide.isUploaded : false,
            isGenerated: slide.isGenerated ? slide.isGenerated : false,
            isSynthesis: slide.isSynthesis ? slide.isSynthesis : false,
            isRedesign: slide.isRedesign ? slide.isRedesign : false,
          });
        });

        const s3url = await this.getMergeSlidesUrl(s, false);
        const { fileName } = this.itemToBeDownloaded.metaData;

        if (this.itemToBeDownloaded.status !== 'cancelled') {
          downloadFileWithCustomName(
            s3url,
            fileName,
            this.downloadInProgress,
            true,
            this.updateFailStatusOnDownloadFailure,
            this.trackDownloadProgressInPercentage,
          );
        }
      } else if (
        ['generateDownload', 'redesignDownload'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        const s3url = await this.getMergeSlidesUrl(
          this.itemToBeDownloaded.metaData.payload,
          false,
        );
        const { fileName } = this.itemToBeDownloaded.metaData;

        if (this.itemToBeDownloaded.status !== 'cancelled') {
          downloadFileWithCustomName(
            s3url,
            fileName,
            this.downloadInProgress,
            true,
            this.updateFailStatusOnDownloadFailure,
            this.trackDownloadProgressInPercentage,
          );
        }
      } else if (
        ['synthesisDownload'].includes(this.itemToBeDownloaded.originType)
      ) {
        let fileUrl;
        if (
          this.itemToBeDownloaded.metaData.slide_path.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.itemToBeDownloaded.metaData.slide_path.startsWith(
            '/output/pptx/',
          )
        ) {
          fileUrl = await getFileURLDownload(
            this.currentUser.user.id,
            this.itemToBeDownloaded.metaData.slide_path,
            this.currentUser.userIp,
          );
        } else {
          fileUrl = await getFileURL(
            this.currentUser.user.id,
            this.itemToBeDownloaded.metaData.slide_path,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }

        const { fileName } = this.itemToBeDownloaded.metaData;

        if (this.itemToBeDownloaded.status !== 'cancelled') {
          downloadFileWithCustomName(
            fileUrl,
            fileName,
            this.downloadInProgress,
            true,
            this.updateFailStatusOnDownloadFailure,
            this.trackDownloadProgressInPercentage,
          );
        }
      } else if (['tcDownload'].includes(this.itemToBeDownloaded.originType)) {
        const s = {
          slides: [],
          outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
          outputPath: `private/template-converter/comply/${this.itemToBeDownloaded.metaData?.uploadId}/mergeslides/`,
        };
        const slides = Object.values(
          this.itemToBeDownloaded.metaData?.slide_images || {},
        );
        slides.forEach((slide) => {
          s.slides.push({
            slideId:
              slide?.pptx?.s3_path?.charAt(0) === '/'
                ? slide?.pptx?.s3_path?.substring(1)
                : slide?.pptx?.s3_path,
            isReplaced: false,
            isUploaded: false,
            isGenerated: false,
            isSynthesis: false,
            isRedesign: false,
            isComply: true,
          });
        });

        const s3url = await this.getMergeSlidesUrl(s, true);
        const { fileName } = this.itemToBeDownloaded.metaData;
        const url = `private/template-converter/comply/${this.itemToBeDownloaded.metaData?.uploadId}/mergeslides/${s3url}`;

        downloadComplyDeck(this.itemToBeDownloaded.metaData?.uploadId, url)
          .then(async () => {
            const fileUrl = await getFileURL(
              this.currentUser.user.id,
              url,
              this.currentUser.userIp,
              'IOCUpload',
            );
            if (this.itemToBeDownloaded.status !== 'cancelled') {
              downloadFileWithCustomName(
                fileUrl,
                fileName,
                this.downloadInProgress,
                true,
                this.updateFailStatusOnDownloadFailure,
                this.trackDownloadProgressInPercentage,
              );
            }
          })
          .catch(() => {
            this.updateFailStatusOnDownloadFailure();
          });
      } else if (
        ['slideLibDetail', 'slideLibCard', 'slideLibCardHybridSearch'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        // add slide download here
        console.log('slide download');

        const { item, filePath, fileName } = this.itemToBeDownloaded.metaData;

        let fileUrl = '';
        let fileType = 'normal';
        if (item.prefs.source === 'uploaded') {
          fileUrl = await getFileURL(
            this.currentUser.user.id,
            filePath,
            this.currentUser.userIp,
            'IOCUpload',
          );
        } else if (
          item.prefs.source === 'generated' ||
          item.prefs.source === 'redesign' ||
          item.prefs.source === 'synthesis'
        ) {
          fileType = 'asset';
          if (
            filePath.startsWith('/newImageReplaceOutput/output/') ||
            filePath.startsWith('/output/pptx/')
          ) {
            fileUrl = await getFileURLDownload(
              this.currentUser.user.id,
              filePath,
              this.currentUser.userIp,
            );
          } else {
            fileUrl = await getFileURL(
              this.currentUser.user.id,
              filePath,
              this.currentUser.userIp,
              'IOCUpload',
            );
          }
          // filepath contains protected/premium-feature/tc are from BPE slides Convert Template
        } else if (
          filePath.indexOf('/comply/') !== -1 ||
          filePath.indexOf('protected/premium-feature/tc/') !== -1
        ) {
          const awsAssetsDistribution =
            process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
          const token = await getLoggedInUserToken();
          fileUrl = `${awsAssetsDistribution}${
            filePath.startsWith('/') ? '' : '/'
          }${filePath}?accesskey=${token}`;
        } else {
          fileUrl = await getFileURL(
            this.currentUser.user.id,
            filePath,
            this.currentUser.userIp,
          );
        }

        if (this.actionCompletedInfo?.isTcCompleted) {
          fileType = 'asset';
        }

        console.log(fileUrl, fileName);
        const isRestricted =
          item?.prefs?.isRestricted ||
          item?.prefs?.permission_level === 0 ||
          item?.permission === 'Restricted';
        let mergePath;
        if (isRestricted) {
          const s = {
            outputFormat: 'pdf',
            outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
            slides: [
              {
                slideId:
                  item.filename.charAt(0) === '/'
                    ? item.filename.substring(1)
                    : item.filename,
                isReplaced:
                  item.filename.startsWith('/newImageReplaceOutput/output/') ||
                  item.filename.startsWith('/output/pptx/'),
                isUploaded: item.prefs.source === 'uploaded',
                isGenerated: item.prefs.source === 'generated',
                isSynthesis: item.prefs.source === 'synthesis',
                isRedesign: item.prefs.source === 'redesign',
                isComply: item.prefs.source === 'comply',
              },
            ],
          };
          mergePath = await this.getMergeSlidesUrl(s, true);
        }

        if (this.itemToBeDownloaded.status !== 'cancelled') {
          this.updateDownloadItemStatus({
            itemIndex: this.itemToBeDownloaded.itemIndex,
            newStatus: 'downloading',
          });
          await downloadFileWithAPI(
            fileType,
            isRestricted ? mergePath : filePath,
            `${fileName}${isRestricted ? '.pdf' : '.pptx'}`,
            item?.prefs?.source,
            this.trackDownloadProgressInPercentage,
          )
            .then(() => {
              if (this.itemToBeDownloaded.downloadStatusCallback) {
                this.itemToBeDownloaded.downloadStatusCallback();
                this.updateDownloadItemStatus({
                  itemIndex: this.itemToBeDownloaded.itemIndex,
                  newStatus: 'complete',
                });
                if (this.itemToBeDownloaded.logDownloadCallback) {
                  this.itemToBeDownloaded.logDownloadCallback();
                }
              }
            })
            .catch(() => {
              this.updateFailStatusOnDownloadFailure();
            });
        }
      } else if (
        ['bpeSlideDetail', 'bpeSlideCard'].includes(
          this.itemToBeDownloaded.originType,
        )
      ) {
        const { filePath, fileName } = this.itemToBeDownloaded.metaData;

        let fileUrl = '';
        // filepath contains protected/premium-feature/tc are from BPE slides Convert Template
        if (
          filePath.indexOf('/comply/') !== -1 ||
          filePath.indexOf('protected/premium-feature/tc/') !== -1
        ) {
          const awsAssetsDistribution =
            process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
          const token = await getLoggedInUserToken();
          fileUrl = `${awsAssetsDistribution}${
            filePath.startsWith('/') ? '' : '/'
          }${filePath}?accesskey=${token}`;
        } else {
          fileUrl = await getFileURL(
            this.currentUser.user.id,
            filePath,
            this.currentUser.userIp,
          );
        }
        if (this.itemToBeDownloaded.status !== 'cancelled') {
          downloadFileWithCustomName(
            fileUrl,
            fileName,
            this.downloadInProgress,
            true,
            this.updateFailStatusOnDownloadFailure,
            this.trackDownloadProgressInPercentage,
          );
        }
      } else if (
        [
          'slideLibDetailReplaceVisuals',
          'slideLibCardHybridSearchMergeSlide',
        ].includes(this.itemToBeDownloaded.originType)
      ) {
        // local download for slide replacements\

        const { fileName, filePath } = this.itemToBeDownloaded.metaData;

        if (this.itemToBeDownloaded.status !== 'cancelled') {
          downloadFileWithCustomName(
            filePath,
            fileName,
            this.downloadInProgress,
            true,
            this.updateFailStatusOnDownloadFailure,
            this.trackDownloadProgressInPercentage,
          );
        }
      }
    },
  },
  mounted() {
    // this.initDownloadItem();
    this.initFeatureUsageChecksAndAudValidity();
  },
};
</script>
<style scoped lang="scss">
@import '@/scss/variables.scss';

.slide-collection-download-row {
  padding: 15px 20px 15px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  .statusText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .completed-icon-container {
    width: 24px !important;
    height: 24px !important;
    .completedIconStatus {
      box-shadow: none;
      background-color: #ffffff;
      padding: 0;
      max-width: 24px;
      height: 24px;
      min-width: 24px;
      &:hover {
        background-color: rgb(211 237 249);
        border-radius: 50%;
        // max-width: 28px;
        // height: 28px;
        // min-width: 28px;
        padding: 4px;
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
      }
    }
  }
}
</style>
