<template>
  <div class="processing-payment-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="failure-payment">
      <div class="failure-payment-img">
        <img src="/assets/img/profile/payment-failure.svg" alt />
      </div>
      <div class="failure-payment-title">Oops!</div>
      <div class="failure-payment-desc">
        Transaction Failed. Please try again.
      </div>
      <div v-if="errorMessage" class="failure-payment-desc">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FailedPaymentDetail',
  props: {
    errorMessage: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('FailedPaymentDetail');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.processing-payment-wrapper {
  width: 100%;
  height: 100%;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .failure-payment {
    width: 100%;
    height: 100%;

    .failure-payment-img {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: auto;
        height: 277px !important;
        margin: 65px auto 45px auto;
      }
    }

    .failure-payment-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 20px;
    }

    .failure-payment-desc {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
</style>
