import {
  NA,
  TD_COURSEMODULEPROGRESS,
  TD_LEARNCATEGORYNAME,
  TD_LEARNCONTENTNAME,
  TD_LEARNCOURSENAME,
  TD_LEARNMODULENAME,
  TD_LEARNVIDEOLENGTH,
  TD_LEARNVIDEOSEEKBARTIME,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const LEARN_EVENT_CATEGORY = 'Learn';
const LEARN_ICONCLICK = 'learn_IconClick';
const LEARN_THUMBNAILCLICK = 'learn_ThumbnailClick';
const LEARN_THUMBNAIL_MODULECLICK = 'learn_Thumbnail_ModuleClick';
const LEARN_EXPANDEDVIEW_GOTOCOURSE = 'learn_ExpandedView_GoToCourse';
const LEARN_EXPANDEDVIEW_MODULECLICK = 'learn_ExpandedView_ModuleClick';
const LEARN_VIDEO_CLICK = 'learn_Video_Click';
const LEARN_VIDEO_SEEKBARCLICK = 'learn_Video_SeekBarClick';
const LEARN_VIDEO_SEEKBARENDCLICK = 'learn_Video_SeekBarEndClick';
const LEARN_VIDEO_CLOSE = 'learn_Video_Close';
const LEARN_VIDEO_SKIPINTRO = 'learn_Video_SkipIntro';
const LEARN_COURSE_VIEWCERTBUTTON = 'learn_Course_ViewCertButton';
const LEARN_COURSE_VIEWCERTICON = 'learn_Course_ViewCertIcon';
const LEARN_COURSE_SHARE = 'learn_Course_Share';
const LEARN_COURSE_DOWNLOADCERT = 'learn_Course_DownloadCert';
const LEARN_COURSE_EMAILCERT = 'learn_Course_EmailCert';
const LEARN_PRACTICE_CLICK = 'learn_Practice_Click';
const LEARN_PRACTICE_SUBMITBUTTON = 'learn_Practice_SubmitButton';
const LEARN_PRACTICE_TRYAGAINBUTTON = 'learn_Practice_TryAgainButton';
const LEARN_PRACTICE_TRYDONEBUTTON = 'learn_Practice_TryDoneButton';
const LEARN_QUIZ_CLICK = 'learn_Quiz_Click';
const LEARN_QUIZ_CHECKANSWER = 'learn_Quiz_CheckAnswer';
const LEARN_LOCAL_DROPDOWN_SEARCH = 'Learn_local-dropdown-search';
const LEARN_LOCAL_HYBRID_SEARCH = 'Learn_local-hybrid-search';
const LEARN_COURSE_CHIP_CLICK = 'Learn_course-chip_click';

const getCourseModuleProgress = (modules) => {
  let modulesProgress = '';
  modules?.forEach((module) => {
    modulesProgress += `${module.display_name}*${module.status}||`;
  });
  return modulesProgress;
};

const getCourseData = (courseInfo) => {
  const { domain, display_name: displayName, modules } = courseInfo;

  return {
    [TD_LEARNCATEGORYNAME]: domain,
    [TD_LEARNCOURSENAME]: displayName,
    [TD_COURSEMODULEPROGRESS]: getCourseModuleProgress(modules),
  };
};

const getCourseDetailData = (courseDetailInfo) => {
  const {
    learnCategoryName,
    learnCourseName,
    learnContentName,
    learnModuleName,
    learnVideoLength,
    modules,
    learnVideoSeekBarTime,
  } = courseDetailInfo;

  return {
    [TD_LEARNCATEGORYNAME]: learnCategoryName || NA,
    [TD_LEARNCOURSENAME]: learnCourseName || NA,
    [TD_LEARNCONTENTNAME]: learnContentName || NA,
    [TD_LEARNMODULENAME]: learnModuleName || NA,
    [TD_LEARNVIDEOLENGTH]: learnVideoLength || NA,
    [TD_COURSEMODULEPROGRESS]: getCourseModuleProgress(modules) || NA,
    [TD_LEARNVIDEOSEEKBARTIME]: learnVideoSeekBarTime || NA,
  };
};

export const trackLearnEvents = {
  learnIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnThumbnailClick(currentUser, courseInfo) {
    const otherData = getCourseData(courseInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_THUMBNAILCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnThumbnailModuleClick(currentUser, courseInfo) {
    const otherData = getCourseData(courseInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_THUMBNAIL_MODULECLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnExpandedViewGoToCourse(currentUser, courseInfo) {
    const otherData = getCourseData(courseInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_EXPANDEDVIEW_GOTOCOURSE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnExpandedViewModuleClick(currentUser, courseInfo) {
    const otherData = getCourseData(courseInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_EXPANDEDVIEW_MODULECLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnVideoClick(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_VIDEO_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnVideoSeekBarClick(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_VIDEO_SEEKBARCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnVideoSeekBarEndClick(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_VIDEO_SEEKBARENDCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnVideoClose(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_VIDEO_CLOSE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnVideoSkipIntro(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_VIDEO_SKIPINTRO,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnCourseViewCertButton(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_COURSE_VIEWCERTBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnCourseViewCertIcon(currentUser, courseInfo) {
    const otherData = getCourseData(courseInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_COURSE_VIEWCERTICON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnCourseShare(currentUser, courseDetailInfo, otherData) {
    const courseInfoData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...otherData,
          ...courseInfoData,
        },
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_COURSE_SHARE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnCourseDownloadCert(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_COURSE_DOWNLOADCERT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnCourseEmailCert(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_COURSE_EMAILCERT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnQuizClick(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_QUIZ_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnQuizCheckAnswer(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_QUIZ_CHECKANSWER,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnPracticeClick(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_PRACTICE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnPracticeSubmitButton(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_PRACTICE_SUBMITBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnPracticeTryAgainButton(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_PRACTICE_TRYAGAINBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnPracticeTryDoneButton(currentUser, courseDetailInfo) {
    const otherData = getCourseDetailData(courseDetailInfo);
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_PRACTICE_TRYDONEBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnLocalDropdownSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_LOCAL_DROPDOWN_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnLocalHybridSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_LOCAL_HYBRID_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  learnCourseChipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: LEARN_EVENT_CATEGORY,
        eventAction: LEARN_COURSE_CHIP_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
