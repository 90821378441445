import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
// import { Storage } from 'aws-amplify';
import { getFileURL } from '@/utils/calyrex';
import usersApi from '../../../API/users-api';
import UserMentionList from './UserMentionList.vue';
import store from '../../../store';

export default {
  items: async ({ query }) => {
    const users = await usersApi.methods.search(query, {
      filterBy: `companyID:=[${store.getters['users/getCurrentUser'].company.id}]`,
    });
    const fullUrl = async (filename) => {
      if (!filename) {
        return null;
      }
      if (filename.startsWith('/assets/img/')) {
        return filename;
      }
      const url = await getFileURL(null, `public/${filename}`, null, 'storage');
      return url;
    };

    const processedUsers = await Promise.all(
      users.map(async ({ document }) => {
        const profilePicture = await fullUrl(document.profileImage);
        return {
          ...document,
          label: document.fullName,
          value: document.email,
          profilePicture,
        };
      }),
    );

    return processedUsers;
  },

  render: () => {
    let component;
    let popup;

    return {
      onBeforeStart: (props) => {
        props.loading = true;

        component = new VueRenderer(UserMentionList, {
          parent: this,
          propsData: props,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },
      onStart: (props) => {
        props.loading = false;
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onBeforeUpdate(props) {
        props.loading = true;
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onUpdate(props) {
        props.loading = false;

        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
