var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleOutsideClick),expression:"handleOutsideClick"}],ref:"saveToGDrivePopupContainer",staticClass:"redirecting-detail-wrapper"},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":"","disabled":_vm.saveProgress},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),(_vm.saveProgress)?_c('div',{staticClass:"saveInProgress"},[_vm._m(0),_c('div',{staticClass:"progressTextWithSpinner"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#21a7e0","size":"25"}}),_c('div',{staticClass:"savingText"},[_vm._v(_vm._s(_vm.$t('common.savingToGDrive')))])],1)]):(_vm.downloadSuccess)?_c('div',{staticClass:"saveComplete"},[_vm._m(1),_c('div',{staticClass:"progressText"},[_vm._v(" "+_vm._s(_vm.$t('common.savedToGDrive'))+" ")])]):_c('div',{staticClass:"yetToSave"},[(
        _vm.isPrezentation &&
        _vm.item &&
        _vm.item.audienceID &&
        _vm.item.audienceID.toLowerCase() !== 'custom'
      )?_c('div',{staticClass:"download-info-content-wrapper"},[_c('DownloadInfoContent',{attrs:{"imageUrl":"/assets/img/downloadmodal/365-download-confirmation.svg","message":'prezentation.gDriveDownloadPopUpDesc',"audienceId":_vm.audienceId,"audienceOwnerId":_vm.audienceOwnerId}})],1):_c('div',[_vm._m(2),(_vm.error)?_c('div',{staticClass:"redirecting-detail-content-desc"},[_vm._v(" "+_vm._s(_vm.$t('prezentation.requestedFileNotFound'))+" ")]):_vm._e(),(!_vm.error)?_c('div',{staticClass:"redirecting-detail-content-desc"},[_vm._v(" "+_vm._s(_vm.$t('prezentation.gDriveDownloadDesc'))+" "),_c('b',[_vm._v(_vm._s(_vm.$t('common.save')))]),_vm._v(". ")]):_vm._e()])]),(!_vm.error)?_c('div',{class:_vm.actionClass,style:({
      'justify-content':
        _vm.saveProgress || _vm.downloadSuccess ? 'center' : 'flex-end',
    })},[(_vm.isPrezentation)?_c('div',[_c('div',{style:(!_vm.saveProgress && !_vm.downloadSuccess
            ? { width: '200px', 'margin-right': '-100px' }
            : {
                'margin-right': '0px',
                'pointer-events': 'none',
              }),attrs:{"id":"prez-container"}},[_c('v-btn',{staticClass:"g-savetodrive",attrs:{"id":"layer1","data-src":_vm.src,"data-filename":_vm.fileNameToBeSaved,"data-sitename":"Prezent"}}),_c('v-btn',{staticClass:"add ma-2 py-2",style:(_vm.saveProgress || _vm.downloadSuccess
              ? { width: '108px', top: '15px', left: '-4px' }
              : { width: '100px', top: '20px', left: '-21px' }),attrs:{"id":"layer2","outlined":"","rounded":"","color":"#20a7e0","height":"40","loading":!_vm.scriptLoaded || _vm.makingMergeSlidesCalls},on:{"click":_vm.handleOK}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getDownloadBtnName))+" ")])],1),(!_vm.saveProgress && !_vm.downloadSuccess)?_c('v-btn',{staticClass:"cancel ma-2 py-2",attrs:{"rounded":"","outlined":"","width":"100","height":"40"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]):_vm._e()],1):_c('div',[_c('div',{style:(!_vm.saveProgress && !_vm.downloadSuccess
            ? { width: '200px', 'margin-right': '-100px' }
            : {
                'margin-right': '0px',
                'pointer-events': 'none',
              }),attrs:{"id":"container"}},[_c('v-btn',{staticClass:"g-savetodrive",attrs:{"id":"layer1","data-src":_vm.src,"data-filename":_vm.fileNameToBeSaved,"data-sitename":"Prezent"}}),_c('v-btn',{staticClass:"add ma-2 py-2",style:(_vm.saveProgress || _vm.downloadSuccess
              ? { width: '108px', top: '15px', left: '-4px' }
              : { width: '100px', top: '20px', left: '-21px' }),attrs:{"id":"layer2","outlined":"","rounded":"","color":"#20a7e0","height":"40","loading":!_vm.scriptLoaded || _vm.makingMergeSlidesCalls},on:{"click":_vm.handleOK}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getDownloadBtnName))+" ")])],1),(!_vm.saveProgress && !_vm.downloadSuccess)?_c('v-btn',{staticClass:"cancel ma-2 py-2",attrs:{"rounded":"","color":"#20a7e0","width":"100","height":"40"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"illustration"},[_c('img',{attrs:{"src":"/assets/img/saving-to-gdrive.svg","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"illustration"},[_c('img',{attrs:{"src":"/assets/img/saved-to-gdrive.svg","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"redirecting-detail-image-wrapper"},[_c('img',{attrs:{"src":"/assets/img/downloadmodal/365-download-confirmation.svg","alt":""}})])}]

export { render, staticRenderFns }