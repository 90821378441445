import { trackCommonEvent } from './MatomoTrackingDataHelper';

const GENERATE_EVENT_CATEGORY = 'Generate';
const GENERATE_ICONCLICK = 'Generate_iconclick';
const GENERATE_GENERATE_CLICK = 'Generate_generate_click';
const GENERATE_AUDIENCE_CLICK = 'Generate_audience_click';
const GENERATE_TEMPLATE_CLICK = 'Generate_template_click';
const GENERATE_NODECOUNT_CLICK = 'Generate_nodecount_click';
const GENERATE_VISUAL_CLICK = 'Generate_visual_click';
const GENERATE_PROMPTCLEARBUTTON_CLICK = 'Generate_promptclearbutton_click';
const GENERATE_ADDTOSLIDELIBRARY_CLICK = 'Generate_addtoslidelibrary_click';
const GENERATE_FAVORITE_CLICK = 'Generate_favorite_click';
const GENERATE_DOWNLOAD_CLICK = 'Generate_download_click';
const GENERATE_CONTEXTUALPROMPT_CLICK = 'Generate_contextualprompt_click';
const GENERATE_SMARTTOOLS_CLICK = 'Generate_Smarttools_click';
const GENERATE_AUDIENCETOOL_CHANGED = 'Generate_audiencetool_changed';
const GENERATE_TEMPLATE_CHANGED = 'Generate_template_changed';
const GENERATE_NODECOUNT_CHANGED = 'Generate_nodecount_changed';
const GENERATE_VISUAL_CHANGED = 'Generate_visual_changed';
const GENERATE_SMARTLAYOUT_CLICK = 'Generate_smartlayout_click';
const GENERATE_SMARTLAYOUT_CHANGED = 'Generate_smartlayout_changed';
const GENERATE_MAGICFILL_CLICK = 'Generate_magicfill_click';
const GENERATE_MAGICFILL_REGENERATE = 'Generate_magicfill_Regenerate';
const GENERATE_EDIT_SLIDE_COUNT_FIELD_CHANGED =
  'Generate_Edit-slide-count_field-changed';
const GENERATE_EDIT_SLIDE_COUNT_REGENERATE =
  'Generate_Edit-slide-count_regenerate';
const GENERATE_EDIT_SLIDE_COUNT_DESCRIPTION_SAVE =
  'Generate_Edit-slide-count_description-save';
const GENERATE_CANCEL_BUTTON_CLICK = 'Generate_cancel-button_click';
const GENERATE_LIKE_DISLIKE_CLICK = 'Generate_like-dislike_click';
const GENERATE_LIKE_DISLIKE_CLICK_SUBMIT = 'Generate_like-dislike_click_submit';
const GENERATE_TUTORIAL_CLICK = 'Generate_tutorial_click';
const GENERATE_PROMPT_EXAMPLES_CLICK = 'Generate_prompt-examples_click';
const GENERATE_ADD_A_SLIDE_GENERATE = 'Generate_add-a-slide_generate';
const GENERATE_EDIT_SLIDE_COUNT_CLICK = 'Generate_Edit-slide-count_click';
const GENERARE_SLIDE_DUPLICATE = 'Generare_slide_duplicate';
const GENERARE_SLIDE_DELETE = 'Generare_slide_delete';
const GENERATE_ABOUT_YOUR_PREZENTATION_CLICK =
  'Generate_About-your-prezentation_click';
const GENERATE_ADD_CONTEXT_CLICK = 'Generate_add-context_click';
const GENERATE_ADD_CONTEXT_REGENARATE_DECK =
  'Generate_add-context_regenarate-deck';
const GENERATE_ADD_CONTEXT_REGENARATE_SLIDE =
  'Generate_add-context_regenarate-slide';
const GENERATE_CLEAR_CONTEXT_CLICK = 'Generate_clear-context_click';
const GENERATE_ADD_CONTEXT_UPLOAD_FAILED = 'Generate_add-context_upload-failed';
const GENERATE_PROMPT_SUGGESTIONS_ON_OFF = 'Generate_Prompt-suggestions_On-off';
const GENERATE_PROMPT_SUGGESTIONS_IGNORE_SUGGESTION =
  'Generate_Prompt-suggestions_Ignore-suggestion';
const GENERATE_PROMPT_SUGGESTIONS_SUGGESTION_SELECTED =
  'Generate_Prompt-suggestions_suggestion-selected';
const GENERATE_USER_SWITCHED = 'Generate_user-switched';

export const trackGenerateEvents = {
  generateIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  generatePromptButtonClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_GENERATE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  generateAudienceClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_AUDIENCE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateTemplateClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_TEMPLATE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateNodeCountClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_NODECOUNT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateVisualClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_VISUAL_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generatePromptClearButtonClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_PROMPTCLEARBUTTON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateAddToSlideLibraryClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ADDTOSLIDELIBRARY_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateFavoriteClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_FAVORITE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateDownloadClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_DOWNLOAD_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateContextualPromtClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_CONTEXTUALPROMPT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateSmartToolsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_SMARTTOOLS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateAudienceToolChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_AUDIENCETOOL_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateTemplateChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_TEMPLATE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateNodeCountChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_NODECOUNT_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateVisualChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_VISUAL_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateSmartLayoutClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_SMARTLAYOUT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateSmartLayoutChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_SMARTLAYOUT_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateMagicFillClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_MAGICFILL_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateMagicFillRegenerate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_MAGICFILL_REGENERATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateEditSlideCountFieldChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_EDIT_SLIDE_COUNT_FIELD_CHANGED,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateEditSlideCountRegenerate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_EDIT_SLIDE_COUNT_REGENERATE,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateEditSlideCountDescriptionSave(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_EDIT_SLIDE_COUNT_DESCRIPTION_SAVE,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateCancelButtonClick(currentUser) {
    try {
      trackCommonEvent({
        currentUser,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_CANCEL_BUTTON_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateLikeDislikeClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_LIKE_DISLIKE_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateLikeDislikeClickSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_LIKE_DISLIKE_CLICK_SUBMIT,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateTutorialClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_TUTORIAL_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generatePromptExamplesClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_PROMPT_EXAMPLES_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateAddASlideGenerate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ADD_A_SLIDE_GENERATE,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateEditSlideCountClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_EDIT_SLIDE_COUNT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateSlideDuplicate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERARE_SLIDE_DUPLICATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateSlideDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERARE_SLIDE_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateAboutYourPrezentationClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ABOUT_YOUR_PREZENTATION_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  generateAddContext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ADD_CONTEXT_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateAddContextRegenerateDeck(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ADD_CONTEXT_REGENARATE_DECK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateAddContextRegenerateSlide(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ADD_CONTEXT_REGENARATE_SLIDE,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateClearContextClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_CLEAR_CONTEXT_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateAddContextUploadFailed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_ADD_CONTEXT_UPLOAD_FAILED,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generatePromptSuggestionsOnOff(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_PROMPT_SUGGESTIONS_ON_OFF,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generatePromptSuggestionsIgnoreSuggestion(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_PROMPT_SUGGESTIONS_IGNORE_SUGGESTION,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generatePromptSuggestionsSuggestionSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_PROMPT_SUGGESTIONS_SUGGESTION_SELECTED,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generateUserTabSwitch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERATE_EVENT_CATEGORY,
        eventAction: GENERATE_USER_SWITCHED,
      });
    } catch (err) {
      console.log(err);
    }
  },
};
