<template>
  <div class="contact-soon-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="contact-soon-wrapper-content">
      <div class="contact-soon-image-wrapper">
        <img
          src="/assets/img/get-in-touch.svg"
          alt
          width="342px"
          height="276px"
        />
      </div>
      <!-- <div class="contact-soon-title">
        This is exciting!
      </div>-->
      <div v-if="origin === 'op'" class="contact-soon-desc desc">
        {{ $t('overnightPresentations.thankForInterest') }}
      </div>
      <div class="contact-soon-desc">
        {{ $t('learn.novice.weHaveReceived') }}
        <br />{{ $t('learn.novice.contactSoon') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactSoonModal',
  props: {
    origin: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('ContactSoonModal');
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.contact-soon-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .contact-soon-wrapper-content {
    width: 100%;
    .contact-soon-image-wrapper {
      margin: auto;
      margin-top: 64px;
      margin-bottom: 27px;
      width: fit-content;
    }

    .contact-soon-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 11px;
    }

    .desc {
      margin-bottom: 15px;
    }

    .contact-soon-desc {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
</style>
