<template>
  <div class="um-view">
    <template v-for="(item, i) of value">
      <span v-for="(node, j) of item.content" :key="`${i}_${j}`">
        <span v-if="node.type === 'text'"
          ><br v-if="i !== 0" />{{ node.text }}</span
        >
        <span v-else-if="node.type === 'mention'" class="um-view__mention">
          {{ node.attrs.label }}
        </span>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UserMentionView',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.um-view {
  white-space: break-spaces;
  ::v-deep &__mention {
    color: #21a7e0;
    border: 1px solid #21a7e0;
    border-radius: 3px;
    padding: 1px 3px 2px;
  }
}
</style>
