<template>
  <div class="commonDownloadSnackbarContainer">
    <div
      class="slide-collection-download-header"
      @click="showDownloadingItems = !showDownloadingItems"
    >
      <span>
        {{ $t(getSnackbarDownloadStatusHeader) }}
      </span>
      <div class="actions">
        <span>
          <v-tooltip
            top
            max-width="250"
            content-class="tooltip-content"
            fixed
            v-if="showDownloadingItems"
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="#121618"
                v-on="on"
                class="icon blue-icon"
                v-text="`mdi-menu-down`"
              />
            </template>
            <span>{{ $t('build.step3.minimize') }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="250"
            content-class="tooltip-content"
            fixed
            v-else
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="#121618"
                v-on="on"
                class="icon blue-icon"
                v-text="`mdi-menu-up`"
              />
            </template>
            <span>{{ $t('slides.expand') }}</span>
          </v-tooltip>
        </span>
        <span>
          <v-icon
            color="#121618"
            :size="20"
            @click="
              () => {
                setShowDownloadSnackbar(false);
                setSnackbarDownloadStatusHeader('assembling');
              }
            "
            >{{ 'mdi-close' }}</v-icon
          >
        </span>
      </div>
    </div>
    <div
      v-show="showDownloadingItems"
      class="slide-collection-download-body"
      ref="downloadItems"
    >
      <CommonDownloadSnackbarItem
        v-for="(dwnldItem, ind) in getDownloadItems"
        :itemToBeDownloaded="dwnldItem"
        :key="ind"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CommonDownloadSnackbarItem from './CommonDownloadSnackbarItem.vue';

export default {
  name: 'CommonDownloadSnackbar',
  data() {
    return {
      showDownloadingItems: true,
    };
  },
  props: {},
  components: {
    CommonDownloadSnackbarItem,
  },
  computed: {
    ...mapGetters('commonDownloads', [
      'getDownloadItems',
      'getSnackbarDownloadStatusHeader',
    ]),

    getFileDownloadStatus() {
      const completedCancelledOrFailedItems = [...this.getDownloadItems].filter(
        (item) =>
          item.status === 'complete' ||
          item.status === 'cancelled' ||
          item.status === 'failed',
      );
      return (
        this.getDownloadItems.length > 0 &&
        completedCancelledOrFailedItems.length === this.getDownloadItems.length
      );
    },
  },
  methods: {
    ...mapActions('commonDownloads', [
      'setShowDownloadSnackbar',
      'resetStateCommonDownloads',
      'setSnackbarDownloadStatusHeader',
    ]),
  },
  watch: {
    getFileDownloadStatus(val) {
      if (val) {
        setTimeout(() => {
          this.showDownloadingItems = false;
          // setTimeout(() => {
          //   if (this.getFileDownloadStatus) {
          //     this.setShowDownloadSnackbar(false);
          //     this.setSnackbarDownloadStatusHeader('assembling');
          //   }
          // }, 4000);
        }, 2000);
      } else {
        this.showDownloadingItems = true;
      }
    },

    getDownloadItems(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.$nextTick(() => {
          // this.$refs.downloadItems.scrollTop = 0;
          const container = this.$refs.downloadItems;
          container.scrollTop = container.scrollHeight;
        });
      }
    },
  },
  destroyed() {
    this.resetStateCommonDownloads();
  },
  beforeDestroy() {
    this.resetStateCommonDownloads();
  },
};
</script>

<style lang="scss" scoped>
.commonDownloadSnackbarContainer {
  .slide-collection-download-header {
    padding: 10px;
    // color: white;
    color: #121618;
    // background-color: #21a7e0;
    background-color: #e1f5fb;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    .actions {
      display: flex;
      gap: 16px;
    }
  }
  .slide-collection-download-body {
    background-color: white;
    /* Fixing the download items container to show max 5 without a scroll */
    max-height: 280px;
    overflow: auto;
  }
}
</style>
