var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-views-container"},[_c('div',{staticClass:"icon-container"},[_c('div',{on:{"click":function($event){return _vm.handleView(false)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('prezentationGallery.gridView'),
          placement: 'top-center',
        }),expression:"{\n          content: $t('prezentationGallery.gridView'),\n          placement: 'top-center',\n        }"}],attrs:{"color":_vm.isColumnView ? '#000' : '#21A7E0'}},[_vm._v(_vm._s(_vm.isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid'))])],1),_c('div',{on:{"click":function($event){return _vm.handleView(true)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('prezentationGallery.columnView'),
          placement: 'top-center',
        }),expression:"{\n          content: $t('prezentationGallery.columnView'),\n          placement: 'top-center',\n        }"}],attrs:{"color":_vm.isColumnView ? '#21A7E0' : '#000'}},[_vm._v(_vm._s(_vm.isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline'))])],1)]),_c('div',{staticClass:"layout-content"},[(!_vm.loading && _vm.slides.length === 0)?[_c('div',{staticClass:"error-layout-container"},[_c('img',{attrs:{"src":'/assets/img/redesign/no-layout-available.svg'}}),_c('p',{staticClass:"no-layout-text"},[_vm._v(" No "+_vm._s(_vm.type)+" layouts available for the selected slide ")])])]:_vm._l((_vm.slidesAdjusted),function(slide,index){return _c('div',{key:index,class:[{ 'column-view': _vm.isColumnView }, 'slide']},[(!slide.loading)?_c('v-img',{staticClass:"layout-image",class:{
            current:
              _vm.selectedSlideID &&
              (slide.unique_id === _vm.selectedSlideID ||
                slide.id === _vm.selectedSlideID),
            disabled: slide.disabled,
          },attrs:{"contain":"","lazy-src":"/assets/img/slides/placeholder-slide.svg","aspect-ratio":16 / 9,"src":_vm.getImgPath(slide),"loading":"lazy"},on:{"click":function($event){return _vm.handleChangeLayout(slide)}}}):_c('div',{staticClass:"layout-skeleton"},[_c('Skeleton',{attrs:{"type":slide.type,"view":_vm.isColumnView ? 'column' : 'list'}})],1)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }