<template>
  <div class="item-template">
    <div class="name">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
  },
};
</script>

<style lang="scss" scoped>
.item-template {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 40px;

  .name {
    font-size: 15px;
  }
}
</style>
