<template>
  <div>
    <div
      v-if="type === 'add'"
      class="um-editor-add"
      :data-empty="isEditorTextEmpty"
    >
      <editor-content :editor="editor" class="comment" />
    </div>
    <div
      v-if="type === 'edit'"
      class="um-editor-edit"
      :data-empty="isEditorTextEmpty"
    >
      <editor-content :editor="editor" class="comment" />
    </div>
  </div>
</template>

<script>
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import { Editor, EditorContent } from '@tiptap/vue-2';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import suggestion from './UserSuggestion';

export default {
  name: 'UserMentionControl',
  components: {
    EditorContent,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
    placeholder: { type: String, default: 'Comment or add others with @' },
    placement: String,
    type: String,
  },
  data() {
    return {
      /** @type {Editor} */
      editor: null,

      /** @type {import('@tiptap/vue-2').JSONContent} */
      localValue: null,
    };
  },
  beforeDestroy() {
    this.editor?.destroy();
  },
  computed: {
    isEditorTextEmpty() {
      return this.editor?.getText().trim().length === 0;
    },
  },
  watch: {
    value(newValue) {
      console.log(this.type);
      if (newValue === this.localValue) {
        return;
      }
      this.localValue = newValue;
      this.editor.commands.setContent(this.value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: {
        type: 'doc',
        content: this.value,
      },
      placeholder: this.placeholder,
      extensions: [
        Document,
        Paragraph,
        Text,
        Mention.configure({
          HTMLAttributes: {
            class: 'um-editor-add__mention',
          },
          suggestion,
        }),
      ],
      onUpdate: () => {
        const { content } = this.editor.getJSON();

        this.localValue = content;

        this.$emit('change', content);
      },
    });
  },
  methods: {
    getText() {
      return this.editor?.getText();
    },
    getHTML() {
      return this.editor?.getHTML();
    },
    getMentions() {
      const mentions = this.parseMentions({ content: this.value });
      return mentions;
    },

    parseMentions(data) {
      const mentions = (data.content || []).flatMap(this.parseMentions);
      if (data.type === 'mention') {
        mentions.push(data.attrs.id);
      }
      return mentions;
    },
  },
};
</script>
<style lang="scss" scoped>
.um-editor-add {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  width: 100%;
  resize: vertical;
  position: relative;
  overflow: auto;
  border-radius: 3px;

  &[data-empty='true']::before {
    content: 'Comment or add others with @'; // Placeholder text
    color: #999; // Placeholder text color
    position: absolute;
    pointer-events: none;
    padding: 6px 8px;
    font-size: 14px;
  }

  .comment {
    height: 100%;
  }

  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px #146bd0; /* Add a focus border with a shadow */
  }

  &:focus-within {
    border: none;
  }

  &::v-deep .ProseMirror {
    padding: 6px 6px;
    overflow: auto;
    outline: none;
    min-height: 75px;
  }

  ::v-deep &__mention {
    color: #21a7e0;
    border: 1px solid #21a7e0;
    border-radius: 3px;
    padding: 1px 3px 2px;
  }
}
.um-editor-edit {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  width: 98%;
  overflow: hidden;
  border-radius: 5px;
  height: 29px;
  margin-right: 3px;
  margin-left: 3px;

  &[data-empty='true']::before {
    content: 'Comment';
    color: #999;
    position: absolute;
    pointer-events: none;
    padding: 6px 6px;
    font-size: 14px;
  }
  p {
    margin-bottom: 0px; /* Set the desired margin for p elements */
  }

  &:focus-within {
    outline: none;
    border: none;
    box-shadow: 0 0 0 2px #146bd0; /* Add a focus border with a shadow */
  }

  &:focus-within {
    border: none;
  }
  ::-webkit-scrollbar {
    display: none;
    height: 0px;
  }

  &::v-deep .ProseMirror {
    padding: 3px 6px;
    overflow: auto;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    white-space: nowrap;
    height: 29px;
    .p {
      margin-bottom: 0px;
    }
  }

  &::v-deep .ProseMirror:focus {
    padding: 6px 8px;
    overflow: auto;
    outline: none;
    border: none;
    border-radius: 0px;
    white-space: nowrap;
  }
  ::v-deep &__mention {
    color: #21a7e0;
    border: 1px solid #21a7e0;
    border-radius: 3px;
    padding: 1px 3px 2px;
  }
}
</style>
