<template>
  <div v-cloak class="slide-detail wrapper" :class="{ isModal }">
    <v-btn v-if="isModal" class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="content-wrapper">
      <v-row
        :class="
          [
            'userUploadedSlide',
            'userGeneratedSlide',
            'userRedesignSlide',
            'userComplySlide',
            'synthesisSlide',
          ].includes(slideType)
            ? 'mb-2'
            : ''
        "
        no-gutters
        style="flex-wrap: nowrap; width: 100%"
      >
        <v-col cols="8">
          <div v-if="result" class="main-wrapper pa-0">
            <div class="header text-h6 text-left font-weight-bold modal-header">
              <div class="nameOrIdeaName">
                <div
                  v-if="
                    slideType === 'prezentSourcedSlide' ||
                    slideType === 'companyBrandedSlide'
                  "
                >
                  {{ getTitle }}
                </div>
                <div v-else>{{ result.name }}</div>
              </div>
              <div
                class="prezentLogo"
                v-if="slideType === 'prezentSourcedSlide'"
              >
                <img src="/assets/img/prezent_avatar.svg" alt width="22px" />
              </div>
            </div>
            <div class="header text-subtitle-1 text-left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="source" v-bind="attrs" v-on="on">
                    {{
                      $t('prezentationsList.sourceLabel', {
                        sourceName: capitalizeFirstLetter(getSourceForSlide()),
                      })
                    }}
                  </div>
                </template>
                <span>{{
                  $t('prezentationsList.sourceLabel', {
                    sourceName: capitalizeFirstLetter(getSourceForSlide()),
                  })
                }}</span>
              </v-tooltip>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="author"
                    v-if="
                      [
                        'companyBrandedSlide',
                        'userUploadedSlide',
                        'userGeneratedSlide',
                        'userRedesignSlide',
                        'synthesisSlide',
                        'userComplySlide',
                      ].includes(slideType)
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      $t('prezentationsList.authorLabel', {
                        authorName: getAuthor(),
                      })
                    }}
                  </div>
                </template>
                <span>{{
                  $t('prezentationsList.authorLabel', {
                    authorName: getAuthor(),
                  })
                }}</span>
              </v-tooltip>
              <div
                v-if="
                  [
                    'companyBrandedSlide',
                    'userUploadedSlide',
                    'userGeneratedSlide',
                    // 'userRedesignSlide',
                    'synthesisSlide',
                    'userComplySlide',
                  ].includes(slideType)
                "
              >
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="description"
                    :style="{
                      'max-width': `${getDescriptionWidthBasedOnSlide()}%`,
                    }"
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      slideType === 'prezentSourcedSlide' ||
                      slideType === 'companyBrandedSlide'
                    "
                  >
                    {{
                      $t('slides.descriptionLabel', {
                        descriptionText:
                          getDescription(getTitle) ||
                          $t('slides.addDescriptionLabel'),
                      })
                    }}
                  </div>
                  <div
                    class="description"
                    :style="{
                      'max-width': `${
                        headingsWidth - getDescriptionWidthBasedOnSlide()
                      }%`,
                    }"
                    v-bind="attrs"
                    v-on="on"
                    v-else-if="slideType !== 'userRedesignSlide'"
                  >
                    {{ $t('userUploadPrezentation.description') }}:
                    <span
                      :class="
                        result.summary && result.summary !== ''
                          ? 'availableDesc'
                          : 'unavailableDesc'
                      "
                      >{{
                        result.summary && result.summary !== ''
                          ? result.summary
                          : $t('slides.addDescription')
                      }}</span
                    >
                  </div>
                </template>
                <span
                  v-if="
                    slideType === 'prezentSourcedSlide' ||
                    slideType === 'companyBrandedSlide'
                  "
                >
                  {{
                    `${
                      getDescription(result.title) ||
                      getDescription(result.asset.title) ||
                      $t('slides.addDescription')
                    }`
                  }}
                </span>
                <span v-else-if="slideType !== 'userRedesignSlide'">
                  {{
                    result.summary && result.summary !== ''
                      ? result.summary
                      : $t('slides.addDescription')
                  }}</span
                >
              </v-tooltip>
              <div
                class="bestPracticesSwitch"
                v-if="
                  [
                    'userUploadedSlide',
                    'userGeneratedSlide',
                    'synthesisSlide',
                  ].includes(slideType)
                "
                v-tooltip="{
                  content: `${getTooltipContentForBestPractisesToggle()}`,
                  placement: 'bottom',
                }"
              >
                <v-switch
                  v-model="toggleBestPractises"
                  :label="$t('slides.bestPractices')"
                  color="#21a7e0"
                  hide-details
                  :disabled="
                    getBestPractisesOnSlide.filter((item) => item.isAdded)
                      .length <= 0
                  "
                  @click="handleToggleButton"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4 ml-2">
          <div
            class="header text-h6 text-left font-weight-bold modal-header"
            v-if="
              slideType === 'prezentSourcedSlide' ||
              slideType === 'companyBrandedSlide'
            "
          >
            {{ $t('slideDetails.similarSlidesText') }}
          </div>
          <v-radio-group
            v-if="
              !isModal &&
              (slideType === 'prezentSourcedSlide' ||
                slideType === 'companyBrandedSlide')
            "
            class="similar-slides-radiobtns"
            v-model="type"
          >
            <v-row :class="{ 'disable-events': isImageReplaceWalkThrough }">
              <v-col cols="4" data-pendo-id="slides-radio-idea">
                <v-radio :label="$t('slides.idea')" value="idea" />
              </v-col>
              <v-col cols="4">
                <v-radio :label="$t('slides.diagram')" value="diagram"
              /></v-col>
            </v-row>
          </v-radio-group>
          <div
            v-if="
              [
                'userUploadedSlide',
                'userGeneratedSlide',
                'userRedesignSlide',
                'synthesisSlide',
                'userComplySlide',
              ].includes(slideType)
            "
            class="header text-h6 text-left font-weight-bold modal-header"
          >
            {{ getSidePanelHeader() }}
          </div>
          <div
            v-if="
              [
                'userUploadedSlide',
                'userGeneratedSlide',
                'userRedesignSlide',
                'synthesisSlide',
                'userComplySlide',
              ].includes(slideType)
            "
            :style="{ 'font-size': '16px', 'line-height': '28px' }"
          >
            {{ getSidePanelSubHeader() }}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters style="flex-wrap: nowrap; width: 100%">
        <v-col cols="8">
          <div v-if="result" class="main-wrapper">
            <div
              :id="
                isImageReplaceWalkThrough
                  ? 'walkthough-slide-id'
                  : 'main-slide-id'
              "
              class="image-wrapper"
              ref="slideDetailImage"
            >
              <v-img
                :aspect-ratio="16 / 9"
                :src="landscapeAsset"
                lazy-src="/assets/img/slides/placeholder-slide.svg"
                alt="image"
                contain
              >
                <div
                  v-if="
                    toggleBestPractises &&
                    getBestPractisesOnSlide &&
                    getBestPractisesOnSlide.length
                  "
                  class="best-practices-container"
                  :key="pointsKey"
                >
                  <div v-if="sidePanelAction === 'bestPractises'">
                    <span
                      class="coordinate-point"
                      v-for="(point, index) in [...getBestPractisesOnSlide]"
                      :key="index"
                    >
                      <BestPractisesDraggable
                        :slideThumbnailRef="slideThumbnailRef"
                        :draggableLeft="draggableLeft"
                        :bestPractisePoint="point"
                        v-if="point && (result.isOwner || point.isNew)"
                      />
                      <v-tooltip top max-width="400" v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            :style="{
                              width: '28px',
                              height: '28px',
                              color: '#ffffff',
                              'background-color': '#ec9f0b',
                              border: '2px solid #f5cf95',
                              'border-radius': '50%',
                              'text-align': 'center',
                              margin: '0px 4px 0px 0px',
                              cursor: 'pointer',
                              left: `${point.x}%`,
                              top: `${point.y}%`,
                              position: 'absolute',
                            }"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ point.name.replace(/#/g, '') }}
                          </div>
                        </template>
                        <span>{{ point.tooltip }}</span>
                      </v-tooltip>
                    </span>
                  </div>
                  <div v-else>
                    <span
                      class="coordinate-point"
                      v-for="(point, index) in getBestPractisesOnSlide"
                      :style="`left: ${point.x}%; top: ${point.y}%; position: absolute;`"
                      :key="index"
                    >
                      <v-tooltip
                        :left="movingInRightHalf(point)"
                        :right="!movingInRightHalf(point)"
                        max-width="400"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-img
                            :src="'/assets/icons/bulb.svg'"
                            width="37.5px"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>{{ point.tooltip }}</span>
                      </v-tooltip>
                    </span>
                  </div>
                </div>
              </v-img>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div
            class="related-products"
            :style="{
              height: `${mainSlideHeight ? `${mainSlideHeight}px` : '100%'}`,
            }"
            v-if="
              slideType === 'prezentSourcedSlide' ||
              slideType === 'companyBrandedSlide'
            "
          >
            <div
              class="relatedSlidesLoaderContainer"
              v-if="loadingRelatedSlides"
            >
              <v-progress-circular indeterminate :size="30" color="primary" />
            </div>
            <div class="related-products-thumbnails" :key="componentKey" v-else>
              <div
                :style="{ padding: '8px 0px 0px 0px' }"
                v-if="
                  filteredSlideByNode.length && filteredSlideByNode.length > 0
                "
              >
                <RelatedSlide
                  v-for="(product, index) in filteredSlideByNode"
                  :in-viewport-once="true"
                  :in-viewport-root-margin="'0% 0%'"
                  :key="index"
                  :handle-related-product-click="handleRelatedProductClick"
                  :product="product"
                  :class="{ 'disable-events': isImageReplaceWalkThrough }"
                  :data-pendo-id="
                    index === 0 ? 'slides-detail-thumbnail' : null
                  "
                />
              </div>
              <div class="noRelatedSlidesIllustration" v-else>
                <div class="imgContainer">
                  <img src="/assets/img/no-files-available.svg" />
                </div>
                <div class="relatedSlidesError">
                  {{
                    `${
                      isRelatedSlidesFetchError
                        ? $t('slides.somethingWentWrong')
                        : $t('slides.noSimilarSlidesFound')
                    }`
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              [
                'userUploadedSlide',
                'userGeneratedSlide',
                'userRedesignSlide',
                'synthesisSlide',
                'userComplySlide',
              ].includes(slideType)
            "
          >
            <EditUserSlideType
              ref="editUserSlideType"
              :editMode="editingSlideDetails"
              :slideDataForEdit="result"
              :mainSlideHeight="mainSlideHeight"
              :slideType="slideType"
              :permission="result.permission"
              :isOwner="result.isOwner"
              :getAcceptButtonState="getAcceptButtonState"
              :getDataForSlideUpdate="getDataForSlideUpdate"
              v-if="sidePanelAction === 'editDetails'"
              :key="compKeyEditSlide"
            />
            <ShareSlidePermissions
              class="share-slide-permission"
              v-if="sidePanelAction === 'shareDetails'"
              :slideOpened="result"
              :template="currentTheme"
              :getDataForSharePermissionsUpdate="
                getDataForSharePermissionsUpdate
              "
              :type="'component'"
              :mainSlideHeight="mainSlideHeight"
              :getAcceptButtonState="getAcceptButtonState"
              :key="compKeyShareSlide"
            />
            <BestPractises
              v-if="sidePanelAction === 'bestPractises'"
              :mainSlideHeight="mainSlideHeight"
              :isBestPractiseToggleActive="toggleBestPractises"
              :getAcceptButtonState="getAcceptButtonState"
              :key="compKeyEditBP"
              :isOwner="result.isOwner"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="dialog-actions">
      <v-row no-gutters style="flex-wrap: nowrap; width: 100%">
        <v-col cols="12">
          <div class="action-wrapper pl-0 pt-0 action-items__container">
            <div v-if="!isModal" class="add-fav-btn__wrapper">
              <v-hover v-slot="{ hover }">
                <v-btn
                  :style="{ 'background-color': hover ? '#fafafa' : '' }"
                  :class="{ 'disable-events': isImageReplaceWalkThrough }"
                  class="action-button"
                  color="primary"
                  text
                  @click="handleFavorite({ asset: result })"
                  data-pendo-id="slides-detail-favorite"
                  :loading="processingFavSlide"
                >
                  <v-icon v-if="!isFav" color="primary">
                    mdi-heart-outline
                  </v-icon>
                  <v-icon v-else color="primary"> mdi-heart </v-icon>
                  <span class="action-button__text">{{
                    `${
                      !isFav
                        ? $t('bestPracticesGuide.knowSlideCard.addFavorites')
                        : $t('bestPracticesGuide.knowSlideCard.removeFavorites')
                    }`
                  }}</span>
                </v-btn>
              </v-hover>
            </div>
            <div v-if="!isModal" class="add-fav-btn__wrapper">
              <v-hover v-slot>
                <v-btn
                  :class="{ 'disable-events': isImageReplaceWalkThrough }"
                  class="action-coll-button"
                  color="primary"
                  :ripple="false"
                  text
                  :plain="isCollectionLimitReached && !isAddedToCollection"
                  @mouseenter="trackCollectionMaxLimitEvent"
                  @click="handleCollection()"
                  data-pendo-id="slides-detail-collection"
                  :loading="processingCollection"
                >
                  <v-tooltip
                    top
                    content-class="tooltip-content"
                    max-width="200"
                    fixed
                    v-if="!isAddedToCollection && isCollectionLimitReached"
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#bdbdbd" v-bind="attrs" v-on="on">
                        mdi-file-plus-outline
                      </v-icon>
                      <span
                        class="action-button-disable__text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('replaceImage.addToCollection') }}
                      </span>
                    </template>
                    <span>{{ $t('replaceImage.addToCollectionTooltip') }}</span>
                  </v-tooltip>
                  <NewFeatureBadge
                    module-name="collections"
                    :feature-id="'collections_thumbnail_id'"
                    :offsetX="'14'"
                    :offsetY="
                      (!isCollectionLimitReached && !isAddedToCollection) ||
                      isAddedToCollection
                        ? '0'
                        : '-8'
                    "
                    :size="'medium'"
                    :inline="true"
                  >
                    <v-icon
                      v-if="!isAddedToCollection && !isCollectionLimitReached"
                      color="primary"
                    >
                      mdi-file-plus-outline
                    </v-icon>
                    <span
                      class="action-button__text"
                      v-if="!isAddedToCollection && !isCollectionLimitReached"
                      >{{ $t('replaceImage.addToCollection') }}</span
                    >
                    <v-icon color="primary" v-if="isAddedToCollection">
                      mdi-file-minus
                    </v-icon>
                    <span
                      class="action-button__text"
                      v-if="isAddedToCollection"
                      >{{ $t('slides.removeFromCollection') }}</span
                    >
                  </NewFeatureBadge>
                </v-btn>
              </v-hover>
            </div>
            <div
              v-if="!isModal && !hideEditSlideDetails"
              class="add-fav-btn__wrapper"
            >
              <v-btn
                :class="
                  !editingSlideDetails &&
                  startLoadingSlidesFlag &&
                  themes.length > 0
                    ? 'editSlideDetailsBtn'
                    : 'editSlideDetailsBtnDisabled'
                "
                @click="toggleEditSlideDetails"
                :disabled="
                  editingSlideDetails ||
                  !startLoadingSlidesFlag ||
                  !themes ||
                  !(themes.length > 0)
                "
              >
                <v-icon>{{ 'mdi-tag-multiple-outline' }}</v-icon>
                <NewFeatureBadge
                  module-name="slides"
                  :feature-id="'slides_revamp_id'"
                  :offsetX="'20'"
                  :offsetY="'-5'"
                  :size="'medium'"
                >
                  {{ $t('userUploadPrezentation.editStorylineDetailsText') }}
                </NewFeatureBadge>
              </v-btn>
            </div>
            <div class="add-fav-btn__wrapper" v-if="showReplaceVisual">
              <v-menu top :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    data-pendo-id="replace-visuals"
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      (!canReplaceImage || hideReplaceImage) &&
                      (!canReplaceIcons || hideReplaceIcon)
                        ? 'replaceAssetsBtnDisabled'
                        : 'replaceAssets'
                    "
                  >
                    <v-icon>{{ 'mdi-swap-horizontal' }}</v-icon>
                    {{ $t('slideDetails.replaceOption') }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in replaceMenuItems"
                    :key="index"
                    class="replaceAssetsList"
                  >
                    <v-btn
                      :class="
                        item.disabled
                          ? 'replaceAssetsTitleDisabled'
                          : 'replaceAssetsTitle'
                      "
                      :data-pendo-id="item.pendoId"
                      :id="item.eleId"
                      :disabled="item.disabled"
                      @click="item.clickHandler()"
                    >
                      <img
                        :src="item.icon"
                        alt
                        v-if="item.title === 'slideDetails.iconTitle'"
                      />
                      <v-icon v-else>{{ `${item.icon}` }}</v-icon>
                      <div
                        class="assetLabel"
                        v-if="item.title === 'slideDetails.iconTitle'"
                      >
                        <NewFeatureBadge
                          :module-name="'replace_icon'"
                          :feature-id="'replace_icon_id'"
                          :offsetX="'-3'"
                          :offsetY="'14'"
                          :size="'small'"
                          >{{ $t(item.title) }}</NewFeatureBadge
                        >
                      </div>
                      <div class="assetLabel" v-else>{{ $t(item.title) }}</div>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <!--
            <div class="add-fav-btn__wrapper" data-pendo-id="replace-image-btn">
              <div
                :id="
                  isImageReplaceWalkThrough
                    ? 'walkthough-replace-img-btn-id'
                    : undefined
                "
                class="replace-img-btn__wrapper"
                v-if="canReplaceImage && !isModal && !hideReplaceImage"
              >
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :style="{ 'background-color': hover ? '#fafafa' : '' }"
                    class="action-button"
                    color="primary"
                    text
                    @click="handleImageReplaceToggle"
                    data-pendo-id="slides-detail-replace-image"
                  >
                    <img src="/assets/img/image-replace.svg" alt />
                    <span class="action-button__text">{{
                      $t('replaceImage.replaceImage')
                    }}</span>
                  </v-btn>
                </v-hover>
              </div>
              <div
                v-else-if="!isModal && !hideReplaceImage"
                class="disabled-action-button"
              >
                <v-tooltip
                  top
                  max-width="200"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="action-button"
                      color="primary"
                      text
                      :ripple="false"
                    >
                      <img
                        v-if="!canReplaceImage"
                        src="/assets/img/replace-image-light-grey.svg"
                        alt
                      />
                      <span class="action-button__text">{{
                        $t('replaceImage.replaceImage')
                      }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t('slides.noImageOnTheSlide') }} </span>
                </v-tooltip>
              </div>
            </div>
            !-->
            <div class="add-fav-btn__wrapper" v-if="!isModal">
              <div
                class="actionContainer"
                v-if="
                  slideType === 'prezentSourcedSlide' ||
                  slideType === 'companyBrandedSlide'
                "
              >
                <div v-if="showCopied === true" class="copiedStatus">
                  <div>{{ $t('navbarActions.shareFreeTrial.copied') }}</div>
                </div>
                <v-btn
                  class="copyLinkBtn"
                  @click="copyToClipBoard('action')"
                  data-pendo-id="copy-link-btn"
                  v-else
                >
                  <v-icon>{{ 'mdi-link-variant' }}</v-icon>
                  <NewFeatureBadge
                    module-name="slides"
                    :feature-id="'slides_revamp_id'"
                    :offsetX="'0'"
                    :offsetY="'0'"
                    :size="'medium'"
                  >
                    {{ $t('userUploadPrezentation.copyLink') }}
                  </NewFeatureBadge>
                </v-btn>
              </div>
              <div
                class="actionContainer"
                v-else-if="
                  [
                    'userUploadedSlide',
                    'userGeneratedSlide',
                    'userRedesignSlide',
                    'userComplySlide',
                    'synthesisSlide',
                  ].includes(slideType) &&
                  (!hideCopyLink ||
                    !hideShareEllipsisAction ||
                    !hideEditBestPractisesEllipsisAction)
                "
              >
                <v-menu
                  top
                  offset-y
                  :key="menuKey"
                  :close-on-content-click="closeEllipsisMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="otherActionsBtn" v-bind="attrs" v-on="on">
                      <v-icon>{{ 'mdi-dots-vertical' }}</v-icon>
                      <NewFeatureBadge
                        module-name="slides"
                        :feature-id="'slides_revamp_id'"
                        :offsetX="'0'"
                        :offsetY="'0'"
                        :size="'medium'"
                      >
                        {{ $t('userUploadPrezentation.otherActions') }}
                      </NewFeatureBadge>
                    </v-btn>
                  </template>
                  <v-list>
                    <!--
                    TODO: Enable this when re upload flow is ready to be integrated
                    <v-list-item v-if="!hideReUploadEllipsisAction">
                      <v-icon>{{ 'mdi-tray-arrow-up' }}</v-icon>
                      <div class="actionName">{{ 'Re-upload' }}</div>
                    </v-list-item>
                    !-->
                    <v-list-item
                      v-if="!hideCopyLink"
                      @click="copyToClipBoard('menuItem')"
                    >
                      <div v-if="showCopied === true" class="copiedStatus">
                        <div>
                          {{ $t('navbarActions.shareFreeTrial.copied') }}
                        </div>
                      </div>
                      <div v-else class="copyOption">
                        <v-icon>{{ 'mdi-link-variant' }}</v-icon>
                        <div class="actionName">
                          {{ $t('userUploadPrezentation.copyLink') }}
                        </div>
                      </div>
                    </v-list-item>
                    <v-list-item
                      v-if="!hideShareEllipsisAction"
                      @click="handleShareSlide(result)"
                    >
                      <v-icon>{{ 'mdi-account-check-outline' }}</v-icon>
                      <div class="actionName">{{ $t('common.share') }}</div>
                    </v-list-item>
                    <v-list-item
                      v-if="!hideEditBestPractisesEllipsisAction"
                      @click="handleBestPractisesLoad"
                    >
                      <v-icon>{{ 'mdi-lightbulb-on-outline' }}</v-icon>
                      <div class="actionName">
                        {{ $t('slides.editBestPractices') }}
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters style="flex-wrap: nowrap; width: 100%">
        <v-col class="pt-0 pb-0 ma-0">
          <div class="footer-wrapper">
            <div class="node pt-0">
              <div
                class="node-text header text-left font-weight-bold"
                v-if="
                  slideType === 'prezentSourcedSlide' ||
                  slideType === 'companyBrandedSlide'
                "
              >
                {{ $t('bestPracticesGuide.knowDetails.selectNodeCount') }}
                <v-tooltip
                  top
                  max-width="200"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-title"
                      v-bind="attrs"
                      v-on="on"
                      v-text="`mdi-information-outline`"
                    />
                  </template>
                  <span>
                    {{
                      $t(
                        'bestPracticesGuide.knowDetails.selectNumberOfMessages',
                      )
                    }}
                  </span>
                </v-tooltip>
              </div>
              <div
                class="node-list"
                data-pendo-id="slides-node-list"
                v-if="
                  slideType === 'prezentSourcedSlide' ||
                  slideType === 'companyBrandedSlide'
                "
              >
                <span
                  v-for="item in availableNodes"
                  :key="item"
                  :class="{ 'disable-events': isImageReplaceWalkThrough }"
                >
                  <span
                    :class="`node-list-item ${
                      selectedNode === item.toString() ? 'outlined' : ''
                    } ${availableNodes.includes(item) ? '' : 'disabled'}`"
                    @click="handleNodeClick(item)"
                    >{{ item }}</span
                  >
                </span>
              </div>
            </div>
            <div class="slide-download">
              <v-btn
                v-if="
                  slideType === 'prezentSourcedSlide' ||
                  slideType === 'companyBrandedSlide'
                "
                :class="
                  disableRevertToOriginal ? 'disabledRevertBtn' : 'revertBtn'
                "
                :disabled="disableRevertToOriginal"
                @click="handleRevertSlide"
                data-pendo-id="revert-to-original-btn"
              >
                {{ $t('slides.revertToOriginal') }}
              </v-btn>
              <v-btn
                v-else-if="
                  [
                    'userUploadedSlide',
                    'userGeneratedSlide',
                    'userRedesignSlide',
                    'userComplySlide',
                    'synthesisSlide',
                  ].includes(slideType) &&
                  (editingSlideDetails ||
                    editingShareOptions ||
                    editingBestPractises)
                "
                @click="handleCancelDuringEdit"
                class="cancelEdit"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <!-- TODO: remove this check for generated slide when download to g slides is fixed." 
              Revert condition at line 816.
              <v-btn
                :style="{
                  'background-color': '#21a7e0',
                  'letter-spacing': 'normal',
                  'text-transform': 'none',
                  'box-shadow': 'none',
                  'border-radius': '20px',
                  color: '#ffffff',
                  'font-size': '18px',
                }"
                v-if="
                  [].includes(slideType) &&
                  !editingSlideDetails &&
                  !editingShareOptions &&
                  !editingBestPractises
                "
                :loading="downloadingSlide"
                @click="handleDownload(false, result, true)"
              >
                {{ $t('common.download') }}
              </v-btn>
              -->
              <Download
                :file-name="getFilename(result)"
                :item="result"
                :downloading="downloadingSlide"
                :log-download="logDownload"
                :count-first-time="1"
                :on-download="
                  () =>
                    handleDownload(
                      false,
                      result,
                      true /** this boolean indicates download from expanded view*/,
                    )
                "
                v-if="
                  slideType === 'prezentSourcedSlide' ||
                  slideType === 'companyBrandedSlide' ||
                  ((slideType === 'userUploadedSlide' ||
                    slideType === 'userComplySlide' ||
                    slideType === 'userGeneratedSlide' ||
                    slideType === 'userRedesignSlide' ||
                    slideType === 'synthesisSlide') &&
                    // || slideType === 'userGeneratedSlide') &&
                    !editingSlideDetails &&
                    !editingShareOptions &&
                    !editingBestPractises)
                "
                :speedDialPosRight="'10'"
                origin="slide"
                limitsKey="slide"
              />
              <v-btn
                v-if="
                  [
                    'userUploadedSlide',
                    'userGeneratedSlide',
                    'userRedesignSlide',
                    'userComplySlide',
                    'synthesisSlide',
                  ].includes(slideType) &&
                  (editingSlideDetails ||
                    editingShareOptions ||
                    editingBestPractises)
                "
                class="acceptEdit"
                @click="handleAccpetBtn"
                :disabled="disableAccept"
                :loading="editing"
              >
                {{ $t(updateText) }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from './event-bus';
import { getRelatedSlides, performSearch } from '@/graphql/queries';
import DialogService from '../../services/DialogService';
import { getTooltipForKeyword } from '@/components/common/KeywordTooltipMapping';
import Download from './Download.vue';
import { AnalyticsHandler } from './Analytics/AnalyticsHandler';
import RelatedSlide from '@/components/Main/Slides/RelatedSlide';
import { getFileURL } from '@/utils/calyrex';
import {
  getSlideDetail,
  updateSharePermissionsForSlide,
  // mergeSlides,
  // fetchRelatedSlides,
} from '@/utils/api-helper';
import { MatomoAnalyticsHandler } from './Analytics/MatomoAnalyticsHandler';
import utils, { doesPrefsObjectHasImage } from '../utils';
import usersApi from '../../API/users-api';
import {
  NA,
  TD_AUD,
  TD_DIAGRAM,
  TD_IDEA,
  TD_SIMILARSLIDETYPE,
  TD_PLACEMENT,
  TD_ISFAVORITED,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  getAudienceCompanyName,
  TD_EXPANDED_VIEW,
  TD_ISVIEWER,
  TD_ISEDITOR,
  TD_ISRESTRICTED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_SLIDES_LIBRARY,
  TD_PREZENTATION_LIBRARY,
} from './Analytics/MatomoTrackingDataHelper';
import { trackSlideEvents } from './Analytics/SlideEvents';
import { getThumbnail } from '../../store/modules/helper';
import {
  deleteCollection,
  updateCollection,
  updateSlide,
} from '../../utils/api-helper';
import NewFeatureBadge from './NewFeatureBadge.vue';
import EditUserSlideType from './EditUserSlideType.vue';
import ShareSlidePermissions from '../Main/Slides/ShareSlidePermissions.vue';
import BestPractises from '../Main/Slides/BestPractises.vue';
import BestPractisesDraggable from '../Main/Slides/BestPractisesDraggable.vue';
import { convertPdfToImage } from '../../utils/pdf-to-png';
import { GraphQLService } from '../../services/GraphQLService';
import { trackIconReplacementEvents } from './Analytics/IconReplacementEvents';

export default {
  name: 'SlideDetail',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  mixins: [DialogService],
  components: {
    Download,
    RelatedSlide,
    NewFeatureBadge,
    EditUserSlideType,
    ShareSlidePermissions,
    BestPractises,
    BestPractisesDraggable,
  },
  watch: {
    dialogContent() {
      this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    },
    favoriteSlides() {
      this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    },
    async type(newVal) {
      await this.handleLoadRelatedSlides(newVal === 'diagram');
    },
    async selectedNode() {
      await this.handleLoadRelatedSlides(this.type === 'diagram');
    },
  },
  props: {
    favoriteSlides: {
      type: Array,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    canReplaceImage: {
      type: Boolean,
      default: true,
    },
    updateThemeUpdated: {
      type: Function,
      default: () => {},
    },
    dialogContent: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    audience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    savedQueryPayload: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    handleFavorite: {
      type: Function,
      required: true,
    },
    handleDownload: {
      type: Function,
      required: true,
    },
    handleUpdateActiveSlide: {
      type: Function,
      required: false,
    },
    logDownload: {
      type: Function,
      required: true,
    },
    from: {
      type: String,
      required: false,
    },
    slideType: {
      type: String,
      default: '',
    },
    processingFavSlide: {
      type: Boolean,
      default: false,
    },
    downloadingSlide: {
      type: Boolean,
      default: false,
    },
    canReplaceIcons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: 'idea',
      mergedUrl: '',
      landscapeAsset: null,
      userAssetVotes: [],
      componentKey: 0,
      relatedSlides: [],
      filteredSlideByNode: [],
      selectedNode: null,
      fab: false,
      isFav: false,
      downloading: false,
      result: {},
      currentProductId: null,
      // relatedProducts: [],
      nodeList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      availableNodes: [],
      siblings: [],
      mainSlideHeight: 0,
      matomoTypeMap: {
        diagram: TD_DIAGRAM,
        idea: TD_IDEA,
      },
      // dialogContentOnLoad: null,
      showCopied: false,
      toggleBestPractises: false,
      headingsWidth: 70,
      editingSlideDetails: false,
      editingShareOptions: false,
      editingBestPractises: false,
      disableAccept: false,
      sidePanelAction: 'editDetails',
      menuKey: 0,
      closeEllipsisMenu: true,
      slideDetailUpdateData: {},
      sharePermissionsUpdateData: {},
      editing: false,
      updateText: 'common.accept',
      pointsKey: 0,
      slideThumbnailRef: null,
      draggableLeft: 0,
      loadingRelatedSlides: true,
      isRelatedSlidesFetchError: false,
      processingCollection: false,
      compKeyEditSlide: 0,
      compKeyEditBP: 0,
      compKeyShareSlide: 0,
      bestPractisesToggleInitialState: false,
      // replaceMenuItems: [],
    };
  },
  computed: {
    ...mapState('users', [
      'assetFolder',
      'currentTheme',
      'startLoadingSlidesFlag',
      'themes',
    ]),
    ...mapState('users', ['currentUser', 'assetVotes']),
    ...mapState('users', ['sessionKey']),
    ...mapState('users', ['assetFolder']),
    ...mapState('slidesCollection', ['tailorID', 'nodeCountChanged']),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    ...mapGetters('slidesCollection', [
      'getAddedCollections',
      'isCollectionLimitReached',
    ]),
    ...mapGetters('slidesStore', [
      'getBestPractisesOnSlide',
      'getBestPractiseSelected',
      'getActiveSlide',
      'getSlidesSnapshot',
      'getSlideViewDialogContentOnLoad',
      'getIsIconNotFound',
      'getIsImageNotFound',
    ]),
    showReplaceVisual() {
      return this.showReplaceIcon || this.showReplaceImage;
    },
    showReplaceImage() {
      return (
        !this.isModal &&
        !this.hideReplaceImage &&
        this.allowReplaceImageOverall &&
        this.allowReplaceImageTabs
      );
    },
    showReplaceIcon() {
      return (
        !this.isModal &&
        !this.hideReplaceIcon &&
        this.allowReplaceIconOverall &&
        this.allowReplaceIconTabs
      );
    },
    hideReUploadEllipsisAction() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false)
      );
    },

    hideShareEllipsisAction() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false)
      );
    },

    hideEditBestPractisesEllipsisAction() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        this.slideType === 'userRedesignSlide' ||
        this.slideType === 'userComplySlide' ||
        (['userUploadedSlide', 'userGeneratedSlide'].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        (this.slideType === 'synthesisSlide' &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideCopyLink() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        (this.slideType === 'synthesisSlide' &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer')
      );
    },

    hideEditSlideDetails() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        (this.slideType === 'synthesisSlide' &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideReplaceImage() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideReplaceIcon() {
      return (
        // this.slideType === 'userRedesignSlide' ||
        // this.slideType === 'userComplySlide' ||
        // this.slideType === 'synthesisSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    allowReplaceIconOverall() {
      const repIconOverallFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'replace_icon_overall',
      );
      return repIconOverallFeature && repIconOverallFeature.enabled;
    },

    disableRevertToOriginal() {
      return _.isEqual(
        this.dialogContent,
        this.getSlideViewDialogContentOnLoad,
      );
    },

    isReplaceable() {
      const selectedSlide = this.dialogContent;
      return doesPrefsObjectHasImage(selectedSlide.prefs);
    },
    isAddedToCollection() {
      return this.getAddedCollections.includes(this.result.unique_id);
    },
    getTitle() {
      return this.result.title || this.result.asset?.title || '';
    },
    replaceMenuItems() {
      return [
        this.showReplaceImage
          ? {
              title: 'slideDetails.imageTitle',
              icon: 'mdi-image-outline',
              disabled: !this.canReplaceImage || this.getIsImageNotFound,
              pendoId: 'replace-image-btn',
              eleId: this.isImageReplaceWalkThrough
                ? 'walkthough-replace-img-btn-id'
                : undefined,
              clickHandler: () => this.handleImageReplaceToggle(),
            }
          : {},
        this.showReplaceIcon
          ? {
              title: 'slideDetails.iconTitle',
              icon: `/assets/img/replace-${
                this.canReplaceIcons && !this.getIsIconNotFound
                  ? 'image-black'
                  : 'icon-disabled'
              }.svg`,
              disabled: !this.canReplaceIcons || this.getIsIconNotFound,
              pendoId: '',
              eleId: '',
              clickHandler: () => this.handleReplaceIconToggle(),
            }
          : {},
      ].filter((itm) => Object.keys(itm).length > 0);
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setBestPractiseSelected',
      'setFlowActive',
      'saveSlidesStateSnapshot',
      'setIsSlideACLDirty',
      'setIsSlideBestPractisesDirty',
      'setIsSlideDetailsDirty',
      'setBestPractisesOnSlide',
      'setSlideDetailViewChangedAudienceAndTemplate',
      'setSlideViewDialogContentOnLoad',
    ]),
    ...mapActions('slidesCollection', [
      'setCollectionData',
      'setNodeCountChange',
    ]),
    ...mapActions('users', [
      'setAssetVotes',
      'setCurrentUser',
      'setCurrentTheme',
    ]),
    isFeatureEnabled(key) {
      return this.currentUser?.features?.find((f) => f.feature_name === key)
        ?.enabled;
    },

    getTooltipContentForBestPractisesToggle() {
      const bestPractises = this.getBestPractisesOnSlide.filter(
        (item) => item.isAdded,
      );
      if (bestPractises.length <= 0) {
        return this.$t('slides.noBestPracticesAvailabledOnSlide');
      }
      if (bestPractises.length === 1) {
        return this.$t('slides.oneBestPracticeAvailable');
      }
      return this.$t('slides.bestPracticesAvailable', {
        count: bestPractises.length,
      });
    },

    handleToggleButton() {
      trackSlideEvents.slidesBestPracticeAddedToggleClick(
        this.result,
        this.currentUser,
        {
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
          [TD_COMMON_COLUMN_NAME]: 'toggle changed to',
          [TD_COMMON_COLUMN_VALUE]: this.toggleBestPractises ? 'On' : 'Off',
        },
      );
    },

    getDescriptionWidthBasedOnSlide() {
      if (this.slideType === 'prezentSourcedSlide') {
        return 80;
      }
      if (this.slideType === 'companyBrandedSlide') {
        return 53;
      }
      return this.getActiveSlide && this.getActiveSlide.isOwner ? 23 : 27;
    },

    getSourceForSlide() {
      if (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide'
      ) {
        return this.result.prefs.source;
      }
      return this.result?.companies[0].toLowerCase().trim();
    },

    handleImageReplaceToggle() {
      this.setFlowActive('replaceImage');
      this.$emit('toggleImageReplace');
    },

    handleReplaceIconToggle() {
      trackIconReplacementEvents.iconReplacementClickOnReplaceIcon(
        this.result,
        this.currentUser,
        {
          [TD_PLACEMENT]:
            this.from === 'slides'
              ? TD_SLIDES_LIBRARY
              : TD_PREZENTATION_LIBRARY,
        },
      );
      this.setFlowActive('replaceIcon');
      this.$emit('toggleIconReplace');
    },
    getSidePanelHeader() {
      let msg = '';
      if (this.sidePanelAction === 'editDetails') {
        msg = this.$t('userUploadPrezentation.slideDetails');
      } else if (this.sidePanelAction === 'shareDetails') {
        msg = this.$t('slides.shareWithColleagues');
      } else if (this.sidePanelAction === 'bestPractises') {
        msg = this.$t('bestPracticesGuide.knowDetails.bestPractices');
      }
      return msg;
    },

    getSidePanelSubHeader() {
      let msg = '';
      if (this.sidePanelAction === 'editDetails') {
        if (this.editingSlideDetails === true) {
          msg = this.$t('slides.modifySlideTags');
        } else {
          msg = this.$t('userUploadPrezentation.slideDetailsSubtext');
        }
      } else if (this.sidePanelAction === 'shareDetails') {
        msg = '';
      } else if (this.sidePanelAction === 'bestPractises') {
        msg = this.$t('slides.addUptoFiveBestPractices');
      }
      return msg;
    },

    handleShareSlide() {
      this.editingSlideDetails = false;
      this.editingBestPractises = false;
      this.editingShareOptions = true;
      this.sidePanelAction = 'shareDetails';
      this.pointsKey++;
      this.compKeyShareSlide++;
      this.bestPractisesToggleInitialState = this.toggleBestPractises;
      this.handleResetBestPractises();
      this.setBestPractiseSelected({});
      this.setFlowActive('shareACL');
      this.setIsSlideBestPractisesDirty(false);
      this.setIsSlideDetailsDirty(false);
    },
    findSlideValuesChanged() {
      let valuesChanged = '';
      valuesChanged +=
        this.result?.name !== this.$refs.editUserSlideType?.slideName
          ? 'Name&'
          : 'NA&';
      valuesChanged +=
        this.result?.summary !== this.$refs.editUserSlideType?.description
          ? 'Description&'
          : 'NA&';
      valuesChanged +=
        this.result?.templateCode !==
        this.$refs.editUserSlideType?.template?.code
          ? 'Template&'
          : 'NA&';
      valuesChanged +=
        this.result?.audience !== this.$refs.editUserSlideType?.audience?.email
          ? 'Audience&'
          : 'NA&';
      valuesChanged +=
        this.result?.prefs?.node !== this.$refs.editUserSlideType?.nodeCount
          ? 'Node-count&'
          : 'NA&';
      valuesChanged +=
        JSON.stringify(this.slideDetailUpdateData?.categories) !==
        JSON.stringify(this.$refs.editUserSlideType?.businessIdeasInitial)
          ? 'Idea&'
          : 'NA&';
      valuesChanged +=
        JSON.stringify(this.slideDetailUpdateData?.keywords) !==
        JSON.stringify(this.$refs.editUserSlideType?.keywordsInitial)
          ? 'Keywords'
          : 'NA';
      return valuesChanged;
    },

    handleBestPractisesLoad() {
      this.editingSlideDetails = false;
      this.editingShareOptions = false;
      this.editingBestPractises = true;
      this.sidePanelAction = 'bestPractises';
      this.pointsKey++;
      this.compKeyEditBP++;
      this.bestPractisesToggleInitialState = this.toggleBestPractises;
      this.toggleBestPractises = true;
      this.handleResetBestPractises();
      this.setBestPractiseSelected({});
      this.setFlowActive('bestPractises');
      this.setIsSlideACLDirty(false);
      this.setIsSlideDetailsDirty(false);
      trackSlideEvents.slidesOtherActionsEditBestPracticesClick(
        this.result,
        this.currentUser,
        {
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
        },
      );
    },

    getAcceptButtonState(val) {
      this.disableAccept = val;
    },

    getDataForSlideUpdate(val) {
      this.slideDetailUpdateData = {
        assetID: this.result.unique_id,
        name: val.slideName,
        summary: val.description,
        categories: val.businessIdeas,
        keywords: val.keywords,
        templateCode: val.template,
        audience: val.audience,
        audienceType: val.audienceType,
        audienceNumId: val.audienceNumId,
        node: val.nodeCount,
      };
    },

    getDataForSharePermissionsUpdate(val) {
      this.sharePermissionsUpdateData = { ...val };
    },

    handleUpdateCurrentThemeForUser() {
      usersApi.methods
        .updateUserProfile({
          defaultTheme: this.slideDetailUpdateData.templateCode,
        })
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.updateThemeUpdated(true);
          this.setCurrentUser(this.currentUser);
          this.setCurrentTheme(
            this.themes.find(
              (thm) => thm.code === this.slideDetailUpdateData.templateCode,
            ),
          );
        })
        .catch((err) =>
          console.log('error while updating theme for current user', err),
        );
    },

    async handleAccpetBtn() {
      this.editing = true;
      if (this.sidePanelAction === 'editDetails') {
        console.log('this.slideDetailUpdateData', this.slideDetailUpdateData);
        await updateSlide(this.slideDetailUpdateData)
          .then(() => {
            this.updateText = 'common.updated';
            trackSlideEvents.slidesEditSlideDetailsUpdated(
              this.result,
              this.currentUser,
              {
                [TD_ISFAVORITED]: this.favoriteSlides.includes(
                  this.result?.unique_id,
                )
                  ? '1'
                  : NA,
                [TD_COMMON_COLUMN_NAME]: 'Fields changed',
                [TD_COMMON_COLUMN_VALUE]: this.findSlideValuesChanged(),
              },
            );
            setTimeout(() => {
              if (
                this.getSlidesSnapshot &&
                Object.keys(this.getSlidesSnapshot).length > 0
              ) {
                if (
                  this.slideDetailUpdateData.templateCode !==
                  this.result.templateCode
                ) {
                  this.handleUpdateCurrentThemeForUser();
                }
                /* update the current theme, audience and flags
                  in the snapshot that these two properties were edited. */
                // update the slide in the snapshot
                const slidesListFromSnapShotUpdated =
                  this.getSlidesSnapshot.slidesFilteredChipAdvanced.map(
                    (sld) => {
                      if (sld.unique_id === this.result.unique_id) {
                        return {
                          ...sld,
                          prefs: {
                            ...this.result.prefs,
                            node: this.slideDetailUpdateData.node,
                          },
                          name: this.slideDetailUpdateData.name,
                          summary: this.slideDetailUpdateData.summary,
                          categories: this.slideDetailUpdateData.categories,
                          keywords: this.slideDetailUpdateData.keywords,
                          templateCode: this.slideDetailUpdateData.templateCode,
                          audience: this.slideDetailUpdateData.audience,
                          audienceType: this.slideDetailUpdateData.audienceType,
                          audienceNumId:
                            this.slideDetailUpdateData.audienceNumId,
                        };
                      }
                      return { ...sld };
                    },
                  );
                this.saveSlidesStateSnapshot({
                  ...this.getSlidesSnapshot,
                  slidesFilteredChipAdvanced: slidesListFromSnapShotUpdated,
                  isAudienceChangedWhileEdit:
                    this.slideDetailUpdateData.audience !==
                    this.result.audience,
                  isTemplateChangedWhileEdit:
                    this.slideDetailUpdateData.templateCode !==
                    this.result.templateCode,
                  filterTailor: this.getSlidesSnapshot.filterTailor,
                  templateSelected:
                    this.slideDetailUpdateData.templateCode !==
                    this.result.templateCode
                      ? this.themes.find(
                          (thm) =>
                            thm.code ===
                            this.slideDetailUpdateData.templateCode,
                        )
                      : this.getSlidesSnapshot.templateSelected,
                });
              } else if (
                this.slideDetailUpdateData.templateCode !==
                  this.result.templateCode ||
                this.slideDetailUpdateData.audience !== this.result.audience
              ) {
                if (
                  this.slideDetailUpdateData.templateCode !==
                  this.result.templateCode
                ) {
                  this.handleUpdateCurrentThemeForUser();
                }
                this.setSlideDetailViewChangedAudienceAndTemplate({
                  templateToApply:
                    this.slideDetailUpdateData.templateCode !==
                    this.result.templateCode
                      ? this.themes.find(
                          (thm) =>
                            thm.code ===
                            this.slideDetailUpdateData.templateCode,
                        )
                      : null,
                  audienceToApply: this.slideDetailUpdateData.audience,
                });
              }
              this.handleUpdateActiveSlide({
                asset: {
                  ...this.result,
                  prefs: {
                    ...this.result.prefs,
                    node: this.slideDetailUpdateData.node,
                  },
                  name: this.slideDetailUpdateData.name,
                  summary: this.slideDetailUpdateData.summary,
                  categories: this.slideDetailUpdateData.categories,
                  keywords: this.slideDetailUpdateData.keywords,
                  templateCode: this.slideDetailUpdateData.templateCode,
                  audience: this.slideDetailUpdateData.audience,
                  audienceType: this.slideDetailUpdateData.audienceType,
                  audienceNumId: this.slideDetailUpdateData.audienceNumId,
                },
              });
              this.result = {
                ...this.result,
                prefs: {
                  ...this.result.prefs,
                  node: this.slideDetailUpdateData.node,
                },
                name: this.slideDetailUpdateData.name,
                summary: this.slideDetailUpdateData.summary,
                categories: this.slideDetailUpdateData.categories,
                keywords: this.slideDetailUpdateData.keywords,
                templateCode: this.slideDetailUpdateData.templateCode,
                audience: this.slideDetailUpdateData.audience,
                audienceType: this.slideDetailUpdateData.audienceType,
                audienceNumId: this.slideDetailUpdateData.audienceNumId,
              };
              this.editingSlideDetails = false;
              this.slideDetailUpdateData = {};
              this.updateText = 'common.accept';
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
        this.editing = false;
      } else if (this.sidePanelAction === 'shareDetails') {
        let updatePayload = {};
        const teamEntity = [];
        const companyEntity = [];
        let usersEntities = [];
        if (this.sharePermissionsUpdateData.shareOptionType === 'me') {
          updatePayload = {
            sharedEntities: [],
          };
        } else if (
          this.sharePermissionsUpdateData.shareOptionType === 'share'
        ) {
          if (this.sharePermissionsUpdateData.teamCheckPermission === 'team') {
            teamEntity.push({
              sharedEntityID: this.currentUser.team.id,
              sharedEntityType: 'team',
              permissionLevel: this.sharePermissionsUpdateData.teamPermission,
            });
          }
          if (
            this.sharePermissionsUpdateData.companyCheckPermission === 'company'
          ) {
            companyEntity.push({
              sharedEntityID: this.currentUser.company.id,
              sharedEntityType: 'company',
              permissionLevel:
                this.sharePermissionsUpdateData.companyPermission,
            });
          }
          if (this.sharePermissionsUpdateData.userCheckPermission === 'user') {
            usersEntities = [
              ...this.sharePermissionsUpdateData.usersWithPermissions.map(
                (usr) => ({
                  sharedEntityID: usr.id,
                  permissionLevel: usr.permissionLevel,
                  sharedEntityType: 'user',
                }),
              ),
            ];
          }
          updatePayload = {
            sharedEntities: [...usersEntities, ...teamEntity, ...companyEntity],
          };
        }
        const sharedEntitiesData = this.getSharedEntitiesData(
          updatePayload?.sharedEntities,
        );
        trackSlideEvents.slidesShareUpdate(this.result, this.currentUser, {
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
          [TD_PLACEMENT]: TD_EXPANDED_VIEW,
          ...sharedEntitiesData,
        });
        await updateSharePermissionsForSlide({
          ...updatePayload,
          slideID: this.result.unique_id,
          type: this.result.prefs.source,
        })
          .then(() => {
            this.updateText = 'common.shared';
            // updating is shared Property;
            setTimeout(() => {
              updateSlide({
                assetID: this.result.unique_id,
                isShared: !(updatePayload.sharedEntities.length <= 0),
              })
                .then((r) => {
                  console.log(r);
                })
                .catch((e) => console.log(e));
              // update the slide in the snapshot
              if (
                this.getSlidesSnapshot &&
                Object.keys(this.getSlidesSnapshot).length > 0
              ) {
                const slidesListFromSnapShotUpdated =
                  this.getSlidesSnapshot.slidesFilteredChipAdvanced.map(
                    (sld) => {
                      if (sld.unique_id === this.result.unique_id) {
                        return {
                          ...sld,
                          isShared: !(updatePayload.sharedEntities.length <= 0),
                        };
                      }
                      return { ...sld };
                    },
                  );
                this.saveSlidesStateSnapshot({
                  ...this.getSlidesSnapshot,
                  slidesFilteredChipAdvanced: slidesListFromSnapShotUpdated,
                });
              }
              this.handleUpdateActiveSlide({
                asset: {
                  ...this.result,
                  isShared: !(updatePayload.sharedEntities.length <= 0),
                },
              });
              this.result = {
                ...this.result,
                isShared: !(updatePayload.sharedEntities.length <= 0),
              };
              this.editingShareOptions = false;
              this.sidePanelAction = 'editDetails';
              this.updateText = 'common.accept';
              this.sharePermissionsUpdateData = {};
            }, 1000);
          })
          .catch((err) => console.log(err));
        this.editing = false;
      } else if (this.sidePanelAction === 'bestPractises') {
        await updateSlide({
          assetID: this.result.unique_id,
          bestPractices: this.getBestPractisesOnSlide,
        })
          .then(() => {
            this.updateText = 'common.updated';
            setTimeout(() => {
              if (
                this.getSlidesSnapshot &&
                Object.keys(this.getSlidesSnapshot).length > 0
              ) {
                const slidesListFromSnapShotUpdated =
                  this.getSlidesSnapshot.slidesFilteredChipAdvanced.map(
                    (sld) => {
                      if (sld.unique_id === this.result.unique_id) {
                        return {
                          ...sld,
                          bestPractices: this.getBestPractisesOnSlide.map(
                            (item) => ({
                              ...item,
                              isAdded: true,
                              isNew: false,
                            }),
                          ),
                        };
                      }
                      return { ...sld };
                    },
                  );
                this.saveSlidesStateSnapshot({
                  ...this.getSlidesSnapshot,
                  slidesFilteredChipAdvanced: slidesListFromSnapShotUpdated,
                });
              }
              this.handleUpdateActiveSlide({
                asset: {
                  ...this.result,
                  bestPractices: this.getBestPractisesOnSlide.map((item) => ({
                    ...item,
                    isAdded: true,
                    isNew: false,
                  })),
                },
              });
              this.result = {
                ...this.result,
                bestPractices: this.getBestPractisesOnSlide.map((item) => ({
                  ...item,
                  isAdded: true,
                  isNew: false,
                })),
              };
              this.editingBestPractises = false;
              this.toggleBestPractises = this.bestPractisesToggleInitialState;
              this.sidePanelAction = 'editDetails';
              this.updateText = 'common.accept';
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
        this.editing = false;
      }
      this.setIsSlideACLDirty(false);
      this.setIsSlideBestPractisesDirty(false);
      this.setIsSlideDetailsDirty(false);
    },

    handleResetBestPractises() {
      this.setBestPractisesOnSlide(
        this.getActiveSlide.bestPractices
          ? this.getActiveSlide.bestPractices.map((item) => ({
              ...item,
              isAdded: true,
              isNew: false,
            }))
          : [],
      );
    },

    handleCancelDuringEdit() {
      if (this.sidePanelAction === 'editDetails') {
        this.editingSlideDetails = false;
        this.compKeyEditSlide++;
      } else if (this.sidePanelAction === 'shareDetails') {
        this.editingShareOptions = false;
        this.compKeyShareSlide++;
      } else if (this.sidePanelAction === 'bestPractises') {
        this.editingBestPractises = false;
        this.compKeyEditSlide++;
      }
      this.sidePanelAction = 'editDetails';
      this.editingSlideDetails = false;
      this.pointsKey++;
      this.setBestPractiseSelected({});
      this.handleResetBestPractises();
      this.toggleBestPractises = this.bestPractisesToggleInitialState;
      this.setIsSlideACLDirty(false);
      this.setIsSlideBestPractisesDirty(false);
      this.setIsSlideDetailsDirty(false);
    },

    toggleEditSlideDetails() {
      this.editingSlideDetails = true;
      this.editingShareOptions = false;
      this.editingBestPractises = false;
      this.sidePanelAction = 'editDetails';
      this.compKeyEditSlide++;
      this.pointsKey++;
      this.bestPractisesToggleInitialState = this.toggleBestPractises;
      this.handleResetBestPractises();
      this.setBestPractiseSelected({});
      this.setIsSlideACLDirty(false);
      this.setIsSlideBestPractisesDirty(false);
      this.setFlowActive('editSlideDetails');
      trackSlideEvents.slidesEditSlideDetailsClick(
        this.result,
        this.currentUser,
        {
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
        },
      );
    },

    getAuthor() {
      let authorName = '';
      if (this.slideType === 'companyBrandedSlide') {
        authorName = 'Brand Team';
      } else {
        authorName =
          this.getActiveSlide && this.getActiveSlide.isOwner
            ? 'Me'
            : this.getActiveSlide.ownerName;
      }
      return authorName;
    },

    copyToClipBoard(type) {
      if (type === 'action') {
        navigator.clipboard.writeText(
          `${window.location.origin}/home/slides/${this.result.unique_id}`,
        );
        this.showCopied = true;
        setTimeout(() => {
          this.showCopied = false;
        }, 1000);
      } else {
        this.closeEllipsisMenu = false;
        navigator.clipboard.writeText(
          `${window.location.origin}/home/slides/${this.result.id}`,
        );
        this.showCopied = true;
        trackSlideEvents.slidesElipsisCopyLink(this.result, this.currentUser, {
          [TD_PLACEMENT]: TD_EXPANDED_VIEW,
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
        });
        setTimeout(() => {
          this.showCopied = false;
          this.closeEllipsisMenu = true;
          this.menuKey++;
        }, 1000);
      }
    },

    getSharedEntitiesData(sharedEntities) {
      const sharedEntitiesTrackingData = {};
      if (!sharedEntities.length) {
        return sharedEntitiesTrackingData;
      }

      const viewers = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Viewer',
      );
      const isViewerList = this.getEntityList(viewers);
      if (isViewerList) {
        sharedEntitiesTrackingData[TD_ISVIEWER] = isViewerList;
      }
      const editors = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Editor',
      );
      const isEditorsList = this.getEntityList(editors);
      if (isEditorsList) {
        sharedEntitiesTrackingData[TD_ISEDITOR] = isEditorsList;
      }
      const restricted = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Restricted',
      );
      const isRestrictedList = this.getEntityList(restricted);
      if (isRestrictedList) {
        sharedEntitiesTrackingData[TD_ISRESTRICTED] = isRestrictedList;
      }

      return sharedEntitiesTrackingData;
    },

    getEntityList(entities) {
      let eList = '';
      entities.forEach((sEntity) => {
        const entityType = sEntity.sharedEntityType;
        console.log('entityType', entityType);
        if (entityType === 'company') {
          eList += 'My Company$';
        }
        if (entityType === 'team') {
          eList += 'My Team$';
        }
        if (entityType === 'user') {
          eList += `${sEntity.shared_entity_id}$`;
        }
      });
      return eList;
    },

    capitalizeFirstLetter(str) {
      return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
    },

    async handleRevertSlide() {
      if (this.assetVotes.length > 0) {
        this.userAssetVotes = this.assetVotes.slice();
      }
      this.handleUpdateActiveSlide({
        asset: this.getSlideViewDialogContentOnLoad,
      });
      await this.getLandscape(this.getSlideViewDialogContentOnLoad);
      this.result = { ...this.getSlideViewDialogContentOnLoad };
      this.setSlideViewDialogContentOnLoad({
        ...this.getSlideViewDialogContentOnLoad,
      });

      try {
        await this.handleLoadNodes();
      } catch (err) {
        console.log(err);
      }
      this.updateViewCount();
      this.selectedNode = this.result.prefs.node;
      this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    },

    trackCollectionMaxLimitEvent() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) {
        trackSlideEvents.slidesCollectionMaxLimit(
          this.result,
          this.currentUser,
          {
            [TD_AUD]: this.tailorID || this.currentUser?.user?.id,
            [TD_ISFAVORITED]: this.favoriteSlides.includes(
              this.result.unique_id,
            )
              ? '1'
              : NA,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.audience,
              this.currentUser,
            ),
          },
        );
      }
    },

    async handleCollection() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) return;
      if (this.isAddedToCollection) {
        const params = {
          type: 'slide',
          slideID: String(this.result.unique_id),
          templateID: this.currentTheme.id,
          slideFileName: String(this.result.file || this.result.filename),
        };
        this.processingCollection = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            // this.collections[indexOfSet].slides.splice(indexOfSlide, 1);
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
        trackSlideEvents.slidesRemoveFromCollection(
          this.result,
          this.currentUser,
          {
            [TD_ISFAVORITED]: this.favoriteSlides.includes(
              this.result.unique_id,
            )
              ? '1'
              : NA,
            [TD_AUD]: this.getAudienceDataToTrack()?.email,
            [TD_PLACEMENT]: TD_EXPANDED_VIEW,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.audience,
              this.currentUser,
            ),
          },
        );
      } else {
        const params = {
          templateID: this.currentTheme?.id,
          templateCode: this.currentTheme?.code,
          templateName: this.currentTheme?.name,
          slideFileName: this.result.file || this.result.filename,
          slideThumbnail: this.result.thumbnail,
          slideID: String(this.result.unique_id),
          idea: this.result.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.result.prefs.construct,
          isImageReplaced: false,
          isIconReplaced: false,
          source: this.result.prefs.source,
          sanitizedLevel: this.result.sanitizedLevel,
          acceptedRejectedCount: this.result.acceptedRejectedCount,
        };
        this.processingCollection = true;
        await updateCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
        if (this.nodeCountChanged) {
          trackSlideEvents.slidesNodeCountChangeAddToCollection(
            this.result,
            this.currentUser,
            {
              [TD_ISFAVORITED]: this.favoriteSlides.includes(
                this.result.unique_id,
              )
                ? '1'
                : NA,
              [TD_AUD]: this.getAudienceDataToTrack()?.email,
              [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                this.audience,
                this.currentUser,
              ),
            },
          );
        } else {
          trackSlideEvents.slidesAddToCollection(
            this.result,
            this.currentUser,
            {
              [TD_ISFAVORITED]: this.favoriteSlides.includes(
                this.result.unique_id,
              )
                ? '1'
                : NA,
              [TD_AUD]: this.getAudienceDataToTrack()?.email,
              [TD_PLACEMENT]: TD_EXPANDED_VIEW,
              [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                this.audience,
                this.currentUser,
              ),
            },
          );
        }
      }
    },

    async handleLoadRelatedSlides(withDiagram = false) {
      const category = this.result.categories[0];
      const payload = {};
      const fingerprint = this.audience.fingerPrint
        ? this.audience.fingerPrint.toLowerCase()
        : 'director';
      payload.choice = this.getChoice(fingerprint);
      payload.category = category;
      payload.username = this.currentUser.user.cognitoID;
      payload.limit = 24;
      payload.skip = 0;
      payload.morprefs = {};
      payload.morprefs.construct = withDiagram
        ? [this.result.prefs.construct]
        : [];
      payload.morprefs.node = this.selectedNode
        ? [this.selectedNode.toString()]
        : [];
      payload.similar = this.result.unique_id;
      if (withDiagram) {
        delete payload.category;
      }
      console.log('got', payload, this.selectedNode);
      this.filteredSlideByNode = [];
      this.relatedSlides = [];

      this.loadingRelatedSlides = true;
      return await this.getRecommendedSlides(
        payload,
        this.sessionKey,
        this.currentTheme,
      )
        .then((slides) => {
          this.relatedSlides = slides.filter(
            (e) => e.unique_id !== this.result.unique_id,
          );
          this.filteredSlideByNode = JSON.parse(
            JSON.stringify(this.relatedSlides),
          );

          this.$forceUpdate();
          this.loadingRelatedSlides = false;
        })
        .catch((err) => {
          this.loadingRelatedSlides = false;
          this.isRelatedSlidesFetchError = true;
          console.log(err);
        });
    },

    async handleLoadNodes() {
      const sibPayload = {
        unique_id: this.result?.parent?.unique_id
          ? this.result?.parent?.unique_id
          : this.result?.unique_id,
        sid: this.result?.parent?.sid,
      };
      return this.getSiblings(sibPayload).then((siblings) => {
        this.siblings = siblings;
        const availableNodes = [];
        console.log(siblings);
        siblings.forEach((item) => {
          availableNodes.push(parseInt(item.asset.prefs.node, 10));
        });
        this.availableNodes = availableNodes.slice().sort((a, b) => a - b);
        return true;
      });
    },

    updateViewCount() {
      const userData = {
        performIncrements: {
          countSlideView: 1,
        },
      };

      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
      AnalyticsHandler.logUserStats(this.currentUser);
    },

    async getLandscape(asset) {
      if (!asset.landscape.startsWith('https')) {
        if (asset.prefs.source === 'uploaded') {
          const uploadedLandscape = await getFileURL(
            this.currentUser.user.cognitoID,
            asset.landscape,
            this.currentUser.userIp,
            'IOCUpload',
          );
          this.landscapeAsset = await convertPdfToImage(uploadedLandscape);
        } else if (
          asset.prefs.source === 'generated' ||
          asset.prefs.source === 'synthesis' ||
          asset.prefs.source === 'redesign' ||
          asset.prefs.source === 'comply'
        ) {
          if (
            asset.landscape.startsWith('/newImageReplaceOutput/output/') ||
            asset.landscape.startsWith('/output/pptx/')
          ) {
            this.landscapeAsset = await getThumbnail(asset.landscape);
          } else {
            this.landscapeAsset = await getFileURL(
              this.currentUser.user.cognitoID,
              asset.landscape,
              this.currentUser.userIp,
              'IOCUpload',
            );
          }
        } else {
          this.landscapeAsset = await getFileURL(
            this.currentUser.user.cognitoID,
            asset.landscape,
            this.currentUser.userIp,
          );
        }
      } else {
        this.landscapeAsset = asset.landscape;
      }
    },

    getSlidesParam(item) {
      const file = item && item.asset ? item.asset.filename.substring(1) : '';
      return `&slides=${file}`;
    },

    getFilename(item) {
      if (!item) return '';
      if (item.filename) {
        return item.filename.toString();
      }
      if (item.asset) {
        return item.asset.filename.toString();
      }
      return '';
    },

    getDescription(keyword) {
      return getTooltipForKeyword(keyword);
    },

    handleClose() {
      this.downloading = false;
      EventBus.$emit('SET_SIMILAR_SLIDE_SELECTED', false);
      this.$modal.hide('SlideDetail');
    },

    async handleNodeClick(selectedNode) {
      const category = this.result.categories
        ? this.result.categories[0]
        : this.result.asset.categories[0];
      const fingerprint = this.audience.fingerPrint
        ? this.audience.fingerPrint.toLowerCase()
        : 'director';
      const payload = {};
      const andprefs = {};
      andprefs.node = selectedNode.toString();
      payload.category = category;
      payload.username = this.currentUser.user.cognitoID;
      payload.limit = 30;
      payload.skip = 0;
      payload.choice = this.getChoice(fingerprint);
      payload.andprefs = andprefs;

      this.relatedSlides = [];
      this.filteredSlideByNode = [];
      this.loadingRelatedSlides = true;
      await this.getRecommendedSlides(payload)
        .then((slides) => {
          this.relatedSlides = slides;
          this.filteredSlideByNode = slides;
          this.selectedNode = selectedNode.toString();
          this.selectSibling();
          this.setIntervalX(
            () => {
              this.componentKey += 1;
            },
            200,
            1,
          );
          this.loadingRelatedSlides = false;
        })
        .catch((err) => {
          this.loadingRelatedSlides = false;
          this.isRelatedSlidesFetchError = true;
          console.log(err);
        });
      AnalyticsHandler.performSlideNodeSelection(
        this.currentUser,
        this.result.unique_id,
        selectedNode,
      );
      if (
        selectedNode !== this.result.prefs.node &&
        this.availableNodes.length > 0
      ) {
        trackSlideEvents.slidesNodeCountChange(this.result, this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
        });
        this.setNodeCountChange(true);
      }
    },

    selectSibling() {
      const foundSibling = this.siblings.find(
        (node) => node.asset.prefs.node === this.selectedNode.toString(),
      );
      this.result = foundSibling.asset;
      if (this.handleUpdateActiveSlide) {
        this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
        this.handleUpdateActiveSlide(foundSibling);
      }
      this.getLandscape(foundSibling);
    },

    handleRelatedProductClick(product) {
      const arr = [];
      arr.push(product.asset.unique_id);
      const params = {
        assetIds: arr,
        getFavourite: true,
      };
      getSlideDetail(params)
        .then((slideData) => {
          const slideMetaData = slideData.data;
          for (const key in slideMetaData) {
            if (
              slideMetaData[key].isFavourite === true &&
              !this.favoriteSlides.includes(key)
            ) {
              this.favoriteSlides.push(key);
            }
          }
        })
        .catch((err) => console.log(err));
      if (this.handleUpdateActiveSlide) {
        this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
        this.handleUpdateActiveSlide(product);
      }
      this.result = {
        ...product.asset,
      };
      this.getLandscape(product);
      const sibPayload = {
        unique_id: this.result.parent
          ? this.result.parent.unique_id
          : this.result.unique_id,
        sid: this.result.parent?.sid,
      };
      this.siblings = [];
      this.availableNodes = [];
      this.getSiblings(sibPayload).then((siblings) => {
        this.siblings = siblings;
        const availableNodes = [];
        siblings.forEach((item) => {
          availableNodes.push(parseInt(item.asset.prefs.node, 10));
        });
        this.availableNodes = availableNodes.slice().sort((a, b) => a - b);
      });
      this.updateViewCount();
      EventBus.$emit('SET_SIMILAR_SLIDE_SELECTED', true);
      trackSlideEvents.slidesSimilar(product.asset, this.currentUser, {
        [TD_SIMILARSLIDETYPE]: this.matomoTypeMap[this.type],
      });
    },

    getUpVoteColor() {
      if (this.upVotedBefore()) return 'primary';
      return '#68788c';
    },

    getDownVoteColor() {
      if (this.downVotedBefore()) return 'deep-orange';
      return '#68788c';
    },

    upVotedBefore() {
      if (this.userAssetVotes.length <= 0) return false;
      const upvote = this.userAssetVotes.find(
        (vote) =>
          vote.userID === this.currentUser.user.id &&
          vote.assetID === this.result.name &&
          vote.voteType === 'upvote',
      );
      return !!upvote;
    },

    downVotedBefore() {
      if (this.userAssetVotes.length <= 0) return false;
      const downvote = this.userAssetVotes.find(
        (vote) =>
          vote.userID === this.currentUser.user.id &&
          vote.assetID === this.result.name &&
          vote.voteType === 'downvote',
      );
      return !!downvote;
    },

    setIntervalX(callback, delay, repetitions) {
      let x = 0;
      const intervalID = window.setInterval(() => {
        callback();

        if (++x === repetitions) {
          window.clearInterval(intervalID);
        }
      }, delay);
    },

    getSiblings(payload) {
      const getSlides = (resolve, reject) => {
        const related = [];
        related.push({
          unique_id: payload.unique_id,
          sid: payload.sid,
          keytype: 'K_SLIDEDATA',
        });
        const params = {
          username: this.currentUser.user.cognitoID,
          related,
        };
        const serialized = JSON.stringify(params);

        GraphQLService.requestWithToken(
          graphqlOperation(getRelatedSlides, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.getRelatedSlides);
            if (result.statusCode === 200) {
              const assets = [];
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                reject(assets);
              } else {
                for (const slide of slides) {
                  const obj = {};
                  obj.asset = slide;
                  obj.asset.name = obj.asset.title;
                  obj.asset.file = obj.asset.filename;
                  obj.asset.pptFile = obj.asset.file;
                  obj.isTrending = false;
                  obj.isFavorite = this.favoriteSlides.includes(
                    this.dialogContent.unique_id,
                  );
                  obj.rating = 4.2;

                  obj.landscape = await getFileURL(
                    this.currentUser.user.cognitoID,
                    obj.asset.landscape,
                    this.currentUser.userIp,
                  );
                  obj.poster = await getFileURL(
                    this.currentUser.user.cognitoID,
                    obj.asset.poster,
                    this.currentUser.userIp,
                  );
                  assets.push(obj);
                }

                resolve(assets);
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => reject(err));
      };
      return new Promise(getSlides);
    },

    async getRecommendedSlides(data) {
      const payload = {
        ...data,
      };
      console.log('requested payload', payload);
      const getSlides = async (resolve, reject) => {
        if (!payload.andprefs) {
          payload.andprefs = {};
        }
        payload.andprefs.theme = this.dialogContent?.prefs?.theme;
        const params = {
          username: payload.username,
          query: payload.query ? payload.query : '',
          category: payload.category,
          orderby: 'CHOICE_DESC',
          choice: payload.choice,
          morprefs: payload.morprefs ? payload.morprefs : [],
          prefs: payload.prefs ? payload.prefs : [],
          andprefs: payload.andprefs ? payload.andprefs : [],
          // notprefs: payload.notprefs ? payload.notprefs : [],
          // company: this.currentUser.companyName.toLowerCase(),
          limit: payload.limit,
          skip: payload.skip >= 0 ? payload.skip : 0,
        };
        const serialized = JSON.stringify(params);

        await GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
            sessionkey: this.sessionKey,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            if (result.statusCode === 200) {
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                resolve([]);
              } else {
                resolve(slides);
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => {
            reject(err);
          });
      };
      return new Promise(getSlides);
    },

    getChoice(fingerprint) {
      let choice = { visual: 6.0, data: 5.0 };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'performer':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'navigator':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'surgeon':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'architect':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'scholar':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'scientist':
          choice = { visual: 0.0, data: 5.0 };
          return choice;
        default:
          return choice;
      }
    },

    getAudienceDataToTrack() {
      try {
        return {
          email:
            this.audience?.id ||
            this.audience?.user?.id ||
            this.currentUser?.user?.id,
        };
      } catch (e) {
        return {
          email: '',
        };
      }
    },

    getHeadingWidths() {
      if (window.innerWidth <= 1020) {
        this.headingsWidth = 43;
        this.draggableLeft = 47;
      } else if (window.innerWidth > 1020 && window.innerWidth <= 1110) {
        this.headingsWidth = 46;
        this.draggableLeft = 44;
      } else if (window.innerWidth > 1110 && window.innerWidth <= 1200) {
        this.headingsWidth = 49;
        this.draggableLeft = 40;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.headingsWidth = 54;
        this.draggableLeft = 40;
      } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        this.headingsWidth = 57;
        this.draggableLeft = 37;
      } else if (window.innerWidth > 1400 && window.innerWidth <= 1440) {
        this.headingsWidth = 60;
        this.draggableLeft = 33;
      } else if (window.innerWidth > 1440 && window.innerWidth <= 1700) {
        this.headingsWidth = 63;
        this.draggableLeft = 30;
      } else if (window.innerWidth > 1700 && window.innerWidth <= 1900) {
        this.headingsWidth = 69;
        this.draggableLeft = 26;
      } else {
        this.headingsWidth = 72;
        this.draggableLeft = 26;
      }
      if (this.$refs.slideDetailImage) {
        this.slideThumbnailRef =
          this.$refs.slideDetailImage.getBoundingClientRect();
      }
    },

    movingInRightHalf(point) {
      const pointX = Number(point.x);
      const pointY = Number(point.y);
      if (pointX <= 50 && pointY <= 50) {
        return false;
      }
      if (pointX > 50 && pointY <= 50) {
        return true;
      }
      if (pointX <= 50 && pointY > 50) {
        return false;
      }
      return true;
    },
  },

  async beforeMount() {
    if (this.assetVotes.length > 0) {
      this.userAssetVotes = this.assetVotes.slice();
    }
    this.result = {
      ...this.dialogContent,
    };
    // this.mergedUrl = await mergeSlides([this.result.filename]).then(
    //   (r) => r.url,
    // );
    // mergeSlides([this.result.filename]);
    await this.getLandscape(this.result);
    this.setSlideViewDialogContentOnLoad(
      this.getSlideViewDialogContentOnLoad &&
        Object.keys(this.getSlideViewDialogContentOnLoad).length > 0
        ? { ...this.getSlideViewDialogContentOnLoad }
        : { ...this.dialogContent },
    );
    // this.currentProductId = this.dialogContent.asset.name;
  },

  async mounted() {
    if (window.ResizeObserver) {
      const mainSlideSizeObserver = new ResizeObserver((data) => {
        this.mainSlideHeight = data && data[0] && data[0].contentRect.height;
      });
      mainSlideSizeObserver.observe(
        document.getElementById(
          this.isImageReplaceWalkThrough
            ? 'walkthough-slide-id'
            : 'main-slide-id',
        ),
      );
      EventBus.$emit('SET_SIMILAR_SLIDE_SELECTED', false);
    }
    // await this.handleLoadRelatedSlides();
    if (
      this.slideType === 'prezentSourcedSlide' ||
      this.slideType === 'companyBrandedSlide'
    ) {
      try {
        await this.handleLoadNodes();
      } catch (err) {
        console.log(err);
      }
      this.selectedNode = this.result.prefs.node;
    }
    // await this.fetchCurrentUserFavorites(this.currentUser.id); // can the property related to this call be taken from store
    // this.updateViewCount();
    const { slideTitle, slideId } = utils.getSlideTitleAndID(this.result);
    MatomoAnalyticsHandler.slidesViewed(slideTitle, slideId, this.currentUser, {
      query: this.savedQueryPayload.query,
      audience: this.getAudienceDataToTrack(),
      from: this.from,
    });
    this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    this.getHeadingWidths();
    window.addEventListener('resize', this.getHeadingWidths);
  },
  destroyed() {
    window.removeEventListener('resize', this.getHeadingWidths);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.wrapper {
  align-items: flex-start;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  padding: 1.5rem;
  position: relative;
  width: 100%;
}

.disable-events {
  pointer-events: none;
}

.text-h6 {
  font-family: 'Lato' !important;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  ::v-deep .v-input--selection-controls {
    margin-bottom: 0px;
    margin-top: -2px;
  }
}
.share-slide-permission {
  padding: 0 !important;
  ::v-deep.v-input--radio-group__input {
    .v-radio {
      margin: 0 !important;
      padding: 10px 0px 8px 0px !important;
      .v-input--selection-controls__input {
        margin: 0px 6px 0px 0px !important;
      }
      .v-label {
        color: black !important;
      }
    }
  }
}
.similar-slides-radiobtns {
  ::v-deep .v-input__control {
    .v-messages {
      display: none !important;
    }
  }
  ::v-deep .v-label {
    color: black;
  }
}

.dialog-actions {
  // margin: 0 auto;
  max-height: 18%;
  padding-top: 20px;
  width: 100%;

  .footer-wrapper {
    align-items: center;
    display: flex;
    font-family: 'Lato' !important;
    justify-content: space-between;
    .slide-download {
      display: flex;
      justify-content: flex-end;
      min-width: 15%;
      .revertBtn,
      .disabledRevertBtn {
        box-shadow: none;
        background: #ffffff;
        letter-spacing: normal;
        text-transform: none;
        color: #21a7e0;
        font-size: 18px;
        border-radius: 20px;
        margin: 8px !important;
        height: 40px;
        margin: 0px 0px 0px 16px;
      }
      .revertBtn {
        border: 1px solid #21a7e0;
      }

      .cancelEdit,
      .acceptEdit {
        background-color: #ffffff;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        border-radius: 20px;
        margin: 0px 0px 0px 16px;
      }

      .cancelEdit {
        border: 1px solid #21a7e0;
        color: #21a7e0;
      }

      .acceptEdit {
        border: none;
        background-color: #21a7e0;
        color: #ffffff;
      }

      ::v-deep .download-actions {
        .new-slide-detail-speed {
          .v-btn {
            margin: 8px !important;
          }
        }
      }
    }
  }

  .action-items__container {
    align-items: center;
    display: flex;
    flex-direction: row;

    .add-fav-btn__wrapper {
      // min-width: 226px;
      ::v-deep .v-btn--plain .v-btn__content {
        opacity: 1;
      }
      .action-button,
      .action-coll-button {
        margin: 0px 16px 0px 0px;
        padding: 0 !important;
        .v-btn__content {
          .v-icon {
            font-size: 16px !important;
          }
          .action-button__text {
            font-size: 16px !important;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .actionContainer {
        .copyLinkBtn,
        .otherActionsBtn {
          margin: 0px 16px 0px 0px;
          box-shadow: none;
          background: #ffffff !important;
          letter-spacing: normal;
          text-transform: none;
          color: #21a7e0;
          padding: 0;
          font-size: 16px !important;
          .v-btn__content {
            font-size: 16px !important;
            .v-icon {
              font-size: 18px !important;
            }
          }
        }
        .copiedStatus {
          color: #21a7e0;
          font-size: 16px;
        }
      }
      .editSlideDetailsBtn {
        margin: 0px 16px 0px 0px;
        box-shadow: none;
        background: #ffffff;
        letter-spacing: normal;
        text-transform: none;
        color: #21a7e0;
        padding: 0;
        font-size: 16px !important;
        .v-btn__content {
          font-size: 16px !important;
          .v-icon {
            font-size: 18px !important;
          }
        }
      }
      .editSlideDetailsBtnDisabled {
        margin: 0px 16px 0px 0px;
        box-shadow: none;
        background: #ffffff;
        letter-spacing: normal;
        text-transform: none;
        color: #21a7e0;
        padding: 0;
        font-size: 16px !important;
        background-color: #ffffff !important;
        .v-btn__content {
          font-size: 16px !important;
          .v-icon {
            font-size: 18px !important;
          }
        }
      }
      .replaceAssets {
        margin: 0px 16px 0px 0px;
        box-shadow: none;
        background: #ffffff;
        letter-spacing: normal;
        text-transform: none;
        color: #21a7e0;
        padding: 0;
        font-size: 16px !important;
        .v-btn__content {
          font-size: 16px !important;
          .v-icon {
            font-size: 18px !important;
          }
        }
      }
      .replaceAssetsBtnDisabled {
        margin: 0px 16px 0px 0px;
        box-shadow: none;
        background: #ffffff;
        letter-spacing: normal;
        text-transform: none;
        color: rgba(0, 0, 0, 0.26) !important;
        pointer-events: none;
        padding: 0;
        font-size: 16px !important;
        background-color: #ffffff !important;
        .v-btn__content {
          font-size: 16px !important;
          .v-icon {
            font-size: 18px !important;
          }
        }
      }
    }

    button {
      .thumb-value {
        color: #68788c;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
        margin-left: 5px;
      }
    }
  }

  .download {
    display: flex;
    justify-content: flex-end;
  }
}

.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.main-wrapper {
  align-items: flex-start;
  display: inline-block;
  flex-direction: column;
  font-family: 'Lato';
  justify-content: flex-start;
  padding: 5px 3px 5px 3px;
  width: 100%;

  .header {
    font-family: 'Lato' !important;
    display: flex;
    overflow-wrap: anywhere;
    .nameOrIdeaName {
    }
    .prezentLogo {
      img {
        margin: 0px 0px 2px 4px;
      }
    }
    .source,
    .author {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .source {
      max-width: 17%;
    }
    .author {
      max-width: 23%;
    }
    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .unavailableDesc {
        color: grey;
      }
    }
    .bestPracticesSwitch {
      margin: 0 0 0 auto;
    }
  }
  .image-wrapper {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    overflow: hidden;
    position: relative;
    // max-height: 32rem;
    aspect-ratio: 16/9;

    .v-responsive {
      object-fit: contain;
    }

    img {
      height: 100%;
      width: 100%;
    }

    .already-download {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
  .image-wrapper:hover {
    // box-shadow: $item-box-shadow-hover;
  }
}

.related-products {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;

  .relatedSlidesLoaderContainer {
    text-align: center;
    margin: 100px 0px 0px 0px;
  }

  .related-products-header {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 0.5rem;
  }
  .related-products-thumbnails {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.3rem;
    padding-top: 0px;
    .noRelatedSlidesIllustration {
      width: 90%;
      height: 90%;
      margin: 0 auto;
      .imgContainer {
        padding: 48px 48px 16px 48px;
        display: flex;
        justify-content: center;
        img {
          width: 266px;
        }
      }
      .relatedSlidesError {
        text-align: center;
        font-size: 16px;
      }
    }
  }

  .product {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    cursor: pointer;
    margin-bottom: 18px;
    padding: 5px;
    transition: all ease 0.5s;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .product:hover {
    box-shadow: $item-box-shadow-hover;
  }
}
.node {
  display: flex;
  font-size: 16px;
  .node-text {
    padding: 2px 10px 0px 0px;
  }
  .node-list-item {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    line-height: 27.5px;
    margin-left: 6px;
    text-align: center;
    width: 30px;

    &:hover {
      background-color: $light-gray;
      border-radius: 20px;
    }
  }
  .outlined {
    background: $zen-blue-default;
    border-radius: 20px;
    color: white;
  }
  .disabled {
    color: #b6b6b6;
    pointer-events: none;
  }
}

.disabled-action-button {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .action-button {
    color: rgba(0, 0, 0, 0.26) !important;
    &::before {
      background-color: unset;
    }
    &:hover {
      background: unset;
      cursor: auto;
    }
  }

  button {
    margin-right: -14px;
  }

  .icon-title {
    color: rgba(0, 0, 0, 0.26);
  }
}

.action-button {
  cursor: pointer;

  &:hover {
    background: $action-button-bg;
  }
  .action-button__text {
    font-size: 18px;
    letter-spacing: normal;
    text-transform: none;
  }
  .action-button-disable__text {
    font-size: 18px;
    color: #bdbdbd;
    letter-spacing: normal;
    text-transform: none;
  }
}
.action-coll-button {
  cursor: pointer;
  text-transform: none;

  &:hover {
    background: #fff;
  }
  .action-button__text {
    font-size: 18px;
    letter-spacing: normal;
    text-transform: none;
  }
  .action-button-disable__text {
    font-size: 18px;
    color: #bdbdbd;
    letter-spacing: normal;
    text-transform: none;
  }
}

::v-deep .action-button {
  caret-color: #21a7e0 !important;
  color: #21a7e0 !important;
}

.disabled-action-button {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  button {
    margin-right: -14px;
  }

  .icon-title {
    color: rgba(0, 0, 0, 0.26);
  }
}

.isModal {
  transition: height 0.66s ease-out;

  .content-wrapper {
    overflow: auto;
  }

  .main-wrapper {
    padding: 0;

    .text-subtitle-1 {
      font-size: 18px;
    }

    .text-left {
      margin-bottom: 5px;
    }

    .image-wrapper {
      max-height: 100%;
    }
  }

  .related-products {
    height: 512px;
  }

  .dialog-actions {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .text-h6 {
    font-family: 'Lato' !important;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 35px;
  }
  .modal-header {
    margin-left: 6px;
  }
}

.v-menu__content {
  .v-list {
    padding: 0 !important;
    .replaceAssetsList {
      padding: 0px !important;
      .replaceAssetsTitle,
      .replaceAssetsTitleDisabled {
        display: flex;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        // width: 100%;
        min-height: 48px;
        .v-icon,
        img {
          margin: 0px 4px 0px 0px;
        }
        .assetLabel {
          margin: 2px 0px 0px 0px;
        }
        &:before {
          background-color: #d8d8d8 !important;
        }
        &:after {
          background-color: #d8d8d8 !important;
        }
      }
      .replaceAssetsTitle {
        background-color: transparent !important;
      }
      .replaceAssetsTitleDisabled {
        background-color: transparent !important;
      }
      &:hover {
        cursor: pointer;
        background-color: #d8d8d8 !important;
      }
    }
    .v-list-item {
      padding: 0px 15px;
      .actionName {
        padding: 0px 0px 0px 8px;
      }
      .copyOption {
        display: flex;
      }
      &:hover {
        cursor: pointer;
        background-color: #d8d8d8 !important;
      }
    }
  }
}
</style>
