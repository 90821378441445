var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-selector"},[_c('p',{staticClass:"info-text"},[_vm._v(_vm._s('Select template for your slides'))]),_c('v-text-field',{staticClass:"template-search-input",attrs:{"solo":"","rounded":"","single-line":"","hide-details":"","autocomplete":"off","placeholder":_vm.$t('profile.searchTemplates')},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.sortedThemeArray.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.templateSearch.length)?_c('v-icon',{staticClass:"icon",attrs:{"name":"global-cross-icon","color":"#21a7e0"},domProps:{"textContent":_vm._s('mdi-close')},on:{"click":_vm.clearSearch}}):_vm._e(),_c('v-icon',{staticClass:"icon",attrs:{"name":"global-search-icon","color":_vm.templateSearch.length ? '#21a7e0' : ''},domProps:{"textContent":_vm._s("mdi-magnify")}})]},proxy:true}]),model:{value:(_vm.templateSearch),callback:function ($$v) {_vm.templateSearch=$$v},expression:"templateSearch"}}),[(_vm.sortedThemeArray().length)?_c('div',{staticClass:"templates-list"},_vm._l((_vm.sortedThemeArray()),function(item){return _c('v-card',{key:item.id,staticClass:"template",class:{ 'template-disabled': item.disabled },style:({
          'background-color': _vm.isCurrentSelected(item.code) ? '#F5FCFF' : '',
        }),attrs:{"ripple":!item.disabled,"name--auto":("slides-advanced-filter-template-item" + (_vm.isCurrentSelected(item.code) ? '--active' : ''))},on:{"click":function($event){return _vm.handleClickTheme(item)}}},[_c('v-tooltip',{attrs:{"top":"","attach":"","absolute":"","max-width":"200","disabled":!item.disabled,"content-class":'center-tooltip'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"template-header"},[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"template-title"},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]),(_vm.isCurrentSelected(item.code))?_c('div',{staticClass:"current-selection"},[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t('tagSlides.currentSelection'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tagSlides.currentSelection')))])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"available-theme-img"},[_c('img',{attrs:{"src":item.thumbnailURL,"alt":""}})]),_c('v-col',{staticClass:"example-slide-img"},[_c('img',{attrs:{"src":item.sampleSlide
                        ? item.sampleSlideURL
                        : item.thumbnailURL,"alt":""}})])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.comingSoon')))])])],1)}),1):_c('div',{staticClass:"not-found-container"},[_c('img',{attrs:{"src":"/assets/img/no-results-found-templates.svg","height":"250","width":"250","alt":""}}),_c('div',{staticClass:"heading mb-1 mt-6"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.noTemplatesFound'))+" ")]),_c('v-btn',{staticClass:"primary-button",attrs:{"center":"","height":"35","width":"160","rounded":"","filled":"","color":"primary"},on:{"click":_vm.handleAddMore}},[_vm._v(" "+_vm._s(_vm.$t('build.step3.addTemplates'))+" ")])],1),_c('div',{staticClass:"tip-bar",class:_vm.sortedThemeArray().length ? '' : 'no-results'},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('tagSlides.templateProTipText'))+" ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }