<template>
  <select
    id="country"
    name="country"
    class="countryDropdown"
    v-model="country"
    :disabled="isDisabled"
    @change="(e) => handleCountryChange(e)"
    placeholder="Select country"
  >
    <option
      v-for="(cont, ind) in [...allCountries].sort((a, b) =>
        a.name.localeCompare(b.name),
      )"
      :key="ind"
      :value="cont.iso2"
    >
      {{ cont.name }}
    </option>
  </select>
</template>

<script>
import { getCountries } from '../../API/common-api';

export default {
  name: 'Country',
  props: {
    countrySelected: {
      type: String,
      default: 'US',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    countryChanged: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      country: this.countrySelected,
      allCountries: [
        {
          name: 'United States',
          iso2: 'US',
        },
      ],
    };
  },
  created() {
    this.fetchCountries();
  },
  methods: {
    fetchCountries() {
      getCountries()
        .then((resp) => {
          if (resp.data?.length) {
            this.allCountries = resp.data;
          }
        })
        .catch((err) => {
          console.log('failed to load countries', err);
        });
    },
    handleCountryChange(e) {
      const countryItem = this.allCountries.find(
        (country) => country.iso2 === e.target.value,
      );
      this.countryChanged(e.target.value, countryItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.countryDropdown {
  font-size: 14px !important;
}
</style>
