<template>
  <div class="fpModalContainer">
    <div class="closeIconContainer">
      <v-icon @click="handleModalClose()"> mdi-close </v-icon>
    </div>
    <div class="headingContainer">
      <h3 class="heading">
        {{ 'Update your fingerprint!' }}
      </h3>
    </div>
    <div class="fpContentContainer">
      <div>
        <div class="fpImageWrapper">
          <img src="/assets/img/welcome-fingerprint.svg" alt />
        </div>
        <div class="subHeading">
          {{
            `Our new fingerprint test will help you find and collaborate with
          your colleagues more easily. Take it today!`
          }}
        </div>
      </div>
    </div>
    <div class="retake-fp">
      <v-btn
        class="primary-button mt-2 py-2 button-label"
        rounded
        color="#21a7e0"
        height="48"
        min-width="134"
        @click="gotoTest"
      >
        Re-take fingerprint test
      </v-btn>
    </div>
    <div class="actionButtonsContainer">
      <v-btn @click="handleModalClose()" class="notNow">
        {{ 'Not Now' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import usersApi from '../../API/users-api';
import { trackGeneralEvents } from './Analytics/GeneralEvents';
import {
  shareFingerPrintVsPlacement,
  TD_PLACEMENT,
  TD_SHARE_FINGERPRINT_NAVBAR,
} from './Analytics/MatomoTrackingDataHelper';

export default {
  name: 'FingerprintReminderModal',
  data() {
    return {};
  },
  methods: {
    gotoTest() {
      trackGeneralEvents.reminderRetakeFingerPrint(this.currentUser, {
        [TD_PLACEMENT]:
          shareFingerPrintVsPlacement[TD_SHARE_FINGERPRINT_NAVBAR],
      });
      this.$router.push('/welcome?initialIndex=1&remider=true');
      this.$modal.hide('FingerprintReminderModal');
    },
    handleModalClose() {
      trackGeneralEvents.reminderRetakeFingerPrintNotNow(this.currentUser, {
        [TD_PLACEMENT]:
          shareFingerPrintVsPlacement[TD_SHARE_FINGERPRINT_NAVBAR],
      });
      this.$modal.hide('FingerprintReminderModal');
      const userdata = {
        fpSkipDate: moment(new Date()),
      };
      usersApi.methods
        .updateUserProfile(userdata)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(this.currentUser);
        })
        .catch((err) => console.log('error while updating skip tour', err));
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
};
</script>

<style lang="scss" scoped>
.fpModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px;
  font-family: 'Lato', sans-serif;
  width: 98%;
  margin: 0 auto;
  .closeIconContainer {
    text-align: right;
    height: 1.5rem;
  }
  .headingContainer {
    .heading {
      width: 60%;
      margin: 16px auto;
      font-size: 22px;
      text-align: center;
    }
  }
  .fpContentContainer {
    .fpImageWrapper {
      width: 60%;
      margin: 0 auto;
      padding: 16px 0px 16px 0px;
      height: 200px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .subHeading {
      width: 88%;
      text-align: center;
      margin: 12px auto 24px auto;
      font-size: 17px;
    }
  }
  .actionButtonsContainer {
    padding-top: 20px;
    .notNow {
      width: 15%;
      margin: 0 auto !important;
      display: block;
      text-transform: none;
      border-radius: 16px;
      letter-spacing: 0.1px;
      background-color: #fff;
      color: #21a7e0;
      border: none;
      box-shadow: none;
      font-size: 15px;
    }
  }
  .retake-fp {
    margin: 0 auto;
    .button-label {
      color: white;
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      height: 40px !important;
      line-height: normal;
      letter-spacing: normal;
      min-width: 64px;
      text-transform: none !important;
    }
  }
}
</style>
