<template>
  <v-dialog v-model="show" max-width="550" @click:outside="onClose">
    <v-card class="confirm-popup">
      <v-card-title class="v-card-title" v-if="title">
        <v-btn class="close-btn" icon @click="onClose">
          <v-icon dark v-text="`mdi-close`" />
        </v-btn>
        <h3 class="title">{{ $t(translatedTitle) }}</h3>
      </v-card-title>
      <v-card-text class="pt-4 v-card-text">
        <div :class="title === '' ? 'text' : 'texxt'">
          {{ text }}
          <div class="text2">{{ text2 }}</div>
        </div>
        <div
          class="d-flex justify-end"
          v-if="primaryBtnText || secondaryBtnText"
        >
          <v-btn
            class="primary-btn ma-2 py-2"
            outlined
            rounded
            color="primary"
            @click="onPrimaryClick"
            :disabled="isConfirming"
            v-if="primaryBtnText"
          >
            {{ $t(primaryBtnText) }}
          </v-btn>
          <v-btn
            style="color: white !important"
            class="secondary-btn ma-2 py-2"
            rounded
            color="primary"
            @click="onSecondaryClick"
            :loading="isConfirming"
            v-if="secondaryBtnText"
            :data-pendo-id="dataPendoId"
          >
            {{ $t(secondaryBtnText) }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    text: {
      type: String,
      required: false,
      default: () => this.$t('confirmationDialog.title'),
    },
    text2: {
      type: String,
      required: false,
      default: '',
    },
    primaryBtnText: {
      type: String,
      required: false,
      default: () => 'common.no',
    },
    secondaryBtnText: {
      type: String,
      required: false,
      default: () => 'common.yes',
    },
    onPrimaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('primary clicked');
      },
    },
    onSecondaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('secondary clicked');
      },
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {
        console.log('closinf popup');
      },
    },
    isConfirming: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dataPendoId: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickOutside() {
      this.onSecondaryClick();
    },
  },
  computed: {
    translatedTitle() {
      if (this.title === 'Clear Set') {
        return 'slides.clearset';
      }
      if (this.title === 'Clear Collection') {
        return 'slides.clearCollection';
      }
      return this.title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

::v-deep .v-dialog {
  border-radius: 20px !important;
}

.confirm-popup {
  padding: 8px 0 4px 0;

  .v-card-title {
    padding-bottom: 0px !important;
  }

  .v-card-text {
    padding-top: 0px !important;
  }
}
.primary-btn {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 40px !important;
  line-height: normal;
  letter-spacing: normal;
  min-width: 64px;
  text-transform: none !important;
}

.secondary-btn {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 40px !important;
  line-height: normal;
  letter-spacing: normal;
  min-width: 64px;
  text-transform: none !important;
}
.title {
  max-width: 480px;
  word-break: break-word;
  line-height: 1.5rem;
  font-family: 'Lato' !important;
  font-weight: bold;
}

.text {
  color: #000;
  font-family: 'Lato' !important;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal !important;
  line-height: normal;
  padding-bottom: 28px !important;
}
.texxt {
  color: #000;
  font-family: 'Lato' !important;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal !important;
  line-height: normal;
  margin-top: 10px;
  padding-bottom: 20px !important;
}

.text2 {
  margin-top: 10px;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
</style>
