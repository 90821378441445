<template>
  <div>
    <!--
    <div v-if="isTrial" style="margin-bottom: 0px" class="trial-banner">
      <div class="banner-content">
        {{ getTrialMessage }}
        <a @click="onUpgradeClick">
          <span style="color: primary">{{ $t('profile.upgrade') }}</span>
        </a>
        {{ $t('profile.today') }}
      </div>
    </div>
    !-->

    <div style="margin-bottom: 0px" class="trial-banner">
      <div
        v-if="isTrial || currentUser.user.role === 'Admin'"
        class="banner-content"
      >
        {{ getTrialMessage }}
        <button @click="onRenewClick" class="primary--text">
          {{ $t('profile.clickHere') }}
        </button>
        {{
          currentUser.user.role === 'User'
            ? $t('profile.notifyYourAdmin')
            : $t('profile.torenew')
        }}
      </div>
      <div v-if="currentUser.user.role === 'User'" class="banner-content">
        {{ getTrialMessage }}
        {{ $t('profile.reachOutAdminToRenew') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { sendEmail } from '../../../utils/api-helper';
import ContactSoonModal from '../ContactSoonModal.vue';

export default {
  name: 'SubscriptionExpiredBanner',
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentActiveSubscription']),
    ...mapGetters('users', ['isKnowEnabled']),
    isTrial() {
      return this.currentUser.user.role === 'TrialUser';
    },
    daysleft() {
      if (
        this.currentActiveSubscription !== null &&
        this.currentUser !== null
      ) {
        const endDate = Date.parse(this.currentActiveSubscription.endDate);
        const dayslft = Math.floor(
          (endDate - new Date()) / (1000 * 60 * 60 * 24),
        );
        return dayslft;
      }
      return 0;
    },
    getTrialMessage() {
      if (
        this.currentActiveSubscription !== null &&
        this.currentUser !== null &&
        this.currentActiveSubscription.teamID === this.currentUser.user.teamID
      ) {
        // const daysleft = this.currentActiveSubscription.daysLeft;
        // const type = this.isTrial
        //   ? this.$t('search.trial')
        //   : this.$t('search.subscription');
        const type = this.$t('search.subscription');
        let bannerMessage = '';
        if (this.daysleft === 0) {
          bannerMessage = this.$t('search.trialExpiresin', {
            subscriptionType: type,
          });
        } else if (this.daysleft < 0) {
          bannerMessage = this.$t('search.trialExpired', {
            subscriptionType: type,
          });
        } else if (this.daysleft === 1) {
          bannerMessage = this.$t('search.trialExpiresInOneDay', {
            subscriptionType: type,
            count: this.daysleft,
          });
        } else {
          bannerMessage = this.$t('search.trialExpiresInDays', {
            subscriptionType: type,
            count: this.daysleft,
          });
        }
        // if (this.daysleft >= 0 && this.isTrial) {
        //   const downloads = this.currentUser.user.downloadUsed;
        //   const teamDownloadLimit = this.currentUser?.user?.downloadLimit;
        //   const remainingDownloads = teamDownloadLimit - downloads;
        //   const knowDownloads = this.currentUser?.user?.knowDownloads;
        //   const remainingKnowDownloads = 5 - knowDownloads;
        //   if (
        //     remainingDownloads <= 0 &&
        //     (remainingKnowDownloads <= 0 || !this.isKnowEnabled)
        //   ) {
        //     bannerMessage += this.$t('search.downloadsExceeded');
        //   } else if (this.isKnowEnabled) {
        //     bannerMessage += this.$t('search.downloadsKnowRemaining', {
        //       downloads: remainingDownloads > 0 ? remainingDownloads : 0,
        //       knowDownloads: remainingKnowDownloads,
        //     });
        //   } else {
        //     bannerMessage += this.$t('search.downloadsRemaining', {
        //       count: remainingDownloads > 0 ? remainingDownloads : 0,
        //       plural:
        //         remainingDownloads > 1
        //           ? this.$t('search.downloads')
        //           : this.$t('search.download'),
        //     });
        //   }
        // }

        return bannerMessage;
      }
      return '';
    },
  },
  methods: {
    onUpgradeClick() {
      this.$router.push('/profile?tab=admin', () => {});
    },
    async onRenewClick() {
      if (this.currentUser.user.role === 'Admin') {
        // this.$router.push('/profile?tab=admin', () => {});
        this.$router.push('/profile?tab=admin#subscribe', () => {});
      } else {
        const successParams = {
          from: [],
          type: 'renewal-success-request',
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            email: this.currentUser.user.id,
          },
        };
        const adminParams = {
          from: [],
          type: 'renewal-admin-request',
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            email: this.currentUser.user.id,
            teamID: this.currentUser.user.teamID,
          },
        };
        this.$modal.show(
          ContactSoonModal,
          {},
          {
            name: 'ContactSoonModal',
            width: '449px',
            height: '503px',
          },
        );
        await Promise.all([sendEmail(successParams), sendEmail(adminParams)]);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.trial-banner {
  height: 48px;
  background: #e1f5fb;
  z-index: 10;
  .banner-content {
    font-size: 16px;
    font-weight: 500;
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin: 0px 8px 0px 8px;
    }
  }
}
</style>
