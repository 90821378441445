import Vue from 'vue';
import { Analytics as AmplifyAnalytics } from 'aws-amplify';
import { MatomoAnalyticsHandler } from './MatomoAnalyticsHandler';
// import { assetsByKeyword } from '../../../graphql/queries';
export const AnalyticsHandler = {
  async identify(user) {
    if (!user) {
      return Promise.resolve();
    }

    let promise;

    try {
      const cognitoUser =
        await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();

      const endpoint = {
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        teamName: user.teamName,
        companyName: user.companyName,
        fingerprint: user.fingerPrint,
        email: user.email,
        role: user.role,
        optOut: 'NONE',
        userAttributes: {
          Username: [user.username],
          ProfileEmail: [user.email],
        },
        attributes: {},
      };

      if (user.sign_up_date) {
        endpoint.attributes.SignUpDate = [user.sign_up_date];
      }

      if (user.last_sign_in_date) {
        endpoint.attributes.LastSignInDate = [user.last_sign_in_date];
      }

      if (user.addresses && user.addresses.length > 0) {
        const address = user.addresses[0];
        endpoint.location = {
          City: address.city,
          Country: address.country,
          PostalCode: address.zipcode,
          Region: address.state,
        };
      }

      if (cognitoUser.attributes.email) {
        endpoint.address = cognitoUser.attributes.email;
        endpoint.channelType = 'EMAIL';
        promise = AmplifyAnalytics.updateEndpoint(endpoint);
      } else {
        promise = Promise.resolve();
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      promise = Promise.reject(error);
    }

    return promise;
  },

  userSignedUp(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'UserSignedUp',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            signUpDate: user.createdAt,
            teamID: user.teamID,
            companyID: user.companyID,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  userSignedIn(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'UserSignedIn',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            signInDate: user.updatedAt,
            teamID: user.teamID,
            companyID: user.companyID,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  userCompletedFingerprint(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'UserCompletedFingerprint',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  userUpdatedPersonalPreference(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'UserUpdatedPersonalPreference',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  createdNewPrezentation(user, audience, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'CreatedNewPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceId: audience.id,
            audienceFingerprint: audience.fingerPrint,
            prezentationId: prezentation.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  addedCommentsToPrezentation(user, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'AddedCommentsToPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId: prezentation.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  chipSelected(user, audience, chip) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'ChipSelected',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceId: audience.id,
            audienceFingerprint: audience.fingerPrint,
            chip,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  userDisabled(user, targetUser) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'userDisabled',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            targetUserEmail: targetUser.email,
            targetUserFirstName: targetUser.firstName,
            targetUserLastName: targetUser.lastName,
            targetUserTeamName: targetUser.teamName,
            targetUserCompanyName: targetUser.companyName,
            targetUserFingerprint: targetUser.fingerPrint,
            targetUserRole: targetUser.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  SkippedFingerprintTest(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'SkippedFingerprintTest',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  loadedHome(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedHome',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  loadedProfile(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedProfile',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  loadedSlides(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedSlides',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  loadedMyPrezentations(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedMyPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  newPrezentationSelectedAudience(user, audienceName, audienceFingerprint) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedNewPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceName,
            audienceFingerprint,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  loadedNewPrezentations(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedNewPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  loadedShareFingerprint(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedShareFingerprint',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  loadedLearn(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'LoadedLearn',
          attributes: {
            userId: user.id,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  OpenedNotifications(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'OpenedNotifications',
          attributes: {
            userId: user.id,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  swappedSlide(user, fromSlideId, toSlideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'SwappedSlide',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            fromSlideId,
            toSlideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  addedWish(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'AddedWish',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  addedFeatureRequest(user, request) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'addedFeatureRequest',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            request,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  votedSlideThumpup(user, slideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'VotedSlideThumpup',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  votedSlideThumpdown(user, slideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'VotedSlideThumpdown',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  OpenedComments(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'OpenedComments',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  slidesSorted(user, audience, sortType) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'SlidesSorted',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceId: audience.id,
            audienceFingerprint: audience.fingerPrint,
            sortType,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  clickedAdvancedFilter(user, audience, filter) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'ClickedAdvancedFilter',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceId: audience.id,
            audienceFingerprint: audience.fingerPrint,
            filter,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  slideAddedToPrezentation(user, audience, slide, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'SlideAddedToPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceId: audience.id,
            audienceFingerprint: audience.fingerPrint,
            prezentationId: prezentation.id,
            slideId: slide.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });

        AmplifyAnalytics.updateEndpoint({
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          teamName: user.teamName,
          companyName: user.companyName,
          fingerprint: user.fingerPrint,
          email: user.email,
          role: user.role,
          attributesHasNewPrezentation: {
            HasNew: ['true'],
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  SlideRemovedFromPrezentation(user, audience, slide, prezentation) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'SlideRemovedFromPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId: slide.id,
            audienceId: audience.id,
            audienceFingerprint: audience.fingerPrint,
            prezentationId: prezentation.id,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  prezentationOutlineUpdated(user, prezentation, outline, action) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'PrezentationOutlineUpdated',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezenttaionId: prezentation.id,
            prezentationType: prezentation.type,
            outline,
            action,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  prezentationOutlineSaved(user, prezentation, outline, name) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'rezentationOutlineSaved',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezenttaionId: prezentation.id,
            prezentationType: prezentation.type,
            outline,
            name,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performIdeaSearch(user, prezentation, outline, query) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'performIdeaSearch',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezenttaionId: prezentation.id,
            outline,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  buyAdditionalLicense(user, license, seats) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'performIdeaSearch',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            license,
            seats,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performTrialToProfessionalUpgrade(user, license, seats) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'performTrialToProfessionalUpgrade',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            license,
            seats,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  requestAdditionalTemplates(user) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'requestAdditionalTemplates',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  requestEnterpriseUpgrade(user) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'requestEnterpriseUpgrade',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  slideViewedMyPrezentation(user, slideId, fingerprint) {
    try {
      console.log(fingerprint);
      if (user) {
        AmplifyAnalytics.record({
          name: 'SlideViewedMyPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  slideViewedNewPrezentation(user, slideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'SlideViewedNewPrezentation',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  myPrezentationViewed(user, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'MyPrezentationViewed',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId: prezentation.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  myPrezentationReviewed(user, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'MyPrezentationReviewed',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId: prezentation.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  prezentationDownloadStarted(user, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'PrezentationDownloadStarted',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId: prezentation.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  prezentationDownloadComplete(user, prezentation) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'PrezentationDownloadComplete',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId: prezentation.id,
            prezentationName: prezentation.name,
            prezentationType: prezentation.type,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  newPrezentationSlideSwapped(user, outline, fromSlideId, toSlideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'newPrezentationSlideSwapped',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            outline,
            fromSlideId,
            toSlideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  slideDownloadStarted(user, asset) {
    try {
      if (user && asset) {
        AmplifyAnalytics.record({
          name: 'SlideDownloadStarted',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId: asset.unique_id,
            construct: asset.construct ? asset.construct.toString() : undefined,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  logUserStats(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'logUserStats',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            totalSlideDownloads: user.downloadUsed ? user.downloadUsed : 0,
            totalPresentationDownloads: user.countPrezentationDownloads
              ? user.countPrezentationDownloads
              : 0,
            totalSlideSearches: user.countSearches ? user.countSearches : 0,
            totalSlideViews: user.countSlideView ? user.countSlideView : 0,
            efficiencyRating: user.efficiencyRating ? user.efficiencyRating : 0,
            effectiveRating: user.effectiveRating ? user.effectiveRating : 0,
            engagement: this.getUserEngagement(user),
            status: user.active ? 'Active' : 'Disabled',
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  getUserEngagement(user) {
    let engagement = 'Engaged';

    if (
      user.downloadUsed > 1 ||
      (this.userLearnIndex && this.userLearnIndex.badgesEarned > 0)
    ) {
      engagement = 'Highly Engaged';
    } else if (
      !user.seenOrSkippedWelcome ||
      (user.downloadUsed <= 0 &&
        user.countSlideView <= 0 &&
        user.countSearches <= 0 &&
        (!this.userLearnIndex || !this.userLearnIndex.badgesEarned))
    ) {
      engagement = 'Not started';
    }
    return engagement;
  },

  slideDownloadComplete(user, asset) {
    try {
      if (user && asset) {
        AmplifyAnalytics.record({
          name: 'SlideDownloadComplete',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId: asset.unique_id,
            construct: asset.construct ? asset.construct.toString() : undefined,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  tookTour(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'TookTour',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  skippedTour(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'skippedTour',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  watchedVideo(user, title) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'watchedVideo',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            title,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  tookPractice(user, title) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'tookPractice',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            title,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  tookQuiz(user, title) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'tookQuiz',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            title,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performAutoComplete(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performedAutoComplete',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideSearch(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performedSlideSearch',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  selectedSlideFromAutoComplete(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'selectedSlideFromAutoComplete',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  selectedPeopleFromAutoComplete(user, name) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'selectedPeopleFromAutoComplete',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            name,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  selectedLearnFromAutoComplete(user, name) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'selectedLearnFromAutoComplete',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            name,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  selectedSlideChip(user, name) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'selectedSlideChip',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            chipName: name,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performHybridSearch(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performedHybridSearch',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performOutlineSlideSearch(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performOutlineSlideSearch',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideAdvancedFilter(user, filter) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideAdvancedFilter',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            filter,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideSourceSelection(user, source) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideSourceSelection',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            sourcce: source,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideNodesSelection(user, nodes) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideNodesSelection',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            nodes,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  performUserSearch(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performUserSearch',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performTemplateSelection(user, query) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performTemplateSelection',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideDownloadLocal(user, slideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideDownloadLocal',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideUniqueId: slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideDownloadMS365(user, slideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideDownloadMS365',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideUniqueId: slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performSlideDownloadGdrive(user, slideId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideDownloadGdrive',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideUniqueId: slideId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  performPrezentationDownloadGdrive(user, prezentationId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performPrezentationDownloadGdrive',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performPrezentationDownloadMS365(user, prezentationId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performPrezentationDownloadMS365',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performPrezentationDownloadLocal(user, prezentationId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performPrezentationDownloadLocal',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            prezentationId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  performAddAudience(user, audienceEmail, audienceFirstName, audienceLastName) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performAddAudience',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceEmail,
            audienceFirstName,
            audienceLastName,
          },
        });
      }
      MatomoAnalyticsHandler.audienceAdded(
        user,
        audienceFirstName,
        audienceLastName,
        audienceEmail,
      );
    } catch (err) {
      console.log(err);
    }
  },
  performAddPrivateAudience(
    user,
    paudienceEmail,
    paudiencFirstName,
    paudiencLastName,
  ) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'addPrivateIndividualAudience',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceEmail: paudienceEmail,
            audienceFirstName: paudiencFirstName,
            audienceLastName: paudiencLastName,
          },
        });
        MatomoAnalyticsHandler.audienceAdded(
          user,
          paudiencFirstName,
          paudiencLastName,
          paudienceEmail,
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  fingerprintEmailedLink(
    user,
    audienceEmail,
    audienceFirstName,
    audienceLastName,
  ) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'fingerprintEmailedLink',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceEmail,
            audienceFirstName,
            audienceLastName,
          },
        });
        MatomoAnalyticsHandler.audienceSharedEmail(
          user,
          audienceEmail,
          audienceFirstName,
          audienceLastName,
        );
      }
    } catch (err) {
      console.log(err);
    }
  },

  fingerprintCopiedLink(
    user,
    audienceEmail,
    audienceFirstName,
    audienceLastName,
  ) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'fingerprintCopiedLink',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            audienceEmail,
            audienceFirstName,
            audienceLastName,
          },
        });
        MatomoAnalyticsHandler.audienceSharedLinkCopied(
          user,
          audienceEmail,
          audienceFirstName,
          audienceLastName,
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  addAudienceAttempted(user, name) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'addAudienceAttempted',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            name,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  audienceCompletedFingerprint(audience) {
    try {
      if (audience) {
        AmplifyAnalytics.record({
          name: 'audienceCompletedFingerprint',
          attributes: {
            audienceEmail: audience.email,
            audienceFirstName: audience.firstName,
            audienceLastName: audience.lastName,
          },
        });
        MatomoAnalyticsHandler.audienceTookTest(
          audience.firstName,
          audience.lastName,
          audience.email,
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  addPrivateGroupAudience(user, name) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'addPrivateGroupAudience',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            name,
          },
        });
        MatomoAnalyticsHandler.audienceAddedGroup(name, user);
      }
    } catch (err) {
      console.log(err);
    }
  },

  ShareFreeTrial(user, mode, invited) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'ShareFreeTrial',
          attributes: {
            mode,
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            invitedEmail: invited.email,
            invitedFirstName: invited.firstName,
            invitedLastName: invited.lastName,
          },
        });
        MatomoAnalyticsHandler.shareFreeTrail(user, mode, invited);
      }
    } catch (err) {
      console.log(err);
    }
  },
  AdminAddedNewUser(user, email, firstName, lastName) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'AdminAddedNewUser',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            newUserEmail: email,
            newUserFirstName: firstName,
            newUserLastName: lastName,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  freeTrialInvitationAccepted(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'freeTrialInvitationAccepted',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  performSlideNodeSelection(user, slideId, nodeNumber) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'performSlideNodeSelection',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            slideId,
            nodeNumber,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  addedProfilePicture(user, fileName) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'addedProfilePicture',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            fileName,
          },
        });
        MatomoAnalyticsHandler.photoUploaded(
          user.firstName,
          user.lastName,
          user.email,
        );
      }
    } catch (err) {
      console.log(err);
    }
  },
  earnedLearningBadge(user, totalBadges) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'earnedLearningBadge',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            totalBadges,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  addedSupportRequest(user, request) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'addedSupportRequest',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            request,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  viewedTutorialVideo(user, name) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'viewedTutorialVideo',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            name,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  viewedMetrics(user) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'viewedMetrics',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  markedSlideFavorite(user, uniqueId) {
    try {
      if (user) {
        AmplifyAnalytics.record({
          name: 'markedSlideFavorite',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            uniqueId,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  slideSearched(user, query) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'SlideSearched',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
            reranked: user ? 'true' : 'false',
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  fingerprintSearched(user, query) {
    try {
      if (user && user.id) {
        AmplifyAnalytics.record({
          name: 'FingerprintSearched',
          attributes: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            teamName: user.teamName,
            companyName: user.companyName,
            fingerprint: user.fingerPrint,
            email: user.email,
            role: user.role,
            query,
            reranked: user ? 'true' : 'false',
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
};
