<template>
  <div class="day-zero-popup-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="day-zero-popup-wrapper-content">
      <template v-if="showIntro">
        <div class="dz-intro">
          <div class="dz-intro__heading">
            {{ $t('generate.dayZeroPopup.title') }}
          </div>
          <div class="dz-intro__image-container">
            <img src="/assets/img/generate/day-zero.svg" />
          </div>
          <div class="dz-intro__desc">
            {{ $t('generate.dayZeroPopup.intro') }}
          </div>
          <div class="dz-intro__desc">
            {{ $t('generate.dayZeroPopup.visit') }}
            <a>
              <img src="/assets/img/generate/quick_reference_blue.svg" />
              {{ $t('generate.dayZeroPopup.promptExamples') }}</a
            >
            {{ $t('generate.dayZeroPopup.pane') }}
            <a>
              <v-icon color="#21A7E0" name="tutorials-icon">
                mdi-television-play
              </v-icon>
              {{ $t('generate.dayZeroPopup.tutorialVideos') }}</a
            >
            {{ $t('generate.dayZeroPopup.sectionTo') }}
          </div>
          <div class="dz-intro__btn-container">
            <v-btn
              rounded
              color="primary"
              elevation="3"
              class="btn continue-btn"
              @click="handleContinue"
            >
              {{ $t('common.continue') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else>
        <v-carousel
          height="400"
          hide-delimiters
          :show-arrows="false"
          v-model="currentTip"
        >
          <v-carousel-item v-for="(pTips, i) in proTips" :key="i">
            <div class="pe-list__header">
              <div class="img-container">
                <img class="" src="/assets/img/prezent_avatar.svg" />
              </div>
              <div class="label-container">
                {{ pTips.label }}
              </div>
            </div>
            <div class="pe-list__content">
              <p class="pe-list__description" v-if="pTips.desc">
                {{ pTips.desc }}
              </p>
              <div class="pe-list__img-container" v-if="pTips.imgPath">
                <img class="pe-list__image" :src="pTips.imgPath" />
              </div>

              <template v-else-if="pTips.tips">
                <ProTips
                  v-for="(tips, j) in pTips.tips"
                  :key="j"
                  :tips="tips"
                />
              </template>
            </div>
          </v-carousel-item>
        </v-carousel>
        <div class="carousel-nav-section">
          <img
            @click="setCurrentTip(j)"
            :src="
              currentTip === j
                ? `/assets/img/trial/dot-indicator-active.svg`
                : `/assets/img/trial/dot-indicator-inactive.svg`
            "
            alt
            v-for="(pTips, j) in proTips"
            :key="j"
          />
        </div>
        <div class="dz-intro__btn-container">
          <v-btn rounded class="btn back-btn" @click="handleBack">
            {{ $t('common.back') }}
          </v-btn>
          <v-btn rounded color="primary" class="btn" @click="handleNext">
            {{ nextBtnText }}
          </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProTips from './SharedComponents/ProTips.vue';
import usersApi from '../../API/users-api';

export default {
  name: 'DayZeroPopup',
  components: {
    ProTips,
  },
  data() {
    return {
      proTips: [
        {
          label: this.$t('generate.proTips.tip1.label'),
          imgPath: '/assets/img/generate/pro-tip-1.svg',
        },
        {
          label: this.$t('generate.proTips.tip2.label'),
          desc: this.$t('generate.proTips.tip2.desc'),
          tips: this.$t('generate.proTips.tip2.tips'),
        },
        {
          label: this.$t('generate.proTips.tip3.label'),
          desc: this.$t('generate.proTips.tip3.desc'),
          tips: this.$t('generate.proTips.tip3.tips'),
        },
        {
          label: this.$t('generate.proTips.tip4.label'),
          desc: this.$t('generate.proTips.tip4.desc'),
          tips: this.$t('generate.proTips.tip4.tips'),
        },
      ],
      currentTip: 0,
      showIntro: true,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'agDayZeroPopUp']),
    nextBtnText() {
      if (this.currentTip === this.proTips.length - 1) {
        return this.$t('common.gotIt');
      }
      return this.$t('common.next');
    },
  },
  async mounted() {
    const userData = {
      performIncrements: {
        agDayZeroPopUp: 1,
      },
    };
    await usersApi.methods
      .updateUserProfile(userData)
      .then((updatedUser) => {
        const userInfo = this.currentUser;
        userInfo.user = updatedUser;
        this.setCurrentUser(userInfo);
      })
      .catch((err) => console.log(err));
  },
  methods: {
    handleClose() {
      this.$modal.hide('DayZeroPopup');
    },
    ...mapActions('users', ['setCurrentUser']),
    handleBack() {
      if (this.currentTip === 0) {
        this.showIntro = true;
      } else {
        this.currentTip = (this.currentTip - 1) % this.proTips.length;
      }
    },
    handleNext() {
      if (this.currentTip === this.proTips.length - 1) {
        this.handleClose();
      } else {
        this.currentTip = (this.currentTip + 1) % this.proTips.length;
      }
    },
    setCurrentTip(index) {
      this.currentTip = index;
    },
    handleContinue() {
      this.showIntro = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.day-zero-popup-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  padding: 28px 24px 24px 24px;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    color: rgba(0, 0, 0, 1);
  }

  .day-zero-popup-wrapper-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

::v-deep .v-window {
  .v-window__prev,
  .v-window__next {
    background: none;
  }

  .v-carousel {
    .v-image__image {
      height: 85%;
      background-size: contain !important;
    }

    .v-carousel__controls {
      background: none;
    }
    .v-window-item {
      border-radius: 16px;
      overflow: hidden;
    }
  }
}
.dz-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  &__heading {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    margin-bottom: 16px;
  }
  &__image-container {
    margin-bottom: 10px;
  }
  &__desc {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: left;

    a {
      color: #21a7e0;
      //cursor: pointer;
      &:hover {
        text-decoration: none;
      }
      img {
        height: 12px;
        width: 12px;
      }
      .v-icon {
        font-size: 12px;
      }
    }
  }
  &__btn-container {
    display: flex;
    width: calc(100% - 20px);
    justify-content: flex-end;
    column-gap: 8px;

    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;

      width: 80px;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: none;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #ffffff;

      background: #21a7e0;
      border-radius: 25px;
    }
    .back-btn {
      color: #21a7e0;
      background-color: #ffffff;
      border: 1.5px solid #21a7e0;
    }
    .continue-btn {
      width: auto;
      // margin-top: 24px;
    }
  }
}
.pe-list {
  &__panel_header {
    padding: 16px 0;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
    column-gap: 5px;
    .img-container {
      img {
        height: 28px;
        width: 28px;
      }
    }
    .label-container {
      font-family: Lato;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      text-align: left;
    }
  }
  &__content {
    margin-left: 2px;
  }
  &__description {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 10px;
  }
  &__img-container {
    margin-bottom: 12px;
    margin-left: 4px;
    img {
      width: calc(100% - 4px);
    }
  }
}
::v-deep .pt-list__tip-container__text {
  width: calc(100% - 36px);
}

.carousel-nav-section {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
  > img {
    cursor: pointer;
  }
}
</style>
