<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0088 21H19C19.55 21 20.0208 20.8042 20.4125 20.4125C20.8042 20.0208 21 19.55 21 19V8L16 3H5C4.45 3 3.97917 3.19583 3.5875 3.5875C3.19583 3.97917 3 4.45 3 5V13.8432L5 11.9329V5H15V9H19V19H10.0062L8.0088 21Z"
      :fill="iconColor"
    />
    <path d="M12.0035 17H17V15H14.0009L12.0035 17Z" :fill="iconColor" />
    <path d="M9.92824 13H17V11H10.7843L9.92824 13Z" :fill="iconColor" />
    <path d="M12 9H7V7H12V9Z" :fill="iconColor" />
    <path
      d="M3.8 21.6L5.6 18L2 17.4L7.7 12H8.6L6.8 15.6L10.4 16.2L4.7 21.6H3.8Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SynthesizeIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
