import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import {
  getDefaultTrackingData,
  setCommonData,
  getSlideData,
  getMatomoEventName,
  trackCommonEvent,
  TD_QUERY,
  TD_FAQTITLE,
  TD_TUTORIALTITLE,
  TD_WALKTHROUGHTITLE,
  TD_QUICKTIPSTITLE,
  TD_HYBRIDCHIP,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  getPrezData,
  TD_HYBRID_NO_RESULTS_SLIDES_TRYNOW,
  TD_HYBRID_NO_RESULTS_BUIDNEWPREZENTATION,
  TD_HYBRID_RESULTS_SECTION_PREZ,
  TD_HYBRID_RESULTS_SECTION_SLIDES,
  TD_HYBRID_RESULTS_SECTION_TEMPLATES,
  TD_HYBRID_NO_RESULTS_ADDNEWTEMPLATE,
  TD_HYBRID_RESULTS_SECTION_HELP,
  TD_HYBRID_NO_RESULTS_SENDSUUPPORTREQUEST,
  TD_HYBRID_NO_RESULTS_BUTTON_CLICK,
  TD_HYBRID_NO_RESULTS_ALL_TRYNOW,
  TD_HYBRID_RESULTS_SECTION_ALL,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_RECENT_OR_SUGGESTED_OR_NA,
} from './MatomoTrackingDataHelper';

const SEARCH_EVENT_CATEGORY = 'Search';
const HYBRID = 'Search_Hybrid';
const HYBRID_SLIDES = 'Slides_hybridSearch_download';
const HYBRID_IDEA_CHIP_CLICK = 'search_hybrid_ideachipclick';
const HYBRID_HELP_FAQ = 'search_hybrid_help_faq';
const HYBRID_HELP_TUTORIAL = 'search_hybrid_help_tutorial';
const HYBRID_HELP_WALKTHROUGH = 'search_hybrid_help_walkthrough';
const HYBRID_HELP_QUICKTIPS = 'search_hybrid_help_quicktips';
const DROPDOWN_PEOPLE = 'search_dropdown_people';
const DROPDOWN_LEARN = 'search_dropdown_learn';
const DROPDOWN_PREZENTATIONS = 'search_dropdown_prezentations';
const DROPDOWN_HELP_FAQ = 'search_dropdown_help_faq';
const DROPDOWN_HELP_TUTORIAL = 'search_dropdown_help_tutorial';
const DROPDOWN_HELP_WALKTHROUGH = 'search_dropdown_help_walkthrough';
const DROPDOWN_HELP_QUICKTIPS = 'search_dropdown_help_quicktips';
const TUTORIAL_IDEACHIPCLICK = 'Help_tutorial_IdeaChipClick';
const DROPDOWN_BUILDIDEAGALLERY = 'search_dropdown_BuildIdeaGallery';
const DROPDOWN_PREZENTATIONIDEAGALLERY =
  'search_dropdown_PrezentationIdeaGallery';
const SEARCH_DROPDOWN_KNOW = 'search_dropdown_Know';
const SEARCH_DROPDOWN_SLIDES = 'search_dropdown_Slides';
const SEARCH_HYBRID_ASSET_CLICK = 'Search_hybrid_asset_click';
const SEARCH_HYBRID_PREZ_DOWNLOAD = 'Search_hybrid_Prez_download';
const SEARCH_HYBRID_SLIDE_FAVORITED = 'Search_hybrid_slide_favorited';
const SEARCH_HYBRID_NO_RESULTS_ACTION = 'Search_hybrid_no_results_action';

const SEARCH_BUILD_IDEAGALLERY_SECTIONS = 'Search_Build_ideagallery_sections';
const SEARCH_DROPDOWN_BUILDIDEAGALLERY_SECTIONS =
  'search_dropdown_BuildIdeaGallery_sections';
const SEARCH_PREZENTATIONS_IDEAGALLERY_SECTIONS =
  'Search_Prezentations_ideagallery_sections';
const SEARCH_DROPDOWN_PREZENTATIONIDEAGALLERY_SECTIONS =
  'search_dropdown_PrezentationIdeaGallery_sections';
const SEARCH_DROPDOWN_SLIDE_FAVORITED = 'Search_dropdown_slide_favorited';
const SLIDES_DROPDOWN_SEARCH_DOWNLOAD = 'Slides_dropdown-Search_download';
const SEARCH_DROPDOWN_SLIDE_THUMBNAIL_CLICK =
  'Search_dropdown_slide_thumbnail_click';

const hybridAssetTypeMap = {
  [TD_HYBRID_RESULTS_SECTION_ALL]: TD_HYBRID_NO_RESULTS_ALL_TRYNOW,
  [TD_HYBRID_RESULTS_SECTION_SLIDES]: TD_HYBRID_NO_RESULTS_SLIDES_TRYNOW,
  [TD_HYBRID_RESULTS_SECTION_PREZ]: TD_HYBRID_NO_RESULTS_BUIDNEWPREZENTATION,
  [TD_HYBRID_RESULTS_SECTION_TEMPLATES]: TD_HYBRID_NO_RESULTS_ADDNEWTEMPLATE,
  [TD_HYBRID_RESULTS_SECTION_HELP]: TD_HYBRID_NO_RESULTS_SENDSUUPPORTREQUEST,
};

export const trackSearchEvents = {
  hybrid(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: HYBRID,
      });
    } catch (e) {
      console.log(e);
    }
  },

  hybridSlides(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SEARCH_EVENT_CATEGORY, HYBRID_SLIDES, eventName);
    } catch (e) {
      console.log(e);
    }
  },

  hybridIdeaChipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: HYBRID_IDEA_CHIP_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  trackHelp(currentUser, data) {
    try {
      const trackingData = getDefaultTrackingData();
      setCommonData(trackingData, currentUser);
      const { type, isSearch, searchKey, item, searchType, dropDownCategory } =
        data;
      trackingData.set(TD_COMMON_COLUMN_NAME, TD_RECENT_OR_SUGGESTED_OR_NA);
      trackingData.set(TD_COMMON_COLUMN_VALUE, dropDownCategory);
      const helpItemName = item?.name;
      if (isSearch) {
        trackingData.set(TD_QUERY, searchKey);
      }
      let eventAction = '';

      switch (type) {
        case 'tutorials':
          trackingData.set(TD_TUTORIALTITLE, helpItemName);
          if (searchType === 'hybrid') {
            eventAction = HYBRID_HELP_TUTORIAL;
          } else {
            eventAction = DROPDOWN_HELP_TUTORIAL;
          }

          break;
        case 'walkthroughs':
          trackingData.set(TD_WALKTHROUGHTITLE, helpItemName);
          if (searchType === 'hybrid') {
            eventAction = HYBRID_HELP_WALKTHROUGH;
          } else {
            eventAction = DROPDOWN_HELP_WALKTHROUGH;
          }
          break;
        case 'faqs':
          trackingData.set(TD_FAQTITLE, helpItemName);
          if (searchType === 'hybrid') {
            eventAction = HYBRID_HELP_FAQ;
          } else {
            eventAction = DROPDOWN_HELP_FAQ;
          }
          break;
        case 'quicktips':
          trackingData.set(TD_QUICKTIPSTITLE, helpItemName);
          if (searchType === 'hybrid') {
            eventAction = HYBRID_HELP_QUICKTIPS;
          } else {
            eventAction = DROPDOWN_HELP_QUICKTIPS;
          }
          break;

        default:
          break;
      }
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SEARCH_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },

  trackHybridSearchHelp(currentUser, data) {
    try {
      const trackingData = getDefaultTrackingData();
      setCommonData(trackingData, currentUser);
      const { type, searchKey, item, hybridchip } = data;
      const helpItemName = item?.name;
      trackingData.set(TD_QUERY, searchKey);
      trackingData.set(TD_HYBRIDCHIP, hybridchip);
      trackingData.set(
        TD_HYBRID_RESULTS_SECTION_CLICK,
        TD_HYBRID_RESULTS_SECTION_HELP,
      );

      switch (type) {
        case 'tutorials':
          trackingData.set(TD_TUTORIALTITLE, helpItemName);

          break;
        case 'walkthroughs':
          trackingData.set(TD_WALKTHROUGHTITLE, helpItemName);
          break;
        case 'faqs':
          trackingData.set(TD_FAQTITLE, helpItemName);

          break;
        case 'quicktips':
          trackingData.set(TD_QUICKTIPSTITLE, helpItemName);
          break;

        default:
          break;
      }
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SEARCH_EVENT_CATEGORY,
        SEARCH_HYBRID_ASSET_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },

  hybridSearchSlideClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SEARCH_EVENT_CATEGORY,
        SEARCH_HYBRID_ASSET_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },

  hybridSearchAssetClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_HYBRID_ASSET_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  hybridSearchPrezentationsClick(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_HYBRID_ASSET_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  searchHybridPrezDownload(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_HYBRID_PREZ_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },

  searchHybridSlideFavorited(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SEARCH_EVENT_CATEGORY,
        SEARCH_HYBRID_SLIDE_FAVORITED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },

  searchHybridNoResultsAction(currentUser, assetType, otherData) {
    const addOtherData = {
      ...otherData,
      [TD_HYBRID_NO_RESULTS_BUTTON_CLICK]: hybridAssetTypeMap[assetType],
    };
    try {
      trackCommonEvent({
        currentUser,
        otherData: addOtherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_HYBRID_NO_RESULTS_ACTION,
      });
    } catch (e) {
      console.log(e);
    }
  },

  trackDropDownPeople(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: DROPDOWN_PEOPLE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  trackDropDownLearn(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: DROPDOWN_LEARN,
      });
    } catch (e) {
      console.log(e);
    }
  },
  trackDropDownPrezentations(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: DROPDOWN_PREZENTATIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },

  trackTutorialIdeachipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: TUTORIAL_IDEACHIPCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  trackDropdownBuildIdeaGallery(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: DROPDOWN_BUILDIDEAGALLERY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  trackDropdownPrezentationIdeaGallery(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: DROPDOWN_PREZENTATIONIDEAGALLERY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  trackDropdownKnow(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_DROPDOWN_KNOW,
      });
    } catch (e) {
      console.log(e);
    }
  },
  searchDropdownSlides(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_DROPDOWN_SLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  searchBuildIdeaGallerySection(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_BUILD_IDEAGALLERY_SECTIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  searchDropdownBuildIdeaGallerySection(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_DROPDOWN_BUILDIDEAGALLERY_SECTIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  searchPrezentationIdeaGallerySection(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_PREZENTATIONS_IDEAGALLERY_SECTIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  searchDropdownPrezentationIdeaGallerySection(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SEARCH_EVENT_CATEGORY,
        eventAction: SEARCH_DROPDOWN_PREZENTATIONIDEAGALLERY_SECTIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  searchDropdownSlideFavorited(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SEARCH_EVENT_CATEGORY,
        SEARCH_DROPDOWN_SLIDE_FAVORITED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchDropdownSlideDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SEARCH_EVENT_CATEGORY,
        SLIDES_DROPDOWN_SEARCH_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchDropdownSlideThumbnailClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SEARCH_EVENT_CATEGORY,
        SEARCH_DROPDOWN_SLIDE_THUMBNAIL_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
};
