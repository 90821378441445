import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import {
  getMatomoEventName,
  getPrezData,
  getSlideData,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const PREZENTATION_EVENT_CATEGORY = 'prezentations';
const PREZENTATIONS_ICONCLICK = 'prezentations_IconClick';
const PREZENTATIONS_PREZTABCLICK = 'prezentations_PrezTabClick';
const PREZENTATIONS_PREZREVIEWTABCLICK = 'prezentations_PrezReviewTabClick';
const PREZENTATIONS_TEAMPREZTABCLICK = 'prezentations_TeamPrezTabClick';
const PREZENTATIONS_SORTBY = 'prezentations_sortBy';
const PREZENTATIONS_EDITSTORYLINE = 'prezentations_EditStoryline';
const PREZENTATIONS_ACCEPTSTORYLINE = 'prezentations_acceptstoryline';
const PREZENTATIONS_TAILORSLIDES = 'prezentations_TailorSlides';
const PREZENTATIONS_THUMBNAIL_SHAREICON = 'prezentations_Thumbnail_ShareIcon';
const PREZENTATIONS_THUMBNAIL_SHAREICONUPDATE =
  'prezentations_Thumbnail_ShareIconUpdate';

const PREZENTATIONS_THUMBNAIL_SHAREBUTTON =
  'prezentations_Thumbnail_ShareButton';
const PREZENTATIONS_THUMBNAIL_SHAREBUTTONUPDATE =
  'prezentations_Thumbnail_ShareButtonUpdate';

const PREZENTATIONS_SHAREICON = 'prezentations_ShareIcon';
const PREZENTATIONS_SHAREICONUPDATE = 'prezentations_ShareIconUpdate';
const PREZENTATIONS_SHAREBUTTON = 'prezentations_ShareButton';
const PREZENTATIONS_SHAREBUTTONUPDATE = 'prezentations_ShareButtonUpdate';
const PREZENTATIONS_COMMENTS = 'prezentations_Comments';
const PREZENTATIONS_THUMBNAILCLICK = 'prezentations_ThumbnailClick';
const PREZENTATIONS_THUMBNAILCLICK_DOWNLOAD =
  'prezentations_ThumbnailClick_download';
const PREZENTATIONS_THUMBNAIL_COPYICONCLICK =
  'prezentations_Thumbnail_CopyIconClick';
const PREZENTATIONS_THUMBNAIL_RENAME = 'prezentations_Thumbnail_Rename';
const PREZENTATIONS_THUMBNAIL_DELETE = 'prezentations_Thumbnail_Delete';
const PREZENTATIONS_SLIDES_IMAGEREPLACEMENT =
  'prezentations_slides_ImageReplacement';
const PREZENTATIONS_TAILORSLIDES_FOR_GREYAUDIENCE =
  'Prezentations_tailor slides for_greyaudience';
const PREZENTATIONS_TAILORSLIDES_FOR_REMIND =
  'Prezentations_tailor slides for_remind';
const PREZENTATIONS_NODECOUNT_CHANGE = 'Prezentations_nodecount_change';
const PREZENTATIONS_SLIDES_IMAGEREPLACEMENT_RESET =
  'Prezentations_slides_ImageReplacement_Resettodefault';
const SEARCH_PREZENATIONS_IDEA_GALLERY = 'Search_Prezentations_ideagallery';
const PREZENTATIONS_SLIDE_FAVOURITE = 'Prezentations_Slidefavourite';
const PREZENTATIONS_IMAGEREPLACEMENT_SEARCH =
  'Prezentations_imagereplacement_Search';
const PREZENTATIONS_IMAGEREPLACEMENT_ACCEPT =
  'Prezentations_imagereplacement_Accept';
const PREZENTATIONS_THUMBNAIL_HOVER = 'Prezentations_thumbnail_hover';
const PREZENTATIONS_STORYLINECHIPCLICK = 'Prezentations_storylinechipclick';
const PREZENTATIONS_SEARCH = 'Prezentations_search';
const PREZENTATIONS_TEMPLATE_DROPDOWN_CLICK =
  'Prezentations_template_dropdown_click';
const PREZENTATIONS_ADVANCE_FILTERS_CLICK =
  'Prezentations_advance_filters_click';
const PREZENTATIONS_ADVANCE_FILTERS_SELECTED =
  'Prezentations_advance_filters_selected';
const PREZENTATIONS_FAVOURITED = 'Prezentations_favourited';
const PREZENTATIONS_THUMBNAIL_DOWNLOAD = 'Prezentations_thumbnail_download';
const PREZENTATIONS_ELIPSIS = 'Prezentations_elipsis';
const PREZENTATIONS_ELIPSIS_COPYLINK = 'Prezentations_elipsis_copylink';
const PREZENTATIONS_RESETFILTERS = 'prezentations_resetfilters';
const PREZENTATIONS_CLEARFILTERS = 'prezentations_clearfilters';
const PREZENTATIONS_IDEA_DRAGGED = 'Prezentations_idea_dragged';
const PREZENTATIONS_SECTION_DRAGGED = 'Prezentations_section_dragged';
const PREZENTATIONS_SECTION_RENAMED = 'Prezentations_section_renamed';
const PREZENTATIONS_SECTION_DELETED = 'Prezentations_section_deleted';
const PREZENTATIONS_SECTION_POSITION_CHANGED =
  'Prezentations_section_position-changed';
const PREZENTATIONS_BEST_PRACTICE_DECK_TOGGLE =
  'Prezentations_Best-practice-deck_toggle';
const PREZENTATIONS_ADDMORE = 'Prezentations_Addmore';
const PREZENTATIONS_ADDMORE_CONTINUE = 'Prezentations_Addmore_Continue';
const PREZENTATIONS_ADDMORE_CONTINUE_SEND_PRESENTATION =
  'Prezentations_Addmore_Continue_Send-Presentation';
const PREZENTATIONS_IDEA_DUPLICATE = 'Prezentations_idea_duplicate';
const PREZENTATIONS_IDEA_DELETE = 'Prezentations_idea_delete';
const PREZENTATIONS_CUSTOM_SECTION_ADDITION =
  'Prezentations_custom-section-addition';
const PREZENTATIONS_THUMBNAILCLICK_ADD_TO_SLIDES =
  'Prezentations_thumbnailclick_add-to-slides';

const PREZENTATIONS_STEP3_MOSIAC_CLICK = 'Prezentations_step3_mosiac_click';
const PREZENTATIONS_GALLERY_TAB_CLICK = 'Prezentations_gallery_tab-click';
const PREZENTATIONS_MAXIMIZE_MINIMIZE_CLICK =
  'Prezentations_maximize-minimize_click';
const PREZENTATIONS_SLIDE_ADDED_STEP3 = 'Prezentations_slide-added-step3';
// const PREZENTATIONS_SLIDE_SEARCH_QUERY = 'Prezentations_slide-search_query';
const PREZENTATIONS_GALLERY_SEARCH_QUERY = 'Prezentations_gallery-search_query';
const PREZENTATIONS_GALLERY_FILTERED = 'Prezentations_gallery_filtered';
const PREZENTATIONS_GALLERY_FILTER_RESET = 'Prezentations_gallery_filter-reset';
const PREZENTATIONS_GALLERY_VIEW_CLICK = 'Prezentations_gallery_view-click';
const PREZENTATIONS_OTHER_ACTIONS_SELECTED =
  'Prezentations_other-actions_selected';
const PREZENTATIONS_GALLERY_CLICK = 'Prezentations_gallery_click';
const PREZENTATIONS_ADD_A_SLIDE_CLICK = 'Prezentations_add-a-slide_click';
const PREZENTATIONS_TUTORIAL_ICON_CLICK = 'Prezentations_tutorial-icon-click';
const PREZENTATIONS_TUTORIAL_ICON_VIDEO_CLICK =
  'Prezentations_tutorial-icon_video-click';
const PREZENTATIONS_EDIT_SLIDE_CLICK = 'Prezentations_edit-slide_click';
const PREZENTATIONS_EDIT_SLIDE_CHANGE_LAYOUT_CLICK =
  'Prezentations_edit-slide_change-layout-click';
const PREZENTATIONS_SIMILAR_SLIDE_SELECTED =
  'Prezentations_similar-slide-selected';
const PREZENTATIONS_CHANGE_LAYOUT_NODE_CHANGED =
  'Prezentations_change-layout_node-changed';
const PREZENTATIONS_CHANGE_LAYOUT_VIEW_CHANGED =
  'Prezentations_change-layout_view-changed';
const PREZENTATIONS_REPLACE_VISUALS_CLICK =
  'Prezentations_replace-visuals-click';
const PREZENTATIONS_EDIT_SLIDE_EDIT_NODE_COUNT_CLICK =
  'Prezentations_edit-slide_edit-node-count-click';
const PREZENTATIONS_EDIT_SLIDE_EDIT_TAGS_CLICK =
  'Prezentations_edit-slide_edit-tags-click';
const PREZENTATIONS_EDIT_SLIDE_EDIT_BEST_PRACTICES_CLICK =
  'Prezentations_edit-slide_edit-best-practices-click';
const PREZENTATIONS_THUMBNAILCLICK_ADD_TO_SLIDES_ADD =
  'Prezentations_thumbnailclick_add-to-slides_add';
const PREZENTATIONS_CHANGE_AUDIENCE_CLICK =
  'Prezentations_change-audience-click';
const PREZENTATIONS_ANONYMIZE_CLICK = 'Prezentations_anonymize-click';
const PREZENTATIONS_ANONYMIZE_ANONYMIZE_SLIDE =
  'Prezentations_anonymize_anonymize-slide';
const PREZENTATIONS_ANONYMIZE_ANONYMIZE_DECK =
  'Prezentations_anonymize_anonymize-deck';
const PREZENTATIONS_BEST_PRACTICES_EDITED =
  'Prezentations_best-practices-edited';
const PREZENTATIONS_BEST_PRACTICES_DELETED =
  'Prezentations_best-practices-deleted';
const PREZENTATIONS_BEST_PRACTICES_ADDED = 'Prezentations_best-practices-added';
const PREZENTATIONS_SLIDES_IMAGEREPLACEMENT_ACCEPT =
  'prezentations_slides_ImageReplacement_accept';
const PREZENTATIONS_ADD_SLIDE_CLICK = 'Prezentations_add-slide_click';
const CONVERT_TEMPLATE_CLICK = 'Convert-template_click';
const CONVERT_TEMPLATE_TEMPLATE_SELECTED = 'Convert-template_template-selected';
const CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS =
  'Convert-template_accept-all-suggestions';
const CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE =
  'Convert-template_select-a-different-template';

export const trackPrezentationEvents = {
  prezentationsShareIcon(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SHAREICON,
      });
    } catch (e) {
      console.log(e);
    }
  },

  prezentationsShareButton(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SHAREBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailShareIcon(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_SHAREICON,
      });
    } catch (e) {
      console.log(e);
    }
  },

  prezentationsThumbnailShareButton(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_SHAREBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsComments(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_COMMENTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsPrezTabClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_PREZTABCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsPrezReviewTabClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_PREZREVIEWTABCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsTeamPrezTabClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TEAMPREZTABCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsTailorSlides(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TAILORSLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsEditStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_EDITSTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAcceptStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ACCEPTSTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSortBy(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SORTBY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailShareIconUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_SHAREICONUPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsShareIconUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SHAREICONUPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsShareButtonUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SHAREBUTTONUPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailShareButtonUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_SHAREBUTTONUPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailClick(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAILCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailClickDownload(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAILCLICK_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailCopyIconClick(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_COPYICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailRename(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_RENAME,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailDelete(currentUser, prezentation, otherData) {
    try {
      const prezData = getPrezData(prezentation);
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationSlidesImageReplacement(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SLIDES_IMAGEREPLACEMENT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationTailorSlidesForGreyAuidence(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TAILORSLIDES_FOR_GREYAUDIENCE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationTailorSlidesForRemind(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TAILORSLIDES_FOR_REMIND,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationNodeCountChange(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        PREZENTATION_EVENT_CATEGORY,
        PREZENTATIONS_NODECOUNT_CHANGE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  prezentationSlideFavourite(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        PREZENTATION_EVENT_CATEGORY,
        PREZENTATIONS_SLIDE_FAVOURITE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  prezentationImageReplacementResetToDefault(
    slideData,
    currentUser,
    otherData,
  ) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        PREZENTATION_EVENT_CATEGORY,
        PREZENTATIONS_SLIDES_IMAGEREPLACEMENT_RESET,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  prezentationIdeaSearchGallery(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: SEARCH_PREZENATIONS_IDEA_GALLERY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsImagereplacementSearch(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        PREZENTATION_EVENT_CATEGORY,
        PREZENTATIONS_IMAGEREPLACEMENT_SEARCH,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsImagereplacementAccept(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        PREZENTATION_EVENT_CATEGORY,
        PREZENTATIONS_IMAGEREPLACEMENT_ACCEPT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailHover(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_HOVER,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsStorylinechipclick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_STORYLINECHIPCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsTemplateDropdownClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TEMPLATE_DROPDOWN_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAdvanceFiltersClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADVANCE_FILTERS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAdvanceFiltersSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADVANCE_FILTERS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsFavourited(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_FAVOURITED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAIL_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsElipsis(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ELIPSIS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsElipsisCopylink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ELIPSIS_COPYLINK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsResetfilters(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_RESETFILTERS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsClearfilters(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_CLEARFILTERS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsIdeaDragged(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_IDEA_DRAGGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSectionDragged(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SECTION_DRAGGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSectionRenamed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SECTION_RENAMED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSectionDeleted(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SECTION_DELETED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSectionPositionChanged(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SECTION_POSITION_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsBestPracticeDeckToggle(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_BEST_PRACTICE_DECK_TOGGLE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAddMoreClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADDMORE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAddMoreContinueClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADDMORE_CONTINUE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAddmoreContinueSendPrezentationClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADDMORE_CONTINUE_SEND_PRESENTATION,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsIdeaDuplicate(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_IDEA_DUPLICATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsIdeaDelete(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_IDEA_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsCustomSectionAddition(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_CUSTOM_SECTION_ADDITION,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsThumbnailClickAddToSlides(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAILCLICK_ADD_TO_SLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsStepThreeMosaicClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_STEP3_MOSIAC_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsGalleryTabClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_GALLERY_TAB_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsMaximizeMinimizeClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_MAXIMIZE_MINIMIZE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationAddSlideClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADD_SLIDE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSlideAddedStep3(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SLIDE_ADDED_STEP3,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSlideSearchQuery(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_GALLERY_SEARCH_QUERY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsGalleryFiltered(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_GALLERY_FILTERED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsGalleryFilterReset(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_GALLERY_FILTER_RESET,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsGalleryViewClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_GALLERY_VIEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsOtherActionsSelected(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_OTHER_ACTIONS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsGalleryClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_GALLERY_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAddaSlideClick(currentUser, prezentation, otherData) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ADD_A_SLIDE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsTutorialIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TUTORIAL_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsTutorialIconVideoPlay(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_TUTORIAL_ICON_VIDEO_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsEditSlideClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_EDIT_SLIDE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsEditSlideChangeLayoutClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_EDIT_SLIDE_CHANGE_LAYOUT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSimilarSlideClicked(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SIMILAR_SLIDE_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsChangeLayoutNodeChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_CHANGE_LAYOUT_NODE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsChangeLayoutViewChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_CHANGE_LAYOUT_VIEW_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsReplaceVisualsClicked(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_REPLACE_VISUALS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsEditNodeCountClicked(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_EDIT_SLIDE_EDIT_NODE_COUNT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsEditTagClicked(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_EDIT_SLIDE_EDIT_TAGS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsEditBestPracticesClicked(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_EDIT_SLIDE_EDIT_BEST_PRACTICES_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAddToSlidesAddClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_THUMBNAILCLICK_ADD_TO_SLIDES_ADD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsChangeAudienceClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_CHANGE_AUDIENCE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAnonymizeClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ANONYMIZE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAnonymizeSlide(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ANONYMIZE_ANONYMIZE_SLIDE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsAnonymizeDeck(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_ANONYMIZE_ANONYMIZE_DECK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsBestPracticesEdited(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_BEST_PRACTICES_EDITED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsBestPracticesDeleted(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_BEST_PRACTICES_DELETED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsBestPracticesAdded(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_BEST_PRACTICES_ADDED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  prezentationsSlidesImageReplacementAccept(
    currentUser,
    prezentation,
    otherData,
  ) {
    const prezData = getPrezData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: PREZENTATIONS_SLIDES_IMAGEREPLACEMENT_ACCEPT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: CONVERT_TEMPLATE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateTemplateSelectClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: CONVERT_TEMPLATE_TEMPLATE_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateAcceptAllSuggestions(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateSelectDifferentTemplate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PREZENTATION_EVENT_CATEGORY,
        eventAction: CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
