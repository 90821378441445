import {
  TD_NORESULTSFOUND,
  TD_QUERY,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const HELP_EVENT_CATEGORY = 'help';
const HELP_ICONCLICK = 'help_IconClick';
const HELP_TUTORIALS_CLICK = 'help_Tutorials_Click';
const HELP_WALKTHROUGHS_CLICK = 'help_Walkthroughs_Click';
const HELP_FAQ_CLICK = 'help_FAQ_Click';
const HELP_QUICKTIPS_CLICK = 'help_QuickTips_Click';

const HELP_SEARCH_HYBRID_HELP_FAQ = 'Help_search_dynamic_Faq';
const HELP_SEARCH_HYBRID_HELP_TUTORIAL = 'Help_search_dynamic_Tutorial';
const HELP_SEARCH_HYBRID_HELP_WALKTHROUGH = 'Help_search_dynamic_Walkthrough';
const HELP_SEARCH_HYBRID_HELP_QUICKTIPS = 'Help_search_dynamic_Quicktips';
const HELP_SECTION_CLICK = 'Help_Section_click';
const HELP_SECTION_ASSEST_CLICK = 'Help_section_assest_click';
const HELP_SUPPORT_CLICK = 'Help_support_click';
const HELP_SUPPORT_SUBMIT = 'Help_support_submit';

export const trackHelpEvents = {
  helpIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpTutorialsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_TUTORIALS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpWalkthroughsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_WALKTHROUGHS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpFAQClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_FAQ_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpQuickTipsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_QUICKTIPS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  hybridSearch(currentUser, query, helpType, searchResultsCount) {
    const otherData = {
      [TD_QUERY]: query,
      [TD_NORESULTSFOUND]: searchResultsCount ? 0 : 1,
    };
    let eventAction = '';
    try {
      switch (helpType) {
        case 'faqs':
          eventAction = HELP_SEARCH_HYBRID_HELP_FAQ;
          break;
        case 'walkthroughs':
          eventAction = HELP_SEARCH_HYBRID_HELP_WALKTHROUGH;
          break;
        case 'tutorials':
          eventAction = HELP_SEARCH_HYBRID_HELP_TUTORIAL;
          break;
        case 'quicktips':
          eventAction = HELP_SEARCH_HYBRID_HELP_QUICKTIPS;
          break;
        default:
          break;
      }
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpSectionClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_SECTION_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpSectionAssetClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_SECTION_ASSEST_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpSupportClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_SUPPORT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  helpSupportSubmitClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: HELP_EVENT_CATEGORY,
        eventAction: HELP_SUPPORT_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
