<template>
  <div class="poweredByStripe">
    <div>Powered by</div>
    <v-img
      src="/assets/img/common/stripe.svg"
      alt
      class="stripeLogo"
      max-width="50px"
    />
  </div>
</template>

<script>
export default {
  name: 'PoweredByStripe',
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.poweredByStripe {
  border: 1px solid $gray-dark-login-text;
  border-radius: 4px;
  display: flex;
  height: 24px;
  padding: 5px 5px 2px 5px;
  width: 140px;

  div:first-child {
    color: $gray-dark-login-text;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 14px;
  }

  .stripeLogo {
    margin-left: 5px;
  }
}
</style>
