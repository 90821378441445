import { render, staticRenderFns } from "./UserMentionList.vue?vue&type=template&id=721a3712&scoped=true&"
import script from "./UserMentionList.vue?vue&type=script&lang=js&"
export * from "./UserMentionList.vue?vue&type=script&lang=js&"
import style0 from "./UserMentionList.vue?vue&type=style&index=0&id=721a3712&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721a3712",
  null
  
)

export default component.exports