<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_6302_20063"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :width="iconWidth"
      :height="iconHeight"
    >
      <rect :width="iconWidth" :height="iconHeight" :fill="iconColor" />
    </mask>
    <g mask="url(#mask0_6302_20063)">
      <path
        d="M6 13.5H12V12H6V13.5ZM6 10.5H12V9H6V10.5ZM4.5 16.5C4.0875 16.5 3.73438 16.3531 3.44063 16.0594C3.14688 15.7656 3 15.4125 3 15V3C3 2.5875 3.14688 2.23438 3.44063 1.94063C3.73438 1.64688 4.0875 1.5 4.5 1.5H10.5L15 6V15C15 15.4125 14.8531 15.7656 14.5594 16.0594C14.2656 16.3531 13.9125 16.5 13.5 16.5H4.5ZM9.75 6.75V3H4.5V15H13.5V6.75H9.75Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FormatIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
