<template>
  <div class="template-complete-popup-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="template-complete-popup-wrapper-content">
      <div class="template-complete-popup-image-wrapper">
        <img
          src="/assets/img/templateDesign/template-ready.svg"
          alt
          width="342px"
          height="276px"
        />
      </div>
      <div class="template-complete-popup-desc">
        {{ $t('templateDesign.templateUpdated') }}
      </div>
      <div class="button-container">
        <v-btn @click="handleGotIt" elevation="3" class="next">
          {{ $t('templateDesign.gotIt') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'TemplateCompleteConfirmationPopup', // If template is selected from the banner
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
  },
  methods: {
    ...mapActions('users', ['setCurrentTheme', 'setCurrentUser']),
    handleClose() {
      this.$modal.hide('TemplateCompleteConfirmationPopup');
    },
    async handleGotIt() {
      this.$router.push(`/home/slides`, () => {});
      this.handleClose();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.template-complete-popup-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .template-complete-popup-wrapper-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .template-complete-popup-image-wrapper {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    width: fit-content;
  }

  .template-complete-popup-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 11px;
  }

  .desc {
    margin-bottom: 15px;
  }

  .template-complete-popup-desc {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    p {
      margin-bottom: 10px;
    }
  }
}

.button-container {
  padding: 20px;
  margin: 5px;
  .next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 22px;
    gap: 8px;

    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}
</style>
