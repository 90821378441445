<template>
  <div class="slides-gallery-container">
    <div data-pendo-id="slides-gallery-search">
      <SearchInputField
        :placeholder="$t('prezentationGallery.slideSearchFieldPlaceholder')"
        :onInput="handleSearchIdeaSlides"
      />
    </div>
    <div>
      <div class="filters-section">
        <div class="filters-action" @click="handleFiltersToggle">
          <span class="filter-title" data-pendo-id="slides-gallery-filter">{{
            $t('prezentationGallery.slidesLibraryFiltersLabel')
          }}</span>
          <v-icon>
            {{ !isFiltersExpand ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
          </v-icon>
        </div>
        <div :style="{ display: isFiltersExpand ? 'block' : 'none' }">
          <SlidesFilters
            ref="slidesFilters"
            :onFilteredSlides="handleFilteredSlides"
            :isloadingCallback="isloadingCallback"
            :prezentationDetails="prezentationDetails"
          />
        </div>
      </div>
      <div v-if="!isFiltersExpand">
        <div class="view-icons" v-if="gallerySlides.length && !isLoading">
          <v-icon
            @click="() => setViewType('grid')"
            :color="viewType === 'grid' ? `#21a7e0` : 'inherit'"
            v-tooltip="{
              content: $t('prezentationGallery.gridView'),
              placement: 'top-center',
            }"
          >
            mdi-view-grid{{ viewType === 'grid' ? '' : '-outline' }}
          </v-icon>
          <v-icon
            @click="() => setViewType('agenda')"
            :color="viewType === 'agenda' ? `#21a7e0` : 'inherit'"
            v-tooltip="{
              content: $t('prezentationGallery.columnView'),
              placement: 'top-center',
            }"
          >
            mdi-view-agenda{{ viewType === 'agenda' ? '' : '-outline' }}
          </v-icon>
        </div>
        <div v-if="!isLoading" :key="componentkey">
          <template v-if="gallerySlides.length">
            <draggable
              class="slide-drag row slides-container from-gallery"
              :list="gallerySlides"
              v-bind="dragOptions"
              @end="onSectionDragEnd('Shells')"
              @add="onSectionDragEnd('Shells')"
              :group="{ name: 'slides', put: false, pull: 'clone' }"
            >
              <v-col
                :cols="viewType === 'grid' ? 6 : 12"
                v-for="(slide, i) in gallerySlides"
                :key="`list-${slide.id}-${i}`"
                class="image-cell"
              >
                <SlideThumbnail :slide="slide" :slideIndex="i">
                  <template>
                    <v-checkbox
                      v-model="selectedSlideObjRef[slide.assetId]"
                      :class="`checkbox ${
                        selectedSlideObjRef[slide.assetId] ? 'checked' : ''
                      }`"
                      size="small"
                      @change="(e) => handleSlidesSelect(e, slide)"
                    />
                  </template>
                </SlideThumbnail>
              </v-col>
            </draggable>
          </template>
          <v-row v-else>
            <v-col class="nodata">
              <template v-if="!isNetworkError">
                <div>
                  <img src="/assets/gallery_no_data.svg" alt />
                </div>

                <div class="not-found-descriptions">
                  <div>{{ $t('prezentationGallery.noResultText') }}</div>
                </div>
              </template>
              <template v-else>
                <div>
                  <img src="/assets/img/sso-user_not_found.svg" alt />
                </div>

                <div class="not-found-descriptions">
                  <div>
                    {{ $t('prezentationGallery.unableToFetchSlides') }} <br />{{
                      $t('prezentationGallery.please')
                    }}
                    <span class="text-btn" @click="handleTryAgain">
                      {{ $t('prezentationGallery.tryAgain') }}
                    </span>
                    !
                  </div>
                </div>
              </template>
            </v-col>
          </v-row>
        </div>
        <v-row align="center" justify="center" class="slides-container" v-else>
          <clip-loader
            :loading="isLoading"
            :color="`#21a7e0`"
            :width="20"
            :height="20"
          />
        </v-row>
      </div>
    </div>
    <v-snackbar
      color="#ffffff"
      v-model="selectedSlides.length"
      bottom
      right
      :timeout="-1"
    >
      <span style="color: rgba(0, 0, 0, 0.87)">
        {{
          $t('prezentationGallery.selectedSlidesText', {
            selectedSlides: selectedSlides.length,
          })
        }}
      </span>
      <span>
        <v-menu
          offset-y
          v-if="
            !isIndexToAddSlidesSelected &&
            prezentationDetails.sections.list.length
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-prezentation-btn"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('prezentationGallery.addToPrezentationBtn') }}
              <v-icon end>mdi-menu-up</v-icon>
            </v-btn>
          </template>
          <v-list class="menu-items-wrapper">
            <v-list-item
              v-for="(item, index) in slidesList"
              :key="index"
              @click="() => handleSlideToAddClick(item)"
            >
              <v-list-item-title>
                {{ item.sectionName || item.count }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="add-prezentation-btn"
          color="primary"
          v-else
          @click="() => handleSlideToAddClick()"
        >
          {{ $t('prezentationGallery.addToPrezentationBtn') }}
        </v-btn>
      </span>
      <v-icon
        size="14px"
        v-show="selectedSlides.length"
        @click="handleSelectionClear"
        color="rgba(0,0,0,0.87)"
      >
        mdi-close
      </v-icon>
    </v-snackbar>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import debounce from 'lodash/debounce';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { getFileURL } from '@/utils/calyrex';
import SearchInputField from './SearchInputField.vue';
import SlidesFilters from './SlidesFilters.vue';
import SlideThumbnail from './SlideThumbnail.vue';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { getThumbnail } from '../../../store/modules/helper';
import { AnalyticsHandler } from '../Analytics/AnalyticsHandler';
import { performSearch } from '../../../graphql/queries';
import { GraphQLService } from '../../../services/GraphQLService';
import { trackPrezentationEvents } from '../Analytics/PrezentationEvents';
import {
  TD_PLACEMENT,
  TD_QUERY,
  getBuildPrezentationData,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  BUILD,
} from '../Analytics/MatomoTrackingDataHelper';
import { trackBuildEvents } from '../Analytics/BuildEvents';

export default {
  components: {
    SearchInputField,
    SlideThumbnail,
    SlidesFilters,
    ClipLoader,
    draggable,
  },
  data() {
    return {
      viewType: 'grid',
      selectedSlides: [],
      gallerySlides: [],
      selectedSlideObjRef: {},
      isFiltersExpand: false,
      isLoading: true,
      searchText: '',
      isNetworkError: false,
      filterText: '',
      componentkey: 1,
    };
  },
  props: {
    slidesList: {
      type: Array,
      default: () => [],
    },
    onAddSlidesToSlideNumber: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      default: '',
    },
    isIndexToAddSlidesSelected: {
      type: Boolean,
      default: false,
    },
    onSectionDragEnd: {
      type: Function,
      default: () => {},
    },
    prezentationDetails: {
      type: Object,
      default: () => ({}),
    },
    currentTab: {
      type: String,
      default: '',
    },
  },
  watch: {
    isIndexToAddSlidesSelected() {
      const list = this.selectedSlides;
      this.selectedSlides = [...list];
    },
    currentTab() {
      if (this.currentTab !== 'slides') {
        this.handleSelectionClear();
      }
    },
    filterText(val) {
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGallerySearchQuery(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Slide library',
          [TD_QUERY]: val,
        });
      } else {
        trackPrezentationEvents.prezentationsSlideSearchQuery(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_QUERY]: val,
            [TD_PLACEMENT]: 'Slides',
          },
        );
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTheme']),

    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
        disabled: false,
      };
    },
    currentThemeCode() {
      return (
        this.currentTheme?.code || this.currentUser?.theme?.code || 'default'
      );
    },
  },
  methods: {
    handleSelectionClear() {
      this.selectedSlides = [];
      this.selectedSlideObjRef = {};
    },
    handleTryAgain() {
      this.gallerySlides = [];
      this.searchWithinCategory(this.searchText);
      this.isFiltersExpand = false;
    },
    getRandomNumber(min, max) {
      const num = (Math.random() * (max - min) + min).toFixed(2);
      return Number(num);
    },
    getChoice(fingerprint) {
      let choice = {
        visual: this.getRandomNumber(4.5, 6.0),
        data: this.getRandomNumber(4.0, 5.0),
      };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'performer':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'navigator':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'surgeon':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'architect':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scholar':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scientist':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;
        case 'producer':
          choice = { visual: 1.0, data: 1.0 };
          return choice;
        default:
          return choice;
      }
    },
    // eslint-disable-next-line func-names
    handleSearchIdeaSlides: debounce(async function (e) {
      if (e) {
        if (this.searchText !== e) {
          this.searchWithinCategory(e);

          this.searchText = e;
        }
        this.filterText = e;
        this.isFiltersExpand = false;
      } else {
        this.isLoading = true;
        this.gallerySlides = [];

        const list = await this.$refs.slidesFilters.handleResetFilters();
        if (list?.length) {
          this.gallerySlides = list;
        }
      }
    }, 300),
    searchWithinCategory(text) {
      this.isLoading = true;
      this.isNetworkError = false;
      this.gallerySlides = [];
      const choice = this.getChoice(
        this.currentUser.user.fingerPrint
          ? this.currentUser.user.fingerPrint
          : 'director',
      );
      const params = {
        username: this.currentUser.user.cognitoID,
        query: text,
        // category: text || 'break',
        orderby: 'DEFAULT',
        choice,
        moreprefs: [],
        andprefs: {
          theme: this.prezentationDetails.theme || 'default',
          is_know: 'no',
        },
        limit: 30,
        skip: 0,
      };
      params.onlyids = false;

      const serialized = JSON.stringify(params);
      GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performSearch);
          if (result.statusCode === 200 && result && this.searchText === text) {
            const slidesList = [];
            result.body.payloads?.forEach((item) => {
              if (
                item.keytype === 'K_SLIDEDATA' &&
                item.prefs.is_know === 'no' &&
                item.permission !== 'Restricted'
              ) {
                slidesList.push({
                  ...item,
                  assetId: item.assetId || item.landscape,
                  name: item.display_name,
                  type: 'Slides',
                  category: item.name,
                  outline: item.title || item.outline || item.idea,
                  filename: item.filename,
                  uniqueID: item.unique_id,
                  isAdded: true,
                });
              }
            });
            this.gallerySlides = slidesList;
            this.componentkey += 1;
          }
          if (this.searchText === text) this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isNetworkError = true;
        })
        .finally(() => {
          if (this.searchText === text) this.isLoading = false;
        });

      // Leaving this to log an empty search
      AnalyticsHandler.performSlideSearch(this.currentUser, text);
      AnalyticsHandler.logUserStats(this.currentUser);
    },
    handleFiltersToggle() {
      this.isFiltersExpand = !this.isFiltersExpand;
      if (!this.isFiltersExpand) {
        this.$refs.slidesFilters.resetToDefaultIfApplyNotClicked();
      }
    },
    isloadingCallback(val) {
      this.isLoading = val;
    },
    handleFilteredSlides(slidesList, filterOptionCategory) {
      this.filterOptionCategory = filterOptionCategory;
      this.isFiltersExpand = false;
      this.searchText = '';
      this.gallerySlides = slidesList
        .filter((slide) => slide.permission !== 'Restricted')
        .map((slide) => ({
          ...slide,
          assetId: slide.assetId || slide.landscape,
          outline: slide.title || slide.outline || slide.idea,
          category: slide.categories ? slide.categories[0] : '',
          filename: slide.filename,
          uniqueID: slide.unique_id,
          isAdded: true,
        }));
    },
    setViewType(type) {
      this.viewType = type;
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGalleryViewClick(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_QUERY]: this.searchText,
          [TD_PLACEMENT]: 'Slide library',
          [TD_COMMON_COLUMN_NAME]: 'view selected',
          [TD_COMMON_COLUMN_VALUE]:
            this.viewType === 'grid' ? 'Row view' : 'Column view',
        });
      } else {
        trackPrezentationEvents.prezentationsGalleryViewClick(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_QUERY]: this.searchText,
            [TD_PLACEMENT]: 'Slides',
            [TD_COMMON_COLUMN_NAME]: 'view selected',
            [TD_COMMON_COLUMN_VALUE]:
              this.viewType === 'grid' ? 'Row view' : 'Column view',
          },
        );
      }
    },
    async handleSlidesSelect(e, slide) {
      if (e) {
        const obj = {
          ...slide,
          assetId: slide.assetId || slide.landscape,
          outline: slide.title || slide.outline || slide.idea,
          category: slide.categories ? slide.categories[0] : '',
          filename: slide.filename,
          uniqueID: slide.unique_id,
          thumbnail: slide.thumbnail || '',
          isAdded: true,
        };

        if (this.filterOptionCategory === 'uploaded') {
          obj.isUserUploaded = true;
        } else if (this.filterOptionCategory === 'generated') {
          obj.isGenerated = true;
          obj.source = 'generated';
        } else if (this.filterOptionCategory === 'comply') {
          obj.isComply = true;
          obj.source = 'comply';
        } else if (this.filterOptionCategory === 'synthesis') {
          obj.isSynthesis = true;
          obj.source = 'synthesis';
        } else if (this.filterOptionCategory === 'redesign') {
          obj.isRedesign = true;
          obj.source = 'redesign';
        }

        // Setting slide intermedietly to avoid displaying image caching
        this.slideData = obj;
        if (obj.assetId && !obj.thumbnail) {
          const thumbnailPath = obj.assetId;

          if (
            thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
            thumbnailPath.startsWith('/output/pptx/')
          ) {
            obj.thumbnail = await getThumbnail(thumbnailPath);
            if (obj.assetId.indexOf('.pdf') !== -1) {
              obj.thumbnail = await convertPdfToImage(obj.thumbnail);
            }
          } else {
            const tempThumbnail = await getFileURL(
              this.currentUser.user.cognitoID,
              thumbnailPath,
              this.currentUser.userIp,
              'IOCUpload',
            );
            if (this.handlePremiumFeatureSlides(obj)) {
              obj.thumbnail = tempThumbnail;
            }
            if (
              obj.prefs?.source === 'uploaded' ||
              obj.source === 'uploaded' ||
              obj.assetId.indexOf('.pdf') !== -1
            ) {
              obj.thumbnail = await convertPdfToImage(tempThumbnail);
            }
          }
        }
        this.selectedSlides.push(obj);
      } else {
        this.selectedSlides = this.selectedSlides.filter(
          (s) => s.assetId !== slide.assetId,
        );
      }
      this.selectedSlideObjRef = {};
      this.selectedSlides.forEach((s) => {
        this.selectedSlideObjRef[s.assetId] = true;
      });
    },
    handlePremiumFeatureSlides(obj) {
      return (
        obj.prefs?.source === 'generated' ||
        obj.source === 'generated' ||
        obj.prefs?.source === 'redesign' ||
        obj.source === 'redesign' ||
        obj.prefs?.source === 'synthesis' ||
        obj.source === 'synthesis' ||
        obj.prefs?.source === 'comply' ||
        obj.source === 'comply'
      );
    },
    handleSlideToAddClick(obj) {
      this.onAddSlidesToSlideNumber(obj, this.selectedSlides);
      this.selectedSlides = [];
      this.selectedSlideObjRef = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.slides-gallery-container {
  .view-icons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;

    .v-icon {
      margin-left: 20px;
    }
  }

  .slides-container {
    min-height: 200px;
    // max-height: 400px;
    // overflow: auto;
    .v-image {
      box-shadow: -1px -1px 4px 0px #0000001a;
      box-shadow: 1px 1px 4px 0px #0000001a;
      position: relative;
    }

    .checkbox {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      margin: 8px;
      padding: 0;
    }

    .checked {
      display: block;
    }

    .image-cell:hover {
      cursor: move;
      .checkbox {
        display: block;
      }
    }
  }

  .nodata {
    margin: 20px;
    text-align: center;

    img {
      max-width: 200px;
    }

    .text-btn {
      cursor: pointer;
      color: #21a7e0;
    }

    .not-found-descriptions {
      margin: 20px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .filters-section {
    margin: 15px 0;
    .filters-action {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      color: #21a7e0;

      .v-icon {
        color: #21a7e0;
      }
    }
  }

  .add-prezentation-btn {
    margin-top: -3px;
    letter-spacing: normal;
    box-shadow: none !important;
    font-size: 14px;
    height: 37px !important;
    color: #21a7e0;
    line-height: 2rem;
    margin-left: 5px;
    margin-bottom: 2px;
    text-transform: none !important;
    background-color: transparent !important;
  }
}
.menu-items-wrapper {
  max-height: 400px;
  overflow: auto;
  padding-bottom: 60px;
}
</style>
