const keywordMappingData = require('../../../utilities/ideas/keyword-tooltip.json');

module.exports = {
  getTooltipForKeyword: (keyword) => {
    const keywordmapping = keywordMappingData.data;
    if (!keyword) return '';
    // First checking for complete keyword match.
    /* To handle cases when search term is let say "sea" and
        keywordmapping = [
            {
              "keyword": "Market Research",
              "tooltip": "Share the market research results or approach"
            },
            {
              "keyword": "Sea",
              "tooltip": "Use water-inspired slide theme"
            }
        it should return "Use water-inspired slide theme" and not Market
        Research's tooltip. (Market Research includes sea)
        */
    const completeMatch = keywordmapping.find(
      (item) =>
        item.keyword.toLowerCase().trim() === keyword.toLowerCase().trim(),
    );
    if (completeMatch) {
      return completeMatch.tooltip;
    }
    // Checking if the searched keyword is substring of any item in keywordmapping array.
    const match = keywordmapping.find((item) =>
      item.keyword.toLowerCase().includes(keyword.toLowerCase().trim()),
    );
    if (match) {
      return match.tooltip;
    }

    return keyword;
  },
};
