var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"list-item-wrap"},'div',attrs,false),on),[_c('v-list-item',{class:("audience-item " + (_vm.status === 'Disabled' || _vm.isInComplete
            ? 'disabled-item'
            : 'clickable') + " " + (_vm.origin === 'profile' ? 'hyperlink-text' : '') + "  " + (_vm.fpVisibleToTeam ? '' : 'audi-fp-not-visible--auto') + " " + _vm.itemClass),style:(_vm.maxWidthStyle),attrs:{"disabled":_vm.status === 'Disabled' || _vm.isInComplete,"data-pendo-id":_vm.isFirstResult ? 'audience-item' : null}},[_c('v-list-item-avatar',{staticClass:"profile-avatar",attrs:{"size":_vm.size}},[(_vm.avatar && !_vm.isPublicLimited)?_c('v-img',{attrs:{"alt":(_vm.computedFullName + " avatar"),"src":_vm.avatar}}):(_vm.userInitials)?_c('div',[_c('v-avatar',{attrs:{"color":"rgb(88, 168, 222)","size":_vm.size}},[_c('span',{staticClass:"profile-avatar--initial"},[_vm._v(" "+_vm._s(_vm.userInitials)+" ")])])],1):_c('v-img',{attrs:{"alt":(_vm.computedFullName + " avatar"),"src":'/assets/img/non-member-dropdown-icon.svg'}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-name"},[_c('span',{staticClass:"mr-1 audience-title"},[_vm._v(" "+_vm._s(_vm.computedFullName)+" "),(_vm.getJobTitle)?_c('span',[_vm._v("·")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getJobTitle)+" ")]),(
                _vm.fingerprintType.toLowerCase() === 'standard' ||
                _vm.fingerprintType.toLowerCase() === 'zensense'
              )?_c('img',{attrs:{"src":"/assets/img/prezent_avatar.svg","alt":"","width":"22px"}}):_vm._e(),(_vm.isRecent)?_c('img',{staticClass:"mr-1",attrs:{"src":"/assets/img/history.svg","alt":"","width":"20px"}}):_vm._e(),(_vm.isTrending)?_c('img',{attrs:{"src":"/assets/img/trending-audience.svg","alt":"","width":"20px"}}):_vm._e()]),(_vm.fingerprintType === 'standard')?_c('v-list-item-subtitle',{staticClass:"text-email"},[(_vm.lastName && _vm.lastName.toLowerCase() === 'group')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.group'))+" "),_c('span',{staticClass:"dot"},[_vm._v("•")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('fingerprintvue.createdByPrezent'))+" ")]):_c('v-list-item-subtitle',{staticClass:"text-email"},[(!_vm.emailEntered && (_vm.isDifferentCompany || _vm.isPublicLimited))?_c('span',[_c('span',[_vm._v(_vm._s(_vm.companyName))])]):(_vm.email)?_c('span',[_vm._v(_vm._s(_vm.email))]):_vm._e(),(
                (_vm.email || _vm.isDifferentCompany) &&
                (_vm.creator || (_vm.isPrivate && _vm.isOwner))
              )?_c('span',{staticClass:"dot"},[_vm._v(" • ")]):_vm._e(),(_vm.isGroup && _vm.showDot)?_c('span',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationShareStatus.group')))]),_c('span',{staticClass:"dot"},[_vm._v("•")])]):_vm._e(),(_vm.creator)?_c('span',[_vm._v(_vm._s(_vm.$t('prezentationShareStatus.createdBy', { who: _vm.creator }))+" ")]):_vm._e(),(_vm.isPrivate && _vm.isOwner)?_c('span',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step1.createdBy'))+" "+_vm._s(_vm.$t('build.step1.me')))]),_c('span',{staticClass:"dot"},[_vm._v("•")]),(_vm.isShared)?_c('span',[_vm._v(_vm._s(_vm.$t('build.step1.shared')))]):_c('span',[_vm._v(_vm._s(_vm.$t('build.step1.private')))])]):_vm._e()])],1),_vm._t("default")],2)],1)]}}],null,true)},[_c('div',[_c('v-list-item',{staticClass:"audience-item"},[(_vm.status !== 'Disabled')?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-name"},[_c('span',{staticClass:"mr-1 audience-title"},[_vm._v(" "+_vm._s(_vm.computedFullName)+" "),(_vm.getJobTitle)?_c('span',[_vm._v("·")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getJobTitle)+" ")])]),(_vm.fingerprintType === 'standard')?_c('v-list-item-subtitle',{staticClass:"text-email"},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationShareStatus.fingerprintStatusText')))])]):_c('v-list-item-subtitle',{staticClass:"text-email"},[(!_vm.emailEntered && (_vm.isDifferentCompany || _vm.isPublicLimited))?_c('span',[_c('span',[_vm._v(_vm._s(_vm.companyName))])]):(_vm.email)?_c('span',[_vm._v(_vm._s(_vm.email))]):_vm._e(),(
              (_vm.email || _vm.isDifferentCompany) &&
              (_vm.creator || (_vm.isPrivate && _vm.isOwner))
            )?_c('span',{staticClass:"dot"},[_vm._v("•")]):_vm._e(),(_vm.isGroup && _vm.showDot)?_c('span',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationShareStatus.group')))]),_c('span',{staticClass:"dot"},[_vm._v("•")])]):_vm._e(),(_vm.creator)?_c('span',[_vm._v(_vm._s(_vm.$t('prezentationShareStatus.createdBy', { who: _vm.creator }))+" ")]):_vm._e(),(_vm.isPrivate && _vm.isOwner)?_c('span',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step1.createdBy'))+" "+_vm._s(_vm.$t('build.step1.me')))]),_c('span',{staticClass:"dot"},[_vm._v("•")]),(_vm.isShared)?_c('span',[_vm._v(_vm._s(_vm.$t('build.step1.shared')))]):_c('span',[_vm._v(_vm._s(_vm.$t('build.step1.private')))])]):_vm._e()])],1):_c('div',{staticClass:"disabled-tooltip"},[_c('p',{staticClass:"text-name"},[_vm._v("User account disabled")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }