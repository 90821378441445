import { trackCommonEvent } from './MatomoTrackingDataHelper';

const REDESIGN_EVENT_CATEGORY = 'Redesign';
const REDESIGN_ICON_CLICK = 'Redesign_icon-click';
const REDESIGN_UPLOAD = 'Redesign_upload';
const REDESIGN_UPLOAD_REDESIGN_START = 'Redesign_upload_redesign';
const REDESIGN_UPLOAD_REDESIGN_NEXT = 'Redesign_upload_redesign_Next';
const REDESIGN_UPLOAD_REDESIGN_FINAL = 'Redesign_upload_redesign_Next_redesign';
const REDESIGN_AUDIENCE_CHANGE = 'Redesign_audience-changed';
const REDESIGN_TEMPLATE_SWITCHED = 'Redesign_template-switched';
const REDESIGN_LAYOUT_CHANGED = 'Redesign_layout-changed';
const REDESIGN_VISUALS_REPLACED = 'Redesign_visuals-replaced';
const REDESIGN_SLIDES_RETRY = 'Redesign_redesign-slide';
const REDESIGN_DOWNLOAD = 'Redesign_download';
const REDESIGN_CTA = 'Redesign_reredesign';
const REDESIGN_TUTORIAL = 'Redesign_tutorial-click';
const REDESIGN_TUTORIAL_LOAD = 'Redesign_tutorial-icon_tutorial-click';
const REDESIGN_FEEDBACK_CLICKED = 'Redesign_feedback-click';
const REDESIGN_FEEDBACK_SUBMITTED = 'Redesign_feedback-submitted';
const REDESIGN_UPLOAD_CANCEL = 'Redesign_upload_redesign_cancel';
const REDESIGN_UPLOAD_TEMPLATE_PAGE_CANCEL =
  'Redesign_upload_redesign_Next_cancel';
const REDESIGN_UPLOAD_FINAL_CANCEL =
  'Redesign_upload_redesign_Next_redesign_cancel';
const REDESIGN_SHOW_ORIGINAL = 'Redesign_show-original';
const REDSIGN_COLLAPSE_EXPAND = 'Redesign_maximize-minimize';
const REDESIGN_SHOW_REDESIGNED = 'Redesign_show-redesigned';
const REDESIGN_SMART_TOOLS_CLICK = 'Redesign_smart-tools-click';
const REDESIGN_SMART_LAYOUT_VIEW_CLICK = 'Redesign_smart-layout_view-click';
const REDESIGN_LEARN_MORE_CLICK = 'Redesign_learn-more_click';
const REDESIGN_SMART_TOOLS_CLOSE_CLICK = 'Redesign_smart-tools-cross-click';
const REDESIGN_FAVORITE = 'Redesign_favorite';
const REDESIGN_ADD_TO_LIBRARY = 'Redesign_add-to-library';
const REDESIGN_UPLOAD_REDESIGN_FAILED = 'Redesign_upload_redesign_failed';
const REDESIGN_USER_SWITCHED = 'Redesign_user-switched';

export const trackRedesignEvents = {
  redesignCheckerIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUpload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadStart(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_REDESIGN_START,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_REDESIGN_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadRedesignFinal(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_REDESIGN_FINAL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignAudienceChange(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_AUDIENCE_CHANGE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignTemplateSwitched(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_TEMPLATE_SWITCHED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignLayoutChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_LAYOUT_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignReplaceVisual(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_VISUALS_REPLACED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignSlideRetry(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_SLIDES_RETRY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignCTA(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_CTA,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignTutorial(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_TUTORIAL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignTutorialLoad(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_TUTORIAL_LOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignFeedbackClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_FEEDBACK_CLICKED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignFeedbackSubmitted(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_FEEDBACK_SUBMITTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadTemplatePageCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_TEMPLATE_PAGE_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadFinalCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_FINAL_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignShowOriginal(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_SHOW_ORIGINAL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignCollapseExpand(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDSIGN_COLLAPSE_EXPAND,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignShowRedesigned(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_SHOW_REDESIGNED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignSmartToolsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_SMART_TOOLS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignSmartLayoutViewClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_SMART_LAYOUT_VIEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignLearnMore(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_LEARN_MORE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignSmartToolsClose(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_SMART_TOOLS_CLOSE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignAddToFavourite(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_FAVORITE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignAddToLibrary(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_ADD_TO_LIBRARY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUploadRedesignFailed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_UPLOAD_REDESIGN_FAILED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  redesignUserTabSwitch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: REDESIGN_EVENT_CATEGORY,
        eventAction: REDESIGN_USER_SWITCHED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
