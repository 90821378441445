<template>
  <div translate="no" class="language-switch" v-if="isLocalizationEnabled">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="flag-btn"
          text
          v-bind="attrs"
          v-on="on"
          data-pendo-id="switch-language"
        >
          <span :class="`fi fi-${selectedLanguage.flag}`"></span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="option in localizationOptions"
          :key="option.code"
          @click="selectOption(option)"
        >
          <span :class="`fi fi-${option.flag}`"></span>&nbsp;
          <span> {{ option.title }}</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { trackProfileEvents } from './common/Analytics/ProfileEvents';
import usersApi from '../API/users-api';

export default {
  name: 'SwitchLocale',
  data() {
    return {
      selectedLanguage: { code: 'en', title: 'English (US)', flag: 'us' },
      localizationOptions: [
        { code: 'en', title: 'English (US)', flag: 'us' },
        { code: 'ja', title: '日本語', flag: 'jp' },
        // { code: 'in', title: 'India' },
      ],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isLocalizationEnabled: (state) => {
      if (state.currentUser) {
        const feature = state.currentUser.features.find(
          (f) => f.feature_name === 'localization',
        );
        return feature && feature.enabled;
      }
      return false;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'currentUser.user.language': function (val) {
      const locale = this.localizationOptions.find((lang) => lang.code === val);
      this.selectedLanguage = locale;
    },
  },
  mounted() {
    if (this.currentUser?.user?.language) {
      const locale = this.localizationOptions.find(
        (lang) => lang.code === this.currentUser?.user?.language,
      );
      this.selectOption(locale, 'mount');
    }
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    selectOption(selectedLanguage, origin) {
      this.selectedLanguage = selectedLanguage;
      this.$i18n.locale = selectedLanguage.code;
      this.handleSaveProfile(origin);
    },
    handleSaveProfile(origin) {
      this.setCurrentUser({
        ...this.currentUser,
        user: {
          ...this.currentUser.user,
          language: this.selectedLanguage.code,
        },
      });
      if (origin !== 'mount') {
        usersApi.methods
          .updateUserProfile({ language: this.selectedLanguage.code })
          .then((value) => {
            if (this.currentUser.user?.jobTitle !== value?.jobTitle) {
              trackProfileEvents.profileBasicJobtitleChanged(this.currentUser);
            }
            if (this.currentUser.user?.industry !== value?.industry) {
              trackProfileEvents.profileBasicIndustryChanged(this.currentUser);
            }
            if (this.currentUser.user?.jobFunction !== value?.jobFunction) {
              trackProfileEvents.profileBasicJobfuntionChanged(
                this.currentUser,
              );
            }
            const userInfo = this.currentUser;
            userInfo.user = value;
            this.setCurrentUser(userInfo);
            trackProfileEvents.profileBasicSaveProfile(this.currentUser);
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
<style lang="scss">
.language-switch {
  display: flex;
  justify-content: center;
  width: 60px;

  .flag-btn {
    height: 20px !important;
    padding: 0 !important;
    min-width: 24px !important;

    .v-btn__content {
      font-size: 18px;
    }

    .fi {
      border: 1px solid #ededed;
    }
  }

  select {
    width: 150px;
    line-height: 49px;
    height: 38px;
    font-size: 16px;
    outline: 0;
    margin-bottom: 15px;
  }
}
</style>
