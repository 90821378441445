<template>
  <div class="wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="full-width">
      <div :class="`content-wrapper`">
        <h2 class="header">
          {{
            isTrialUser
              ? 'Your trial has expired'
              : 'Your subscription has expired'
          }}
        </h2>
        <div class="image-wrapper">
          <v-carousel
            cycle
            height="auto"
            hide-delimiters
            :show-arrows="false"
            :interval="3000"
            light
            class="tip-img"
            v-model="currentBanner"
          >
            <v-carousel-item
              v-for="(banner, index) in banners"
              :key="index"
              eager
            >
              <v-img
                :src="banner"
                min-height="265px"
                max-height="265px"
                eager
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="banner-text">
          <h3>Get more out of Prezent</h3>
          <p>{{ getCurrentBannerText() }}{{ getSubHeaderText() }}</p>
        </div>
        <div class="carousel-nav-section">
          <img
            @click="setCurrentBanner(bIndex)"
            :src="
              currentBanner === bIndex
                ? `/assets/img/trial/dot-indicator-active.svg`
                : `/assets/img/trial/dot-indicator-inactive.svg`
            "
            alt
            v-for="(banner, bIndex) in banners"
            :key="bIndex"
          />
          <!-- <v-btn class="nav-dots" :id="bIndex" icon> </v-btn> -->
        </div>
        <template>
          <!-- <div class="content-text" v-if="!trialDaysLeft">
            {{ $t('subscriptionExpired.contentText') }}
            {{
              isTrialUser
                ? $t('subscriptionExpired.freeTrial')
                : $t('subscriptionExpired.subscription')
            }}
            {{ $t('subscriptionExpired.hasExpired') }}.
          </div>
          <div class="content-text" v-else>
            {{ $t('subscriptionExpired.freeTrialEnds') }}{{ trialDaysLeft }}
            {{ $t('subscriptionExpired.days') }}.
          </div>
          <div class="content-text">
            {{ $t('subscriptionExpired.wouldYou')
            }}{{
              isTrialUser
                ? $t('subscriptionExpired.subscribe')
                : $t('subscriptionExpired.renew')
            }}{{ $t('subscriptionExpired.continue') }}
            <span class="primary-color">{{
              $t('subscriptionExpired.zen')
            }}</span
            >{{ $t('subscriptionExpired.experience') }}?
          </div> -->
        </template>
        <div class="action-wrapper">
          <v-btn
            class="primary-button"
            rounded
            color="#21a7e0"
            height="48"
            min-width="93"
            @click="handleSubscribeClick"
            v-if="
              this.currentUser &&
              this.currentUser.user &&
              this.currentUser.user.role &&
              this.currentUser.user.role !== 'User'
            "
          >
            {{
              isTrialUser
                ? $t('trialAndSubscribeBanner.upgrade')
                : $t('subscriptionExpired.renewNow')
            }}
          </v-btn>
          <p class="user-cta-text" v-else>
            {{ $t('profile.reachOutAdminToRenew') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { sendEmail } from '../../utils/api-helper';
import ECommerceActionsApi from '../../API/ecommerce-actions-api.js';
import ContactSoonModal from './ContactSoonModal.vue';
import { trackTrialsEvents } from './Analytics/TrialsEvents';
import { TD_ROLE } from './Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SubscriptionExpired',
  components: {},
  props: {
    trialDaysLeft: {
      type: Number,
      required: true,
    },
    isTrialUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      banners: [
        '/assets/img/trial/Generate-unlimited-slides-or-prezentations-in-your-brand-templates.svg',
        '/assets/img/trial/Download-unlimited-slides-in-your-brand-templates.svg',
        '/assets/img/trial/Make-unlimited-slides-and-decks-brand-compliant.svg',
        '/assets/img/trial/Create-unlimited-prezentations-in-your-brand-templates.svg',
        '/assets/img/trial/Redesign unlimited slides and decks in your brand templates.svg',
        '/assets/img/trial/Download unlimited Best Practice Examples.svg',
        '/assets/img/trial/Synthesize-unlimited-slides-and-decks.svg',
      ],
      currentBanner: 0,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    async handleSubscribeClick() {
      if (
        this.currentUser.user.role === 'Admin' ||
        this.currentUser.user.role === 'TrialUser'
      ) {
        this.$router.push('/profile?tab=admin#subscribe', () => {});
        this.$modal.hide('SubscriptionExpired', { close: true });
      } else {
        const successParams = {
          from: [],
          type: 'renewal-success-request',
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            email: this.currentUser.user.id,
          },
        };
        const adminParams = {
          from: [],
          type: 'renewal-admin-request',
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            email: this.currentUser.user.id,
            teamID: this.currentUser.user.teamID,
          },
        };
        this.$modal.hide('SubscriptionExpired', { close: true });
        this.$modal.show(
          ContactSoonModal,
          {},
          {
            name: 'ContactSoonModal',
            width: '449px',
            height: '503px',
          },
        );
        await Promise.all([sendEmail(successParams), sendEmail(adminParams)]);
      }
      ECommerceActionsApi.methods
        .registerAction({
          operation: 'create-lead',
          callAction: 'renew',
        })
        .catch((err) => {
          console.log(err);
        });
      if (this.isTrialUser) {
        const otherData = {
          [TD_ROLE]: this.currentUser?.user?.role,
        };
        trackTrialsEvents.subscriptionExpiryPopupUpgradeClick(
          this.currentUser,
          otherData,
        );
      } else {
        const otherData = {
          [TD_ROLE]: this.currentUser?.user?.role,
        };
        trackTrialsEvents.subscriptionExpiryPopupRenewClick(
          this.currentUser,
          otherData,
        );
      }
    },
    handleClose() {
      localStorage.setItem('trialDaysLeftLastShown', this.trialDaysLeft);
      this.$modal.hide('SubscriptionExpired');
    },
    getCurrentBannerText() {
      switch (this.currentBanner) {
        case 0:
          return this.$t('trialAndSubscribeBanner.generatePopupBanner');
        case 1:
          return this.$t('trialAndSubscribeBanner.slidePopupBanner');
        case 2:
          return this.$t('trialAndSubscribeBanner.complyPopupBanner');
        case 3:
          return this.$t('trialAndSubscribeBanner.prezentationPopupBanner');
        case 4:
          return this.$t('trialAndSubscribeBanner.redesignUnlimitedSlides');
        case 5:
          return this.$t(
            'trialAndSubscribeBanner.downloadBestPracticeExamples',
          );
        case 6:
          return this.$t('trialAndSubscribeBanner.synthesisPopupBanner');
        default:
          return '';
      }
    },
    getSubHeaderText() {
      if (
        this.currentUser &&
        this.currentUser.user &&
        this.currentUser.user.role === 'Admin'
      ) {
        return this.$t('trialAndSubscribeBanner.renewNow');
      }
      if (this.isTrialUser) {
        return this.$t('trialAndSubscribeBanner.upgradeNow');
      }
      return null;
    },
    setCurrentBanner(index) {
      this.currentBanner = index;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.primary-color {
  color: #20a7e0;
}
.content-text {
  font-size: 25px;
  margin: 20px 0px;
}
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.image-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.full-width {
  width: 100%;
}
.content-wrapper {
  padding: 24px;
  width: 100%;
  height: 540px;
  position: relative;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  .header {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    // margin-bottom: 34px;
    text-align: start;
  }
  .description {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 34px;
  }
  .content-panel {
    margin-bottom: 15px;
    .content-label {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  .action-wrapper {
    display: flex;
    justify-content: center;
    button {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: white;
      text-transform: none;
    }
    .user-cta-text {
      margin-top: 5px;
      height: 10px;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
    }
  }
  .align-btn-left {
    flex-direction: row;
  }
}

.carousel-nav-section {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
  // margin-top: 40px;
  > img {
    cursor: pointer;
  }
}

.banner-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  > p {
    font-size: 16px;
  }
}
</style>
