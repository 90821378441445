<template>
  <div>
    <div
      class="bannerContainer"
      v-if="
        banners.length > 0 &&
        currentUser.user &&
        currentActiveSubscription &&
        currentTheme &&
        banners.length > 0
      "
    >
      <v-carousel
        hide-delimiters
        cycle
        :show-arrows="false"
        :interval="15000"
        :key="carouselKey"
      >
        <v-carousel-item v-for="(bnn, ind) in banners" :key="ind">
          <component
            :is="bnn.component"
            class="banner"
            :upgradeFromBanner="onUpgradeClick"
            v-if="bnn.key === 'trial_sub_banner'"
          />
          <component
            :is="bnn.component"
            class="banner"
            :componentKey="bnn.key"
            v-else-if="bnn.key.includes('temp-designer')"
          />
          <component :is="bnn.component" class="banner" v-else />
        </v-carousel-item>
      </v-carousel>
    </div>
    <!-- 
      moving the impelmentation of upgrade popup from TrialAndSubscriptionBanner to this component
      as TrialAndSubscriptionBanner is not mounted on the first load of BannersCarousel compoennt
    !-->
    <div class="popup-banner" v-if="showUpgradePopup">
      <div class="banner-header">
        <span class="popup-banner-title">
          {{ $t('trialAndSubscribeBanner.bannerHeader') }}
        </span>
        <span>
          <v-icon @click="handleCloseModal"> mdi-close </v-icon>
        </span>
      </div>
      <div class="banner-image">
        <v-img :src="popupIllustration"></v-img>
      </div>
      <div class="banner-message" id="banner-message">
        {{ popupMessage }} {{ $t('trialAndSubscribeBanner.upgradeNow') }}
      </div>
      <!-- <div class="banner-message">
       
      </div> -->
      <div class="banner-action">
        <v-btn
          medium
          color="primary"
          rounded
          @click="onUpgradeClick(popupPlacement, 'popup')"
        >
          {{ $t('profile.upgrade') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { getFeatureUsage } from '../../utils/api-helper';
import ComplyNativeBanner from './ProductBanners/ComplyNativeBanner.vue';
import GenerateNativeBanner from './ProductBanners/GenerateNativeBanner.vue';
import RedesignNativeBanner from './ProductBanners/RedesignNativeBanner.vue';
import SynthesizeNativeBanner from './ProductBanners/SynthesizeNativeBanner.vue';
import SubscriptionExpiredBanner from './ProductBanners/SubscriptionExpiredBanner.vue';
import TrialAndSubscriptionBanner from './ProductBanners/TrialAndSubscriptionBanner.vue';
import TemplateDesignerCompleteBanner from './ProductBanners/TemplateDesignerCompleteBanner.vue';
import TemplateDesignerProgressBanner from './ProductBanners/TemplateDesignerProgressBanner.vue';
import TemplateDesignerSelectBanner from './ProductBanners/TemplateDesignerSelectBanner.vue';
import TemplateDesignerAddBanner from './ProductBanners/TemplateDesignerAddBanner';
import { trackTrialsEvents } from './Analytics/TrialsEvents';
import { TD_PLACEMENT, TD_ROLE } from './Analytics/MatomoTrackingDataHelper';
import { designerTemplateLimit } from '../../pages/components/TemplateDesigner/constants.js';

export default {
  name: 'BannersCarousel',
  components: {},
  data() {
    return {
      popupIllustration: '',
      popupMessage: '',
      popupPlacement: '',
      carouselKey: 0,
    };
  },
  watch: {
    currentUser() {
      const { pathname } = location;
      if (
        pathname.includes('/home/slides') ||
        // pathname.includes('/home/prezentations') ||
        pathname.includes('/home/best-practice-library') ||
        pathname.includes('/home/main') ||
        pathname.includes('/profile') ||
        pathname.includes('/home/fingerprint') ||
        pathname.includes('/home/learn')
      ) {
        this.setUpgradeBanner(
          this.currentUser.user.role.toLowerCase() === 'trialuser',
        );
      }
    },

    // eslint-disable-next-line func-names
    'currentUser.user.language': function () {
      this.upgradePopupTypeHandler(this.upgradePopupType);
    },

    upgradePopupType(val) {
      this.upgradePopupTypeHandler(val);
    },

    async $route() {
      this.setUpgradePopup({ upgradePopup: false, popupType: '' });
    },

    banners(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.carouselKey++;
      }
      this.setBannerActive(
        newVal.length > 0 &&
          this.currentUser.user &&
          this.currentActiveSubscription &&
          this.currentTheme,
      );
    },
  },
  props: {},
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'currentActiveSubscription',
      'limitsInfoObj',
      'showUpgradePopup',
      'upgradePopupType',
    ]),
    ...mapState('prezentationDetails', ['selectedMainAction']),
    ...mapGetters('users', ['doUserHavePremiumSubscription']),
    ...mapGetters('templateDesigner', [
      'getTDData',
      'getTDDataAsc',
      'getFirstTDData',
    ]),

    daysleft() {
      if (
        this.currentActiveSubscription !== null &&
        this.currentUser !== null
      ) {
        const endDate = Date.parse(this.currentActiveSubscription.endDate);
        const dayslft = Math.floor(
          (endDate - new Date()) / (1000 * 60 * 60 * 24),
        );
        return dayslft;
      }
      return 0;
    },

    isComplyFullAccessBannerCheck() {
      const cmpFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'comply',
      );
      return (
        cmpFeature &&
        cmpFeature.enabled &&
        cmpFeature.access_level &&
        cmpFeature.access_level === 'full_access'
      );
    },

    isGenerateFullAccessBannerCheck() {
      const genFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'slide-generate',
      );
      return (
        genFeature &&
        genFeature.enabled &&
        genFeature.access_level &&
        genFeature.access_level === 'full_access'
      );
    },

    isRedesignAccessibleBannerCheck() {
      const redesignFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'restricted_access')
      );
    },

    isSynthesizeFullAccessBannerCheck() {
      const synFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'synthesis',
      );
      return (
        synFeature &&
        synFeature.enabled &&
        synFeature.access_level &&
        synFeature.access_level === 'full_access'
      );
    },
    // handle multiple TD banners
    formTDBanners() {
      const tdBanners = [];
      if (
        this.currentUser?.company?.isNonBranded &&
        this.currentUser.user.role.toLowerCase() === 'trialuser'
      ) {
        const tdData = this.getTDDataAsc; // TD Data in ascending order
        if (tdData.length) {
          tdData.forEach((item) => {
            if (item.status === 'inprogress' && item.percentage < 100) {
              // TemplateDesignerProgressBanner
              tdBanners.push({
                component: TemplateDesignerProgressBanner,
                key: `temp-designer_prg_banner_uuid_${item.uuid}`,
              });
            } else if (
              item.status === 'completed' &&
              (item.banners.templateCompletionBanner || 0) === 0
            ) {
              // TemplateDesignerCompleteBanner
              tdBanners.push({
                component: TemplateDesignerCompleteBanner,
                key: `temp-designer_cmp_banner_uuid_${item.uuid}`,
              });
            } else if (
              item.status === 'completed' &&
              item.banners.templateCompletionBanner > 0 &&
              (item.banners.templateSelectionBanner || 0) === 0
            ) {
              // TemplateDesignerSelectBanner
              tdBanners.push({
                component: TemplateDesignerSelectBanner,
                key: `temp-designer_sel_banner_uuid_${item.uuid}`,
              });
            }
          });
        }
        // TemplateDesignerAddBanner does not depend on status and should be shown when there is one or no Designer Templates
        if (
          tdData.length < designerTemplateLimit &&
          (this.getFirstTDData.banners?.templateAddBanner || 0) === 0
        ) {
          tdBanners.push({
            component: TemplateDesignerAddBanner,
            key: `temp-designer_add_banner_${0}`,
          });
        }
      }
      return tdBanners;
    },

    banners() {
      const { pathname } = location;
      let items = [];

      items = [
        ...this.formTDBanners,

        // TrialAndSubscriptionBanner

        (pathname.includes('/home/redesign') &&
          this.limitsInfoObj?.redesign?.feature &&
          !this.limitsInfoObj?.redesign?.isFullAccess) ||
        (pathname.includes('/home/synthesis') &&
          this.limitsInfoObj?.synthesis?.feature &&
          !this.limitsInfoObj?.synthesis?.isFullAccess) ||
        (pathname.includes('/home/search') &&
          this.currentUser?.user?.role === 'TrialUser' &&
          (this.limitsInfoObj?.slide?.feature ||
            this.limitsInfoObj['bpe-slide']?.feature)) ||
        (pathname.includes('/home/learn') &&
          this.currentUser?.user?.role === 'TrialUser' &&
          this.limitsInfoObj?.course?.feature) ||
        (pathname.includes('/home/template-converter') &&
          this.limitsInfoObj?.comply?.access_level === 'restricted_access') ||
        (pathname.includes('/home/generate') &&
          this.limitsInfoObj?.generate?.access_level === 'restricted_access') ||
        (pathname.includes('/home/best-practice-library') &&
          this.currentUser?.user?.role === 'TrialUser' &&
          this.limitsInfoObj['bpe-slide']?.feature) ||
        (pathname.includes('/home/slides') &&
          this.currentUser?.user?.role === 'TrialUser' &&
          this.limitsInfoObj?.slide?.feature) ||
        ((pathname.includes('/home/myPrezentation') ||
          pathname.includes('/home/build') ||
          pathname.includes('/home/best-practice-library-decks')) &&
          ((this.selectedMainAction.id === 'convertTemplate' &&
            this.limitsInfoObj?.comply?.access_level === 'restricted_access') ||
            (this.selectedMainAction.id === 'synthesize' &&
              this.limitsInfoObj?.synthesis?.access_level ===
                'restricted_access'))) ||
        ((pathname.includes('/home/prezentations') ||
          pathname.includes('/home/myPrezentation')) &&
          this.currentUser?.user?.role === 'TrialUser' &&
          this.limitsInfoObj?.slide?.feature) ||
        (pathname.includes('/home/build') &&
          this.currentUser?.user?.role === 'TrialUser' &&
          this.limitsInfoObj?.slide?.feature) ||
        ((pathname.includes('/home/main') ||
          pathname.includes('/profile') ||
          pathname.includes('/home/fingerprint')) &&
          this.currentUser?.user?.role === 'TrialUser') ||
        ((pathname.includes('/home/overnight-prezentations') ||
          pathname.includes('/home/my-overnight-prezentation')) &&
          ((this.limitsInfoObj?.op?.access_level === 'full_access' &&
            this.currentUser.user.role.toLowerCase() === 'trialuser') ||
            (this.limitsInfoObj?.op?.access_level === 'restricted_access' &&
              this.currentUser.user.role.toLowerCase() !== 'trialuser') ||
            ((this.limitsInfoObj?.slide?.feature ||
              this.limitsInfoObj?.op?.feature) &&
              this.currentUser.user.role.toLowerCase() === 'trialuser')))
          ? // this.showTrialAndSubBanner()
            {
              component: TrialAndSubscriptionBanner,
              key: 'trial_sub_banner',
            }
          : {},

        // SubscriptionExpiredBanner
        this.currentUser.user.role.toLowerCase() !== 'trialuser' &&
        this.currentActiveSubscription &&
        (this.currentActiveSubscription.stripeSubscriptionID ||
          !this.currentActiveSubscription.stripeSubscriptionID) &&
        (this.daysleft <= 0 || this.daysleft <= 60)
          ? {
              component: SubscriptionExpiredBanner,
              key: 'sub_exp_banner',
            }
          : {},

        // ComplyNativeBanner
        this.$route.path.includes('compliance') &&
        this.currentUser.user.complyBanner === -1 &&
        this.currentUser.user.role.toLowerCase() !== 'trialuser' &&
        this.isComplyFullAccessBannerCheck &&
        this.doUserHavePremiumSubscription
          ? {
              component: ComplyNativeBanner,
              key: 'cmp_ntv_banner',
            }
          : {},

        // GenerateNativeBanner
        this.$route.path.includes('generate') &&
        this.currentUser.user.generateBanner === -1 &&
        this.currentUser.user.role.toLowerCase() !== 'trialuser' &&
        this.isGenerateFullAccessBannerCheck &&
        this.doUserHavePremiumSubscription
          ? {
              component: GenerateNativeBanner,
              key: 'gen_ntv_banner',
            }
          : {},

        // RedesignNativeBanner
        this.$route.path.includes('redesign') &&
        this.currentUser.user.redesignBanner === 0 &&
        this.isRedesignAccessibleBannerCheck
          ? {
              component: RedesignNativeBanner,
              key: 'rdsgn_ntv_banner',
            }
          : {},

        // SynthesizeNativeBanner
        this.$route.path.includes('synthesis') &&
        this.currentUser.user.synthesizeBanner === -1 &&
        this.currentUser.user.role.toLowerCase() !== 'trialuser' &&
        this.isSynthesizeFullAccessBannerCheck &&
        this.doUserHavePremiumSubscription
          ? {
              component: SynthesizeNativeBanner,
              key: 'syn_ntv_banner',
            }
          : {},
      ].filter((item) => Object.keys(item).length > 0);

      if (items.length === 2) {
        return [...items, ...items];
      }
      return items;
    },
  },
  methods: {
    ...mapActions('users', [
      'setLimitData',
      'setUpgradePopup',
      'setUpgradeBanner',
      'setBannerActive',
    ]),

    // showTrialAndSubBanner() {
    //   const { pathname } = location;
    //   const show =
    //     (pathname.includes('/home/redesign') &&
    //       this.limitsInfoObj?.redesign?.feature &&
    //       !this.limitsInfoObj?.redesign?.isFullAccess) ||
    //     (pathname.includes('/home/synthesis') &&
    //       this.limitsInfoObj?.synthesis?.feature &&
    //       !this.limitsInfoObj?.synthesis?.isFullAccess) ||
    //     (pathname.includes('/home/search') &&
    //       this.currentUser?.user?.role === 'TrialUser' &&
    //       (this.limitsInfoObj?.slide?.feature ||
    //         this.limitsInfoObj['bpe-slide']?.feature)) ||
    //     (pathname.includes('/home/learn') &&
    //       this.currentUser?.user?.role === 'TrialUser' &&
    //       this.limitsInfoObj?.course?.feature) ||
    //     (pathname.includes('/home/template-converter') &&
    //       this.limitsInfoObj?.comply?.access_level === 'restricted_access') ||
    //     (pathname.includes('/home/generate') &&
    //       this.limitsInfoObj?.generate?.access_level === 'restricted_access') ||
    //     (pathname.includes('/home/best-practice-library') &&
    //       this.currentUser?.user?.role === 'TrialUser' &&
    //       this.limitsInfoObj['bpe-slide']?.feature) ||
    //     (pathname.includes('/home/slides') &&
    //       this.currentUser?.user?.role === 'TrialUser' &&
    //       this.limitsInfoObj?.slide?.feature) ||
    //     ((pathname.includes('/home/prezentations') ||
    //       pathname.includes('/home/myPrezentation')) &&
    //       this.currentUser?.user?.role === 'TrialUser' &&
    //       this.limitsInfoObj?.slide?.feature) ||
    //     (pathname.includes('/home/build') &&
    //       this.currentUser?.user?.role === 'TrialUser' &&
    //       this.limitsInfoObj?.slide?.feature) ||
    //     ((pathname.includes('/home/main') ||
    //       pathname.includes('/profile') ||
    //       pathname.includes('/home/fingerprint')) &&
    //       this.currentUser?.user?.role === 'TrialUser') ||
    //     (pathname.includes('/home/overnight-prezentations') &&
    //       ((this.limitsInfoObj?.op?.access_level === 'full_access' &&
    //         this.currentUser.user.role.toLowerCase() === 'trialuser') ||
    //         (this.limitsInfoObj?.op?.access_level === 'restricted_access' &&
    //           this.currentUser.user.role.toLowerCase() !== 'trialuser') ||
    //         ((this.limitsInfoObj?.slide?.feature ||
    //           this.limitsInfoObj?.op?.feature) &&
    //           this.currentUser.user.role.toLowerCase() === 'trialuser')));
    //   return show;
    // },

    handleCloseModal() {
      this.setUpgradePopup({ upgradePopup: false, popupType: '' });
    },

    onUpgradeClick(placement, type = 'popup') {
      this.setUpgradePopup({ upgradePopup: false, popupType: '' });
      this.$router.push('/profile?tab=admin#subscribe', () => {});
      this.$root.$emit('upgrade-clicked');
      if (type === 'banner') {
        const otherData = {
          [TD_PLACEMENT]: placement,
          [TD_ROLE]: this.currentUser?.user?.role,
        };
        trackTrialsEvents.upgradeBannerOnTopClick(this.currentUser, otherData);
      } else {
        const otherData = {
          [TD_PLACEMENT]: placement,
          [TD_ROLE]: this.currentUser?.user?.role,
        };
        trackTrialsEvents.upgradePopupClick(this.currentUser, otherData);
      }
    },

    upgradePopupTypeHandler(val) {
      if (
        (val === 'slide' &&
          this.currentUser.user.role.toLowerCase() === 'trialuser') ||
        val === 'op-slide'
      ) {
        if (val === 'slide') {
          this.popupPlacement = 'Slide Library';
        } else {
          this.popupPlacement = 'Overnight Prezentations';
        }
        this.popupIllustration =
          '/assets/img/trial/Download-unlimited-slides-in-your-brand-templates.svg';
        this.popupMessage = this.$t('trialAndSubscribeBanner.slidePopupBanner');
      } else if (
        (val === 'prezentation' &&
          this.currentUser.user.role.toLowerCase() === 'trialuser') ||
        val === 'op-prezentation'
      ) {
        if (window.location.href.includes('/home/build')) {
          this.popupPlacement = 'Story Builder';
        } else if (val === 'prezentation') {
          this.popupPlacement = 'Prezentation Library';
        } else {
          this.popupPlacement = 'Overnight Prezentations';
        }
        this.popupIllustration =
          '/assets/img/trial/Create-unlimited-prezentations-in-your-brand-templates.svg';
        this.popupMessage = this.$t(
          'trialAndSubscribeBanner.prezentationPopupBanner',
        );
      } else if (
        (val === 'bpe-slide' || val === 'bpe-deck') &&
        this.currentUser.user.role.toLowerCase() === 'trialuser'
      ) {
        this.popupPlacement = 'Best Practice Library';
        this.popupIllustration =
          '/assets/img/trial/Download-unlimited-slides-in-your-brand-templates.svg';
        this.popupMessage = this.$t('trialAndSubscribeBanner.bpePopupBanner');
      } else if (val === 'comply') {
        this.popupPlacement = 'Compliance Checker';
        this.popupIllustration =
          '/assets/img/trial/Make-unlimited-slides-and-decks-brand-compliant.svg';
        this.popupMessage = this.$t(
          'trialAndSubscribeBanner.complyPopupBanner',
        );
      } else if (val === 'generate') {
        this.popupPlacement = 'Auto Generator';
        this.popupIllustration =
          '/assets/img/trial/Generate-unlimited-slides-or-prezentations-in-your-brand-templates.svg';
        this.popupMessage = this.$t(
          'trialAndSubscribeBanner.generatePopupBanner',
        );
      } else if (val === 'synthesis') {
        this.popupPlacement = 'Synthesis';
        this.popupIllustration =
          '/assets/img/trial/Synthesize-unlimited-slides-and-decks.svg';
        this.popupMessage = this.$t(
          'trialAndSubscribeBanner.synthesisPopupBanner',
        );
      } else if (val === 'redesign') {
        this.popupPlacement = 'Redesign';
        this.popupIllustration =
          '/assets/img/trial/Redesign-unlimited-slides-and-decks-in-your-brand-templates.svg';
        this.popupMessage = this.$t(
          'trialAndSubscribeBanner.redesignPopupBanner',
        );
      } else {
        this.popupIllustration = '';
        this.popupMessage = '';
      }
    },
  },
  async beforeMount() {
    await getFeatureUsage()
      .then((response) => {
        if (response?.data) {
          this.setLimitData(response.data);
        } else {
          this.setLimitData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setLimitData([]);
      });
  },
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.bannerContainer {
  height: 48px;
  background: #e1f5fb;
  // box-shadow: 1px 1px 4px 0px #0000001a;
  ::v-deep .v-window {
    height: 100% !important;
    .v-window__container {
      height: 100% !important;
      .v-image {
        height: 100% !important;
      }
    }
  }
}
.popup-banner {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 11;
  background-color: white;
  box-shadow: 0px 0px 6px 0px #00000029;
  padding: 24px;
  border-radius: 12px;
  gap: 16px;
  width: 728px;
  height: 512px;

  .banner-header {
    display: flex;
    justify-content: space-between;
    .popup-banner-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .banner-image {
    margin: 10px 0;
  }

  .banner-action {
    margin-top: 20px;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    button {
      letter-spacing: normal;
      font-weight: bold;
    }
  }

  .banner-message {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  button {
    text-transform: capitalize;
    margin-top: -5px;
  }
}
</style>
