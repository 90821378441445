<template>
  <div class="branded-temp-popup-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="branded-temp-popup-wrapper-content">
      <div class="branded-temp-popup-image-wrapper">
        <img
          src="/assets/img/templateDesign/template-ready.svg"
          alt
          width="342px"
          height="276px"
        />
      </div>
      <div class="branded-temp-popup-desc">
        <p>{{ $t('templateDesign.brandedTemplateReady') }}</p>

        {{ $t('templateDesign.checkItOut') }}
      </div>
      <div class="button-container">
        <v-btn @click="handleExploreTemp" elevation="3" class="next">
          {{ $t('templateDesign.exploreTemplates') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import usersApi from '../../API/users-api';

export default {
  name: 'BrandedTemplatePopup', // When you upgrade from a non-branded to a branded company
  computed: {
    ...mapState('users', ['currentUser', 'showBrandedCompanyPopUp']),
  },
  methods: {
    handleClose() {
      this.$modal.hide('BrandedTemplatePopup');
    },
    handleExploreTemp() {
      this.$router.push('/profile?tab=templates', () => {});
      this.handleClose();
    },
  },
  async mounted() {
    const userData = {
      performIncrements: {
        showBrandedCompanyPopUp: 1,
      },
    };
    await usersApi.methods
      .updateUserProfile(userData)
      .then((updatedUser) => {
        const userInfo = this.currentUser;
        userInfo.user = updatedUser;
        this.setCurrentUser(userInfo);
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style lang="scss" scoped>
.branded-temp-popup-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .branded-temp-popup-wrapper-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .branded-temp-popup-image-wrapper {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    width: fit-content;
  }

  .branded-temp-popup-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 11px;
  }

  .desc {
    margin-bottom: 15px;
  }

  .branded-temp-popup-desc {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    p {
      margin-bottom: 10px;
    }
  }
}

.button-container {
  padding: 20px;
  margin: 5px;
  .next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 22px;
    gap: 8px;

    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}
</style>
