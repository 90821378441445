import { trackCommonEvent } from './MatomoTrackingDataHelper';

const COMPLY_EVENT_CATEGORY = 'Compliance Checker';
const COMPIANCE_CHECKER_ICON_CLICK = 'compliance-checker_icon-click';
const COMPLIANCE_CHECKER_UPLOAD = 'compliance-checker_upload';
const COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE =
  'compliance-checker_upload_check-compliance';
const COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE_NEXT =
  'compliance-checker_upload_check-compliance_next';
const COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE_NEXT_CHECK_COMPLIANCE =
  'compliance-checker_upload_check-compliance_next_check-compliance';
const COMPLIANCE_CHECKER_REVIEW_DETAILS = 'compliance-checker_review-details';
const COMPLIANCE_CHECKER_MAKE_100_COMPLIANT =
  'compliance-checker_make-100-compliant';
const COMPLIANCE_CHECKER_DOWNLOAD = 'compliance-checker_download';
const COMPLIANCE_CHECKER_RECHECK_COMPLIANCE =
  'compliance-checker_recheck-compliance';
const COMPLIANCE_CHECKER_CHANGE_TEMPLATE = 'compliance-checker_change-template';
const COMPLIANCE_CHECKER_FEEDBACK_CLICK = 'compliance-checker_feedback-click';
const COMPLIANCE_CHECKER_FEEDBACK_SUBMITTED =
  'compliance-checker_feedback-submitted';
const COMPLIANCE_CHECKER_SHOW_MARKERS_TOGGLE =
  'compliance-checker_show-markers_toggle';
const COMPLIANCE_CHECKER_CANCEL = 'compliance-checker_Cancel';
const COMPLIANCE_CHECKER_REVIEW_DETAILS_CATEGORY_ACCEPT =
  'compliance-checker_review-details_Category-Accept';
const COMPLIANCE_CHECKER_REVIEW_DETAILS_CATEGORY_REJECT =
  'compliance-checker_review-details_Category-Reject';
const COMPLIANCE_CHECKER_REVIEW_DETAILS_SUGGESTION_ACCEPT =
  'compliance-checker_review-details_Suggestion-Accept';
const COMPLIANCE_CHECKER_REVIEW_DETAILS_SUGGESTION_REJECT =
  'compliance-checker_review-details_Suggestion-Reject';
const COMPLIANCE_CHECKER_REVIEW_DETAILS_ACCEPT_SLIDE_SUGGESTIONS =
  'compliance-checker_review-details_accept-slide-suggestions';
const COMPLIANCE_CHECKER_COLOR_CHANGED = 'compliance-checker_color-changed';
const COMPLIANCE_CHECKER_IMAGE_CHANGED = 'compliance-checker_Image-changed';
const COMPLIANCE_CHECKER_MARKER_CLICK = 'compliance-checker_marker-click';
const COMPLIANCE_CHECKER_SMART_TOOLS_CLICK =
  'compliance-checker_smart-tools-click';
const COMPLIANCE_CHECKER_COMPLIANCE_SUGGESTIONS_CLICK =
  'compliance-checker_compliance-suggestions-click';
const COMPLIANCE_CHECKER_TUTORIAL_ICON_CLICK =
  'compliance-checker_tutorial-icon-click';
const COMPLIANCE_CHECKER_ICON_TUTORIAL_CLICK =
  'compliance-checker_tutorial-icon_tutorial-click';
const COMPLIANCE_CHECKEER_SUGGESTION_CHANGE_RESET =
  'Compliance-checkeer-suggestion-change-reset';
const COMPLIANCE_CHECKER_FAVORITE_CLICK = 'compliance-checker_favorite-click';
const COMPLIANCE_CHECKER_ADD_TO_LIBRARY_CLICK =
  'compliance-checker_add-to-library_click';
const COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE_FAILED =
  'compliance-checker_upload_check-compliance_failed';
const COMPLIANCE_CHECKER_USER_SWITCHED = 'Compliance-checker_user-switched';

export const trackComplyEvents = {
  complianceCheckerIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPIANCE_CHECKER_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerUpload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_UPLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerUploadCheckCompliance(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerUploadCheckComplianceNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerUploadCheckComplianceNextCheckCompliance(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction:
          COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE_NEXT_CHECK_COMPLIANCE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerReviewDetails(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_REVIEW_DETAILS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerMakeHundredCompliant(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_MAKE_100_COMPLIANT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerRecheckCompliance(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_RECHECK_COMPLIANCE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerChangeTemplate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_CHANGE_TEMPLATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerFeedbackClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_FEEDBACK_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerFeedbackSubmitted(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_FEEDBACK_SUBMITTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerShowMarkersToggle(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_SHOW_MARKERS_TOGGLE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerReviewDetailsCategoryAccept(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_REVIEW_DETAILS_CATEGORY_ACCEPT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerReviewDetailsCategoryReject(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_REVIEW_DETAILS_CATEGORY_REJECT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerReviewDetailsSuggestionAccept(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_REVIEW_DETAILS_SUGGESTION_ACCEPT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerReviewDetailsSuggestionReject(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_REVIEW_DETAILS_SUGGESTION_REJECT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerReviewDetailsAcceptSlideSuggestions(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_REVIEW_DETAILS_ACCEPT_SLIDE_SUGGESTIONS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerColorChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_COLOR_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerImageChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_IMAGE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerMarkerClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_MARKER_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerSmartToolsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_SMART_TOOLS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerComplianceSuggestionsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_COMPLIANCE_SUGGESTIONS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerTutorialIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_TUTORIAL_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerTutorialPlay(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_ICON_TUTORIAL_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerSuggestionChangeReset(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKEER_SUGGESTION_CHANGE_RESET,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerAddToFavourite(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_FAVORITE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerAddToLibrary(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_ADD_TO_LIBRARY_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerUploadCheckComplianceFailed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_UPLOAD_CHECK_COMPLIANCE_FAILED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  complianceCheckerUserTabSwitch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: COMPLY_EVENT_CATEGORY,
        eventAction: COMPLIANCE_CHECKER_USER_SWITCHED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
