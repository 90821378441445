<template>
  <div
    class="editUserSlideTypeContainer"
    :style="{
      height: `${mainSlideHeight ? `${mainSlideHeight}px` : '100%'}`,
    }"
    :key="componentkey"
  >
    <div class="readMode" v-if="!editMode">
      <div class="name">
        <div class="label">{{ $t('profile.name') }}</div>
        <div :class="slideName && slideName !== '' ? 'text' : 'unavailable'">
          {{
            slideName && slideName !== ''
              ? slideName
              : $t('slides.addNameToSlide')
          }}
        </div>
      </div>
      <div class="description">
        <div class="label">{{ $t('build.step3.description') }}</div>
        <div
          :class="description && description !== '' ? 'text' : 'unavailable'"
        >
          {{
            description && description !== ''
              ? description
              : $t('slides.addDescription')
          }}
        </div>
      </div>
      <div class="template">
        <div class="label">{{ $t('overnightPresentations.template') }}</div>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="!template || Object.keys(template) <= 0"
          :size="20"
          :width="3"
        />
        <div class="text" v-else>{{ template.name }}</div>
      </div>
      <div class="audience">
        <div class="label">{{ $t('overnightPresentations.audience1') }}</div>
        <AudienceSelectorTypesense
          v-model="audience"
          :isViewOnly="true"
          origin="slide-detail"
        />
      </div>
      <div class="nodeCount">
        <div class="label">{{ $t('generate.nodeCount') }}</div>
        <div class="nodeCountSelection">{{ nodeCount }}</div>
      </div>
      <div
        class="businessIdea"
        v-if="
          slideType === 'userUploadedSlide' || slideType === 'userComplySlide'
        "
      >
        <div class="label">{{ $t('slides.businessIdea') }}</div>
        <div
          class="ideaListWrapper"
          v-if="businessIdeas && businessIdeas.length > 0"
        >
          <div
            class="ideaItems"
            v-for="(idea, index) in businessIdeas"
            :key="index"
          >
            <v-chip class="ma-2">
              {{ idea.name }}
            </v-chip>
          </div>
        </div>
        <div class="ideaListUnavailable" v-else>
          {{ $t('slides.addOneBusinessIdea') }}
        </div>
      </div>
      <div class="keywords">
        <div class="label">{{ $t('userUploadPrezentation.keywords') }}</div>
        <div class="keywordsList" v-if="keywords && keywords.length > 0">
          <div
            class="keywordContainer"
            v-for="(keyword, index) in keywords"
            :key="index"
          >
            <v-chip class="ma-2">
              {{ keyword.name }}
            </v-chip>
          </div>
        </div>
        <div class="keywordsListUnavailable" v-else>
          {{ $t('userUploadPrezentation.addKeywordsToSlide') }}
        </div>
      </div>
    </div>
    <div class="editMode" v-else>
      <v-form ref="form" lazy-validation>
        <div class="nameEdit" v-if="!disableEdit">
          <div class="label">{{ $t('profile.name') }}</div>
          <v-text-field
            v-model="slideName"
            :rules="[rules.required, rules.nameRuleMin, rules.nameRuleMax]"
          />
        </div>
        <div class="name" v-else>
          <div class="label">{{ $t('profile.name') }}</div>
          <div class="text">{{ slideName }}</div>
        </div>
        <div class="descriptionEdit">
          <div class="label">{{ $t('build.step3.description') }}</div>
          <v-text-field
            v-model="description"
            :rules="[rules.descriptionRule]"
          />
        </div>
        <div class="templateEdit">
          <div class="label">{{ $t('overnightPresentations.template') }}</div>
          <v-autocomplete
            :value="template"
            :items="themes"
            return-object
            :placeholder="$t('tagSlides.templatePlaceholder')"
            item-text="name"
            class="templates-dropdown"
            dense
            :search-input.sync="templateSearch"
            :attach="true"
            :menu-props="{
              maxHeight: '400',
              padding: '0px',
              rounded: 'lg',
            }"
            solo
            @change="handleClickTheme"
            :rules="[rules.required]"
          >
            <template v-slot:item="{ item }">
              <div
                class="template-list-item"
                :style="{
                  'background-color': isCurrentSelected(item.code)
                    ? '#F5FCFF'
                    : '',
                }"
              >
                <div class="template-header">
                  <v-tooltip
                    top
                    max-width="200"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on }">
                      <div class="template-title" v-on="on">
                        {{ item.name }}
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <div
                    v-if="isCurrentSelected(item.code)"
                    class="current-selection"
                  >
                    <v-tooltip
                      top
                      max-width="200"
                      content-class="tooltip-content"
                      fixed
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          {{ $t('tagSlides.Current selection') }}
                        </div>
                      </template>
                      <span>{{ $t('tagSlides.Current selection') }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-row class="mb-2">
                  <v-col class="available-theme-img">
                    <img :src="item.thumbnailURL" alt />
                  </v-col>
                  <v-col class="example-slide-img">
                    <img
                      :src="
                        item.sampleSlide
                          ? item.sampleSlideURL
                          : item.thumbnailURL
                      "
                      alt
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
            <template v-slot:no-data>
              <div class="no-templates-fount">
                <p class="no-result-text">
                  {{ $t('tagSlides.noResultFound') }}
                </p>
              </div>
            </template>
            <template v-slot:append-item>
              <div class="tip-bar">
                <p>
                  {{ $t('tagSlides.templateProTipText') }}
                </p>
              </div>
            </template>
          </v-autocomplete>
          <div
            :class="
              !template || !(template && Object.keys(template).length > 0)
                ? 'templateRequiredError'
                : 'templateEditWarning'
            "
          >
            {{
              `${
                slideDataForEdit &&
                slideDataForEdit.templateCode &&
                template &&
                template.code &&
                slideDataForEdit.templateCode !== template.code
                  ? $t('slides.defaultTemplateUpdated', { name: template.name })
                  : ''
              }`
            }}
            {{
              `${
                !template || !(template && Object.keys(template).length > 0)
                  ? $t('slides.required')
                  : ''
              }`
            }}
          </div>
        </div>
        <div class="audienceEdit">
          <div class="label">{{ $t('overnightPresentations.audience1') }}</div>
          <AudienceSelectorTypesense
            v-model="audience"
            :isTextFieldStyle="true"
            :showSearchIcon="false"
            :placeholder="$t('fingerprint.compareFingAud.searchByNameOrEmail')"
            :maxWidth="300"
            origin="slide-detail"
            @add="handleAddAudience($event)"
          />
          <div class="audienceEditWarning">
            {{
              `${
                slideDataForEdit &&
                slideDataForEdit.audience &&
                audience &&
                audience.email &&
                slideDataForEdit.audience !== audience.email
                  ? $t('slides.defaultAudienceUpdated', {
                      name: audience.fullName,
                    })
                  : ''
              }`
            }}
          </div>
        </div>
        <div class="nodeCountEdit">
          <div class="label">{{ $t('slides.howManyNodesDoesSlideHave') }}</div>
          <div class="nodeCountSelectionEdit">
            <div
              v-for="(node, i) in nodesList"
              :key="i"
              class="nodeCountContainer"
              @click="nodeCount = node"
            >
              <div
                :class="`${
                  node === nodeCount
                    ? 'nodeCountSelected'
                    : 'nodeCountAvailable'
                }`"
              >
                {{ node }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="businessIdeaEdit"
          v-if="
            slideType === 'userUploadedSlide' || slideType === 'userComplySlide'
          "
        >
          <div class="label">{{ $t('slides.businessIdea') }}</div>
          <v-combobox
            v-model="searchIdea"
            :items="filteredIdeaGallery"
            :loading="isLoadingIdeaSuggestions"
            @keyup="(e) => searchIdeaWithDebounce(e)"
            @change="handleIdeaSelect"
            :open-on-clear="true"
            @click:clear="() => (filteredIdeaGallery = [])"
            class="search-input"
            solo
            :attach="true"
            dense
            clearable
            hide-selected
            hide-details
            item-text="idea"
            item-value="category"
            :label="$t('build.step2.searchIdeaLabel')"
            :placeholder="$t('build.step2.searchIdeaLabel')"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('build.step2.noResultText') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #prepend-inner>
              <v-chip v-for="(item, i) in businessIdeas" :key="i">
                <template #default>
                  <div class="ideaContent">{{ item.name }}</div>
                  <v-icon
                    class="cancelIcon"
                    small
                    v-if="!disableEdit || item.isNew === true"
                    @click="handleIdeaRemove(item.name, i)"
                  >
                    {{ 'mdi-close' }}
                  </v-icon>
                </template>
              </v-chip>
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ item.idea }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <div class="ideasError" v-if="getIdeaError() !== ''">
            {{ $t(getIdeaError()) }}
          </div>
        </div>
        <div class="keywordsEdit">
          <div class="label">
            {{ $t('slides.suggestionsToAddKeywords') }}
          </div>
          <p class="subText">
            {{ $t('slides.suggestionsOnSlideContents') }}
          </p>
          <div class="keywordsEditorContainer">
            <v-text-field
              v-model="newKeyword"
              @keyup.enter="addNewKeyword"
              :placeholder="$t('slides.typeOther')"
            >
              <template #prepend-inner>
                <v-chip v-for="(keyword, i) in keywords" :key="i">
                  <template #default>
                    <div class="keywordContent">{{ keyword.name }}</div>
                    <v-icon
                      class="cancelIcon"
                      small
                      v-if="!disableEdit || keyword.isNew === true"
                      @click="handleKeywordRemove(keyword.name, i)"
                    >
                      {{ 'mdi-close' }}
                    </v-icon>
                  </template>
                </v-chip>
              </template>
              <template #append v-if="newKeyword.length > 0">
                <v-icon @click="newKeyword = ''">{{ 'mdi-close' }}</v-icon>
              </template>
            </v-text-field>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { mapState, mapGetters, mapActions } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import AudienceSelectorTypesense from './AudienceSelectorTypesense.vue';
import {
  performAutoComplete,
  // performSearch,
} from '../../graphql/queries';
import { GraphQLService } from '../../services/GraphQLService';
import ReminderDialog from '../Main/MyPrezentation/ReminderDialog.vue';
import { AddNewAudienceModalProps } from '../Main/AddNewAudience/AddNewAudienceModalProps';
import { TD_AUDIENCE_ORIGIN_SLIDES } from './Analytics/MatomoTrackingDataHelper';
import AddCollegueDetail from '../Main/NewPrezentation/AddCollegueDetail.vue';

export default {
  name: 'EditUserSlideType',
  components: {
    AudienceSelectorTypesense,
  },
  data() {
    return {
      componentkey: 0,
      slideName: '',
      audience: {},
      description: '',
      template: {},
      businessIdeas: [],
      nodeCount: 0,
      keywords: [],
      nodesList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      newKeyword: '',
      templateSearch: '',
      searchIdea: '',
      filteredIdeaGallery: [],
      isLoadingIdeaSuggestions: false,
      rules: {
        required: (value) => !!value || this.$t('slides.required'),
        nameRuleMin: (v) =>
          v.length >= 5 ||
          this.$t('warnings.aMinimumCharactersRequiredForSlide'),
        nameRuleMax: (v) =>
          v.length <= 200 || this.$t('warnings.aMaxCharactersRequiredForslide'),
        descriptionRule: (v) =>
          v.length <= 200 ||
          this.$t('warnings.maxCharactersSupportedForSlideDescription'),
        keywordsRule: () =>
          this.keywords.length > 0 ||
          this.$t('warnings.minimumOneKeywordShouldBeProvided'),
      },
      isDataChanged: false,
      businessIdeasInitial: [],
      keywordsInitial: [],
    };
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    mainSlideHeight: {
      type: Number,
      default: 100,
    },
    slideType: {
      type: String,
      default: '',
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
      default: '',
    },
    getAcceptButtonState: {
      type: Function,
      default: () => {},
    },
    getDataForSlideUpdate: {
      type: Function,
      default: () => {},
    },
    slideDataForEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('users', ['themes', 'currentUser', 'currentTheme']),
    ...mapGetters('slidesStore', ['getSlidesSnapshot', 'getSlideViewOrigin']),

    disableEdit() {
      return !this.isOwner;
    },

    disableAccept() {
      if (
        this.slideName.length < 5 ||
        this.slideName.length > 100 ||
        this.description.length > 200 ||
        !this.template ||
        (this.template && Object.keys(this.template) <= 0) ||
        !this.audience ||
        (this.audience && Object.keys(this.audience) <= 0) ||
        ((this.slideType === 'userUploadedSlide' ||
          this.slideType === 'userComplySlide') &&
          this.businessIdeas.length <= 0) ||
        ((this.slideType === 'userUploadedSlide' ||
          this.slideType === 'userComplySlide') &&
          this.businessIdeas.length > 5) ||
        this.nodeCount <= 0 ||
        !this.isDataChanged
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('slidesStore', ['setIsSlideDetailsDirty']),

    handleAddAudience(val) {
      if (val && !val.fingerPrint) {
        this.openRemindModal(val);
      } else {
        this.$modal.show(
          AddCollegueDetail,
          {
            addColleague: this.addColleague,
            origin: TD_AUDIENCE_ORIGIN_SLIDES,
          },
          AddNewAudienceModalProps,
        );
      }
    },
    openRemindModal(currentAudience) {
      const isUser = !!this.currentUser.user;
      this.$forceUpdate();
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: !!isUser,
          origin: 'slides',
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    getIdeaError() {
      if (this.businessIdeas.length <= 0) {
        return 'warnings.minimumOfOneBusinessIdea';
      }
      if (this.businessIdeas.length > 0 && this.businessIdeas.length <= 5) {
        return '';
      }
      return 'warnings.maximumOfFiveBusinessIdeas';
    },

    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },

    handleIdeaSelect(value) {
      if (value && typeof value !== 'string') {
        this.businessIdeas.push({
          ...value,
          name: value.idea,
          isNew: true,
        });
      }
    },

    searchIdeaWithDebounce(e) {
      this.searchIdeas(e, this);
    },

    searchIdeas: debounce((e, thisInstance) => {
      let text = null;
      if (typeof e === 'string') {
        text = e;
      } else {
        text = e.target.value;
      }

      if (text === undefined || text === null || text.length === 0) {
        thisInstance.filteredIdeaGallery = [];
        thisInstance.isLoadingIdeaSuggestions = false;
        return;
      }

      if (thisInstance.isLoadingIdeaSuggestions) {
        return;
      }

      if (text && text.length > 1) {
        thisInstance.isLoadingIdeaSuggestions = true;
        const params = {
          username: thisInstance.currentUser.user.cognitoID, // "pz_rath_admin",
          query: text,
          company: thisInstance.currentUser.company.name.toLowerCase(),
          limit: 10,
          type: 'keywords',
          theme: thisInstance.currentUser?.theme?.code || 'default',
        };
        const serialized = thisInstance.serializeParam(params);

        GraphQLService.requestWithToken(
          graphqlOperation(performAutoComplete, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performAutoComplete);
            const matchedItems = [];

            if (result?.statusCode === 200 && result?.body?.payloads) {
              result.body.payloads.forEach((item) => {
                if (item.keytype === 'K_CATEGORY') {
                  matchedItems.push({
                    idea: item.display_name,
                    category: item.name,
                  });
                }
              });

              thisInstance.filteredIdeaGallery = [...matchedItems];
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            thisInstance.isLoadingIdeaSuggestions = false;
          });
      }
    }, 500),

    addNewKeyword() {
      const kwrds = [...this.keywords];
      kwrds.push({ name: this.newKeyword, isNew: true });
      this.keywords = [...kwrds];
      this.newKeyword = '';
    },

    handleKeywordRemove(keyword, pos) {
      const existingKeywords = [...this.keywords];
      existingKeywords.splice(pos, 1);
      this.keywords = [...existingKeywords];
    },

    handleIdeaRemove(idea, pos) {
      const existingIdeas = [...this.businessIdeas];
      existingIdeas.splice(pos, 1);
      this.businessIdeas = [...existingIdeas];
    },

    handleClickTheme(val) {
      this.template = val;
    },

    isCurrentSelected(id) {
      return this.template && this.template.code === id;
    },

    setDefaultAudience() {
      this.audience = {
        defaultAudience: `${this.slideDataForEdit.audience}-${this.slideDataForEdit.audienceType}`,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'currentUser.user.language': function () {
      this.componentkey += 1;
    },
    disableAccept(val) {
      this.getAcceptButtonState(val);
    },
    slideDataForEdit(val) {
      this.slideName = val.name;
      this.setDefaultAudience();
      this.description = this.slideDataForEdit.summary;
      this.template = this.themes.find((thm) => thm.code === val.templateCode);
      this.businessIdeas = val.categories.map((bi) => ({
        name: bi,
        isNew: false,
      }));
      this.nodeCount = val.prefs.node;
      this.keywords = val.keywords.map((kw) => ({
        name: kw,
        isNew: false,
      }));
    },
    themes(val) {
      if (val && val.length > 0) {
        const tmplt = this.themes.find(
          (thm) => thm.code === this.slideDataForEdit.templateCode,
        );
        this.template = tmplt || this.currentTheme;
      }
    },
    $data: {
      handler() {
        const values = {
          slideName: this.slideName || '',
          audience: this.audience?.email || '',
          audienceType: this.audience?.type,
          audienceNumId: this.audience?.num_id,
          description: this.description || '',
          template: this.template?.code || '',
          businessIdeas:
            this.businessIdeas && this.businessIdeas.length > 0
              ? this.businessIdeas.map((itm) => itm.name)
              : [],
          nodeCount: Number(this.nodeCount || 0),
          keywords:
            this.keywords && this.keywords.length > 0
              ? this.keywords.map((itm) => itm.name)
              : [],
        };
        const loadedValues = {
          slideName: this.slideDataForEdit.name || '',
          audience: this.slideDataForEdit.audience || '',
          audienceType: this.slideDataForEdit?.audienceType || '',
          audienceNumId: this.audience?.num_id || '',
          description: this.slideDataForEdit.summary || '',
          template: this.slideDataForEdit.templateCode || {},
          businessIdeas: this.slideDataForEdit.categories || [],
          nodeCount: Number(this.slideDataForEdit?.prefs?.node || 0),
          keywords: this.slideDataForEdit.keywords || [],
        };
        this.getDataForSlideUpdate(values);
        if (_.isEqual(values, loadedValues) || !this.editMode) {
          this.isDataChanged = false;
          this.setIsSlideDetailsDirty(false);
        } else {
          this.isDataChanged = true;
          this.setIsSlideDetailsDirty(true);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.template = this.currentTheme;
    this.getAcceptButtonState(this.disableAccept);
    // audience will be pupulated from the slide object;
    this.slideName = this.slideDataForEdit.name;
    this.setDefaultAudience();

    this.description = this.slideDataForEdit.summary;
    if (this.themes && this.themes.length > 0) {
      const tmplt = this.themes.find(
        (thm) => thm.code === this.slideDataForEdit.templateCode,
      );
      this.template = tmplt || this.currentTheme;
    }
    this.businessIdeasInitial = this.slideDataForEdit?.categories;
    this.businessIdeas = this.slideDataForEdit.categories
      ? this.slideDataForEdit.categories.map((bi) => ({
          name: bi,
          isNew: false,
        }))
      : [];
    this.nodeCount = Number(this.slideDataForEdit.prefs.node);
    this.keywordsInitial = this.slideDataForEdit?.keywords;
    this.keywords = this.slideDataForEdit.keywords
      ? this.slideDataForEdit.keywords.map((kw) => ({
          name: kw,
          isNew: false,
        }))
      : [];
    if (this.$refs.form) {
      this.$refs.form.validate();
    }
  },
};
</script>

<style lang="scss" scoped>
.editUserSlideTypeContainer {
  overflow: auto;
  padding: 0px 16px 6px 0px;
  // box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  border-radius: 8px;
  .readMode {
    .name,
    .description,
    .template,
    .audience,
    .businessIdea,
    .nodeCount,
    .keywords {
      margin: 0px 0px 16px 0px;
      .label {
        font-size: 17px;
        margin: 0px 0px 4px 0px;
        font-weight: 600;
        line-height: 20px;
      }
      .text {
        font-size: 16px;
        word-wrap: break-word;
      }
      .unavailable {
        font-size: 16px;
        color: grey;
      }
    }

    .nodeCount {
      .nodeCountSelection {
        border-radius: 50%;
        width: 27px;
        height: 27px;
        background-color: #878d97;
        color: #ffffff;
        text-align: center;
        padding: 2px;
      }
    }

    .keywords {
      .keywordsList {
        display: flex;
        flex-wrap: wrap;
        .keywordContainer {
          .v-chip {
            margin: 4px !important;
            background: #ffffff;
            border: 1px solid grey;
          }
        }
      }
      .keywordsListUnavailable {
        font-size: 16px;
        color: grey;
      }
    }

    .businessIdea {
      .ideaListWrapper {
        display: flex;
        flex-wrap: wrap;
        .ideaItems {
          .v-chip {
            margin: 4px !important;
            background: #ffffff;
            border: 1px solid grey;
          }
        }
      }
      .ideaListUnavailable {
        font-size: 16px;
        color: grey;
      }
    }
  }
  .editMode {
    .name {
      margin: 0px 0px 16px 0px;
      .label {
        font-size: 17px;
        margin: 0px 0px 4px 0px;
        font-weight: 600;
        line-height: 20px;
      }
      .text {
        font-size: 16px;
      }
    }

    .nameEdit,
    .descriptionEdit,
    .templateEdit,
    .keywordsEdit {
      margin: 0px 0px 4px 0px;
      .label {
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
      }
    }

    .nameEdit,
    .descriptionEdit {
      .v-input {
        margin: 0 !important;
        padding: 4px 0px 0px 0px !important;
      }
    }

    .businessIdeaEdit {
      margin: 0px 0px 16px 0px;
      .label {
        font-weight: 600;
        font-size: 17px;
        margin: 0px 0px 16px 0px;
        line-height: 20px;
      }
      .search-input {
        ::v-deep .v-input__control {
          .v-input__slot {
            display: inline-block;
            box-shadow: none !important;
            border-bottom: 1px solid black !important;
            border-radius: 0 !important;
            padding: 0 !important;
            .v-input__prepend-inner {
              width: 100%;
              flex-wrap: wrap;
              .v-chip {
                margin: 4px;
                background-color: #ffffff;
                border: 1px solid #21a7e0;
                color: #21a7e0;
                .v-chip__content {
                  .v-icon {
                    color: #21a7e0;
                    padding: 6px 0px 4px 4px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .ideasError {
        color: #f70000;
        font-size: 12px;
      }
    }

    .audienceEdit {
      margin: 0px 0px 4px 0px;
      .label {
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
      }
      ::v-deep .audience-select {
        .audience-select-dropdown {
          margin: 0 !important;
          padding: 0 !important;
          min-height: auto;
          .v-input__control {
            .v-input__slot {
              margin: 0 !important;
              .v-select__slot {
                input {
                  font-size: 16px;
                }
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
      .audienceEditWarning {
        height: 22px;
        font-size: 12px;
        color: rgb(250 164 80);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .templateEdit {
      .templates-dropdown {
        flex: none;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 19px;
        ::v-deep .v-input__control {
          .v-input__slot {
            box-shadow: none !important;
            border-bottom: 1px solid black !important;
            border-radius: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
            font-size: 15px;
          }
          .v-text-field__details {
            display: none;
          }
        }

        .template-list-item {
          padding: 0 16px;
          .template-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            font-family: 'Lato';
            font-style: normal;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            height: 40px;
            margin-top: 10px;
            .template-title {
              color: #212121;
              width: 45%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .current-selection {
              color: #7b7b7b;
              width: 46%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .mb-2 {
            .available-theme-img,
            .example-slide-img {
              img {
                box-shadow: 0.413462px 0.413462px 1.65385px rgba(0, 0, 0, 0.1),
                  -0.413462px -0.413462px 1.65385px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
              }
            }
          }
        }
        .no-templates-fount {
          color: #000000;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          .no-result-text {
            border-bottom: 0.8px solid #b6b6b6;
            padding: 20px 0px 20px 20px;
          }
        }
        .tip-bar {
          position: sticky;
          bottom: 0;
          background: #ffffff;
          color: #7b7b7b;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          height: 54px;
          line-height: 17px;
          letter-spacing: 0.01em;
          p {
            height: 34px;
            padding: 15px;
          }
        }
      }
      .templateEditWarning {
        height: 22px;
        font-size: 12px;
        color: rgb(250 164 80);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .templateRequiredError {
        height: 22px;
        font-size: 12px;
        color: red;
      }
    }

    .nodeCountEdit {
      margin: 0px 0px 16px 0px;
      .label {
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
      }
      .nodeCountSelectionEdit {
        display: flex;
        justify-content: space-between;
        margin: 12px 0px 0px 0px;
        .nodeCountContainer {
          cursor: pointer;
          .nodeCountSelected {
            color: #ffffff;
            background-color: #21a7e0;
            border-radius: 50%;
            width: 27px;
            height: 27px;
            padding: 2px;
            text-align: center;
          }
          .nodeCountAvailable {
            width: 27px;
            height: 27px;
            padding: 2px;
            text-align: center;
          }
        }
      }
    }

    .keywordsEdit {
      .subText {
        margin: 0;
        font-size: 14px;
        color: #838383;
      }
      .keywordsEditorContainer {
        ::v-deep .v-input {
          .v-input__control {
            .v-input__slot {
              display: inline-block;
              .v-input__prepend-inner {
                width: 100%;
                flex-wrap: wrap;
                .v-chip {
                  margin: 4px;
                  background-color: #ffffff;
                  border: 1px solid #21a7e0;
                  color: #21a7e0;
                  .v-chip__content {
                    .v-icon {
                      color: #21a7e0;
                      padding: 6px 0px 4px 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
              .v-input__append-inner {
                position: absolute !important;
                right: 0 !important;
                bottom: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
