<template>
  <div class="processing-payment-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="success-payment">
      <div class="success-payment-img">
        <img src="/assets/img/profile/payment-success.svg" alt="" />
      </div>
      <div class="success-payment-title">Success!</div>
      <div class="success-payment-desc" v-if="numberOfLicenses > 0">
        {{
          `Your payment for  ${formatAmountWithCommas(
            numberOfLicenses,
          )} additional ${
            numberOfLicenses > 1 ? 'licenses' : 'license'
          } has been
        processed.`
        }}<br />You will receive a confirmation email shortly.
        <div class="success-payment-signout" v-if="isSignedOut">
          Please sign in to continue using Prezent.
        </div>
      </div>
      <div class="success-payment-desc" v-else>
        Your payment has been processed.<br />
        <template v-if="isSignedOut">
          Your subscription is now active.
          <div class="success-payment-signout" v-if="isSignedOut">
            Please sign in to continue using Prezent.
          </div>
        </template>
        <template v-else>
          Your Prezent subscription has been renewed.
          <div class="success-sub-text">
            We have emailed you the payment invoice
          </div>
          <v-btn
            class="primary-button"
            rounded
            color="#21a7e0"
            height="48"
            min-width="134"
            @click="handleClose()"
          >
            Continue using Prezent
          </v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPaymentDetail',
  props: {
    isSignedOut: {
      type: Boolean,
      default: false,
    },
    numberOfLicenses: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      console.log('numberOfLicenses', this.numberOfLicenses);
      this.$modal.hide('SuccessPaymentDetail');
    },

    formatAmountWithCommas(num) {
      if (num % 1 === 0)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return num
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.processing-payment-wrapper {
  width: 100%;
  height: 100%;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .success-payment {
    width: 100%;
    height: 100%;

    .success-payment-img {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: auto;
        margin: 65px auto 25px auto;
      }
    }

    .success-payment-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 20px;
    }

    .success-payment-signout {
      margin-top: 10px;
    }

    .success-payment-desc {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .success-sub-text {
      margin: 25px 0;
    }
  }
}
</style>
