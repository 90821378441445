<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 21C2.45 21 1.97917 20.8042 1.5875 20.4125C1.19583 20.0208 1 19.55 1 19V6H3V19H20V21H3ZM7 17C6.45 17 5.97917 16.8042 5.5875 16.4125C5.19583 16.0208 5 15.55 5 15V4C5 3.45 5.19583 2.97917 5.5875 2.5875C5.97917 2.19583 6.45 2 7 2H12L14 4H21C21.55 4 22.0208 4.19583 22.4125 4.5875C22.8042 4.97917 23 5.45 23 6V15C23 15.55 22.8042 16.0208 22.4125 16.4125C22.0208 16.8042 21.55 17 21 17H7ZM7 15H21V6H13.175L11.175 4H7V15Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PrezentationsExIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
