<template>
  <div class="modal-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="title">Welcome to Prezent!</div>
    <div class="modal-content">
      <div class="modal-content-vdo">
        <img
          src="/assets/img/onboarding-video-img.svg"
          alt
          width="200px"
          height="150px"
        />
        <div class="category-title">Product overview</div>
        <div class="category-subtitle">Watch our main features in action</div>
        <v-btn class="category-btn" @click="handleVideoClick">View video</v-btn>
        <div class="category-duration">Duration: 3-4 min</div>
      </div>
      <div class="modal-content-tour">
        <img
          src="/assets/img/onboarding-walkthrough-img.svg"
          alt
          width="200px"
          height="150px"
        />
        <div class="category-title">Guided walkthrough</div>
        <div class="category-subtitle">
          Take an interactive tour of our key features
        </div>
        <v-btn class="category-btn" @click="handleTourClick">Start tour</v-btn>
        <div class="category-duration">Duration: 1-2 min</div>
      </div>
    </div>
    <div class="modal-footer">
      <p>
        You can revisit the product overview video or other help resources any
        time by clicking the Help
        <v-icon size="20" style="padding-bottom: 4px">
          mdi-help-circle-outline
        </v-icon>
        icon at the bottom right corner of your screen
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserOnboardingModal',
  components: {},
  props: {
    handleModalCloseProp: {
      type: Function,
      default: () => ({}),
    },
    handleVideoOpenProp: {
      type: Function,
      required: true,
    },
    handleTourClickProp: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      openDialogue: false,
    };
  },
  methods: {
    handleClose() {
      this.handleModalCloseProp();
    },
    handleVideoClick() {
      this.handleVideoOpenProp();
    },
    handleTourClick() {
      this.handleTourClickProp();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  padding: 30px;
  //   width: 100%;
  //   height: 100%;
  //   position: relative;
  //   overflow-y: auto;
  //   background: white;
  //   display: flex;
  //   align-items: flex-start;
  //   flex-direction: column;
  //   justify-content: space-between;
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-bottom: 25px;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    gap: 25px;
    .modal-content-vdo,
    .modal-content-tour {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      padding: 20px;
      width: 50%;
      box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      img {
        height: -webkit-fill-available !important;
      }
      .category-title {
        font-family: 'Lato';
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        color: #000000;
      }
      .category-subtitle {
        font-family: 'Lato';
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #000000;
      }
      .category-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        gap: 8px;

        width: auto;
        height: 32px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: none;
        letter-spacing: normal;
        line-height: 19px;

        /* Color Styles (Old)/White */

        color: #ffffff;

        background: #21a7e0;
        border-radius: 25px;
      }
      .category-duration {
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        text-align: center;
        color: #a2a9b0;
      }
    }
  }
  .modal-footer {
    padding: 30px 0px;
    text-align: center;
    p {
      font-family: 'Lato';
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: #7b7b7b;
    }
  }
}
</style>
