<template>
  <div class="advanced-filter-options">
    <div class="filters-action reset-btn">
      <v-btn
        text
        color="primary"
        @click="handleResetFilters"
        :disabled="
          isAdvancedFilterChanged ||
          searchProgress ||
          (!this.filterType.isFavorite &&
            !this.filterType.isDownloaded &&
            slidesType === 'slides')
        "
      >
        {{ $t('prezentationsList.resetAllFilters') }}
      </v-btn>
    </div>

    <v-list>
      <v-list-group
        v-for="item in advancedItems"
        :key="item.title"
        v-model="item.active"
        no-action
      >
        <template v-slot:activator>
          <v-icon v-if="item.isIcon" color="#000">{{ item.icon }}</v-icon>
          <img
            v-else
            alt
            :src="`/assets/img/${item.icon}.svg`"
            width="24"
            height="24"
          />
          <v-list-item-content>
            <v-list-item-title
              class="advanced-filter-title"
              v-text="$t(item.display_title)"
            />
          </v-list-item-content>
        </template>
        <v-list-item dense v-for="child in item.items" :key="child.title">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-radio-group
                v-if="item.inputType === 'radio'"
                v-model="slidesType"
                :input-value="active"
                @change="handleSlidesTypeSelect(item)"
              >
                <v-radio
                  color="#20a7e0"
                  :label="$t(child.display_title)"
                  :value="child.name"
                  :disabled="searchProgress || child.disabled"
                >
                </v-radio>
              </v-radio-group>
              <v-checkbox
                v-else
                :disabled="searchProgress || child.disabled"
                :on-icon="'mdi-checkbox-marked-outline'"
                :off-icon="'mdi-checkbox-blank-outline'"
                :input-value="active"
                v-model="child.active"
                color="primary"
                :label="$t(child.display_title)"
                @click="handleCheckbox(item)"
              />
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-group>
    </v-list>
    <div class="filters-action apply-btn">
      <v-btn
        rounded
        color="primary"
        :loading="searchProgress"
        @click="handleApplyFilters"
        :disabled="isAdvancedFilterChanged || searchProgress"
      >
        {{ $t('slides.applyFilters') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { trackBuildEvents } from '../Analytics/BuildEvents';
import {
  getBuildPrezentationData,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FILTER_SELECTED,
  TD_PLACEMENT,
} from '../Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../Analytics/PrezentationEvents';

export default {
  props: {
    onFilteredSlides: {
      type: Function,
      default: () => ({}),
    },
    isloadingCallback: {
      type: Function,
      default: () => ({}),
    },
    onFilterApply: {
      type: Function,
      default: () => ({}),
    },
    prezentationDetails: {
      type: Object,
      default: () => ({}),
    },
    searchProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAdvancedFilterChanged: false,
      slidesType: 'slides',
      filterType: { isFavorite: false, isDownloaded: false },
      isFiltersAppliedClick: false,
      advanceFilterTitleChanged: [],
      advancedItems: [
        {
          items: [
            {
              title: 'Slides',
              display_title: 'prezentationGallery.slidesText',
              name: 'slides',
              active: true,
              disabled: false,
            },
            {
              title: 'Prezentations',
              display_title: 'prezentationGallery.decksText',
              name: 'prezentations',
              active: false,
              disabled: false,
            },
          ],
          title: 'Type',
          display_title: 'bestPracticesGuide.knowContainer.type',
          inputType: 'radio',
          active: true,
          icon: 'slide-type-slides',
        },
        {
          items: [
            {
              title: 'My Favorites',
              display_title: 'filters.myFavorites',
              name: 'favorites',
              active: false,
              disabled: false,
            },
          ],
          title: 'Favorites',
          display_title: 'slides.favourites',
          active: true,
          isIcon: true,
          icon: 'mdi-heart-outline',
        },
        {
          items: [
            {
              title: 'My Downloads',
              display_title: 'filters.myDownloads',
              name: 'downloaded',
              active: false,
              disabled: false,
            },
          ],
          title: 'Downloads',
          display_title: 'slides.downloads',
          active: true,
          isIcon: true,
          icon: 'mdi-tray-arrow-down',
        },
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    handleSlidesTypeSelect(item) {
      this.filterType = { isFavorite: false, isDownloaded: false };
      this.advancedItems[1].items[0].active = false;
      this.advancedItems[2].items[0].active = false;
      const index = this.advanceFilterTitleChanged.includes(item.title);
      if (!index) {
        this.advanceFilterTitleChanged.push(item.title);
      }
    },
    handleCheckbox(item) {
      this.filterType = {
        isFavorite: this.advancedItems[1].items[0].active,
        isDownloaded: this.advancedItems[2].items[0].active,
      };
      const index = this.advanceFilterTitleChanged.includes(item.title);
      if (!index) {
        this.advanceFilterTitleChanged.push(item.title);
      }
    },
    handleApplyFilters() {
      this.isFiltersAppliedClick = true;
      this.onFilterApply(this.slidesType, this.filterType);
      if (this.$route.name === 'myPrezentation.detail')
        trackPrezentationEvents.prezentationsGalleryFiltered(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_PLACEMENT]: 'Best Practice Library',
            [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: this.advanceFilterTitleChanged,
          },
        );
      else
        trackBuildEvents.buildGalleryFiltered(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Best Practice Library',
          [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: this.advanceFilterTitleChanged,
        });

      this.advanceFilterTitleChanged = [];
    },
    resetToDefaultIfApplyNotClicked() {
      if (!this.isFiltersAppliedClick) {
        this.handleResetFilters();
      }

      this.isFiltersAppliedClick = false;
    },
    handleResetFilters() {
      this.slidesType = 'slides';
      this.filterType = { isFavorite: false, isDownloaded: false };
      this.advancedItems[1].items[0].active = false;
      this.advancedItems[2].items[0].active = false;
      this.onFilterApply(this.slidesType, this.filterType);
      if (this.$route.name === 'myPrezentation.detail')
        trackPrezentationEvents.prezentationsGalleryFilterReset(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_PLACEMENT]: 'Best Practice Library',
          },
        );
      else
        trackBuildEvents.buildGalleryFilterReset(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Best Practice Library',
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-filter-options {
  // height: calc(100% - 100px);
  // overflow: auto;
  padding: 0px 0px 15px 0px;

  .filters-action {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    .v-btn {
      text-transform: none;
      float: right;
    }

    &.apply-btn {
      position: sticky;
      bottom: 0;
      right: 0;
      width: fit-content;
      float: right;

      .v-btn {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }
    }

    &.reset-btn {
      ::v-deep .v-btn__content {
        letter-spacing: normal;
      }
    }
  }
  ::v-deep .v-list-item {
    max-height: 40px !important;
    min-height: 40px !important;
  }
  ::v-deep .v-label {
    color: black;
    font-size: 17px;
    margin-left: 10px;
  }
  ::v-deep .v-input--selection-controls {
    margin-top: 0px !important;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 0 !important;
  }

  ::v-deep .v-list-group__items > .v-list-item {
    padding-left: 50px;
  }

  .customGroupWrapper {
    margin: 0 10px;

    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }

    .applyDatesCTA {
      width: 70%;
      margin: 0 auto;
      .v-btn {
        background: transparent;
        border: none;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        font-size: 16px;
        color: #21a7e0;
        width: 100%;
        background-color: transparent !important;
      }
    }

    .clearIcon:hover,
    .calendarIcon:hover {
      color: rgb(32, 167, 224);
    }
  }

  .advanced-filter-title {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 10px;
  }
}
</style>
