<template>
  <div class="synthesize-banner">
    {{ $t('synthesize.synthesizeBanner') }}
    <button @click="onDismissBetaBanner" class="dismiss-btn primary--text">
      {{ $t('generate.dismiss') }}
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import usersApi from '../../../API/users-api';

export default {
  name: 'SynthesizeNativeBanner',
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),

    onDismissBetaBanner() {
      const userData = {
        performIncrements: {
          synthesizeBanner: 1,
        },
      };
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.synthesize-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 8px 14px 16px;
  gap: 4px;
  height: 48px;
  // background: #e1f5fb;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .dismiss-btn {
    cursor: pointer;
  }
}
</style>
