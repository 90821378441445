<template>
  <div class="processing-payment-wrapper">
    <div class="processing-payment">
      <div class="processing-payment-title">Processing Payment</div>
      <div class="processing-payment-content">
        Do not close or refresh your browser page
      </div>
      <div class="processing-payment-loader">
        <moon-loader :loading="true" color="#21a7e0" />
      </div>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

export default {
  name: 'ProcessingPaymentDetail',
  components: {
    MoonLoader,
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('ProcessingPaymentDetail');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.processing-payment-wrapper {
  width: 100%;
  height: 100%;

  .processing-payment {
    width: 100%;

    .processing-payment-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 27px;
      margin-top: 53px;
    }

    .processing-payment-content {
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 27px;
    }

    .processing-payment-loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
