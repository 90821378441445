<template>
  <div :key="componentKey" class="slider-wrapper mx-4 my-2">
    <div v-if="title" class="slider-title">
      {{ title }}
    </div>
    <div class="content-wrapper row">
      <div
        class="col-sm-3 carousel-cell"
        v-for="(presentation, index) in filteredPrezentations"
        :key="index"
      >
        <div class="image-wrapper">
          <v-img
            :src="
              presentation.thumbnail ||
              '/assets/img/slides/placeholder-slide.svg'
            "
            alt="image"
            @click="handlePrezentationClick(presentation)"
            min-width="200"
            :aspect-ratio="16 / 9"
          />
          <div v-if="editable" class="additional-options">
            <div>
              <v-tooltip
                top
                max-width="200"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleEdit(index)"
                    id="edit-presentation--auto"
                  >
                    mdi-pencil-outline
                  </v-icon>
                </template>
                <span>Rename</span>
              </v-tooltip>
              <v-tooltip
                top
                max-width="200"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDuplicate(index)"
                    id="duplicate-presentation--auto"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip
                top
                max-width="200"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleDelete(index)"
                    id="delete-presentation--auto"
                  >
                    mdi-delete-outline
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <v-text-field
          v-if="editingIndex === index"
          :value="presentation.name"
          class="edit"
          @change="handleTextChange"
          autofocus
        />
        <div v-else class="prezentation-title">
          {{ presentation.name }}
        </div>
        <div class="type">
          <strong>Created On:</strong>
          {{ new Date(presentation.createdAt).toLocaleDateString() }}
        </div>
        <div class="type">
          <strong>Type:</strong>
          {{ presentation.type }}
        </div>
        <div class="creator" v-if="!editable && presentation.ownerID">
          <strong>Creator:</strong>
          {{ presentation.owner.firstName }}
          {{ presentation.owner.lastName }}
        </div>
        <div v-if="presentation.countNewComments" class="comment-wrapper">
          <div class="comment-icon" />
          <div class="comment-content">
            {{
              presentation.countNewComments > 1
                ? `${presentation.countNewComments} New Comments`
                : `${presentation.countNewComments} New Comment`
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="filteredPrezentations.length < prezentations.length"
      class="slides-action"
    >
      <v-btn
        rounded
        outlined
        color="#21a7e0"
        height="32"
        @click="handleLoadMorePrezentations"
      >
        See more results
      </v-btn>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';
import API from '../../utils/api';
import DeleteConfirmationModal from './DeleteConfirmation.vue';

export default {
  name: 'PresentationSlider',
  components: {},
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    gotoDetail: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    prezentations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      componentKey: 0,
      editingIndex: -1,
      deleteIndex: -1,
      dupIndex: -1,
      filteredPrezentations: [],
      pageSize: 8,
    };
  },
  methods: {
    handleLoadMorePrezentations() {
      if (this.filteredPrezentations.length < this.prezentations.length) {
        const startIndex = 0;
        const endIndex = Math.min(
          this.prezentations.length,
          this.filteredPrezentations.length + this.pageSize,
        );
        this.filteredPrezentations = this.prezentations.slice(
          startIndex,
          endIndex,
        );
        this.componentKey++;
      }
    },
    setIntervalX(callback, delay, repetitions) {
      let x = 0;
      const intervalID = window.setInterval(() => {
        callback();
        if (++x === repetitions) {
          window.clearInterval(intervalID);
        }
      }, delay);
    },
    handlePrezentationClick(presentation) {
      // this.$router.push(`/home/myPrezentation?id=${presentation.id}`, () => {});
      this.gotoDetail(presentation, this.editable);
    },
    handleEdit(index) {
      this.editingIndex = index;
    },
    async onConfirmDelete() {
      if (this.deleteIndex > -1) {
        const toDelete = this.prezentations[this.deleteIndex];
        await this.deletePrezentation(toDelete.id)
          .then((value) => {
            console.log('Deleted...');
            console.log(toDelete.id);
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
        this.prezentations.splice(this.deleteIndex, 1);
        // this.$router.go(this.$router.currentRoute)
      }
    },
    async handleDuplicate(index) {
      this.dupIndex = index;

      if (this.dupIndex > -1) {
        const clone = { ...this.prezentations[this.dupIndex] };
        delete clone.owner;
        delete clone.audience;
        delete clone.comments;
        delete clone.slides;
        const { thumbnail } = clone;
        delete clone.thumbnail;
        clone.id = uuidv4();
        clone.name = `${this.prezentations[this.dupIndex].name}--copy`;
        await this.createPrezentation(clone)
          .then((value) => {
            const newItem = value.prezentationData;
            console.log(newItem);
            newItem.thumbnail = thumbnail;
            this.prezentations.splice(this.dupIndex, 0, newItem);
            this.componentKey++;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async createPrezentation(prezentationData) {
      const path = '/prezentation';
      return API.post(path, prezentationData);
    },
    async updatePrezentation(prezentationData) {
      const path = '/prezentation';
      return API.put(path, prezentationData);
    },
    async deletePrezentation(toDeleteId) {
      const path = `/prezentation/${toDeleteId}`;
      return API.delete(path);
    },

    handleDelete(index) {
      this.deleteIndex = index;
      this.$modal.show(
        DeleteConfirmationModal,
        {
          handleDelete: this.onConfirmDelete,
          name: this.prezentations[index].name,
        },
        {
          name: 'DeleteConfirmationModal',
          width: '467px',
          height: '173px',
        },
      );
    },
    async handleTextChange(value) {
      console.log('Text hanged...');
      console.log(value);
      if (this.editingIndex > -1 && value) {
        this.prezentations[this.editingIndex].name = value;
        const prezentationData = {
          id: this.prezentations[this.editingIndex].id,
          name: value,
        };
        console.log(prezentationData);
        await this.updatePrezentation(prezentationData)
          .then((data) => {
            console.log(data);
            // this.$router.go(this.$router.currentRoute);
            this.componentKey++;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // todo make api call
    },
  },
  mounted() {
    console.log(this.prezentations, 'Loaded prezenations into slider');
    this.filteredPrezentations = this.prezentations.slice(0, this.pageSize);
  },
  computed: {
    ...mapState('users', ['currentUser', 'isTrialExpired', 'themes']),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.slides-action {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 37px;
  padding-bottom: 24px;

  button {
    color: $zen-blue-default;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    text-transform: none;
  }
}

.slider-wrapper {
  width: auto;
  position: relative;
  .slider-content-wrapper {
    display: flex;
  }
  .slider-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 24px;
    text-align: left;
  }
  ::v-deep .flickity-viewport {
    height: 350px !important;
  }
  ::v-deep .flickity-prev-next-button {
    top: 33%;
  }
  .carousel-cell {
    padding-bottom: 24px;
    min-width: 200px;
    transition: all ease 0.5s;

    .edit {
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: -10px;
      border: 0;
      font-weight: bold;
      font-size: 24px;
    }
    .edit.v-text-field > .v-input__control > .v-input__slot:before {
      border-style: none;
    }
    .edit.v-text-field > .v-input__control > .v-input__slot:after {
      border-style: none;
    }
    .image-wrapper {
      position: relative;
      //height: 168px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    }
    .image-wrapper:hover .additional-options {
      display: flex;
    }
    .additional-options:hover {
      display: flex;
    }
    .additional-options {
      position: absolute;
      height: 40px;
      background: #f2f2f2;
      bottom: 0px;
      width: 100%;
      display: none;
      justify-content: flex-end;
      div {
        padding-top: 10px;
        padding-right: 10px;
        i,
        button {
          padding-left: 10px;
          cursor: pointer;
        }
      }
    }
    .prezentation-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 18px auto 8px;
    }
    .prezentation-title,
    .type,
    .comment-wrapper,
    .creator {
      width: 100%;
      text-align: left;
      text-transform: capitalize;
    }
    .title {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 6px;
      text-transform: capitalize;
    }
    .type,
    .creator,
    .comment-wrapper {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 8px;
    }
    .comment-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .comment-icon {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #21a7e0;
        margin-right: 6px;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>
