var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("download-actions " + _vm.mode)},[(!_vm.isGoogleDownloadEnabled)?_c('div',{staticClass:"save-to-gdrive-disabled"},[(_vm.mode === 'button')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-2 download ma-2",attrs:{"outlined":"","rounded":"","min-width":"110","color":"#20a7e0","loading":_vm.downloading,"id":"download-btn","data-pendo-id":"download-cta","disabled":_vm.isDisabled,"ripple":false},on:{"click":_vm.handleDirectDownload}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(_vm.btnText))+" ")])]}}],null,false,605902183)},[_c('span',[_vm._v(_vm._s(_vm.mapUserDownloadPreferenceToTooltip))])]):_c('div',{class:("download-icon" + (_vm.isDisabled ? '__disabled' : '')),attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.handleDirectDownload}},[_c('v-tooltip',{attrs:{"top":_vm.customSpeedDialPositionEnabled,"bottom":!_vm.customSpeedDialPositionEnabled,"max-width":_vm.customSpeedDialPositionEnabled ? '300' : '200',"content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showIconButton)?_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isDisabled,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-outline ")])],1):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.isDisabled}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-outline ")])]}}],null,false,159613789)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.iconModeTooltipText ? _vm.iconModeTooltipText : 'common.download')))])])],1)],1):_c('div',{staticClass:"downloadPreferences"},[(
        _vm.usrDownloadPref &&
        _vm.usrDownloadPref !== '' &&
        _vm.usrDownloadPref.toLowerCase() !== 'always_ask'
      )?_c('div',{staticClass:"downloadEleWithPrefs"},[(_vm.mode === 'button')?_c('v-btn',{staticClass:"py-2 download ma-2",attrs:{"outlined":"","rounded":"","min-width":"110","color":"#20a7e0","loading":_vm.downloading,"id":"download-btn","data-pendo-id":"download-cta","disabled":_vm.isDisabled,"ripple":false},on:{"click":_vm.handleDownloadWithSpeceficPreference}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.allowDownloadSettingsIcon
                  ? 'btnTextWithSettingsIcon'
                  : 'btnText'},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(_vm.btnText))+" ")])]}}],null,false,3850438872)},[_c('span',[_vm._v(_vm._s(_vm.mapUserDownloadPreferenceToTooltip))])]),(_vm.allowDownloadSettingsIcon)?_c('div',{staticClass:"downloadSettingsIcon",attrs:{"data-pendo-id":"download-settings-icon"},on:{"click":function($event){$event.stopPropagation();return _vm.openUserDownloadPreferenceModal.apply(null, arguments)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(_vm._s('mdi-cog-outline'))])],1):_vm._e()],1):_c('div',{class:("download-icon" + (_vm.isDisabled ? '__disabled' : '')),attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.handleDownloadWithSpeceficPreference}},[_c('v-tooltip',{attrs:{"top":_vm.customSpeedDialPositionEnabled,"bottom":!_vm.customSpeedDialPositionEnabled,"max-width":_vm.customSpeedDialPositionEnabled ? '300' : '200',"content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.showIconButton)?_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isDisabled,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-outline ")])],1):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.isDisabled},on:{"click":function (e) { return _vm.getDownloadIconCoordinates(e); }}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-outline ")])]}}],null,false,545413859)},[_c('span',[_vm._v(_vm._s(_vm.mapUserDownloadPreferenceToTooltip))])])],1)],1):_c('div',{staticClass:"alwaysAskPref"},[_c('v-speed-dial',{staticClass:"new-slide-detail-speed",attrs:{"direction":_vm.speedDialDirection ? _vm.speedDialDirection : 'bottom'},scopedSlots:_vm._u([(_vm.mode === 'button')?{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"py-2 download ma-2",attrs:{"outlined":"","rounded":"","min-width":"110","color":"#20a7e0","loading":_vm.downloading,"id":"download-btn","data-pendo-id":"download-cta","disabled":_vm.isDisabled,"ripple":false},on:{"click":_vm.onDownloadButtonClick}},[_c('v-tooltip',{attrs:{"attach":"","absolute":"","max-width":"200","content-class":'center-tooltip'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.allowDownloadSettingsIcon
                      ? 'btnTextWithSettingsIcon'
                      : 'btnText'},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(_vm.btnText))+" ")])]}}],null,false,1899018968)},[_c('span',[_vm._v(_vm._s(_vm.mapUserDownloadPreferenceToTooltip))])]),(_vm.allowDownloadSettingsIcon)?_c('div',{staticClass:"downloadSettingsIcon",attrs:{"data-pendo-id":"download-settings-icon"},on:{"click":function($event){$event.stopPropagation();return _vm.openUserDownloadPreferenceModal.apply(null, arguments)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(_vm._s('mdi-cog-outline'))])],1):_vm._e()],1)]},proxy:true}:{key:"activator",fn:function(){return [_c('div',{class:("download-icon" + (_vm.isDisabled ? '__disabled' : '')),attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.onDownloadButtonClick}},[_c('v-tooltip',{attrs:{"top":_vm.customSpeedDialPositionEnabled,"bottom":!_vm.customSpeedDialPositionEnabled,"max-width":_vm.customSpeedDialPositionEnabled ? '300' : '200',"content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.showIconButton)?_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isDisabled,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-outline ")])],1):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.isDisabled},on:{"click":function (e) { return _vm.getDownloadIconCoordinates(e); }}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.mapUserDownloadPreferenceToTooltip))])])],1)]},proxy:true}],null,true)},[(!_vm.isDisabled)?_c('div',{staticClass:"download-list",style:(_vm.customSpeedDialPositionEnabled
              ? { top: (_vm.speedDialTop + "px"), right: (_vm.speedDialRight + "px") }
              : {
                  top: ((_vm.mode === 'icon'
                      ? ("" + (!_vm.isGoogleDownloadEnabled
                            ? ("" + (_vm.speedDialInitTopIcon + 55))
                            : ("" + _vm.speedDialInitTopIcon)))
                      : ("" + (!_vm.isGoogleDownloadEnabled
                            ? ("" + (_vm.speedDialInitTopBtn + 55))
                            : ("" + _vm.speedDialInitTopBtn)))) + "px"),
                  right: (_vm.speedDialInitRight + "px"),
                }),attrs:{"data-pendo-id":"download-cta-dropdown"}},[_c('div',{staticClass:"download-list-item download-from-new-present-list--auto",attrs:{"data-pendo-id":"download-btn-dropdown-link"},on:{"click":function($event){return _vm.checkDownloadLimitAndProceed()}}},[_c('v-btn',{ref:"saveBtn",staticClass:"download-direct",attrs:{"text":"","depressed":"","elevation":"0","ripple":false,"id":"download-btn-from-list"}},[_c('span',{staticClass:"download-btn__content"},[_c('v-icon',[_vm._v(_vm._s('mdi-download-outline'))]),_vm._v(" "+_vm._s(_vm.origin === 'prezentation' || _vm.origin === 'slide' || _vm.origin === 'bpe-slide' ? _vm.isRestricted ? _vm.$t('common.downloadPDF') : _vm.$t('common.downloadPPTX') : _vm.$t('common.download'))+" ")],1)])],1),(_vm.isGoogleDownloadEnabled)?_c('div',{staticClass:"download-list-item"},[_c('div',{on:{"click":function($event){_vm.disableSaveToGDrive ? function () {} : _vm.startSaveToGdriveProcess()}}},[(_vm.disableSaveToGDrive)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"download-btn open-in-google-slides",attrs:{"text":"","depressed":"","elevation":"0","ripple":false,"disabled":_vm.disableSaveToGDrive}},[_c('span',{staticClass:"download-btn__content"},[_c('v-icon',[_vm._v("mdi-google")]),_vm._v(" "+_vm._s(_vm.origin === 'prezentation' || _vm.origin === 'slide' ? _vm.isRestricted ? _vm.$t('common.googleDownloadPDF') : _vm.$t('common.googleDownloadPPTX') : _vm.$t('common.googleDownload'))+" ")],1)])],1)]}}],null,false,4112087779)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.saveToGDriveDisabledTooltip)))])]):_c('v-btn',{staticClass:"download-btn open-in-google-slides",attrs:{"text":"","depressed":"","elevation":"0","ripple":false}},[_c('span',{staticClass:"download-btn__content"},[_c('v-icon',[_vm._v("mdi-google")]),_vm._v(" "+_vm._s(_vm.origin === 'prezentation' || _vm.origin === 'slide' ? _vm.isRestricted ? _vm.$t('common.googleDownloadPDF') : _vm.$t('common.googleDownloadPPTX') : _vm.$t('common.googleDownload'))+" ")],1)])],1)]):_vm._e()]):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }