<template>
  <div class="added-to-lib-fav-snackbars-container">
    <v-snackbar
      :value="getAddedToLibOrFavSnackbar.show"
      bottom
      centered
      dark
      :timeout="snackbarTimeout"
      :width="400"
    >
      <template #default>
        <div class="contentContainer">
          <div class="text-container">
            {{ $t(getAddedToLibOrFavSnackbar.snackbarText) }}
          </div>
          <div class="actions-container">
            <v-btn
              class="navBtn"
              color="rgb(131 210 238)"
              text
              :ripple="false"
              @click="handleNav"
            >
              {{ $t(getAddedToLibOrFavSnackbar.ctaText) }}
            </v-btn>
            <v-icon
              class="closeIcon"
              @click="removeSnackbarFromStack()"
              :size="20"
            >
              {{ 'mdi-close' }}
            </v-icon>
          </div>
        </div>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AddedToLibOrFavSnackbars',
  data() {
    return {
      snackbarTimeout: 5000,
    };
  },
  props: {},
  methods: {
    ...mapActions('commonDownloads', ['removeSnackbarFromStack']),

    handleNav() {
      this.getAddedToLibOrFavSnackbar.navFn();
      this.removeSnackbarFromStack();
    },
  },
  computed: {
    ...mapGetters('commonDownloads', ['getAddedToLibOrFavSnackbar']),
  },
  mounted() {
    /* start a timeout to wipe off this snackbar after 5 seconds from store
      if not closed before timeout expire */

    setTimeout(() => {
      this.removeSnackbarFromStack();
    }, this.snackbarTimeout);
  },
};
</script>

<style lang="scss" scoped>
.added-to-lib-fav-snackbars-container {
  ::v-deep .v-snack {
    position: fixed;
    top: auto;
    height: auto;
    .v-snack__wrapper {
      .v-snack__content {
        padding: 8px 14px;
        .contentContainer {
          display: flex;
          justify-content: space-between;
          .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .actions-container {
            display: flex;
            gap: 8px;
            .navBtn {
              text-transform: none;
              letter-spacing: normal;
              padding: 0px 8px;
            }
            .closeIcon {
            }
          }
        }
      }
    }
  }
}
</style>
