var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"commonDownloadSnackbarContainer"},[_c('div',{staticClass:"slide-collection-download-header",on:{"click":function($event){_vm.showDownloadingItems = !_vm.showDownloadingItems}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getSnackbarDownloadStatusHeader))+" ")]),_c('div',{staticClass:"actions"},[_c('span',[(_vm.showDownloadingItems)?_c('v-tooltip',{attrs:{"top":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon blue-icon",attrs:{"color":"#121618"},domProps:{"textContent":_vm._s("mdi-menu-down")}},'v-icon',attrs,false),on))]}}],null,false,1559127233)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.minimize')))])]):_c('v-tooltip',{attrs:{"top":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon blue-icon",attrs:{"color":"#121618"},domProps:{"textContent":_vm._s("mdi-menu-up")}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.expand')))])])],1),_c('span',[_c('v-icon',{attrs:{"color":"#121618","size":20},on:{"click":function () {
              _vm.setShowDownloadSnackbar(false);
              _vm.setSnackbarDownloadStatusHeader('assembling');
            }}},[_vm._v(_vm._s('mdi-close'))])],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDownloadingItems),expression:"showDownloadingItems"}],ref:"downloadItems",staticClass:"slide-collection-download-body"},_vm._l((_vm.getDownloadItems),function(dwnldItem,ind){return _c('CommonDownloadSnackbarItem',{key:ind,attrs:{"itemToBeDownloaded":dwnldItem}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }