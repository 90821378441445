var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-group-section"},[_c('div',{staticClass:"users-group-section--list"},_vm._l((_vm.usersForAvatars),function(user,index){return _c('v-avatar',{key:index,class:index >= 1
          ? 'users-group-section--item'
          : 'users-group-section--item__border',attrs:{"color":user.color,"size":"56","rounded":""}},[(user.profileImageUrl === null || user.profileImageUrl === '')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"white--text text-h5",staticStyle:{"color":"white","font-size":"27px","font-weight":"250"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(("" + (user.firstName.charAt(0).toUpperCase()) + (user.lastName .charAt(0) .toUpperCase())))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(((user.firstName) + " " + (user.lastName))))])]):_c('v-menu',{attrs:{"bottom":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":user.profileImageUrl,"contain":"","alt":""}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{style:({
            padding: '6px',
          })},[_c('span',{staticClass:"learnersWithPicNames"},[_vm._v(" "+_vm._s(((user.firstName) + " " + (user.lastName)))+" ")])])])],1)}),1),(_vm.usersForFellowText.length > 0)?_c('p',{staticClass:"users-group-section--user-count"},[_c('v-menu',{attrs:{"top":"","offset-y":_vm.offset,"open-on-hover":"","max-height":_vm.setMaxHeightToMenuTooltip(),"max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"fellowUsersTextSpan"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getFellowText())+" ")])]}}],null,false,3953438983)},[_c('div',{style:({
          'max-width': '185px',
          'max-height': ((_vm.setMaxHeightToMenuTooltip() - 18) + "px"),
          'overflow-y': 'auto',
          margin: '9px 16px',
        })},[_c('v-list',{staticClass:"tooltipList"},_vm._l((_vm.usersForFellowText.sort(function (a, b) { return (a.firstName + a.lastName).localeCompare(
                b.firstName + b.lastName
              ); }
            )),function(fellowUsers,index){return _c('v-list-item',{key:index,staticClass:"tooltipListItem"},[_c('v-list-item-title',{staticClass:"tooltipListItemTitle"},[_vm._v(_vm._s(((fellowUsers.firstName.charAt(0).toUpperCase() + fellowUsers.firstName.substring(1)) + " " + (fellowUsers.lastName.charAt(0).toUpperCase() + fellowUsers.lastName.substring(1)))))])],1)}),1)],1)])],1):_c('p',{staticClass:"users-group-section--user-count"},[_vm._v(" "+_vm._s(_vm.getFellowText())+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }