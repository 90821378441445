<template>
  <div class="wrapper">
    <div class="main-wrapper">
      <div class="dialog-header">
        Are you sure you want to permanently delete “{{ name }}” prezentation?
      </div>
    </div>
    <div class="dialog-actions">
      <v-btn
        class="cancel ma-2 py-2"
        outlined
        rounded
        color="#20a7e0"
        width="100"
        height="30"
        :loading="inProgress"
        @click="handleDel"
      >
        Delete
      </v-btn>
      <v-btn
        class="add ma-2 py-2"
        rounded
        color="#20a7e0"
        width="155"
        height="30"
        @click="handleClose"
      >
        Cancel
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteConfirmationModal',
  components: {},
  props: {
    name: {
      type: String,
    },
    handleDelete: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      inProgress: false,
    };
  },
  methods: {
    handleClose() {
      this.$modal.hide('DeleteConfirmationModal');
    },
    handleDel() {
      this.inProgress = true;
      this.handleDelete();
      this.inProgress = false;
      this.handleClose();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.dialog-header {
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}

.preference-wrapper {
  width: 100%;
  height: 50px;

  textarea {
    width: 380px;
    height: 150px;
    padding: 12px 17px 36px 22px;
    border-radius: 20px;
    box-shadow: inset 0 1px 3px 0 #050708;
    background: white;
    border-style: none;
    border-color: Transparent;
    outline: none;
    margin-left: 18px;
    margin-top: 20px;
  }
}

.dialog-actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  button.cancel {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    text-transform: none;
    color: #21a7e0 !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 40px;
  }

  button.add {
    border-radius: 25px;
    text-transform: none;
    color: white !important;
    background: #21a7e0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.main-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
</style>
