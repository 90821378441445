<template>
  <div class="tmpDesignerCompleteAddBnnrCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{ formBannerText }}
      </div>
      <div class="navCTA">
        <v-btn @click="handleGoToProfile">{{
          $t('fingerprint.profile')
        }}</v-btn>
      </div>
      <div class="removeBanner" v-if="getTDData.length">
        <v-progress-circular
          indeterminate
          v-if="removingBanner"
          color="primary"
          size="20"
        />
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="removeBanner()" v-bind="attrs" v-on="on">{{
              'mdi-close'
            }}</v-icon>
          </template>
          <span>{{ $t('templateDesign.banners.dismiss') }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TemplateDesignerService } from '../../../pages/components/TemplateDesigner/TemplateDesignerService.js';

export default {
  name: 'TemplateDesignerAddBanner', // Banner is displayed when there are less than 2 designer templates
  data() {
    return {
      removingBanner: false,
    };
  },
  computed: {
    ...mapGetters('templateDesigner', ['getTDData', 'getFirstTDData']),
    formBannerText() {
      return this.getTDData.length === 0
        ? this.$t('templateDesign.banners.addTemplateBanner')
        : this.$t('templateDesign.banners.addAnotherTemplateBanner');
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  methods: {
    ...mapActions('templateDesigner', ['setTDData']),
    handleGoToProfile() {
      this.$router.push('/profile?tab=templates');
    },
    removeBanner() {
      // To hide TemplateDesignerCompleteBanner
      this.removingBanner = true;
      const tdData = this.getFirstTDData;
      this.templateDesignerService.updateBanner({
        uuid: tdData.uuid,
        bannerType: 'templateAddBanner',
      });
      const updatedTDData = {
        ...tdData,
        banners: {
          ...tdData.banners,
          templateAddBanner: 1,
        },
      };
      // There will only be one designer template
      this.setTDData([updatedTDData]);
      this.removingBanner = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerCompleteAddBnnrCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
