var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audience-select",class:_vm.isTextFieldStyle ? 'style-text-field' : ''},[(_vm.label)?_c('div',{staticClass:"audience-select-title"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.isViewOnly)?_c('div',{staticClass:"audience-select__view"},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":20,"width":2}}):_c('span',[_vm._v(_vm._s(_vm.currentAudience && _vm.currentAudience.fullName))])],1):_c('v-autocomplete',{staticClass:"audience-select-dropdown",attrs:{"data-pendo-id":"audience-selector","items":_vm.items,"solo":!_vm.isTextFieldStyle,"autofocus":_vm.origin === 'compareFing' ? true : false,"return-object":"","item-text":"fullName","loading":!_vm.typesenseAudiencesLoaded,"id":"audience-autocomplete-list--auto","placeholder":_vm.placeholder,"item-value":"audienceId","disabled":_vm.isDisabled,"no-filter":true,"menu-props":{
      closeOnContentClick: true,
    }},on:{"update:search-input":function($event){return _vm.handleSearchUpdate($event)},"change":function($event){return _vm.handleAudienceChange($event)},"click":function($event){return _vm.handleAudienceFocus($event)},"blur":function($event){return _vm.handleBlur($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-list-item',{staticClass:"no-results",attrs:{"dense":""}},[_c('v-list-item-content',[_c('clip-loader',{attrs:{"color":"#21a7e0","width":20,"height":20}})],1)],1):(_vm.search && _vm.search.length >= 2)?[_c('v-list-item',{staticClass:"no-results",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"audience-list-content--auto"},[_vm._v(" "+_vm._s(_vm.$t('build.step1.storylineFieldNoResult'))+" ")])],1)],1),(
            _vm.origin && _vm.origin !== '' && (_vm.origin === 'OP' || _vm.origin === 'Build')
          )?_c('v-list-item',{staticClass:"audience-list-item--self",on:{"click":function($event){return _vm.handleCurrentFP()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',[_c('img',{staticClass:"add-icon fingerprint-logo",attrs:{"src":_vm.fingerprintData.logo,"alt":"Fingerprint-Logo"}})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"add-new-audience-header"},[_vm._v(" "+_vm._s(_vm.$t('upload.useMyFingerprint'))+" ("+_vm._s(_vm.currentUser.user.fingerPrint)+") ")]),_c('span',{staticClass:"add-new-audience-subheader",style:({
                    'max-width': ("" + (_vm.maxWidth ? (_vm.maxWidth + "px") : 'auto')),
                    'white-space': 'initial',
                  })},[_vm._v(" "+_vm._s(_vm.$t('upload.overrideAudienecPreferences'))+" ")])])])],1)],1):_vm._e()]:_vm._e(),_c('span')]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
return [(!item.isActive)?_c('v-list-item',[_c('AudienceItem',{attrs:{"itemClass":'no-padding',"isInComplete":!item.fingerPrint,"fullName":item.fullName,"firstName":item.firstName,"jobTitle":item.jobTitle,"lastName":item.lastName,"profileImgS3Path":item.profileImage,"email":item.isGeneratedEmail ? '' : item.email,"creator":item.creator,"ownerId":item.ownerID,"maxWidth":_vm.maxWidth,"companyName":item.companyName,"isPrivate":item.isPrivate,"isShared":item.isShared,"status":item.isActive ? '' : 'Disabled',"isDifferentCompany":item.companyID !== _vm.currentUser.user.companyID,"isPublicLimited":item.isPublicLimited ? item.isPublicLimited : false,"isGroup":item.isGroup,"fingerprintType":item.fingerprintType
              ? item.fingerprintType.toLowerCase()
              : 'default',"isRecent":item.isRecent,"isTrending":item.isTrending}})],1):_c('AudienceItem',{attrs:{"itemClass":'no-padding',"isInComplete":!item.fingerPrint,"fullName":item.fullName,"firstName":item.firstName,"jobTitle":item.jobTitle,"lastName":item.lastName,"profileImgS3Path":item.profileImage,"email":item.isGeneratedEmail ? '' : item.email,"creator":item.creator,"ownerId":item.ownerID,"maxWidth":_vm.maxWidth,"companyName":item.companyName,"isPrivate":item.isPrivate,"isShared":item.isShared,"status":item.isActive ? '' : 'Disabled',"isDifferentCompany":item.companyID !== _vm.currentUser.user.companyID,"isPublicLimited":item.isPublicLimited ? item.isPublicLimited : false,"isGroup":item.isGroup,"fingerprintType":item.fingerprintType
            ? item.fingerprintType.toLowerCase()
            : 'default',"isRecent":item.isRecent,"isTrending":item.isTrending}})]}},{key:"append-item",fn:function(){return [_c('v-list-item',{staticClass:"audience-list-item--add",on:{"click":_vm.handleAddInternal}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',[_c('v-icon',{staticClass:"add-icon",attrs:{"color":"#20a7e0"}},[_vm._v(" mdi-plus ")])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"add-new-audience-header"},[_vm._v(" "+_vm._s(_vm.$t('upload.addFingerprint'))+" ")]),_c('span',{staticClass:"add-new-audience-subheader",style:({
                  'max-width': ("" + (_vm.maxWidth ? (_vm.maxWidth + "px") : 'auto')),
                  'white-space': 'initial',
                })},[_vm._v(" "+_vm._s(_vm.$t('upload.threeWaysToAdd'))+" ")])])])],1)],1)]},proxy:true},(_vm.showSearchIcon)?{key:"append",fn:function(){return [_c('v-icon',{staticClass:"icon search-icon"},[_vm._v("mdi-magnify")])]},proxy:true}:null],null,true),model:{value:(_vm.currentAudience),callback:function ($$v) {_vm.currentAudience=$$v},expression:"currentAudience"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }