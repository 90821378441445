var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide-collection-download-row"},[_c('v-tooltip',{attrs:{"top":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"statusText"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.status)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.status))])]),(_vm.itemToBeDownloaded.status === 'complete')?_c('span',{staticClass:"completed-icon-container"},[(_vm.showNavIcon)?_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"completedIconStatus",on:{"click":_vm.getNavItem.navFn}},'v-btn',attrs,false),on),[_c(_vm.iconMap[_vm.itemToBeDownloaded.originType],{tag:"component",attrs:{"iconWidth":24,"iconHeight":24,"iconColor":'#21a7e0',"iconStrokeWidth":0.25}})],1)]}}],null,false,1296861595)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.getNavItem.tooltipContent)))])]):_c('v-icon',{staticClass:"completedCheckStatus",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s('mdi-check-circle')+" ")])],1):(_vm.itemToBeDownloaded.status === 'cancelled')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s('mdi-close-circle')+" ")]):(_vm.itemToBeDownloaded.status === 'failed')?_c('v-icon',{attrs:{"color":"#f70000"}},[_vm._v(" "+_vm._s('mdi-alert')+" ")]):(_vm.itemToBeDownloaded.status === 'downloading')?_c('v-progress-circular',{attrs:{"rotate":-90,"value":_vm.downloadPercentage,"color":"primary","width":3,"size":24}}):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',[(!hover)?_c('v-progress-circular',{attrs:{"rotate":-90,"value":0,"color":"primary","width":3,"size":24}}):_c('v-tooltip',{attrs:{"right":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancelDownload()}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-close-circle-outline')+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" "+_vm._s(_vm.$t('common.downloadText'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }