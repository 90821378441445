<template>
  <div :class="`spinning-loading-wrapper ${classes}`">
    <clip-loader :color="color" :width="width" :height="height" />
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'Loading',
  components: {
    ClipLoader,
  },
  props: {
    height: {
      type: String,
      required: false,
      default: '20',
    },
    width: {
      type: String,
      required: false,
      default: '20',
    },
    color: {
      type: String,
      required: false,
      default: '#21a7e0',
    },
    classes: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinning-loading-wrapper {
  align-items: center;
  display: flex;
  height: 20rem;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>
