<template>
  <div class="tmpDesignerCompleteBannerCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{
          $t('templateDesign.banners.completionText', {
            templateName: getTemplateName,
          })
        }}
      </div>
      <div class="navCTA">
        <v-btn @click="handleSelectTheme" :loading="changingTheme">{{
          $t('templateDesign.banners.selectTemplate')
        }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import usersApi from '../../../API/users-api';
import TemplateCompleteConfirmationPopup from '../TemplateCompleteConfirmationPopup.vue';
import { TemplateDesignerService } from '../../../pages/components/TemplateDesigner/TemplateDesignerService.js';

export default {
  name: 'TemplateDesignerCompleteBanner', // Banner is displayed when the designer job is completed with option to select the template
  data() {
    return {
      changingTheme: false,
      jobUUID: '',
    };
  },
  props: {
    componentKey: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const regex = /uuid_(.*)/;
    const [, uuid] = this.componentKey.match(regex);
    this.jobUUID = uuid;
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    ...mapGetters('templateDesigner', ['getTDData']),
    getTemplateName() {
      return this.getCurrentTDData.name ? this.getCurrentTDData.name : '';
    },
    getCurrentTDData() {
      const data = this.getTDData.filter((item) => item.uuid === this.jobUUID);
      return data.length ? data[0] : {};
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser', 'setCurrentTheme']),
    ...mapActions('templateDesigner', ['setTDData']),
    async handleSelectTheme() {
      const theme = this.getCurrentTDData;
      if (theme.code) {
        this.changingTheme = true;
        try {
          await usersApi.methods
            .updateUserProfile({ defaultTheme: theme.code })
            .then(async (updatedUserDetail) => {
              const userInfo = this.currentUser;
              userInfo.user = { ...userInfo.user, ...updatedUserDetail };
              userInfo.theme = theme;
              await this.setCurrentUser(userInfo);
            });
          this.setCurrentTheme(theme);
          this.hideTDCompleteBanner();
          this.changingTheme = false;
          this.$modal.show(
            TemplateCompleteConfirmationPopup,
            {},
            {
              name: 'TemplateCompleteConfirmationPopup',
              width: '400px',
              height: '380px',
              styles: {
                borderRadius: '12px',
              },
            },
          );
        } catch (err) {
          this.changingTheme = false;
          console.error(err);
        }
      }
    },
    hideTDCompleteBanner() {
      // To hide TemplateDesignerCompleteBanner
      const updatedTDData = this.getTDData.map((item) => {
        if (item.uuid === this.jobUUID) {
          item = {
            ...item,
            banners: {
              ...item.banners,
              templateCompletionBanner: 1,
            },
          };
        }
        return item;
      });
      this.templateDesignerService.updateBanner({
        uuid: this.jobUUID,
        bannerType: 'templateCompletionBanner',
      });
      this.setTDData(updatedTDData);
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerCompleteBannerCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
