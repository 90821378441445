<template>
  <div class="main-wrapper">
    <div class="spinner-wrapper" v-if="isLoading">
      <LoadSpinner />
    </div>
    <div class="sharing-modal-main-wrapper" v-if="!isLoading">
      <div class="sharing-modal-container">
        <v-radio-group v-model="shareOptionType" style="height: 67px">
          <v-radio
            color="#20a7e0"
            name="shareType"
            class="share-private--auto"
            value="me"
            @change="handleShareTypeChangeFn()"
          >
            <template #label>
              <div class="label-wrapper">
                <div class="header">{{ $t('build.step1.private') }}</div>
                <div class="sub-header">
                  {{ $t('prezentation.privateDesc') }}
                </div>
              </div>
            </template>
          </v-radio>
          <v-radio
            color="#20a7e0"
            name="shareType"
            :label="$t('profile.shareTemplateAccess.shareWith')"
            value="share"
            class="share-with--auto"
            @change="handleShareTypeChangeFn()"
          ></v-radio>
        </v-radio-group>
        <div class="share-container">
          <div class="radio-button_item">
            <v-checkbox
              v-model="permissionGroup"
              color="#20a7e0"
              hide-details
              value="company"
              class="my_company--auto"
              :disabled="shareOptionType !== 'share'"
            >
              <template #label>
                <div class="label-wrapper">
                  <div class="header">
                    {{ $t('profile.shareTemplateAccess.myCompany') }}
                  </div>
                  <div class="sub-header">
                    {{ $t('prezentation.companyColleaguesCanAccess') }}
                  </div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="radio-button_item">
            <v-checkbox
              v-model="permissionGroup"
              color="#20a7e0"
              class="my_team--auto"
              hide-details
              value="team"
              :disabled="shareOptionType !== 'share'"
            >
              <template #label>
                <div class="label-wrapper">
                  <div class="header">
                    {{ $t('profile.shareTemplateAccess.myTeam') }}
                  </div>
                  <div class="sub-header">
                    {{ $t('prezentation.teamMembersDesc') }}
                  </div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-checkbox
            v-model="permissionGroup"
            class="my_colleagues--auto"
            color="#20a7e0"
            hide-details
            value="user"
            :disabled="shareOptionType !== 'share'"
          >
            <template #label>
              <div class="label-wrapper">
                <div class="header">
                  {{ $t('navbarActions.shareFingerprintvue.selectMembers') }}
                </div>
                <div class="sub-header">
                  {{ $t('prezentation.selectMembersDesc') }}
                </div>
              </div>
            </template>
          </v-checkbox>
          <div
            v-if="permissionGroup.indexOf('user') > -1"
            :label="$t('profile.shareTemplateAccess.selectColleague')"
          >
            <UserSelector
              class="select-colleagues-autocomplete add_more_colleagues--auto"
              v-model="selectedUsers"
              filter="insideCompanyUsers"
            />
          </div>
        </div>
      </div>
      <div :class="`${!isPopUp ? 'mt-60 actions' : 'actions'}`">
        <v-btn
          class="primary-button"
          rounded
          color="#21a7e0"
          height="48"
          min-width="134"
          :loading="isUpdating"
          @click="updateShareDetails"
          :disabled="isShareButtonDisabled"
        >
          {{ $t(updateBtnText) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadSpinner from './LoadSpinner';
import UserSelector from './UserSelector/UserSelector.vue';
import {
  getFingerprintShareDetails,
  updateFingerprintShareDetails,
} from '../../utils/api-helper';
import { fpTypes } from '@/mock/fingerprint';
import {
  FP_SHARE,
  MatomoAnalyticsHandler,
} from './Analytics/MatomoAnalyticsHandler';

export default {
  name: 'ShareWithColleagues',
  props: {
    entityID: {
      type: String,
      required: true,
    },
    updateBtnText: {
      type: String,
      default: 'common.update',
    },
    shareData: {
      type: Object,
    },
    origin: {
      type: String,
      default: '',
    },
    isPopUp: {
      type: Boolean,
      default: true,
    },
    showfpTest: {
      type: Boolean,
      default: false,
    },
  },
  components: { LoadSpinner, UserSelector },
  data() {
    return {
      isLoading: true,
      selectedUsers: [],
      shareOptionType: '',
      permissionGroup: [],
      subModalClosed: true,
      userLevelShareDetails: null,
      isUpdating: false,
    };
  },
  async beforeMount() {
    this.isLoading = true;

    // get sharedDetails for this user's fingerprint
    try {
      const { data } = await getFingerprintShareDetails(this.entityID);

      if (data.length) {
        this.shareOptionType = 'share';
      } else {
        this.shareOptionType = this.defaultShareOptionType;
        if (this.defaultShareOptionType === 'share') {
          this.permissionGroup = ['company'];
        }
      }
      this.shareOptionType =
        data.length > 0 ? 'share' : this.defaultShareOptionType;

      if (data.filter((acl) => acl.sharedEntityType === 'company').length)
        this.permissionGroup.push('company');

      if (data.filter((acl) => acl.sharedEntityType === 'team').length)
        this.permissionGroup.push('team');

      this.userLevelShareDetails =
        data.filter((acl) => acl.sharedEntityType === 'user') || [];

      if (this.userLevelShareDetails.length > 0) {
        this.permissionGroup.push('user');
        this.selectedUsers = this.userLevelShareDetails.map((user) => ({
          ...user,
          isActive: true, // making users enabled by default
          isDifferentCompany:
            user.companyID !== this.currentUser?.user?.companyID,
          id: user.sharedEntityID,
        }));
      }

      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }

    this.isLoading = false;
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isShareButtonDisabled() {
      if (this.shareOptionType !== 'share') {
        return false;
      }

      if (!this.permissionGroup.length) {
        return true;
      }

      return false;
    },
    fingerprintData() {
      return fpTypes.find(
        (fprint) => fprint.title === this.shareData.fingerPrint?.toLowerCase(),
      );
    },
    defaultShareOptionType() {
      if (this.origin === 'encourageShareFingerPrint') {
        return 'share';
      }
      return 'me';
    },
  },
  methods: {
    async updateShareDetails() {
      let sharedEntities = [];
      const usersToPush = [];
      this.permissionGroup.forEach((entity) => {
        switch (entity) {
          case 'company':
            sharedEntities.push({
              type: 'company',
              id: this.currentUser.user.companyID,
            });
            break;
          case 'team':
            sharedEntities.push({
              type: 'team',
              id: this.currentUser.user.teamID,
            });
            break;
          case 'user':
            this.selectedUsers.forEach((user) => {
              const userInfo = {
                type: 'user',
                id: user.id,
              };
              usersToPush.push(userInfo);
            });
            sharedEntities.push(...usersToPush);
            break;
          default:
            sharedEntities = [];
            break;
        }
      });

      if (this.updateBtnText === 'common.update') {
        this.$emit('changeUpdateBtnText', 'common.updating');
      } else {
        this.isUpdating = true;
      }
      const body = {
        entityType: 'private_audience',
        entityID: this.entityID,
        sharedEntities,
      };

      // call ACL endpoint
      if (this.origin === 'encourageShareFingerPrint') {
        this.$emit('handleSave', body);
      } else {
        await updateFingerprintShareDetails(body);
        this.$emit('handleSave', sharedEntities);
      }
      MatomoAnalyticsHandler.trackFingerprint({
        user: this.currentUser,
        actionName: FP_SHARE,
        sharedEntities,
      });
    },
    handleShareTypeChangeFn() {
      if (this.shareOptionType && this.shareOptionType === 'share') {
        this.permissionGroup = [];
      } else {
        this.permissionGroup = ['company'];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.main-wrapper {
  height: 100%;
  min-height: 200px;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.radio-button_item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.share-option-select_dropdown {
  max-width: 120px;
  width: 120px;
}
.sharing-modal-main-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  .preview-email {
    color: #21a7e0;
    cursor: pointer;
    margin: 10px 0px 10px 15px;
    font-size: 1rem;
  }
  .sharing-modal-container {
    width: 100%;
    .sub-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #7b7b7b;
      margin-bottom: 10px;
      margin-left: 30px;
    }
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 4px;
  }

  ::v-deep .v-input--selection-controls .v-radio > .v-label {
    color: black;
  }
  ::v-deep.v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .sharing-modal__permissions {
    margin: 36px 0;
  }
  .actions {
    margin: 10px 0;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
.spinner-wrapper {
  left: 48%;
  margin: auto;
  position: absolute;
  top: 50%;
}
.share-select-item {
  padding: 5px;
  max-width: 220px;

  .share-select-item__description {
    color: gray;
    padding-top: 5px;
  }
}
.share-select-item__selected {
  width: 30px;
}

.share-container {
  margin-left: 25px;
  margin-top: 10px;

  ::v-deep .v-label {
    color: black;
  }

  .v-input {
    margin-bottom: 10px;
    margin-top: 0;
    width: max-content;
  }
}

::v-deep .v-input--selection-controls__input {
  margin-right: 8px;
}

::v-deep .v-input--selection-controls {
  margin-top: 0px;
}

::v-deep .v-input__slot {
  align-items: start;
}
.header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2.5px;
  color: #000000;
}
.sub-header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
}
.pt-20 {
  padding-top: 20px;
}
.mt-60 {
  margin-top: 60px !important;
}
.select-colleagues-autocomplete {
  margin-bottom: 0 !important;
  padding: 14px 14px 5px 14px;
  width: 100% !important;
}
</style>
