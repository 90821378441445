import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import {
  getMatomoEventName,
  getSlideData,
  trackCommonEvent,
  getBuildPrezentationData,
} from './MatomoTrackingDataHelper';

const BUILD_EVENT_CATEGORY = 'Build';
const BUILD_ICONCLICK = 'build_IconClick';
const BUILD_NEXT = 'build_Next';
const BUILD_ACCEPTSTORYLINE = 'build_AcceptStoryline';
const BUILD_ACCEPTSAVESTORYLINE = 'build_AcceptSaveStoryline';
const BUILD_TAILORSLIDES = 'build_TailorSlides';
const BUILD_SHAREICON = 'build_ShareIcon';
const BUILD_SHAREICONUPDATE = 'build_ShareIconUpdate';
const BUILD_SHAREBUTTON = 'build_ShareButton';
const BUILD_SHAREBUTTONUPDATE = 'build_ShareButtonUpdate';
const BUILD_COMMENTS = 'build_Comments';
const BUILD_SLIDES_IMAGEREPLACEMENT = 'build_slides_ImageReplacement';
const BUILD_ACCEPTSTORYLINE_DOWNLOAD = 'build_AcceptStoryline_download';
const BUILD_ACCEPTSAVESTORYLINE_DOWNLOAD = 'build_AcceptSaveStoryline_download';
const BUILD_NEXT_BACK_TO_STEP_ONE = 'Build_next_backtostep1';
const BUILD_STEP_THREE_MOSIAC_CLICK = 'Build_step3_mosiac_click';
const BUILD_TAILOR_SLIDES_FOR_GREY_AUDIENCE_STEP_ONE =
  'Build_tailor slides for_greyaudience';
const BUILD_TAILOR_SLIDES_FOR_GREY_AUDIENCE_STEP_THREE =
  'Build_tailor slides for_greyaudience';
const BUILD_REMIND_BUTTON_FOR_GREY_AUDIENCE_STEP_ONE =
  'Build_tailor slides for_remind';
const BUILD_REMIND_BUTTON_FOR_GREY_AUDIENCE_STEP_THREE =
  'Build_tailor slides for_remind';
const BUILD_NODE_COUNT_CHANGE = 'Build_nodecount_change';
const BUILD_EDIT_STORY_LINE = 'Build_EditStoryline';
const BUILD_SLIDES_IMAGEREPLACEMENT_RESET_TO_DEFAULT =
  'build_slides_ImageReplacement_Resettodefault';
const BUILD_SEARCH_BUILD_IDEA_GALLERY = 'Search_Build_ideagallery';
const BUILD_SLIDE_FAVOURITE = 'Build_Slidefavourite';
const BUILD_IMAGEREPLACEMENT_SEARCH = 'Build_imagereplacement_Search';
const BUILD_IMAGEREPLACEMENT_ACCEPT = 'Build_imagereplacement_Accept';
const BUILD_TEMPLATEINFO_CLICK = 'Build_templateinfo_click';
const BUILD_TEMPLATEINFO_SEARCH = 'Build_templateinfo_search';
const BUILD_TEMPLATEINFO_YESIMSURE = 'Build_templateinfo_yesimsure';
const BUILD_TEMPLATEINFO_ADDMORETEMPLATES_GETINTOUCH =
  'Build_templateinfo_Addmoretemplates_getintouch';
const BUILD_MANAGESTORYLINES = 'Build_managestorylines';
const BUILD_CUSTOMSTORYLINE = 'Build_customstoryline';
const BUILD_BACKFROMSTEP2 = 'Build_backfromstep2';
const BUILD_UPDATE_EXISTING_STORYLINE_ACCEPT_AND_SAVE =
  'Build_update-existing-storyline_Accept-and-Save';
const BUILD_STORYLINE_SEARCH = 'Build_storyline_search';
const BUILD_IDEA_DRAGGED = 'Build_idea_dragged';
const BUILD_SECTION_DRAGGED = 'Build_section_dragged';
const BUILD_SECTION_RENAMED = 'Build_section_renamed';
const BUILD_SECTION_DELETED = 'Build_section_deleted';
const BUILD_SECTION_POSITION_CHANGED = 'Build_section_position-changed';
const BUILD_CUSTOM_SECTION_ADDITION = 'Build_custom-section-addition';
const BUILD_IDEA_DELETE = 'Build_idea_delete';
const BUILD_IDEA_DUPLICATE = 'Build_idea_duplicate';
const BUILD_MAXIMIZE_MINIMIZE_CLICK = 'Build_maximize-minimize_click';
const BUILD_SLIDES_DRAGGED = 'Build_slides_dragged';
const BUILD_ADD_A_SLIDE_CLICK_STEP3 = 'Build_add-a-slide_click-step3';
const BUILD_GALLERY_CLICK = 'Build_gallery_click';
const BUILD_OTHER_ACTIONS_SELECTED = 'Build_other-actions_selected';
const BUILD_GALLERY_VIEW_CLICK = 'Build_gallery_view-click';
const BUILD_GALLERY_SEARCH_QUERY = 'Build_gallery-search_query';
const BUILD_GALLERY_FILTER_RESET = 'Build_gallery_filter-reset';
const BUILD_GALLERY_FILTERED = 'Build_gallery_filtered';
const BUILD_SLIDE_ADDED_STEP3 = 'Build_slide-added-step3';
const BUILD_GALLERY_TAB_CLICK = 'Build_gallery_tab-click';
const BUILD_TUTORIAL_ICON_CLICK = 'Build_tutorial-icon-click';
const BUILD_TUTORIAL_ICON_VIDEO_CLICK = 'Build_tutorial-icon_video-click';
const BUILD_EDIT_SLIDE_CLICK = 'Build_edit-slide_click';
const BUILD_CHANGE_AUDIENCE_CLICK = 'Build_change-audience-click';
const BUILD_ANONYMIZE_CLICK = 'Build_anonymize-click';
const BUILD_EDIT_SLIDE_CHANGE_LAYOUT_CLICK =
  'Build_edit-slide_change-layout-click';
const BUILD_REPLACE_VISUALS_CLICK = 'Build_replace-visuals-click';
const BUILD_EDIT_SLIDE_EDIT_NODE_COUNT_CLICK =
  'Build_edit-slide_edit-node-count-click';
const BUILD_EDIT_SLIDE_EDIT_TAGS_CLICK = 'Build_edit-slide_edit-tags-click';
const BUILD_SIMILAR_SLIDE_SELECTED = 'Build_similar-slide-selected';
const BUILD_CHANGE_LAYOUT_NODE_CHANGED = 'Build_change-layout_node-changed';
const BUILD_CHANGE_LAYOUT_VIEW_CHANGED = 'Build_change-layout_view-changed';
const BUILD_ANONYMIZE_ANONYMIZE_SLIDE = 'Build_anonymize_anonymize-slide';
const BUILD_ANONYMIZE_ANONYMIZE_DECK = 'Build_anonymize_anonymize-deck';
const BUILD_COPYLINK = 'Build_copylink';
const BUILD_FAVOURITED = 'Build_favourited';
const BUILD_ADD_SLIDE_CLICK = 'Build_add-slide_click';

export const trackBuildEvents = {
  buildIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTemplateInfoClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TEMPLATEINFO_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTemplateInfoSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TEMPLATEINFO_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTemplateInfoYesIamSure(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TEMPLATEINFO_YESIMSURE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTemplateInfoAddMoreGetInTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TEMPLATEINFO_ADDMORETEMPLATES_GETINTOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildNextBackToStepOne(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_NEXT_BACK_TO_STEP_ONE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAcceptStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ACCEPTSTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAcceptStorylineDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ACCEPTSTORYLINE_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAcceptSaveStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ACCEPTSAVESTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAcceptSaveStorylineDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ACCEPTSAVESTORYLINE_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTailorSlides(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TAILORSLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildShareIcon(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SHAREICON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildShareButton(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SHAREBUTTON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildShareIconUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SHAREICONUPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildShareButtonUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SHAREBUTTONUPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildComments(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_COMMENTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSlidesImageReplacement(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        BUILD_EVENT_CATEGORY,
        BUILD_SLIDES_IMAGEREPLACEMENT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  buildStepThreeMosaicClick(currentUser, prezentation, otherData) {
    const prezData = getBuildPrezentationData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },

        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_STEP_THREE_MOSIAC_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTailorSlidesGreyAudienceClickOnStepThree(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TAILOR_SLIDES_FOR_GREY_AUDIENCE_STEP_THREE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTailorSlidesGreyAudienceClickOnStepOne(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TAILOR_SLIDES_FOR_GREY_AUDIENCE_STEP_ONE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildBuildTailorSlidesForRemindStepOne(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_REMIND_BUTTON_FOR_GREY_AUDIENCE_STEP_ONE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildBuildTailorSlidesForRemindStepThree(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_REMIND_BUTTON_FOR_GREY_AUDIENCE_STEP_THREE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildNodeCountChange(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        BUILD_EVENT_CATEGORY,
        BUILD_NODE_COUNT_CHANGE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  buildEditStoryLine(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_EDIT_STORY_LINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSlidesImageReplacementReset(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        BUILD_EVENT_CATEGORY,
        BUILD_SLIDES_IMAGEREPLACEMENT_RESET_TO_DEFAULT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  buildSearchIdeaGallery(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SEARCH_BUILD_IDEA_GALLERY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSlideFavourite(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(BUILD_EVENT_CATEGORY, BUILD_SLIDE_FAVOURITE, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  buildImagereplacementSearch(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        BUILD_EVENT_CATEGORY,
        BUILD_IMAGEREPLACEMENT_SEARCH,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  buildImagereplacementAccept(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        BUILD_EVENT_CATEGORY,
        BUILD_IMAGEREPLACEMENT_ACCEPT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  buildManagestorylines(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_MANAGESTORYLINES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildCustomstoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_CUSTOMSTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildBackfromstep2(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_BACKFROMSTEP2,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildUpdateExistingStorylineAcceptAndSave(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_UPDATE_EXISTING_STORYLINE_ACCEPT_AND_SAVE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildStorylineSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_STORYLINE_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildIdeaDragged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_IDEA_DRAGGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSectionDragged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SECTION_DRAGGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSectionRenamed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SECTION_RENAMED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSectionDeleted(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SECTION_DELETED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSectionPositionChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SECTION_POSITION_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildCustomSectionAddition(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_CUSTOM_SECTION_ADDITION,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildIdeaDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_IDEA_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildIdeaDuplicate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_IDEA_DUPLICATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildMaximizeMinimizeClick(currentUser, prezentation, otherData) {
    const prezData = getBuildPrezentationData(prezentation);
    try {
      trackCommonEvent({
        currentUser,
        otherData: {
          ...prezData,
          ...otherData,
        },
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_MAXIMIZE_MINIMIZE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSlidesDragged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SLIDES_DRAGGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAddASlideClickStep3(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ADD_A_SLIDE_CLICK_STEP3,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildGalleryClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_GALLERY_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildOtherActionsSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_OTHER_ACTIONS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildGalleryViewClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_GALLERY_VIEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildGallerySearchQuery(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_GALLERY_SEARCH_QUERY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildGalleryFilterReset(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_GALLERY_FILTER_RESET,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildGalleryFiltered(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_GALLERY_FILTERED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAddSlideClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ADD_SLIDE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildSlideAddedStep3(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SLIDE_ADDED_STEP3,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildGalleryTabClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_GALLERY_TAB_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTutorialIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TUTORIAL_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildTutorialIconVideoClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_TUTORIAL_ICON_VIDEO_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildEditSlideClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_EDIT_SLIDE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildChangeAudienceClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_CHANGE_AUDIENCE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAnonymizeClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ANONYMIZE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildChangeLayoutClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_EDIT_SLIDE_CHANGE_LAYOUT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildReplaceVisualClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_REPLACE_VISUALS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildEditNodeCountClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_EDIT_SLIDE_EDIT_NODE_COUNT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildEditTagtClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_EDIT_SLIDE_EDIT_TAGS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildChangeLayoutSimilarSlideClicked(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_SIMILAR_SLIDE_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildChangeLayoutNodeCountChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_CHANGE_LAYOUT_NODE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildChangeLayoutViewChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_CHANGE_LAYOUT_VIEW_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAnonymizeSlideClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ANONYMIZE_ANONYMIZE_SLIDE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildAnonymizeDeckClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_ANONYMIZE_ANONYMIZE_DECK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildCopylink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_COPYLINK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  buildFavourited(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: BUILD_EVENT_CATEGORY,
        eventAction: BUILD_FAVOURITED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
