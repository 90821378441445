<template>
  <select
    id="state"
    name="state"
    class="stateDropdown"
    v-model="state"
    :disabled="isDisabled"
    @change="(e) => handleStateChange(e)"
    :class="{ 'placeholder-selected': isPlaceholderSelected }"
    placeholder="Select state"
  >
    <option value="" style="color: grey">Select state</option>
    <option
      v-for="(stat, ind) in [...allStates].sort((a, b) =>
        a.name.localeCompare(b.name),
      )"
      :key="ind"
      :value="stat.state_code"
    >
      {{ stat.name }}
    </option>
  </select>
</template>

<script>
import { getStates } from '../../API/common-api';

export default {
  name: 'State',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      required: true,
    },
    selectedState: {
      type: String,
      default: '',
    },
    stateChanged: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      state: '',
      allStates: [],
      isPlaceholderSelected: true,
    };
  },
  watch: {
    country: 'fetchStates',
  },
  created() {
    this.fetchStates();
  },
  methods: {
    fetchStates() {
      this.state = '';
      this.isPlaceholderSelected = true;
      this.allStates = [];
      if (this.country) {
        getStates(this.country)
          .then((resp) => {
            if (resp.data?.length) {
              this.allStates = resp.data;
              const selectedStateItem = this.allStates.find(
                (state) => state.state_code === this.selectedState,
              );
              if (selectedStateItem) {
                this.state = selectedStateItem.state_code;
                this.isPlaceholderSelected = false;
              }
            }
          })
          .catch((err) => {
            console.log('failed to load countries', err);
          });
      }
    },
    handleStateChange(e) {
      if (e.target.value === '') {
        this.isPlaceholderSelected = true;
        const stateItem = {
          name: '',
          state_code: '',
          country_iso2: '',
        };
        this.stateChanged(e.target.value, stateItem);
      } else {
        this.isPlaceholderSelected = false;
        const stateItem = this.allStates.find(
          (state) => state.state_code === e.target.value,
        );
        this.stateChanged(e.target.value, stateItem);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stateDropdown {
  font-size: 14px !important;
}
.placeholder-selected {
  color: grey; /* Color for the placeholder option */
}
</style>
