<template>
  <div
    class="redirecting-detail-wrapper"
    ref="saveToGDrivePopupContainer"
    v-click-outside="handleOutsideClick"
  >
    <v-btn class="close-btn" icon @click="handleClose" :disabled="saveProgress">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="saveInProgress" v-if="saveProgress">
      <div class="illustration">
        <img src="/assets/img/saving-to-gdrive.svg" alt />
      </div>
      <div class="progressTextWithSpinner">
        <v-progress-circular indeterminate color="#21a7e0" size="25" />
        <div class="savingText">{{ $t('common.savingToGDrive') }}</div>
      </div>
    </div>
    <div class="saveComplete" v-else-if="downloadSuccess">
      <div class="illustration">
        <img src="/assets/img/saved-to-gdrive.svg" alt />
      </div>
      <div class="progressText">
        {{ $t('common.savedToGDrive') }}
      </div>
    </div>
    <div class="yetToSave" v-else>
      <div
        v-if="
          isPrezentation &&
          item &&
          item.audienceID &&
          item.audienceID.toLowerCase() !== 'custom'
        "
        class="download-info-content-wrapper"
      >
        <DownloadInfoContent
          imageUrl="/assets/img/downloadmodal/365-download-confirmation.svg"
          :message="'prezentation.gDriveDownloadPopUpDesc'"
          :audienceId="audienceId"
          :audienceOwnerId="audienceOwnerId"
        />
      </div>
      <div v-else>
        <div class="redirecting-detail-image-wrapper">
          <img
            src="/assets/img/downloadmodal/365-download-confirmation.svg"
            alt
          />
        </div>

        <div v-if="error" class="redirecting-detail-content-desc">
          {{ $t('prezentation.requestedFileNotFound') }}
        </div>

        <div v-if="!error" class="redirecting-detail-content-desc">
          {{ $t('prezentation.gDriveDownloadDesc') }}
          <b>{{ $t('common.save') }}</b
          >.
        </div>
      </div>
    </div>
    <div
      v-if="!error"
      :class="actionClass"
      :style="{
        'justify-content':
          saveProgress || downloadSuccess ? 'center' : 'flex-end',
      }"
    >
      <div v-if="isPrezentation">
        <div
          id="prez-container"
          :style="
            !saveProgress && !downloadSuccess
              ? { width: '200px', 'margin-right': '-100px' }
              : {
                  'margin-right': '0px',
                  'pointer-events': 'none',
                }
          "
        >
          <v-btn
            id="layer1"
            class="g-savetodrive"
            :data-src="src"
            :data-filename="fileNameToBeSaved"
            data-sitename="Prezent"
          />
          <v-btn
            id="layer2"
            class="add ma-2 py-2"
            :style="
              saveProgress || downloadSuccess
                ? { width: '108px', top: '15px', left: '-4px' }
                : { width: '100px', top: '20px', left: '-21px' }
            "
            outlined
            rounded
            color="#20a7e0"
            height="40"
            @click="handleOK"
            :loading="!scriptLoaded || makingMergeSlidesCalls"
          >
            {{ $t(getDownloadBtnName) }}
          </v-btn>
        </div>
        <v-btn
          class="cancel ma-2 py-2"
          rounded
          outlined
          width="100"
          height="40"
          @click="handleClose"
          v-if="!saveProgress && !downloadSuccess"
        >
          {{ $t('common.cancel') }}
        </v-btn>
      </div>
      <div v-else>
        <div
          id="container"
          :style="
            !saveProgress && !downloadSuccess
              ? { width: '200px', 'margin-right': '-100px' }
              : {
                  'margin-right': '0px',
                  'pointer-events': 'none',
                }
          "
        >
          <v-btn
            id="layer1"
            class="g-savetodrive"
            :data-src="src"
            :data-filename="fileNameToBeSaved"
            data-sitename="Prezent"
          />
          <v-btn
            id="layer2"
            class="add ma-2 py-2"
            :style="
              saveProgress || downloadSuccess
                ? { width: '108px', top: '15px', left: '-4px' }
                : { width: '100px', top: '20px', left: '-21px' }
            "
            outlined
            rounded
            color="#20a7e0"
            height="40"
            @click="handleOK"
            :loading="!scriptLoaded || makingMergeSlidesCalls"
          >
            {{ $t(getDownloadBtnName) }}
          </v-btn>
        </div>
        <v-btn
          class="cancel ma-2 py-2"
          rounded
          color="#20a7e0"
          width="100"
          height="40"
          @click="handleClose"
          v-if="!saveProgress && !downloadSuccess"
        >
          {{ $t('common.cancel') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { miscConfig } from '../../runtimeConfig';
import { getFileURL } from '@/utils/calyrex';
import { callMergeSlidesApi } from '../../utils/merge-slide-helper';
import DownloadInfoContent from './DownloadInfoContent.vue';

export default {
  name: 'GdriveTransition',
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          // Check if the click was outside the element and its children
          if (
            !el.contains(event.target) &&
            !event.target.className.includes('gc-bubbleDefault') &&
            !event.target.className.includes('pls-tailbottom') &&
            !event.target.className.includes('pls-topTail') &&
            !event.target.className.includes('pls-vertShim')
          ) {
            // Call the method provided in the directive's value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent, true);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent, true);
      },
    },
  },
  components: {
    DownloadInfoContent,
  },
  props: {
    downloadUrl: {
      type: String,
      default: '',
    },
    mergedUrl: {
      type: Function,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    downloadFileName: {
      type: String,
      default: '',
    },
    origin: {
      type: String,
      default: '',
    },
    downloadType: {
      type: String,
      default: '',
    },
    isPrezentation: {
      type: Boolean,
      default: false,
    },
    handleLogDownloadOnSaveComplete: {
      type: Function,
      default: () => {},
    },
    filename: {
      type: String,
      default: '',
    },
    audienceId: String,
    audienceOwnerId: String,
  },

  data() {
    return {
      src: '',
      file: '',
      error: false,
      loading: false,
      scriptLoaded: false,
      saveProgress: false,
      downloadSuccess: false,
      iframeBtnResizeObs: null,
      downloadProgressCount: 0,
      makingMergeSlidesCalls: false,
    };
  },
  watch: {
    downloadProgressCount(val) {
      if (val >= 2) {
        this.saveProgress = false;
        this.downloadSuccess = true;
        this.enableVisibiltyOfIframe();
      }
    },

    saveProgress(val) {
      if (val) {
        this.positionSaveToDriveButton('downloading');
      }
    },

    downloadSuccess(val) {
      if (val) {
        this.positionSaveToDriveButton('saved');
      }
    },
  },
  methods: {
    handleOutsideClick() {
      if (!this.saveProgress || this.downloadSuccess) {
        this.handleClose();
      }
    },

    handleClose() {
      this.$modal.hide('GdriveTransition');
    },

    handleOK() {
      this.handleClose();
    },

    gDridvePopupCoordinates() {
      const okBtn = document.querySelector('#layer2');
      let clientX = 0;
      let clientY = 0;

      if (okBtn) {
        const rect = okBtn.getBoundingClientRect();
        clientX = rect.left;
        clientY = rect.top;
      }
      return {
        x: clientX + 15,
        y: clientY + 28,
      };
    },

    positionSaveToDriveButton(status) {
      const btnGDrive = document.querySelectorAll('div[id^="___savetodrive_"]');
      if (btnGDrive) {
        btnGDrive.forEach((item) => {
          item.style.setProperty('position', 'relative', 'important');
          item.style.setProperty(
            'left',
            `${
              status === 'downloading' || status === 'saved' ? '0px' : '-21px'
            }`,
            'important',
          );
          item.style.setProperty('width', '100px', 'important');
          item.style.setProperty('height', '40px', 'important');
          item.style.setProperty('border-radius', '20px', 'important');
        });
      }

      const gDriveIframes = document.querySelectorAll(
        'iframe[src^="https://drive.google.com/savetodrivebutton"]',
      );
      if (gDriveIframes) {
        gDriveIframes.forEach((item) => {
          item.style.setProperty('position', 'relative', 'important');
          item.style.setProperty('width', '100px', 'important');
          item.style.setProperty('height', '40px', 'important');
          item.style.setProperty('border-radius', '20px', 'important');
        });
      }
    },

    applyPositionToGDrivePopup() {
      const gLoginInfoPopup = document.querySelectorAll('.gc-bubbleDefault');

      if (gLoginInfoPopup.length > 0) {
        gLoginInfoPopup.forEach((pup) => {
          const points = this.gDridvePopupCoordinates();
          pup.style.setProperty('top', `${points.y}px`, 'important');
          pup.style.setProperty('left', `${points.x}px`, 'important');
          // pup.style.setProperty('visibility', 'visible', 'important');
          pup.style.setProperty('z-index', '999', 'important');
          pup.style.setProperty('position', 'fixed', 'important');
        });
      }
    },

    disableVisibiltyOfIframe() {
      const gLoginInfoPopup = document.querySelectorAll('.gc-bubbleDefault');

      if (gLoginInfoPopup.length > 0) {
        gLoginInfoPopup.forEach((pup) => {
          pup.style.setProperty('visibility', 'hidden', 'important');
        });
      }
    },

    enableVisibiltyOfIframe() {
      const gLoginInfoPopup = document.querySelectorAll('.gc-bubbleDefault');

      if (gLoginInfoPopup.length > 0) {
        gLoginInfoPopup.forEach((pup) => {
          pup.style.setProperty('visibility', 'visible', 'important');
        });
      }
    },

    removeScrollOnPage() {
      document.body.classList.add('no-scroll');
    },

    revertScrollOnPage() {
      document.body.classList.remove('no-scroll');
    },

    observeIframeBtn() {
      const savePopup = this.$refs.saveToGDrivePopupContainer;
      if (savePopup) {
        this.resizeObserver = new ResizeObserver(this.checkButtonPosition);
        this.resizeObserver.observe(savePopup);
      }
    },

    checkButtonPosition() {
      this.applyPositionToGDrivePopup();
    },

    getIframeMessage(event) {
      if (event.origin === 'https://drive.google.com') {
        if (event.data.startsWith('!_')) {
          const jsonString = event.data.slice(2);
          const obj = JSON.parse(jsonString);
          if (obj.s) {
            if (obj?.s?.includes('::')) {
              const status = obj.s.split('::')[1];
              if (!this.saveProgress && status && status === 'managerready') {
                this.saveProgress = true;
                this.handleLogDownloadOnSaveComplete(this.file);
                this.disableVisibiltyOfIframe();
              } else if (
                this.saveProgress &&
                status &&
                status === '_g_restyleMe' &&
                obj?.a[0]?.height
              ) {
                this.downloadProgressCount++;
              }
            }
          }
        }
      }
    },

    capitalizeFirstLetter(str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    async getMergedUrlForSlides() {
      let mergedUrl = '';
      let s = {
        slides: [
          {
            slideId:
              this.item.filename.charAt(0) === '/'
                ? this.item.filename.substring(1)
                : this.item.filename,
            isReplaced:
              this.item.filename.startsWith('/newImageReplaceOutput/output/') ||
              this.item.filename.startsWith('/output/pptx/'),
            isUploaded: this.item.prefs.source === 'uploaded',
            isGenerated: this.item.prefs.source === 'generated',
            isSynthesis: this.item.prefs.source === 'synthesis',
            isRedesign: this.item.prefs.source === 'redesign',
            isComply: this.item.prefs.source === 'comply',
          },
        ],
      };

      if (
        this.item.isRestricted ||
        this.item.permission_level === 0 ||
        (this.item?.permission &&
          this.item?.permission?.toLowerCase() === 'restricted')
      ) {
        s = {
          ...s,
          outputFormat: 'pdf',
        };
      }
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          // if (mergedslide.includes('.pptx')) {
          mergedUrl = `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
          // }
        })
        .catch((err) => {
          console.log(err);
        });
      return mergedUrl;
    },

    getSlideBasedTemplateForDownload(code) {
      const template = this.themes.find((thm) => thm.code === code);
      if (template) {
        return template.name;
      }
      return code;
    },

    async mergeSlidesCallsOnLoad() {
      let urlToDownload;
      if (this.downloadUrl) {
        urlToDownload = this.downloadUrl;
      } else {
        urlToDownload = await this.mergedUrl();
      }

      if (urlToDownload) {
        this.src = urlToDownload;
        this.file = this.filename;

        if (
          this.item.prefs &&
          (this.item?.prefs?.source === 'generated' ||
            this.item?.prefs?.source === 'synthesis' ||
            this.item?.prefs?.source === 'redesign' ||
            this.item?.prefs?.source === 'uploaded' ||
            this.item?.prefs?.source === 'comply') &&
          this.item?.name
        ) {
          this.file = `${this.item.name.replace(/.pptx/g, '')}.pptx`;
        } else if (this.item.name) {
          this.file = `${this.item.name}.pptx`;
        } else if (this.item.theme && this.item.type) {
          this.file = `${this.item.theme} - ${this.item.type}.pptx`;
        } else if (this.item.type) {
          this.file = `${this.item.type}.pptx`;
        } else if (this.item.theme) {
          this.file = `${this.item.theme}.pptx`;
        } else {
          this.file = `${this.capitalizeFirstLetter(
            this.item.title || this.item.categories[0],
          )} - ${this.getSlideBasedTemplateForDownload(
            this.item.prefs.theme,
          )}.pptx`;
        }
      } else {
        this.file = this.filename;

        try {
          if (
            (['slide', 'bpe-slide'].includes(this.origin) &&
              this.downloadType !== 'SlideReplacement' &&
              this.item.prefs &&
              this.item.prefs.source &&
              (this.item.prefs.source === 'uploaded' ||
                this.item.prefs.source === 'generated' ||
                this.item.prefs.source === 'comply' ||
                this.item.prefs.source === 'synthesis' ||
                this.item.prefs.source === 'redesign')) ||
            this.item.filename.indexOf('protected') !== -1 ||
            this.item.filename.indexOf('private') !== -1 ||
            this.item.filename.indexOf('public') !== -1
          ) {
            this.src = await this.getMergedUrlForSlides();
            this.file =
              `${this.item.name.replace(/.pptx/g, '')}.${
                this.item?.permission &&
                this.item?.permission?.toLowerCase() === 'restricted'
                  ? 'pdf'
                  : 'pptx'
              }` || '';
          } else {
            this.src = await getFileURL(
              this.currentUser.user.id,
              this.file,
              this.currentUser.userIp,
            );
            if (
              this.item.prefs &&
              (this.item.prefs.source === 'generated' ||
                this.item.prefs.source === 'synthesis' ||
                this.item.prefs.source === 'redesign' ||
                this.item.prefs.source === 'uploaded' ||
                this.item.prefs.source === 'comply') &&
              this.item.name
            ) {
              this.file = `${this.item.name.replace(/.pptx/g, '')}.pptx`;
            } else if (this.item.asset) {
              this.file = `${this.capitalizeFirstLetter(
                this.item.asset.title || this.item.asset.categories[0],
              )} - ${this.getSlideBasedTemplateForDownload(
                this.item.asset.prefs.theme,
              )}.pptx`;
            } else {
              this.file = `${this.capitalizeFirstLetter(
                this.item.title || this.item.categories[0],
              )} - ${this.getSlideBasedTemplateForDownload(
                this.item.prefs.theme,
              )}.pptx`;
            }
          }
        } catch (err) {
          console.log(err);
          if (this.item.theme && this.item.type) {
            this.file = `${this.item.theme} - ${this.item.type}`;
          } else {
            this.file = `${this.capitalizeFirstLetter(
              this.item.title || this.item.categories[0],
            )} - ${this.getSlideBasedTemplateForDownload(
              this.item.prefs.theme,
            )}`;
          }
          this.error = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters('commonDownloads', ['getGdriveFileDownloads']),
    ...mapState('users', ['currentUser', 'themes']),
    actionClass() {
      return `dialog-actions ${this.isPrezentation ? 'prezentation' : ''}`;
    },

    getDownloadBtnName() {
      if (this.saveProgress) {
        return 'common.saving';
      }
      if (this.downloadSuccess) {
        return 'common.done';
      }
      return 'common.next';
    },

    /* Updating same filename with subsequent saves,
    as same name file save was throwing errors with g drive iframe */
    fileNameToBeSaved() {
      const flNameCount = this.getGdriveFileDownloads[this.file];
      if (flNameCount) {
        const isPdf = this.file?.includes('.pdf');
        if (isPdf) {
          return `${this.file.replace(/.pdf/g, '')}(${flNameCount}).pdf`;
        }
        return `${this.file.replace(/.pptx/g, '')}(${flNameCount}).pptx`;
      }
      return this.file;
    },
  },

  async mounted() {
    // disabling body scroll when save to g drive popup is opened
    this.removeScrollOnPage();

    this.makingMergeSlidesCalls = true;
    await this.mergeSlidesCallsOnLoad();
    this.makingMergeSlidesCalls = false;

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://apis.google.com/js/platform.js?v=${Date.now()}`;
    script.onload = () => {
      setTimeout(() => {
        this.scriptLoaded = true;
        this.applyPositionToGDrivePopup();
        this.positionSaveToDriveButton('yetToSave');

        window.addEventListener('message', this.getIframeMessage);
      }, 1000);
    };
    document.querySelector('head').appendChild(script);

    window.addEventListener('resize', this.applyPositionToGDrivePopup);

    this.observeIframeBtn();
  },
  beforeDestroy() {
    const gDriveIframes = document.querySelectorAll(
      'iframe[src^="https://drive.google.com/savetodrivehover"]',
    );
    if (gDriveIframes) {
      gDriveIframes.forEach((item) => {
        item.parentNode.removeChild(item);
      });
    }

    const gApisScriptFirst = document.querySelectorAll(
      'script[src^="https://apis.google.com/js/platform.js"]',
    );
    if (gApisScriptFirst) {
      gApisScriptFirst.forEach((item) => {
        item.parentNode.removeChild(item);
      });
    }

    const gDrivePoups = document.querySelectorAll('.gc-bubbleDefault');
    if (gDrivePoups.length > 0) {
      gDrivePoups.forEach((pup) => {
        pup.remove();
      });
    }

    window.removeEventListener('resize', this.applyPositionToGDrivePopup);
    window.removeEventListener('message', this.getIframeMessage);

    // enabling body scroll when save to g drive popup is closed
    this.revertScrollOnPage();

    if (this.iframeBtnResizeObs) {
      this.iframeBtnResizeObs.disconnect();
    }
  },
  destroyed() {
    const gDriveIframes = document.querySelectorAll(
      'iframe[src^="https://drive.google.com/savetodrivehover"]',
    );
    if (gDriveIframes) {
      gDriveIframes.forEach((item) => {
        item.parentNode.removeChild(item);
      });
    }

    const gApisScriptFirst = document.querySelectorAll(
      'script[src^="https://apis.google.com/js/platform.js"]',
    );
    if (gApisScriptFirst) {
      gApisScriptFirst.forEach((item) => {
        item.parentNode.removeChild(item);
      });
    }

    const gDrivePoups = document.querySelectorAll('.gc-bubbleDefault');
    if (gDrivePoups.length > 0) {
      gDrivePoups.forEach((pup) => {
        pup.remove();
      });
    }

    window.removeEventListener('resize', this.applyPositionToGDrivePopup);
    window.removeEventListener('message', this.getIframeMessage);

    // enabling body scroll when save to g drive popup is closed
    this.revertScrollOnPage();

    if (this.iframeBtnResizeObs) {
      this.iframeBtnResizeObs.disconnect();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.no-scroll {
  overflow: hidden;
  height: 100%; /* Ensure the body height is fixed */
}

.gc-bubbleDefault {
  visibility: visible;
}

.pls-tailbottom {
  left: 22px !important;
}
</style>
<style lang="scss" scoped>
.redirecting-detail-wrapper {
  padding: 24px;
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .saveInProgress {
    padding: 20px;
    .illustration {
      width: 75%;
      margin: 32px auto 32px auto;
      height: 170px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .progressTextWithSpinner {
      display: flex;
      justify-content: center;
      .savingText {
        text-align: center;
        font-size: 16px;
        margin: 0px 0px 4px 0px;
      }
    }
  }
  .saveComplete {
    padding: 20px;
    .illustration {
      width: 75%;
      margin: 32px auto 32px auto;
      height: 170px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .progressText {
      text-align: center;
      font-size: 16px;
      margin: 0px 0px 4px 0px;
    }
  }
  .yetToSave {
    padding: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
    background: white;

    .download-info-content-wrapper {
      // margin-top: 20px;
    }

    ::v-deep .download-info-content-wrapper {
      .current-audience-preferences {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    .redirecting-detail-image-wrapper {
      width: 75%;
      margin: 32px auto 32px auto;
      height: 170px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .redirecting-detail-content-desc {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-align: center;
      margin: 0px 0px 4px 0px;
    }
  }
  .dialog-actions {
    display: flex;
    align-items: flex-start;
    width: 100%;

    &.prezentation {
      button.cancel {
        color: $zen-blue-default !important;
        margin-right: unset;
        margin-top: unset;
      }
    }

    div {
      display: flex;
    }

    button.cancel {
      position: relative;
      border-radius: 25px;
      text-transform: none;
      color: #21a7e0;
      background-color: white !important;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      border: 1px solid #21a7e0;
      box-shadow: none;
    }

    button.add {
      position: absolute;
      border-radius: 25px;
      text-transform: none;
      border: solid 2px #21a7e0;
      color: white !important;
      background: #21a7e0;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      margin-right: -90px;
      margin-top: -20px;
      z-index: 10px;
    }

    #container {
      position: relative;
      z-index: 0;
      // margin-top: 24px;
    }
    #prez-container {
      position: relative;
      z-index: 0;
    }
    #layer1 {
      position: absolute;
      z-index: 1;
    }

    #layer2 {
      position: absolute;
      z-index: 2;
      background-color: '#20a7e0';
      pointer-events: none;
    }
  }
}
</style>
