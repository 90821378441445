<template>
  <div
    class="presentation-type-combobox-wrapper"
    :class="isTextFieldStyle ? 'style-text-field' : ''"
    ref="dropDownRef"
    data-pendo-id="storyline-type-selection"
  >
    <div
      v-if="label"
      :class="
        origin === 'Build'
          ? 'presentation-type-label'
          : 'presentation-type-labell'
      "
    >
      {{ label }}
    </div>
    <v-autocomplete
      v-model="currentStoryline"
      :items="items"
      :solo="!isTextFieldStyle"
      return-object
      item-text="type"
      :placeholder="placeholder"
      :hide-selected="true"
      item-value="id"
      id="prezentation-type-autocomplete-list"
      class="prezentation-type-select-dropdown"
      @update:search-input="handleSearchUpdate($event)"
      :disabled="isDisabled"
      :no-filter="true"
      @change="handleStorylineChange($event)"
      @focus="handleStorylineFocus($event)"
      :menu-props="{
        closeOnClick: true,
        closeOnContentClick: true,
      }"
      :loading="loading || searchLoading"
    >
      <template v-slot:no-slot>
        <v-list-item dense class="no-results">
          <v-list-item-content>
            <v-list-item-title class="presentation-type-content--auto">
              {{ $t('build.step1.storylineFieldNoResult') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:no-data>
        <v-list-item dense class="no-results" v-if="loading || searchLoading">
          <v-list-item-content>
            <clip-loader color="#21a7e0" :width="20" :height="20" />
          </v-list-item-content>
        </v-list-item>
        <template v-else-if="search && search.length >= 2">
          <v-list-item dense class="no-results">
            <v-list-item-content>
              <v-list-item-title class="audience-list-content--auto">
                {{ $t('build.step1.storylineFieldNoResult') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span></span>
      </template>
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
          v-on="on"
          v-bind="attrs"
          two-line
          :style="{
            'padding-left': '50px',
            'max-width': `${menuWidth}px !important`,
          }"
        >
          <v-list-item-content
            v-if="!(item.type === 'Custom' && item.isPrezentGenerated)"
            id="presentation-type-content--auto"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="option-wrapper" v-bind="attrs" v-on="on">
                  <div class="prez-heading__wrapper">
                    <v-list-item-subtitle
                      class="prez-description"
                      v-if="item.isSearchUsingExtension"
                      :style="{ 'max-width': `${menuWidth - 80}px !important` }"
                    >
                      {{ $t('build.step1.didYouMean') }}
                    </v-list-item-subtitle>
                    <v-list-item-title
                      class="prez-heading"
                      v-if="!item.isPrezentGenerated && isOwner(item)"
                      :style="{ 'max-width': `${menuWidth - 80}px !important` }"
                    >
                      {{ item.type }} | {{ $t('build.step3.author') }}:
                      {{
                        !isOwner(item)
                          ? item.fullName
                          : $t('fingerprint.compareFingAud.me')
                      }}
                      |
                      {{
                        item.isShared
                          ? $t('build.step1.shared')
                          : $t('build.step1.private')
                      }}
                      <NewFeatureBadge
                        module-name="build"
                        :feature-id="'build_storyline_dropdown_id'"
                        :offsetX="'-4'"
                        :offsetY="'4'"
                        :size="'medium'"
                        v-if="isNew(item)"
                        :content="'common.newContent'"
                      >
                      </NewFeatureBadge>
                    </v-list-item-title>
                    <v-list-item-title
                      class="prez-heading"
                      v-else-if="!item.isPrezentGenerated && !isOwner(item)"
                      :style="{ 'max-width': `${menuWidth - 80}px !important` }"
                    >
                      {{ item.type }} | {{ $t('build.step3.author') }}:
                      {{
                        !isOwner(item)
                          ? item.fullName
                          : $t('fingerprint.compareFingAud.me')
                      }}
                      <NewFeatureBadge
                        module-name="build"
                        :feature-id="'build_storyline_dropdown_id'"
                        :offsetX="'-4'"
                        :offsetY="'4'"
                        :size="'medium'"
                        :content="'common.newContent'"
                        v-if="isNew(item)"
                      >
                      </NewFeatureBadge>
                    </v-list-item-title>
                    <v-list-item-title
                      class="prez-heading"
                      v-else
                      :style="{ 'max-width': `${menuWidth - 80}px !important` }"
                    >
                      {{ item.type }}
                      <img
                        src="/assets/img/prezent_avatar.svg"
                        alt
                        width="22px"
                      />
                      <NewFeatureBadge
                        module-name="build"
                        :feature-id="'build_storyline_dropdown_id'"
                        :offsetX="'-4'"
                        :offsetY="'4'"
                        :size="'medium'"
                        :content="'common.newContent'"
                        v-if="isNew(item)"
                      >
                      </NewFeatureBadge>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="prez-description"
                      :style="{ 'max-width': `${menuWidth - 80}px !important` }"
                    >
                      {{ item.description ? item.description : '' }}
                    </v-list-item-subtitle>
                  </div>
                </div>
              </template>
              <div
                :class="`option-tooltip-wrapper${
                  item.description && item.description.length > 50
                    ? '__small'
                    : ''
                }`"
              >
                <div>
                  <v-list-item-title
                    class="prez-heading"
                    v-if="!item.isPrezentGenerated && isOwner(item)"
                  >
                    {{ item.type }} | {{ $t('build.step3.author') }}:
                    {{
                      !isOwner(item)
                        ? item.fullName
                        : $t('fingerprint.compareFingAud.me')
                    }}
                    |
                    {{
                      item.isShared
                        ? $t('build.step1.shared')
                        : $t('build.step1.private')
                    }}
                    <NewFeatureBadge
                      module-name="build"
                      :feature-id="'build_storyline_dropdown_id'"
                      :offsetX="'-4'"
                      :offsetY="'4'"
                      :size="'medium'"
                      :content="'common.newContent'"
                      v-if="isNew(item)"
                    >
                    </NewFeatureBadge>
                  </v-list-item-title>
                  <v-list-item-title
                    class="prez-heading"
                    v-else-if="!item.isPrezentGenerated && !isOwner(item)"
                  >
                    {{ item.type }} | {{ $t('build.step3.author') }}:
                    {{
                      !isOwner(item)
                        ? item.fullName
                        : $t('fingerprint.compareFingAud.me')
                    }}
                    <NewFeatureBadge
                      module-name="build"
                      :feature-id="'build_storyline_dropdown_id'"
                      :offsetX="'-4'"
                      :offsetY="'4'"
                      :size="'medium'"
                      :content="'common.newContent'"
                      v-if="isNew(item)"
                    >
                    </NewFeatureBadge>
                  </v-list-item-title>
                  <v-list-item-title class="prez-heading" v-else>
                    {{ item.type }}
                    <img
                      src="/assets/img/prezent_avatar.svg"
                      alt
                      width="22px"
                    />
                    <NewFeatureBadge
                      module-name="build"
                      :feature-id="'build_storyline_dropdown_id'"
                      :offsetX="'-4'"
                      :offsetY="'4'"
                      :size="'medium'"
                      :content="'common.newContent'"
                      v-if="isNew(item)"
                    >
                    </NewFeatureBadge>
                  </v-list-item-title>
                  <v-list-item-subtitle class="prez-description">
                    {{ item.description ? item.description : '' }}
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #append-item>
        <v-list-item
          :class="`prezentaion-list-item--add ${
            isCustom ? 'add-list-item' : 'add-item-other'
          }`"
          @click="handleCustomSelectClicked"
          :style="{ 'max-width': `${menuWidth}px !important` }"
        >
          <v-list-item-content>
            <v-list-item-title v-if="isCustom">
              <div>
                <v-icon color="#20a7e0" class="add-icon" v-text="`mdi-plus`" />
              </div>
              <div class="option-wrapper">
                <div>
                  <v-list-item-title class="prez-heading">
                    {{ $t('build.step1.custom') }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="prez-description">
                    {{ $t('build.step1.customSubtitle') }}
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item-title>
            <v-list-item-title v-else class="prez-heading">
              {{ $t('common.other') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="prezentaion-list-item--add manage-list-item"
          @click="handleManageStorylines"
          v-if="isCustom"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-list-item-title class="prez-heading-primary">
                {{ $t('build.step1.manageStoryline') }}
              </v-list-item-title>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { TypeSenseServer } from '../../pages/components/TypeSenseInit';
import NewFeatureBadge from './NewFeatureBadge.vue';

export default {
  name: 'StorylineDropdown',
  data() {
    return {
      currentStoryline: null,
      search: null,
      storylines: [],
      allStorylines: [],
      loading: true,
      searchLoading: false,
      doSearch: false,
      page: 1,
      menuWidth: 0,
    };
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    origin: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    handleManageStorylines: {
      type: Function,
      default: () => {},
    },
    handleCustomSelectClick: {
      type: Function,
      default: () => {},
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search by type or build your own prezentation',
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    isTextFieldStyle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
    audienceStoryPref: {
      type: String,
      default: '',
    },
  },
  computed: {
    items() {
      if (
        this.currentStoryline &&
        this.currentStoryline.type === 'Custom' &&
        this.currentStoryline.isPrezentGenerated
      ) {
        return [...this.storylines];
      }
      return this.storylines.filter(
        (ele) => !(ele.type === 'Custom' && ele.isPrezentGenerated),
      );
    },
    isDisabled() {
      return this.disabled || this.loading;
    },
    ...mapState('users', ['currentUser']),
    hasSearchKeyword() {
      if (
        this.search &&
        this.search !== this.currentStoryline?.type &&
        this.currentStoryline?.type !== 'Custom' &&
        this.currentStoryline?.type !== 'Other'
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async audienceStoryPref(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.audienceStoryPref) {
          this.loading = true;
          this.doSearch = true;
          this.page = 1;
          this.storylines = [];
          while (this.doSearch) {
            const tsClient = await TypeSenseServer.getClient();
            const searchParameters = {
              q: '*',
              query_by:
                'type, description, sectionsName, extension, fullName, userID',
              filter_by: `storyType:=[${this.audienceStoryPref}, all]`,
              per_page: '250',
              page: this.page,
              sort_by: 'type:asc',
            };
            await tsClient
              .collections('storylines')
              .documents()
              .search(searchParameters)
              .then((response) => {
                const { hits, found } = response;
                const storylines = [];
                hits.forEach((storyline) => {
                  storylines.push({
                    ...storyline.document,
                    storyType: this.audienceStoryPref,
                  });
                });
                this.storylines = [...this.storylines, ...storylines];
                if (this.storylines && this.storylines.length >= found) {
                  this.doSearch = false;
                }
                this.page++;
                this.allStorylines = this.storylines;
              });
          }
          if (this.currentStoryline) {
            this.currentStoryline = this.allStorylines.find(
              (i) => this.currentStoryline.type === i.type,
            );
            this.currentStoryline.sectionGallery = JSON.parse(
              this.currentStoryline.sectionGallery
                ? this.currentStoryline.sectionGallery
                : '{}',
            );
            this.currentStoryline.ideaGallery = JSON.parse(
              this.currentStoryline.ideaGallery
                ? this.currentStoryline.ideaGallery
                : '{}',
            );
            this.currentStoryline.sections = JSON.parse(
              this.currentStoryline.sections
                ? this.currentStoryline.sections
                : '{}',
            );
            this.$emit('change', this.currentStoryline);
            this.$emit('change:user', this.currentStoryline);
          }
          this.loading = false;
        }
      }
    },
    value: {
      immediate: true,
      async handler(val) {
        if (
          val &&
          val.defaultStoryline &&
          val.defaultStoryline !== 'Custom' &&
          val.defaultStoryline !== 'Other'
        ) {
          this.loading = true;
          this.search = val.defaultStoryline;
          const tsClient = await TypeSenseServer.getClient();
          const searchParameters = {
            q: val.defaultStoryline,
            query_by: 'type',
            filter_by: `storyType:=[${this.audienceStoryPref}, all]`,
          };
          await tsClient
            .collections('storylines')
            .documents()
            .search(searchParameters)
            .then((response) => {
              const { hits } = response;
              this.storylines = [
                ...this.allStorylines,
                ...hits.map((ele) => ele.document),
              ];
              this.currentStoryline = {
                ...hits[0].document,
                sections: JSON.parse(
                  hits[0].document.sections ? hits[0].document.sections : '{}',
                ),
                sectionGallery: JSON.parse(
                  hits[0].document.sectionGallery
                    ? hits[0].document.sectionGallery
                    : '{}',
                ),
                ideaGallery: JSON.parse(
                  hits[0].document.ideaGallery
                    ? hits[0].document.ideaGallery
                    : '{}',
                ),
              };
              this.search = this.currentStoryline.type;
            });
          this.loading = false;
          this.$emit('change', this.currentStoryline);
          this.$emit('change:user', this.currentStoryline);
        }
      },
    },
  },
  async mounted() {
    if (this.audienceStoryPref) {
      this.doSearch = true;
      this.loading = true;
      this.page = 1;
      this.storylines = [];
      while (this.doSearch) {
        const tsClient = await TypeSenseServer.getClient();
        const searchParameters = {
          q: '*',
          query_by:
            'type, description, sectionsName, extension, fullName, userID',
          filter_by: `storyType:=[${this.audienceStoryPref}, all]`,
          per_page: '250',
          page: this.page,
          sort_by: 'type:asc',
        };
        await tsClient
          .collections('storylines')
          .documents()
          .search(searchParameters)
          .then((response) => {
            const { hits, found } = response;
            const storylines = [];
            hits.forEach((storyline) => {
              storylines.push({
                ...storyline.document,
                storyType: this.audienceStoryPref,
              });
            });
            this.storylines = [...this.storylines, ...storylines];
            if (this.storylines && this.storylines.length >= found) {
              this.doSearch = false;
            }
            this.page++;
            this.allStorylines = this.storylines;
          });
      }
      this.loading = false;
    }
    if (this.$refs.dropDownRef) {
      this.menuWidth = this.$refs.dropDownRef.offsetWidth;
    }
    window.addEventListener('resize', this.handleMenuWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleMenuWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleMenuWidth);
  },
  methods: {
    handleMenuWidth() {
      if (this.$refs.dropDownRef) {
        this.menuWidth = this.$refs.dropDownRef.offsetWidth;
      }
    },
    handleSearchDebounced: debounce((val, _this) => {
      _this.handleSearch(val);
    }, 500),
    async handleCustomSelectClicked() {
      this.storylines = [...this.allStorylines];
      this.loading = true;
      const tsClient = await TypeSenseServer.getClient();
      const searchParameters = {
        q: 'Custom',
        query_by: 'type',
        filter_by: `storyType:=[${this.audienceStoryPref}, all] && isPrezentGenerated:true`,
      };
      await tsClient
        .collections('storylines')
        .documents()
        .search(searchParameters)
        .then((response) => {
          const { hits } = response;
          this.storylines = [
            ...this.allStorylines.filter(
              (ele) => !(ele.type === 'Custom' && ele.isPrezentGenerated),
            ),
            ...hits.map((ele) => {
              if (this.origin !== 'Build') {
                return { ...ele.document, type: 'Other' };
              }
              return ele.document;
            }),
          ];
          this.search = this.origin !== 'Build' ? 'Other' : 'Custom';
          this.currentStoryline = {
            ...hits[0].document,
            sections: JSON.parse(
              hits[0].document.sections ? hits[0].document.sections : '{}',
            ),
            sectionGallery: JSON.parse(
              hits[0].document.sectionGallery
                ? hits[0].document.sectionGallery
                : '{}',
            ),
            type: this.origin !== 'Build' ? 'Other' : hits[0].document.type,
            ideaGallery: JSON.parse(
              hits[0].document.ideaGallery
                ? hits[0].document.ideaGallery
                : '{}',
            ),
          };
        });
      this.loading = false;
      this.$emit('change', this.currentStoryline);
      this.$emit('change:user', this.currentStoryline);
      this.handleCustomSelectClick();
    },
    isOwner(item) {
      return (
        item.userID &&
        this.currentUser.user &&
        item.userID.toLocaleLowerCase() ===
          this.currentUser.user.id.toLocaleLowerCase()
      );
    },
    isNew(item) {
      const timestamp = new Date().getTime() + 28 * 24 * 60 * 60 * 1000;
      return item.createdAt < timestamp && item.isPrezentGenerated;
    },
    async handleSearch(val) {
      if (val && val.length >= 2 && this.hasSearchKeyword) {
        this.storylines = [];
        this.searchLoading = true;
        const tsClient = await TypeSenseServer.getClient();
        const searchParameters = {
          q: val,
          query_by:
            'type, description, sectionsName, extension, fullName, userID',
          filter_by: `storyType:=[${this.audienceStoryPref}, all]`,
          per_page: '250',
          sort_by: 'type:asc',
        };
        await tsClient
          .collections('storylines')
          .documents()
          .search(searchParameters)
          .then((response) => {
            const { hits } = response;
            const storylines = [];
            hits.forEach((storyline) => {
              let isExtension = false;
              storyline.highlights.forEach((ele) => {
                if (ele.field === 'extension') {
                  isExtension = true;
                }
              });
              storylines.push({
                ...storyline.document,
                storyType: this.audienceStoryPref,
                isSearchUsingExtension: isExtension,
              });
            });
            this.storylines = [...storylines];
          });
        this.searchLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleSearchUpdate(search) {
      this.search = search;
      this.handleSearchDebounced(search, this);
    },
    handleStorylineChange($event) {
      if (this.currentStoryline === null) {
        if (this.origin === 'Upload') {
          this.$emit('change', this.currentStoryline);
        }
        return;
      }
      this.currentStoryline = {
        ...this.currentStoryline,
        sections: JSON.parse(
          this.currentStoryline.sections
            ? this.currentStoryline.sections
            : '{}',
        ),
        sectionGallery: JSON.parse(
          this.currentStoryline.sectionGallery
            ? this.currentStoryline.sectionGallery
            : '{}',
        ),
        ideaGallery: JSON.parse(
          this.currentStoryline.ideaGallery
            ? this.currentStoryline.ideaGallery
            : '{}',
        ),
      };
      this.search = this.currentStoryline.type;
      this.$emit('change', this.currentStoryline);
      if ($event) {
        this.$emit('change:user', this.currentStoryline);
      }
    },
    async handleStorylineFocus(val) {
      if (val.target.value === '') {
        this.storylines = this.allStorylines;
      }
      this.$emit('focus');
    },
  },
  components: { NewFeatureBadge, ClipLoader },
};
</script>
<style lang="scss" scoped>
.presentation-type-label {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.presentation-type-labell {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.presentation-type-combobox-wrapper {
  .presentation-type-step2 {
    display: flex;
    ::v-deep .v-input {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 85%;
      font-size: 14x;
    }
  }
  .storyline-type {
    padding-top: 5px;
    font-size: 14px;
    padding-right: 7px;
  }
  ::v-deep .option-wrapper {
    display: flex;
    align-items: flex-end;
    .plus-icon {
      font-size: 30px;
      margin-right: 10px;
    }
    .prez-heading__wrapper {
      width: 100% !important;
    }
    .prez-heading,
    .prez-description {
      width: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .prez-heading {
      font-size: 14px;
      color: black;
    }
    .prez-description {
      font-size: 12px;
    }
  }
  .v-list .v-list-item--active {
    color: #21a7e0 !important;
  }
  .v-list .v-list-item--active .v-icon {
    color: rgba(0, 0, 0, 0.87);
  }
  .v-list-item--two-line {
    min-height: unset !important;
  }
  .v-tooltip__content {
    .option-tooltip-wrapper {
      max-width: 43vw;
      .prez-heading {
        font-size: 14px;
        color: black;
      }
      .prez-description {
        font-size: 12px;
        color: #666666;
      }
    }
    .option-tooltip-wrapper__small {
      max-width: 25vw;
      .prez-heading {
        font-size: 14px;
        color: black;
      }
      .prez-description {
        font-size: 12px;
        color: #666666;
      }
      ::v-deep .v-list-item__subtitle,
      .prez-description {
        white-space: normal !important;
      }
    }
  }
}
::v-deep .v-list-item {
  // max-width: 200px;
  overflow: hidden;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .v-list-item__title {
    // max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.prezentaion-list-item--add {
  ::v-deep .v-list-item__content {
    display: block;
    .v-list-item__title {
      align-items: center;
      display: flex !important;
      font-size: 14px;

      .add-icon {
        margin-right: 10px;
      }
    }
  }
}
.style-text-field {
  position: relative;

  .prezentation-type-select-dropdown {
    border-radius: 0;

    ::v-deep .v-input__control .v-input__slot {
      padding: 0;
    }
  }
}
.prezentaion-list-item--add {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #ccc;

  &.add-list-item {
    bottom: 49px;
  }
  &.add-item-other {
    padding: 0px 50px;
  }

  .prez-heading-primary {
    justify-content: center;
    font-size: 14px;
    color: #21a7e0;
  }
}
</style>
