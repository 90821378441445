/* eslint-disable */

/** EVENT CATEGORIES */
const NEW_PREZENTATION = 'new_prezentation';
const SEARCH = 'search';
const FINGER_PRINT = 'Fingerprint';
const TEMPLATES = 'templates';
const FEATURE_REQUESTS = 'feature_requests';
const ADD_AUDIENCE = 'add_audience';
const SHARE_TRAIL = 'share_trial';
const BASICS = 'basics';
const SUPPORT = 'support';
const VIEW_PREZENTATION = 'view_prezentation';
const TAILOR_SLIDES_FOR = 'tailor_slides_for';
const LEARN = 'Learn';
const ADVANCE_FILTER = 'AdvanceFilter';
const HELP = 'Help';
const ADD_NEW_AUDIENCE = 'AddNewAudience';
const PROFILE = 'Profile';
const PREZENTATIONS = 'Prezentations';
const SLIDES = 'slides';
const MY_PREZENTATION = 'my_prezentation';

/** EVENT ACTIONS */
export const THUMBNAIL = 'thumbnail';
const AUTOSUGGEST = 'autosuggest';
const HYBRID_SEARCH = 'hybrid_search';
const CREATED = 'created';
const DOWNLOADED = 'download';
const OUTLINE_SAVED = 'outline_saved';
const COMMENTED = 'commented';
const THUMBS_UP = 'thumbs_up';
const THUMBS_DOWN = 'thumbs_down';
const VIEWED = 'view';
const EDITED = 'edited';
const HYBRID = 'hybrid';
const RESULTS_HYBRID = 'results_hybrid';
const SELECT_USER = 'select_user';
const QUERY = 'query';
const RETOOK = 'retook';
const SWITCHED_TEMPLATE = 'switched_template';
const CLICKED_ADD_MORE = 'clicked_add_more';
const MESSAGED = 'messaged';
const SHARED_BY = 'shared_by';
const ADDED = 'added';
const TOOK_TEST = 'took_test';
const SHARED_LINK_COPIED = 'shared_link_copied';
const SHARED_EMAIL = 'shared_sent_email';
const GROUP = 'group';
const FROM = 'from';
const TO = 'to';
const CUSTOM_MESSAGE = 'custom_message';
const PHOTO_UPLOADED = 'photo_uploaded';
const PROFILE_UPDATED = 'profile_updated';
const PASSWORD_CHANGED = 'password_changed';
const T_WATCHED = 'tutorial_watched';
const SLIDE_CHIP = 'slide_chip';
const NO_RESULTS = 'no_results';
const AUDIENCE_ACCESS = 'audience_accessed';
const AUDIENCE_SELECT = 'audience_selected';
const AUDIENCE_GRAY_SELECT = 'gray_audience_selected';
const AUDIENCE_GRAY_REMINDED = 'gray_audience_reminded';
const OUTLINE_BEGINNING = 'outline_beginning';
const OUTLINE_MIDDLE = 'outline_middle';
const OUTLINE_END = 'outline_end';
const LEARN_HOME = 'LearnHome';
const LEARN_COURSE_VIEWED_EXPAND = 'LearnCourseViewedExpand';
const LEARN_COURSE_VIEWED_THUMBNAIL = 'LearnCourseViewedThumbnail';
const LEARN_COURSE_VIEWED_EXPAND_GO_BUTTON = 'LearnCourseViewedExpandGoButton';
const LEARN_COURSE_VIEWED_EXPAND_MODULE_CLICK =
  'LearnCourseViewedExpandModuleClick';
const LEARN_COURSE_VIEWED_THUMBNAIL_MODULE_CLICK =
  'LearnCourseViewedThumbnailModuleClick';
const LEARN_VIDEO_CLICK = 'LearnVideoClick';
const LEARN_VIDEO_CLOSE = 'LearnVideoClose';
const LEARN_VIDEO_SKIP_INTRO = 'LearnVideoSkipIntro';
const LEARN_COURSE_SHARE_LINKEDIN = 'LearnCourseShareLinkedIn';
const LEARN_VIDEO_SEEK_BAR_CLICK = 'LearnVideoSeekBarClick';
const LEARN_VIDEO_SEEK_BAR_END_CLICK = 'LearnVideoSeekBarEndClick';
const LEARN_COURSE_MODULE_PROGRESS = 'learnCourseModuleProgress';
const LEARN_COURSE_CERT_ADDED = 'LearnCourseAddCert';
const LEARN_DOWNLOAD_CERT = 'LearnCourseDownloadCert';
const LEARN_EMAIL_CERT_CLICK = 'LearnCourseEmailCert';
const LEARN_QUIZ_ANSWERS_CHECK = 'LearnQuizCheckAnswer';
const LEARN_PRACTICE_SUBMIT_FIT = 'LearnPracticeSubmitButton';
const LEARN_VIEW_CERT_BUTTON_CLICK = 'LearnCourseViewCertButton';
const LEARN_VIEW_CERT_ICON_CLICK = 'LearnCourseViewCertIcon';
const LEARN_COURSE_SHARE_POST_CLICK = 'LearnCourseSharePost';
const LEARN_SEARCH_AUTO_VIDEO_CLICK = 'LearnSearchAutoVideoClick';
const LEARN_HYBRID_SEARCH_AUTO_VIDEO_CLICK = 'LearnSearchHybridVideoClick';
const LEARN_QUIZ_CLICK = 'LearnQuizClick';
const SEARCH_AUTO_EXACT_LEARN_CLICK = 'SearchAutoExactLearnClick';
const SEARCH_HYBRID_EXACT_LEARN_CLICK = 'SearchHybridExactLearnClick';
const FAVORITED = 'favorited';
const SELECTED = 'Selected';
const UNSELECTED = 'Unselected';
const CHECK_ALL = 'CheckAll';
const UNCHECK_ALL = 'UnCheckAll';
const NOTIFICATION_ICON = 'NotificationsIcon';
const NOTIFICATION_READ = 'NotificationsMarkRead';
const NOTIFICATION_UNREAD = 'NotificationsMarkUnread';
export const WALKTHROUGHS = 'Walkthroughs';
const FAQ = 'FAQ';
export const TUTORIALS = 'Tutorials';
export const QUICKTIPS = 'Quicktips';
const H_SEARCH = 'Search';
const RENAME_PREZENTATION_ICON_CLICK = 'RenameIcon';
const PREZENTATION_COPY_ICON_CLICK = 'PrezentationCopyIcon';
const PREZENTATION_DELETE_ICON_CLICK = 'PrezentationDeleteIconClick';
const PREZENTATION_DELETED = 'PrezentationDeleted';

const helpTypeMap = {
  tutorials: TUTORIALS,
  faqs: FAQ,
  walkthroughs: WALKTHROUGHS,
  quicktips: QUICKTIPS,
};

export const VIDEO_MODULE = 'VideoModule';
export const SEARCH_CHIP = 'SearchChip';

export const FP_TEST_WELCOME = 'FingerprintTestWelcomeScreen';
export const FP_TEST_RETAKEN = 'FingerprintTestRetaken';
export const FP_RESULT_START_FREE_TRIAL = 'FingerprintResultStartFreeTrial';
export const FP_RESULT_GET_IN_TOUCH = 'FingerprintResultGetInTouch';
export const FP_RESULT_HOME = 'FingerprintResultHome';
export const FP_SHARE = 'FingerprintShare';
export const FP_RESULT_COPY_LINK = 'FingerprintResultCopyLink';
export const FP_PROFILE = 'ProfileFingerprint';
export const FP_PROFILE_OTHER = 'ProfileFingerprintOther';
export const FP_PROFILE_OTHER_DELETE = 'ProfileFingerprintOtherDelete';

export const AUDIENCE_TYPE_INDIVIDUAL = 'Individual';
export const AUDIENCE_TYPE_GROUP = 'Group';
export const AUDIENCE_ADD_METHOD_COMPLETE = 'Complete Fingerprint';
export const AUDIENCE_ADD_METHOD_REQUEST = 'Request Fingerprint';

/** EVENT NAMES */
const DIRECT = 'direct';
const GS = 'google_slides';
const SELECT_IDEA = 'select_idea';
const ACTION_SLIDES = 'slides';
const USERS = 'users';

export const _S_ = ';';
const AUD = ';aud;';

const SHARE = 'share';
const SHARE_WITH = 'share_with';
const PRIVATE = 'private';
const MY_TEAM = 'myteam';
const EVERYONE = 'everyone';
const SELECT_COLLEGUES = 'select_colleagues';
const VIEWER = 'viewer';
const REVIEWER = 'reviewer';
const EDITOR = 'editor';

const PermissionLevelMap = {
  1: VIEWER,
  2: REVIEWER,
  3: EDITOR,
};

const SharedToMap = {
  team: MY_TEAM,
  company: EVERYONE,
  user: SELECT_COLLEGUES,
};

const trackEvent = (category, action, name, shouldAddTimeStamp, value) => {
  if (!window._paq) {
    console.warn('Matomo is not configured for this website');
    return;
  }

  const eventAction = shouldAddTimeStamp
    ? `${category}${_S_}${action}${_S_}${getTimeNow()}`
    : `${category}${_S_}${action}`;

  if (name && value) {
    window._paq.push(['trackEvent', category, eventAction, name, value]);
    return;
  } else {
    if (name) {
      window._paq.push(['trackEvent', category, eventAction, name, 1]);
      return;
    }
  }

  window._paq.push(['trackEvent', category, eventAction]);
};

const trackLearnEvent = (eventCategory, eventAction, eventName) => {
  if (!window._paq) {
    console.warn('Matomo is not configured for this website');
    return;
  }
  const eventNameAndTS = `${eventName}${_S_}${getTimeNow()}`;
  if (eventCategory && eventAction) {
    window._paq.push([
      'trackEvent',
      eventCategory,
      eventAction,
      eventNameAndTS,
      1,
    ]);
  }
};

const trackEventNew = (category, action, name, shouldAddTimeStamp, value) => {
  if (!window._paq) {
    console.warn('Matomo is not configured for this website');
    return;
  }

  const eventAction = shouldAddTimeStamp
    ? `${action}${_S_}${getTimeNow()}`
    : `${action}`;

  if (name && value) {
    window._paq.push(['trackEvent', category, eventAction, name, value]);
    return;
  } else {
    if (name) {
      window._paq.push(['trackEvent', category, eventAction, name, 1]);
      return;
    }
  }

  window._paq.push(['trackEvent', category, eventAction]);
};

const getUserInfo = (user) => {
  return user?.user?.id;
};

const getUserAndAudienceInfo = (user, audienceEmail) => {
  return `${getUserInfo(user)}${AUD}${audienceEmail}`;
};

const getSlideNameAndId = (name, id) => {
  return `${name}::${id}`;
};

const getTimeNow = () => {
  return new Date().toUTCString().replaceAll(',', '').replace('GMT', '');
};

const getCourseInfo = (course, user) => {
  return `${course.domain || ''}${_S_}${course.display_name}${_S_}${getUserInfo(
    user,
  )}`;
};

const getCourseModuleInfo = (course, user) => {
  return `${course.domain || ''}${_S_}${course.display_name || ''}${_S_}${
    course.moduleName || ''
  }${_S_}${course.contentName || ''}${_S_}${getUserInfo(user)}`;
};

const getCourseModuleVideoInfo = (course, user) => {
  return `${course.learnCategoryName || ''}${_S_}${
    course.learnCourseName || ''
  }${_S_}${course.learnModuleName || ''}${_S_}${
    course.learnContentName || ''
  }${_S_}${getUserInfo(user)}`;
};

const getCourseModuleLinkedInInfo = (course, user) => {
  return `${course.learnCourseName || ''}${_S_}${getUserInfo(user)}`;
};

const getCourseModuleProgressInfo = (course, user, percentageCompleted) => {
  return `${percentageCompleted || ''}${_S_}${
    course.learnCategoryName || ''
  }${_S_}${course.learnCourseName || ''}${_S_}${
    course.learnModuleName || ''
  }${_S_}${getUserInfo(user)}`;
};

const getCourseDetailsActionText = (data) => {
  const {
    learnCourseName,
    learnModuleName,
    learnCategoryName,
    learnContentName,
  } = data;
  return `${learnCourseName}${_S_}${learnModuleName}${_S_}${learnCategoryName}${_S_}${learnContentName}`;
};

const getPrezentationDetailsText = (prezData) => {
  return `${prezData.name || ''}${_S_}${prezData.type || ''}${_S_}${
    prezData.accessType || ''
  }${_S_}${prezData.createdAt || ''}`;
};

export const MatomoAnalyticsHandler = {
  setUser(user) {
    if (window._paq) {
      window._paq.push(['setUserId', user.email]);
    }
  },
  newPrezentationCreated(prezentation, user) {
    try {
      trackEvent(NEW_PREZENTATION, CREATED, prezentation.type);
      trackEvent(
        NEW_PREZENTATION,
        `${CREATED}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  newPrezentationDownloaded(prezentation, user) {
    try {
      trackEvent(
        NEW_PREZENTATION,
        `${DOWNLOADED}${_S_}${DIRECT}`,
        prezentation.type,
      );
      trackEvent(
        NEW_PREZENTATION,
        `${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  newPrezentationDownloadedGS(prezentation, user) {
    try {
      trackEvent(
        NEW_PREZENTATION,
        `${DOWNLOADED}${_S_}${GS}`,
        prezentation.type,
      );
      trackEvent(
        NEW_PREZENTATION,
        `${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  newPrezentationOutlineSaved(prezentation, user) {
    try {
      trackEvent(NEW_PREZENTATION, OUTLINE_SAVED, prezentation.type);
      trackEvent(
        NEW_PREZENTATION,
        `${OUTLINE_SAVED}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  newPrezentationAddComments(prezentation, user) {
    try {
      trackEvent(NEW_PREZENTATION, COMMENTED, prezentation.type);
      trackEvent(
        NEW_PREZENTATION,
        `${COMMENTED}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  newPrezentationSlideThumbsUp(title, user, audienceID) {
    try {
      trackEvent(NEW_PREZENTATION, THUMBS_UP, title);
      trackEvent(
        NEW_PREZENTATION,
        `${THUMBS_UP}${_S_}${getUserAndAudienceInfo(user, audienceID)}`,
        title,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  newPrezentationSlideThumbsDown(title, user, audienceID) {
    try {
      trackEvent(NEW_PREZENTATION, THUMBS_DOWN, title);
      trackEvent(
        NEW_PREZENTATION,
        `${THUMBS_DOWN}${_S_}${getUserAndAudienceInfo(user, audienceID)}`,
        title,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  myPrezentationDownloadedGS(prezentation, user) {
    try {
      trackEvent(
        MY_PREZENTATION,
        `${DOWNLOADED}${_S_}${GS}`,
        prezentation.type,
      );
      trackEvent(
        MY_PREZENTATION,
        `${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  myPrezentationDownloaded(prezentation, user) {
    try {
      trackEvent(
        MY_PREZENTATION,
        `${DOWNLOADED}${_S_}${DIRECT}`,
        prezentation.type,
      );
      trackEvent(
        MY_PREZENTATION,
        `${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  myPrezentationSaved(prezentation, user) {
    try {
      trackEvent(MY_PREZENTATION, OUTLINE_SAVED, prezentation.type);
      trackEvent(
        MY_PREZENTATION,
        `${OUTLINE_SAVED}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  myPrezentationAddComments(prezentation, user) {
    try {
      trackEvent(MY_PREZENTATION, COMMENTED, prezentation.type);
      trackEvent(
        MY_PREZENTATION,
        `${COMMENTED}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  myPrezentationSlideThumbsUp(title, user, audienceID) {
    try {
      trackEvent(MY_PREZENTATION, THUMBS_UP, title);
      trackEvent(
        MY_PREZENTATION,
        `${THUMBS_UP}${_S_}${getUserAndAudienceInfo(user, audienceID)}`,
        title,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  myPrezentationSlideThumbsDown(title, user, audienceID) {
    try {
      trackEvent(MY_PREZENTATION, THUMBS_DOWN, title);
      trackEvent(
        MY_PREZENTATION,
        `${THUMBS_DOWN}${_S_}${getUserAndAudienceInfo(user, audienceID)}`,
        title,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesViewed(name, id, user, data) {
    try {
      const slideNameWithId = getSlideNameAndId(name, id);
      if (data.query) {
        trackEvent(SEARCH, `${AUTOSUGGEST}${_S_}${VIEWED}`, slideNameWithId);
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${VIEWED}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else if (data.from && data.from === 'search') {
        trackEvent(SEARCH, `${HYBRID_SEARCH}${_S_}${VIEWED}`, slideNameWithId);
        trackEvent(
          SEARCH,
          `${HYBRID_SEARCH}${_S_}${VIEWED}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else {
        trackEvent(SLIDES, VIEWED, slideNameWithId);
        trackEvent(
          SLIDES,
          `${VIEWED}${_S_}${getUserAndAudienceInfo(user, data.audience.email)}`,
          slideNameWithId,
          true,
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  slidesDownloadedExpandedView(title, id, user, data) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      if (data.query) {
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${DIRECT}`,
          slideNameWithId,
        );
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else {
        trackEvent(
          SLIDES,
          `${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${DIRECT}`,
          slideNameWithId,
        );
        trackEvent(
          SLIDES,
          `${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  slidesDownloadedThumbnail(title, id, user, data) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      if (data.query) {
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${DIRECT}`,
          slideNameWithId,
        );
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else {
        trackEvent(
          SLIDES,
          `${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${DIRECT}`,
          slideNameWithId,
        );
        trackEvent(
          SLIDES,
          `${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  slidesDownloadedGSExpandedView(title, id, user, data) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      if (data.query) {
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${GS}`,
          slideNameWithId,
        );
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else if (data.fromImageReplace) {
        trackEvent(
          SLIDES,
          `${EXPANDED_VIEW}${_S_}image_replacement${_S_}${DOWNLOADED}${_S_}${GS}`,
          slideNameWithId,
        );
        trackEvent(
          SLIDES,
          `${EXPANDED_VIEW}${_S_}image_replacement${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else {
        trackEvent(
          SLIDES,
          `${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${GS}`,
          slideNameWithId,
        );
        trackEvent(
          SLIDES,
          `${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  slidesDownloadedGSThumbnail(title, id, user, data) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      if (data.query) {
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${GS}`,
          slideNameWithId,
        );
        trackEvent(
          SEARCH,
          `${AUTOSUGGEST}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      } else {
        trackEvent(
          SLIDES,
          `${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${GS}`,
          slideNameWithId,
        );
        trackEvent(
          SLIDES,
          `${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
            user,
            data.audience.email,
          )}`,
          slideNameWithId,
          true,
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  slidesThumpUp(title, id, user) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      trackEvent(SLIDES, THUMBS_UP, slideNameWithId);
      trackEvent(
        SLIDES,
        `${THUMBS_UP}${_S_}${getUserInfo(user)}`,
        slideNameWithId,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesThumpDown(title, id, user) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      trackEvent(SLIDES, THUMBS_DOWN, slideNameWithId);
      trackEvent(
        SLIDES,
        `${THUMBS_DOWN}${_S_}${getUserInfo(user)}`,
        slideNameWithId,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideChipSelected(keyword, user, data) {
    try {
      trackEvent(SEARCH, SLIDE_CHIP, keyword);
      trackEvent(
        SEARCH,
        `${SLIDE_CHIP}${_S_}${getUserAndAudienceInfo(
          user,
          data.audience.email,
        )}`,
        keyword,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideFavorited(title, id, user, data) {
    try {
      const slideNameWithId = getSlideNameAndId(title, id);
      trackEvent(SLIDES, FAVORITED, slideNameWithId);
      trackEvent(
        SLIDES,
        `${FAVORITED}${_S_}${getUserAndAudienceInfo(
          user,
          data.audience.email,
        )}`,
        slideNameWithId,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchSelectIdea(name, user) {
    try {
      trackEvent(SEARCH, SELECT_IDEA, name);
      trackEvent(
        SEARCH,
        `${SELECT_IDEA}${_S_}${getUserInfo(user)}`,
        name,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchSelectPeople(name, user) {
    try {
      trackEvent(SEARCH, SELECT_USER, name);
      trackEvent(
        SEARCH,
        `${SELECT_USER}${_S_}${getUserInfo(user)}`,
        name,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchHybrid(searchTerm, user) {
    try {
      trackEvent(SEARCH, HYBRID, searchTerm);
      trackEvent(
        SEARCH,
        `${HYBRID}${_S_}${getUserInfo(user)}`,
        searchTerm,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchHybridResultsIdeas(list, user) {
    try {
      trackEvent(SEARCH, RESULTS_HYBRID, ACTION_SLIDES);
    } catch (e) {
      console.log(e);
    }
  },
  searchHybridResultsPeople(list, user) {
    try {
      trackEvent(SEARCH, RESULTS_HYBRID, USERS);
    } catch (e) {
      console.log(e);
    }
  },
  searchActualQuery(query, user) {
    try {
      trackEvent(SEARCH, QUERY, query);
      trackEvent(SEARCH, `${QUERY}${_S_}${getUserInfo(user)}`, query, true);
    } catch (e) {
      console.log(e);
    }
  },
  searchHybridUserSelected(userName) {
    try {
      trackEvent(SEARCH, `${HYBRID_SEARCH}${_S_}user`, userName);
    } catch (e) {
      console.log(e);
    }
  },
  hybridSearchSlidesDownloadedExpandedView(title, id, user, data) {
    try {
      const slideTitle = getSlideNameAndId(title, id);
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${DIRECT}`,
        slideTitle,
      );
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
          user,
          data.audience.email,
        )}`,
        slideTitle,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  hybridSearchSlidesDownloadedThumbnail(title, id, user, data) {
    try {
      const slideTitle = getSlideNameAndId(title, id);
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${DIRECT}`,
        slideTitle,
      );
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${DIRECT}${_S_}${getUserAndAudienceInfo(
          user,
          data.audience.email,
        )}`,
        slideTitle,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  hybridSearchSlidesDownloadedGSExpandedView(title, id, user, data) {
    try {
      const slideTitle = getSlideNameAndId(title, id);
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${GS}`,
        slideTitle,
      );
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${EXPANDED_VIEW}${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
          user,
          data.audience.email,
        )}`,
        slideTitle,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  hybridSearchSlidesDownloadedGSThumbnail(title, id, user, data) {
    try {
      const slideTitle = getSlideNameAndId(title, id);
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${GS}`,
        slideTitle,
      );
      trackEvent(
        SEARCH,
        `${HYBRID_SEARCH}${_S_}${THUMBNAIL}${_S_}${DOWNLOADED}${_S_}${GS}${_S_}${getUserAndAudienceInfo(
          user,
          data.audience.email,
        )}`,
        slideTitle,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  searchNoResults(query) {
    try {
      trackEvent(SEARCH, `${HYBRID_SEARCH}${_S_}${NO_RESULTS}`, query);
    } catch (e) {
      console.log(e);
    }
  },
  audienceAdded(user, fn, ln, email) {
    try {
      trackEvent(ADD_AUDIENCE, ADDED, email);
      trackEvent(
        ADD_AUDIENCE,
        `${ADDED}${_S_}${getUserInfo(user)}`,
        email,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  audienceAddedGroup(name, user) {
    try {
      trackEvent(ADD_AUDIENCE, GROUP, name);
      trackEvent(
        ADD_AUDIENCE,
        `${GROUP}${_S_}${getUserInfo(user)}`,
        name,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  audienceTookTest(fn, ln, email) {
    try {
      trackEvent(ADD_AUDIENCE, TOOK_TEST, email);
    } catch (e) {
      console.log(e);
    }
  },
  audienceSharedLinkCopied(user, fn, ln, email) {
    try {
      trackEvent(ADD_AUDIENCE, SHARED_LINK_COPIED, email);
      trackEvent(
        ADD_AUDIENCE,
        `${SHARED_LINK_COPIED}${_S_}${getUserInfo(user)}`,
        email,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  audienceSharedEmail(user, fn, ln, email) {
    try {
      trackEvent(ADD_AUDIENCE, SHARED_EMAIL, email);
      trackEvent(
        ADD_AUDIENCE,
        `${SHARED_EMAIL}${_S_}${getUserInfo(user)}`,
        email,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  shareFreeTrail(user, mode, invited) {
    try {
      trackEvent(SHARE_TRAIL, FROM, getUserInfo(user));
      trackEvent(SHARE_TRAIL, TO, getUserInfo(invited));
      trackEvent(SHARE_TRAIL, mode);
      trackEvent(SHARE_TRAIL, CUSTOM_MESSAGE, invited.message);
    } catch (e) {
      console.log(e);
    }
  },
  profileUpdated(data) {
    try {
      trackEvent(BASICS, PROFILE_UPDATED, data.id);
    } catch (e) {
      console.log(e);
    }
  },
  passwordChanged(userName) {
    try {
      trackEvent(BASICS, PASSWORD_CHANGED, userName);
    } catch (e) {
      console.log(e);
    }
  },
  photoUploaded(fn, ln, email) {
    try {
      trackEvent(BASICS, PHOTO_UPLOADED, email);
    } catch (e) {
      console.log(e);
    }
  },
  fingerPrintShared(user) {
    try {
      trackEvent(FINGER_PRINT, SHARED_BY, getUserInfo(user));
    } catch (e) {
      console.log(e);
    }
  },
  fingerPrintRetook(user) {
    try {
      trackEvent(
        FINGER_PRINT,
        RETOOK,
        `${getUserInfo(user)} - ${user.fingerPrint}`,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  templateSwitched(user, theme) {
    try {
      trackEvent(TEMPLATES, SWITCHED_TEMPLATE, theme.name);
      trackEvent(
        TEMPLATES,
        `${SWITCHED_TEMPLATE}${_S_}${getUserInfo(user)}`,
        theme.name,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  templateClickedAddMore(user) {
    try {
      trackEvent(TEMPLATES, CLICKED_ADD_MORE, getUserInfo(user));
    } catch (e) {
      console.log(e);
    }
  },
  featureRequestThumbsUp(user, wish) {
    try {
      trackEvent(FEATURE_REQUESTS, THUMBS_UP, wish);
      trackEvent(
        FEATURE_REQUESTS,
        `${THUMBS_UP}${_S_}${getUserInfo(user)}`,
        wish,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  featureRequestThumbsDown(user, wish) {
    try {
      trackEvent(FEATURE_REQUESTS, THUMBS_DOWN, wish);
      trackEvent(
        FEATURE_REQUESTS,
        `${THUMBS_DOWN}${_S_}${getUserInfo(user)}`,
        wish,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  featureRequested(user, wish) {
    try {
      trackEvent(FEATURE_REQUESTS, MESSAGED, wish);
      trackEvent(
        FEATURE_REQUESTS,
        `${MESSAGED}${_S_}${getUserInfo(user)}`,
        wish,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },
  tutorialWatched(name, user) {
    try {
      trackEvent(SUPPORT, T_WATCHED, name);
      trackEvent(SUPPORT, `${T_WATCHED}${_S_}${getUserInfo(user)}`, name, true);
    } catch (e) {
      console.log(e);
    }
  },

  sharePrezentation(origin, shareActionData, prezentation, user) {
    try {
      let actionName = `${origin.replace(/-/g, _S_)}${_S_}${SHARE}`;
      trackEvent(VIEW_PREZENTATION, actionName, prezentation.type);
      trackEvent(
        VIEW_PREZENTATION,
        `${actionName}${_S_}${getUserAndAudienceInfo(
          user,
          prezentation.audienceID,
        )}`,
        prezentation.type,
        true,
      );

      if (shareActionData.length) {
        shareActionData.forEach((shareTo) => {
          const permission = PermissionLevelMap[shareTo.permission_level];
          const shareEntity = SharedToMap[shareTo.shared_entity_type];
          const perm_share = `${_S_}${shareEntity}${_S_}${permission}`;
          const shareActionName = `${actionName}${_S_}${SHARE_WITH}${perm_share}`;
          trackEvent(VIEW_PREZENTATION, shareActionName, prezentation.type);
        });
      } else {
        const shareActionName = `${actionName}${_S_}${SHARE_WITH}${_S_}${PRIVATE}`;
        trackEvent(VIEW_PREZENTATION, shareActionName, prezentation.type);
      }
    } catch (e) {
      console.log(e);
    }
  },

  tailorSlidesAudienceAccessed(origin, prezentation, user) {
    try {
      if (origin === SLIDES) {
        trackEvent(
          TAILOR_SLIDES_FOR,
          `${SLIDES}${_S_}${AUDIENCE_ACCESS}${_S_}${getUserInfo(user)}`,
          null,
          true,
        );
      } else if (origin === MY_PREZENTATION) {
        trackEvent(
          TAILOR_SLIDES_FOR,
          `${MY_PREZENTATION}${_S_}${AUDIENCE_ACCESS}${_S_}${getUserAndAudienceInfo(
            user,
            prezentation.audienceID,
          )}`,
          prezentation.type,
          true,
        );
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  },
  tailorSlidesAudienceSelected(origin, prezentation, user) {
    try {
      if (origin === SLIDES) {
        trackEvent(
          TAILOR_SLIDES_FOR,
          `${SLIDES}${_S_}${AUDIENCE_SELECT}${_S_}${getUserInfo(user)}`,
          null,
          true,
        );
      } else if (origin === MY_PREZENTATION) {
        trackEvent(
          TAILOR_SLIDES_FOR,
          `${MY_PREZENTATION}${_S_}${AUDIENCE_SELECT}${_S_}${getUserAndAudienceInfo(
            user,
            prezentation.audienceID,
          )}`,
          prezentation.type,
          true,
        );
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  },
  tailorSlidesGrayAudienceSelected(origin, prezentation, user) {
    try {
      if (origin === SLIDES) {
        trackEvent(
          TAILOR_SLIDES_FOR,
          `${SLIDES}${_S_}${AUDIENCE_GRAY_SELECT}${_S_}${getUserInfo(user)}`,
          null,
          true,
        );
      } else if (origin === MY_PREZENTATION) {
        trackEvent(
          TAILOR_SLIDES_FOR,
          `${MY_PREZENTATION}${_S_}${AUDIENCE_GRAY_SELECT}${_S_}${getUserAndAudienceInfo(
            user,
            prezentation.audienceID,
          )}`,
          prezentation.type,
          true,
        );
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  },
  tailorSlidesGrayAudienceReminded(origin, user) {
    try {
      trackEvent(
        TAILOR_SLIDES_FOR,
        `${origin}${_S_}${AUDIENCE_GRAY_REMINDED}${_S_}${getUserInfo(user)}`,
        null,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },

  newPrezentationOutlineIdeas(prezentation, user) {
    try {
      prezentation?.beginningOutlines?.forEach((bOutlineIdea, index) => {
        trackEvent(
          NEW_PREZENTATION,
          `${OUTLINE_BEGINNING}${_S_}${
            prezentation.type
          }${_S_}${getUserAndAudienceInfo(user, prezentation.audienceID)}`,
          `${bOutlineIdea}_${index + 1}`,
          true,
        );
      });

      prezentation?.middleOutlines?.forEach((mOutlineIdea, index) => {
        trackEvent(
          NEW_PREZENTATION,
          `${OUTLINE_MIDDLE}${_S_}${
            prezentation.type
          }${_S_}${getUserAndAudienceInfo(user, prezentation.audienceID)}`,
          `${mOutlineIdea}_${index + 1}`,
          true,
        );
      });

      prezentation?.endOutlines?.forEach((eOutlineIdea, index) => {
        trackEvent(
          NEW_PREZENTATION,
          `${OUTLINE_END}${_S_}${
            prezentation.type
          }${_S_}${getUserAndAudienceInfo(user, prezentation.audienceID)}`,
          `${eOutlineIdea}_${index + 1}`,
          true,
        );
      });
    } catch (e) {
      console.log(e);
    }
  },

  myPrezentationOutlineIdeas(prezentation, user) {
    try {
      prezentation?.beginningOutlines.forEach((bOutlineIdea, index) => {
        trackEvent(
          MY_PREZENTATION,
          `${OUTLINE_BEGINNING}${_S_}${
            prezentation.type
          }${_S_}${getUserAndAudienceInfo(user, prezentation.audienceID)}`,
          `${bOutlineIdea}_${index + 1}`,
        );
      });

      prezentation?.middleOutlines.forEach((mOutlineIdea, index) => {
        trackEvent(
          MY_PREZENTATION,
          `${OUTLINE_MIDDLE}${_S_}${
            prezentation.type
          }${_S_}${getUserAndAudienceInfo(user, prezentation.audienceID)}`,
          `${mOutlineIdea}_${index + 1}`,
        );
      });

      prezentation?.endOutlines.forEach((eOutlineIdea, index) => {
        trackEvent(
          MY_PREZENTATION,
          `${OUTLINE_END}${_S_}${
            prezentation.type
          }${_S_}${getUserAndAudienceInfo(user, prezentation.audienceID)}`,
          `${eOutlineIdea}_${index + 1}`,
        );
      });
    } catch (e) {
      console.log(e);
    }
  },

  learnHome(user) {
    try {
      trackLearnEvent(LEARN, LEARN_HOME, getUserInfo(user));
    } catch (e) {
      console.log(e);
    }
  },

  learnCourseViewedExpand(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_VIEWED_EXPAND,
        getCourseInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnCourseViewedThumbnail(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_VIEWED_THUMBNAIL,
        getCourseInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnCourseViewedExpandGoButton(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_VIEWED_EXPAND_GO_BUTTON,
        getCourseInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnCourseViewedExpandModuleClick(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_VIEWED_EXPAND_MODULE_CLICK,
        getCourseModuleInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnCourseViewedThumbnailModuleClick(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_VIEWED_THUMBNAIL_MODULE_CLICK,
        getCourseModuleInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnVideoClick(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_VIDEO_CLICK,
        getCourseModuleInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnVideoClose(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_VIDEO_CLOSE,
        getCourseModuleVideoInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnVideoSkipIntro(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_VIDEO_SKIP_INTRO,
        getCourseModuleVideoInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnVideoSeekBarClick(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_VIDEO_SEEK_BAR_CLICK,
        getCourseModuleVideoInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnVideoSeekBarEndClick(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_VIDEO_SEEK_BAR_END_CLICK,
        getCourseModuleVideoInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  learnCourseModuleProgress(course, user, percentageCompleted) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_MODULE_PROGRESS,
        getCourseModuleProgressInfo(course, user, percentageCompleted),
      );
    } catch (e) {
      console.log(e);
    }
  },

  searchLearn(data) {
    try {
      const { origin, isExact, title, user, searchKeyword } = data;
      const eventName = `${searchKeyword}${_S_}${title}${_S_}${getUserInfo(
        user,
      )}`;
      if (origin === 'auto') {
        if (isExact) {
          trackLearnEvent(LEARN, SEARCH_AUTO_EXACT_LEARN_CLICK, eventName);
        } else {
          trackLearnEvent(LEARN, LEARN_SEARCH_AUTO_VIDEO_CLICK, eventName);
        }
        return;
      }
      if (origin === 'hybrid') {
        if (isExact) {
          trackLearnEvent(LEARN, SEARCH_HYBRID_EXACT_LEARN_CLICK, eventName);
        } else {
          trackLearnEvent(
            LEARN,
            LEARN_HYBRID_SEARCH_AUTO_VIDEO_CLICK,
            eventName,
          );
        }
        return;
      }
    } catch (e) {
      console.log(e);
    }
  },

  advancedFilter(data, user) {
    try {
      const { category, selected, title, isAll } = data;
      let eventAction;
      if (isAll) {
        eventAction = `${category}_${selected ? CHECK_ALL : UNCHECK_ALL}`;
      } else {
        eventAction = `${category}_${title}_${
          selected ? SELECTED : UNSELECTED
        }`;
      }
      trackEventNew(ADVANCE_FILTER, eventAction, getUserInfo(user), false);
    } catch (e) {
      console.log(e);
    }
  },

  trackHelp(data, user) {
    /*
     * type -> walkthroughs, tutorials, faqs, quicktips
     * tActionItem - for tutorials only - VideoModule or Searchchip
     * tActionValue - for tutorials only - module name or search chip value
     * actionValue for other
     */

    try {
      let eventAction = '';
      const {
        type,
        tActionItem,
        tActionValue,
        actionValue,
        isSearch,
        searchKey,
      } = data;
      if (isSearch) {
        eventAction = `${helpTypeMap[type]}${_S_}${H_SEARCH}${_S_}${searchKey}`;
      } else {
        switch (type) {
          case 'tutorials':
            eventAction = helpTypeMap[type];
            if (tActionItem) {
              eventAction = `${eventAction}${_S_}${tActionItem}`;
            }
            if (tActionValue) {
              eventAction = `${eventAction}${_S_}${tActionValue}`;
            }
            break;

          case 'walkthroughs':
          case 'faqs':
          case 'quicktips':
            eventAction = helpTypeMap[type];
            if (actionValue) {
              eventAction = `${eventAction}${_S_}${actionValue}`;
            }
            break;

          default:
            break;
        }
      }

      trackEventNew(HELP, eventAction, getUserInfo(user), false);
    } catch (e) {
      console.log(e);
    }
  },

  trackFingerprint(data) {
    try {
      const { user, audience, actionName, sharedEntities } = data;
      let eventName = getUserInfo(user);
      if (actionName === FP_SHARE) {
        if (sharedEntities.length) {
          sharedEntities.forEach((sEntity) => {
            const { type, id } = sEntity;
            trackEventNew(FINGER_PRINT, `${actionName}-${type}`, id, false);
          });
        } else {
          trackEventNew(
            FINGER_PRINT,
            `${actionName}-Private`,
            eventName,
            false,
          );
        }
        return;
      }

      switch (actionName) {
        case FP_PROFILE_OTHER:
        case FP_PROFILE_OTHER_DELETE:
        case FP_RESULT_COPY_LINK:
        case FP_TEST_RETAKEN:
          if (audience) {
            eventName = `${eventName}${AUD}${audience.id}`;
          }

        default:
          break;
      }

      trackEventNew(FINGER_PRINT, actionName, eventName, false);
    } catch (e) {
      console.log(e);
    }
  },

  trackAddNewAudience(data, user) {
    try {
      const { firstName, lastName, id, companyName, type, method, origin } =
        data;
      const actionName = `${origin}${_S_}${firstName}${_S_}${lastName}${_S_}${id}${_S_}${companyName}${_S_}${type}${_S_}${method}`;
      trackEventNew(ADD_NEW_AUDIENCE, actionName, getUserInfo(user), false);
    } catch (e) {
      console.log(e);
    }
  },

  trackAddCertification(user, courseName) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${courseName}`;
      trackEvent(LEARN, LEARN_COURSE_CERT_ADDED, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackDownloadCertClick(user, courseName) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${courseName}`;
      trackEvent(LEARN, LEARN_DOWNLOAD_CERT, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackEmailCertClick(user, courseName) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${courseName}`;
      trackEvent(LEARN, LEARN_EMAIL_CERT_CLICK, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackQuizClick(data, user) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getCourseDetailsActionText(
        data,
      )}`;
      trackEvent(LEARN, LEARN_QUIZ_CLICK, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackQuizAnswersCheck(data, user) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getCourseDetailsActionText(
        data,
      )}`;
      trackEvent(LEARN, LEARN_QUIZ_ANSWERS_CHECK, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackFitSubmit(data, user) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getCourseDetailsActionText(
        data,
      )}`;
      trackEvent(LEARN, LEARN_PRACTICE_SUBMIT_FIT, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackViewCertificate(user, courseName) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${courseName}`;
      trackEvent(LEARN, LEARN_VIEW_CERT_BUTTON_CLICK, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackNotificationIconClick(user) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}`;
      trackEvent(PROFILE, NOTIFICATION_ICON, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackNotificationRead(user, notificationText) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${notificationText}`;
      trackEvent(PROFILE, NOTIFICATION_READ, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackNotificationUnread(user, notificationText) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${notificationText}`;
      trackEvent(PROFILE, NOTIFICATION_UNREAD, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  tractViewCertificateIconClick(user, courseName) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${courseName}`;
      trackLearnEvent(LEARN, LEARN_VIEW_CERT_ICON_CLICK, actionName);
    } catch (e) {
      console.log(e);
    }
  },

  trackLearnCourseSharePost(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_SHARE_POST_CLICK,
        getCourseModuleLinkedInInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  trackLearnCourseAddPost(course, user) {
    try {
      trackLearnEvent(
        LEARN,
        LEARN_COURSE_CERT_ADDED,
        getCourseModuleLinkedInInfo(course, user),
      );
    } catch (e) {
      console.log(e);
    }
  },

  trackPrezRenameIconClick(user, prezData) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getPrezentationDetailsText(
        prezData,
      )}`;
      trackEvent(
        PREZENTATIONS,
        RENAME_PREZENTATION_ICON_CLICK,
        actionName,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },

  trackPrezCopyIconClick(user, prezData) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getPrezentationDetailsText(
        prezData,
      )}`;
      trackEvent(PREZENTATIONS, PREZENTATION_COPY_ICON_CLICK, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },

  trackPrezDeleteIconClick(user, prezData) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getPrezentationDetailsText(
        prezData,
      )}`;
      trackEvent(
        PREZENTATIONS,
        PREZENTATION_DELETE_ICON_CLICK,
        actionName,
        true,
      );
    } catch (e) {
      console.log(e);
    }
  },

  trackDeletePrezentation(user, prezData) {
    try {
      const { firstName, lastName } = user;
      const actionName = `${firstName}${_S_}${lastName}${_S_}${getPrezentationDetailsText(
        prezData,
      )}`;
      trackEvent(PREZENTATIONS, PREZENTATION_DELETED, actionName, true);
    } catch (e) {
      console.log(e);
    }
  },
};

export const trackMatomoEvent = (eventCategory, eventAction, eventName) => {
  if (!window._paq) {
    console.warn('Matomo is not configured for this website');
    return;
  }

  window._paq.push(['trackEvent', eventCategory, eventAction, eventName, 1]);
};
