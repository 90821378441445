<template>
  <div class="pt-list__tips">
    <div class="pt-list__tip-container">
      <div class="pt-list__tip-container__icon">
        <img :src="'/assets/img/generate/prompt-example.svg'" alt />
      </div>
      <div class="pt-list__tip-container__text">
        {{ tips.promptExample }}
      </div>
    </div>
    <div class="pt-list__tip-container">
      <div class="pt-list__tip-container__icon">
        <img :src="'/assets/img/generate/prompt-correction.svg'" alt />
      </div>
      <div class="pt-list__tip-container__text mb-text">
        {{ tips.promptCorrection }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProTips',
  props: {
    tips: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-list {
  &__tips {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 12px;
  }
  &__tip-container {
    display: flex;
    column-gap: 10px;
    &__icon {
      padding: 5px 0;
      img {
        height: 24px;
        width: 24px;
      }
    }

    &__text {
      display: flex;
      box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.1),
        1px 1px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 100%;
      padding: 8px;
      text-align: left;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      color: #212121;
    }
    .mb-text {
      margin-bottom: 10px;
    }
  }
}
</style>
