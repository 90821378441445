<template>
  <div class="prezentations-gallery-container">
    <div>
      <SearchInputField
        :placeholder="
          $t('prezentationGallery.prezentationSearchFieldPlaceholder')
        "
        :onChange="filterPrezentationsWithTextField"
      />
    </div>
    <div>
      <div class="filters-section">
        <div class="filters-expand" @click="handleFiltersToggle">
          <span class="filter-title">
            {{ $t('build.step3.prezentationFilters') }}
          </span>
          <v-icon>
            {{ !isFiltersExpand ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
          </v-icon>
        </div>
        <div class="advanced-filter-wrapper" v-if="isFiltersExpand">
          <div class="filters-action reset-btn">
            <v-btn
              text
              color="primary"
              @click="resetAllFiltersOfAdvancedFilters"
              :disabled="!isAdvancedFilterChanged || searchProgress"
            >
              {{ $t('prezentationsList.resetAllFilters') }}
            </v-btn>
          </div>

          <v-expansion-panels
            accordion
            multiple
            v-model="advancedFilterActivePanels"
          >
            <v-expansion-panel
              v-for="item in advancedItems"
              :key="item.title"
              expand
            >
              <v-expansion-panel-header>
                <div class="exp-filter-header">
                  <img
                    class="filter-icon"
                    alt
                    :src="`/assets/img/${item.icon}.svg`"
                    width="24"
                    height="24"
                  />
                  <div>{{ $t(item.display_title) }}</div>
                  <div
                    :class="
                      item.title === 'Created from'
                        ? 'badge-container'
                        : 'badge-container-author'
                    "
                    v-if="
                      item.title === 'Created from' || item.title === 'Author'
                    "
                  >
                    <NewFeatureBadge
                      module-name="upload"
                      :feature-id="'upload_landing_id'"
                      :offsetX="'130'"
                      :offsetY="'23'"
                      :size="'medium'"
                      :inline="true"
                    ></NewFeatureBadge>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item
                  v-if="item.includeAllOption"
                  :disabled="
                    item.disableAll &&
                    item.disableAll === true &&
                    (item.title === 'Access' || item.title === 'Author')
                  "
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-model="item.checkAll"
                      color="primary"
                      :on-icon="'mdi-checkbox-marked-outline'"
                      :off-icon="'mdi-checkbox-blank-outline'"
                      @click="handleAllOptionClickForCategories(item)"
                    />
                  </v-list-item-action>
                  <img
                    alt
                    :src="`/assets/img/filter-all.svg`"
                    width="20"
                    height="20"
                    class="advanced-filter-icon"
                    v-if="item.showIcon"
                  />
                  <v-list-item-content>
                    <v-list-item-title
                      class="advanced-filter-content-text"
                      v-text="$t('overnightPresentations.all')"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  v-for="child in item.items"
                  :key="child.title"
                  :disabled="child.disabled"
                  :data-pendo-id="child.key"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :on-icon="'mdi-checkbox-marked-outline'"
                        :off-icon="'mdi-checkbox-blank-outline'"
                        :input-value="active"
                        v-model="child.active"
                        color="primary"
                        @click="onAdvancedFilerChange(item)"
                      />
                    </v-list-item-action>
                    <img
                      alt
                      :src="`/assets/img/filter-${child.icon}.svg`"
                      width="20"
                      height="20"
                      v-if="child.icon"
                      class="advanced-filter-icon"
                    />
                    <v-list-item-content
                      v-if="child.title === 'Overnight Prezentations'"
                    >
                      <div class="filter-op-title__wrapper">
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </div>
                    </v-list-item-content>
                    <v-list-item-content v-else-if="child.title === 'Generate'">
                      <div class="filter-op-title__wrapper">
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </div>
                    </v-list-item-content>
                    <v-list-item-content v-else-if="child.title === 'Redesign'">
                      <div class="filter-op-title__wrapper">
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="child.title === 'Template Converter'"
                    >
                      <div class="filter-op-title__wrapper">
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </div>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title
                        class="advanced-filter-content-text"
                        v-text="$t(child.display_title)"
                      />
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <div v-if="item.isCustomGroup" class="customGroupWrapper">
                  <div class="fromDateWrapper">
                    <div class="label">
                      {{ $t('overnightPresentations.from') }}
                    </div>
                    <div class="datePicker">
                      <v-menu
                        v-model="fromDateMenu"
                        :close-on-content-click="false"
                        :close-on-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fromDateStr"
                            :placeholder="
                              $t('overnightPresentations.dateFormat')
                            "
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @focus="getCurrentDateFormatted('from')"
                            @mouseup="getCurrentDateFormatted('from')"
                          >
                            <template #append>
                              <v-icon
                                v-if="fromDateStr !== ''"
                                @click="handleClearDate('from')"
                                class="clearIcon"
                              >
                                {{ 'mdi-close' }}
                              </v-icon>
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="calendarIcon"
                              >
                                {{ 'mdi-calendar' }}
                              </v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fromDate"
                          :allowedDates="allowedFromDates"
                          no-title
                          scrollable
                          @input="fromDateMenu = false"
                          @change="() => handleDatePickerTextChange('from')"
                          color="#20a7e0"
                          :picker-date.sync="pickerDate"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <div class="toDateWrapper">
                    <div class="label">
                      {{ $t('overnightPresentations.to') }}
                    </div>
                    <div class="datePicker">
                      <v-menu
                        v-model="toDateMenu"
                        :close-on-content-click="false"
                        :close-on-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="toDateStr"
                            :placeholder="
                              $t('overnightPresentations.dateFormat')
                            "
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @focus="getCurrentDateFormatted('to')"
                            @mouseup="getCurrentDateFormatted('to')"
                          >
                            <template #append>
                              <v-icon
                                v-if="toDateStr !== ''"
                                @click="handleClearDate('to')"
                                class="clearIcon"
                              >
                                {{ 'mdi-close' }}
                              </v-icon>
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="calendarIcon"
                              >
                                {{ 'mdi-calendar' }}
                              </v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="toDate"
                          :allowedDates="allowedToDates"
                          no-title
                          scrollable
                          @input="toDateMenu = false"
                          @change="() => handleDatePickerTextChange('to')"
                          color="#20a7e0"
                          :picker-date.sync="pickerDate"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <!-- <div class="applyDatesCTA">
                    <v-btn
                      @click="onAdvancedFilerChange(item)"
                      :disabled="
                        (fromDateStr === '' && toDateStr === '') ||
                        (!fromDateStr && !toDateStr)
                      "
                    >
                      {{ $t('prezentationsList.applyCustomDates') }}
                    </v-btn>
                  </div> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="filters-action apply-btn">
            <v-btn
              rounded
              color="primary"
              :loading="searchProgress"
              @click="handleApplyFilters"
            >
              {{ $t('slides.applyFilters') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isFiltersExpand">
      <div class="view-icons" v-if="filteredPrezentations.length">
        <v-icon
          @click="() => setViewType('grid')"
          :color="viewType === 'grid' ? `#21a7e0` : 'inherit'"
          v-tooltip="{
            content: $t('prezentationGallery.gridView'),
            placement: 'top-center',
          }"
        >
          mdi-view-grid{{ viewType === 'grid' ? '' : '-outline' }}
        </v-icon>
        <v-icon
          @click="() => setViewType('agenda')"
          :color="viewType === 'agenda' ? `#21a7e0` : 'inherit'"
          v-tooltip="{
            content: $t('prezentationGallery.columnView'),
            placement: 'top-center',
          }"
        >
          mdi-view-agenda{{ viewType === 'agenda' ? '' : '-outline' }}
        </v-icon>
      </div>
      <v-row class="slides-container" v-if="!searchProgress">
        <template v-if="filteredPrezentations.length">
          <div @click="handleExpandToggle" class="expand-all-btn">
            {{
              !isExpanded
                ? $t('build.step3.expandAllText')
                : $t('build.step3.collapseAllText')
            }}
            <v-icon id="expand-collapse--auto">
              {{ !isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon>
          </div>
          <v-expansion-panels
            accordion
            multiple
            v-model="expandedPrezentations"
            @change="handleExpandPrezentation"
            class="sections-expand-panel prezentations"
          >
            <v-expansion-panel
              v-for="(prezentation, prezIndex) in filteredPrezentations"
              :key="prezentation.id"
              expand
            >
              <v-expansion-panel-header
                :class="`prezentations-header prezentations-${prezIndex}-header`"
              >
                <div>{{ prezentation.name }}</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="expansion-panel-data">
                <v-expansion-panels
                  accordion
                  multiple
                  v-model="prezSectionExpandList[prezIndex]"
                  class="sections-expand-panel sections"
                >
                  <draggable
                    class="sections-drag"
                    :list="prezentation.sections.list"
                    v-bind="dragOptions"
                    :draggable="`.expansion-panel${
                      prezentationDetails.sections.list.length >= 5 ? '-' : ''
                    }`"
                    :group="{ name: 'sections', put: false, pull: 'clone' }"
                    :options="{
                      disabled: prezentationDetails.sections.list.length >= 5,
                    }"
                    @start="onSectionDragStart"
                    @end="onSectionDragEnd('Original deck', 'section')"
                    @add="onSectionDragEnd('Original deck', 'section')"
                  >
                    <v-expansion-panel
                      v-for="(section, sectionIndex) in prezentation.sections
                        .list"
                      :key="`${prezentation.id}-${sectionIndex}`"
                      expand
                      class="expansion-panel"
                    >
                      <v-expansion-panel-header>
                        <div
                          :class="`chip-title section-header-text prezentations-${prezIndex}-section-${sectionIndex}-header-text`"
                        >
                          <div class="drag-icon">
                            <v-icon dense> mdi-dots-vertical </v-icon>
                            <v-icon dense> mdi-dots-vertical </v-icon>
                          </div>
                          <div class="title-text">
                            {{ section.sectionName }}
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="expansion-panel-data">
                        <draggable
                          class="slide-drag row slides-row"
                          :list="section.slides"
                          v-bind="dragOptions"
                          :group="{ name: 'slides', put: false, pull: 'clone' }"
                          @end="onSectionDragEnd('Original deck')"
                          @add="onSectionDragEnd('Original deck')"
                        >
                          <v-col
                            :cols="viewType === 'grid' ? 6 : 12"
                            v-for="(slide, slideIndex) in section.slides"
                            :key="`${viewType}-${slideIndex}-${slide.assetId}`"
                            class="image-cell"
                            :style="{
                              display: slide.assetId ? 'block' : 'none',
                            }"
                          >
                            <SlideThumbnail
                              :slide="slide"
                              :prezIndex="prezIndex"
                              :sectionIndex="sectionIndex"
                              :slideIndex="slideIndex"
                              v-if="slide.assetId"
                            >
                              <template>
                                <v-checkbox
                                  v-model="selectedSlideObjRef[slide.uniqueKey]"
                                  :class="`checkbox ${
                                    selectedSlideObjRef[slide.uniqueKey]
                                      ? 'checked'
                                      : ''
                                  }`"
                                  size="small"
                                  @change="(e) => handleSlidesSelect(e, slide)"
                                />
                              </template>
                            </SlideThumbnail>
                          </v-col>
                        </draggable>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </draggable>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <v-row v-else>
          <v-col class="nodata">
            <template v-if="!isNetworkError">
              <div>
                <img src="/assets/gallery_no_data.svg" alt />
              </div>

              <div class="not-found-descriptions">
                <div>{{ $t('prezentationGallery.noResultText') }}</div>
              </div>
            </template>
            <template v-else>
              <div>
                <img src="/assets/img/sso-user_not_found.svg" alt />
              </div>

              <div class="not-found-descriptions">
                <div>
                  {{ $t('prezentationGallery.unableToFetchSlides') }}
                  <br />{{ $t('prezentationGallery.please') }}
                  <span class="text-btn" @click="handleTryAgain">
                    {{ $t('prezentationGallery.tryAgain') }}
                  </span>
                  !
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <!-- <v-col
          :cols="viewType === 'grid' ? 6 : 12"
          v-for="slide in slides"
          :key="`${viewType}-${slide.assetId}`"
        >
          <SlideThumbnail :slide="slide">
            <template>
              <v-checkbox
                class="checkbox"
                size="small"
                @change="(e) => handleSlidesSelect(e, slide)"
              />
            </template>
          </SlideThumbnail>
        </v-col> -->
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="slides-container"
        v-if="searchProgress"
      >
        <clip-loader
          :loading="searchProgress"
          :color="`#21a7e0`"
          :width="20"
          :height="20"
        />
      </v-row>
    </div>
    <v-snackbar
      color="#ffffff"
      v-model="selectedSlides.length"
      bottom
      right
      :timeout="-1"
    >
      <span style="color: rgba(0, 0, 0, 0.87)">
        {{
          $t('prezentationGallery.selectedSlidesText', {
            selectedSlides: selectedSlides.length,
          })
        }}
      </span>
      <span>
        <v-menu
          offset-y
          top
          v-if="
            !isIndexToAddSlidesSelected &&
            prezentationDetails.sections.list.length
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-prezentation-btn"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('prezentationGallery.addToPrezentationBtn') }}
              <v-icon end>mdi-menu-up</v-icon>
            </v-btn>
          </template>
          <v-list class="menu-items-wrapper">
            <v-list-item
              v-for="(item, index) in slidesList"
              :key="index"
              @click="() => handleSlideToAddClick(item)"
            >
              <v-list-item-title>
                {{ item.sectionName || item.count }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="add-prezentation-btn"
          color="primary"
          v-else
          @click="() => handleSlideToAddClick()"
        >
          Add to Prezentation
        </v-btn>
      </span>
      <v-icon
        size="14px"
        v-show="selectedSlides.length"
        @click="handleSelectionClear"
        color="rgba(0,0,0,0.87)"
      >
        mdi-close
      </v-icon>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import draggable from 'vuedraggable';
import sortBy from 'lodash/sortBy';
import { mapActions, mapGetters, mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import debounce from 'lodash/debounce';
import { getFileURL } from '@/utils/calyrex';
import SearchInputField from './SearchInputField.vue';
import SlideThumbnail from './SlideThumbnail.vue';
import { trackPrezentationEvents } from '../Analytics/PrezentationEvents';
import {
  TD_COMMENT,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_CREATED_FROM,
  TD_CREATED_ON,
  TD_FROM_TO,
  TD_ISDOWNLOAD,
  TD_ISFAVORITED,
  TD_MY_DOWNLOADS,
  TD_MY_FAVORITES,
  TD_PREZENTATIONAUTHOR,
  TD_PREZENTATIONRESULTS,
  TD_PREZENTATIONUSERACCESS,
  TD_PREZENTATION_ACCESS,
  TD_PREZENTATION_AUTHOR,
  TD_PREZENTATION_TYPE,
  TD_PREZTYPE,
  TD_PREZ_ADVANCED_FILTER_MY_DOWNLOADS,
  TD_PREZ_ADVANCED_FILTER_MY_FAVORITES,
  TD_PREZ_ADVANCED_FILTER_REVIEW,
  TD_REPLACE_IMAGE_SOURCE,
  TD_SECTION_CHANGED,
  TD_SORTTYPE,
  TD_QUERY,
  TD_PLACEMENT,
  getBuildPrezentationData,
  TD_FILTER_SELECTED,
  BUILD,
} from '../Analytics/MatomoTrackingDataHelper';
import { PrezentationViewTypes } from '../../../utils/constants';
import NewFeatureBadge from '../NewFeatureBadge.vue';
import {
  getPrezentationByID,
  getPrezentationsForListView,
} from '../../../utils/api-helper';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { getThumbnail } from '../../../store/modules/helper';
import { trackBuildEvents } from '../Analytics/BuildEvents';

const customTemplate = {
  code: 'custom',
  name: 'Uploaded by me',
  thumbnailURL: '/assets/img/Custom_Template.svg',
  checked: true,
  disabled: true,
};

let advancedFilters = [];

export default {
  components: {
    SearchInputField,
    NewFeatureBadge,
    SlideThumbnail,
    ClipLoader,
    draggable,
  },
  data() {
    return {
      viewType: 'grid',
      isFiltersExpand: false,
      searchProgress: false,
      pickerDate: '',
      expandedPrezentations: [0],
      prezSectionExpandList: [[0, 1, 2, 3, 4]],
      selectedSlides: [],
      selectedSlideObjRef: {},
      prezentations: [],
      selectedFilter: {
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      },
      advanceFilterTitleChanged: [],
      isExpanded: false,
      isAdvacnedFilterActive: false,
      viewTypes: PrezentationViewTypes,
      allPrezentations: [],
      isNetworkError: false,
      filteredPrezentations: [],
      filterMap: {
        'Most recent': 'recent',
        'Oldest first': 'oldest',
        'A-Z': 'AZ',
        'Z-A': 'ZA',
      },
      trendingOutlines: [],
      defaultOutlines: [
        { name: 'Business Case' },
        { name: 'All-hands' },
        { name: 'Project Update' },
        { name: 'Communication Plan' },
        { name: 'Knowledge Transfer' },
        { name: 'Strategy Plan' },
        { name: 'Business Plan' },
        { name: 'Brand Plan' },
        { name: 'Leadership Meeting' },
        { name: 'Team Meeting' },
      ],
      outlineChipClicked: ['All'],
      templateAllFilter: { name: 'All', checked: true, disabled: false },
      selectedTemplates: [{ name: 'All', checked: true, disabled: false }],
      allThemes: [],
      advancedFilterOpened: false,
      advancedItems: JSON.parse(JSON.stringify(advancedFilters)),
      advancedFilterActivePanels: [0, 1, 2, 3, 4, 5, 6, 7],
      offsetTop: 0,
      fromDate: '',
      toDate: '',
      fromDateMenu: false,
      isResetClicked: false,
      toDateMenu: false,
      toDateStr: '',
      fromDateStr: '',
      filterText: '',
      filterItems: [
        { title: 'Most recent', field: 'createdAt', comparator: 'lt' },
        { title: 'Oldest first', field: 'createdAt', comparator: 'gt' },
        { title: 'A-Z', field: 'name', comparator: 'gt' },
        { title: 'Z-A', field: 'name', comparator: 'lt' },
      ],
      searchWithTextBoxActive: false,
      outlineHovered: '',
    };
  },
  props: {
    slidesList: {
      type: Array,
      default: () => [],
    },
    onAddSlidesToSlideNumber: {
      type: Function,
      default: () => {},
    },
    isIndexToAddSlidesSelected: {
      type: Boolean,
      default: false,
    },
    prezentationDetails: {
      type: Object,
      default: () => ({}),
    },
    origin: {
      type: String,
      default: '',
    },
    currentTab: {
      type: String,
      default: '',
    },
    onSectionDragStart: {
      type: Function,
      default: () => {},
    },
    onSectionDragEnd: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'themes',
      'startLoadingSlidesFlag',
      'companyData',
    ]),
    ...mapState('prezentations', [
      // 'allPrezentations',
      'filteredAndSearchedPrezentations',
      'reloadOnLastPrezDelete',
      'snapshot',
      'prezentationBackClicked',
    ]),
    ...mapGetters('users', ['getOPAccessLevel', 'isUploadLimittedAccess']),
    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
        disabled: false,
      };
    },
    isGenerateEnabled() {
      const genFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'slide-generate',
      );
      return (
        (genFeature &&
          genFeature.enabled &&
          genFeature.access_level &&
          genFeature.access_level === 'full_access') ||
        (genFeature &&
          genFeature.access_level &&
          (genFeature.access_level === 'limited_access' ||
            genFeature.access_level === 'restricted_access'))
      );
    },
    isRedesignEnabled() {
      const redesignFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          (redesignFeature.access_level === 'restricted_access' ||
            redesignFeature.access_level === 'limited_access'))
      );
    },
    isComplyEnabled() {
      const complyFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'comply',
      );
      return (
        (complyFeature &&
          complyFeature.enabled &&
          complyFeature.access_level &&
          complyFeature.access_level === 'full_access') ||
        (complyFeature &&
          complyFeature.access_level &&
          (complyFeature.access_level === 'restricted_access' ||
            complyFeature.access_level === 'limited_access'))
      );
    },
    isAdvancedFilterChanged() {
      return this.advancedItems.some(
        (item) =>
          !item.checkAll ||
          (item.includeAllOption &&
            item.items &&
            item.items.some((itm) => !itm.active)) ||
          (!item.includeAllOption &&
            item.items &&
            item.items.some((itm) => itm.active)),
      );
    },
  },

  watch: {
    isIndexToAddSlidesSelected() {
      const list = this.selectedSlides;
      this.selectedSlides = [...list];
    },
    currentTab() {
      if (this.currentTab !== 'slides') {
        this.handleSelectionClear();
      }
    },
    filterText(val) {
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGallerySearchQuery(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Prezentations',
          [TD_QUERY]: val,
        });
      } else {
        trackPrezentationEvents.prezentationsSlideSearchQuery(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_QUERY]: val,
            [TD_PLACEMENT]: 'Prezentations',
          },
        );
      }
    },
  },
  mounted() {
    this.loadPrezentationsByOwner();
    advancedFilters = [
      {
        items: [
          {
            title: 'Prezentation Shells',
            display_title: 'filters.presentationShells',
            active: true,
            value: 'shell',
            key: 'prezentation-shells',
          },
          {
            title: 'Best-Practice Decks',
            display_title: 'filters.partiallyAnonymized',
            active: true,
            value: 'best practices',
            key: 'best-practice-guides',
          },
          {
            title: 'Original Decks',
            display_title: 'filters.original',
            active: true,
            value: 'original deck',
            key: 'original-deck',
          },
          // { title: 'Original Decks', active: false },
        ],
        checkAll: true,
        includeAllOption: true,
        title: 'Prezentation Type',
        display_title: 'filters.prezentationType',
        active: true,
        icon: 'prezentation-type',
      },
      {
        items: [
          {
            title: 'Build',
            display_title: 'advancedFiltersFeatureNames.story_builder',
            name: 'build',
            active: true,
            value: ['product'],
          },
        ],
        checkAll: true,
        includeAllOption: true,
        title: 'Created from',
        display_title: 'filters.createdFrom',
        active: true,
        icon: 'created-from',
      },
      {
        items: [
          {
            title: 'Me',
            display_title: 'fingerprint.compareFingAud.me',
            active: true,
            value: 'get_my_prezentations',
          },
          {
            title: 'My Colleagues',
            display_title: 'filters.myColleagues',
            active: true,
            value: 'get_team_prezentations',
          },
          {
            title: 'Brand Team',
            display_title: 'filters.brandTeam',
            active: true,
            value: 'get_team_prezentations',
          },
        ],
        checkAll: true,
        title: 'Author',
        display_title: 'build.step3.author',
        active: true,
        includeAllOption: true,
        icon: 'author',
      },
      {
        items: [
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: true,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: true,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: true,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: true,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: true,
          },
          // { title: 'Original Decks', active: false },
        ],
        checkAll: true,
        title: 'Access',
        display_title: 'filters.access',
        active: true,
        includeAllOption: true,
        icon: 'access',
      },
      {
        items: [
          {
            title: 'Prezentations to Review',
            display_title: 'filters.prezentationReview',
            active: false,
          },
        ],
        checkAll: true,
        title: 'Comment Mentions',
        display_title: 'filters.commentMention',
        active: true,
        includeAllOption: false,
        icon: 'comment-mentions',
      },
      {
        items: [
          {
            title: 'My Favorites',
            display_title: 'filters.myFavorites',
            active: false,
          },
        ],
        checkAll: true,
        title: 'Favorites',
        display_title: 'slides.favourites',
        active: true,
        includeAllOption: false,
        icon: 'favorites',
      },
      {
        items: [
          {
            title: 'My Downloads',
            display_title: 'filters.myDownloads',
            active: false,
          },
        ],
        checkAll: true,
        title: 'Downloads',
        display_title: 'slides.downloads',
        active: true,
        includeAllOption: false,
        icon: 'downloads',
      },
      {
        checkAll: true,
        title: 'Created/Uploaded on',
        display_title: 'filters.filterByDate',
        active: true,
        includeAllOption: false,
        icon: 'created-on',
        isCustomGroup: true,
      },
    ];
    this.advancedItems = JSON.parse(JSON.stringify(advancedFilters));
    if (this.isUploadLimittedAccess) {
      const uploadFilter = {
        title: 'Upload',
        display_title: 'advancedFiltersFeatureNames.uploader',
        name: 'upload',
        active: true,
        value: ['iocupload', 'user uploaded'],
      };
      this.advancedItems
        .find((item) => item.title === 'Created from')
        .items.push(uploadFilter);
      advancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...uploadFilter });
    }
    if (this.isGenerateEnabled) {
      const generateFilter = {
        title: 'Generate',
        display_title: 'advancedFiltersFeatureNames.auto_generator',
        name: 'generate',
        active: true,
        value: ['generated'],
      };
      this.advancedItems
        .find((item) => item.title === 'Created from')
        .items.push(generateFilter);
      advancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...generateFilter });
    }
    if (this.isRedesignEnabled) {
      const redesignFilter = {
        title: 'Redesign',
        display_title: 'advancedFiltersFeatureNames.redesign',
        name: 'redesign',
        active: true,
        value: ['redesign'],
      };
      this.advancedItems
        .find((item) => item.title === 'Created from')
        .items.push(redesignFilter);
      advancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...redesignFilter });
    }
    if (this.isComplyEnabled) {
      const complyFilter = {
        title: 'Template Converter',
        display_title: 'advancedFiltersFeatureNames.comply',
        name: 'template converter',
        active: true,
        value: ['comply'],
      };
      this.advancedItems
        .find((item) => item.title === 'Created from')
        .items.push(complyFilter);
      advancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...complyFilter });
    }
    if (this.getOPAccessLevel !== 'no_access') {
      this.advancedItems
        .find((item) => item.title === 'Created from')
        .items.push({
          title: 'Overnight Prezentations',
          display_title: 'overnightPresentations.overnightPresentation',
          name: 'overnight',
          active: true,
          value: ['op'],
        });
      advancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({
          title: 'Overnight Prezentations',
          display_title: 'overnightPresentations.overnightPresentation',
          name: 'overnight',
          active: true,
          value: ['op'],
        });
    }
    if (!this.isUploadLimittedAccess && this.isGenerateEnabled) {
      this.advancedItems.find(
        (item) => item.title === 'Prezentation Type',
      ).items = this.advancedItems
        .find((item) => item.title === 'Prezentation Type')
        .items.filter((item) => item.title !== 'Best-Practice Decks');

      advancedFilters.find((item) => item.title === 'Prezentation Type').items =
        advancedFilters
          .find((item) => item.title === 'Prezentation Type')
          .items.filter((item) => item.title !== 'Best-Practice Decks');
    }
    if (
      !this.isUploadLimittedAccess &&
      !this.isGenerateEnabled &&
      !this.isComplyEnabled
    ) {
      this.advancedItems.splice(0, 1);
      advancedFilters.splice(0, 1);
    }

    if (
      !this.isUploadLimittedAccess &&
      !this.isGenerateEnabled &&
      !this.isComplyEnabled &&
      this.getOPAccessLevel === 'no_access'
    ) {
      this.advancedItems.splice(0, 1);
      advancedFilters.splice(0, 1);
    }
  },
  methods: {
    ...mapActions('prezentations', [
      'setUserSelectedPrezentationView',
      'setFilterAndSearchedPrezentations',
      'setReloadLastPrezOnDelete',
      'setPrezentationStateSnapshot',
      'setPrezentationViewOrigin',
    ]),
    ...mapActions('users', ['setFilteredThemes']),
    handleExpandPrezentation(e) {
      this.expandedPrezentations = [...e];
      const lastIndex = e.pop();
      if (!this.prezSectionExpandList[lastIndex]) {
        this.prezSectionExpandList[lastIndex] = [0, 1, 2, 3, 4];
      }
    },
    handleTryAgain() {
      this.loadPrezentationsByOwner();
      this.isFiltersExpand = false;
    },
    handleSelectionClear() {
      this.selectedSlides = [];
      this.selectedSlideObjRef = {};
    },
    handleExpandToggle() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expandedPrezentations = [];
        this.filteredPrezentations.forEach((section, sectionIndex) => {
          this.expandedPrezentations.push(sectionIndex);
        });
      } else {
        this.expandedPrezentations = [];
      }
    },
    async loadPrezentationsByOwner() {
      this.searchProgress = true;
      this.allPrezentations = [];
      let nextTick = true;
      let lastSortValue = false;
      let lastNumId = false;

      this.expandedPrezentations = [0];
      this.prezSectionExpandList = [[0, 1, 2, 3, 4]];
      while (nextTick) {
        const response = await getPrezentationsForListView(
          lastSortValue,
          lastNumId,
          this.getOPAccessLevel !== 'no_access',
        );
        const prezentationArr = await Promise.all(
          response.data.items?.map(async (ele) => {
            if (ele.prezentationType === 'OP') {
              const prezentation = await getPrezentationByID(
                ele.prezentationId ? ele.prezentationId : ele.id,
              );
              return { ...ele, sections: prezentation.sections };
            }
            return { ...ele };
          }),
        );
        const list = [...prezentationArr];
        list.forEach((prez, i) => {
          prez.sections?.list?.forEach((section, j) => {
            section.slides?.forEach((slide, s) => {
              list[i].sections.list[j].slides[s] = {
                ...slide,
                uniqueKey: `${slide.assetId || slide.landscape}_${i}_${j}_${s}`,
                assetId: slide.assetId || slide.landscape,
              };
            });
          });
        });
        const filteredList = list.filter(
          (prez) => prez?.id !== this.prezentationDetails?.id,
        );
        this.allPrezentations = [
          ...this.allPrezentations,
          ...this.formatPresentations(filteredList),
        ];
        if (!response.data.nextToken.next) {
          nextTick = false;
        } else {
          lastSortValue = response.data.nextToken.last_sort_value;
          lastNumId = response.data.nextToken.last_num_id;
        }
        this.loadedFirstCall = false;
      }
      this.allPrezentations = this.allPrezentations.filter(
        (prez) => prez.permission_level !== 0,
      );
      this.filteredPrezentations = JSON.parse(
        JSON.stringify(this.allPrezentations.slice(0, 24)),
      );

      this.searchProgress = false;
    },
    formatPresentations(presentations) {
      for (let i = 0; i < presentations.length; i++) {
        presentations[i].sharedWith = presentations[i].shared
          ? 'Shared'
          : 'Private';
      }
      return presentations;
    },
    handleFiltersToggle() {
      this.isFiltersExpand = !this.isFiltersExpand;
    },
    setViewType(type) {
      this.viewType = type;
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGalleryViewClick(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_QUERY]: this.searchText,
          [TD_PLACEMENT]: 'Prezentations',
          [TD_COMMON_COLUMN_NAME]: 'view selected',
          [TD_COMMON_COLUMN_VALUE]:
            this.viewType === 'grid' ? 'Row view' : 'Column view',
        });
      } else {
        trackPrezentationEvents.prezentationsGalleryViewClick(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_QUERY]: this.searchText,
            [TD_PLACEMENT]: 'Prezentations',
            [TD_COMMON_COLUMN_NAME]: 'view selected',
            [TD_COMMON_COLUMN_VALUE]:
              this.viewType === 'grid' ? 'Row view' : 'Column view',
          },
        );
      }
    },
    async handleSlidesSelect(e, slide) {
      // console.log(e, slide);
      if (e) {
        const obj = {
          ...slide,
          // assetId: slide.assetId || slide.landscape,
          // outline: slide.title || slide.outline || slide.idea,
          // category: slide.categories ? slide.categories[0] : '',
          // filename: slide.filename,
          // uniqueID: slide.unique_id,
          // thumbnail: slide.thumbnail || '',
          isAdded: true,
        };

        if (this.filterOptionCategory === 'uploaded') {
          obj.isUserUploaded = true;
        } else if (this.filterOptionCategory === 'generated') {
          obj.isGenerated = true;
          obj.source = 'generated';
        } else if (this.filterOptionCategory === 'redesign') {
          obj.isRedesign = true;
          obj.source = 'redesign';
        } else if (this.filterOptionCategory === 'comply') {
          obj.isComply = true;
          obj.source = 'comply';
        }

        // Setting slide intermedietly to avoid displaying image caching
        this.slideData = obj;
        if (obj.assetId && !obj.thumbnail) {
          const thumbnailPath = obj.assetId;

          if (
            thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
            thumbnailPath.startsWith('/output/pptx/')
          ) {
            obj.thumbnail = await getThumbnail(thumbnailPath);
            if (obj.assetId.indexOf('.pdf') !== -1) {
              obj.thumbnail = await convertPdfToImage(obj.thumbnail);
            }
          } else {
            const tempThumbnail = await getFileURL(
              this.currentUser.user.cognitoID,
              thumbnailPath,
              this.currentUser.userIp,
              'IOCUpload',
            );
            if (this.handlePremiumFeatureSlides(obj)) {
              obj.thumbnail = tempThumbnail;
            }
            if (
              obj.prefs?.source === 'uploaded' ||
              obj.source === 'uploaded' ||
              obj.assetId.indexOf('.pdf') !== -1
            ) {
              obj.thumbnail = await convertPdfToImage(tempThumbnail);
            }
          }
        }
        this.selectedSlides.push(obj);
      } else {
        this.selectedSlides = this.selectedSlides.filter(
          (s) => s.uniqueKey !== slide.uniqueKey,
        );
      }
      this.selectedSlideObjRef = {};
      this.selectedSlides.forEach((s) => {
        this.selectedSlideObjRef[s.uniqueKey] = true;
      });
    },
    handlePremiumFeatureSlides(obj) {
      return (
        obj.prefs?.source === 'generated' ||
        obj.source === 'generated' ||
        obj.prefs?.source === 'redesign' ||
        obj.source === 'redesign' ||
        obj.prefs?.source === 'comply' ||
        obj.source === 'comply'
      );
    },
    handleSlideToAddClick(obj) {
      this.onAddSlidesToSlideNumber(obj, [...this.selectedSlides]);
      this.selectedSlides = [];
      this.selectedSlideObjRef = {};
      this.$forceUpdate();
    },

    getCurrentDateFormatted(origin) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      this.pickerDate = `${year}-${month}-${day}`;
      if (origin === 'from' && this.fromDate) this.pickerDate = this.fromDate;
      if (origin === 'to' && this.toDate) this.pickerDate = this.toDate;
    },
    checkAndDisableAllValues(data) {
      return data.map((item) => ({
        ...item,
        checked: true,
        disabled: true,
      }));
    },
    // eslint-disable-next-line func-names
    filterPrezentationsWithTextField: debounce(function (e) {
      let prezs = [];
      if (e) {
        this.filterText = e;
        prezs = [...this.allPrezentations].filter(
          (przs) =>
            przs.name.toLowerCase().includes(e.toLowerCase()) ||
            przs.type?.toLowerCase().includes(e.toLowerCase()) ||
            przs.author?.toLowerCase().includes(e.toLowerCase()),
        );
        this.filteredPrezentations = prezs.slice(0, 24);
      } else {
        this.filteredPrezentations = [...this.allPrezentations.slice(0, 24)];
      }
    }, 500),

    handleAllOptionClickForCategories(item) {
      this.searchModel = '';
      this.outlineChipClicked = ['All'];
      this.filterItems = [
        { title: 'Most recent', field: 'createdAt', comparator: 'lt' },
        { title: 'Oldest first', field: 'createdAt', comparator: 'gt' },
        { title: 'A-Z', field: 'name', comparator: 'gt' },
        { title: 'Z-A', field: 'name', comparator: 'lt' },
      ];
      this.selectedFilter = {
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      };
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, this.themes]),
      ];

      if (item.title === 'Author') {
        if (item.checkAll === true) {
          this.advancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...item,
                items: item.items.map((it) => ({ ...it, active: true })),
              };
            }
            if (itm.title === 'Access') {
              return {
                items: [
                  {
                    title: 'Viewer',
                    display_title: 'prezentationShareStatus.viewer',
                    active: true,
                    disabled: false,
                  },
                  {
                    title: 'Reviewer',
                    display_title: 'prezentationShareStatus.reviewer',
                    active: true,
                    disabled: false,
                  },
                  {
                    title: 'Editor',
                    display_title: 'prezentationShareStatus.editor',
                    active: true,
                    disabled: false,
                  },
                  {
                    title: 'Shared',
                    display_title: 'prezentationShareStatus.shared',
                    active: true,
                    disabled: false,
                  },
                  {
                    title: 'Private',
                    display_title: 'prezentationShareStatus.private',
                    active: true,
                    disabled: false,
                  },
                  // { title: 'Original Decks', active: false },
                ],
                checkAll: true,
                title: 'Access',
                display_title: 'filters.access',
                active: true,
                includeAllOption: true,
                icon: 'access',
              };
            }
            return {
              ...itm,
            };
          });
        } else {
          this.advancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...item,
                items: item.items.map((it) => ({ ...it, active: false })),
              };
            }
            if (itm.title === 'Access') {
              return {
                items: [
                  {
                    title: 'Viewer',
                    display_title: 'prezentationShareStatus.viewer',
                    active: false,
                    disabled: true,
                  },
                  {
                    title: 'Reviewer',
                    display_title: 'prezentationShareStatus.reviewer',
                    active: false,
                    disabled: true,
                  },
                  {
                    title: 'Editor',
                    display_title: 'prezentationShareStatus.editor',
                    active: false,
                    disabled: true,
                  },
                  {
                    title: 'Shared',
                    display_title: 'prezentationShareStatus.shared',
                    active: false,
                    disabled: true,
                  },
                  {
                    title: 'Private',
                    display_title: 'prezentationShareStatus.private',
                    active: false,
                    disabled: true,
                  },
                  // { title: 'Original Decks', active: false },
                ],
                checkAll: false,
                title: 'Access',
                display_title: 'filters.access',
                disableAll: true,
                active: true,
                includeAllOption: true,
                icon: 'access',
              };
            }
            return {
              ...itm,
            };
          });
        }
      } else if (item.title === 'Access') {
        if (item.checkAll === true) {
          this.advancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...this.advancedItems.find((ele) => ele.title === 'Author'),
              };
            }
            if (itm.title === 'Access') {
              return {
                ...item,
                items: [
                  ...this.getAccessItemsWithAuthorPermissions(
                    this.advancedItems.find((ele) => ele.title === 'Author'),
                  ),
                ],
              };
            }
            return {
              ...itm,
            };
          });
        } else {
          this.advancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...this.advancedItems.find((ele) => ele.title === 'Author'),
              };
            }
            if (itm.title === 'Access') {
              return {
                ...item,
                items: [
                  ...this.getAccessItemsWithAuthorPermissions(
                    this.advancedItems.find((ele) => ele.title === 'Author'),
                  ).map((accessItem) => ({
                    ...accessItem,
                    active: false,
                  })),
                ],
              };
            }
            return {
              ...itm,
            };
          });
        }
      } else if (item.title === 'Source') {
        item.items.forEach((i) => {
          i.active = item.checkAll;
        });
        if (item.checkAll === true) {
          this.advancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author' || itm.title === 'Access') {
              itm.items = itm.items.map((i) => {
                i.active = true;
                i.disabled = false;
                return i;
              });
              itm.disableAll = false;
              itm.checkAll = true;
            }
            return itm;
          });
        }
      } else {
        item.items.forEach((i) => {
          i.active = item.checkAll;
        });
      }
    },

    handleClearDate(type) {
      if (type === 'from') {
        this.fromDate = '';
        this.fromDateStr = '';
      } else if (type === 'to') {
        this.toDate = '';
        this.toDateStr = '';
      }
    },

    allowedToDates(val) {
      if (this.fromDate === '') {
        return moment(val).isSameOrBefore(new Date());
      }
      return (
        moment(val).isSameOrAfter(this.fromDate) &&
        moment(val).isSameOrBefore(new Date())
      );
    },

    allowedFromDates(val) {
      return moment(val).isSameOrBefore(new Date());
    },

    handleChangeFilter(value) {
      if (value === 'click')
        trackPrezentationEvents.prezentationsSortBy(this.currentUser, {
          [TD_SORTTYPE]: this.selectedFilter?.title,
        });
      const prezentations = [...this.filteredAndSearchedPrezentations];
      Object.values(this.filterItems).forEach((filterBy) => {
        if (this.selectedFilter.title === filterBy.title) {
          let sortedSlides = sortBy(prezentations, [filterBy.field]);
          if (filterBy.comparator === 'lt') {
            sortedSlides = sortedSlides.reverse();
          }

          this.filteredPrezentations = sortedSlides.slice(0, 24);
        }
      });
    },
    resetAllFiltersOfAdvancedFilters() {
      this.searchModel = '';
      this.outlineChipClicked = ['All'];
      this.filterItems = [
        { title: 'Most recent', field: 'createdAt', comparator: 'lt' },
        { title: 'Oldest first', field: 'createdAt', comparator: 'gt' },
        { title: 'A-Z', field: 'name', comparator: 'gt' },
        { title: 'Z-A', field: 'name', comparator: 'lt' },
      ];
      this.selectedFilter = {
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      };
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, this.themes]),
      ];
      this.advancedItems = JSON.parse(JSON.stringify(advancedFilters));
      this.advancedFilterActivePanels = [0, 1, 2, 3, 4, 5, 6, 7];
      this.fromDate = '';
      this.toDate = '';
      this.fromDateMenu = false;
      this.toDateMenu = false;
      this.toDateStr = '';
      this.fromDateStr = '';
      this.isResetClicked = true;
      setTimeout(() => {
        this.isResetClicked = false;
      }, 1000);
      const prezs = [...this.allPrezentations.slice(0, 24)];

      this.expandedPrezentations = [0];
      this.prezSectionExpandList = [[0, 1, 2, 3, 4]];

      this.filteredPrezentations = JSON.parse(JSON.stringify(prezs));
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGalleryFilterReset(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Prezentations',
        });
      } else {
        trackPrezentationEvents.prezentationsGalleryFilterReset(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_PLACEMENT]: 'Prezentations',
          },
        );
      }
    },

    resetAdvancedFilters() {
      this.advancedItems = JSON.parse(JSON.stringify(advancedFilters));
      this.advancedFilterActivePanels = [0, 1, 2, 3, 4, 5, 6, 7];
      this.fromDate = '';
      this.toDate = '';
      this.fromDateMenu = false;
      this.toDateMenu = false;
      this.toDateStr = '';
      this.fromDateStr = '';
    },

    handleDatePickerTextChange(type) {
      if (type === 'from') {
        const fromDate = moment(this.fromDate);
        this.fromDateStr = fromDate.format('MM/DD/YYYY');
        if (this.toDate && moment(this.toDate).isBefore(fromDate)) {
          this.toDate = '';
          this.toDateStr = '';
        }
      } else if (type === 'to') {
        this.toDateStr = moment(this.toDate).format('MM/DD/YYYY');
      }
    },

    getAccessItemsWithAuthorPermissions(item) {
      const items = [...item.items];
      const selections = items
        .filter((sel) => sel.active === true)
        .map((itm) => itm.title);
      let res = [];
      if (selections.includes('Me') && selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: true,
            disabled: false,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: true,
            disabled: false,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: true,
            disabled: false,
          },
        ];
      }
      if (selections.includes('Me') && !selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: false,
            disabled: true,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: true,
            disabled: false,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: true,
            disabled: false,
          },
        ];
      }
      if (!selections.includes('Me') && selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: true,
            disabled: false,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: false,
            disabled: true,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: false,
            disabled: true,
          },
        ];
      }
      if (!selections.includes('Me') && !selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: false,
            disabled: true,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: false,
            disabled: true,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: false,
            disabled: true,
          },
        ];
      }

      if (selections.includes('Brand Team')) {
        res.forEach((row) => {
          if (row.title === 'Viewer') {
            row.active = true;
          }
        });
      }

      return res;
    },

    checkOrUncheckAllOfCurrentAdvancedFilter(item) {
      return (
        item.items.filter((itm) => itm.active === true).length ===
        item.items.length
      );
    },

    updateCurrentAdvancedFilter(item) {
      const index = this.advanceFilterTitleChanged.includes(item?.title);
      if (!index) {
        this.advanceFilterTitleChanged.push(item?.title);
      }
      try {
        const otherData = {};
        let authors;
        let authorsInString;
        let prezs;
        let prezsInString;
        const isSingleCheckBoxItemSetTrue = item?.items?.[0]?.active;
        switch (item.icon) {
          case 'author':
            // eslint-disable-next-line no-case-declarations
            authors = item.items.filter((advItem) => advItem.active);
            // eslint-disable-next-line no-case-declarations
            authorsInString = authors
              .map((auth) => auth.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZENTATIONAUTHOR] = authorsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZENTATION_AUTHOR;
            break;
          case 'access':
            //
            // eslint-disable-next-line no-case-declarations
            authors = item.items.filter((advItem) => advItem.active);
            // eslint-disable-next-line no-case-declarations
            authorsInString = authors
              .map((auth) => auth.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZENTATIONUSERACCESS] = authorsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZENTATION_ACCESS;
            break;
          case 'comment-mentions':
            otherData[TD_COMMENT] = `${
              isSingleCheckBoxItemSetTrue ? '' : '-'
            }${TD_PREZ_ADVANCED_FILTER_REVIEW}`;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZ_ADVANCED_FILTER_REVIEW;
            break;
          case 'favorites':
            otherData[TD_ISFAVORITED] = `${
              isSingleCheckBoxItemSetTrue ? '' : '-'
            }${TD_PREZ_ADVANCED_FILTER_MY_FAVORITES}`;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_MY_FAVORITES;
            break;
          case 'downloads':
            otherData[TD_ISDOWNLOAD] = `${
              isSingleCheckBoxItemSetTrue ? '' : '-'
            }${TD_PREZ_ADVANCED_FILTER_MY_DOWNLOADS}`;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_MY_DOWNLOADS;
            break;
          case 'created-on':
            if (this.fromDateStr || this.toDateStr) {
              otherData[TD_FROM_TO] = `${this.fromDateStr}-${this.toDateStr}`;
            }
            otherData[TD_COMMON_COLUMN_VALUE] = TD_CREATED_ON;
            break;
          case 'prezentation-type':
            // eslint-disable-next-line no-case-declarations
            prezs = item.items.filter((advItem) => advItem.active);
            // eslint-disable-next-line no-case-declarations
            prezsInString = prezs
              .map((prez) => prez.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZTYPE] = prezsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZENTATION_TYPE;
            break;
          case 'created-from':
            prezs = item.items.filter((advItem) => advItem.active);
            prezsInString = prezs
              .map((prez) => prez.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZENTATIONRESULTS] = prezsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_CREATED_FROM;
            break;
          default:
            break;
        }
        otherData[TD_COMMON_COLUMN_NAME] = TD_SECTION_CHANGED;
        let sourceSelected = '';
        if (item?.title === 'Source') {
          item.items.forEach((value) => {
            if (value.active) {
              sourceSelected += value.title;
              sourceSelected += '_';
            }
          });
          otherData[TD_REPLACE_IMAGE_SOURCE] = sourceSelected;
          otherData[TD_COMMON_COLUMN_VALUE] = item?.title;
        }
        // trackPrezentationEvents.prezentationsAdvanceFiltersSelected(
        //   this.currentUser,
        //   otherData,
        // );
      } catch (e) {
        console.log(e);
      }

      // checking all option accordingly
      if (item.includeAllOption === true) {
        item = {
          ...item,
          checkAll:
            this.checkOrUncheckAllOfCurrentAdvancedFilter(item) === true,
        };
      }

      // syncing checkboxes
      if (item.title === 'Author') {
        this.advancedItems = [...this.advancedItems].map((itm) => {
          if (itm.title === 'Author') {
            return {
              ...item,
            };
          }
          if (itm.title === 'Access') {
            const activeItems = [
              ...this.getAccessItemsWithAuthorPermissions(item),
            ];
            return {
              items: activeItems,
              checkAll:
                activeItems.filter((it) => it.active === true).length ===
                activeItems.length,
              title: 'Access',
              display_title: 'filters.access',
              active: true,
              disableAll:
                activeItems.filter((it) => it.active === true).length <
                activeItems.length,
              includeAllOption: true,
              icon: 'access',
            };
          }
          return {
            ...itm,
          };
        });
      } else if (item.title === 'Access') {
        this.advancedItems = [...this.advancedItems].map((itm) => {
          if (itm.title === 'Author') {
            return {
              ...this.advancedItems.find((ele) => ele.title === 'Author'),
            };
          }
          if (itm.title === 'Access') {
            return {
              ...item,
            };
          }
          return {
            ...itm,
          };
        });
      } else if (item.title === 'Source') {
        this.advancedItems = [...this.advancedItems].map((itm) => {
          if (itm.title === item.title) {
            return {
              ...item,
            };
          }
          if (itm.title === 'Author' || itm.title === 'Access') {
            const isPrezentSourceSelected =
              !item.checkAll &&
              item.items
                .filter((i) => i.active)
                .map((i) => i.title)
                .includes('Prezent');
            if (isPrezentSourceSelected) {
              itm.items = itm.items.map((i) => {
                i.active = true;
                i.disabled = true;
                return i;
              });
              itm.checkAll = true;
              itm.disableAll = true;
            } else {
              itm.items = itm.items.map((i) => {
                i.active = true;
                i.disabled = false;
                return i;
              });
              itm.checkAll = true;
              itm.disableAll = false;
            }
          }
          return {
            ...itm,
          };
        });
      } else {
        this.advancedItems = [...this.advancedItems].map((itm) => {
          if (itm.title === item.title) {
            return {
              ...item,
            };
          }
          return {
            ...itm,
          };
        });
      }
    },

    applyDateFiltersWithSearch(prezs) {
      if (this.fromDateStr !== '' || this.toDateStr !== '') {
        if (this.fromDateStr !== '' && this.toDateStr !== '') {
          const currToDate = new Date(this.toDate);
          currToDate.setDate(currToDate.getDate() + 1);
          prezs = [...prezs].filter(
            (prz) =>
              moment(prz.createdAt).isSameOrAfter(this.fromDate) &&
              moment(prz.createdAt).isSameOrBefore(currToDate),
          );
        } else if (this.fromDateStr !== '' && this.toDateStr === '') {
          prezs = [...prezs].filter((prz) =>
            moment(prz.createdAt).isSameOrAfter(this.fromDate),
          );
        } else if (this.fromDateStr === '' && this.toDateStr !== '') {
          const currToDate = new Date(this.toDate);
          currToDate.setDate(currToDate.getDate() + 1);
          prezs = [...prezs].filter((prz) =>
            moment(prz.createdAt).isSameOrBefore(currToDate),
          );
        }
      }
      return prezs;
    },

    onAdvancedFilerChange(item) {
      // resetting all other filter categories of the page
      this.searchModel = '';
      this.outlineChipClicked = ['All'];
      this.filterItems = [
        { title: 'Most recent', field: 'createdAt', comparator: 'lt' },
        { title: 'Oldest first', field: 'createdAt', comparator: 'gt' },
        { title: 'A-Z', field: 'name', comparator: 'gt' },
        { title: 'Z-A', field: 'name', comparator: 'lt' },
      ];
      this.selectedFilter = {
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      };
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, this.themes]),
      ];
      this.updateCurrentAdvancedFilter(item);
      // this.getCurrentAdvancedFilterValuesAndSearch();
    },

    isPrezentationOwner(prezentation) {
      return (
        prezentation.ownerID &&
        this.currentUser &&
        prezentation.ownerID.toLowerCase() ===
          this.currentUser.user.id.toLowerCase()
      );
    },
    getCurrentAdvancedFilterValuesAndSearch() {
      const authorResults = [];
      const authorWithAccessResults = [];
      // const sources = [];
      // const sources = this.advancedItems
      //   .find((ele) => ele.title === 'Source')
      //   .items.filter((itm) => itm.active === true)
      //   .map((i) => i.title.toLowerCase());
      const types = this.advancedItems.find(
        (ele) => ele.title === 'Prezentation Type',
      )
        ? this.advancedItems
            .find((ele) => ele.title === 'Prezentation Type')
            .items.filter((itm) => itm.active === true)
            .map((i) => i.value)
        : [];
      const createdFrom = this.advancedItems.find(
        (ele) => ele.title === 'Created from',
      )
        ? this.advancedItems
            .find((ele) => ele.title === 'Created from')
            .items.filter((itm) => itm.active === true)
            .map((i) => i.value)
            .reduce((a, b) => [...a, ...b], [])
        : [];
      const authors = this.advancedItems
        .find((ele) => ele.title === 'Author')
        .items.filter((it) => it.active === true)
        .map((itm) => itm.title);
      const permissions = this.advancedItems
        .find((ele) => ele.title === 'Access')
        .items.filter((itm) => itm.active === true)
        .map((at) => at.title);

      let prezentationTypeResults = [...this.allPrezentations].filter(
        (prz) => prz.permission_level !== 0,
      );

      if (
        this.advancedItems.find((ele) => ele.title === 'Prezentation Type') &&
        !this.advancedItems.find((ele) => ele.title === 'Prezentation Type')
          .checkAll
      ) {
        prezentationTypeResults = [...prezentationTypeResults].filter((prz) =>
          types.includes(prz.prezentationType.toLowerCase()),
        );
      }
      if (
        this.advancedItems.find((ele) => ele.title === 'Created from') &&
        !this.advancedItems.find((ele) => ele.title === 'Created from').checkAll
      ) {
        prezentationTypeResults = [...prezentationTypeResults].filter((prz) =>
          createdFrom.includes(prz.prezentationSource.toLowerCase()),
        );
      }
      // search with author and access combinations
      if (!this.advancedItems.find((ele) => ele.title === 'Author').checkAll) {
        authors.forEach((at) => {
          if (at === 'Me') {
            const myPrezs = [...prezentationTypeResults].filter((prz) =>
              this.isPrezentationOwner(prz),
            );
            authorResults.push(...myPrezs);
          }
          if (at === 'My Colleagues') {
            const colleaguesPrezs = [...prezentationTypeResults].filter(
              (prz) => !this.isPrezentationOwner(prz),
            );
            authorResults.push(...colleaguesPrezs);
          }
          if (at === 'Brand Team') {
            const brandTeamPrezs = [...prezentationTypeResults].filter(
              (prz) =>
                prz.author &&
                prz.author === 'Brand team' &&
                prz.source !== 'prezent',
            );
            authorResults.push(...brandTeamPrezs);
          }
        });
      } else {
        authorResults.push(...prezentationTypeResults);
      }

      // search with allowed permissions based on author selected
      if (!this.advancedItems.find((ele) => ele.title === 'Access').checkAll) {
        permissions.forEach((prm) => {
          if (prm === 'Viewer') {
            const viewer = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) && prz.permission_level === 1,
            );
            authorWithAccessResults.push(...viewer);
          }
          if (prm === 'Reviewer') {
            const reviewer = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) && prz.permission_level === 2,
            );
            authorWithAccessResults.push(...reviewer);
          }
          if (prm === 'Editor') {
            const editor = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) && prz.permission_level === 3,
            );
            authorWithAccessResults.push(...editor);
          }
          if (prm === 'Shared') {
            const shared = [...authorResults].filter(
              (prz) => this.isPrezentationOwner(prz) && prz.shared === true,
            );
            authorWithAccessResults.push(...shared);
          }
          if (prm === 'Private') {
            const priv = [...authorResults].filter(
              (prz) => this.isPrezentationOwner(prz) && !prz.shared,
            );
            authorWithAccessResults.push(...priv);
          }
        });
      } else {
        authorWithAccessResults.push(...authorResults);
      }

      // search with other other sub filters independently on author and access filtered data
      let prezs = [...authorWithAccessResults];

      const includeCommentMentions = this.advancedItems.find(
        (ele) => ele.title === 'Comment Mentions',
      ).items[0].active;
      const includeFavorites = this.advancedItems.find(
        (ele) => ele.title === 'Favorites',
      ).items[0].active;
      const includeDownloads = this.advancedItems.find(
        (ele) => ele.title === 'Downloads',
      ).items[0].active;

      if (includeCommentMentions) {
        prezs = prezs.filter(
          (prz) => prz.isReviewer && prz.isReviewer === true,
        );
      }

      if (includeFavorites) {
        prezs = prezs.filter((prz) => prz.isFavorite);
      }

      if (includeDownloads) {
        prezs = prezs.filter((prz) => prz.isDownloaded);
      }

      if (this.fromDateStr !== '' && this.toDateStr !== '') {
        const currToDate = new Date(this.toDate);
        currToDate.setDate(currToDate.getDate() + 1);
        prezs = [...prezs].filter(
          (prz) =>
            moment(prz.createdAt).isSameOrAfter(this.fromDate) &&
            moment(prz.createdAt).isSameOrBefore(currToDate),
        );
      } else if (this.fromDateStr !== '' && this.toDateStr === '') {
        prezs = [...prezs].filter((prz) =>
          moment(prz.createdAt).isSameOrAfter(this.fromDate),
        );
      } else if (this.fromDateStr === '' && this.toDateStr !== '') {
        const currToDate = new Date(this.toDate);
        currToDate.setDate(currToDate.getDate() + 1);
        prezs = [...prezs].filter((prz) =>
          moment(prz.createdAt).isSameOrBefore(currToDate),
        );
      }

      this.filteredPrezentations = prezs.slice(0, 24);
      // this.handleChangeFilter();
    },
    handleApplyFilters() {
      this.searchProgress = true;

      this.expandedPrezentations = [0];
      this.prezSectionExpandList = [[0, 1, 2, 3, 4]];

      this.getCurrentAdvancedFilterValuesAndSearch();
      this.searchProgress = false;
      this.isFiltersExpand = false;
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGalleryFiltered(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Prezentations',
          [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: this.advanceFilterTitleChanged,
        });
        this.advanceFilterTitleChanged = [];
      } else {
        trackPrezentationEvents.prezentationsGalleryFiltered(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_PLACEMENT]: 'Prezentations',
            [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: this.advanceFilterTitleChanged,
          },
        );
        this.advanceFilterTitleChanged = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prezentations-gallery-container {
  .view-icons {
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: flex-end;

    .v-icon {
      margin-left: 20px;
    }
  }

  .slides-container {
    justify-content: flex-end;
    min-height: 200px;
    // overflow: auto;
    .v-image {
      box-shadow: -1px -1px 4px 0px #0000001a;
      box-shadow: 1px 1px 4px 0px #0000001a;
      position: relative;
    }

    .checkbox {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      margin: 8px;
      padding: 0;
    }

    .image-cell .checked {
      display: block;
    }

    .image-cell:hover {
      cursor: move;
      .checkbox {
        display: block;
      }
    }
  }

  .filters-section {
    margin: 15px 0;
    .filters-expand {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      color: #21a7e0;

      .v-icon {
        color: #21a7e0;
      }
    }
  }

  .filters-action {
    display: flex;
    justify-content: flex-end;
    z-index: 2;

    .v-btn {
      text-transform: none;
      float: right;
    }

    &.apply-btn {
      position: sticky;
      bottom: 0;
      right: 0;
      width: fit-content;
      float: right;

      .v-btn {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }
    }

    &.reset-btn {
      ::v-deep .v-btn__content {
        letter-spacing: normal;
      }
    }
  }

  .advanced-filter-wrapper {
    ::v-deep .exp-filter-header {
      display: flex;
      align-items: center;
      gap: 5px;

      .filter-icon {
        width: 24px;
        height: 24px;
      }
      .badge-container {
        position: absolute;
        z-index: 4;
        left: 130px;
        bottom: 25px;
      }
      .badge-container-author {
        position: absolute;
        z-index: 4;
        left: 90px;
        bottom: 25px;
      }
    }
    .customGroupWrapper {
      margin-left: 4px;
      padding: 15px;
      .fromDateWrapper,
      .toDateWrapper {
        .label {
          font-size: 16px;
          font-weight: 600;
          color: black;
        }
        .datePicker {
          .v-input {
            .v-input__control {
              .v-input__slot {
                .v-input__append-inner {
                  .clearIcon,
                  .calendarIcon {
                    &:hover {
                      color: #21a7e0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .applyDatesCTA {
        width: 60%;
        margin: 0 auto;
        .v-btn {
          background: transparent;
          border: none;
          box-shadow: none;
          letter-spacing: normal;
          text-transform: none;
          font-size: 16px;
          color: #21a7e0;
          width: 100%;
          background-color: transparent !important;
        }
      }
    }
    ::v-deep .v-expansion-panels {
      height: calc(100% - 100px);
      overflow: auto;
      .v-expansion-panel {
        flex: none;
        width: 100%;
        &::before {
          box-shadow: none;
        }

        &::after {
          opacity: 0 !important;
        }
      }

      .v-expansion-panel-header {
        padding: 0 16px;
        min-height: 47px !important;
      }
      .v-expansion-panel-content__wrap {
        padding: 0;
      }
      .v-list-item {
        min-height: 30px !important;
        padding: 0;
        padding-left: 60px;
        .v-list-item__action {
          margin: 0px 7px 0px 0px;
        }
        .v-list-item__content {
          max-height: 34px;
          padding: 8px 0px;
          .v-list-item__title {
            font-size: 16px;
            line-height: 18px !important;
          }
        }
      }
    }
    .filter-header {
      padding: 5px 10px 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        display: inline-block;
        font-size: 17px;
      }
    }

    .clear-text {
      color: #21a7e0 !important;
      cursor: pointer;
    }
    .resetFilters {
      display: flex;
      padding: 15px 15px 15px 15px;
      justify-content: space-between;
      .resetText {
        color: #21a7e0 !important;
        cursor: pointer;
      }
    }
    .disabledResetFilters {
      display: flex;
      padding: 15px 0px 15px 15px;
      padding: 15px 15px 15px 15px;
      justify-content: space-between;
      .v-icon,
      .resetText {
        color: grey;
        margin: 0px 0px 0px 5px;
        cursor: default;
      }
    }
  }

  .sections-expand-panel {
    margin: 0;
    width: 100%;

    &.sections {
      margin: 0;
    }

    .prezentations-header {
      padding-left: 10px;
      padding-right: 10px;
    }

    ::v-deep .v-expansion-panel::before {
      box-shadow: none !important;
    }

    .slides-row {
      padding: 10px 0;
    }
  }

  .add-prezentation-btn {
    letter-spacing: normal;
    box-shadow: none !important;
    font-size: 14px;
    height: 37px !important;
    color: #21a7e0;
    line-height: 2rem;
    margin-left: 5px;
    margin-bottom: 2px;
    text-transform: none !important;
    background-color: transparent !important;
  }
  .sections-drag {
    width: 100%;

    .v-expansion-panel-header {
      padding: 0px;
      width: 100%;
    }
  }

  .section-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    display: flex;
    align-items: center;
  }
  .drag-icon {
    display: flex !important;
    cursor: move;
  }

  .chip-title {
    display: flex;
    align-items: center;
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    .v-icon:nth-child(2) {
      margin-left: -15px;
    }

    .v-icon {
      color: #a2a9b0;
    }

    .title-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: black;
      padding: 0px;
      white-space: normal;
      font-size: 16px;
    }
    .title-text::after {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 5px;
      background: white;
    }
  }

  .expand-all-btn {
    cursor: pointer;
    color: #21a7e0;
    padding: 0 10px;
    width: 100%;
    text-align: end;
    .v-icon {
      color: #21a7e0;
    }
  }

  .nodata {
    margin: 20px;
    text-align: center;

    img {
      max-width: 200px;
    }

    .text-btn {
      cursor: pointer;
      color: #21a7e0;
    }

    .not-found-descriptions {
      margin: 20px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

.ghost-list {
  .v-icon {
    color: #a2a9b0;
    display: none;
  }

  .checkbox {
    display: none;
  }

  ::v-deep .v-expansion-panel-header__icon {
    display: none;
  }
}

.menu-items-wrapper {
  max-height: 400px;
  overflow: auto;
  // padding-bottom: 60px;
}

.expansion-panel-data {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 10px;
  }
}
</style>
