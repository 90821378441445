<template>
  <div class="direct-download-info-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="download-info-wrapper">
      <DownloadInfoContent
        v-if="origin === 'prezentation'"
        imageUrl="/assets/img/downloadmodal/downloading-shortly.svg"
        :message="'prezentation.yourDownloadWillBeginShortly'"
        :audienceId="audienceId"
        :audienceOwnerId="audienceOwnerId"
        :isLocalDownload="isLocalDownload"
      />
      <DownloadRedesignInfo v-else-if="origin === 'redesign'" />
    </div>
    <div class="dialog-actions">
      <v-btn
        class="primary-button"
        color="#21A7E0"
        rounded
        @click="handleClose"
      >
        {{ $t('common.ok') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import DownloadInfoContent from './DownloadInfoContent.vue';
import DownloadRedesignInfo from './DownloadRedesignInfo.vue';

export default {
  name: 'DirectDownloadInfo',
  props: {
    audienceId: String,
    audienceOwnerId: String,
    origin: {
      type: String,
      default: 'prezentation',
    },
    isLocalDownload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('DirectDownloadInfo');
    },
  },
  components: {
    DownloadInfoContent,
    DownloadRedesignInfo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss';
.direct-download-info-wrapper {
  background: white;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  width: 100%;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  ::v-deep .download-info-wrapper {
    .current-audience-preferences {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .download-info-wrapper {
    margin-top: 10px;
  }

  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 20px;

    button {
      color: $white-default;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .direct-download-info-content {
    .current-audience-preferences {
      border-top: 0.5px solid #b6b6b6;
      border-bottom: 0.5px solid #b6b6b6;
      min-height: 150px;

      margin-left: -20px;
      margin-right: -20px;
      margin-top: 26px;
    }
    .direct-download-info-image-wrapper {
      display: flex;
      justify-content: center;
      img {
        width: 33%;
      }
    }

    .message {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
