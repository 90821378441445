/* eslint-disable no-undef */

/** EVENTS */
const NO_RESULTS = 'no_search_results';
const PRIVATE_AUDIENCE_ADDED = 'audience_private_added';
const PUBLIC_AUDIENCE_ADDED = 'audience_public_added';
const SHARE_TRAIL = 'share_trial';

const pendoTrack = (name, data) => {
  if (pendo && pendo.isReady && pendo.isReady()) {
    pendo.track(name, data);
    console.log(`${name} - ${data}`);
  }
};

export const PendoAnalyticsHandler = {
  noSearchResults: (data) => {
    try {
      pendoTrack(`${NO_RESULTS};${data.query}`, data);
    } catch (e) {
      console.log(e);
    }
  },
  privateAudienceAdded: (data) => {
    try {
      pendoTrack(`${PRIVATE_AUDIENCE_ADDED};${data.origin}`, data);
    } catch (e) {
      console.log(e);
    }
  },
  publicAudienceAdded: (data) => {
    try {
      pendoTrack(`${PUBLIC_AUDIENCE_ADDED};${data.origin}`, data);
    } catch (e) {
      console.log(e);
    }
  },
  shareTrial: (data) => {
    try {
      pendoTrack(SHARE_TRAIL, data);
    } catch (e) {
      console.log(e);
    }
  },
};
