<template>
  <div class="filter-sort-wrapper">
    <v-tabs v-model="currentTab" color="#21a7e0" grow height="40">
      <v-tab
        class="vtab"
        :name--auto="`prezentations-advanced-filter-filter-tab${
          currentTab === 0 ? '--active' : ''
        }`"
      >
        <span>{{ $t('prezentationsList.advancedFilters') }}</span>
      </v-tab>
      <v-tab
        class="vtab"
        :name--auto="`prezentations-advanced-filter-sort-tab${
          currentTab === 1 ? '--active' : ''
        }`"
      >
        <span>{{ $t('bestPracticesGuide.knowContainer.sortBy') }}</span>
      </v-tab>

      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <div class="advanced-filter-wrapper">
            <div
              :class="
                isAdvancedFilterChanged
                  ? 'resetFilters'
                  : 'disabledResetFilters'
              "
            >
              <div
                class="resetText"
                @click="isAdvancedFilterChanged ? resetAdvancedFilters() : null"
              >
                {{ $t('prezentationsList.resetAllFilters') }}
              </div>
            </div>
            <v-expansion-panels accordion multiple v-model="activePanels">
              <v-expansion-panel
                v-for="item in advancedItems"
                :key="item.title"
                expand
              >
                <v-expansion-panel-header>
                  <div class="exp-filter-header">
                    <img
                      class="filter-icon"
                      alt
                      :src="`/assets/img/${item.icon}.svg`"
                      width="24"
                      height="24"
                    />
                    <div>{{ $t(item.display_title) }}</div>
                    <div
                      :class="
                        item.title === 'Created from'
                          ? 'badge-container'
                          : 'badge-container-author'
                      "
                      v-if="
                        item.title === 'Created from' || item.title === 'Author'
                      "
                    ></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item
                    v-if="item.includeAllOption"
                    :disabled="
                      item.disableAll &&
                      item.disableAll === true &&
                      (item.title === 'Access' || item.title === 'Author')
                    "
                  >
                    <v-list-item-action>
                      <v-checkbox
                        v-model="item.checkAll"
                        color="primary"
                        :on-icon="'mdi-checkbox-marked-outline'"
                        :off-icon="'mdi-checkbox-blank-outline'"
                        @click="handleAllOptionClickForCategories(item)"
                      />
                    </v-list-item-action>
                    <img
                      alt
                      :src="`/assets/img/filter-all.svg`"
                      width="20"
                      height="20"
                      class="advanced-filter-icon"
                      v-if="item.showIcon"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-content-text"
                        v-text="$t('overnightPresentations.all')"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    dense
                    v-for="child in item.items"
                    :key="child.title"
                    :disabled="child.disabled"
                    :data-pendo-id="child.key"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :on-icon="'mdi-checkbox-marked-outline'"
                          :off-icon="'mdi-checkbox-blank-outline'"
                          :input-value="active"
                          v-model="child.active"
                          color="primary"
                          @click="onAdvancedFilerChange(item)"
                        />
                      </v-list-item-action>
                      <img
                        alt
                        :src="`/assets/img/filter-${child.icon}.svg`"
                        width="20"
                        height="20"
                        v-if="child.icon"
                        class="advanced-filter-icon"
                      />
                      <v-list-item-content
                        v-if="child.title === 'Overnight Prezentations'"
                      >
                        <div class="filter-op-title__wrapper">
                          <v-list-item-title
                            class="advanced-filter-content-text"
                            v-text="$t(child.display_title)"
                          />
                          <div class="badge-container">
                            <NewFeatureBadge
                              module-name="overnight_prezentations"
                              :feature-id="'overnight_prezentations_filter_id'"
                              :offsetX="'84'"
                              :offsetY="'16'"
                              :size="'medium'"
                              :userFeatureAccessDate="userFeatureAccessDate"
                            ></NewFeatureBadge>
                          </div>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content
                        v-else-if="child.title === 'Generate'"
                      >
                        <div class="filter-op-title__wrapper">
                          <v-list-item-title
                            class="advanced-filter-content-text"
                            v-text="$t(child.display_title)"
                          />
                          <div class="badge-container">
                            <NewFeatureBadge
                              :module-name="'generate'"
                              :feature-id="'generate_id'"
                              :offsetX="'120'"
                              :offsetY="'15'"
                              :size="'medium'"
                            ></NewFeatureBadge>
                          </div>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="child.title === 'Upload'">
                        <div class="filter-op-title__wrapper">
                          <v-list-item-title
                            class="advanced-filter-content-text"
                            v-text="$t(child.display_title)"
                          />
                          <div class="badge-container">
                            <NewFeatureBadge
                              :module-name="'upload'"
                              :feature-id="'upload_id'"
                              :offsetX="'120'"
                              :offsetY="'15'"
                              :size="'medium'"
                            ></NewFeatureBadge>
                          </div>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content
                        v-else-if="child.title === 'Restricted'"
                      >
                        <div class="filter-op-title__wrapper">
                          <v-list-item-title
                            class="advanced-filter-content-text"
                            v-text="$t(child.display_title)"
                          />
                          <div class="badge-container">
                            <NewFeatureBadge
                              :module-name="'restricted'"
                              :feature-id="'restricted_id'"
                              :offsetX="'100'"
                              :offsetY="'27'"
                              :size="'medium'"
                            ></NewFeatureBadge>
                          </div>
                        </div>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title
                          class="advanced-filter-content-text"
                          v-text="$t(child.display_title)"
                        />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <div v-if="item.isCustomGroup" class="customGroupWrapper">
                    <div class="fromDateWrapper">
                      <div class="label">
                        {{ $t('overnightPresentations.from') }}
                      </div>
                      <div class="datePicker">
                        <v-menu
                          v-model="fromDateMenu"
                          :close-on-content-click="false"
                          :close-on-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fromDateStr"
                              :placeholder="
                                $t('overnightPresentations.dateFormat')
                              "
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @focus="getCurrentDateFormatted('from')"
                              @click="(e) => handleDateTextFieldClick(e)"
                              @mouseup="getCurrentDateFormatted('from')"
                            >
                              <template #append>
                                <v-icon
                                  v-if="fromDateStr !== ''"
                                  @click="handleClearDate('from')"
                                  class="clearIcon"
                                >
                                  {{ 'mdi-close' }}
                                </v-icon>
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="calendarIcon"
                                >
                                  {{ 'mdi-calendar' }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fromDate"
                            :allowedDates="allowedFromDates"
                            no-title
                            scrollable
                            @input="updateFromDateMenu(false)"
                            @change="() => handleDatePickerTextChange('from')"
                            color="#20a7e0"
                            :width="`${datePickerWidth}px`"
                          />
                        </v-menu>
                      </div>
                    </div>
                    <div class="toDateWrapper">
                      <div class="label">
                        {{ $t('overnightPresentations.to') }}
                      </div>
                      <div class="datePicker">
                        <v-menu
                          v-model="toDateMenu"
                          :close-on-content-click="false"
                          :close-on-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="toDateStr"
                              :placeholder="
                                $t('overnightPresentations.dateFormat')
                              "
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @focus="getCurrentDateFormatted('to')"
                              @click="(e) => handleDateTextFieldClick(e)"
                              @mouseup="getCurrentDateFormatted('to')"
                            >
                              <template #append>
                                <v-icon
                                  v-if="toDateStr !== ''"
                                  @click="handleClearDate('to')"
                                  class="clearIcon"
                                >
                                  {{ 'mdi-close' }}
                                </v-icon>
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="calendarIcon"
                                >
                                  {{ 'mdi-calendar' }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="toDate"
                            :allowedDates="allowedToDates"
                            no-title
                            scrollable
                            @input="updateToDateMenu(false)"
                            @change="() => handleDatePickerTextChange('to')"
                            color="#20a7e0"
                            :width="`${datePickerWidth}px`"
                          />
                        </v-menu>
                      </div>
                    </div>
                    <div class="applyDatesCTA">
                      <v-btn @click="onAdvancedFilerChange(item)">
                        {{ $t('prezentationsList.applyCustomDates') }}
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-tab-item>

        <v-tab-item>
          <v-radio-group
            v-model="selectedFilter"
            @change="handleChangeFilter('click')"
            column
          >
            <v-radio
              v-for="(option, index) in filterItems"
              :key="index"
              :label="option.title"
              :value="option"
              color="primary"
            />
          </v-radio-group>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import EventBus from '../../event-bus';
import NewFeatureBadge from '../../NewFeatureBadge.vue';

export default {
  name: 'PrezentationAdvancedFilters',
  data() {
    return {
      datePickerWidth: '',
      menuPosition: {
        x: 0,
        y: 0,
      },
      currentTab: 0,
    };
  },
  components: {
    NewFeatureBadge,
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'themes',
      'startLoadingSlidesFlag',
      'companyData',
    ]),
    activePanels: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.activePanels;
      },
      set() {},
    },
    advancedFilters: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations
          .advancedFilters;
      },
      set() {},
    },
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations
          .advancedItems;
      },
      set() {},
    },
    fromDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.fromDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setPrezFromDateMenu', value);
      },
    },
    toDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.toDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setPrezToDateMenu', value);
      },
    },
    fromDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.fromDateStr;
      },
      set() {},
    },
    toDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.toDateStr;
      },
      set() {},
    },
    fromDate: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.fromDate;
      },
      set(value) {
        const payload = {
          type: 'from',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/setPrezDatePickerTextChange',
          payload,
        );
      },
    },
    toDate: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.toDate;
      },
      set(value) {
        const payload = {
          type: 'to',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/setPrezDatePickerTextChange',
          payload,
        );
      },
    },
    pickerDate: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.pickerDate;
      },
      set() {},
    },
    filterItems: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.filterItems;
      },
      set() {},
    },
    selectedFilter: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations
          .selectedFilter;
      },
      set(value) {
        this.$store.dispatch(
          'advancedFilterStore/setPrezSelectedFilter',
          value,
        );
      },
    },
    isAdvancedFilterChanged() {
      return this.advancedItems.some(
        (item) =>
          !item.checkAll ||
          (item.includeAllOption &&
            item.items &&
            item.items.some((itm) => !itm.active)) ||
          (!item.includeAllOption &&
            item.items &&
            item.items.some((itm) => itm.active)) ||
          (!item.includeAllOption && item.isDateChanged),
      );
    },
    userFeatureAccessDate() {
      let userFeatureAccessDate = null;
      const overnightPresentationFeature = this.currentUser?.features?.find(
        (feature) => feature.feature_name === 'overnight-prezentations',
      );

      if (overnightPresentationFeature) {
        userFeatureAccessDate = overnightPresentationFeature.updated_at;
      }
      return userFeatureAccessDate;
    },
  },
  methods: {
    ...mapActions('advancedFilterStore', [
      'setPrezAdvancedItems',
      'setPrezAdvancedFilters',
      'setPrezFromDateMenu',
      'setPrezToDateMenu',
      'setPrezFromDate',
      'setPrezFromDateStr',
      'setPrezToDate',
      'setPrezToDateStr',
      'setPrezDatePickerTextChange',
      'setPrezPickerDate',
    ]),
    updateFromDateMenu(type) {
      this.setPrezFromDateMenu(type);
    },
    updateToDateMenu(type) {
      this.setPrezToDateMenu(type);
    },
    getDatePickerWidth() {
      if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        this.datePickerWidth = 250;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.datePickerWidth = 218;
      } else {
        this.datePickerWidth = 280;
      }
    },
    handleDatePickerTextChange(type) {
      const payload = {
        type,
        date: type === 'from' ? this.fromDate : this.toDate,
      };
      this.setPrezDatePickerTextChange(payload);

      if (moment(this.toDate).isSameOrBefore(this.fromDate)) {
        this.handleClearDate('to');
      }
    },
    allowedFromDates(val) {
      return moment(val).isSameOrBefore(new Date());
    },
    allowedToDates(val) {
      if (this.fromDate === '') {
        return moment(val).isSameOrBefore(new Date());
      }
      return (
        moment(val).isSameOrAfter(this.fromDate) &&
        moment(val).isSameOrBefore(new Date())
      );
    },
    getCurrentDateFormatted(origin) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      this.setPrezPickerDate(`${year}-${month}-${day}`);
      if (origin === 'from' && this.fromDate)
        this.setPrezPickerDate(this.fromDate);
      if (origin === 'to' && this.toDate) this.setPrezPickerDate(this.toDate);
    },
    handleDateTextFieldClick(e) {
      this.menuPosition.x = e.clientX - 150;
      this.menuPosition.y = e.clientY;
    },
    handleClearDate(type) {
      if (type === 'from') {
        this.setPrezFromDate('');
        this.setPrezFromDateStr('');
      } else if (type === 'to') {
        this.setPrezToDate('');
        this.setPrezToDateStr('');
      }
    },
    handleFilterCategoryChangeFn() {
      EventBus.$emit('SLIDES_FILTER_CATEGORY_CHANGE');
    },
    handleSortByChange() {
      EventBus.$emit('HANDLE_KNOW_SORTING', this.sortByValue, true);
    },
    resetAdvancedFilters() {
      EventBus.$emit('PREZ_RESET_ALL_FILTERS');
    },
    onAdvancedFilerChange(item) {
      EventBus.$emit('PREZ_ON_FILTER_CHANGE', item);
    },
    handleAllOptionClickForCategories(item) {
      EventBus.$emit('PREZENTATIONS_CLICK_ALL_CATEGORY', item);
    },
    handleChangeFilter(value) {
      EventBus.$emit('PREZ_CHANGE_SORT', value);
    },
  },
  mounted() {
    this.getDatePickerWidth();
    window.addEventListener('resize', this.getDatePickerWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.getDatePickerWidth);
  },
};
</script>

<style lang="scss" scoped>
.advanced-filter-wrapper {
  .exp-filter-header {
    display: flex;
    align-items: center;
    gap: 5px;

    .filter-icon {
      width: 24px;
      height: 24px;
    }
  }

  .clear-text {
    color: #21a7e0 !important;
    cursor: pointer;
  }

  .badge-container {
    display: flex;
    position: absolute;
    right: 0;
    top: -4px;
    z-index: 4;
  }
}

.vtab {
  span {
    font-size: 16px;
    font-weight: 600;
    color: #4d5358;
    text-transform: none;
    letter-spacing: normal;
  }
}

.v-tab {
  text-transform: none !important;
  color: black;
  font-weight: 300;
  font-family: 'Lato';
  font-size: 1.1rem;
  letter-spacing: normal;
}

.customGroupWrapper {
  margin-left: 5px;
  padding: 15px 15px 15px 0;

  .fromDateWrapper,
  .toDateWrapper {
    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
    .datePicker {
      .v-input {
        .v-input__control {
          .v-input__slot {
            width: 80%;

            .v-input__append-inner {
              .clearIcon,
              .calendarIcon {
                &:hover {
                  color: #21a7e0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panels {
  height: calc(100vh - 64px - 200px);
  overflow: auto;
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .v-expansion-panel {
    flex: none;
    width: 100%;
    &::before {
      box-shadow: none;
    }

    &::after {
      opacity: 0 !important;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: 47px !important;
  }

  .v-list-item {
    min-height: 30px !important;
    padding: 0;
    padding-left: 2px;
    .v-list-item__action {
      margin: 0px 7px 0px 0px;
    }
    .v-list-item__content {
      padding: 8px 0px;
      .v-list-item__title {
        font-size: 16px;
        line-height: 18px !important;
      }
    }
  }
}

.v-expansion-panels::-webkit-scrollbar {
  display: none;
}

.applyDatesCTA {
  width: fit-content;

  .v-btn {
    padding: 0 !important;
    background: transparent !important;
    border: none;
    box-shadow: none;
    letter-spacing: normal;
    text-transform: none;
    font-size: 16px;
    color: #21a7e0;
    width: 100%;
    background-color: transparent !important;
  }

  // .disabled-applyDatesCTA {
  //   color: #878d96;
  // }
}

.resetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .resetText {
    color: #21a7e0 !important;
    cursor: pointer;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.disabledResetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .v-icon,
  .resetText {
    color: #7b7b7b;
    margin: 0;
    cursor: default;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.filter-header {
  padding: 5px 10px 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
  }

  h3 {
    font-size: 17px;
    text-align: center;
  }
}
</style>
