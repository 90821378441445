<template>
  <div class="search-item">
    <div class="item-name">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.search-item {
  .item-name {
    padding: 10px 20px;
  }
}
</style>
