<template>
  <div class="ml-list">
    <template v-if="items.length">
      <div
        class="ml-list__item"
        :class="{ 'ml-list--selected': index === selectedIndex }"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        <AudienceItem
          :itemClass="'no-padding'"
          :firstName="item.firstName"
          :lastName="item.lastName"
          :profileImg="item.profilePicture || ''"
          :email="item.email"
          :maxWidth="220"
        />
      </div>
    </template>
    <div v-else-if="loading" class="ml-list__loading">
      <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
    </div>
    <div class="ml-list__noresult" v-else>No result</div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import AudienceItem from '../AudienceItem.vue';

export default {
  name: 'UserMentionList',
  components: { AudienceItem, ClipLoader },
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items[index];

      if (item) {
        this.command({ id: item.email, label: item.fullName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ml-list {
  max-height: 250px;
  overflow-y: auto !important;
  width: 250px;
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  &__item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;
  }
  &--selected {
    border-color: #21a7e0;
  }

  &__loading,
  &__noresult {
    height: 35px;
    width: 100%;
    text-align: center;
    line-height: 35px;
  }
}
</style>
