<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM4 19H20V5H4V19ZM5 17H10V15H5V17ZM14.55 15L19.5 10.05L18.075 8.625L14.55 12.175L13.125 10.75L11.725 12.175L14.55 15ZM5 13H10V11H5V13ZM5 9H10V7H5V9Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ComplyIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
