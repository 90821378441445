<template>
  <div
    :class="`slide-wrapper ${className} section-${sectionIndex}-slide-${slideIndex}-dragarea--auto`"
    :style="[
      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md
        ? { 'min-width': '200px' }
        : {},
    ]"
    v-if="slide.assetId"
  >
    <span v-if="serialNumber" class="serial-number">{{ serialNumber }}</span>
    <div :class="`slider-content-wrapper ${getimageClass}`">
      <div
        class="image-wrapper"
        :class="`${isSelected ? 'selected' : ''}`"
        @click="handleClick"
        v-if="slide.outline"
      >
        <v-tooltip top max-width="250px">
          <template v-slot:activator="{ on }">
            <v-img
              :src="slide.thumbnail"
              v-on="on"
              alt
              max-width="337"
              min-width="200"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              :aspect-ratio="16 / 9"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
                </v-row>
              </template>
            </v-img>
          </template>
          <span class="tooltip-outline-title">{{ slide.outline }}</span>
        </v-tooltip>
      </div>
      <div
        class="image-wrapper"
        :class="`${isSelected ? 'selected' : ''}`"
        @click="handleClick"
        v-else
      >
        <v-img
          :src="slide.thumbnail"
          alt
          max-width="337"
          min-width="200"
          lazy-src="/assets/img/slides/placeholder-slide.svg"
          :aspect-ratio="16 / 9"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
            </v-row>
          </template>
        </v-img>
      </div>
      <div class="bottom-wrapper">
        <div class="bottom-wrapper-title">
          <v-tooltip bottom content-class="tooltip-content" max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="bottom-wrapper-title-header col-12 text-truncate"
              >
                {{ slide.description || getDescription(slide.outline) }}
              </div>
            </template>
            <span>
              {{ slide.description || getDescription(slide.outline) }}
            </span>
          </v-tooltip>
        </div>
        <!-- <div class="action-wrapper">
          <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="handleClick"
                :disabled="
                  isViewAccess ||
                  isTemplateRemoved ||
                  slide.isAddedFromBestpracticeDecks
                "
              >
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="zoom-icon"
                >
                  mdi-magnify-plus-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('common.view') }}</span>
          </v-tooltip>
          <div class="show-detail" @click="onClick">
            <v-tooltip bottom content-class="tooltip-content" max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="/assets/img/show-detail.svg"
                  alt
                  width="20px"
                  height="20px"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>View</span>
            </v-tooltip>
          </div>
        </div> -->
      </div>

      <div class="slide-controls" v-if="!isViewAccess && !isTemplateRemoved">
        <div :class="`drag-icon`">
          <v-icon dense> mdi-dots-vertical </v-icon>
          <v-icon dense> mdi-dots-vertical </v-icon>
        </div>
        <div class="actions">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small @click="onDuplicate">
                mdi-content-copy
              </v-icon>
            </template>
            <span> {{ $t('common.duplicate') }} </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="18" @click="onDelete">
                mdi-delete-outline
              </v-icon>
            </template>
            <span> {{ $t('common.delete') }} </span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { getTooltipForKeyword } from '../KeywordTooltipMapping';

export default {
  components: {
    ClipLoader,
  },
  props: {
    isViewAccess: {
      type: Boolean,
      default: false,
    },
    isTemplateRemoved: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isMatchedSlide: {
      type: Boolean,
      default: false,
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
    onClick: {
      type: Function,
      default: () => ({}),
    },
    onDuplicate: {
      type: Function,
      default: () => ({}),
    },
    onDelete: {
      type: Function,
      default: () => ({}),
    },
    serialNumber: {
      type: Number,
      default: 0,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getimageClass() {
      if (this.isSelected) {
        return 'selected';
      }
      if (this.isMatchedSlide) {
        return 'matched-slide';
      }
      return '';
    },
  },
  methods: {
    handleClick() {
      if (this.isTemplateRemoved || this.isViewAccess) {
        return;
      }

      if (this.onClick) {
        this.onClick();
      }
    },
    getDescription(keyword) {
      if (this.slide.isGenerated) {
        return '';
      }
      return getTooltipForKeyword(keyword);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-wrapper {
  max-width: 380px;
  min-width: 200px;
  padding: 12px 10px;
  overflow: hidden;

  .title-text::after {
    content: '' !important;
    display: none !important;
  }

  .slider-content-wrapper {
    box-shadow: $item-box-shadow;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.matched-slide {
      border: 2px solid #fea93d;
    }
  }
  .slider-content-wrapper:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3), -1px -1px 4px rgba(0, 0, 0, 0.3);
  }
  .image-wrapper img {
    width: 100%;
  }
  .image-wrapper-mosaic img {
    width: 80%;
    height: 80%;
  }
  .image-wrapper {
    border-bottom: 1px solid rgba(211, 211, 211, 0.2);
  }
  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;

    .bottom-wrapper-title {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      width: 80%;
    }
    .action-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .show-detail {
        cursor: pointer;
        margin-right: 15px;
        margin-left: 15px;
      }
    }
  }

  .zoom-icon {
    color: rgba(0, 0, 0, 0.54) !important;

    &:hover {
      color: #21a7e0 !important;
    }
  }
  .slide-controls {
    display: none;
    justify-content: space-between;

    .drag-icon {
      cursor: move;
      display: flex !important;
      padding: 4px 8px;

      .v-icon {
        width: 5px;
        display: flex;
      }
    }
    .actions {
      display: flex !important;
      padding: 4px 8px;

      .v-icon:hover {
        color: #21a7e0 !important;
      }
    }
  }

  &:hover {
    .slide-controls {
      display: flex;
    }
  }

  .serial-number {
    margin-left: -20px;
    z-index: 10;
    position: absolute;
  }
}

.tooltip-outline-title {
  font-weight: bold;
}
</style>
