<template>
  <div class="custom-skeleton" :class="[view]">
    <template v-if="type === 'type-1'">
      <div class="t1-section1">
        <div class="avatar bone"></div>
        <div class="article bone"></div>
      </div>
      <div class="t1-section2">
        <div class="text bone" />
        <div class="text bone" />
        <div class="text bone" />
        <div class="text bone" />
      </div>
    </template>
    <template v-if="type === 'type-2'">
      <div class="t2-text bone"></div>
      <div class="t2-section2">
        <div class="left-block bone"></div>
        <div class="t1-section2">
          <div class="text bone" />
          <div class="text bone" />
          <div class="text bone" />
          <div class="text bone" />
          <div class="text bone" />
        </div>
      </div>
    </template>
    <template v-if="type === 'type-3'">
      <div class="t2-text bone"></div>
      <div class="t3-section2">
        <div class="article-block">
          <div class="text bone"></div>
          <div class="paragraph bone"></div>
        </div>
        <div class="article-block">
          <div class="text bone"></div>
          <div class="paragraph bone"></div>
        </div>
        <div class="article-block">
          <div class="text bone"></div>
          <div class="paragraph bone"></div>
        </div>
      </div>
    </template>
    <template v-if="type === 'type-4'">
      <div class="t4-section1">
        <div class="t4-heading bone"></div>
        <div class="t4-subheading bone"></div>
      </div>
      <div class="t4-section2">
        <div class="avatar bone"></div>
        <div class="t1-section2">
          <div class="text bone"></div>
          <div class="text bone"></div>
          <div class="text bone"></div>
        </div>
      </div>
    </template>
    <template v-if="type === 'type-5'">
      <div class="t2-text bone"></div>
      <div class="t3-section2">
        <div class="paragraph bone"></div>
        <div class="subsection">
          <div class="block bone"></div>
          <div class="block bone"></div>
          <div class="block bone"></div>
        </div>
        <div class="subsection">
          <div class="block bone"></div>
          <div class="block bone"></div>
          <div class="block bone"></div>
        </div>
      </div>
    </template>
    <template v-if="type === 'type-6'">
      <div class="t2-text bone"></div>
      <div class="t6-section2">
        <div class="list-item">
          <div class="dot bone"></div>
          <div class="title bone"></div>
        </div>
        <div class="list-item">
          <div class="dot bone"></div>
          <div class="title bone"></div>
        </div>
        <div class="list-item">
          <div class="dot bone"></div>
          <div class="title bone"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'RedesignChangeLayoutViews',
  components: {},
  props: {
    type: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      default: 'column',
    },
  },
  methods: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.custom-skeleton {
  &.list {
    padding: 10px;
    gap: 8px;
    .bone {
      border-radius: 8px;
    }

    .text {
      height: 8px;
    }
  }

  &.column {
    padding: 16px;
    gap: 16px;
    .bone {
      border-radius: 8px;
    }
    .text {
      height: 16px;
    }

    .avatar {
      width: 86px;
      height: 86px;
    }
    .t1-section1 {
      height: 86px;
      gap: 16px;
    }

    .t2-text {
      height: 32px;
    }

    .t2-section2 {
      gap: 16px;

      .left-block {
        width: 108px;
      }

      .text {
        height: 20px;
      }
    }

    .t4-section1 {
      gap: 8px;

      .t4-heading {
        height: 38px;
      }

      .t4-subheading {
        height: 24px;
      }
    }

    .t4-section2 {
      margin-top: 8px;
      display: flex;
      gap: 8px;

      .avatar {
        height: 98px;
        width: 98px;
      }

      .text {
        height: 22px;
      }
    }

    .t6-section2 {
      .list-item {
        gap: 14px;

        .dot {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .bone {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background: linear-gradient(
      270deg,
      #34befa -5.51%,
      #c1edff 48.91%,
      #cdf0ff 100%
    );
  }

  > div {
    width: 100%;
  }

  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50% !important;
  }

  .t1-section1 {
    display: flex;
    height: 42px;
    gap: 12px;

    .article {
      flex: 1;
    }
  }
  .t1-section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .t2-text {
    height: 16px;
  }

  .t2-section2 {
    display: flex;
    flex: 1;
    gap: 8px;

    .left-block {
      width: 52px;
    }

    .text {
      height: 10px;
    }
  }

  .t3-section2 {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .article-block {
      max-width: 30%;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 8px;

      .paragraph {
        flex: 1;
        max-width: 100%;
      }
    }

    .paragraph {
      flex: 1;
      max-width: 30%;
    }

    .subsection {
      max-width: 30%;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      .block {
        max-height: 30%;
        flex: 1;
      }
    }
  }

  .t4-section1 {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .t4-heading {
      height: 18px;
    }

    .t4-subheading {
      height: 12px;
    }
  }

  .t4-section2 {
    margin-top: 4px;
    display: flex;
    gap: 8px;

    .avatar {
      height: 46px;
      width: 46px;
    }

    .text {
      height: 12px;
    }
  }

  .t6-section2 {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    .list-item {
      display: flex;
      gap: 7px;

      .dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      .title {
        flex: 1;
      }
    }
  }
}

/* Shimmer Animation */
.bone::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
