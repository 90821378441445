<template>
  <div class="tour-wrapper">
    <v-btn
      v-if="tourType && tourType !== 'newNotification'"
      class="close-btn"
      icon
      @click="handleClose"
    >
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div v-if="tourType" class="content-wrapper">
      <template v-if="tourType !== 'newNotification'">
        <div class="image-wrapper">
          <img :src="`/assets/img/tour-${tourType}.png`" alt="" />
        </div>
        <div class="tour-content" v-html="returnMarked(content)" />
      </template>
      <template v-else-if="newNotifications">
        <div class="notifications-wrapper" v-if="newNotifications.length > 0">
          <div
            class="notification-item"
            v-for="(item, index) in newNotifications"
            :key="index"
          >
            <div class="top-panel">
              <v-badge dot inline left>
                <div class="notification-title">
                  {{ item.notification }}
                </div>
              </v-badge>
            </div>
            <div class="bottom-panel">
              {{ elapsedTime(item.createdAt) }}
            </div>
          </div>
        </div>
        <div class="no-notification">
          <img src="/assets/img/no-notifications.svg" alt="" />
          <div class="no-notification-title">
            There are no new notifications
          </div>
        </div>
      </template>
    </div>
    <div
      class="dialog-actions"
      v-if="tourType && tourType !== 'newNotification'"
    >
      <v-btn
        :class="`cancel ma-2 py-2 ${tourType === 'support' ? 'hide' : ''}`"
        text
        width="60"
        height="30"
        @click="handleClose"
      >
        Skip Tour
      </v-btn>
      <v-btn
        class="download ma-2 py-2"
        text
        width="60"
        height="30"
        @click="handleStartTour"
      >
        <div v-if="tourType === 'support'">Done</div>
        <div v-else>Next</div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { mapActions, mapState } from 'vuex';
import { tourService } from '../../services/tourServie';
import usersApi from '../../API/users-api';

export default {
  name: 'Tour',
  components: {},
  props: {
    content: {
      type: [String, Array],
      required: false,
    },
    tourType: {
      type: String,
      required: true,
      default: 'initial',
    },
  },
  data() {
    return {
      newNotifications: [],
      types: {
        'new-presentation': 'my-presentation',
        'my-presentation': 'slides',
        slides: 'learn',
        learn: 'audience',
        audience: 'notifications',
        notifications: 'share',
        share: 'profile',
        profile: 'support',
        support: 'initial',
      },
    };
  },
  computed: {
    ...mapState('tourStore', ['notifications']),
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('tourStore', ['setTour']),
    ...mapActions('users', ['setCurrentUser']),
    returnMarked(content) {
      if (content) {
        return DOMPurify.sanitize(
          `<div class="marked-container">${marked(content)}</div>`,
        );
      }
      return content;
    },
    elapsedTime(createdDate) {
      const prevTime = Date.parse(createdDate);
      const thisTime = new Date();
      const diff = thisTime.getTime() - prevTime;
      return `${Math.round(diff / (1000 * 60))} minutes ago`;
    },
    handleClose() {
      tourService.setSelectedTour('initial');
      const userdata = {
        takenOrSkippedTour: true,
      };
      usersApi.methods
        .updateUserProfile(userdata)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(this.currentUser);
        })
        .catch((err) => console.log('error while updating skip tour', err));
    },
    handleStartTour() {
      if (this.tourType === 'support') {
        this.handleClose();
      }
      tourService.setSelectedTour(this.types[this.tourType]);
      this.currentUser.user.takenOrSkippedTour = true;
    },
  },
  beforeMount() {
    this.newNotifications = this.notifications;
  },
  mounted() {
    // if (this.newNotifications.length < 1) {
    //   document.getElementById("tour-dialog").style.height = "171px";
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.tour-wrapper {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  opacity: 1;
}

.tour-content {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 15px;

  ::v-deep .highlight {
    font-weight: bold;
  }
}

.content-wrapper {
  padding: 5px 10px 5px 10px;
  width: 100%;
  height: 268px;

  .no-notification {
    margin-top: 30px;
    img {
      margin-bottom: 10px;
    }

    .no-notification-title {
      font-size: 16px;
    }
  }

  .notification-item {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 5px;
    padding-top: 5px;

    .top-panel {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      ::v-deep .v-badge--inline {
        align-items: flex-start;
      }

      .notification-title {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
    .bottom-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b6b6b6;
    }
  }
}

.image-wrapper {
  margin: auto;
  margin-top: 15px;
  width: 164px;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.dialog-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  button.cancel {
    text-transform: none;
    color: black !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 15px;

    &.hide {
      visibility: hidden;
    }
  }

  button.download {
    border-radius: 25px;
    text-transform: none;
    color: #21a7e0 !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
</style>
