<template>
  <div class="tmpDesignerCompleteSelectCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{
          $t('templateDesign.banners.cravingSlideCustomizations', {
            templateName: getTemplateName,
          })
        }}
      </div>
      <div class="navCTA">
        <v-btn @click="getInTouch" :loading="sendingEmail">{{
          $t('templateDesign.banners.getInTouch')
        }}</v-btn>
      </div>
      <div class="removeBanner">
        <v-progress-circular
          indeterminate
          v-if="removingBanner"
          color="primary"
          size="20"
        />
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="removeBanner()" v-bind="attrs" v-on="on">{{
              'mdi-close'
            }}</v-icon>
          </template>
          <span>{{ $t('templateDesign.banners.dismiss') }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { TemplateDesignerService } from '../../../pages/components/TemplateDesigner/TemplateDesignerService.js';
import ContactSoonModal from '../ContactSoonModal.vue';
import { sendEmail } from '../../../utils/api-helper';

export default {
  name: 'TemplateDesignerSelectBanner', // Banner is displayed after designer template selection to opt for more customization
  data() {
    return {
      removingBanner: false,
      sendingEmail: false,
      jobUUID: '',
    };
  },
  mounted() {
    const regex = /uuid_(.*)/;
    const [, uuid] = this.componentKey.match(regex);
    this.jobUUID = uuid;
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('templateDesigner', ['getTDData']),
    getTemplateName() {
      return this.getCurrentTDData.name ? this.getCurrentTDData.name : '';
    },
    getCurrentTDData() {
      const data = this.getTDData.filter((item) => item.uuid === this.jobUUID);
      return data.length ? data[0] : {};
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },
    isBPEPage() {
      // Return false if $route is not yet available
      if (!this.$route) return false;
      const { path } = this.$route;
      // Check if the current route path includes 'best-practice-library'
      return path.includes('best-practice-library');
    },
  },
  props: {
    componentKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('templateDesigner', ['setTDData']),
    async removeBanner() {
      this.removingBanner = true;
      // To hide TemplateDesignerSelectBanner
      const updatedTDData = this.getTDData.map((item) => {
        if (item.uuid === this.jobUUID) {
          item = {
            ...item,
            banners: {
              ...item.banners,
              templateSelectionBanner: 1,
            },
          };
        }
        return item;
      });
      this.templateDesignerService.updateBanner({
        uuid: this.jobUUID,
        bannerType: 'templateSelectionBanner',
      });
      this.setTDData(updatedTDData);
      this.removingBanner = false;
    },
    async getInTouch() {
      this.sendingEmail = true;
      const params = {
        type: 'template-designer-request',
        meta: {
          firstName: `${this.currentUser?.user?.firstName}`,
          trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
        },
      };
      await sendEmail(params)
        .then((response) => {
          console.log(response);
          this.sendingEmail = false;
        })
        .catch((err) => {
          console.log(err);
          this.sendingEmail = false;
        });
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerCompleteSelectCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
