import { trackFingerprintEvents } from './FingerprintEvents';
import { trackGeneralEvents } from './GeneralEvents';
import {
  TD_CREATED_FINGERPRINTS,
  TD_EXTERNAL_AUDIENCES,
  TD_MY_FINGERPRINT,
  TD_PROFILE_ACCESS_REQUESTS,
  TD_PROFILE_CREATED_FINGERPRINTS,
  TD_PROFILE_EXTERNAL_AUDIENCES,
  TD_PROFILE_MY_FINGERPRINT,
  TD_SHARE_FINGERPRINT_CREATED,
  TD_SHARE_FINGERPRINT_MFP,
  TD_SHARE_FINGERPRINT_NAVBAR,
  TD_SHARE_FINGERPRINT_ZENTRENDS,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const PROFILE_EVENT_CATEGORY = 'profile';
const PROFILE_ICONCLICK = 'profile_IconClick';
const PROFILE_BASIC_CLICK = 'profile_Basic_Click';
const PROFILE_BASIC_EDITPHOTO = 'profile_Basic_EditPhoto';
const PROFILE_BASIC_EDITPHOTOSAVE = 'profile_Basic_EditPhotoSave';
const PROFILE_BASIC_EDITPROFILE = 'profile_Basic_EditProfile';
const PROFILE_BASIC_CHANGEPASSWORD = 'profile_Basic_ChangePassword';
const PROFILE_BASIC_CHANGEPASSWORDEMAILCODE =
  'profile_Basic_ChangePasswordEmailcode';
const PROFILE_FINGERPRINT_CLICK = 'profile_Fingerprint_Click';
const PROFILE_FINGERPRINT_RETAKETEST = 'profile_Fingerprint_Retaketest';
const PROFILE_TEMPLATES_CLICK = 'profile_Templates_Click';
const PROFILE_TEMPLATES_SEARCH = 'profile_Templates_Search';
const PROFILE_TEMPLATES_SWITCH = 'profile_Templates_Switch';
const PROFILE_METRICS_CLICK = 'profile_Metrics_Click';
const PROFILE_METRICS_SEARCH = 'profile_Metrics_Search';
const PROFILE_METRICS_TAKESURVEY = 'profile_Metrics_TakeSurvey';
const PROFILE_METRICS_TAKESURVEYSUBMIT = 'profile_Metrics_TakeSurveySubmit';
const PROFILE_FEATUREREQUEST_CLICK = 'profile_FeatureRequest_Click';
const PROFILE_FEATUREREQUEST_SUBMIT = 'profile_FeatureRequest_Submit';
const PROFILE_ADMINSETTINGS_CLICK = 'profile_AdminSettings_Click';
const PROFILE_ADMINSETTINGS_SEARCH = 'profile_AdminSettings_Search';
const PROFILE_ADMINSETTINGS_ADDNEWUSERS_CLICK =
  'profile_AdminSettings_AddnewUsers_Click';
const PROFILE_ADMINSETTINGS_BUYADDITIONALLICENSE =
  'profile_AdminSettings_BuyAdditionalLicense';
const PROFILE_ADMINSETTINGS_RENEW = 'profile_AdminSettings_Renew';
const PROFILE_ADMINSETTINGS_UPGRADE = 'profile_AdminSettings_Upgrade';

const PROFILE_FINGERPRINT_MYFINGERPRINTCLICK =
  'profile_Fingerprint_MyFingerprintClick';
const PROFILE_FINGERPRINT_CREATEDFINGERPRINTSCLICK =
  'profile_Fingerprint_createdfingerprintsclick';
const PROFILE_FINGERPRINT_ACCESSREQUESTSCLICK =
  'profile_Fingerprint_Accessrequestsclick';
const PROFILE_FINGERPRINT_EXTERNALFINGERPRINTSCLICK =
  'profile_Fingerprint_ExternalFingerprintsClick';
const PROFILE_FINGERPRINT_SHARE_UPDATE = 'profile_Fingerprint_share_update';
const PROFILE_FINGERPRINT_COPYLINK = 'profile_Fingerprint_copylink';
const PROFILE_FINGERPRINT_CREATEDFINGERPRINTS_DELETE =
  'profile_Fingerprint_createdfingerprints_delete';
const PROFILE_FINGERPRINT_ACCESSREQUESTS_APPROVE_ALL =
  'profile_Fingerprint_Accessrequests_approve_all';
const PROFILE_FINGERPRINT_ACCESSREQUESTS_DECLINE_ALL =
  'profile_Fingerprint_Accessrequests_Decline_all';
const PROFILE_FINGERPRINT_ACCESSREQUESTS_ACTION_APPROVE =
  'profile_Fingerprint_Accessrequests_action_approve';
const PROFILE_FINGERPRINT_ACCESSREQUESTS_ACTION_DECLINE =
  'profile_Fingerprint_Accessrequests_action_decline';
const PROFILE_STORYLINES_CLICK = 'Profile_storylines_click';
const PROFILE_STORYLINES_BUILDNEWPREZ = 'Profile_storylines_BuildnewPrez';
const PROFILE_STORYLINES_SHARE = 'Profile_storylines_share';
const PROFILE_STORYLINES_SHARE_UPDATE = 'Profile_storylines_share_update';
const PROFILE_STORYLINES_BUILD = 'Profile_storylines_Build';
const PROFILE_STORYLINES_COPYLINK = 'Profile_storylines_copylink';
const PROFILE_STORYLINES_EDIT = 'Profile_storylines_Edit';
const PROFILE_STORYLINES_EDIT_SAVEEDITS = 'Profile_storylines_Edit_Saveedits';
const PROFILE_STORYLINES_EDIT_SAVEEDITSANDGOTOSTORYLINE =
  'Profile_storylines_Edit_Save-and-go-to-storyline';
const PROFILE_STORYLINES_DUPLICATE = 'Profile_storylines_duplicate';
const PROFILE_STORYLINES_DELETE = 'Profile_storylines_delete';
const PROFILE_STORYLINES_NAMEOFSTORYLINE = 'Profile_storylines_Nameofstoryline';
const PROFILE_STORYLINEVIEW_BUILD = 'Profile_storylineview_Build';
const PROFILE_STORYLINEVIEW_SHARE = 'Profile_storylineview_share';
const PROFILE_STORYLINEVIEW_SHARE_UPDATE = 'Profile_storylineview_share_update';
const PROFILE_STORYLINEVIEW_COPYLINK = 'Profile_storylineview_copylink';
const PROFILE_STORYLINEVIEW_EDIT = 'Profile_storylineview_edit';
const PROFILE_STORYLINEVIEW_EDIT_SAVE_EDITS =
  'Profile_storylineview_Edit_Save-edits';
const PROFILE_STORYLINEVIEW_DUPLICATE = 'Profile_storylineview_Duplicate';
const PROFILE_STORYLINEVIEW_DUPLICATE_GOTO_NEW_STORYLINE =
  'Profile_storylineview_Duplicate_Go-to-new-storyline';
const PROFILE_STORYLINEVIEW_DUPLICATE_STAY_ON_EXISTING_STORYLINE =
  'Profile_storylineview_Duplicate_Stay-on-existing-storyline';
const PROFILE_STORYLINEVIEW_DELETE = 'Profile_storylineview_Delete';
const PROFILE_STORYLINEVIEW_SAVE_STORYLINE =
  'Profile_storylineview_Save-storyline';
const PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE =
  'Profile_storylineview_Save-as-new-storyline';
const PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE_SAVE =
  'Profile_storylineview_Save-as-new-storyline_Save';
const PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE_SAVE_GOTO_NEW_STORYLINE =
  'Profile_storylineview_Save-as-new-storyline_Save_Go-to-new-storyline';
const PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE_SAVE_STAY_ON_EXISTING_STORYLINE =
  'Profile_storylineview_Save-as-new-storyline_Save_Stay-on-existing-storyline';
const PROFILE_BASIC_SAVEPROFILE = 'profile_Basic_SaveProfile';
const PROFILE_BASIC_JOBTITLE_CHANGED = 'profile_Basic_Jobtitle_changed';
const PROFILE_BASIC_JOBFUNCTION_CHANGED = 'profile_Basic_Jobfunction_changed';
const PROFILE_BASIC_INDUSTRY_CHANGED = 'profile_Basic_Industry_changed';
const PROFILE_TEMPLATES_ADD_MORE_GET_IN_TOUCH =
  'Profile_Templates_Add-more_Get-in-touch';
const PROFILE_METRICS_TEAM_BUTTONCLICK = 'Profile_Metrics_Team_buttonclick';
const PROFILE_METRICS_ME_BUTTONCLICK = 'Profile_Metrics_me_buttonclick';
// const PROFILE_ADMINSETTINGS_RENEW_PAY_BY_CARD =
//   'profile_AdminSettings_Renew_Pay-by-card';
// const PROFILE_ADMINSETTINGS_RENEW_GET_IN_TOUCH =
//   'profile_AdminSettings_Renew_Get-in-touch';
// const PROFILE_ADMINSETTINGS_UPGRADE_GET_IN_TOUCH =
//   'profile_AdminSettings_upgrade_Get-in-touch';
const PROFILE_ADMINSETTINGS_DISABLE_OK = 'profile_AdminSettings_disable_ok';
const PROFILE_ADMINSETTINGS_ENABLE_OK = 'profile_AdminSettings_enable_ok';

const PROFILE_ADMINSETTINGS_PAYMENT_METHODS_CLICK =
  'profile_AdminSettings_payment-methods_click';
const PROFILE_ADMINSETTINGS_PAYMENTS_ADD_NEW_PAYMENT_METHOD =
  'profile_AdminSettings_Payments_add-new-payment-method';
const PROFILE_ADMINSETTINGS_PAYMENTS_ADD_NEW_PAYMENT_METHOD_ADD =
  'profile_AdminSettings_Payments_add-new-payment-method_add';
const PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_EDIT =
  'profile_AdminSettings_Payments_elipsis_edit';
const PROFILE_ADMINSETTINGS_PAYMENTS_UPDATE =
  'profile_AdminSettings_Payments_Update';
const PROFILE_ADMINSETTINGS_PAYMENTS_CANCEL =
  'profile_AdminSettings_Payments_cancel';
const PROFILE_ADMINSETTINGS_PAYMENTS_ERROR_POPUP =
  'profile_AdminSettings_Payments_Error-popup';
const PROFILE_ADMINSETTINGS_PAYMENTS_ERROR_POPUP_TRYAGAIN =
  'profile_AdminSettings_Payments_Error-popup_Tryagain';
const PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_DELETE =
  'profile_AdminSettings_Payments_elipsis_Delete';
const PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_DELETE_DELETE =
  'profile_AdminSettings_Payments_elipsis_Delete_Delete';
const PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_SET_AS_DEFAULT =
  'profile_AdminSettings_Payments_elipsis_Set-as-default';
const PROFILE_ADMINSETTINGS_PAYMENTS_SELECT_A_DIFFERENT_CARD =
  'profile_AdminSettings_Payments_Select-a-different-card';
const PROFILE_ADMINSETTINGS_PAYMENTS_DISCLAIMER_BOX_CLICK =
  'profile_AdminSettings_Payments_disclaimer-box_click';
const PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_ON =
  'profile_AdminSettings_Payments_toggle-on';
const PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_ON_AGREE_AND_ENABLE_AUTO_RENEW =
  'profile_AdminSettings_Payments_toggle-on_Agree-and-enable-auto-renew';
const PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_OFF =
  'profile_AdminSettings_Payments_toggle-off';
const PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_OFF_KEEP_AUTO_RENEW =
  'profile_AdminSettings_Payments_toggle-off_Keep-auto-renew';
const PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_OFF_DISABLE_AUTO_RENEW =
  'profile_AdminSettings_Payments_toggle-off_Disable-auto-renew';
const PROFILE_ADMINSETTINGS_ADD_USERS_SEND_EMAIL =
  'profile_AdminSettings_Add-Users_Send-email';
const PROFILE_ADMINSETTINGS_RENEW_PAY_BY_CARD =
  'profile_AdminSettings_Renew_Pay-by-card';
const PROFILE_ADMINSETTINGS_RENEW_GET_IN_TOUCH =
  'profile_AdminSettings_Renew_Get-in-touch';
const PROFILE_ADMINSETTINGS_UPGRADE_GET_IN_TOUCH =
  'profile_AdminSettings_upgrade_Get-in-touch';
const PROFILE_ADMINSETTINGS_BUY_ADDITIONAL_LICENSES_PAY_BY_CARD =
  'profile_AdminSettings_Buy-additional-licenses_Pay-by-card';
const PROFILE_ADMINSETTINGS_BUY_ADDITIONAL_LICENSES_RENEW_GET_IN_TOUCH =
  'profile_AdminSettings_Buy-additional-licenses_Get-in-touch';
const PROFILE_ADMINSETTINGS_USER_ACCESS_BULK_ACTIONS_SELECTED =
  'profile_AdminSettings_user-access_Bulk-actions_Selected';
const PROFILE_OP_USER_ACCESS = 'Profile_OP-user-access';
const PROFILE_ADMINSETTINGS_BUY_ADDITIONAL_LICENSES_PAY_BY_CARD_AGREE_AND_SUBSCRIBE =
  'profile_AdminSettings_Buy-additional-licenses_Pay-by-card_Agree-and-subscribe';
const PROFILE_METRICS_DATE_FILTER_USED = 'Profile_Metrics_date-filter-used';
const PROFILE_METRICS_DOWNLOAD = 'Profile_Metrics_download';
const PROFILE_ADMINSETTINGS_UPGRADE_PAY_BY_CARD_AGREE_AND_SUBSCRIBE =
  'profile_AdminSettings_Upgrade_Pay-by-card_Agree-and-subscribe';
const PROFILE_ADMINSETTINGS_RENEW_PAY_BY_CARD_AGREE_AND_RENEW =
  'profile_AdminSettings_Renew_Pay-by-card_Agree-and-renew';
const PROFILE_ADMINSETTINGS_UPGRADE_PAY_BY_CARD =
  'profile_AdminSettings_Upgrade_Pay-by-card';
const PROFILE_ADMINSETTINGS_SUBSCRIPTION_BUY =
  'profile_AdminSettings_subscription-Buy';

const fingerPrintTabVsActionMap = {
  [TD_PROFILE_MY_FINGERPRINT]: PROFILE_FINGERPRINT_MYFINGERPRINTCLICK,
  [TD_PROFILE_CREATED_FINGERPRINTS]:
    PROFILE_FINGERPRINT_CREATEDFINGERPRINTSCLICK,
  [TD_PROFILE_ACCESS_REQUESTS]: PROFILE_FINGERPRINT_ACCESSREQUESTSCLICK,
  [TD_PROFILE_EXTERNAL_AUDIENCES]:
    PROFILE_FINGERPRINT_EXTERNALFINGERPRINTSCLICK,
};

export const fpCopyVsPlacementMap = {
  [TD_PROFILE_MY_FINGERPRINT]: TD_MY_FINGERPRINT,
  [TD_PROFILE_CREATED_FINGERPRINTS]: TD_CREATED_FINGERPRINTS,
  [TD_PROFILE_EXTERNAL_AUDIENCES]: TD_EXTERNAL_AUDIENCES,
};

export const trackProfileEvents = {
  profileIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicEditPhoto(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_EDITPHOTO,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicEditPhotoSave(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_EDITPHOTOSAVE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicEditProfile(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_EDITPROFILE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicSaveProfile(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_SAVEPROFILE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicJobtitleChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_JOBTITLE_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicJobfuntionChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_JOBFUNCTION_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicIndustryChanged(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_INDUSTRY_CHANGED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileTemplatesAddmoreGetinTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_TEMPLATES_ADD_MORE_GET_IN_TOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsTeamButtonClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_TEAM_BUTTONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsMeButtonClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_ME_BUTTONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsDisableOKclick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_DISABLE_OK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsEnableOKclick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_ENABLE_OK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicChangePassword(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_CHANGEPASSWORD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileBasicChangePasswordEmailcode(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_BASIC_CHANGEPASSWORDEMAILCODE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintRetaketest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_RETAKETEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileTemplatesClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_TEMPLATES_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileTemplatesSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_TEMPLATES_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileTemplatesSwitch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_TEMPLATES_SWITCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFeatureRequestClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FEATUREREQUEST_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFeatureRequestSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FEATUREREQUEST_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsAddnewUsersClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_ADDNEWUSERS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsBuyAdditionalLicense(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_BUYADDITIONALLICENSE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsRenew(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_RENEW,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsUpgrade(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_UPGRADE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsTakeSurvey(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_TAKESURVEY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsTakeSurveySubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_TAKESURVEYSUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },

  profileFingerprintTabClick(currentUser, fingerprintTab, otherData) {
    try {
      const eventAction = fingerPrintTabVsActionMap[fingerprintTab];
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction,
      });
    } catch (e) {
      console.log(e);
    }
  },

  profileFingerprintShareUpdate(currentUser, origin, otherData) {
    try {
      if (
        origin === TD_SHARE_FINGERPRINT_MFP ||
        origin === TD_SHARE_FINGERPRINT_CREATED
      ) {
        trackCommonEvent({
          currentUser,
          otherData,
          eventCategory: PROFILE_EVENT_CATEGORY,
          eventAction: PROFILE_FINGERPRINT_SHARE_UPDATE,
        });
      }
      if (origin === TD_SHARE_FINGERPRINT_NAVBAR) {
        trackGeneralEvents.shareFingerprintUpdate(currentUser, otherData);
      }
      if (origin === TD_SHARE_FINGERPRINT_ZENTRENDS) {
        trackFingerprintEvents.fingerprintsMyFingerprintShareMyFingerprintUpdate(
          currentUser,
          otherData,
        );
      }
    } catch (e) {
      console.log(e);
    }
  },

  profileFingerprintCopylink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_COPYLINK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintCreatedfingerprintsDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_CREATEDFINGERPRINTS_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintAccessrequestsApproveAll(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_ACCESSREQUESTS_APPROVE_ALL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintAccessrequestsDeclineAll(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_ACCESSREQUESTS_DECLINE_ALL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintAccessrequestsActionApprove(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_ACCESSREQUESTS_ACTION_APPROVE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileFingerprintAccessrequestsActionDecline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_FINGERPRINT_ACCESSREQUESTS_ACTION_DECLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesBuildnewPrez(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_BUILDNEWPREZ,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesShare(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_SHARE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesShareUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_SHARE_UPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesBuild(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_BUILD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesCopylink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_COPYLINK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesEdit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_EDIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesEditSaveedits(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_EDIT_SAVEEDITS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesEditSaveeditsandgotostoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_EDIT_SAVEEDITSANDGOTOSTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesDuplicate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_DUPLICATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylinesNameofstoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINES_NAMEOFSTORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewBuild(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_BUILD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewShare(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_SHARE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewShareUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_SHARE_UPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewCopylink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_COPYLINK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewEdit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_EDIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewDuplicate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_DUPLICATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewDuplicateGotoNewStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_DUPLICATE_GOTO_NEW_STORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewDuplicateStayOnExistingStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_DUPLICATE_STAY_ON_EXISTING_STORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewSaveStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_SAVE_STORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewSaveAsNewStoryline(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewSaveAsNewStorylineSave(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE_SAVE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewSaveAsNewStorylineSaveGotoNewStoryline(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE_SAVE_GOTO_NEW_STORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewSaveAsNewStorylineSaveStayOnExistingStoryline(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_STORYLINEVIEW_SAVE_AS_NEW_STORYLINE_SAVE_STAY_ON_EXISTING_STORYLINE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileStorylineviewEditSaveEdits(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_STORYLINEVIEW_EDIT_SAVE_EDITS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentMethodClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENT_METHODS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsAddNewPaymentMethod(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ADD_NEW_PAYMENT_METHOD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsAddNewPaymentMethodAdd(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ADD_NEW_PAYMENT_METHOD_ADD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsElipsisEdit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_EDIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_UPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsErrorPopup(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ERROR_POPUP,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsErrorPopupTryAgain(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ERROR_POPUP_TRYAGAIN,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsElipsisDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsElipsisDeleteDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_DELETE_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsElipsisSetAsDefault(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_ELIPSIS_SET_AS_DEFAULT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsSelectaDifferentCard(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_SELECT_A_DIFFERENT_CARD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsDisclaimerBoxClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_DISCLAIMER_BOX_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsToggleOn(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_ON,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsToggleOnAgreeAndEnableAutoRenew(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_ON_AGREE_AND_ENABLE_AUTO_RENEW,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsToggleOff(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_OFF,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsToggleOffKeepAutoRenew(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_OFF_KEEP_AUTO_RENEW,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsPaymentsToggleOffDisableAutoRenew(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_ADMINSETTINGS_PAYMENTS_TOGGLE_OFF_DISABLE_AUTO_RENEW,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsAddUsersSendEmail(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_ADD_USERS_SEND_EMAIL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsRenewPayByCard(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_RENEW_PAY_BY_CARD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsRenewGetInTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_RENEW_GET_IN_TOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsUpgradeGetInTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_UPGRADE_GET_IN_TOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsBuyAdditionalLicensesRenewPayByCard(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_BUY_ADDITIONAL_LICENSES_PAY_BY_CARD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsBuyAdditionalLicensesRenewRenewGetinTouch(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_ADMINSETTINGS_BUY_ADDITIONAL_LICENSES_RENEW_GET_IN_TOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsUserAccessBulkActionsSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_USER_ACCESS_BULK_ACTIONS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileOPUserAccess(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_OP_USER_ACCESS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsBuyAdditionalLicensePayByCardAgreeAndSubscribe(
    currentUser,
    otherData,
  ) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_ADMINSETTINGS_BUY_ADDITIONAL_LICENSES_PAY_BY_CARD_AGREE_AND_SUBSCRIBE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsDateFilterUsed(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_DATE_FILTER_USED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileMetricsDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_METRICS_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsUpgradeAgreeAndSubscribe(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction:
          PROFILE_ADMINSETTINGS_UPGRADE_PAY_BY_CARD_AGREE_AND_SUBSCRIBE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsRenewAgreeAndRenew(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_RENEW_PAY_BY_CARD_AGREE_AND_RENEW,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsUpgradePayByCard(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_UPGRADE_PAY_BY_CARD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  profileAdminSettingsBuy(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: PROFILE_EVENT_CATEGORY,
        eventAction: PROFILE_ADMINSETTINGS_SUBSCRIPTION_BUY,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
