<template>
  <div>
    <div class="trial-subsctiption-banner" v-if="showBanner && bannerMessage">
      <span class="text" id="banner-text">
        {{ bannerMessage }}
      </span>
      <span>
        <v-btn
          medium
          color="primary"
          text
          @click="upgradeFromBanner(placement, 'banner')"
        >
          {{ $t('profile.upgrade') }}
        </v-btn>
      </span>
    </div>
    <!-- <div class="popup-banner" v-if="showUpgradePopup">
      <div class="banner-header">
        <span class="popup-banner-title">
          {{ $t('trialAndSubscribeBanner.bannerHeader') }}
        </span>
        <span>
          <v-icon @click="handleCloseModal"> mdi-close </v-icon>
        </span>
      </div>
      <div class="banner-image">
        <v-img :src="popupIllustration"></v-img>
      </div>
      <div class="banner-message" id="banner-message">
        {{ popupMessage }} {{ $t('trialAndSubscribeBanner.upgradeNow') }}
      </div>
      <div class="banner-action">
        <v-btn medium color="primary" rounded @click="onUpgradeClick">
          {{ $t('profile.upgrade') }}
        </v-btn>
      </div>
    </div> !-->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      showBanner: false,
      bannerMessage: '',
      placement: '',
    };
  },
  props: {
    upgradeFromBanner: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  computed: {
    ...mapState('prezentationDetails', ['selectedMainAction']),
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
  },
  watch: {
    currentUser() {
      this.showBannerHandler();
    },
    // eslint-disable-next-line func-names
    'currentUser.user.language': function () {
      // this.upgradePopupTypeHandler(this.upgradePopupType);
      this.getMessage();
    },
    limitsInfoObj: {
      handler() {
        this.getMessage();
      },
      deep: true,
    },
    // eslint-disable-next-line func-names
    async $route() {
      this.bannerMessage = '';
      this.getMessage();
    },
    selectedMainAction() {
      this.getMessage();
    },
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    showBannerHandler() {
      const { pathname } = location;
      this.showBanner = true;
      if (
        pathname.includes('/home/slides') ||
        pathname.includes('/home/prezentations') ||
        pathname.includes('/home/best-practice-library') ||
        pathname.includes('/home/main') ||
        pathname.includes('/profile') ||
        pathname.includes('/home/fingerprint') ||
        pathname.includes('/home/learn')
      ) {
        this.showBanner =
          this.currentUser.user.role.toLowerCase() === 'trialuser';
        // this.setUpgradeBanner(this.showBanner);
      }
    },
    getMessage() {
      this.showBannerHandler();
      this.bannerMessage = '';
      const { pathname } = location;

      if (
        pathname.includes('/home/main') ||
        pathname.includes('/profile') ||
        pathname.includes('/home/fingerprint')
      ) {
        if (pathname.includes('/home/main')) {
          this.placement = 'Home Screen';
        } else if (pathname.includes('/profile')) {
          this.placement = 'Profile';
        } else {
          this.placement = 'Fingerprints';
        }
        const daysLeft = this.currentUser?.subscriptions?.[0]?.daysLeft;
        if (daysLeft === 0) {
          this.bannerMessage = this.$t(
            'trialAndSubscribeBanner.trialExpiresToday',
          );
        } else if (daysLeft > 0) {
          this.bannerMessage = this.$t(
            'trialAndSubscribeBanner.trialExpiresIn',
            {
              days: daysLeft,
              dayText: daysLeft === 1 ? 'day' : 'days',
            },
          );
        } else {
          this.bannerMessage = this.$t('trialAndSubscribeBanner.trialExpired');
        }
      } else if (
        pathname.includes('/home/build') &&
        this.currentUser?.user?.role === 'TrialUser'
      ) {
        this.placement = 'Story Builder';
        const data = this.limitsInfoObj?.slide || {};

        if (data.feature) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t(
                'trialAndSubscribeBanner.slidesDownloadExceededToBuildPrezentation',
              )
            : this.$t('trialAndSubscribeBanner.slideDownloadLeft', {
                slides: data.remainingLimit,
                downloadText:
                  data.remainingLimit === 1 ? 'download' : 'downloads',
              });
        }
        if (this.selectedMainAction.id === 'convertTemplate') {
          this.getTCbanner();
        }
      } else if (
        pathname.includes('/home/prezentations') ||
        ((pathname.includes('/home/myPrezentation') ||
          pathname.includes('/home/build') ||
          pathname.includes('/home/best-practice-library-decks')) &&
          (this.limitsInfoObj?.comply?.access_level === 'restricted_access' ||
            this.limitsInfoObj?.synthesis?.access_level ===
              'restricted_access'))
      ) {
        let data = this.limitsInfoObj?.slide || {};
        this.placement = 'Prezentation Exchange';

        if (data.feature && this.currentUser?.user?.role === 'TrialUser') {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.prezentationsDownloadExceeded')
            : this.$t('trialAndSubscribeBanner.slideDownloadLeft', {
                slides: data.remainingLimit,
                downloadText:
                  data.remainingLimit === 1 ? 'download' : 'downloads',
              });
        }

        if (this.selectedMainAction.id === 'convertTemplate') {
          this.getTCbanner();
        } else if (this.selectedMainAction.id === 'synthesize') {
          this.placement = 'Synthesis';
          data = this.limitsInfoObj?.synthesis || {};
          this.showBanner = data.access_level === 'restricted_access';
          if (data.feature && !data.isFullAccess) {
            this.bannerMessage = data.isLimitCrossed
              ? this.$t('trialAndSubscribeBanner.synthesisExceeded')
              : this.$t('trialAndSubscribeBanner.synthesisLeft', {
                  slides: data.remainingLimit,
                });
          }
        }
      } else if (
        pathname.includes('/home/slides') &&
        this.currentUser?.user?.role === 'TrialUser'
      ) {
        this.placement = 'Slide Library';
        const data = this.limitsInfoObj?.slide || {};

        if (data.feature) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.slidesDownloadExceeded')
            : this.$t('trialAndSubscribeBanner.slideDownloadLeft', {
                slides: data.remainingLimit,
                downloadText:
                  data.remainingLimit === 1 ? 'download' : 'downloads',
              });
        }
      } else if (
        pathname.includes('/home/best-practice-library') &&
        this.currentUser?.user?.role === 'TrialUser'
      ) {
        this.placement = 'Best Practice Library';
        const data = this.limitsInfoObj?.['bpe-slide'] || {};
        // if (!data.feature) {
        //   data = this.limitsInfoObj?.['bpe-slide'] || {};
        // }
        if (data.feature) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.bestpracticeDownloadExceeded')
            : this.$t('trialAndSubscribeBanner.bestpracticeDownloadLeft', {
                bestpractice: data.remainingLimit,
                downloadText:
                  data.remainingLimit === 1 ? 'download' : 'downloads',
              });
        }
      } else if (pathname.includes('/home/generate')) {
        this.placement = 'Auto Generator';
        const data = this.limitsInfoObj?.generate || {};
        console.log(data);
        this.showBanner = data.access_level === 'restricted_access';
        if (data.feature && !data.isFullAccess) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.generatedSlideExceeded')
            : this.$t('trialAndSubscribeBanner.generatedSlideLeft', {
                slides: data.remainingLimit,
                generateText:
                  data.remainingLimit === 1 ? 'generation' : 'generations',
              });
        }
      } else if (pathname.includes('/home/upload')) {
        this.bannerMessage = '';
      } else if (
        pathname.includes('/home/template-converter') ||
        this.selectedMainAction.id === 'convertTemplate'
      ) {
        this.getTCbanner();
      } else if (
        pathname.includes('/home/learn') &&
        this.currentUser?.user?.role === 'TrialUser'
      ) {
        this.placement = 'Courses';
        const data = this.limitsInfoObj?.course || {};

        if (data.feature) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.courseExceeded')
            : this.$t('trialAndSubscribeBanner.coursesLeft', {
                courses: data.remainingLimit,
                downloadText:
                  data.remainingLimit === 1 ? 'download' : 'downloads',
              });
        }
      } else if (
        pathname.includes('/home/overnight-prezentations') ||
        pathname.includes('/home/my-overnight-prezentation')
      ) {
        this.placement = 'Overnight Prezentations';
        const opData = this.limitsInfoObj?.op || {};
        const slidesData = this.limitsInfoObj?.slide || {};
        if (
          opData.access_level === 'full_access' &&
          this.currentUser.user.role.toLowerCase() === 'trialuser'
        ) {
          if (slidesData.feature) {
            this.bannerMessage = slidesData.isLimitCrossed
              ? this.$t('trialAndSubscribeBanner.onlyOpSlideExceed')
              : this.$t('trialAndSubscribeBanner.opSlideLeft', {
                  slides: slidesData.remainingLimit,
                  downloadText:
                    slidesData.remainingLimit === 1 ? 'download' : 'downloads',
                });
          }
        } else if (
          opData.access_level === 'restricted_access' &&
          this.currentUser.user.role.toLowerCase() !== 'trialuser'
        ) {
          if (opData.feature) {
            this.bannerMessage = opData.isLimitCrossed
              ? this.$t('trialAndSubscribeBanner.onlyOpRequestExceed')
              : this.$t('trialAndSubscribeBanner.opRequestLeft', {
                  opRequests: opData.remainingLimit,
                  requestText:
                    opData.remainingLimit === 1 ? 'request' : 'requests',
                });
          }
        } else if (
          (slidesData.feature || opData.feature) &&
          this.currentUser.user.role.toLowerCase() === 'trialuser'
        ) {
          if (slidesData.isLimitCrossed && !opData.isLimitCrossed) {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.opRequestsLeftSlidesExceed',
              {
                opRequests: opData.remainingLimit,
                requestText:
                  opData.remainingLimit === 1 ? 'request' : 'requests',
              },
            );
          } else if (!slidesData.isLimitCrossed && opData.isLimitCrossed) {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.opRequestsExceedSlidesLeft',
              {
                slides: slidesData.remainingLimit,
                downloadText:
                  slidesData.remainingLimit === 1 ? 'download' : 'downloads',
              },
            );
          } else if (!slidesData.isLimitCrossed && !opData.isLimitCrossed) {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.opRequestsLeft',
              {
                slides: slidesData.remainingLimit,
                opRequests: opData.remainingLimit,
                requestText:
                  opData.remainingLimit === 1 ? 'request' : 'requests',
                downloadText:
                  slidesData.remainingLimit === 1 ? 'download' : 'downloads',
              },
            );
          } else {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.opRequestsExceed',
            );
          }
        }
      } else if (
        pathname.includes('/home/search') &&
        this.currentUser?.user?.role === 'TrialUser'
      ) {
        this.placement = 'hybrid search results screen';
        const slidesData = this.limitsInfoObj?.slide || {};
        const bpeData = this.limitsInfoObj?.['bpe-slide'] || {};

        if (slidesData.feature || bpeData.feature) {
          if (slidesData.isLimitCrossed && !bpeData.isLimitCrossed) {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.searchBPELeft',
              {
                bpe: bpeData.remainingLimit,
                downloadText:
                  bpeData.remainingLimit === 1 ? 'download' : 'downloads',
              },
            );
          } else if (!slidesData.isLimitCrossed && bpeData.isLimitCrossed) {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.searchSlidesLeft',
              {
                slides: slidesData.remainingLimit,
                downloadText:
                  slidesData.remainingLimit === 1 ? 'download' : 'downloads',
              },
            );
          } else if (!slidesData.isLimitCrossed && !bpeData.isLimitCrossed) {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.searchBothLeft',
              {
                slides: slidesData.remainingLimit,
                bpe: bpeData.remainingLimit,
                bpeDownloadText:
                  bpeData.remainingLimit === 1 ? 'download' : 'downloads',
                slideDownloadText:
                  slidesData.remainingLimit === 1 ? 'download' : 'downloads',
              },
            );
          } else {
            this.bannerMessage = this.$t(
              'trialAndSubscribeBanner.searchExceed',
            );
          }
        }
      } else if (pathname.includes('/home/synthesis')) {
        this.placement = 'Synthesis';
        const data = this.limitsInfoObj?.synthesis || {};
        if (data.feature && !data.isFullAccess) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.synthesisExceeded')
            : this.$t('trialAndSubscribeBanner.synthesisLeft', {
                slides: data.remainingLimit,
              });
        }
      } else if (pathname.includes('/home/redesign')) {
        this.placement = 'Redesign';
        const data = this.limitsInfoObj?.redesign || {};
        if (data.feature && !data.isFullAccess) {
          this.bannerMessage = data.isLimitCrossed
            ? this.$t('trialAndSubscribeBanner.redesignExceeded')
            : this.$t('trialAndSubscribeBanner.redesignLeft', {
                slides: data.remainingLimit,
                redesignText:
                  data.remainingLimit === 1 ? 'redesign' : 'redesigns',
              });
        }
      }
    },
    getTCbanner() {
      this.placement = 'Compliance Checker';
      const data = this.limitsInfoObj?.comply || {};
      this.showBanner = data.access_level === 'restricted_access';

      if (data.feature && !data.isFullAccess) {
        this.bannerMessage = data.isLimitCrossed
          ? this.$t('trialAndSubscribeBanner.complianceCheckExceeded')
          : this.$t('trialAndSubscribeBanner.complianceCheckLeft', {
              compliance: data.remainingLimit,
              checkText:
                data.remainingLimit === 1 ? 'conversion' : 'conversions',
            });
      }
    },
    // handleCloseModal() {
    //   this.setUpgradePopup({ upgradePopup: false, popupType: '' });
    // },
  },
};
</script>

<style lang="scss" scoped>
.trial-subsctiption-banner {
  position: relative;
  height: 48px;
  padding: 14px 8px 14px 16px;
  border-radius: 4px;
  gap: 4px;
  background: #e1f5fb;
  text-align: center;
  // box-shadow: 1px 1px 4px 0px #0000001a;
  z-index: 10;

  .text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
  }

  button {
    text-transform: capitalize;
    font-weight: bold;
    margin-top: -5px;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    padding: 0 8px !important;
  }
}

.popup-banner {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 0px 6px 0px #00000029;
  padding: 24px;
  border-radius: 12px;
  gap: 16px;
  width: 728px;
  height: 512px;

  .banner-header {
    display: flex;
    justify-content: space-between;
    .popup-banner-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .banner-image {
    margin: 10px 0;
  }

  .banner-action {
    margin-top: 20px;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    button {
      letter-spacing: normal;
    }
  }

  .banner-message {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  button {
    text-transform: capitalize;
    margin-top: -5px;
  }
}
</style>
