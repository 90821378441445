import { trackCommonEvent } from './MatomoTrackingDataHelper';

const OP_EVENT_CATEGORY = 'OP';
const OP_ICONCLICK = 'OP_iconclick';
const OP_OVERVIEW_CLICK = 'OP_overview_click';
const OP_NEWREQUEST_CLICK = 'OP_Newrequest_click';
const OP_NEWREQUEST_VIEW_PREFERENCES_CLICK =
  'OP_Newrequest_view-preferences_click';
const OP_MYREQUESTS_CLICK = 'OP_Myrequests_click';
const OP_OVERVIEW_SUBMIT_NEW_REQUEST_CLICK =
  'OP_overview_submit-new-request_click';
const OP_OVERVIEW_SUBMIT_NEW_REQUEST_NEXT =
  'OP_overview_submit-new-request_Next';
const OP_HOW_TO_SHARE_ACCESS_CLICK = 'OP_How-to-share-access_click';
const OP_OVERVIEW_SUBMIT_NEW_REQUEST_NEXT_NEXT =
  'OP_overview_submit-new-request_Next_Next';
const OP_OVERVIEW_SUBMIT_NEW_REQUEST_NEXT_NEXT_SUBMIT =
  'OP_overview_submit-new-request_Next_Next_Submit';
const OP_SUBMIT_ANOTHER_REQUEST = 'OP_submit-another-request';
const OP_FAQ_CLICK = 'OP_FAQ_click';
const OP_NEWREQUEST_NEXT = 'OP_Newrequest_Next';
const OP_NEWREQUEST_NEXT_NEXT = 'OP_Newrequest_Next_Next';
const OP_NEWREQUEST_NEXT_NEXT_SUBMIT = 'OP_Newrequest_Next_Next_Submit';
const OP_MYREQUESTS_THUMBNAIL_CLICK = 'OP_Myrequests_thumbnail_click';
const OP_MYREQUESTS_DOWNLOAD = 'OP_Myrequests_download';
const OP_MYREQUESTS_RENAME = 'OP_Myrequests_Rename';

const OP_MYREQUESTS_MANAGE_FILES_AND_LINKS =
  'OP_Myrequests_Manage-request_Add-a-message';
const OP_MYREQUESTS_MANAGE_FILES_AND_LINKS_DOWNLOAD =
  'OP_Myrequests_Manage-files-and-links_download';
const OP_MYREQUESTS_MANAGE_FILES_AND_LINKS_DELETE =
  'OP_Myrequests_Manage-files-and-links_delete';
const OP_MYREQUESTS_MANAGE_FILES_AND_LINKS_SAVE =
  'OP_Myrequests_Manage-request_Add-a-message_submit';
const OP_MYREQUESTS_VIEW_PREZENTATION = 'OP_Myrequests_View-Prezentation';
const OP_MYREQUESTS_VIEW_PREZENTATION_DOWNLOAD =
  'OP_Myrequests_View-Prezentation_download';
const OP_MYREQUESTS_VIEW_PREZENTATION_ADD_TO_PREZENTATIONS_LIBRARY_ADD =
  'OP_Myrequests_View-Prezentation_Add-to-prezentations-library_Add';
const OP_MYREQUESTS_RATE_PRESENTATION = 'OP_Myrequests_rate-presentation';
const OP_MYREQUESTS_CHANGE_REQUEST = 'OP_Myrequests_change-request';
const OP_MYREQUESTS_CHANGE_REQUEST_SUBMIT =
  'OP_Myrequests_change-request_submit';
const OP_MYREQUESTS_SORTBY = 'OP_Myrequests_sortby';
const OP_MYREQUESTS_ADVANCE_FILTERS_CLICK =
  'OP_Myrequests_advance-filters_click';
const OP_MYREQUESTS_ADVANCE_FILTERS_SELECTED =
  'OP_Myrequests_advance-filters-selected';
const OP_MYREQUESTS_ADVANCE_FILTERS_RESET =
  'OP_Myrequests_advance-filters_reset';
const OP_MYREQUESTS_SEARCH = 'OP_Myrequests_search';
const OP_MYREQUESTS_SELECT_TEMPLATE_SELECTED =
  'OP_Myrequests_select-template-selected';
const OP_MYREQUESTS_LIST_VIEW_CLICK = 'OP_Myrequests_List-view_click';
const OP_MYREQUESTS_MOSAIC_VIEW_CLICK = 'OP_Myrequests_Mosaic-view_click';
const OP_MYREQUESTS_MANAGE_REQUEST_CLICK = 'OP_Myrequests_Manage-request_click';
const OP_MYREQUESTS_MANAGE_REQUEST_DOWNLOAD_VIA_LINK =
  'OP_Myrequests_Manage-request_download-via-link';
const OP_CHANGE_REQUEST_SUBMIT_NEW_CHANGE_REQUEST =
  'OP_change-request_Submit-new-change-request';
const OP_CHANGE_REQUEST_GO_TO_MY_REQUESTS =
  'OP_change-request_Go-to-my-requests';
const OP_OTHER_ACTIONS_ADD_TO_PREZENTATION_LIBRARY =
  'OP_other-actions_add-to-prezentation-library';
const OP_OTHER_ACTIONS_SUBMIT_NEW_REQUEST =
  'OP_other-actions_Submit-new-request';
const OP_ADD_MESSAGE_CLICK = 'OP_add-message_click';
const OP_ADD_MESSAGE_SEND = 'OP_add-message_send';

export const trackOvernightPrezentationsEvents = {
  OPIconClick(currentUser) {
    try {
      trackCommonEvent({
        currentUser,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOverviewClick(currentUser) {
    try {
      trackCommonEvent({
        currentUser,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OVERVIEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPNewRequestClick(currentUser) {
    try {
      trackCommonEvent({
        currentUser,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_NEWREQUEST_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPNewRequestViewPreferencesClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_NEWREQUEST_VIEW_PREFERENCES_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyrequestsClick(currentUser) {
    try {
      trackCommonEvent({
        currentUser,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOverviewSubmitNewRequestClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OVERVIEW_SUBMIT_NEW_REQUEST_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOverviewSubmitNewRequestNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OVERVIEW_SUBMIT_NEW_REQUEST_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPHowToShareAccessClick(currentUser) {
    try {
      trackCommonEvent({
        currentUser,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_HOW_TO_SHARE_ACCESS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOverviewSubmitNewRequestNextNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OVERVIEW_SUBMIT_NEW_REQUEST_NEXT_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOverviewSubmitNewRequestNextNextSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OVERVIEW_SUBMIT_NEW_REQUEST_NEXT_NEXT_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPSubmitAnotherRequest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_SUBMIT_ANOTHER_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPFAQClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_FAQ_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPNewRequestNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_NEWREQUEST_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPNewRequestNextNext(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_NEWREQUEST_NEXT_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPNewRequestNextNextSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_NEWREQUEST_NEXT_NEXT_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyrequestsThumbnailClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_THUMBNAIL_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsRename(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_RENAME,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsManageFilesAndLink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_FILES_AND_LINKS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsManageFilesAndLinkDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_FILES_AND_LINKS_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsManageFilesAndLinkDelete(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_FILES_AND_LINKS_DELETE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsManageFilesAndLinkSave(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_FILES_AND_LINKS_SAVE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsConvertionHistory(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_REQUEST_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsViewPrezentation(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_VIEW_PREZENTATION,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsViewPrezentationDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_VIEW_PREZENTATION_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsViewPrezentationAddToPrezLibrary(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction:
          OP_MYREQUESTS_VIEW_PREZENTATION_ADD_TO_PREZENTATIONS_LIBRARY_ADD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsRatePrezentation(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_RATE_PRESENTATION,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsChangeRequest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_CHANGE_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsChangeRequestSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_CHANGE_REQUEST_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsSortBy(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_SORTBY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsAdvanceFiltersClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_ADVANCE_FILTERS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsAdvanceFiltersSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_ADVANCE_FILTERS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsAdvanceFiltersReset(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_ADVANCE_FILTERS_RESET,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsSelectTemplateSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_SELECT_TEMPLATE_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsListViewClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_LIST_VIEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsMosaicViewClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MOSAIC_VIEW_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsManageRequestDownloadViaLink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_REQUEST_DOWNLOAD_VIA_LINK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPMyRequestsManageRequestClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_MYREQUESTS_MANAGE_REQUEST_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPChangeRequestSubmitNewChangeRequest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_CHANGE_REQUEST_SUBMIT_NEW_CHANGE_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPChangeRequestGoToMyRequests(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_CHANGE_REQUEST_GO_TO_MY_REQUESTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOtherActionsAddToLibrary(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OTHER_ACTIONS_ADD_TO_PREZENTATION_LIBRARY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPOtherActionsSubmitNewRequest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_OTHER_ACTIONS_SUBMIT_NEW_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPAddMessageClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_ADD_MESSAGE_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  OPAddMessageSend(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: OP_EVENT_CATEGORY,
        eventAction: OP_ADD_MESSAGE_SEND,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
