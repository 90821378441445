<template>
  <v-tooltip bottom open-delay="500" close-delay="300">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="list-item-wrap">
        <v-list-item
          :class="`audience-item ${
            status === 'Disabled' || isInComplete
              ? 'disabled-item'
              : 'clickable'
          } ${origin === 'profile' ? 'hyperlink-text' : ''}  ${
            fpVisibleToTeam ? '' : 'audi-fp-not-visible--auto'
          } ${itemClass}`"
          :disabled="status === 'Disabled' || isInComplete"
          :data-pendo-id="isFirstResult ? 'audience-item' : null"
          :style="maxWidthStyle"
        >
          <v-list-item-avatar :size="size" class="profile-avatar">
            <v-img
              v-if="avatar && !isPublicLimited"
              :alt="`${computedFullName} avatar`"
              :src="avatar"
            />
            <div v-else-if="userInitials">
              <v-avatar color="rgb(88, 168, 222)" :size="size">
                <span class="profile-avatar--initial">
                  {{ userInitials }}
                </span>
              </v-avatar>
            </div>
            <v-img
              v-else
              :alt="`${computedFullName} avatar`"
              :src="'/assets/img/non-member-dropdown-icon.svg'"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-name">
              <span class="mr-1 audience-title">
                {{ computedFullName }} <span v-if="getJobTitle">&#183;</span>
                {{ getJobTitle }}
              </span>
              <img
                v-if="
                  fingerprintType.toLowerCase() === 'standard' ||
                  fingerprintType.toLowerCase() === 'zensense'
                "
                src="/assets/img/prezent_avatar.svg"
                alt
                width="22px"
              />
              <img
                v-if="isRecent"
                src="/assets/img/history.svg"
                alt
                width="20px"
                class="mr-1"
              />
              <img
                v-if="isTrending"
                src="/assets/img/trending-audience.svg"
                alt
                width="20px"
              />
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-email"
              v-if="fingerprintType === 'standard'"
            >
              <span v-if="lastName && lastName.toLowerCase() === 'group'">
                {{ $t('prezentationShareStatus.group') }}
                <span class="dot">&bull;</span>
              </span>
              {{ $t('fingerprintvue.createdByPrezent') }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-email" v-else>
              <span
                v-if="!emailEntered && (isDifferentCompany || isPublicLimited)"
              >
                <span>{{ companyName }}</span>
              </span>
              <span v-else-if="email">{{ email }}</span>
              <span
                v-if="
                  (email || isDifferentCompany) &&
                  (creator || (isPrivate && isOwner))
                "
                class="dot"
              >
                &bull;
              </span>
              <span v-if="isGroup && showDot" class="align-center">
                <span>{{ $t('prezentationShareStatus.group') }}</span>
                <span class="dot">&bull;</span>
              </span>
              <span v-if="creator"
                >{{ $t('prezentationShareStatus.createdBy', { who: creator }) }}
              </span>
              <span v-if="isPrivate && isOwner" class="align-center">
                <span
                  >{{ $t('build.step1.createdBy') }}&nbsp;{{
                    $t('build.step1.me')
                  }}</span
                >
                <span class="dot">&bull;</span>
                <span v-if="isShared">{{ $t('build.step1.shared') }}</span>
                <span v-else>{{ $t('build.step1.private') }}</span>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <slot />
        </v-list-item>
      </div>
    </template>
    <div>
      <v-list-item class="audience-item">
        <v-list-item-content v-if="status !== 'Disabled'">
          <v-list-item-title class="text-name">
            <span class="mr-1 audience-title">
              {{ computedFullName }} <span v-if="getJobTitle">&#183;</span>
              {{ getJobTitle }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-email"
            v-if="fingerprintType === 'standard'"
          >
            <span>{{
              $t('prezentationShareStatus.fingerprintStatusText')
            }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-email" v-else>
            <span
              v-if="!emailEntered && (isDifferentCompany || isPublicLimited)"
            >
              <span>{{ companyName }}</span>
            </span>
            <span v-else-if="email">{{ email }}</span>
            <span
              v-if="
                (email || isDifferentCompany) &&
                (creator || (isPrivate && isOwner))
              "
              class="dot"
              >&bull;</span
            >
            <span v-if="isGroup && showDot" class="align-center">
              <span>{{ $t('prezentationShareStatus.group') }}</span>
              <span class="dot">&bull;</span>
            </span>
            <span v-if="creator"
              >{{ $t('prezentationShareStatus.createdBy', { who: creator }) }}
            </span>
            <span v-if="isPrivate && isOwner" class="align-center">
              <span
                >{{ $t('build.step1.createdBy') }}&nbsp;{{
                  $t('build.step1.me')
                }}</span
              >
              <span class="dot">&bull;</span>
              <span v-if="isShared">{{ $t('build.step1.shared') }}</span>
              <span v-else>{{ $t('build.step1.private') }}</span>
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <div class="disabled-tooltip" v-else>
          <p class="text-name">User account disabled</p>
        </div>
      </v-list-item>
    </div>
  </v-tooltip>
</template>
<script>
// import { Storage } from 'aws-amplify';
import { mapState } from 'vuex';
import { getFullName, getInitials } from '@/utils/common';
import { getFileURL } from '@/utils/calyrex';

export default {
  props: {
    fullName: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    emailEntered: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
    jobTitle: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    profileImg: {
      type: String,
      default: '',
    },
    profileImgS3Path: String,
    creator: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    companyName: {
      type: String,
      default: '',
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
    isDifferentCompany: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isInComplete: {
      type: Boolean,
      default: false,
    },
    fpVisibleToTeam: {
      type: Boolean,
      default: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
    showDot: {
      type: Boolean,
      default: true,
    },
    fingerprintType: {
      type: String,
      default: 'default',
    },
    isRecent: {
      type: Boolean,
      default: false,
    },
    isTrending: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: '',
    },
    isPublicLimited: {
      type: Boolean,
    },
    size: {
      type: String,
      default: '26',
    },
    ownerId: {
      type: String,
      default: '',
    },
    isFirstResult: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      processedProfileImage: null,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    maxWidthStyle() {
      if (this.maxWidth) return { 'max-width': `${this.maxWidth}px` };
      return {};
    },
    userInitials() {
      const groupName = this.firstName.replace(/\s+/g, ' ').split(' ');
      if (this.isGroup && groupName.length === 1) {
        return this.firstName[0].toUpperCase();
      }
      if (this.isGroup && groupName.length >= 2) {
        return `${groupName[0][0].toUpperCase()}${groupName[1][0].toUpperCase()}`;
      }
      return getInitials(this.firstName, this.lastName);
    },
    computedFullName() {
      if (this.isGroup) {
        return getFullName(this.firstName);
      }
      if (this.fullName) return this.fullName;
      return getFullName(this.firstName, this.lastName);
    },
    getJobTitle() {
      return this.jobTitle;
    },
    isOwner() {
      return this.currentUser.user.id === this.ownerId;
    },
    avatar() {
      return this.processedProfileImage || this.profileImg;
    },
  },
  watch: {
    profileImgS3Path: {
      immediate: true,
      handler(filename) {
        if (!filename) {
          return;
        }

        if (filename.startsWith('/assets/img/')) {
          this.processedProfileImage = filename;
          return;
        }

        getFileURL(null, `public/${filename}`, null, 'storage').then((url) => {
          this.processedProfileImage = url;
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  &.hyperlink-text {
    color: $zen-blue-default !important;
  }
}
.list-item-wrap {
  width: 100%;
}
.audience-item {
  min-height: 40px;

  .profile-avatar {
    margin-right: 8px !important;

    &--initial {
      color: white;
      font-size: 14px;
      font-weight: 250;
    }
  }

  .text-name {
    font-size: 14px;
    display: flex;
    align-items: center;
    .dot {
      margin: 0 4px;
      font-size: 8px;
    }
  }

  .text-email {
    font-size: 12px;
    text-align: start;

    .dot {
      margin: 0 4px;
      font-size: 8px;
    }
  }
}

.disabled-item {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.no-padding.v-list-item {
  padding: 0;
}

.no-padding.v-list-item {
  padding: 0;
}

::v-deep .v-list-item__avatar {
  margin: 0;
}

::v-deep .v-list-item__content {
  padding: 4px 0;
}
.v-tooltip__content {
  opacity: 1 !important;
  padding: 5px 0px;
  .v-list-item {
    padding: 0 10px;
  }
  .v-list-item__content {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
  }
}
.audi-fp-not-complete--auto {
  opacity: 0.5;
}
.audi-fp-not-visible--auto {
  cursor: auto !important;
}

.audience-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled-tooltip {
  p {
    margin: 0;
  }
}
</style>
