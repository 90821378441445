<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 14.25V12.75H15V14.25H3ZM3 11.25V9.75H15V11.25H3ZM3 8.25V6.4875L7.5 3.75L11.25 6.4125L15 3.75V5.5875L11.25 8.25L7.44375 5.55L3 8.25Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'DataStyleIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
