<template>
  <div :class="`download-actions ${mode}`">
    <!-- Handling direct download when save to gdrive is disabled for user !-->
    <div v-if="!isGoogleDownloadEnabled" class="save-to-gdrive-disabled">
      <v-tooltip top v-if="mode === 'button'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="py-2 download ma-2"
            outlined
            rounded
            min-width="110"
            color="#20a7e0"
            :loading="downloading"
            id="download-btn"
            @click="handleDirectDownload"
            data-pendo-id="download-cta"
            :disabled="isDisabled"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t(btnText) }}
          </v-btn>
        </template>
        <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
      </v-tooltip>

      <div
        :class="`download-icon${isDisabled ? '__disabled' : ''}`"
        @click="handleDirectDownload"
        :disabled="isDisabled"
        v-else
      >
        <v-tooltip
          :top="customSpeedDialPositionEnabled"
          :bottom="!customSpeedDialPositionEnabled"
          :max-width="customSpeedDialPositionEnabled ? '300' : '200'"
          content-class="tooltip-content"
          fixed
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="isDisabled"
              icon
              v-bind="attrs"
              v-on="on"
              v-if="showIconButton"
            >
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-download-outline
              </v-icon>
            </v-btn>
            <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              :disabled="isDisabled"
              v-else
            >
              mdi-download-outline
            </v-icon>
          </template>
          <span>{{
            $t(iconModeTooltipText ? iconModeTooltipText : 'common.download')
          }}</span>
        </v-tooltip>
      </div>
    </div>

    <!-- Handling download when save to gdrive is enabled for user!-->
    <div class="downloadPreferences" v-else>
      <!-- Handling download when user download preference is selected and is not 'always_ask' !-->
      <div
        class="downloadEleWithPrefs"
        v-if="
          usrDownloadPref &&
          usrDownloadPref !== '' &&
          usrDownloadPref.toLowerCase() !== 'always_ask'
        "
      >
        <v-btn
          v-if="mode === 'button'"
          class="py-2 download ma-2"
          outlined
          rounded
          min-width="110"
          color="#20a7e0"
          :loading="downloading"
          id="download-btn"
          @click="handleDownloadWithSpeceficPreference"
          data-pendo-id="download-cta"
          :disabled="isDisabled"
          :ripple="false"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                :class="
                  allowDownloadSettingsIcon
                    ? 'btnTextWithSettingsIcon'
                    : 'btnText'
                "
                v-bind="attrs"
                v-on="on"
              >
                {{ $t(btnText) }}
              </div>
            </template>
            <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
          </v-tooltip>
          <div
            class="downloadSettingsIcon"
            v-if="allowDownloadSettingsIcon"
            data-pendo-id="download-settings-icon"
            @click.stop="openUserDownloadPreferenceModal"
          >
            <v-icon size="18">{{ 'mdi-cog-outline' }}</v-icon>
          </div>
        </v-btn>

        <div
          v-else
          :class="`download-icon${isDisabled ? '__disabled' : ''}`"
          @click="handleDownloadWithSpeceficPreference"
          :disabled="isDisabled"
        >
          <v-tooltip
            :top="customSpeedDialPositionEnabled"
            :bottom="!customSpeedDialPositionEnabled"
            :max-width="customSpeedDialPositionEnabled ? '300' : '200'"
            content-class="tooltip-content"
            fixed
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="isDisabled"
                icon
                v-bind="attrs"
                v-on="on"
                v-if="showIconButton"
              >
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-download-outline
                </v-icon>
              </v-btn>
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="isDisabled"
                v-else
                @click="(e) => getDownloadIconCoordinates(e)"
              >
                mdi-download-outline
              </v-icon>
            </template>
            <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
          </v-tooltip>
        </div>
      </div>

      <!-- Handling download when user download preference is selected and is set to 'always_ask' !-->
      <div class="alwaysAskPref" v-else>
        <v-speed-dial
          :direction="speedDialDirection ? speedDialDirection : 'bottom'"
          class="new-slide-detail-speed"
        >
          <template v-if="mode === 'button'" v-slot:activator>
            <v-btn
              class="py-2 download ma-2"
              outlined
              rounded
              min-width="110"
              color="#20a7e0"
              :loading="downloading"
              id="download-btn"
              @click="onDownloadButtonClick"
              data-pendo-id="download-cta"
              :disabled="isDisabled"
              :ripple="false"
            >
              <v-tooltip
                attach
                absolute
                max-width="200"
                :content-class="'center-tooltip'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :class="
                      allowDownloadSettingsIcon
                        ? 'btnTextWithSettingsIcon'
                        : 'btnText'
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t(btnText) }}
                  </div>
                </template>
                <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
              </v-tooltip>
              <div
                class="downloadSettingsIcon"
                v-if="allowDownloadSettingsIcon"
                data-pendo-id="download-settings-icon"
                @click.stop="openUserDownloadPreferenceModal"
              >
                <v-icon size="18">{{ 'mdi-cog-outline' }}</v-icon>
              </div>
            </v-btn>
          </template>

          <template v-else v-slot:activator>
            <div
              :class="`download-icon${isDisabled ? '__disabled' : ''}`"
              @click="onDownloadButtonClick"
              :disabled="isDisabled"
            >
              <v-tooltip
                :top="customSpeedDialPositionEnabled"
                :bottom="!customSpeedDialPositionEnabled"
                :max-width="customSpeedDialPositionEnabled ? '300' : '200'"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="isDisabled"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="showIconButton"
                  >
                    <v-icon color="primary" v-bind="attrs" v-on="on">
                      mdi-download-outline
                    </v-icon>
                  </v-btn>
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="isDisabled"
                    v-else
                    @click="(e) => getDownloadIconCoordinates(e)"
                  >
                    mdi-download-outline
                  </v-icon>
                </template>
                <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
              </v-tooltip>
            </div>
          </template>

          <div
            class="download-list"
            data-pendo-id="download-cta-dropdown"
            v-if="!isDisabled"
            :style="
              customSpeedDialPositionEnabled
                ? { top: `${speedDialTop}px`, right: `${speedDialRight}px` }
                : {
                    top: `${
                      mode === 'icon'
                        ? `${
                            !isGoogleDownloadEnabled
                              ? `${speedDialInitTopIcon + 55}`
                              : `${speedDialInitTopIcon}`
                          }`
                        : `${
                            !isGoogleDownloadEnabled
                              ? `${speedDialInitTopBtn + 55}`
                              : `${speedDialInitTopBtn}`
                          }`
                    }px`,
                    right: `${speedDialInitRight}px`,
                  }
            "
          >
            <div
              class="download-list-item download-from-new-present-list--auto"
              @click="checkDownloadLimitAndProceed()"
              data-pendo-id="download-btn-dropdown-link"
            >
              <v-btn
                text
                depressed
                elevation="0"
                ref="saveBtn"
                class="download-direct"
                :ripple="false"
                id="download-btn-from-list"
              >
                <span class="download-btn__content">
                  <v-icon>{{ 'mdi-download-outline' }}</v-icon>
                  {{
                    origin === 'prezentation' ||
                    origin === 'slide' ||
                    origin === 'bpe-slide'
                      ? isRestricted
                        ? $t('common.downloadPDF')
                        : $t('common.downloadPPTX')
                      : $t('common.download')
                  }}
                </span>
              </v-btn>
            </div>
            <div class="download-list-item" v-if="isGoogleDownloadEnabled">
              <!-- save to drive comp template !-->
              <div
                @click="
                  disableSaveToGDrive ? () => {} : startSaveToGdriveProcess()
                "
              >
                <v-tooltip
                  bottom
                  max-width="200"
                  content-class="tooltip-content"
                  fixed
                  v-if="disableSaveToGDrive"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn
                        text
                        depressed
                        elevation="0"
                        class="download-btn open-in-google-slides"
                        :ripple="false"
                        :disabled="disableSaveToGDrive"
                      >
                        <span class="download-btn__content">
                          <v-icon>mdi-google</v-icon>
                          {{
                            origin === 'prezentation' || origin === 'slide'
                              ? isRestricted
                                ? $t('common.googleDownloadPDF')
                                : $t('common.googleDownloadPPTX')
                              : $t('common.googleDownload')
                          }}
                        </span>
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t(saveToGDriveDisabledTooltip) }}</span>
                </v-tooltip>
                <v-btn
                  v-else
                  text
                  depressed
                  elevation="0"
                  class="download-btn open-in-google-slides"
                  :ripple="false"
                >
                  <span class="download-btn__content">
                    <v-icon>mdi-google</v-icon>
                    {{
                      origin === 'prezentation' || origin === 'slide'
                        ? isRestricted
                          ? $t('common.googleDownloadPDF')
                          : $t('common.googleDownloadPPTX')
                        : $t('common.googleDownload')
                    }}
                  </span>
                </v-btn>
              </div>
            </div>
          </div>
        </v-speed-dial>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from './event-bus';
import { getFeatureUsage, postFeatureUsage } from '../../utils/api-helper';
import UserDownloadPreferenceSelector from './UserDownloadPreferenceSelector.vue';
import { AnalyticsHandler } from './Analytics/AnalyticsHandler';
import GdriveTransition from './GdriveTransitionV2.vue';
import { trackDownloadSettingsEvents } from './Analytics/DownloadSettingsEvents.js';
import {
  TD_PLACEMENT,
  TD_ISDOWNLOAD,
  TD_VIEWTYPE,
  TD_EXPANDED_VIEW,
} from './Analytics/MatomoTrackingDataHelper';

export default {
  name: 'DownloadComponent',
  props: {
    mode: {
      type: String,
      default: 'button',
    },
    downloading: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: 'common.download',
    },
    fileName: {
      type: String,
      default: '',
    },
    downloadFileName: {
      type: String,
      default: '',
    },
    mergedUrl: {
      type: Function,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    logDownload: {
      type: Function,
      required: false,
      default: () => {},
    },
    downloadType: {
      type: String,
      default: null,
      required: false,
    },
    downloadIconType: {
      type: String,
      default: null,
      required: false,
    },
    isReDownload: {
      type: Boolean,
      default: false,
    },
    onDownload: {
      type: Function,
      default: () => {},
    },
    countFirstTime: {
      type: Number,
    },
    downloadUrl: {
      type: String,
      required: false,
    },
    showIconButton: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: '',
    },
    limitsKey: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    speedDialDirection: {
      type: String,
      default: 'bottom',
    },
    iconModeTooltipText: {
      type: String,
      default: '',
    },
    customSpeedDialPositionEnabled: {
      type: Boolean,
      default: false,
    },
    saveToGDriveDisabledTooltip: {
      type: String,
      default: '',
    },
    disableSaveToGDrive: {
      type: Boolean,
      default: false,
    },
    speedDialPosTop: {
      type: String,
      default: '',
    },
    speedDialPosRight: {
      type: String,
      default: '',
    },
    closePreviousPopups: {
      type: Function,
      default: () => {},
    },
    allowDownloadSettingsIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      speedDialTop: 0,
      speedDialRight: 0,
      speedDialInitTopIcon: -150,
      speedDialInitTopBtn: -170,
      speedDialInitRight: 0,
    };
  },
  components: {},
  methods: {
    ...mapActions('commonDownloads', ['setGdriveFileDownloads']),
    ...mapActions('users', [
      'setCurrentUser',
      'setLimitData',
      'setUpgradePopup',
    ]),

    openUserDownloadPreferenceModal() {
      this.handleDownloadSettingsEvents();
      this.$modal.show(
        UserDownloadPreferenceSelector,
        {
          compType: 'modal',
          viewType: TD_EXPANDED_VIEW,
        },
        {
          name: 'UserDownloadPreferenceSelector',
          width: '500px',
          height: 'auto',
          styles: {
            borderRadius: '12px',
          },
          // clickToClose: false,
        },
      );
    },
    handleDownloadSettingsEvents() {
      let placement = '';
      switch (this.origin) {
        case 'slide':
          placement = 'Slide Library';
          break;
        case 'build':
          placement = 'Story Builder';
          break;
        case 'prezentation':
          placement = 'Prezentation Library';
          break;
        case 'my_prezentation':
          placement = 'Prezentation Library';
          break;
        case 'bpe-slide':
          placement = 'Best Practice Library (Slides)';
          break;
        case 'bpe-deck':
          placement = 'Best Practice Library (Decks)';
          break;
        default:
          break;
      }
      const otherData = {
        [TD_ISDOWNLOAD]: 0,
        [TD_VIEWTYPE]: TD_EXPANDED_VIEW,
        [TD_PLACEMENT]: placement,
      };
      trackDownloadSettingsEvents.downloadSettingsClick(
        this.currentUser,
        otherData,
      );
    },

    async handleDownloadWithSpeceficPreference() {
      if (!this.isDisabled) {
        EventBus.$emit('DOWNLOAD_BUTTON_CLICKED');
      }

      if (this.usrDownloadPref === 'local_download') {
        await this.checkDownloadLimitAndProceed();
      } else if (this.usrDownloadPref === 'save_to_gdrive') {
        // making analytics call before start of save to gdrive process
        if (this.mergedUrl) {
          AnalyticsHandler.performPrezentationDownloadGdrive(
            this.currentUser,
            this.fileName,
          );
        } else {
          AnalyticsHandler.performSlideDownloadGdrive(
            this.currentUser,
            this.fileName,
          );
        }

        await this.startSaveToGdriveProcess();
      }
    },

    handleLogDownloadPostDownload(fileName) {
      if (this.logDownload) {
        if (this.$route.name && this.$route.name === 'search') {
          // handling log download pattern of hybrid search page
          this.logDownload(
            this.item,
            '',
            this.downloadType && this.downloadType === 'SlideReplacement',
            this.downloadIconType &&
              this.downloadIconType === 'SlideIconReplacement',
          );
        } else {
          this.logDownload(
            this.item,
            this.downloadType && this.downloadType === 'SlideReplacement',
            true /** to indicate its from Google Slides Download */,
            this.downloadIconType &&
              this.downloadIconType === 'SlideIconReplacement',
          );
        }
        this.setGdriveFileDownloads(fileName);
      }
    },

    async startSaveToGdriveProcess() {
      // closing any popup invoked from its parent component
      if (this.closePreviousPopups) {
        await this.closePreviousPopups();
      }

      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      let key = 'slide';
      let noofslides;

      if (this.limitsKey === 'bpe-deck' || this.limitsKey === 'bpe-slide') {
        key = 'bpe-slide';
      }

      if (this.limitsKey === 'bpe-deck' || this.limitsKey === 'prezentation') {
        noofslides = this.item.noofslides;
      }

      if (
        !this.limitsInfoObj?.[key]?.isLimitCrossed ||
        this.currentUser.user.role !== 'TrialUser'
      ) {
        postFeatureUsage({ feature: this.limitsKey, noofslides })
          .then((response) => {
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));

        this.$modal.show(
          GdriveTransition,
          {
            isPrezentation: this.isPrezentation,
            audienceId: this.item.audienceID,
            audienceOwnerId: this.item.audienceOwnerID,
            handleLogDownloadOnSaveComplete: this.handleLogDownloadPostDownload,
            downloadUrl: this.downloadUrl,
            mergedUrl: this.mergedUrl,
            item: this.item,
            downloadFileName: this.downloadFileName,
            origin: this.origin,
            downloadType: this.downloadType,
            filename: this.fileName,
          },
          {
            name: 'GdriveTransition',
            width: '500px',
            height: 'auto',
            styles: { borderRadius: '16px' },
            clickToClose: false,
          },
        );
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: this.limitsKey });
      }
    },

    async handleDirectDownload() {
      if (!this.isDisabled) {
        EventBus.$emit('DOWNLOAD_BUTTON_CLICKED');
      }
      await this.checkDownloadLimitAndProceed();
    },

    async onDownloadButtonClick() {
      if (!this.isDisabled) {
        EventBus.$emit('DOWNLOAD_BUTTON_CLICKED');
      }
    },

    async checkDownloadLimitAndProceed() {
      this.onDownload();
    },

    getDownloadIconCoordinates(e) {
      this.speedDialTop = e.clientY;
      this.speedDialRight = 125;
    },
  },
  computed: {
    ...mapGetters('users', ['getUser']),
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
    isPrezentation() {
      return this.origin === 'prezentation';
    },
    isRestricted() {
      return (
        this.item.isRestricted ||
        this.item?.permission_level === 0 ||
        this.item?.permission === 'Restricted'
      );
    },
    isGoogleDownloadEnabled: (state) => {
      if (state.currentUser) {
        const feature = state.currentUser.features.find(
          (f) => f.feature_name === 'google_slides_download',
        );
        return feature && feature.enabled;
      }
      return false;
    },

    usrDownloadPref() {
      return this.currentUser?.user?.userDownloadPreference?.toLowerCase();
    },

    /* eslint-disable no-nested-ternary */
    mapUserDownloadPreferenceToTooltip() {
      const userDownloadPreference =
        this.currentUser?.user?.userDownloadPreference?.toLowerCase();
      if (userDownloadPreference === 'local_download') {
        return this.origin === 'prezentation' ||
          this.origin === 'slide' ||
          this.origin === 'bpe-slide'
          ? this.isRestricted
            ? this.$t('common.downloadPDF')
            : this.$t('common.downloadPPTX')
          : this.$t('common.download');
      }
      if (userDownloadPreference === 'save_to_gdrive') {
        return this.origin === 'prezentation' || this.origin === 'slide'
          ? this.isRestricted
            ? this.$t('common.googleDownloadPDF')
            : this.$t('common.googleDownloadPPTX')
          : this.$t('common.googleDownload');
      }
      return this.$t('common.download');
    },
  },
  mounted() {
    if (this.speedDialPosTop && this.speedDialPosTop !== '') {
      if (this.mode === 'icon') {
        this.speedDialInitTopIcon = Number(this.speedDialPosTop);
      } else {
        this.speedDialInitTopBtn = Number(this.speedDialPosTop);
      }
    }
    if (this.speedDialPosRight && this.speedDialPosRight !== '') {
      this.speedDialInitRight = Number(this.speedDialPosRight);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.download-actions {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  .save-to-gdrive-disabled {
    .download {
      border-radius: 25px;
      color: white !important;
      background-color: $zen-blue-default;
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      height: 40px;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
      min-width: 110px;
      padding: 0 !important;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      &.v-btn--disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        background: #ccc !important;
        border: 1px solid rgba(0, 0, 0, 0.26) !important;
      }

      &:before {
        background-color: $zen-blue-default;
      }
    }

    .download-icon__disabled {
      pointer-events: none;
      .v-icon--disabled {
        color: rgba(0, 0, 0, 0.38) !important;
        &:hover {
          background-color: #ffffff;
        }
      }
    }

    .download-icon {
      &:hover {
        background-color: #21a7e01c;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }

  .downloadPreferences {
    .downloadEleWithPrefs {
      .download {
        border-radius: 25px;
        color: white !important;
        background-color: $zen-blue-default;
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        height: 40px;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
        min-width: 110px;
        padding: 0 !important;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        &.v-btn--disabled {
          color: rgba(0, 0, 0, 0.26) !important;
          background: #ccc !important;
          border: 1px solid rgba(0, 0, 0, 0.26) !important;
        }

        .btnText {
          padding: 8px 16px;
        }
        .btnTextWithSettingsIcon {
          padding: 8px 8px 8px 16px;
        }

        .downloadSettingsIcon {
          border-left: 1px solid #ffffff;
          height: 40px;
          padding: 8px 8px 8px 4px;
        }

        &:before {
          background-color: $zen-blue-default;
        }
      }

      .download-icon__disabled {
        pointer-events: none;
        .v-icon--disabled {
          color: rgba(0, 0, 0, 0.38) !important;
          &:hover {
            background-color: #ffffff;
          }
        }
      }

      .download-icon {
        &:hover {
          background-color: #21a7e01c;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }

    .alwaysAskPref {
      .new-slide-detail-speed {
        .download {
          border-radius: 25px;
          color: white !important;
          background-color: $zen-blue-default;
          font-size: 18px;
          font-style: normal;
          font-stretch: normal;
          height: 40px;
          line-height: normal;
          letter-spacing: normal;
          text-transform: none;
          min-width: 110px;
          padding: 0 !important;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          &.v-btn--disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            background: #ccc !important;
            border: 1px solid rgba(0, 0, 0, 0.26) !important;
          }

          .btnText {
            padding: 8px 16px;
          }
          .btnTextWithSettingsIcon {
            padding: 8px 8px 8px 16px;
          }

          .downloadSettingsIcon {
            border-left: 1px solid #ffffff;
            height: 40px;
            padding: 8px 8px 8px 4px;
          }

          &:before {
            background-color: $zen-blue-default;
          }
        }

        .download-icon__disabled {
          pointer-events: none;
          .v-icon--disabled {
            color: rgba(0, 0, 0, 0.38) !important;
            &:hover {
              background-color: #ffffff;
            }
          }
        }

        .download-icon {
          &:hover {
            background-color: #21a7e01c;
            border-radius: 20px;
            cursor: pointer;
          }
        }

        .download-list {
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
          position: absolute;
          padding: 4px;
          background-color: #ffffff;
          .download-list-item {
            background: white;
            color: black;
            cursor: pointer;
            font-size: 20px;
            text-align: left;
            &:hover {
              background: $gray-background-hover;
            }
            .download-direct {
              color: black;
              font-size: 16px;
              font-stretch: normal;
              font-style: normal;
              font-weight: 500;
              letter-spacing: normal;
              line-height: normal;
              padding: unset;
              text-transform: none;
              &::before {
                background-color: transparent !important;
              }
              .download-btn__content {
                align-items: center;
                display: flex;
                gap: 4px;

                .v-icon {
                  padding: 2px 0px 0px 0px;
                  font-size: 18px;
                }
              }
            }
            .download-btn {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: black;
              text-transform: none;
              text-align: left;
              overflow: hidden;
              padding: unset !important;
              margin: 6px 8px 6px 6px !important;
              &::before {
                background-color: transparent !important;
                text-align: left;
              }
              .v-btn__content {
                justify-content: flex-start !important;
              }
              &:hover::before {
                opacity: 0 !important;
              }
              .download-btn__content {
                align-items: center;
                display: flex;
                gap: 4px;

                .v-icon {
                  font-size: 16px;
                  width: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.center-tooltip {
  top: -70% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: black;
  font-weight: 400;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
</style>
