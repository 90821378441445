<template>
  <div class="wrapper">
    <div class="main-wrapper">
      <textarea class="text" readonly>
       Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!
   
   Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!

Lorem ipsum dolor sit amet. Id quidem molestiae eum maiores ullam ea ullam quibusdam aut temporibus tempora ad illum culpa. Qui asperiores reiciendis sed magnam vero ut sunt vitae id obcaecati officiis et explicabo assumenda ut voluptate quisquam eum eveniet molestiae.

Aut esse dolor et velit similique ea libero impedit quo porro iusto cum accusamus adipisci. Est quam tempore aut quos enim sed laudantium ipsam aut harum fugit cum dolor maxime.

Sed voluptatibus libero ut libero vitae aut vero iusto aut facilis illo? Sed minus quaerat ut aspernatur alias quo voluptatem internos est debitis architecto ex numquam blanditiis eos nostrum quia! In accusamus quas et molestias facilis et eius nobis qui delectus veniam ea dolor ipsum. Vel architecto quod aut minus modi et architecto neque sed assumenda aspernatur rem voluptas necessitatibus!


    </textarea
      >
    </div>
    <div class="dialog-actions">
      <v-btn
        class="add ma-2 py-2"
        rounded
        color="#20a7e0"
        width="155"
        height="30"
        @click="handleAccept"
      >
        Accept
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyModal',
  components: {},
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hideAll();
    },
    handleAccept() {
      this.handleClose();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.dialog-header {
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}

.dialog-actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  button.cancel {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    text-transform: none;
    color: #21a7e0 !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 40px;
  }

  button.add {
    border-radius: 25px;
    text-transform: none;
    color: white !important;
    background: #21a7e0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.main-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.main-wrapper .text {
  resize: none;
  outline: none;
  width: 100%;
  padding: 10px;
  border: none;
  height: 100%;
  margin: -10px;
  wrap: 'hard';
  white-space: pre-wrap;
}
</style>
