import {
  audiencePlacementMap,
  TD_AUDIENCE_TYPE,
  TD_AUD_ADDITION_TYPE,
  TD_AUD_COMPANY_NAME,
  TD_AUD_EMAIL,
  TD_AUD_FIRST_NAME,
  TD_AUD_LAST_NAME,
  TD_PLACEMENT,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const GENERAL_EVENT_CATEGORY = 'General';
const SHARETRIALS_ICON_CLICK = 'sharetrials_icon_click';
const SHARETRIALS_ICONCLICK_LEARNMORE = 'sharetrials_iconclick_learnmore';
const SHARETRIALS_ICONCLICK_LEARNMORE_VISITSITE =
  'sharetrials_iconclick_learnmore_visitsite';
const SHARETRIALS_SHARE = 'sharetrials_share';
const NOTIFICATIONS_ICON_CLICK = 'notifications_icon_click';
const SHARE_FINGERPRINT_UPDATE = 'Share_fingerprint_update';
const SHARE_FINGERPRINT_ICONCLICK = 'Share_fingerprint_iconclick';
const SHARE_FINGERPRINT_PREVIEWEMAIL = 'Share_fingerprint_Previewemail';
const ADDNEWAUDIENCE_ICONCLICK = 'addnewaudience_iconclick';
const ADDNEWAUDIENCE_LEARNMORE = 'addnewaudience_learnmore';
const ADDNEWAUDIENCE_LEARNMORE_VISITSITE = 'addnewaudience_learnmore_visitsite';
const ADDNEWAUDIENCE_CONTINUE = 'addnewaudience_continue';
const ADDNEWAUDIENCE_CONTINUE_SENDEMAIL = 'addnewaudience_continue_sendemail';
const ADDNEWAUDIENCE_CONTINUE_ASSESSMENTCOPYLINK =
  'addnewaudience_continue_assessmentcopylink';
const ADDNEWAUDIENCE_CONTINUE_PREVIEWEMAIL =
  'addnewaudience_continue_previewemail';
const ADDNEWAUDIENCE_LEARNMORE_V2 = 'addnewaudience_learnmore_v2';
const ADDNEWAUDIENCE_LEARNMORE_VISITSITE_V2 =
  'addnewaudience_learnmore_visitsite_v2';
const ADDNEWAUDIENCE_CONTINUE_PREVIEWEMAIL_V2 =
  'addnewaudience_continue_previewemail_v2';

const ADDNEWAUDIENCE_SHOWFINGERPRINT = 'addnewaudience_showfingerprint';
const ADDNEWAUDIENCE_SHOWFINGERPRINT_RETAKETEST =
  'addnewaudience_showfingerprint_Retaketest';
const ADDNEWAUDIENCE_SHOWFINGERPRINT_COPYLINK =
  'addnewaudience_showfingerprint_copylink';
const ADDNEWAUDIENCE_SHOWFINGERPRINT_SHAREUPDATE =
  'addnewaudience_showfingerprint_shareupdate';

const REMINDER_RETAKE_FINGERPRINT_TEST = 'Reminder_Retake-fingerprint-test';
const REMINDER_RETAKE_FINGERPRINT_TEST_NOT_NOW =
  'Reminder_Retake-fingerprint-test_Not-now';
const FINGERPRINTS_WHY_WE_ASK_CLICK = 'Fingerprinttest_why-we-ask_click';
const FINGERPRINTS_ADD_YOUR_OWN_CLICK = 'Fingerprinttest_add-your-own_click';
const FINGERPRINTS_ADD_YOUR_OWN_ADD = 'Fingerprinttest_add-your-own_add';
const FINGERPRINTS_SKIP = 'Fingerprinttest_Skip';
const FINGERPRINTS_NEXT = 'Fingerprinttest_Next';
const SHARETRIALS_SHARE_COPYLINK = 'sharetrials_share_copylink';
const GENERAL_TRY_FOR_FREE_CLICK = 'General_Try-for-free_click';
const GENERAL_RESET_PASSWORD_CLICK = 'General_Reset-Password_click';
const GENERAL_GET_IN_TOUCH = 'General_Get-in-Touch';

export const trackGeneralEvents = {
  sharetrialsIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARETRIALS_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  sharetrialsIconclickLearnmore(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARETRIALS_ICONCLICK_LEARNMORE,
      });
    } catch (e) {
      console.log(e);
    }
  },

  sharetrialsIconclickLearnmoreVisitsite(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARETRIALS_ICONCLICK_LEARNMORE_VISITSITE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  sharetrialsShare(currentUser, colleaguesList, oData) {
    try {
      let audFirstName = '';
      let audLastName = '';
      let audEmail = '';
      let audCompany = '';
      colleaguesList.forEach((colleague) => {
        const { email, firstname, lastname, company } = colleague;
        audEmail += `${email}_`;
        audFirstName += `${firstname}_`;
        audLastName += `${lastname}_`;
        audCompany += `${company}_`;
      });
      const otherData = {
        [TD_AUD_EMAIL]: audEmail,
        [TD_AUD_FIRST_NAME]: audFirstName,
        [TD_AUD_LAST_NAME]: audLastName,
        [TD_AUD_COMPANY_NAME]: audCompany,
        ...oData,
      };
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARETRIALS_SHARE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  notificationsIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: NOTIFICATIONS_ICON_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceClick(currentUser, otherData, origin) {
    try {
      const placement = audiencePlacementMap[origin];

      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceLearnmore(currentUser, otherData, origin) {
    try {
      const placement = audiencePlacementMap[origin];
      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_LEARNMORE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceLearnmoreVisitsite(currentUser, otherData, origin) {
    try {
      const placement = audiencePlacementMap[origin];
      const updatedOtherData = {
        ...otherData,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData: updatedOtherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_LEARNMORE_VISITSITE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceContinue(currentUser, audienceDetails) {
    try {
      const { id, firstName, lastName, companyName, type, method, origin } =
        audienceDetails;
      const placement = audiencePlacementMap[origin];
      const otherData = {
        [TD_AUD_EMAIL]: id,
        [TD_AUD_FIRST_NAME]: firstName,
        [TD_AUD_LAST_NAME]: lastName,
        [TD_AUD_COMPANY_NAME]: companyName,
        [TD_AUD_ADDITION_TYPE]: method,
        [TD_AUDIENCE_TYPE]: type,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_CONTINUE,
      });
    } catch (e) {
      console.log(e);
    }
  },

  addnewaudienceContinueSendemail(currentUser, audienceDetails) {
    try {
      const { id, firstName, lastName, companyName, type, method, origin } =
        audienceDetails;
      const placement = audiencePlacementMap[origin];
      const otherData = {
        [TD_AUD_EMAIL]: id,
        [TD_AUD_FIRST_NAME]: firstName,
        [TD_AUD_LAST_NAME]: lastName,
        [TD_AUD_COMPANY_NAME]: companyName,
        [TD_AUD_ADDITION_TYPE]: method,
        [TD_AUDIENCE_TYPE]: type,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_CONTINUE_SENDEMAIL,
      });
    } catch (e) {
      console.log(e);
    }
  },

  addnewaudienceContinueAssessmentcopylink(currentUser, audienceDetails) {
    try {
      const { id, firstName, lastName, companyName, type, method, origin } =
        audienceDetails;
      const placement = audiencePlacementMap[origin];
      const otherData = {
        [TD_AUD_EMAIL]: id,
        [TD_AUD_FIRST_NAME]: firstName,
        [TD_AUD_LAST_NAME]: lastName,
        [TD_AUD_COMPANY_NAME]: companyName,
        [TD_AUD_ADDITION_TYPE]: method,
        [TD_AUDIENCE_TYPE]: type,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_CONTINUE_ASSESSMENTCOPYLINK,
      });
    } catch (e) {
      console.log(e);
    }
  },

  addnewaudienceContinuePreviewemail(currentUser, audienceDetails) {
    try {
      const { id, firstName, lastName, companyName, type, method, origin } =
        audienceDetails;
      const placement = audiencePlacementMap[origin];
      const otherData = {
        [TD_AUD_EMAIL]: id,
        [TD_AUD_FIRST_NAME]: firstName,
        [TD_AUD_LAST_NAME]: lastName,
        [TD_AUD_COMPANY_NAME]: companyName,
        [TD_AUD_ADDITION_TYPE]: method,
        [TD_AUDIENCE_TYPE]: type,
        [TD_PLACEMENT]: placement,
      };
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_CONTINUE_PREVIEWEMAIL,
      });
    } catch (e) {
      console.log(e);
    }
  },

  shareFingerprintUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARE_FINGERPRINT_UPDATE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  shareFingerprintIconclick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARE_FINGERPRINT_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  shareFingerprintPreviewemail(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARE_FINGERPRINT_PREVIEWEMAIL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceLearnMoreV2(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_LEARNMORE_V2,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceLearnMoreVisitSiteV2(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_LEARNMORE_VISITSITE_V2,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceContinuePreviewEmailV2(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_CONTINUE_PREVIEWEMAIL_V2,
      });
    } catch (e) {
      console.log(e);
    }
  },

  addnewaudienceShowfingerprint(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_SHOWFINGERPRINT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  addnewaudienceShowfingerprintRetakeTest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_SHOWFINGERPRINT_RETAKETEST,
      });
    } catch (err) {
      console.log(err);
    }
  },
  addnewaudienceShowfingerprintCopyLink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_SHOWFINGERPRINT_COPYLINK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  addnewaudienceShowfingerprintShareUpdate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: ADDNEWAUDIENCE_SHOWFINGERPRINT_SHAREUPDATE,
      });
    } catch (err) {
      console.log(err);
    }
  },
  reminderRetakeFingerPrint(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: REMINDER_RETAKE_FINGERPRINT_TEST,
      });
    } catch (err) {
      console.log(err);
    }
  },
  reminderRetakeFingerPrintNotNow(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: REMINDER_RETAKE_FINGERPRINT_TEST_NOT_NOW,
      });
    } catch (err) {
      console.log(err);
    }
  },
  fingerPrintTestWhyWeAskClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_WHY_WE_ASK_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  fingerPrintTestAddYourOwnClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_ADD_YOUR_OWN_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  fingerPrintTestAddYourOwnAddClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_ADD_YOUR_OWN_ADD,
      });
    } catch (err) {
      console.log(err);
    }
  },
  fingerPrintTestSkipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_SKIP,
      });
    } catch (err) {
      console.log(err);
    }
  },
  fingerPrintTestNextClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: FINGERPRINTS_NEXT,
      });
    } catch (err) {
      console.log(err);
    }
  },
  shareTrialsShareLink(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: SHARETRIALS_SHARE_COPYLINK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generalTryForFreeClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: GENERAL_TRY_FOR_FREE_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generalResetPasswordClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: GENERAL_RESET_PASSWORD_CLICK,
      });
    } catch (err) {
      console.log(err);
    }
  },
  generalGetInTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: GENERAL_EVENT_CATEGORY,
        eventAction: GENERAL_GET_IN_TOUCH,
      });
    } catch (err) {
      console.log(err);
    }
  },
};
