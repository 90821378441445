<template>
  <div class="download-info-content">
    <div class="download-info-image-wrapper">
      <img src="/assets/img/downloadmodal/downloading-shortly.svg" alt />
    </div>
    <div class="message">{{ $t('redesign.download.text') }}</div>
    <div class="message sub">{{ $t('redesign.download.subText') }}</div>
  </div>
</template>

<script>
export default {
  name: 'DownloadRedesignInfo',
};
</script>

<style lang="scss" scoped>
.download-info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .download-info-image-wrapper {
    display: flex;
    justify-content: center;
  }

  .message {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 20px;
    text-align: center;

    &.sub {
      width: 65%;
    }
  }
}
</style>
