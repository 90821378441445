import { render, staticRenderFns } from "./FormatIcon.vue?vue&type=template&id=09f213ee&scoped=true&"
import script from "./FormatIcon.vue?vue&type=script&lang=js&"
export * from "./FormatIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f213ee",
  null
  
)

export default component.exports