<template>
  <div class="quick-selection-wrapper">
    <div class="quick-selection-cntr">
      <div v-if="isNotCollection" class="tool-header">
        <h1>{{ toolHeader }}</h1>
        <v-icon color="#000000" @click="close"> mdi-close </v-icon>
      </div>

      <div v-show="toolType === 'audience'">
        <AudienceToolCntr
          :handleAudienceChange="handleAudienceChange"
          :handleAudienceChangeInitial="handleAudienceChangeInitial"
          :handleAudienceFocus="handleAudienceFocus"
          :handleAddAudience="handleAddAudience"
          :audienceOnLoad="filterTailor"
          :audience="audience"
        />
      </div>
      <div v-if="toolType === 'collection'">
        <CollectionToolCntr
          :downloadCollection="downloadCollection"
          :showReset="showReset"
          :hideCollection="hideCollection"
          :handleDeleteCollection="handleDeleteCollection"
          :logDownloadForSaveToGdrive="logDownloadForSaveToGdrive"
        />
      </div>
      <div v-else-if="toolType === 'filter'">
        <slot></slot>
      </div>
      <div v-if="toolType === 'template'">
        <TemplateToolCntr
          v-if="isSlidesPage"
          :allThemes="allThemes"
          :filterTailor="filterTailor"
        />
        <TemplateToolFilter
          v-else
          :allThemes="allThemes"
          :handleSearchWithTemplateModelChange="
            handleSearchWithTemplateModelChange
          "
          :handleAllTemplatesClick="handleAllTemplatesClick"
          :handleOtherTemplatesClick="handleOtherTemplatesClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../event-bus';
import AudienceToolCntr from './AudienceToolCntr';
import CollectionToolCntr from './CollectionToolCntr';
import TemplateToolCntr from './TemplateToolCntr';
import TemplateToolFilter from './TemplateToolFilter';

export default {
  name: 'QuickSelectionCntr',
  components: {
    AudienceToolCntr,
    CollectionToolCntr,
    TemplateToolCntr,
    TemplateToolFilter,
  },
  props: {
    toolType: String,
    allThemes: {
      type: Array,
      default: () => [],
    },
    filterTailor: {
      type: Object,
      default: () => {},
    },
    handleAudienceChange: {
      type: Function,
      default: () => ({}),
    },
    handleAudienceChangeInitial: {
      type: Function,
      default: () => ({}),
    },
    handleAudienceFocus: {
      type: Function,
      default: () => ({}),
    },
    handleAddAudience: {
      type: Function,
      default: () => ({}),
    },
    downloadCollection: {
      type: Function,
      default: () => ({}),
    },
    showReset: {
      type: Function,
      default: () => ({}),
    },
    hideCollection: {
      type: Function,
      default: () => ({}),
    },
    handleDeleteCollection: {
      type: Function,
      default: () => ({}),
    },
    handleSearchWithTemplateModelChange: {
      type: Function,
      default: () => ({}),
    },
    handleAllTemplatesClick: {
      type: Function,
      default: () => ({}),
    },
    handleOtherTemplatesClick: {
      type: Function,
      default: () => ({}),
    },
    audience: {
      type: Object,
      default: () => ({}),
    },
    logDownloadForSaveToGdrive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      toolHeader: 'Filter',
      selectedTool: 'Filter',
    };
  },
  async mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);

    this.changeHeader();
  },
  computed: {
    isSlidesPage() {
      return this.$route.path === '/home/slides';
    },
    isNotCollection() {
      return this.toolType !== 'collection';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changeHeader() {
      if (this.toolType === 'audience') {
        if (this.isSlidesPage) this.toolHeader = 'Audience';
        else this.toolHeader = 'Your audience preferences at a glance';
      } else if (this.toolType === 'collection') {
        this.toolHeader = 'Collection';
      } else if (this.toolType === 'filter') {
        this.toolHeader = 'Filter & Sort';
      } else if (this.toolType === 'template') {
        this.toolHeader = 'Template';
      }
    },
  },
  watch: {
    toolType() {
      this.changeHeader();
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-selection-wrapper {
  height: calc(100vh - 64px);
  padding: 0;
  position: relative;
  background-color: white;
  animation: openQuickSelection 0.25s forwards ease-in;
}

@keyframes openQuickSelection {
  0% {
    width: 0%;
  }

  100% {
    width: 50%;
  }
}

.quick-selection-cntr {
  // width: 24.5%;
  width: 550px;
  height: 100%;
  position: fixed;
  padding: 1.5rem 1rem;
  box-shadow: -2px 0px 2px 0px #b6b6b64d;
  opacity: 0;
  animation: showToolCntr 0.25s forwards 0.24s ease-in;
}

@keyframes showToolCntr {
  100% {
    opacity: 1;
  }
}

.tool-header {
  margin-bottom: 1rem;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  h1 {
    color: #000000;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
}
</style>
