<template>
  <div class="toggleAutoRenewModalContainer">
    <div
      class="closeIconContainer"
      @click="handleModalClose('closeButton')"
      :disabled="disabling || enabling"
    >
      <v-icon>{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="headingContainer">
      {{
        $t(
          modalType === 'disable'
            ? 'toggleAutoRenew.disableAutoRenew'
            : 'toggleAutoRenew.enableAutoRenew',
        )
      }}
    </div>
    <div class="enableOrDisable">
      <div v-if="modalType === 'disable'" class="disableContainer">
        <div class="disableImageContainer">
          <img
            src="/assets/img/Disable-Auto-Renew.svg"
            alt="disable_auto_renew"
          />
        </div>
        <div class="content">
          {{ $t('toggleAutoRenew.areYouSure') }}
        </div>
        <div class="contentSubText">
          {{
            $t('toggleAutoRenew.subscriptionExpiresMessage', {
              endDate: getSubscriptionEndDate(),
            })
          }}
        </div>
        <div class="disableActions">
          <div class="buttonsContainer">
            <v-btn
              class="disable"
              @click="handleDisableAutoRenew"
              :loading="disabling"
            >
              {{ $t('toggleAutoRenew.disableAutoRenew') }}
            </v-btn>
            <v-btn
              class="keep"
              @click="handleModalClose('keep')"
              :disabled="disabling"
            >
              {{ $t('toggleAutoRenew.keepAutoRenew') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="modalType === 'enable'" class="enableContainer">
        <div class="spinnerContainer" v-if="loadingCardsAndAddress">
          <v-progress-circular :size="50" indeterminate color="#21a7e0" />
        </div>
        <div v-else class="contentContainer">
          <div class="modalSubHeading">
            {{ `${getModalSubHeader()}` }}
          </div>
          <div class="cardsListOrStripeIframeWithName">
            <div v-if="!savedCardsPresent" class="stripeIframeWithName">
              <div class="newPaymentMethodLabel">
                {{ $t('toggleAutoRenew.newPaymentMethod') }}
              </div>
              <div class="stripeFieldContainer">
                <div class="cardFieldLabel">
                  {{ $t('toggleAutoRenew.cardNumber') }}
                </div>
                <stripe-element-card
                  @element-change="stripeElementCardChanged($event)"
                  ref="stripeElementCard"
                  :pk="striprPublicKey"
                  class="stripeElement"
                />
              </div>
              <div class="cardHolderNameField">
                <div class="cardHolderNameLabel">
                  {{ $t('toggleAutoRenew.nameOnCard') }}
                </div>
                <div class="nameFieldContainer">
                  <v-text-field
                    v-model="cardHolderName"
                    :placeholder="$t('toggleAutoRenew.cardHolderName')"
                  />
                  <div class="nameError" v-if="cardHolderName.length > 30">
                    {{ $t('toggleAutoRenew.nameCannotExceedCharacters') }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="presentCardListContainer">
              <div
                class="defaultCardContainer"
                v-if="viewMode === 'cardSelected'"
              >
                <div class="defaultCardPresentLabel">
                  {{
                    $t('toggleAutoRenew.payWithCard', {
                      cardType:
                        cardToBeUsed.is_default === true
                          ? $t('toggleAutoRenew.defaultCard')
                          : $t('toggleAutoRenew.selectedCard'),
                    })
                  }}
                </div>
                <PaymentMethodCard
                  :card="cardToBeUsed"
                  :actionsNeeded="false"
                />
                <PoweredByStripe />
                <div class="selectDifferentCardContainer">
                  <v-btn
                    @click="handleChangeViewMode"
                    v-if="[...getPaymentMethods].length > 2"
                  >
                    {{ $t('toggleAutoRenew.selectDifferentCard') }}
                  </v-btn>
                </div>
              </div>
              <div class="cardListContainer" v-if="viewMode === 'cardList'">
                <div class="cardListLabel">
                  {{ $t('toggleAutoRenew.selectNewDefaultCard') }}
                </div>
                <div class="cardList">
                  <div
                    v-for="(card, ind) in [...getPaymentMethods].slice(
                      0,
                      getPaymentMethods.length - 1,
                    )"
                    class="paymentCards"
                    :key="ind"
                    @click="handleAvailableCardsSelect(card)"
                  >
                    <PaymentMethodCard :card="card" :actionsNeeded="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="billingDetailsWithZipAndCountry"
            v-if="!savedCardsPresent || !addressPresent"
          >
            <div class="billingDetails">
              <div class="billingDetailsLabel">
                {{ $t('toggleAutoRenew.billingAddress') }}
              </div>
              <div class="billingAddressFieldContainer">
                <v-text-field
                  v-model="billingAddress"
                  :placeholder="$t('toggleAutoRenew.enterBillingAddress')"
                />
              </div>
            </div>
            <div class="zipAndCountry">
              <div class="country">
                <div class="countryLabel">
                  {{ $t('toggleAutoRenew.country') }}
                </div>
                <div class="countryFieldContainer">
                  <Country
                    :countryChanged="countryChanged"
                    :countrySelected="country"
                  />
                </div>
              </div>
              <div class="state">
                <div class="stateLabel">{{ stateLabel }}</div>
                <div class="stateFieldContainer">
                  <State
                    :stateChanged="stateChanged"
                    :country="country"
                    :disabled="country === ''"
                    :selectedState="state_code"
                  />
                  <div
                    class="stateError"
                    v-if="(country === 'US' || country === 'CA') && stateError"
                  >
                    {{ 'Please select a state' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="zipWrapper">
              <div class="zip">
                <div class="zipLabel">{{ $t('toggleAutoRenew.zipcode') }}</div>
                <div class="zipFieldContainer">
                  <v-text-field
                    v-model="zipCode"
                    @input="isZipValidAsPerCountry"
                    @click="isZipValidAsPerCountry"
                    :placeholder="$t('toggleAutoRenew.digitCode')"
                  />
                </div>
                <div class="zipError" v-if="zipCodeError">
                  {{ 'Please enter a valid zip code' }}
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="saveAndEnableCheck">
            <v-checkbox
              v-model="saveCardAutoRenew"
              :label="`${getCheckboxLabel()}`"
              color="#20a7e0"
              hide-details
            />
          </div>
          !-->
          <div class="disclaimer">
            {{ $t('toggleAutoRenew.disclaimer') }}
            <span class="mailTo"
              ><a href="mailto:success@prezent.ai">{{
                'success@prezent.ai '
              }}</a></span
            >{{ $t('toggleAutoRenew.toCancelAnyTime') }}
            <span class="terms"
              ><a href="https://prezent.ai/terms-of-service/" target="_blank">{{
                $t('profile.editPaymentDetail.termsOfService')
              }}</a></span
            >
            {{ $t('profile.editPaymentDetail.and') }}
            <span class="policy"
              ><a href="https://prezent.ai/privacy-policy/" target="_blank">{{
                $t('fingerprint.privacyPolicy')
              }}</a
              >.</span
            >
          </div>
          <div class="actions">
            <div class="agreeButtonContainer">
              <v-btn
                :disabled="agreeAndContinueDisabled()"
                @click="handleAutoRenewOn"
                :loading="enabling"
              >
                {{ $t('toggleAutoRenew.agreeAndEnableAutoRenew') }}
              </v-btn>
            </div>
            <div class="questionsAndGetInTouch">
              <div class="questionsForUs">
                {{ $t('profile.editPaymentDetail.haveAQuestion') }}
              </div>
              <div class="getInTouch" @click="getInTouch">
                {{ $t('fingerprint.getInTouch') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import {
  getSavedPaymentMethods,
  getBillingAddress,
  configureAutoRenew,
} from '../../API/payment-api';
import { sendEmail } from '../../utils/api-helper';
import PoweredByStripe from './PoweredByStripe.vue';
import PaymentMethodCard from '../Main/Profile/payment-methods/PaymentMethodCard.vue';
import ContactSoonModal from './ContactSoonModal.vue';
import Country from './Country.vue';
import State from './State.vue';
import { trackProfileEvents } from './Analytics/ProfileEvents';
import { TD_PLACEMENT, TD_RENEW } from './Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ToggleAutoRenewModal',
  components: {
    StripeElementCard,
    PaymentMethodCard,
    PoweredByStripe,
    Country,
    State,
  },
  data() {
    return {
      loadingCardsAndAddress: false,
      fetchedBillingAddress: '',
      fetchedCountry: 'US',
      fetchedZipCode: '',
      fetchedStateCode: '',
      fetchedState: '',
      billingAddress: '',
      country: 'US',
      state: '',
      state_code: '',
      zipCode: '',
      addressPresent: false,
      cardToBeUsed: null,
      viewMode: '',
      striprPublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      isCardElementError: true,
      stateError: false,
      cardHolderName: '',
      defaultCardPresent: false,
      renewCheckboxText: '',
      disabling: false,
      enabling: false,
      zipCodeError: false,
    };
  },
  computed: {
    ...mapGetters('paymentMethods', ['getPaymentMethods']),
    ...mapState('users', ['currentActiveSubscription', 'currentUser']),
    savedCardsPresent() {
      return this.getPaymentMethods.length >= 2;
    },
    isExistingBillingAddress() {
      return (
        this.billingAddress === this.fetchedBillingAddress &&
        this.zipCode === this.fetchedZipCode &&
        this.country === this.fetchedCountry &&
        this.state_code === this.fetchedStateCode &&
        this.state === this.fetchedState
      );
    },
    stateLabel() {
      return this.country === 'US' || this.country === 'CA'
        ? 'State'
        : 'State (optional)';
    },
  },
  methods: {
    ...mapActions('paymentMethods', ['setPaymentMethods', 'setSnackbar']),
    ...mapActions('users', ['setCurrentUser']),

    async handleAutoRenewOn() {
      this.enabling = true;
      trackProfileEvents.profileAdminSettingsPaymentsToggleOnAgreeAndEnableAutoRenew(
        this.currentUser,
        {},
      );
      if (this.cardToBeUsed) {
        // turn on auto renew with existing card
        await configureAutoRenew({
          operation: 'on',
          paymentMethodID: this.cardToBeUsed.id,
          subscriptionID: this.currentActiveSubscription?.id,
          address_line1: this.billingAddress,
          state_code: this.state_code ? this.state_code : '',
          state: this.state ? this.state : '',
          country: this.country,
          postal_code: this.zipCode,
          isExistingPaymentMethod: true,
          isExistingBillingAddress: this.isExistingBillingAddress,
        })
          .then((resp) => {
            const userData = this.currentUser;
            userData.subscriptions[0].stripeSubscriptionID =
              resp?.data.stripeSubscriptionID;
            this.setCurrentUser(userData);
            this.setAutoRenewToggleStatus(true);
            this.setSnackbar({
              status: true,
              text: 'Auto-renew enabled successfully!',
              action: null,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setSnackbar({
              status: true,
              text: 'Could not enable auto-renew. Please try again.',
              action: null,
            });
            this.setAutoRenewToggleStatus(false);
          });
      } else {
        // add new card and turn auto renew on
        const billingDetails = {
          name: this.cardHolderName,
          email: this.currentUser.user.id,
        };
        const { stripe } = this.$refs.stripeElementCard;
        await stripe
          .createPaymentMethod({
            type: 'card',
            card: this.$refs.stripeElementCard.element,
            billing_details: billingDetails,
          })
          .then(async (paymentMethodRes) => {
            const { paymentMethod } = paymentMethodRes;
            await configureAutoRenew({
              operation: 'on',
              paymentMethodID: paymentMethod?.id,
              subscriptionID: this.currentActiveSubscription?.id,
              address_line1: this.billingAddress,
              state_code: this.state_code ? this.state_code : undefined,
              state: this.state ? this.state : undefined,
              country: this.country,
              postal_code: this.zipCode,
              isExistingPaymentMethod: false,
              isExistingBillingAddress: this.isExistingBillingAddress,
            })
              .then((resp) => {
                const userData = this.currentUser;
                userData.subscriptions[0].stripeSubscriptionID =
                  resp?.data.stripeSubscriptionID;
                this.setCurrentUser(userData);
                this.setAutoRenewToggleStatus(true);
                this.setSnackbar({
                  status: true,
                  text: 'Auto-renew enabled successfully!',
                  action: null,
                });
              })
              .catch((err) => {
                console.log(err);
                this.setAutoRenewToggleStatus(false);
                this.setSnackbar({
                  status: true,
                  text: 'Could not enable auto-renew. Please try again.',
                  action: null,
                });
              });
          })
          .catch((stripeError) => {
            console.log('error while calling create payment', stripeError);
            this.setSnackbar({
              status: true,
              text: 'Could not enable auto-renew. Please try again.',
              action: null,
            });
          });
      }

      // fetch new payment methods
      getSavedPaymentMethods()
        .then((resp) => {
          if (resp?.data && resp?.data.length) {
            const cardsInfo = resp.data.map((card) => ({
              ...card,
            }));
            this.setPaymentMethods(cardsInfo);
          } else {
            this.setPaymentMethods([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.enbling = false;
      this.handleModalClose();
    },

    handleChangeViewMode() {
      this.viewMode = 'cardList';
      this.cardToBeUsed = null;
      trackProfileEvents.profileAdminSettingsPaymentsSelectaDifferentCard(
        this.currentUser,
        {
          [TD_PLACEMENT]: TD_RENEW,
        },
      );
    },

    handleAvailableCardsSelect(card) {
      this.viewMode = 'cardSelected';
      this.cardToBeUsed = card;
      // this.cardToBeUsed = {
      //   ...this.cardToBeUsed,
      //   is_default: true,
      // };
    },

    getModalSubHeader() {
      let text = '';
      if (!this.savedCardsPresent && !this.addressPresent) {
        text =
          'Please add at least one payment method and enter your billing information before enabling auto-renew';
      } else if (!this.savedCardsPresent && this.addressPresent) {
        text =
          'Please add atleast one payment method before enabling auto-renew';
      } else if (!this.defaultCardPresent && !this.addressPresent) {
        text =
          'Please select a default payment method and enter your billing information before enabling auto-renew';
      } else if (!this.defaultCardPresent && this.addressPresent) {
        text =
          'Please select a default payment method before enabling auto-renew';
      } else if (!this.addressPresent) {
        text =
          'Please enter your billing information before enabling auto-renew';
      } else {
        text =
          'Please select a default payment method before enabling auto-renew';
      }
      return text;
    },

    // getCheckboxLabel() {
    //   let text = '';
    //   if (!this.savedCardsPresent && !this.addressPresent) {
    //     text = 'Save card details and enable auto-renew';
    //   } else if (!this.savedCardsPresent && this.addressPresent) {
    //     text = 'Save card details and enable auto-renew';
    //   } else if (!this.defaultCardPresent && !this.addressPresent) {
    //     text = 'Save card details and enable auto-renew';
    //   } else if (!this.defaultCardPresent && this.addressPresent) {
    //     text = 'Save card details and enable auto-renew';
    //   } else if (!this.addressPresent) {
    //     text = 'Enable auto-renew';
    //   } else {
    //     text = 'Enable auto-renew';
    //   }
    //   return text;
    // },

    handleModalClose(type) {
      if (type === 'keep')
        trackProfileEvents.profileAdminSettingsPaymentsToggleOffKeepAutoRenew(
          this.currentUser,
          {},
        );
      if (type === 'closeButton' || type === 'keep') {
        if (this.modalType === 'enable') {
          this.setAutoRenewToggleStatus(false);
        } else {
          this.setAutoRenewToggleStatus(true);
        }
      }
      this.$modal.hide('ToggleAutoRenewModal');
    },

    getDayFormat(day) {
      if (day === 1 || day === 31) {
        return 'st';
      }
      if (day === 2) {
        return 'nd';
      }
      if (day === 3) {
        return 'rd';
      }
      return 'th';
    },

    getSubscriptionEndDate() {
      const endDate = new Date(this.currentActiveSubscription.endDate);
      const year = endDate.getUTCFullYear();
      const monthsList = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const month = monthsList[endDate.getMonth()];
      const day = endDate.getDate();
      return `${month} ${day}${this.getDayFormat(day)}, ${year}`;
    },

    async handleDisableAutoRenew() {
      // disable auto renew
      this.disabling = true;
      trackProfileEvents.profileAdminSettingsPaymentsToggleOffDisableAutoRenew(
        this.currentUser,
        {},
      );
      await configureAutoRenew({
        operation: 'off',
        subscriptionID: this.currentActiveSubscription?.id,
      })
        .then((resp) => {
          const userData = this.currentUser;
          userData.subscriptions[0].stripeSubscriptionID =
            resp?.data.stripeSubscriptionID;
          this.setCurrentUser(userData);
          this.setAutoRenewToggleStatus(false);
          this.setSnackbar({
            status: true,
            text: 'Auto-renew disabled successfully!',
            action: null,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setSnackbar({
            status: true,
            text: 'Could not disable auto-renew. Please try again.',
            action: null,
          });
          this.setAutoRenewToggleStatus(true);
        });
      this.disabling = false;
      this.handleModalClose();
    },

    stripeElementCardChanged(event) {
      if (event && (event.error || event.empty || !event.complete)) {
        this.isCardElementError = true;
      } else {
        this.isCardElementError = false;
      }
    },

    getInTouch() {
      try {
        const params = {
          type: 'enable-auto-renew',
          to: ['sfdc@prezent.ai'],
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            userEmail: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log('sendEmail rsp ', response))
          .catch((err) => console.log('sendEmail err ', err));
      } catch (err) {
        console.log(err);
      }

      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
        },
      );
    },

    agreeAndContinueDisabled() {
      let disableButton = true;
      if (!this.savedCardsPresent && !this.addressPresent) {
        disableButton =
          this.isCardElementError ||
          this.cardHolderName === '' ||
          this.billingAddress === '' ||
          this.zipCode === '' ||
          this.stateError ||
          this.stateMissingButRequired() ||
          this.country === '' ||
          this.cardHolderName.length > 30;
      } else if (!this.savedCardsPresent && this.addressPresent) {
        disableButton =
          this.isCardElementError ||
          this.cardHolderName === '' ||
          this.billingAddress === '' ||
          this.zipCode === '' ||
          this.stateError ||
          this.stateMissingButRequired() ||
          this.country === '' ||
          this.cardHolderName.length > 30;
      } else if (!this.defaultCardPresent && !this.addressPresent) {
        disableButton =
          !this.cardToBeUsed ||
          this.billingAddress === '' ||
          this.zipCode === '' ||
          this.stateError ||
          this.stateMissingButRequired() ||
          this.country === '' ||
          this.cardHolderName.length > 30;
      } else if (!this.defaultCardPresent && this.addressPresent) {
        disableButton = !this.cardToBeUsed;
      } else if (!this.addressPresent) {
        disableButton =
          !this.cardToBeUsed ||
          this.billingAddress === '' ||
          this.zipCode === '' ||
          this.stateMissingButRequired() ||
          this.stateError ||
          this.country === '';
      } else {
        disableButton = !this.cardToBeUsed;
      }
      return disableButton;
    },

    countryChanged(newCountry) {
      this.stateError = false;
      this.state = '';
      this.state_code = '';
      this.zipCode = '';
      this.country = newCountry;
    },

    stateChanged(newState, state) {
      this.state = state.name;
      this.state_code = newState;
      this.stateError = false;
      this.zipCode = '';
      if (this.country === 'US' || this.country === 'CA') {
        if (newState === '') {
          this.stateError = true;
        }
      }
    },

    async isZipValidAsPerCountry() {
      // this.zipCodeError = false;
      // this.taxJarError = false;
      /* eslint-disable prefer-regex-literals */
      const usCodes = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
      const caCodes = new RegExp(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      );
      if (this.country === 'US') {
        if (usCodes.test(this.zipCode.toString())) {
          this.zipCodeError = false;
        } else {
          this.zipCodeError = true;
        }
      } else if (this.country === 'CA') {
        if (caCodes.test(this.zipCode.toString())) {
          this.zipCodeError = false;
        } else {
          this.zipCodeError = true;
        }
      } else if (this.zipCode.toString()) {
        this.zipCodeError = false;
      } else {
        this.zipCodeError = true;
      }
    },

    stateMissingButRequired() {
      return (
        (this.country === 'US' || this.country === 'CA') &&
        this.state_code === ''
      );
    },
  },
  props: {
    modalType: {
      type: String,
      default: '',
    },
    setAutoRenewToggleStatus: {
      type: Function,
      default: () => ({}),
    },
  },
  async mounted() {
    if (this.modalType !== 'disable') {
      this.loadingCardsAndAddress = true;
      await getSavedPaymentMethods()
        .then((resp) => {
          if (resp?.data && resp?.data?.length) {
            const defaultCard = resp.data.find(
              (itm) => itm.is_default === true,
            );
            if (defaultCard) {
              this.cardToBeUsed = defaultCard;
              this.viewMode = 'cardSelected';
              this.defaultCardPresent = true;
            } else {
              this.viewMode = 'cardList';
              this.defaultCardPresent = false;
              this.cardToBeUsed = null;
            }
            this.setPaymentMethods(resp?.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await getBillingAddress()
        .then((resp) => {
          if (resp?.data && resp?.data?.id) {
            this.fetchedBillingAddress = resp.data.address_line1;
            this.fetchedCountry = resp.data.country;
            this.fetchedZipCode = resp.data.postal_code;
            this.billingAddress = resp.data.address_line1;
            this.zipCode = resp.data.postal_code;
            this.country = resp.data.country;
            if (resp.data.state_code) {
              this.state_code = resp.data.state_code;
            } else {
              this.state_code = '';
            }
            if (resp.data.state) {
              this.state = resp.data.state;
            } else {
              this.state = '';
            }
            this.fetchedState = this.state;
            this.fetchedStateCode = this.state_code;
            this.addressPresent = true;
          } else {
            this.addressPresent = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.loadingCardsAndAddress = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.toggleAutoRenewModalContainer {
  padding: 24px;
  .closeIconContainer {
    position: absolute;
    top: 6px;
    right: 4px;
    cursor: pointer;
  }
  .headingContainer {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 16px;
  }
  .enableOrDisable {
    .disableContainer {
      .disableImageContainer {
        padding: 24px 0px 36px 0px;
        img {
          width: 100%;
          height: 300px;
        }
      }
      .content,
      .contentSubText {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        padding-bottom: 10px;
      }
      .contentSubText {
        padding-bottom: 20px;
      }
      .disableActions {
        display: flex;
        justify-content: flex-end;
        .buttonsContainer {
          width: 60%;
          display: flex;
          justify-content: space-between;
          .disable,
          .keep {
            height: 36px;
            min-width: 64px;
            padding: 0 16px;
            box-shadow: none;
            text-transform: none;
            letter-spacing: normal;
            border-radius: 20px;
            font-size: 15px;
            cursor: pointer;
          }
          .disable {
            background: transparent;
            background-color: transparent;
            border: 1px solid #21a7e0 !important;
            color: #21a7e0;
          }
          .keep {
            background-color: #21a7e0;
            color: #fff;
          }
        }
      }
    }
    .enableContainer {
      .spinnerContainer {
        padding: 40px 0px;
        text-align: center;
      }
      .contentContainer {
        .modalSubHeading {
          font-size: 16px;
        }
        .cardsListOrStripeIframeWithName {
          .stripeIframeWithName {
            .newPaymentMethodLabel {
              padding: 14px 0px;
              font-size: 15px;
              font-weight: 600;
            }
            .stripeFieldContainer {
              width: 100%;
              height: 96px;
              .cardFieldLabel {
                width: 100%;
                padding: 0px;
                font-size: 16px;
                font-weight: 600;
              }
              ::v-deep .stripeElement {
                #stripe-element-form {
                  .StripeElement {
                    box-shadow: none !important;
                    border-radius: 0px !important;
                    padding: 14px 0px 0px 0px;
                    border-bottom: thin solid rgba(0, 0, 0, 0.42);
                  }
                  .StripeElement--focus {
                    border-bottom: thin solid #21a7e0 !important;
                  }
                  .StripeElement--invalid {
                    box-shadow: none !important;
                    border-radius: 0px !important;
                    padding: 14px 0px 0px 0px;
                    border-color: transparent transparent #fa755a transparent;
                  }
                  #stripe-element-errors {
                    color: #fa755a;
                  }
                }
              }
            }
            .cardHolderNameField {
              width: 100%;
              height: 96px;
              .cardHolderNameLabel {
                width: 100%;
                padding: 0px 0px 8px 0px;
                font-size: 16px;
                font-weight: 600;
              }
              .nameFieldContainer {
                width: 100%;
                ::v-deep .v-input {
                  padding: 0;
                  margin: 0;
                  .v-input__control {
                    .v-input__slot {
                      margin: 0 !important;
                      &:before {
                        border-style: none !important;
                        border-width: 0px !important;
                        border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
                      }
                      &:after {
                        border: none !important;
                      }
                    }
                    .v-text-field__details {
                      display: none;
                    }
                  }
                }
                ::v-deep .v-input--is-focused {
                  padding: 0;
                  margin: 0;
                  .v-input__control {
                    .v-input__slot {
                      margin: 0 !important;
                      &:before {
                        border-style: none !important;
                        border-width: 0px !important;
                        border-bottom: thin solid #21a7e0 !important;
                      }
                      &:after {
                        border: none !important;
                      }
                    }
                    .v-text-field__details {
                      display: none;
                    }
                  }
                }
                .nameError {
                  color: red;
                }
              }
            }
          }
          .presentCardListContainer {
            .defaultCardContainer {
              .defaultCardPresentLabel {
                padding: 14px 0px;
                font-size: 15px;
                font-weight: 600;
              }
              .savedCards {
                height: 112px;
                ::v-deep .otherCardInfo {
                  width: 70%;
                  padding: 15px 0px;
                  text-align: left;
                  .maskedCardNumber {
                    .mask {
                      padding: 7px 0px 0px 0px;
                    }
                  }
                }
                ::v-deep .cardActionsAndStatus {
                  width: 5% !important;
                }
                ::v-deep .iconContainer {
                  padding: 16px 12px;
                }
              }
              .poweredByStripe {
                margin: 20px 0px 0px 0px;
              }
              .selectDifferentCardContainer {
                padding: 12px 0px;
                .v-btn {
                  height: 20px;
                  background: transparent;
                  background-color: transparent;
                  box-shadow: none;
                  padding: 0;
                  text-transform: none;
                  font-size: 16px;
                  letter-spacing: normal;
                  color: #20a7e0;
                  cursor: pointer;
                  &:hover {
                    background-color: transparent !important;
                  }
                  &:before {
                    background-color: transparent !important;
                  }
                }
              }
            }
            .cardListContainer {
              .cardListLabel {
                padding: 12px 0px;
                font-size: 15px;
                font-weight: 600;
              }
              .cardList {
                max-height: 280px;
                overflow: auto;
                padding: 4px 16px 4px 16px;
                .paymentCards {
                  margin: 0px 0px 16px 0px;
                  cursor: pointer;
                  .savedCards {
                    height: 112px;
                    ::v-deep .otherCardInfo {
                      width: 70%;
                      padding: 15px 0px;
                      text-align: left;
                      .maskedCardNumber {
                        .mask {
                          padding: 7px 0px 0px 0px;
                        }
                      }
                    }
                    ::v-deep .cardActionsAndStatus {
                      width: 5% !important;
                    }
                    ::v-deep .iconContainer {
                      padding: 16px 12px;
                    }
                    &:hover {
                      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
                    }
                  }
                }
              }
            }
          }
        }
        .billingDetailsWithZipAndCountry {
          .billingDetails {
            width: 100%;
            height: 96px;
            padding: 0px 0px 0px 0px;
            .billingDetailsLabel {
              width: 100%;
              padding: 0px 0px 8px 0px;
              font-size: 16px;
              font-weight: 600;
            }
            .billingAddressFieldContainer {
              width: 100%;
              ::v-deep .v-input {
                padding: 0;
                margin: 0;
                .v-input__control {
                  .v-input__slot {
                    margin: 0 !important;
                    &:before {
                      border-style: none !important;
                      border-width: 0px !important;
                      border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
                    }
                    &:after {
                      border: none !important;
                    }
                  }
                  .v-text-field__details {
                    display: none;
                  }
                }
              }
              ::v-deep .v-input--is-focused {
                padding: 0;
                margin: 0;
                .v-input__control {
                  .v-input__slot {
                    margin: 0 !important;
                    &:before {
                      border-style: none !important;
                      border-width: 0px !important;
                      border-bottom: thin solid #21a7e0 !important;
                    }
                    &:after {
                      border: none !important;
                    }
                  }
                  .v-text-field__details {
                    display: none;
                  }
                }
              }
            }
          }
          .zipAndCountry {
            display: flex;
            justify-content: space-between;
            height: 96px;
            .country {
              width: 48%;
              .countryLabel {
                width: 100%;
                padding: 0px 0px 8px 0px;
                font-size: 16px;
                font-weight: 600;
              }
              .countryFieldContainer {
                width: 100%;
                border-style: none !important;
                border-width: 0px !important;
                .countryDropdown {
                  font-size: 15px !important;
                  padding: 6px 0px;
                  width: 100%;
                  border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
                  &:focus {
                    background-color: #fff;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    border-bottom: thin solid #21a7e0 !important;
                  }
                }
              }
            }
            .state {
              width: 48%;
              height: 113px;
              .stateLabel {
                width: 100%;
                padding: 0px 0px 8px 0px;
                font-size: 16px;
                font-weight: 600;
              }
              .stateFieldContainer {
                width: 100%;
                border-style: none !important;
                border-width: 0px !important;
                .stateDropdown {
                  font-size: 15px !important;
                  padding: 6px 0px;
                  width: 100%;
                  border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
                  &:focus {
                    background-color: #fff;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    border-bottom: thin solid #21a7e0 !important;
                  }
                }
                .stateError {
                  color: red;
                }
              }
            }
          }
          .zipWrapper {
            display: flex;
            justify-content: space-between;
            height: 96px;
            .zip {
              width: 48%;
              .zipLabel {
                width: 100%;
                padding: 0px 0px 8px 0px;
                font-size: 16px;
                font-weight: 600;
              }
              .zipFieldContainer {
                width: 100%;
                ::v-deep .v-input {
                  padding: 0;
                  margin: 0;
                  .v-input__control {
                    .v-input__slot {
                      margin: 0 !important;
                      &:before {
                        border-style: none !important;
                        border-width: 0px !important;
                        border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
                      }
                      &:after {
                        border: none !important;
                      }
                    }
                    .v-text-field__details {
                      display: none;
                    }
                  }
                }
                ::v-deep .v-input--is-focused {
                  padding: 0;
                  margin: 0;
                  .v-input__control {
                    .v-input__slot {
                      margin: 0 !important;
                      &:before {
                        border-style: none !important;
                        border-width: 0px !important;
                        border-bottom: thin solid #21a7e0 !important;
                      }
                      &:after {
                        border: none !important;
                      }
                    }
                    .v-text-field__details {
                      display: none;
                    }
                  }
                }
              }
              .zipError {
                color: red;
              }
            }
          }
        }
        .saveAndEnableCheck {
          padding: 2px 0px;
          ::v-deep .v-input {
            margin: 0 !important;
            padding: 0 !important;
            .v-input__control {
              .v-input__slot {
                .v-label {
                  padding: 0px 0px 0px 4px;
                }
              }
            }
          }
        }
        .disclaimer {
          font-size: 13px;
          color: #7b7b7b;
          padding: 15px 0px 16px 0px;
          line-height: 16px;
          a {
            color: #21a7e0 !important;
            cursor: pointer;
          }
        }
        .actions {
          display: flex;
          justify-content: space-between;
          .agreeButtonContainer {
            .v-btn {
              background: transparent;
              box-shadow: none;
              text-transform: none;
              letter-spacing: normal;
              font-size: 17px;
              background-color: #21a7e0;
              border-radius: 20px;
              color: #fff;
              height: 40px;
            }
          }
          .questionsAndGetInTouch {
            font-size: 13px;
            padding: 10px 0px;
            .questionsForUs {
              color: #7b7b7b;
              line-height: 12px;
            }
            .getInTouch {
              text-align: right;
              color: #21a7e0;
              line-height: 18px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
