<template>
  <div class="loader-container">
    <video
      autoplay
      :loop="asset.loop"
      muted
      playsinline
      ref="video"
      :key="id"
      @ended="onEnd()"
    >
      <source :src="asset.url" type="video/mp4" />
    </video>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'VideoLoader',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    asset() {
      this.id = uuidv4();
      console.log(this.value.getAsset(), 'asset changed');
    },
  },
  computed: {
    asset() {
      return this.value.getAsset();
    },
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    onEnd() {
      this.value.nextAsset();
      this.$emit('update:value', this.value);
    },
  },
  mounted() {
    this.id = uuidv4();
    console.log(this.value.getAsset(), 'mounted');
  },
};
</script>
<style scoped lang="scss">
.loader-container {
  background-color: white;
  height: 100%;
  width: 100%;

  video {
    width: 100%;
    height: 100%;
  }
}
</style>
