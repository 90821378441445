<template>
  <div class="users-group-section">
    <div class="users-group-section--list">
      <v-avatar
        :color="user.color"
        size="56"
        rounded
        v-for="(user, index) of usersForAvatars"
        :key="index"
        :class="
          index >= 1
            ? 'users-group-section--item'
            : 'users-group-section--item__border'
        "
      >
        <v-tooltip
          bottom
          max-width="200"
          content-class="tooltip-content"
          fixed
          v-if="user.profileImageUrl === null || user.profileImageUrl === ''"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="white--text text-h5"
              style="color: white; font-size: 27px; font-weight: 250"
            >
              {{
                `${user.firstName.charAt(0).toUpperCase()}${user.lastName
                  .charAt(0)
                  .toUpperCase()}`
              }}
            </span>
          </template>
          <span>{{ `${user.firstName} ${user.lastName}` }}</span>
        </v-tooltip>

        <v-menu bottom offset-y v-else open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              :src="user.profileImageUrl"
              contain
              alt
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <div
            :style="{
              padding: '6px',
            }"
          >
            <span class="learnersWithPicNames">
              {{ `${user.firstName} ${user.lastName}` }}
            </span>
          </div>
        </v-menu>
      </v-avatar>
    </div>

    <p
      class="users-group-section--user-count"
      v-if="usersForFellowText.length > 0"
    >
      <v-menu
        top
        :offset-y="offset"
        open-on-hover
        :max-height="setMaxHeightToMenuTooltip()"
        max-width="200"
      >
        <template v-slot:activator="{ on, attrs }">
          <span class="fellowUsersTextSpan" v-bind="attrs" v-on="on">
            {{ getFellowText() }}
          </span>
        </template>

        <div
          :style="{
            'max-width': '185px',
            'max-height': `${setMaxHeightToMenuTooltip() - 18}px`,
            'overflow-y': 'auto',
            margin: '9px 16px',
          }"
        >
          <v-list class="tooltipList">
            <v-list-item
              v-for="(fellowUsers, index) in usersForFellowText.sort((a, b) =>
                (a.firstName + a.lastName).localeCompare(
                  b.firstName + b.lastName,
                ),
              )"
              :key="index"
              class="tooltipListItem"
            >
              <v-list-item-title class="tooltipListItemTitle">{{
                `${
                  fellowUsers.firstName.charAt(0).toUpperCase() +
                  fellowUsers.firstName.substring(1)
                } ${
                  fellowUsers.lastName.charAt(0).toUpperCase() +
                  fellowUsers.lastName.substring(1)
                }`
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </p>
    <p v-else class="users-group-section--user-count">
      {{ getFellowText() }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'UsersGroup',
  components: {},
  props: {
    usersInfo: {
      type: Object,
      required: true,
    },
    courseCompletionStatus: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      offset: true,
    };
  },
  computed: {
    usersForAvatars() {
      return this.usersInfo.learners ? this.usersInfo.learners.slice(0, 5) : [];
    },
    usersForFellowText() {
      return this.usersInfo.learners ? this.usersInfo.learners.slice(5) : [];
    },
  },
  methods: {
    getFellowText() {
      let msg = '';
      if (this.usersInfo.learners) {
        if (this.usersInfo.learners.length <= 0) {
          if (this.courseCompletionStatus !== 100) {
            msg = this.$t('learn.course.beTheFirstToCompleteCourse');
          } else {
            msg = this.$t('learn.course.youAreFirstToCompleteCourse');
          }
        } else if (
          this.usersForAvatars.length <= 5 &&
          this.usersForFellowText.length === 0
        ) {
          msg = '';
        } else if (this.usersForFellowText.length > 0) {
          msg = this.$t('learn.course.colleaguesStartedLearning', {
            users: this.usersForFellowText.length,
          });
        }
        return msg;
      }
      // if things yet to load
      return '';
    },
    setMaxHeightToMenuTooltip() {
      if (this.usersForFellowText.length <= 10) {
        return this.usersForFellowText.length * 25 + 18; // height of each item + margin for the container.
      }
      return 268; // less height to enable scroll for more than 10 users in the list.
    },
  },
};
</script>
<style lang="scss" scoped>
.v-menu__content,
.theme--light,
.v-menu__content--fixed {
  background: #fff;
}

.fellowUsersTextSpan {
  padding: 8px 0px 0px 0px;
}

.tooltipList {
  padding: 0 !important;
}

.tooltipListItem {
  min-height: 25px;
  padding: 0;
}

.tooltipListItemTitle {
  font-size: 15px;
}

.users-group-section {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  &--list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
  }
  &--item {
    border: 2px solid white !important;
    margin-left: -20px;
  }
  &--item__border {
    border: 2px solid white !important;
  }
  &--user-count {
    color: #7b7b7b;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 10px;
  }
}
</style>
