<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 14.2361V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H12.2547C11.8334 2.60401 11.5049 3.27757 11.2899 4H8V16H20V15C20.7684 15 21.4692 14.7111 22 14.2361Z"
      :fill="iconColor"
    />
    <path
      d="M2.5875 21.4125C2.97917 21.8042 3.45 22 4 22H18V20H4V6H2V20C2 20.55 2.19583 21.0208 2.5875 21.4125Z"
      :fill="iconColor"
    />
    <path
      d="M17.9966 13.4479C17.7244 13.4479 17.4473 13.392 17.1654 13.2802C16.8834 13.1684 16.6695 12.9813 16.5237 12.7188H16.3341C15.9549 12.6507 15.6463 12.4757 15.4081 12.1938C15.1699 11.9118 15.0508 11.5813 15.0508 11.2021V9.2625C14.4869 8.825 14.0543 8.28785 13.7529 7.65104C13.4515 7.01424 13.3008 6.33125 13.3008 5.60208C13.3008 4.30903 13.7602 3.20313 14.6789 2.28438C15.5977 1.36563 16.7036 0.90625 17.9966 0.90625C19.2897 0.90625 20.3956 1.36563 21.3143 2.28438C22.2331 3.20313 22.6924 4.30903 22.6924 5.60208C22.6924 6.32153 22.5418 7.00208 22.2404 7.64375C21.939 8.28542 21.5063 8.825 20.9424 9.2625V11.2021C20.9424 11.5813 20.8233 11.9118 20.5852 12.1938C20.347 12.4757 20.0383 12.6507 19.6591 12.7188H19.4549C19.3091 12.9813 19.0952 13.1684 18.8133 13.2802C18.5313 13.392 18.2591 13.4479 17.9966 13.4479ZM16.7716 11.2021H19.2216V10.5021H16.7716V11.2021ZM16.7716 9.80208H19.2216V9.10208H16.7716V9.80208ZM16.0424 7.87708H17.5299V6.49167L16.1883 5.15L16.8445 4.49375L17.9966 5.64583L19.1487 4.49375L19.8049 5.15L18.4633 6.49167V7.87708H19.9508C20.2911 7.5757 20.5463 7.22813 20.7164 6.83438C20.8865 6.44063 20.9716 6.02986 20.9716 5.60208C20.9716 4.77569 20.6824 4.07326 20.1039 3.49479C19.5254 2.91632 18.823 2.62708 17.9966 2.62708C17.1702 2.62708 16.4678 2.91632 15.8893 3.49479C15.3109 4.07326 15.0216 4.77569 15.0216 5.60208C15.0216 6.02014 15.1067 6.42847 15.2768 6.82708C15.447 7.2257 15.7022 7.5757 16.0424 7.87708Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'BpeIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
