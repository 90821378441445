<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V7H4V18H18V20H4ZM8 16C7.45 16 6.97917 15.8042 6.5875 15.4125C6.19583 15.0208 6 14.55 6 14V6C6 5.45 6.19583 4.97917 6.5875 4.5875C6.97917 4.19583 7.45 4 8 4H15.4L13.4 6H8V14H20V8.6L22 6.6V14C22 14.55 21.8042 15.0208 21.4125 15.4125C21.0208 15.8042 20.55 16 20 16H8ZM13.5 12C13.1167 12 12.7458 11.9125 12.3875 11.7375C12.0292 11.5625 11.7333 11.3167 11.5 11C11.7833 11 12.0208 10.9042 12.2125 10.7125C12.4042 10.5208 12.5 10.2833 12.5 10C12.5 9.58333 12.6458 9.22917 12.9375 8.9375C13.2292 8.64583 13.5833 8.5 14 8.5C14.4167 8.5 14.7708 8.64583 15.0625 8.9375C15.3542 9.22917 15.5 9.58333 15.5 10C15.5 10.55 15.3042 11.0208 14.9125 11.4125C14.5208 11.8042 14.05 12 13.5 12ZM16.775 9L15 7.225L19.1 3.15C19.2 3.05 19.3167 3 19.45 3C19.5833 3 19.7 3.05 19.8 3.15L20.85 4.2C20.95 4.3 21 4.41667 21 4.55C21 4.68333 20.95 4.8 20.85 4.9L16.775 9Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'BuildIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 24,
    },
    iconHeight: {
      type: Number,
      default: 24,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
