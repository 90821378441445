<template>
  <div class="no-results-container">
    <v-img width="500" :src="imgUrl" alt />
    <div class="heading font-weight-bold mb-1 mt-6">
      {{ $t('build.step1.storylineFieldNoResult') }}
    </div>
    <p v-if="description" class="not-found-descriptions">
      {{ $t(description) }}
    </p>
    <v-btn
      v-if="isBtnVisible"
      center
      id="new-prez-btn"
      class="primary-button"
      rounded
      filled
      color="primary"
      @click="handleCTA"
    >
      {{ $t(buttonName) }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'NoResultPage',
  data() {
    return {};
  },
  props: {
    imgUrl: {
      type: String,
      required: true,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    isBtnVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleCTA: {
      type: Function,
      required: false,
    },
    buttonName: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .heading {
    font-size: 24px;
  }
  .not-found-descriptions {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: fit-content;
  }
  button {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 15px 0;
    color: white;
    text-transform: none;
  }
}
</style>
