<template>
  <div class="great-decision-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="great-decision-wrapper-content">
      <div class="great-decision-image-wrapper">
        <img src="/assets/img/profile/limit.svg" alt />
      </div>
      <div class="great-decision-desc">
        {{ $t('profile.youDontHaveLicenses') }}<br />{{
          $t('profile.likeToTakeLicenses')
        }}
      </div>
      <div class="great-decision-action">
        <v-btn
          v-if="plan && isPayByCardEnabled"
          class="great-decision-go-to-payment"
          rounded
          color="#20a7e0"
          width="157"
          height="32"
          @click="handleUpgrade"
        >
          {{ $t('profile.payByCard') }}
        </v-btn>
        <v-btn
          class="great-decision-get-in-touch"
          rounded
          text
          color="#20a7e0"
          width="157"
          height="32"
          @click="handleContact"
        >
          {{ $t('fingerprint.getInTouch') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContactSoonModal from './ContactSoonModal.vue';
import SubmitPaymentDetail from './SubmitPaymentDetail.vue';
import BuyAdditionalLicense from './BuyAdditionalLicense.vue';
import { sendEmail } from '../../utils/api-helper';

export default {
  name: 'AddLicensesModal',
  props: {
    upgrade: {
      type: Function,
      default: () => ({}),
    },
    plan: {
      type: Object,
    },
    paymentType: {
      type: String,
    },
    currentNumSeats: {
      type: Number,
      default: 0,
    },
    endDate: {
      type: String,
    },
    startDate: {
      type: String,
    },

    subscriptionID: {
      type: String,
    },
    isPayByCardEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    handleClose() {
      this.$modal.hide('AddLicensesModal');
    },
    handleContact() {
      this.$modal.hide('AddLicensesModal');
      try {
        const params = {
          type: 'buy-subscription',
          to: ['sfdc@prezent.ai'],
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            userEmail: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log('sendEmail rsp ', response))
          .catch((err) => console.log('sendEmail err ', err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
        },
      );
      this.handleClose();
    },
    handleUpgrade() {
      this.$modal.hide('AddLicensesModal');
      if (this.paymentType === 'upgrade') {
        this.$modal.show(
          SubmitPaymentDetail,
          {
            plan: this.plan,
            subscriptionID: this.subscriptionID,
          },
          {
            name: 'SubmitPaymentDetail',
            width: '995px',
            height: '800px',
          },
        );
      } else {
        this.$modal.show(
          BuyAdditionalLicense,
          {
            upgradePlan: this.upgrade,
            plan: this.plan,
            currentNumSeats: this.currentNumSeats,
            endDate: this.endDate,
            startDate: this.startDate,
            subscriptionID: this.subscriptionID,
          },
          {
            name: 'BuyAdditionalLicense',
            width: '995px',
            height: '800px',
          },
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.great-decision-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .great-decision-wrapper-content {
    width: 100%;
    .great-decision-image-wrapper {
      margin: auto;
      margin-top: 55px;
      margin-bottom: 22px;
      width: fit-content;
    }
    .great-decision-desc {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 31px;
    }
    .great-decision-action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .great-decision-go-to-payment {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        text-transform: none;
        margin-bottom: 5px;
      }
      .great-decision-get-in-touch {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #21a7e0;
        text-transform: none;
      }
    }
  }
}
</style>
