var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inlineSlidesList"},[(_vm.slides.length > 0)?_c('v-item-group',{staticClass:"text-center",attrs:{"active-class":"primary"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.slides),function(item,index){return _c('v-col',{key:index,staticClass:"slide-wrapper col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4",style:([
            _vm.$vuetify.breakpoint.xs ||
            _vm.$vuetify.breakpoint.sm ||
            _vm.$vuetify.breakpoint.md
              ? { 'min-width': '300px' }
              : {} ])},[_c('Slide',_vm._g({attrs:{"favorite-slides":_vm.favoriteSlides,"in-viewport-once":true,"in-viewport-root-margin":'0% 0%',"item":item,"log-download":_vm.logDownload.bind(this, { fromExpandedView: false }),"handle-favorite":_vm.handleFavorite,"slideOrigin":_vm.slideOrigin,"handle-click-detail":_vm.handleSlideClick,"disable-download":_vm.disableDownload,"handle-download-from-list":_vm.handleDownloadFromList,"listDownloads":_vm.listDownloads,"listImageReplaced":_vm.listImageReplaced,"listIconReplaced":_vm.listIconReplaced,"is-trending":_vm.trendingSlides.includes(item.unique_id),"id":index,"slides-meta-data":_vm.slidesMetaData,"audience":_vm.currentAudience,"removeSlideOnDelete":_vm.removeSlideOnDelete,"downloadingSlides":_vm.downloadingSlides,"addingToFavSlides":_vm.addingToFavSlides}},_vm.$listeners))],1)}),1)],1)],1):_vm._e(),_c('div',{staticClass:"loadmore-container",attrs:{"data-pendo-id":"slides-container"}},[_c('v-btn',{staticClass:"loadmore-btn",staticStyle:{"text-transform":"none"},attrs:{"id":"loadmore-btn","disabled":_vm.canLoadMore,"color":"#21a7e0","rounded":"","outlined":"","height":"32"},on:{"click":_vm.loadMoreSlides}},[_vm._v(" "+_vm._s(!_vm.isNoMore ? _vm.$t('prezentationsList.seeMoreResult') : _vm.$t('prezentationsList.noMoreResult'))+" ")]),(
        _vm.isNoMore &&
        _vm.origin === 'hybrid_search' &&
        _vm.slideOrigin === 'brand_slides'
      )?_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.limitedResultsSlide'))+" "),_c('a',{staticClass:"text-link",on:{"click":_vm.goToSlideLibrary}},[_vm._v(" "+_vm._s(_vm.$t('sideBarFeatures.slide_library'))+" ")])]):(
        _vm.isNoMore &&
        _vm.origin === 'slidesListPage' &&
        this.searchQuery.length === 0 &&
        _vm.slideOrigin === 'brand_slides'
      )?_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.limitedResults'))+" "),_c('a',{staticClass:"text-link",on:{"click":_vm.scrollToSearch}},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.searchBar'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationsList.or'))+" "),_c('a',{staticClass:"text-link",on:{"click":_vm.scrollToFilters}},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.filters'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationsList.onTop'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }