<template>
  <!--
  put loader or spinner here
  !-->
  <div v-if="Object.keys(this.previewData).length <= 0" class="clip-loader">
    <clip-loader
      :color="`#21a7e0`"
      :width="20"
      :height="20"
      class="cliploader"
    />
  </div>
  <div class="EmailPreviewContainer" v-else>
    <div class="header">
      <div class="closeIconContainer">
        <v-icon @click="handleCloseModal"> mdi-close </v-icon>
      </div>
      <div class="headingContainer">
        <h3 class="heading">
          {{ heading }}
        </h3>
      </div>
    </div>
    <v-card class="preview">
      <div class="preview__header">
        <h4 class="preview__subject">
          {{ 'Subject: ' + previewData.subject }}
        </h4>
        <h4 class="preview__from">{{ 'From: ' + previewData.from }}</h4>
        <h4 class="preview__to" v-if="previewData.to">
          {{ `To: ${previewData.to}` }}
        </h4>
      </div>
      <div class="preview__body">
        <v-card class="email-card">
          <div
            class="email-card__content"
            v-html="returnMarked(previewData.html)"
          ></div>
        </v-card>
      </div>
    </v-card>
  </div>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import DOMPurify from 'dompurify';
import { sendEmail, sendEmailAud } from '../../utils/api-helper';

export default {
  name: 'EmailPreviewModal',
  data() {
    return {
      previewData: {},
    };
  },
  components: {
    ClipLoader,
  },
  props: {
    emailApiPayload: {
      type: Object,
    },
    heading: {
      type: String,
    },
    previewType: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleCloseModal() {
      this.$modal.hide('EmailPreviewModal');
    },
    returnMarked(content) {
      return DOMPurify.sanitize(content);
    },
  },
  async mounted() {
    if (this.previewType === 'AUDIENCE') {
      await sendEmailAud({
        ...this.emailApiPayload,
        preview: true,
      })
        .then((resp) => {
          this.previewData = resp.preview;
        })
        .catch((err) => console.log(err));
    } else {
      await sendEmail({
        ...this.emailApiPayload,
        preview: true,
      })
        .then((resp) => {
          this.previewData = resp.preview;
        })
        .catch((err) => console.log(err));
    }
  },
};
</script>

<style lang="scss" scoped>
.clip-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EmailPreviewContainer {
  background-color: white;
  width: 100%;
  .closeIconContainer {
    position: absolute;
    top: 0px;
    right: 10px;
    margin: 20px 25px;
    z-index: 100;
  }
  .heading {
    font-size: 24px;
  }
  .preview {
    box-shadow: none;
    .preview__header {
      margin: 10px 0px;
      .preview__subject,
      .preview__from,
      .preview__to {
        margin: 0;
        font-size: 12.5px;
      }
    }
    .email-card {
      pointer-events: none;
    }
  }
}
</style>
