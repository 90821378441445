<template>
  <div>
    <v-radio-group v-model="filterBy" @change="handleChangeFilter()" column>
      <div
        class="radioItemWrapper"
        :name--auto="`slides-advanced-filter-sort-${getOptionActive(option)}${
          filterBy === option ? '--active' : ''
        }`"
        v-for="(option, index) in filterItems"
        :key="index"
      >
        <v-radio :label="option" :value="option" color="primary" />
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import EventBus from '../../event-bus';

export default {
  computed: {
    filterItems: {
      get() {
        return this.$store.state.advancedFilterStore.slides.filterItems;
      },
      set() {},
    },
    filterBy: {
      get() {
        return this.$store.state.advancedFilterStore.slides.filterBy;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setSlidesFilterBy', value);
      },
    },
  },
  methods: {
    handleChangeFilter() {
      EventBus.$emit('CHANGE_SLIDES_SORT_FILTER');
    },

    getOptionActive(option) {
      if (option === 'Recommended for selected audience') {
        return 'recommended';
      }
      if (option === 'Popular') {
        return 'popular';
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 17px;
  color: black;
  padding: 8px;
}
</style>
