var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.slide.assetId)?_c('div',{class:("left-side-item " + _vm.getimageClass + " " + _vm.className + " section-" + _vm.sectionIndex + "-slide-" + _vm.slideIndex + "-dragarea--auto")},[(_vm.serialNumber)?_c('span',{staticClass:"serial-number"},[_vm._v(_vm._s(_vm.serialNumber))]):_vm._e(),(
      _vm.animationsData.type === 'acceptSuggestionLoader' ||
      _vm.animationsData.type === 'reviewCheckLoader' ||
      _vm.animationsData.type === 'synthesizeVideoLoading'
    )?_c('div',[_c('v-img',{attrs:{"alt":"","lazy-src":"/assets/img/slides/placeholder-slide.svg","aspect-ratio":16 / 9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('clip-loader',{attrs:{"color":"#21a7e0","width":20,"height":20}})],1)]},proxy:true}],null,false,1316975780)})],1):[(_vm.slide.outline)?_c('v-tooltip',{attrs:{"right":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-img',_vm._g({attrs:{"src":_vm.slide.thumbnail,"alt":"","lazy-src":"/assets/img/slides/placeholder-slide.svg","aspect-ratio":16 / 9},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('clip-loader',{attrs:{"color":"#21a7e0","width":20,"height":20}})],1)]},proxy:true}],null,true)},on))]}}],null,false,3836397507)},[_c('span',{staticClass:"tooltip-outline-title"},[_vm._v(_vm._s(_vm.slide.outline))])]):_c('v-img',{attrs:{"src":_vm.slide.thumbnail,"alt":"","lazy-src":"/assets/img/slides/placeholder-slide.svg","aspect-ratio":16 / 9},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('clip-loader',{attrs:{"color":"#21a7e0","width":20,"height":20}})],1)]},proxy:true}],null,false,1316975780)})],(!_vm.isViewAccess && !_vm.isTemplateRemoved && !_vm.isOpPrezentation)?_c('div',{staticClass:"slide-controls"},[_c('div',{class:"drag-icon"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-dots-vertical ")]),_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-dots-vertical ")])],1),_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.onDuplicate}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,false,3824563801)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.duplicate'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"18"},on:{"click":_vm.onDelete}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,false,2445486065)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")])])],1)]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }