import { render, staticRenderFns } from "./CommunicationStandAlone.vue?vue&type=template&id=a9e135c6&scoped=true&"
import script from "./CommunicationStandAlone.vue?vue&type=script&lang=js&"
export * from "./CommunicationStandAlone.vue?vue&type=script&lang=js&"
import style0 from "./CommunicationStandAlone.vue?vue&type=style&index=0&id=a9e135c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9e135c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VCheckbox,VToolbarTitle})
