<template>
  <div class="filter-sort-wrapper">
    <v-tabs color="#21a7e0" height="40" grow>
      <v-tab class="vtab"
        ><span>{{ $t('prezentationsList.advancedFilters') }}</span></v-tab
      >
      <v-tab class="vtab"
        ><span>{{ $t('bestPracticesGuide.knowContainer.sortBy') }}</span></v-tab
      >

      <v-tab-item>
        <div class="advanced-filter-wrapper">
          <div
            :class="
              isAdvancedFilterChanged ? 'resetFilters' : 'disabledResetFilters'
            "
          >
            <div
              class="resetText"
              @click="
                (e) =>
                  isAdvancedFilterChanged ? resetAdvancedFilters(e) : null
              "
            >
              {{ $t('prezentationsList.resetAllFilters') }}
            </div>
          </div>
          <v-expansion-panels
            accordion
            multiple
            v-model="advancedFilterActivePanels"
          >
            <v-expansion-panel
              v-for="item in advancedItems"
              :key="item.title"
              expand
            >
              <v-expansion-panel-header>
                <div class="exp-filter-header">
                  <img
                    class="filter-icon"
                    alt
                    :src="`/assets/img/${item.icon}.svg`"
                    width="24"
                    height="24"
                  />
                  <div>{{ item.title }}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item v-if="item.includeAllOption">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="item.checkAll"
                      color="primary"
                      :on-icon="'mdi-checkbox-marked-outline'"
                      :off-icon="'mdi-checkbox-blank-outline'"
                      @click="handleAllOptionClickForCategories(item)"
                    />
                  </v-list-item-action>
                  <img
                    alt
                    :src="`/assets/img/filter-all.svg`"
                    width="20"
                    height="20"
                    class="advanced-filter-icon"
                    v-if="item.showIcon"
                  />
                  <v-list-item-content>
                    <v-list-item-title
                      class="advanced-filter-content-text"
                      v-text="$t('overnightPresentations.all')"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  v-for="child in item.items"
                  :key="child.title"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :on-icon="'mdi-checkbox-marked-outline'"
                        :off-icon="'mdi-checkbox-blank-outline'"
                        :input-value="active"
                        v-model="child.active"
                        color="primary"
                        @click="onAdvancedFilerChange(item)"
                      />
                    </v-list-item-action>
                    <img
                      alt
                      :src="`/assets/img/filter-${child.icon}.svg`"
                      width="20"
                      height="20"
                      v-if="child.icon"
                      class="advanced-filter-icon"
                    />
                    <v-list-item-content>
                      <v-list-item-title
                        class="advanced-filter-content-text"
                        v-text="child.title"
                      />
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <div v-if="item.isCustomGroup" class="customGroupWrapper">
                  <div class="fromDateWrapper">
                    <div class="label">
                      {{ $t('overnightPresentations.from') }}
                    </div>
                    <div class="datePicker">
                      <v-menu
                        v-model="fromDateMenu"
                        :close-on-content-click="false"
                        :close-on-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fromDateStr"
                            :placeholder="
                              $t('overnightPresentations.dateFormat')
                            "
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @focus="setCurrentDateFormatted('from')"
                            @mouseup="setCurrentDateFormatted('from')"
                          >
                            <template #append>
                              <v-icon
                                v-if="fromDateStr !== ''"
                                @click="resetDate('from')"
                                class="clearIcon"
                              >
                                {{ 'mdi-close' }}
                              </v-icon>
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="calendarIcon"
                              >
                                {{ 'mdi-calendar' }}
                              </v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fromDate"
                          :allowedDates="allowedFromDates"
                          no-title
                          scrollable
                          @input="updateFromDateMenu(false)"
                          color="#20a7e0"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <div class="toDateWrapper">
                    <div class="label">
                      {{ $t('overnightPresentations.to') }}
                    </div>
                    <div class="datePicker">
                      <v-menu
                        v-model="toDateMenu"
                        :close-on-content-click="false"
                        :close-on-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="toDateStr"
                            :placeholder="
                              $t('overnightPresentations.dateFormat')
                            "
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @focus="setCurrentDateFormatted('to')"
                            @mouseup="setCurrentDateFormatted('to')"
                          >
                            <template #append>
                              <v-icon
                                v-if="toDateStr !== ''"
                                @click="resetDate('to')"
                                class="clearIcon"
                              >
                                {{ 'mdi-close' }}
                              </v-icon>
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="calendarIcon"
                              >
                                {{ 'mdi-calendar' }}
                              </v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="toDate"
                          :allowedDates="allowedToDates"
                          no-title
                          scrollable
                          @input="updateToDateMenu(false)"
                          color="#20a7e0"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <div class="applyDatesCTA">
                    <v-btn @click="onAdvancedFilerChange(item)">
                      {{ $t('prezentationsList.applyCustomDates') }}
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-tab-item>

      <v-tab-item>
        <OpSort />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import EventBus from '../../event-bus';
import OpSort from './OpSort.vue';

export default {
  name: 'OpAdvancedFilters',
  components: {
    OpSort,
  },
  computed: {
    advancedFilterActivePanels: {
      get() {
        return this.$store.state.advancedFilterStore.op
          .advancedFilterActivePanels;
      },
      set() {},
    },
    fromDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.op.fromDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setFromDateMenu', value);
      },
    },
    toDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.op.toDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setToDateMenu', value);
      },
    },
    fromDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.op.fromDateStr;
      },
      set() {},
    },
    toDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.op.toDateStr;
      },
      set() {},
    },
    fromDate: {
      get() {
        return this.$store.state.advancedFilterStore.op.fromDate;
      },
      set(value) {
        const payload = {
          type: 'from',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/handleDatePickerTextChange',
          payload,
        );
      },
    },
    toDate: {
      get() {
        return this.$store.state.advancedFilterStore.op.toDate;
      },
      set(value) {
        const payload = {
          type: 'to',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/handleDatePickerTextChange',
          payload,
        );
      },
    },
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.op.advancedItems;
      },
      set() {},
    },
    isAdvancedFilterChanged() {
      return (
        this.advancedItems.some(
          (item) =>
            !item.checkAll ||
            (item.includeAllOption &&
              item.items &&
              item.items.some((itm) => !itm.active)) ||
            (!item.includeAllOption &&
              item.items &&
              item.items.some((itm) => itm.active)),
        ) ||
        this.fromDateStr ||
        this.toDateStr
      );
    },
  },
  methods: {
    ...mapActions('advancedFilterStore', [
      'resetDate',
      'allowedFromDates',
      'allowedToDates',
      'handleDatePickerTextChange',
      'setCurrentDateFormatted',
      'setFromDateMenu',
      'setToDateMenu',
    ]),
    updateFromDateMenu(type) {
      this.setFromDateMenu(type);
    },
    updateToDateMenu(type) {
      this.setToDateMenu(type);
    },
    resetAdvancedFilters() {
      EventBus.$emit('RESET_ADVANCED_FILTERS');
    },
    handleAllOptionClickForCategories(item) {
      EventBus.$emit('ALL_CATEGORY', item);
    },
    onAdvancedFilerChange(item) {
      EventBus.$emit('ON_FILTER_CHANGE', item);
    },
    setDatePickerTextChange(type) {
      const payload = {
        type,
        date: type === 'from' ? this.fromDate : this.toDate,
      };
      this.handleDatePickerTextChange(payload);
    },
    allowedFromDates(value) {
      return moment(value).isSameOrBefore(new Date());
    },
    allowedToDates(value) {
      if (this.fromDate === '') {
        return moment(value).isSameOrBefore(new Date());
      }
      return (
        moment(value).isSameOrAfter(this.fromDate) &&
        moment(value).isSameOrBefore(new Date())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-filter-wrapper {
  .exp-filter-header {
    display: flex;
    align-items: center;
    gap: 5px;

    .filter-icon {
      width: 24px;
      height: 24px;
    }
  }

  .clear-text {
    color: #21a7e0 !important;
    cursor: pointer;
  }
}

.vtab {
  span {
    font-size: 16px;
    font-weight: 600;
    color: #4d5358;
    text-transform: none;
    letter-spacing: normal;
  }
}

.customGroupWrapper {
  margin-left: 30px;
  padding: 15px 15px 15px 0;

  .fromDateWrapper,
  .toDateWrapper {
    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
    .datePicker {
      .v-input {
        .v-input__control {
          .v-input__slot {
            width: 80%;

            .v-input__append-inner {
              .clearIcon,
              .calendarIcon {
                &:hover {
                  color: #21a7e0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panels {
  max-height: calc(100vh - 127px);
  overflow: auto;
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .v-expansion-panel {
    flex: none;
    width: 100%;
    &::before {
      box-shadow: none;
    }

    &::after {
      opacity: 0 !important;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: 47px !important;
  }

  .v-list-item {
    min-height: 30px !important;
    padding: 0;
    padding-left: 28px;
    .v-list-item__action {
      margin: 0px 7px 0px 0px;
    }
    .v-list-item__content {
      padding: 8px 0px;
      .v-list-item__title {
        font-size: 16px;
        line-height: 18px !important;
      }
    }
  }
}

.v-expansion-panels::-webkit-scrollbar {
  display: none;
}

.applyDatesCTA {
  width: fit-content;

  .v-btn {
    padding: 0 !important;
    background: transparent !important;
    border: none;
    box-shadow: none;
    letter-spacing: normal;
    text-transform: none;
    font-size: 16px;
    color: #21a7e0;
    width: 100%;
    background-color: transparent !important;
  }

  // .disabled-applyDatesCTA {
  //   color: #878d96;
  // }
}

.resetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .resetText {
    color: #21a7e0 !important;
    cursor: pointer;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.disabledResetFilters {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .v-icon,
  .resetText {
    color: #7b7b7b;
    margin: 0px 0px 0px 5px;
    cursor: default;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.filter-header {
  padding: 5px 10px 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
  }

  h3 {
    font-size: 17px;
    text-align: center;
  }
}
</style>
