<template>
  <div class="linkedInShare">
    <v-progress-circular
      indeterminate
      size="25"
      color="primary"
      v-if="sharingLinkedIn"
      :style="{ margin: '4px 30px 0px 0px' }"
    />
    <div class="shareOnLinkedInContainer" @click="updateInternalFP" v-else>
      <v-icon :size="iconSize" left color="primary">{{
        'mdi-linkedin'
      }}</v-icon>
      <span>{{ 'Share on LinkedIn' }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { updateInternalFingerprint } from '../../utils/api-helper';
import { trackFingerprintEvents } from './Analytics/FingerprintEvents';
import {
  TD_PLACEMENT,
  TD_MY_FP_IN_PROFILE,
  TD_FP_FEATURE,
  TD_FP_TEST_PLACEMENT,
  TD_USER,
  TD_COMPANY,
  TD_TIMESTAMP,
  TD_USER_NAME,
} from './Analytics/MatomoTrackingDataHelper.js';

export default {
  name: 'ShareFPOnLinkedIn',
  data() {
    return {
      fpDataState: null,
      sharingLinkedIn: false,
    };
  },
  props: {
    origin: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '20px',
    },
    isAudience: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    fpData: {
      type: Object,
      default: () => {},
    },
    answers: {
      type: Object,
      default: () => {},
    },
    audience: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTestAudience']),
  },
  methods: {
    constructFpPayload() {
      if (this.isAudience && this.origin === 'add_new_aud') {
        return {
          company: this.audience.companyDisplayName,
          domain: window.location.origin,
          firstName: this.audience.firstName,
          function: this.audience.jobFunction || '',
          group: this.audience.subDivision || [],
          id: this.audience.id,
          industry: this.audience.industry || '',
          jobTitle: this.audience.jobTitle || '',
          lastName: this.audience.lastName,
          picture: '',
          region: this.audience.region || [],
          shareType: 'LinkedIn',
          personalPreference: this.audience.personalPreference || [],
          preferences: this.constructPreferencesFromFpData(),
        };
      }
      return {
        company: this.currentUser.company.displayName,
        domain: window.location.origin,
        firstName: this.currentUser.user.firstName,
        function: this.currentUser.user.jobFunction || '',
        group: this.currentUser.user.subDivision || [],
        id: this.currentUser.user.id,
        industry: this.currentUser.user.industry || '',
        jobTitle: this.currentUser.user.jobTitle || '',
        lastName: this.currentUser.user.lastName,
        picture: this.currentUser.user.profileImageUrl || '',
        region: this.currentUser.user.region || [],
        shareType: 'LinkedIn',
        personalPreference: this.currentUser.user.personalPreference,
        preferences: this.constructPreferencesFromFpData(),
      };
    },
    constructPreferencesFromFpData() {
      let preferences = {};
      const fpResponses = this.currentUser?.user?.fingerprintResponse;
      fpResponses?.forEach((item) => {
        preferences[item.split(',')[0]] =
          item.split(',')[1] === 'leftImage' ? 1 : 2;
      });
      if (!fpResponses && this.answers) {
        preferences = {
          question0: this.answers?.question0,
          question1: this.answers?.question1,
          question2: this.answers?.question2,
          question3: this.answers?.question3,
          question4: this.answers?.question4,
          question5: this.answers?.question5,
        };
      }
      return preferences;
    },
    async updateInternalFP() {
      this.sharingLinkedIn = true;
      let placement = '';
      if (this.origin === 'my_fp') {
        placement = TD_MY_FP_IN_PROFILE;
      } else if (this.origin === TD_FP_FEATURE) {
        placement = TD_FP_FEATURE;
      } else if (this.origin === 'Retake-fingerprint-test') {
        placement = TD_FP_TEST_PLACEMENT;
      } else {
        placement = TD_FP_TEST_PLACEMENT;
      }
      if (this.currentUser.user) {
        const otherData = {
          [TD_PLACEMENT]: placement,
        };
        trackFingerprintEvents.fingerprintsShareOnLinkedIn(
          this.currentUser,
          otherData,
        );
      } else {
        const userName = this.currentTestAudience?.fullName
          ? this.currentTestAudience.fullName
          : `${this.currentTestAudience.firstName} ${this.currentTestAudience.lastName}`;
        const otherData = {
          [TD_PLACEMENT]: placement,
          [TD_USER]: this.currentTestAudience.id,
          [TD_COMPANY]: this.currentTestAudience.companyName,
          [TD_TIMESTAMP]: new Date()
            .toUTCString()
            .replaceAll(',', '')
            .replace('GMT', ''),
          [TD_USER_NAME]: userName,
        };
        trackFingerprintEvents.fingerprintsShareOnLinkedIn(null, otherData);
      }
      const payload = this.constructFpPayload();
      await updateInternalFingerprint(payload)
        .then((resp) => {
          this.fpDataState = { ...this.fpData, ...resp?.data };
          this.handleShareOnLinkedIn();
        })
        .catch((err) => {
          this.sharingLinkedIn = false;
          console.log(err);
        });
    },
    handleShareOnLinkedIn() {
      if (this.fpDataState?.s3Path) {
        const url = `${
          process.env.VUE_APP_FINGERPRINT_DISTRIBUTION_URL
        }${this.fpDataState?.s3Path.replace('public-dist', '')}`;
        const link = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
        window.open(link);
      }
      this.sharingLinkedIn = false;
    },
  },
  mounted() {
    this.fpDataState = this.fpData;
  },
};
</script>

<style lang="scss" scoped>
.linkedInShare {
  display: flex;
  align-items: center;

  .shareOnLinkedInContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0px 5px 0px 0px;
    cursor: pointer;

    i {
      margin: 0px !important;
    }

    span {
      color: #21a7e0;
      font-size: 14px;
    }
  }
}
</style>
